import * as R      from 'ramda';
import { connect } from 'react-redux';
import React       from 'react';
import PropTypes   from 'prop-types';

import {
  compose,
  lifecycle,
  setPropTypes,
  withProps,
  withStateHandlers
} from 'recompose';

import Modal from '@material-ui/core/Modal';

import VideoWidgetContent      from './VideoWidgetContent';
import VideoWidgetModalContent from './VideoWidgetModalContent';
import VideoWidgetHeader       from './VideoWidgetHeader';

import WidgetCreator from './WidgetCreator/WidgetCreator';

import ModalInner from '../ModalInner';

import withUser    from '../../modules/auth';
import withClient  from '../../modules/client';
import { history } from '../../store';

import { isClientUser } from '../../modules/client/ClientReducer';

import { fetchQuestionAnswers as _fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';
import { fetchDocuments as _fetchDocuments }             from '../../modules/document/DocumentActions';
import { filterByAction as _filterByAction }             from '../../modules/svr-responses/SvrResponsesActions';
import { fetchFormsByIds as _fetchFormsByIds }           from '../../modules/saved-widgets/SavedWidgetsActions';

import {
  mapAnswersToQuestions,
  getFlaggedDocumentUuids,
  getStarredDocumentUuids,
  getStoreVisitIdsContainingFlaggedDocumentUuids,
  getStoreVisitIdsContainingStarredDocumentUuids
} from '../../utils/widgetHelpers';

import filterBlockedVideos from '../../modules/svr-responses/components/review/utils/filterBlockedVideos';

const maybeShowOnlyStarredOrFlaggedVideos = ({ starred, flagged }, filteredWidgetData, allUuids) => {
  if (starred) {
    return R.always(getStarredDocumentUuids("Videos")(filteredWidgetData));
  }
  if (flagged) {
    return R.always(getFlaggedDocumentUuids("Videos")(filteredWidgetData));
  }
  return allUuids;
};

const maybeFilterStoreVisitsWithStarredOrFlaggedVideos = ({ starred, flagged }, filteredWidgetData) => {
  if (starred) {
    const starredStoreVisitIds = getStoreVisitIdsContainingStarredDocumentUuids("Videos")(filteredWidgetData);
    return R.filter(R.compose(R.contains(R.__, starredStoreVisitIds), R.prop('id')))(filteredWidgetData);
  }
  if (flagged) {
    const flaggedStoreVisitIds = getStoreVisitIdsContainingFlaggedDocumentUuids("Videos")(filteredWidgetData);
    return R.filter(R.compose(R.contains(R.__, flaggedStoreVisitIds), R.prop('id')))(filteredWidgetData);
  }
  return filteredWidgetData;
};

const VideoWidgetContainer = (
  {
    widgetData,
    currentSlide,
    description,
    handleNextSlide,
    handlePreviousSlide,
    currentModalSlide,
    isDetailView,
    widgetContent,
    title,
    toggleDetailView,
    handleNextModalSlide,
    handlePreviousModalSlide,
    handleEditModal,
    isEditFormView,
    onMoveClick,
    onTitleClick,
    isLoading
  }) => (
  <div>
    {isEditFormView && (
      <WidgetCreator
        isOpen              = {isEditFormView}
        toggleWidgetCreator = {handleEditModal}
        namespace           = {R.pathOr('', ['metaJson', 'namespace'], widgetData)}
        savedWidgetFormData = {widgetData}
      />
    )}
      <VideoWidgetHeader
        title               = {title}
        description         = {description}
        handleNextSlide     = {handleNextSlide}
        handlePreviousSlide = {handlePreviousSlide}
        toggleDetailView    = {toggleDetailView}
        handleEditModal     = {handleEditModal}
        onMoveClick         = {onMoveClick}
        widgetContent       = {widgetContent}
        onTitleClick        = {onTitleClick}
      />
      <div>
          <VideoWidgetContent
            imgUrl            = {R.pathOr('',   [[currentSlide], 'imgUrl'],            widgetContent)}
            imgUrlAfter       = {R.pathOr('',   [[currentSlide], 'imgUrlAfter'],       widgetContent)}
            imgUrlBefore      = {R.pathOr('',   [[currentSlide], 'imgUrlBefore'],      widgetContent)}
            doorNumber        = {R.pathOr(null, [[currentSlide], 'doorNumber'],        widgetContent)}
            location          = {R.pathOr('',   [[currentSlide], 'location'],          widgetContent)}
            retailer          = {R.pathOr('',   [[currentSlide], 'retailer'],          widgetContent)}
            imgRotation       = {R.pathOr('',   [[currentSlide], 'imgRotation'],       widgetContent)}
            imgRotationAfter  = {R.pathOr('',   [[currentSlide], 'imgRotationAfter'],  widgetContent)}
            imgRotationBefore = {R.pathOr('',   [[currentSlide], 'imgRotationBefore'], widgetContent)}
            toggleDetailView  = {toggleDetailView}
          />
          <div style={{ fontSize : '0.8em', textAlign : 'center', margin : '1.45em', position: 'relative' }}>
            {
              widgetContent.length ? (
                <div>
                  <span style={{ fontWeight : 'bold' }}>{R.pathOr('', [[currentSlide], 'retailer'], widgetContent)}: </span>
                  <span>{R.pathOr('', [[currentSlide], 'location'], widgetContent)} </span>
                  <span>(#{R.pathOr(null, [[currentSlide], 'doorNumber'], widgetContent)})</span>
                </div>
              ) : (
                <span>{isLoading ? 'Loading ...' : 'Empty Widget'}</span>
              )
            }
          </div>
        </div>
    <Modal
      aria-labelledby  = "video-widget-modal"
      aria-describedby = "video-widget-modal-detail"
      open             = {isDetailView}
      onClose          = {toggleDetailView}>
      <ModalInner>
        {
          widgetContent.length ? (
            <VideoWidgetModalContent
              handlePreviousSlide = {handlePreviousModalSlide}
              handleNextSlide     = {handleNextModalSlide}
              imgUrlAfter         = {widgetContent[currentModalSlide].imgUrlAfter}
              imgTitleAfter       = {widgetContent[currentModalSlide].imgTitleAfter}
              imgUrlBefore        = {widgetContent[currentModalSlide].imgUrlBefore}
              imgTitleBefore      = {widgetContent[currentModalSlide].imgTitleBefore}
              imgUrl              = {widgetContent[currentModalSlide].imgUrl}
              doorNumber          = {widgetContent[currentModalSlide].doorNumber}
              location            = {widgetContent[currentModalSlide].location}
              retailer            = {widgetContent[currentModalSlide].retailer}
              imgTitle            = {widgetContent[currentModalSlide].imgTitle}
              checkinDate         = {widgetContent[currentModalSlide].checkinDate}
              imgRotation         = {widgetContent[currentModalSlide].imgRotation}
              imgRotationAfter    = {widgetContent[currentModalSlide].imgRotationAfter}
              imgRotationBefore   = {widgetContent[currentModalSlide].imgRotationBefore}
            />
          ) : null
        }
      </ModalInner>
    </Modal>
  </div>
);

const mapStateToProps = () => ({
  isClientUser
});

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload) => dispatch(_fetchQuestionAnswers(namespace, payload)),
  fetchDocuments       : (namespace, payload) => dispatch(_fetchDocuments(namespace, payload)),
  fetchFormsByIds      : (namespace, payload) => dispatch(_fetchFormsByIds(namespace, payload)),
  filterByAction       : (namespace, filters) => dispatch(_filterByAction(namespace, filters))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withProps(({ widgetData : { description, title, filteredWidgetData, formQuestionCanonicalKeys, filters }}) => ({
    description,
    title,
    filteredWidgetData,
    formQuestionCanonicalKeys,
    filters
  })),
  withStateHandlers(
    ({
      currentSlide      = 0,
      currentModalSlide = 0,
      isDetailView      = false,
      widgetContent     = [],
      isEditFormView    = false,
      isLoading         = false
    }, open = false) => ({
      currentSlide,
      isDetailView,
      open,
      widgetContent,
      currentModalSlide,
      isEditFormView,
      isLoading
    }),
    {
      setIsLoading        : () => loading => ({
        isLoading         : loading
      }),
      handlePreviousSlide : ({ currentSlide, widgetContent }) => () => ({
        currentSlide :
          currentSlide - 1 < 0
            ? widgetContent.length - 1
            : currentSlide - 1
      }),
      handleNextSlide     : ({ currentSlide, widgetContent }) => () => ({
        currentSlide :
          currentSlide + 1 > widgetContent.length - 1
            ? 0
            : currentSlide + 1
      }),
      handlePreviousModalSlide : ({ currentModalSlide, widgetContent }) => () => ({
        currentModalSlide :
          currentModalSlide - 1 < 0
            ? widgetContent.length - 1
            : currentModalSlide - 1
      }),
      handleNextModalSlide     : ({ currentModalSlide, widgetContent }) => () => ({
        currentModalSlide :
          currentModalSlide + 1 > widgetContent.length - 1
            ? 0
            : currentModalSlide + 1
      }),
      toggleDetailView    : ({ isDetailView, currentSlide }) => () => ({
        isDetailView      : !isDetailView,
        currentModalSlide : currentSlide
      }),
      onItemClick         : () => id => {
        history.push('/svr-response/' + id);
      },
      setWidgetContent    : () => widgetContent => ({ widgetContent, isLoading: false }),
      handleEditModal     : ({ isEditFormView }) => () => ({ isEditFormView: !isEditFormView })
    }
  ),
  setPropTypes({
    description  : PropTypes.string,
    title        : PropTypes.string,
    widgetData   : PropTypes.object.isRequired,
    onTitleClick : PropTypes.func,
    onEditClick  : PropTypes.func
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const {
        widgetData : {
          filteredWidgetData,
          formQuestionCanonicalKeys,
          metaJson
        },
        fetchFormsByIds,
        setWidgetContent,
        fetchQuestionAnswers,
        fetchDocuments,
        isClientUser,
        setIsLoading
      } = this.props;

      const {
        widgetData : {
          filteredWidgetData: nextFilteredWidgetData,
        }
      } = nextProps;

      if(R.equals(filteredWidgetData, nextFilteredWidgetData) || !R.is(Array, nextFilteredWidgetData) || !nextFilteredWidgetData.length) {
        return;
      }

      setIsLoading(true);
      const questions = formQuestionCanonicalKeys || [];

      fetchQuestionAnswers(metaJson.namespace, questions)
        .then(res => {
          let answers       = R.pathOr([], ['payload', 'data', 'data'], res);
          const storeVisits = maybeFilterStoreVisitsWithStarredOrFlaggedVideos(metaJson, nextFilteredWidgetData);

          answers = R.filter(R.compose(R.contains(R.__, R.pluck('id', storeVisits)), R.prop('storeVisitId')))(answers);
          
          const uuids = R.compose(
            R.without(
              R.compose(
                R.flatten,
                R.filter(R.length),
                R.map(v => filterBlockedVideos(v, null, true))
              )(storeVisits)
            ),
            R.filter(R.identity),
            maybeShowOnlyStarredOrFlaggedVideos(
              metaJson,
              nextFilteredWidgetData,
              R.pluck('answerValue')
            )
          )(answers);
          
          if (uuids.length) {
            fetchDocuments(metaJson.namespace, uuids)
              .then(res => {
                const videos = R.pathOr([], ['payload', 'data', 'data'], res);

                mapAnswersToQuestions(
                  answers,
                  videos,
                  storeVisits,
                  fetchFormsByIds,
                  formQuestionCanonicalKeys,
                  isClientUser(),
                  metaJson.namespace
                )
                  .then(R.filter(R.prop('imgUrl')))
                  .then(R.sort(R.descend(R.prop('checkinDate'))))
                  .then(setWidgetContent);
              });
          } else {
            setIsLoading(false);
          }
        });
    }
  })
)(VideoWidgetContainer);
