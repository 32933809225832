import React          from 'react';
import * as R         from 'ramda';
import styled         from 'styled-components';
import { connect }    from 'react-redux';
import PropTypes      from 'prop-types';
import { withRouter } from 'react-router';
import uuid           from 'uuid/v4';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
  withStateHandlers,
  withState
} from 'recompose';

import { MenuItem, Modal, Select, CircularProgress } from '@material-ui/core';

import { axiosClient, history }           from '../../../store';
import SavedRollupsFormsList from '../components/SavedRollupsFormsList';
import selectListData        from '../../../utils/selectListData';
import selectQuestions       from '../../../utils/selectQuestions';

import {
  fetchFormsByIds   as _fetchForms,
  createSavedRollup as _createRollUp,
  editSavedRollup   as _editRollUp,
  fetchSavedRollup  as _fetchRollUp,
  resetForms        as _resetForms
} from '../SavedRollupsActions';

const HEIGHT = 30;

const Wrapper = styled.div`
  background    : #fff;
  margin        : 40px auto;
  width         : 90vw;
  max-height    : 90vh;
  min-height    : initial;
  overflow-y    : auto;
  border-radius : 2px;
  box-shadow    : 0px 1px 5px 0px  rgba(0, 0, 0, 0.2),
                  0px 2px 2px 0px  rgba(0, 0, 0, 0.14),
                  0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const Content = styled.div`
  display        : flex;
  flex-direction : row;
`;

const OptionsContainer = styled.div`
  display        : flex;
  flex-direction : column;
  width          : 100%;
  min-height     : initial;
  border-right   : 2px solid #F2F2F2;
  flex-wrap      : wrap;
  flex-grow      : 1;
`;

const SelectContainer = styled.div`
  margin : 1rem 2rem 2rem 2rem;

  p {
    font-size   : .875rem;
    font-weight : 500;
  }
`;

const Footer = styled.div`
  display         : flex;
  justify-content : flex-end;
  align-items     : center;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  font-size       : 1rem;
  padding         : .5rem 1.5rem;

  & > div {
    color  : #F3212E;
    cursor : pointer;
  }
`;

const SavedRollupPage = ({
  forms,
  handleToggleForm,
  handleQuickSelect,
  quickSelectedValue,
  handleSelectQuestion,
  selectedQuestions,
  selectedForm,
  isOpen,
  onClose,
  handleUpdate,
  SearchParamsList,
  isClientUser,
  loading
}) => (
  <Modal
    aria-labelledby  = "simple-modal-title"
    aria-describedby = "simple-modal-description"
    open             = {isOpen}
    onClose          = {onClose}
    style            = {{ overflow : 'auto', justifyContent : 'center', alignItems : 'center' }}
  >
    <Wrapper>
      <Content>
        <OptionsContainer>

          { SearchParamsList }

          <SelectContainer >
            <p>QUICK SELECT</p>
            <Select
              fullWidth
              value    = {quickSelectedValue}
              onChange = {event => handleQuickSelect(event, forms)}
            >
              {selectListData.map(s => <MenuItem key={s.value} value={s.value}>{s.name}</MenuItem>)}
            </Select>
          </SelectContainer>
        </OptionsContainer>
        {loading 
          ? <div style={{minWidth: "50%", display: "flex", alignItems: "center", justifyContent: "center"}}>
              <CircularProgress/>
            </div>
          : <SavedRollupsFormsList
              forms                = {forms}
              handleToggleForm     = {handleToggleForm}
              selectedForm         = {selectedForm}
              selectedQuestions    = {selectedQuestions}
              handleSelectQuestion = {handleSelectQuestion}
              isClientUser         = {isClientUser}
            />}
      </Content>
      <Footer>
        {
          selectedQuestions.length ? (
            <div onClick={handleUpdate}>SAVE</div>
          ) : (
            <span>Please select one or more questions to roll up</span>
          )
        }
      </Footer>
    </Wrapper>
  </Modal>
);


const mapDispatchToProps = dispatch => ({
  fetchForms   : (namespace, payload)    => dispatch(_fetchForms(namespace, payload)),
  createRollUp : payload    => dispatch(_createRollUp(payload)),
  editRollUp   : (data, id) => dispatch(_editRollUp(data, id)),
  fetchRollUp  : payload    => dispatch(_fetchRollUp(payload)),
  resetForms   : (namespace)=> dispatch(_resetForms(namespace))
});

const withRollUpPage = compose(
  connect(null, mapDispatchToProps),
  withStateHandlers(
    ({
      id                 = null,
      selectedForm       = '',
      quickSelectedValue = '',
      selectedQuestions  = []
    }) => ({
      selectedForm,
      quickSelectedValue,
      selectedQuestions,
      id
    }),
    ({
      handleUpdate         : ({ selectedQuestions, id }, { rollUpTitle, rollUpDescription, createRollUp, editRollUp, filterList, restrictTokenUserId, embedPhotos, includeMotivesInExcelReport }) => () => {
        const uniqSelectedQuestions = R.uniq(selectedQuestions);

        if (R.isNil(id)) {
          const data = {
            formQuestionCanonicalKeys : R.join(',', uniqSelectedQuestions),
            filters                   : JSON.stringify(filterList),
            description               : rollUpDescription,
            title                     : rollUpTitle
          };

          if (restrictTokenUserId) {
            data.restrictTokenUserId = parseInt(restrictTokenUserId, 10);
          }

          data.metaJson =  { embedPhotosInExcelReport: Boolean(embedPhotos), includeMotivesInExcelReport };

          createRollUp(data).then(res => history.push(`/dashboard/roll-ups/${R.path(['payload', 'data', 'data', 'id'], res)}/edit`));
        } else {
          const data = {
            formQuestionCanonicalKeys : R.join(',', uniqSelectedQuestions),
            filters                   : JSON.stringify(filterList),
            description               : rollUpDescription,
            title                     : rollUpTitle,
            restrictTokenUserId       : restrictTokenUserId ? parseInt(restrictTokenUserId, 10) : null,
            metaJson                  : { embedPhotosInExcelReport: Boolean(embedPhotos), includeMotivesInExcelReport }
          };

          editRollUp(data, id).then(res => history.push(`/dashboard/roll-ups/${R.path(['payload', 'data', 'data', 'id'], res)}/edit`));
        }
      },
      handleToggleForm  : ({ selectedForm }) => formId => ({ selectedForm : R.equals(selectedForm, formId) ? '' : formId }),
      handleQuickSelect : () => ({ target }, forms) => {
        const selectedQuestions = selectQuestions(target.value, forms);

        return {
          quickSelectedValue: target.value,
          selectedQuestions
        };
      },
      handleSelectQuestion : ({ selectedQuestions }) => ({ target }, canonicalKey) => ({
        selectedQuestions: target.checked ?
          R.append(canonicalKey, selectedQuestions)
          :
          R.without([canonicalKey], selectedQuestions)
      }),
      setRollUp: () => rollUp => ({
        selectedQuestions : R.propOr([], 'formQuestionCanonicalKeys', rollUp),
        id                : R.propOr(null, 'id', rollUp)
      })
    })
  ),
  defaultProps({
    forms : [],
    isClientUser: false
  }),
  setPropTypes({
    forms       : PropTypes.array,
    isClientUser: PropTypes.bool
  }),
  withState('forms', 'setForms', []),
  withState('currentFormsRequestId', 'setCurrentFormsRequestId', []),
  withState('loading', 'setLoading', false),
  lifecycle({
    async componentDidMount() {
      const {
        data,
        rollUpId,
        fetchRollUp,
        setRollUp
      } = this.props;
  
      const formIds = R.pluck('formId', data);
  
      if (!R.isEmpty(formIds)) {
        this.props.setLoading(true);
        const resp = await axiosClient.get(`/api/v2/form/ids/${R.compose(R.join(','), R.filter(R.identity), R.uniq)(formIds)}`, {
          params : { status: [1] },
          headers: {
            Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
          }
        });
        this.props.setLoading(false);
        this.props.setForms(resp.data.data);
      }
  
      if (rollUpId) {
        fetchRollUp(rollUpId).then(rollupData => setRollUp(R.pathOr({}, ['payload', 'data', 'data'], rollupData)));
      }
    },
    componentWillReceiveProps(nextProps) {
      const dataIsDifferent = !R.equals(R.pluck('timestamp', nextProps.data), R.pluck('timestamp', this.props.data));
      if (dataIsDifferent) {
        const formIds = R.reject(R.isNil, R.pluck('formId', nextProps.data));
        this.props.setLoading(true);
        if (R.isEmpty(formIds)) {
          this.props.setLoading(false);
          return;
        }
        this.props.resetForms('savedRollup');
        const currentRequestId = uuid();
        this.props.setCurrentFormsRequestId(currentRequestId,
        async () => {
          if (!R.isEmpty(formIds)) {
            const resp = await axiosClient.get(`/api/v2/form/ids/${R.compose(R.join(','), R.filter(R.identity), R.uniq)(formIds)}`, {
              params : { status: [1] },
              headers: {
                Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
              }
            });
            if(this.props.currentFormsRequestId === currentRequestId) { 
              this.props.setForms(resp.data.data);
              this.props.setLoading(false);
            }
          }
        });
        
      }
    }
  }),
  withRouter
);

export default withRollUpPage(SavedRollupPage);
