import * as R               from 'ramda';
import React, { Component } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';

class MoveWidgetDialog extends Component {
  constructor(props, context) {
    super(props, context);

    const widgetIds       = R.propOr([], 'widgetIds', props),
          id              = R.propOr(0,  'id',        props),
          positionPlusOne = widgetIds.indexOf(id) + 1;

    this.state = { position : positionPlusOne };
  }

  render() {
    const {
      position
    } = this.state;

    const {
      open,
      onClose,
      onCancel,
      onConfirm,
      editingDashboard,
      id,
      widgetIds
    } = this.props;

    return (
      <div>
        <Dialog
          open            = {open}
          onClose         = {onClose}
          aria-labelledby = "form-dialog-title">
          <DialogTitle id="form-dialog-title">Move Widget To A New Position</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps = {{ shrink : true }}
              label           = {`new position from ${widgetIds.indexOf(id)+1} to (1 - ${widgetIds.length})`}
              value           = {position}
              margin          = "normal"
              name            = "position"
              id              = "position"
              onChange        = {e => {
                const positionPlusOne = parseInt(e.target.value, 10);
                if (!isNaN(positionPlusOne)) {
                  if (positionPlusOne >= 1 && positionPlusOne <= widgetIds.length) {
                    this.setState({ position : positionPlusOne });
                  }
                } else {
                  this.setState({ position : '' });
                }
              }}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent : 'space-between' }}>
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            {
              editingDashboard ? (
                <Button onClick={this.confirmDelete} color="secondary">
                  Delete
                </Button>
              ) : null
            }
            <Button
              onClick = {
                () => {
                  if (this.state.position) {
                    R.compose(
                      onConfirm,
                      R.objOf('widgetIds'),
                      R.insert(this.state.position - 1, id),
                      R.without([id])
                    )(widgetIds);
                  }
                }
              }
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default MoveWidgetDialog;
