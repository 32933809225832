import React      from 'react';
import PropTypes  from 'prop-types';
import styled     from 'styled-components';
import * as R     from 'ramda';

import { Paper }                         from '@material-ui/core';
import EditIcon                          from '@material-ui/icons/ModeEdit';
import CopyIcon                          from '@material-ui/icons/ContentCopy';
import DeleteIcon                        from '@material-ui/icons/Delete';
import OpenWith                          from '@material-ui/icons/OpenWith';
import AddConditionalItem                from './AddConditionalItem';
import getPairedQuestion                 from '../../helpers/getPairedQuestion';
import { ITEM_TYPES }                    from '../../BuilderReducer';

const Wrapper = styled(props => <Paper {...R.omit(['isConditional'], props)} />)`
  display          : flex;
  justify-content  : space-between;
  align-items      : center;
  font-size        : .875rem;
  margin           : .5rem 0;
  margin-left      : ${props => props.isConditional ? props.level * 2.5 : props.level * 1.5}rem;
  padding          : 0 .5rem;
  border-left      : .625rem solid ${props => props.isConditional ? '#42a5cb' : 'rgba(57, 133, 89, 0.7)'};
  box-sizing       : border-box;
  background-color : ${props => props.selected ? `rgba(243, 33, 46, .25) !important` : `inherit`};
`;

const Label = styled.p`
  margin       : 0;
  font-size    : .875rem;
  font-weight  : 500;
  cursor       : default;
`;

const Title = styled.p`
  margin       : .25rem .5rem;
  align-self   : center;
  margin-right : auto;
  cursor       : default;
`;

const SectionActions = styled.div`
  display      : flex;
  align-items  : center;
  margin-right : .5rem;

  svg {
    margin-left : .25em;
    font-size   : 1.25rem;
    cursor      : pointer;
  }
`;

const AnswerTitle = styled.p`
  margin-left   : ${props => props.level * 1.5}rem;
  font-style    : oblique;
  color         : #767676;
  font-weight   : 100;
  margin-top    : .5rem;
  margin-bottom : .5rem;
`;

const renderTitle = (commonId, isConditional, questionType, forkFromNumericConditional) => {
  if (commonId) {
    return 'COMMON QUESTION';
  }
  if (isConditional) {
    return isConditional && questionType === ITEM_TYPES.NUMERIC_CONDITIONAL ? 'NUMERIC CONDITIONAL' : 'CONDITIONAL';
  } else {
    return forkFromNumericConditional ? 'NUMERIC CONDITIONAL QUESTION' : 'QUESTION';
  }
};

const QuestionItem = ({
  title,
  meta: {
    isConditional,
    forkFromNumericConditional,
  },
  onCopy,
  onFork,
  onEdit,
  onDelete,
  conditionalItems,
  options,
  openForkQuestionModal,
  openQuestionModal,
  copyForkQuestion,
  deleteForkQuestion,
  sectionId,
  sectionGroup,
  level,
  questionUuid,
  hasNesting,
  commonId,
  answerTitle,
  onSelect,
  forkedOnSelect,
  selectedFlattenItems,
  appendDocumentIds,
  documentIds,
  question
}) => (
  <div>
    { documentIds.forEach(d => appendDocumentIds({ id: d, questionUuid, sectionId, sectionGroup, question})) }
    {answerTitle && <AnswerTitle level={level}>{`Answer "${answerTitle}"`}</AnswerTitle>}
    <Wrapper
      isConditional={hasNesting ? false : isConditional}
      level={level}
      selected={R.contains(questionUuid, selectedFlattenItems)}
    >
      <Label>{renderTitle(commonId, isConditional, question.type, forkFromNumericConditional)}</Label>
      <Title>{title}</Title>
      {
        !forkFromNumericConditional &&
          <SectionActions>
            <CopyIcon
              onClick={onCopy}
            />
            <OpenWith
              onClick={onSelect}
            />
            {isConditional && (
              question.type === ITEM_TYPES.NUMERIC_CONDITIONAL 
              ? <AddConditionalItem
                  items={[
                    { type: ITEM_TYPES.SINGLE_LINE , name: 'Single Line' },
                    { type: ITEM_TYPES.MULTI_LINE  , name: 'Multi Line' },
                    { type: ITEM_TYPES.PHOTO       , name: 'Photo' }
                  ]}
                  conditionItems={conditionalItems}
                  onAdd={onFork}
                />
              : <AddConditionalItem
                  conditionItems={conditionalItems}
                  onAdd={onFork}
                />
            )}
            <EditIcon
              onClick={onEdit}
            />
            <DeleteIcon
              onClick={onDelete}
            />
          </SectionActions>
      }

    </Wrapper>
    {R.flatten(options.map(option => option.conditionalFork.map((forkedQuestion, i) => (
      <QuestionItem
        level={R.inc(level)}
        key={forkedQuestion.uuid}
        title={forkedQuestion.title}
        meta={forkedQuestion.meta}
        documentIds={forkedQuestion.documentIds}
        hasNesting={forkedQuestion.options.some(option => R.length(option.conditionalFork) > 0)}
        conditionalItems={forkedQuestion.options}
        onFork={openForkQuestionModal.bind(null, sectionGroup, sectionId, forkedQuestion.uuid)}
        onEdit={openForkQuestionModal.bind(null, sectionGroup, sectionId, questionUuid, option, {
          ...forkedQuestion,
          pairedQuestion: getPairedQuestion(forkedQuestion, option.conditionalFork)
        }, true)}
        onCopy={copyForkQuestion.bind(null, sectionGroup, sectionId, questionUuid, option.uuid, forkedQuestion.uuid)}
        onDelete={deleteForkQuestion.bind(null, sectionGroup, sectionId, questionUuid, option.uuid, forkedQuestion.uuid)}
        options={forkedQuestion.options}
        openForkQuestionModal={openForkQuestionModal}
        openQuestionModal={openQuestionModal}
        copyForkQuestion={copyForkQuestion}
        sectionId={sectionId}
        questionUuid={forkedQuestion.uuid}
        sectionGroup={sectionGroup}
        deleteForkQuestion={deleteForkQuestion}
        answerTitle={i === 0 && option.label}
        appendDocumentIds={appendDocumentIds}
        question={question}
        onSelect={forkedOnSelect.bind(null,
          sectionGroup,
          sectionId,
          option.conditionalFork[i],
          true,
          questionUuid,
          option.uuid,
          forkedQuestion.uuid
          )
        }
        forkedOnSelect={forkedOnSelect}
        selectedFlattenItems={selectedFlattenItems}
      />
    ))))}
  </div>
);

QuestionItem.propTypes = {
  selectedFlattenItems : PropTypes.array,
  options              : PropTypes.array,
  title                : PropTypes.string.isRequired,
  meta                 : PropTypes.shape({
    isConditional : PropTypes.bool,
    forkFromNumericConditional: PropTypes.bool,
  }),
  children             : PropTypes.node,
  onCopy               : PropTypes.func.isRequired,
  onFork               : PropTypes.func,
  onEdit               : PropTypes.func.isRequired,
  onDelete             : PropTypes.func.isRequired,
  hasNesting           : PropTypes.bool,
  questionUuid         : PropTypes.string,
  appendDocumentIds    : PropTypes.func,
  documentIds          : PropTypes.array,
  question             : PropTypes.object,
};

QuestionItem.defaultProps = {
  selectedFlattenItems : [],
  options              : [],
  onFork               : () => {},
  hasNesting           : false,
  children             : null,
  level                : 1,
  meta                 : {
    isConditional : false,
    forkFromNumericConditional: false,
  },
  questionUuid         : '',
  appendDocumentIds    : () => {},
  documentIds          : [],
};

export default QuestionItem;
