import React     from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';

import Modal from '../../components/Modal/Modal';

const DownloadZipProgressModal = ({ zipLink, zipError, onApprove }) => (
  <Modal
    onCancel       = {() => {}}
    onApprove      = {onApprove}
    withoutCancel  = {true}
    withoutApprove = {!zipError}
  >

    {
      zipError ? (
        <div>
          <p style={{ color : 'red' }}>Could not zip files</p>
          <em>Errors have been logged on the server. Please notify support if this persists.</em>
        </div>
      ) : (
        <div>
          {
            !zipLink ? <p>Files zipping. Please do not close this window, this may take several minutes.</p> : null
          }
          <div>
            <Button
              variant="flat"
              style={{
                border          : '2px solid #DE898C',
                color           : '#F3212E',
                backgroundColor : 'transparent',
                borderRadius    : '5px',
                padding         : '8px',
                minWidth        : '120px',
                letterSpacing   : '1px',
                opacity         : zipLink ? '1' : '0.5'
              }}
              disabled ={!zipLink}
              onClick  = {() => {
                window.open(zipLink);
                onApprove();
              }}
            >
              { zipLink ? 'Download Zip' : 'Zipping...' }
            </Button>
            {!zipLink && (
              <CircularProgress
                size={24}
                style={{
                  position  : 'absolute',
                  left      : 'calc(50% - 1rem)',
                  marginTop : '.5rem'
                }}
              />
            )}
          </div>
        </div>
      )
    }

  </Modal>
);

DownloadZipProgressModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  zipLink   : PropTypes.string,
  zipError  : PropTypes.string
};

export default DownloadZipProgressModal;
