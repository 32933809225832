import moment from 'moment';

const ALLOWABLE_MINUTES_MAX = 120;

export const checkOutLate = (actualCheckOut, expectedCheckIn, expectedDurationMinutes) => {

  if (actualCheckOut && expectedCheckIn && expectedDurationMinutes) {

    const expectedCheckout = moment(expectedCheckIn).add(expectedDurationMinutes, 'minute');
    const diff             = moment(actualCheckOut).diff(expectedCheckout, 'minute');

    if (diff > ALLOWABLE_MINUTES_MAX) {
      return `${diff - ALLOWABLE_MINUTES_MAX} minutes later than allowed`;
    }

    if (diff < ALLOWABLE_MINUTES_MAX * -1) {
      return `${diff - ALLOWABLE_MINUTES_MAX} minutes earlier than allowed`;
    }

  }

  return '';
};

export const checkInLate = (actualCheckIn, expectedCheckIn) => {

  if (actualCheckIn && expectedCheckIn) {

    const diff = moment(actualCheckIn).diff(expectedCheckIn, 'minute');

    if (diff > ALLOWABLE_MINUTES_MAX) {
      return `${diff - ALLOWABLE_MINUTES_MAX} minutes later than allowed`;
    }

    if (diff < ALLOWABLE_MINUTES_MAX * -1) {
      return `${diff - ALLOWABLE_MINUTES_MAX} minutes earlier than allowed`;
    }

  }

  return '';
};

