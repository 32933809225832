import * as R from 'ramda';
import uuid   from 'uuid/v4';
import { history } from '../../store';

import {
  createSvr,
  updateSvr,
  fetchSvrs,
  createDraftSvr,
  updateDraftSvr,
  deleteSvr,
  createSvrForm
} from './SvrActions';

import {
  getSvr,
} from './SvrReducer';

import {
  getUserId,
  getEmail
} from '../auth/AuthReducer';

export const UPDATE_TITLE                         = `SVR_BUILDER/UPDATE_TITLE`;
export const UPDATE_AUTO_APPROVE                  = `SVR_BUILDER/UPDATE_AUTO_APPROVE`;
export const UPDATE_HIDE_FROM_CLIENT              = `SVR_BUILDER/UPDATE_HIDE_FROM_CLIENT`;
export const UPDATE_INVOICE_PLANNED_TIME          = `SVR_BUILDER/UPDATE_INVOICE_PLANNED_TIME`;
export const UPDATE_BYPASS_GPS                    = `SVR_BUILDER/UPDATE_BYPASS_GPS`;
export const UPDATE_BYPASS_DUPLICATE_PHOTO_CHECK  = `SVR_BUILDER/UPDATE_BYPASS_DUPLICATE_PHOTO_CHECK`;
export const UPDATE_MAX_ALLOWED_TIME              = `SVR_BUILDER/UPDATE_MAX_ALLOWED_TIME`;
export const UPDATE_HERO_IMAGE                    = `SVR_BUILDER/UPDATE_HERO_IMAGE`;
export const UPDATE_CLIENT                        = `SVR_BUILDER/UPDATE_CLIENT`;
export const UPDATE_CALLOUT                       = `SVR_BUILDER/UPDATE_CALLOUT`;
export const ADD_SECTION                          = `SVR_BUILDER/ADD_SECTION`;
export const EDIT_SECTION                         = `SVR_BUILDER/EDIT_SECTION`;
export const COPY_SECTION                         = `SVR_BUILDER/COPY_SECTION`;
export const DELETE_SECTION                       = `SVR_BUILDER/DELETE_SECTION`;
export const CHANGE_NODE_SECTION                  = `SVR_BUILDER/CHANGE_NODE_SECTION`;
export const ADD_QUESTION                         = `SVR_BUILDER/ADD_QUESTION`;
export const EDIT_QUESTION                        = `SVR_BUILDER/EDIT_QUESTION`;
export const COPY_QUESTION                        = `SVR_BUILDER/COPY_QUESTION`;
export const DELETE_QUESTION                      = `SVR_BUILDER/DELETE_QUESTION`;
export const CHANGE_NODE_QUESTION                 = `SVR_BUILDER/CHANGE_NODE_QUESTION`;
export const LOAD                                 = `SVR_BUILDER/LOAD`;
export const RESET                                = `SVR_BUILDER/RESET`;
export const ADD_FORK_QUESTION                    = `SVR_BUILDER/ADD_FORK_QUESTION`;
export const DELETE_FORK_QUESTION                 = `SVR_BUILDER/DELETE_FORK_QUESTION`;
export const COPY_FORK_QUESTION                   = `SVR_BUILDER/COPY_FORK_QUESTION`;
export const EDIT_FORK_QUESTION                   = `SVR_BUILDER/EDIT_FORK_QUESTION`;
export const ADD_GROUP_QUESTION                   = `SVR_BUILDER/ADD_GROUP_QUESTION`;
export const EDIT_GROUP_QUESTION                  = `SVR_BUILDER/EDIT_GROUP_QUESTION`;
export const COPY_GROUP_QUESTION                  = `SVR_BUILDER/COPY_GROUP_QUESTION`;
export const DELETE_GROUP_QUESTION                = `SVR_BUILDER/DELETE_GROUP_QUESTION`;
export const CHANGE_NODE_GROUP_QUESTION           = `SVR_BUILDER/CHANGE_NODE_GROUP_QUESTION`;
export const SVR_SAVED_NOTIFICATION               = `SVR_BUILDER/SVR_SAVED_NOTIFICATION`;
export const LAST_SAVED_SVR                       = `SVR_BUILDER/LAST_SAVED_SVR`;
export const IS_DRAFT                             = `SVR_BUILDER/IS_DRAFT`;
export const RESET_SEARCHED_TAGS                  = `SVR_BUILDER/RESET_SEARCHED_TAGS`;
export const FETCH_TAGS_BY_STRING                 = `SVR_BUILDER/FETCH_TAGS_BY_STRING`;
export const ADD_TAG                              = `SVR_BUILDER/ADD_TAG`;

export const updateTitle = payload => ({
  type : UPDATE_TITLE,
  payload
});

export const updateHeroImage = payload => ({
  type : UPDATE_HERO_IMAGE,
  payload
});

export const updateAutoApprove = payload => ({
  type : UPDATE_AUTO_APPROVE,
  payload
});

export const updateHideFromClient = payload => ({
  type : UPDATE_HIDE_FROM_CLIENT,
  payload
});

export const updateInvoicePlannedTime = payload => ({
  type : UPDATE_INVOICE_PLANNED_TIME,
  payload
});

export const updateMaxAllowedTime = payload => ({
  type : UPDATE_MAX_ALLOWED_TIME,
  payload
});

export const updateBypassGPS = payload => ({
  type : UPDATE_BYPASS_GPS,
  payload
});

export const updateBypassDuplicatePhotoCheck = payload => ({
  type : UPDATE_BYPASS_DUPLICATE_PHOTO_CHECK,
  payload
});

export const updateClient = payload => ({
  type : UPDATE_CLIENT,
  payload
});

export const updateCallout = payload => ({
  type : UPDATE_CALLOUT,
  payload
});

/* Section */
export const saveSection = (sectionGroup, payload) => {
  if (payload.uuid) {
    return editSection(sectionGroup, payload);
  }

  return addSection(sectionGroup, { ...payload, uuid : uuid() });
};

export const addSection = (sectionGroup, payload) => ({
  type : ADD_SECTION,
  sectionGroup,
  payload
});

export const editSection = (sectionGroup, payload) => ({
  type : EDIT_SECTION,
  sectionGroup,
  payload
});

export const copySection = (sectionGroup, payload) => ({
  type : COPY_SECTION,
  sectionGroup,
  payload
});

export const deleteSection = (sectionGroup, payload) => ({
  type : DELETE_SECTION,
  sectionGroup,
  payload
});

export const changeNodeSection = (sectionGroup, sectionPosition, payload) => ({
  type : CHANGE_NODE_SECTION,
  sectionGroup,
  sectionPosition,
  payload
});

/* Question */
export const saveQuestion = (sectionGroup, section, payload) => {
  if (payload.uuid) {
    return editQuestion(sectionGroup, section, payload);
  }

  return addQuestion(sectionGroup, section, { ...payload, uuid : payload.canonicalKey });
};

export const addQuestion = (sectionGroup, section, payload) => ({
  type : ADD_QUESTION,
  sectionGroup,
  section,
  payload
});

export const editQuestion = (sectionGroup, section, payload) => ({
  type : EDIT_QUESTION,
  sectionGroup,
  section,
  payload
});

export const copyQuestion = (sectionGroup, section, payload) => ({
  type : COPY_QUESTION,
  sectionGroup,
  section,
  payload
});

export const deleteQuestion = (sectionGroup, section, payload) => ({
  type : DELETE_QUESTION,
  sectionGroup,
  section,
  payload
});

export const  changeNodeQuestion = (sectionGroup, section, question, payload) => ({
  type : CHANGE_NODE_QUESTION,
  sectionGroup,
  section,
  question,
  payload
});

export const changeNodeQ = (sectionGroup, section, question, payload) => dispatch => {
  if (R.prop('isForked', payload)) {
    dispatch(deleteForkQuestion(sectionGroup, section, payload.forkedQuestion, payload.forkedAnswer, payload.forkedPayload));
  }

  dispatch(changeNodeQuestion(
    sectionGroup,
    section,
    question,
    R.omit(['isForked', 'forkedQuestion', 'forkedAnswer', 'forkedPayload'], payload)
  ));
};

/* Question Group */
export const saveGroupQuestion = (sectionGroup, section, questionGroup, payload) => {
  if (payload.uuid) {
    return editGroupQuestion(sectionGroup, section, questionGroup, payload);
  }

  return addGroupQuestion(sectionGroup, section, questionGroup, { ...payload, uuid : payload.canonicalKey });
};

export const addGroupQuestion = (sectionGroup, section, questionGroup, payload) => ({
  type : ADD_GROUP_QUESTION,
  sectionGroup,
  section,
  questionGroup,
  payload
});

export const editGroupQuestion = (sectionGroup, section, questionGroup, payload) => ({
  type : EDIT_GROUP_QUESTION,
  sectionGroup,
  section,
  questionGroup,
  payload
});

export const copyGroupQuestion = (sectionGroup, section, questionGroup, payload) => ({
  type : COPY_GROUP_QUESTION,
  sectionGroup,
  section,
  questionGroup,
  payload
});

export const deleteGroupQuestion = (sectionGroup, section, questionGroup, payload) => ({
  type : DELETE_GROUP_QUESTION,
  sectionGroup,
  section,
  questionGroup,
  payload
});

export const changeNodeGroupQuestion = (sectionGroup, section, payload) => ({
  type : CHANGE_NODE_GROUP_QUESTION,
  sectionGroup,
  section,
  payload
});

export const saveForkQuestion = (sectionGroup, section, question, forkedAnswer, payload, isEditForkQuestion) => {
  if (isEditForkQuestion) {
    return editForkQuestion(sectionGroup, section, question, forkedAnswer, payload);
  }
  let newPayload = { ...payload, uuid : payload.canonicalKey };

  if (Array.isArray(payload)) {
    newPayload = payload;
  }

  return addForkQuestion(sectionGroup, section, question, forkedAnswer, newPayload);
};

export const addForkQuestion = (sectionGroup, section, question, forkedAnswer, payload) => ({
  type : ADD_FORK_QUESTION,
  sectionGroup,
  section,
  question,
  forkedAnswer,
  payload
});

export const editForkQuestion = (sectionGroup, section, question, forkedAnswer, payload) => ({
  type : EDIT_FORK_QUESTION,
  sectionGroup,
  section,
  question,
  forkedAnswer,
  payload
});

export const copyForkQuestion = (sectionGroup, section, question, forkedAnswer, payload) => ({
  type : COPY_FORK_QUESTION,
  sectionGroup,
  section,
  question,
  forkedAnswer,
  payload
});

export const deleteForkQuestion = (sectionGroup, section, question, forkedAnswer, payload) => ({
  type : DELETE_FORK_QUESTION,
  sectionGroup,
  section,
  question,
  forkedAnswer,
  payload
});

export const loadSvr = (namespace, svrId) => (dispatch, getState) => {
  const state = getState();
  const svr   = getSvr(state, namespace, svrId);

  if (svr === null) {
    history.push({ pathname: '/svrs/create' });
    return false;
  }

  const svrFromLocalStorage = JSON.parse(localStorage.getItem(svr.id));

  if(svrFromLocalStorage && svrFromLocalStorage.timestamp > svr.timestamp) {
    dispatch({
      type    : LOAD,
      payload : svrFromLocalStorage,
    });
  }
  else {
    dispatch({
      type    : LOAD,
      payload : svr,
    });
  }
};

export const isSaving = (message, error, cb) => dispatch => {
  dispatch({
    type    : SVR_SAVED_NOTIFICATION,
    payload : {
      status : true,
      message,
      error
    }
  });

  setTimeout(() => {
    dispatch({
      type    : SVR_SAVED_NOTIFICATION,
      payload : {
        status  : false,
        message : ''
      }
    });

    if (R.is(Function, cb)) {
      cb();
    }

  }, 2000);
};

export const lastSavedSvr = payload => ({
  type: LAST_SAVED_SVR,
  payload
});

export const isDraftAction = payload => ({
  type: IS_DRAFT,
  payload
});

const didSucceed = R.pathEq(['payload', 'status'], 200);

const onCreateSvrResponse = R.curry(
  (dispatch, res) => {
    if (didSucceed(res)){
      const canonicalKey = R.pathOr(null, ['payload', 'data', 'data', 'canonicalKey'])(res);
      dispatch(lastSavedSvr(R.path(['payload', 'data', 'data'])(res)));
      dispatch(isSaving('SVR published successfully.', null, () => history.push({ pathname: `/svrs/${canonicalKey}` })));
    } else if (res.error) {
      dispatch(isSaving('Could not Save.', true));
    }
  }
);

const onUpdateDraftSvrResponse = R.curry(
  (namespace, dispatch, res) => {
    if (didSucceed(res)){
      dispatch(lastSavedSvr(R.path(['payload', 'data', 'data'])(res)));
      (dispatch(isSaving('Draft updated successfully.', null), dispatch(fetchSvrs(namespace))));
    } else if (res.error) {
      dispatch(isSaving('Could not Edit.', true));
    }
  }
);


export const saveSvrForm = (namespace, payload)  => {
  return (dispatch, getState) => {
    const state      = getState(),
          userId     = getUserId(state),
          userEmail  = getEmail(state);

    if(!R.has('id', payload)) {
      payload.status = 3; //starts as a Draft
      return dispatch(createSvrForm(payload, { userId, userEmail }))
        .then(onCreateSvrResponse(dispatch));
    }

    if(payload.status === 3){
      return dispatch(updateDraftSvr(payload.id, payload, userEmail))
        .then(onUpdateDraftSvrResponse(namespace, dispatch));
    }

    dispatch(updateSvr(payload, { userId, userEmail }))
      .then(res => {
        if(didSucceed(res)){
          dispatch(lastSavedSvr(res.payload.data.data));
          dispatch(isSaving('SVR published successfully.', null, () => history.push({ pathname: `/svrs/${payload.canonicalKey}` })));
        }
        else if (res.error) {
          dispatch(isSaving('Could not Save.', true));
        }
      });

  };
};

export const saveSvr = (_, payload)  => {
  return (dispatch, getState) => {
    const state      = getState(),
          userId     = getUserId(state),
          userEmail  = getEmail(state);

    if(R.has('id', payload)) {
      // if draft then publish the form with status 1
      if (payload.status === 3) {
        payload.status = 1;
      }

      dispatch(updateSvr(payload, { userId, userEmail }))
        .then(res => {
          if(didSucceed(res)){
            dispatch(lastSavedSvr(res.payload.data.data));
            dispatch(deleteSvr(payload.id));
            dispatch(isSaving('SVR published successfully.', null, () => history.push({ pathname: `/svrs/${payload.canonicalKey}` })));
          }
          else if (res.error) {
            dispatch(isSaving('Could not Save.', true));
          }
        });
    } else {
      dispatch(createSvr(payload, { userId, userEmail }))
        .then(onCreateSvrResponse(dispatch));
    }
  };
};


export const saveDraftSvr = (namespace, payload)  => {
  return (dispatch, getState) => {
    const state      = getState(),
          userId     = getUserId(state),
          userEmail  = getEmail(state);

    // if status === 1 then create a draft and update the status to 3
    // if status === 0 then update the draft
    if (payload.status === 1) {
      payload.status = 3;
      dispatch(createDraftSvr(payload, { userId, userEmail }))
        .then(res => {
          if(didSucceed(res)) {
            dispatch(lastSavedSvr(res.payload.data.data));
            dispatch(isSaving('Draft created successfully.', null, () => history.push({ pathname: `/svrs/${payload.canonicalKey}` })));
          }
          else if (res.error) {
            dispatch(isSaving('Could not create the Draft.', true));
          }
        });
    } else {
      dispatch(updateDraftSvr(payload.id, payload, userEmail))
        .then(onUpdateDraftSvrResponse(namespace, dispatch));
    }
  };
};

export const resetSvrForm = () => ({
  type : RESET
});

export const addTag = payload => ({
  type    : ADD_TAG,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/tag',
      data   : {
        title : payload
      }
    }
  }
});

export const fetchTagsByStr = payload => ({
  type    : FETCH_TAGS_BY_STRING,
  payload : {
    request : {
      method : 'GET',
      url    : `/api/v2/tag/substr/${payload}`
    }
  }
});

export const resetSearchedTags = () => ({
  type : RESET_SEARCHED_TAGS
});
