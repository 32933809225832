import * as R      from 'ramda';
import React       from 'react';
import { connect } from 'react-redux';
import styled      from 'styled-components';
import AddIcon     from '@material-ui/icons/AddCircleOutline';

import { updateHeroImage as _updateHeroImage } from '../../BuilderActions';

import Uploader from '../../../upload/components/Uploader';

const Wrapper = styled(Uploader)`
  margin-top : 20px;
  cursor          : pointer;
`;

const HeroImage = styled.div`
  width             : 150px;
  height            : 150px;
  background-image  : url(${({ src }) => src });
  background-size   : contain;
  background-repeat : no-repeat; 
`;


const HeroImageUploader = ({ updateHeroImage, heroImageUrl }) => (
  <Wrapper
    onUpload={R.T}
    handleFile={updateHeroImage}
  >
    {
     R.isEmpty(heroImageUrl) ? (
       <AddIcon/>
     ) : (
       <HeroImage src={heroImageUrl} />
     )
    }
  </Wrapper>
);

const mapDispatchToProps = dispatch => ({
 updateHeroImage : payload => dispatch(_updateHeroImage(payload))
});

export default connect(null, mapDispatchToProps)(HeroImageUploader);
