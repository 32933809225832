import React, { Component }     from 'react';
import styled    from 'styled-components';
import * as R    from 'ramda';
import * as moment    from 'moment';

import { TableRow, Typography, TableHead, TableCell, Table, TableBody } from '@material-ui/core';

import Modal from './components/Modal';

import { maybeDecorateColumn } from '../../../../utils/tableColumns';

const Container = styled.div`
  width        : 100%;
  padding-left : 20px;

  p {
    font-size   : 13px;
    font-weight : bold;
    color       : #5E5D5F;
  }
`;

const SelectContainer = styled.div`
  margin : 1rem 2rem 2rem 2rem;

  p {
    font-size   : .875rem;
    font-weight : 500;
  }
`;

const IMMEDIATE_NOTIF = {
  value: 0,
  label: 'IMMEDIATE'
};
const SCHEDULED_NOTIF = {
  value: 1,
  label: 'SCHEDULED'
};

const NOTIFICATION_TYPES = [
  IMMEDIATE_NOTIF,
  SCHEDULED_NOTIF
];

const NOTIF_SENT_STATUS = [{
  label: 'No',
  toolTip: ''
}, {
  label: 'Yes',
  toolTip: 'This notification has been sent already'
}, {
  label: 'Cancelled',
  toolTip: 'This notification has been canceled'
}];

const HEIGHT = 50;

const tableColumnsDefinition =  {
  mcid: {
    path:       ['mcid'],
    label:      'MCID'
  },
  name: {
    path:       ['name'],
    label:      'Name'
  },
  date_seen: {
    path:       ['date_seen'],
    label:       'Date Seen',
    decorator:  v => v ? moment(v.replace(/z/i, '')).format('M-D-YYYY h:mm A') : 'NA'
  },
  date_scheduled: {
    path:       ['config', 'scheduled', 'date'],
    label:       'Scheduled Date and Time',
    decorator:  v => v ? moment(v.replace(/z/i, '')).format('M-D-YYYY h:mm A') : 'NA'
  }
};

class NotificationModal extends Component {
  state = {
  }

  render () {
    const { onSend, onCancel, notification, onCancelNotification } = this.props;

    const notificationType = R.find(R.propEq('value', R.prop('type', notification)), NOTIFICATION_TYPES);
    const isScheduled = SCHEDULED_NOTIF.value === R.prop('type', notificationType);
    const sentStatus = R.compose(s => R.nth(s, NOTIF_SENT_STATUS),  R.prop('sent'))(notification);

    const tableColumns    = R.values(tableColumnsDefinition);
    const dateScheduled   = R.prop('date_scheduled', tableColumnsDefinition);

    const notificationId  = R.prop('id', notification);
    
    return (
      <Modal
        onSend                    = {() => onSend(notificationId)}
        onCancel                  = {onCancel}
        onCancelText              = {'Close'}
        onCancelNotification      = {() => onCancelNotification(notificationId)}
        toolTip                   = {R.prop('toolTip', sentStatus)}
      >
        <Container>
          <h2>{R.propOr('NA', 'title', notification)}</h2>

          <SelectContainer>
            <Typography
              variant="subheading"
            >
              {`"${R.propOr('NA', 'message', notification)}"`}
            </Typography>
          </SelectContainer>
          <SelectContainer>
            <p>Sent</p>
            <Typography
              variant="subheading"
            >
              {R.prop('label', sentStatus)}
            </Typography>
          </SelectContainer>
          <SelectContainer>
            <p>Notification Type</p>
            <Typography
              variant="subheading"
            >
              {R.propOr('NA', 'label', notificationType)}
            </Typography>
          </SelectContainer>
          {
            isScheduled && (
              <SelectContainer>
                <p>Scheduled Date and Time</p>
                <Typography
                  variant="subheading"
                >
                  {
                    maybeDecorateColumn(R.prop('decorator', dateScheduled))(R.path(R.prop('path', dateScheduled), notification))
                  }
                </Typography>
              </SelectContainer>
            )
          }
          <SelectContainer>
            <p>Recipients</p>
            <div style={{overflow: 'scroll', maxHeight: 150}}>
              <Table>
                <TableHead>
                  <TableRow>
                  {
                    R.map(({ path, label }) => {
                      return (
                        <TableCell
                          key     = {path}
                          padding = "dense"
                          numeric = {false}
                          type    = "body"
                        >{label}</TableCell>
                      );})(tableColumns)
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {R.defaultTo([], R.prop('users', notification)).map(user => {

                    return (
                      <TableRow
                        hover
                        key          = {R.prop('mcid', user)}
                        style        = {{ height: `${HEIGHT}px`, cursor: 'pointer', whiteSpace: 'nowrap' }}
                      >
                        {
                          R.map(({ path, decorator }) => {
                            return (
                              <TableCell
                                key     = {path}
                                padding = "dense"
                                numeric = {false}
                                type    = "body"
                              >{maybeDecorateColumn(decorator)(R.path(path, user))}</TableCell>
                          );})(tableColumns)
                        }
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </SelectContainer>
        </Container>
      </Modal>
    );
  }
}

export default NotificationModal;
