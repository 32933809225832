import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import QuestionItem from './QuestionItem';


const Wrapper = styled.div`
  padding-left     : 20px;
  height           : 30px;
  border-bottom    : 2px solid #EEEEEE;
  display          : flex;
  background-color : #F4F4F4;
  align-items      : center;
  color            : #909091;
  font-size        : 13px;
  font-weight      : bold;
`;

const QuestionGroup = ({
  title,
  content,
  isUuid,
  iUuid
}) => (
  <div>
    {
      isUuid ?
        <Wrapper>{<><span>{`Question Group: ${title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${iUuid}`}</span> </>}</Wrapper>
        :
        <Wrapper>{<><span>{`Question Group: ${title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`${iUuid}`}</span> </>}</Wrapper>
    }
    {
      isUuid ?
        content.map(q => (
          <QuestionItem
            key={q.uuid}
            title={<><span>{q.title}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${q.uuid}`}</span> </>}
            level={1}
          />
        ))
        :
        content.map(q => (
          <QuestionItem
            key={q.uuid}
            title={`${q.title}`}
            level={1}
          />
        ))
    }
  </div>
);

QuestionGroup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.array,
  isUuid: PropTypes.bool,
  iUuid: PropTypes.string
};

QuestionGroup.defaultProps = {
  title: '',
  content: [],
  isUuid: false,
  iUuid: ''
};

export default QuestionGroup;

