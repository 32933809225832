import React      from 'react';
import styled     from 'styled-components';
import { Button, Hidden } from '@material-ui/core';

const Toolbar = styled.div`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 10px;
  padding         : 0 30px;
`;

const SavedRollupsListToolbar = ({ onClick }) => (
  <Hidden only={['xs', 'sm']}>
    <Toolbar>
      <Button
        onClick={onClick}
        style={{
          border          : '2px solid #DE898C',
          color           : '#F3212E',
          backgroundColor : 'transparent',
          borderRadius    : '5px',
          padding         : '8px',
          minWidth        : '120px',
          letterSpacing   : '1px',
        }}
      >
        Create New
      </Button>
    </Toolbar>
  </Hidden>
);

export default SavedRollupsListToolbar;
