import * as R from 'ramda';

const isBadUserId = R.compose(R.identical(NaN), R.prop('restrictTokenUserId'));
const maybeParseId = R.unless(R.isNil, R.partialRight(parseInt, [10]));

const ensureNumericTokenUserId = R.compose(
  R.when(isBadUserId, R.dissoc('restrictTokenUserId')),
  R.over(R.lensProp('restrictTokenUserId'), maybeParseId)
);

export default ensureNumericTokenUserId;
