import React         from 'react';
import styled        from 'styled-components';
import {
  compose,
  withStateHandlers,
  withHandlers,
  lifecycle
}                    from 'recompose';
import * as R        from 'ramda';

import ArrowBackIcon    from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon  from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const Toolbar = styled.div`
  display         : flex;
  justify-content : space-between;
  margin-top      : 30px;
  margin-bottom   : 10px;
  padding         : 0 30px;
`;

const BackButtonWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  cursor         : pointer;
`;

const BackButtonTitle = styled.p`
  margin-left : 15px;
  font-size   : 15px;
  font-weight : 500;
  color       : #CE343E;
`;

const ViewWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;

  & svg {
    cursor       : pointer;
    margin-right : 15px;
  }
`;

const ViewTitle = styled.p`
  color        : #8F9090;
  margin-right : 15px;
  font-size    : 13px;
`;

const BackButton = ({ onClick }) => (
  <BackButtonWrapper onClick={onClick}>
    <ArrowBackIcon />
    <BackButtonTitle>BACK TO NOTIFICATIONS</BackButtonTitle>
  </BackButtonWrapper>
);

const NotificationDetailToolbar = ({
  history,
  handleNextArrowClick,
  handlePrevArrowClick,
  hasPrev,
  hasNext,
  navIndex
}) => (
  <div style={{ overflow : 'hidden', paddingBottom : '2rem', marginBottom : '-0.875rem' }}>
    <Toolbar className='no-print'>
      <BackButton onClick={() => {
        if (
          document.referrer.indexOf(window.location.host) !== -1) {
          history.go(navIndex);
        } else {
          history.push(`/`);
        }
      }} />
      <ViewWrapper>
        <ChevronLeftIcon
          onClick={() => hasPrev ? handlePrevArrowClick() : {}}
          nativeColor={hasPrev ? '#000' : '#D3D3D3'}
        />
        <ChevronRightIcon
          onClick={() => hasNext ? handleNextArrowClick() : {}}
          nativeColor={hasNext ? '#000' : '#D3D3D3'}
        />
        <ViewTitle>View Previous / Next</ViewTitle>
      </ViewWrapper>
    </Toolbar>
  </div>
);

const withCompose = compose(
  withStateHandlers(
    ({
      filteredData = [],
      hasNext      = false,
      hasPrev      = false,
      anchorEl     = null
    }) => ({
      filteredData,
      hasNext,
      hasPrev,
      anchorEl
    }),
    {
      setAnchorEl : () => anchorEl => ({ anchorEl }),
      onHandleChange       : (__, { navigateFilter, onFilterChange }) => ({ target }) => {
        if (navigateFilter !== target.value) {
          onFilterChange(target.value);
        }
      },
      handleNextArrowClick : ({ filteredData, navIndex }, { history, id }) => () => {
        window.localStorage.setItem("displayUnansweredSections", 'NO');
        const currentIndex = R.findIndex(R.propEq('id', id))(filteredData);
        const nextId       = R.pathOr('', [currentIndex + 1, 'id'], filteredData);
        
        if (nextId) {
          history.push(`/notification/id/${nextId}`, { ids: filteredData, navIndex: --navIndex });
        }
      },
      handlePrevArrowClick : ({ filteredData, navIndex }, { history, id }) => () => {
        window.localStorage.setItem("displayUnansweredSections", 'NO');
        const currentIndex = R.findIndex(R.propEq('id', id))(filteredData);
        const prevId = R.pathOr('', [currentIndex - 1, 'id'], filteredData);

        if (prevId) {
          history.push(`/notification/id/${prevId}`, { ids: filteredData, navIndex: --navIndex });
        }
      },
      setFilteredData      : () => (filteredData, hasNext, hasPrev, navIndex) => ({
        filteredData,
        hasNext,
        hasPrev,
        navIndex
      })

    },
  ),
  withHandlers({
    handleExportClick     : ({ setAnchorEl }) => e => { setAnchorEl(e.currentTarget); },
    handleExportMenuClose : ({ setAnchorEl }) => () => { setAnchorEl(null); }
  }),
  lifecycle({
    componentDidMount() {
      const { setFilteredData, id, currentIds, navIndex } = this.props;

      const currentIndex = R.findIndex(R.propEq('id', id))(currentIds);
      const hasNext      = currentIndex !== currentIds.length - 1;
      const hasPrev      = currentIndex >= 1;
      
      setFilteredData(currentIds, hasNext, hasPrev, navIndex);
    },
    componentDidUpdate() {
      const { setFilteredData, id, currentIds, navIndex } = this.props;

      const currentIndex = R.findIndex(R.propEq('id', id))(currentIds);
      const hasNext      = currentIndex !== currentIds.length - 1;
      const hasPrev      = currentIndex >= 1;

      setFilteredData(currentIds, hasNext, hasPrev, navIndex);
    }
  })
);

export default withCompose(NotificationDetailToolbar);
