import React, { Component } from 'react';
import * as R from 'ramda';
import { NavLink/*, Redirect */ } from 'react-router-dom';
import styled from 'styled-components';
import { Menu, MenuItem, TextField } from '@material-ui/core';
import { ArrowDropDown, Home } from '@material-ui/icons';
import Logo from '../Logo';
import Modal from '../Modal/PModal';
import { hasLocalStorage } from '../../utils/featureDetect';

const Wrapper = styled.div`
  display         : flex;
  align-items     : center;
  padding         : 0 0.5rem;
  color           : ${props => props.theme.header.color};
  background      : ${props => props.theme.main.black};
  justify-content : space-between;
  @media print    {
    display : none;
  }
`;

const StyledNavLink = styled(NavLink)`
  display         : flex;
  align-items     : center;
  font-size       : 1rem;
  margin-right    : ${({ margin }) => margin ? '1.5rem' : '0px'};
  font-weight     : lighter;
  letter-spacing  : 1px;
  color           : ${props => props.theme.header.color};
  border-bottom   : 2px solid transparent;
  border-top      : 2px solid transparent;
  text-decoration : none;
  cursor          : pointer;
  box-sizing      : border-box;
  transition      : .3s opacity;

  &:hover {
    opacity : 0.8;
  }

  &.selected {
    border-bottom : 4px solid ${props => props.theme.main.red};
  }
`;


const StyledBlock = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : flex-start;
`;

const ClientProjectMenu = styled.div`
  border          : 1px solid ${props => props.theme.header.color};
  color           : ${props => props.theme.header.color};
  border-radius   : 2rem;
  padding         : .25rem 2rem;
  text-align      : center;
  cursor          : pointer;
  justify-content : center;
  position        : relative;
`;

const ClientMenu = styled.div`
  border          : 1px solid ${props => props.theme.header.color};
  color           : ${props => props.theme.header.color};
  border-radius   : 2rem;
  padding         : .25rem 2rem;
  text-align      : center;
  cursor          : pointer;
  justify-content : center;
  position        : relative;
`;

const ProjectMenu = styled.div`
  border          : 1px solid ${props => props.theme.header.color};
  color           : ${props => props.theme.header.color};
  border-radius   : 2rem;
  padding         : .25rem 2rem;
  text-align      : center;
  cursor          : pointer;
  justify-content : center;
  position        : relative;
`;

const ClientName = styled.h4`
  margin      : 0;
  padding     : 0;
  font-weight : 500;
`;

const ProjectName = styled.h4`
  margin      : 0;
  padding     : 0;
  font-weight : 500;
`;

const ITEM_HEIGHT = 48;

const INITIAL_STATE = {
  anchorEl: null,
  focusedClientEl: null,
  focusedProjectEl: null,
  focusedClient: null,
  focusedProject: null,
  enableClientSearchDiv: false,
  enableProjectSearchDiv: false,
  statecurrentClient: "All Clients",
  statecurrentProject: "All Projects",
  stateselectedClientData: [{ projectId: null, clientId: null, projectName: 'ALL PROJECTS' }],
  stateClients: null,
  stateselectedSearchResults: null,
  stateselectedSearchClientData: null,
  //isRedirectlogout: false
  showErrorModal: false,
  errorModalTitle: "Your session has expired. Please login again."
};

const searchdiv = {
  dropdownContent: {
    position: 'absolute',
    backgroundColor: 'white',
    minWidth: '230px',
    overflow: 'auto',
    border: '1px solid #d1d1d1',
    zIndex: 1,
    top: '49px',
    left: '10px'
  },
  contenta: {
    color: '#343434',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'left',
    fontWeight: '300',
    fontSize: '1rem',
    cursor: 'pointer'
  },

  dropdown_Input: {
    padding: '15px 15px 6px 15px'
  }
};

class Header extends Component {
  state = { ...INITIAL_STATE };

  searchClientRef = React.createRef();
  searchClientProjectRef = React.createRef();

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClientSearchClick = () => {
    this.setState({ enableClientSearchDiv: !this.state.enableClientSearchDiv });
    this.setState({ enableProjectSearchDiv: false });
  };

  handleProjectSearchClick = () => {
    this.setState({ enableProjectSearchDiv: !this.state.enableProjectSearchDiv });
    this.setState({ enableClientSearchDiv: false });
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState(INITIAL_STATE);
  };

  handleClickClient = (e, focusedClient) => {
    this.setState({
      focusedClientEl: e.currentTarget,
      focusedClient
    });
  };

  handleNewClickClient = (e, focusedClient, selectedClientData) => {

    this.setState({
      focusedClientEl: e.currentTarget,
      focusedClient: selectedClientData,
      statecurrentClient: focusedClient,
      stateselectedClientData: selectedClientData,
      statecurrentProject: "All Projects",
      enableClientSearchDiv: false
    });

    window.localStorage.setItem('selectClient', focusedClient);
    window.localStorage.setItem('selectedClientdata', JSON.stringify(selectedClientData));

    this.setState({
      stateClients: [],
      stateselectedSearchResults: null
    });


    this.props.setCurrentClient(R.find(R.eqProps('clientId', {
      clientId: selectedClientData.clientId, projectId: null
      , projectName: "ALL PROJECTS"
    }))(this.props.clients));
    this.props.setCurrentProject("ALL PROJECTS");


    window.localStorage.setItem('selectProject', "ALL PROJECTS");
    this.setState({
      statecurrentProject: "ALL PROJECTS",
      enableProjectSearchDiv: false
    });
  };





  HandleSearchClient = clients => (event) => {
    var results = [];
    results = clients.filter((x) =>
      x.clientName.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      stateClients: results
    });
  };

  HandleSearchProject = userselectedclient => (event) => {

    const clientProjects = R.compose(
      R.reject(p => R.isNil(p.projectId)),
      R.propOr([], 'projects')
    )(userselectedclient);

    const results = clientProjects.filter((x) =>
      x.projectName.toLowerCase().includes(event.target.value.toLowerCase()));

    this.setState({
      stateselectedSearchResults
        : results
    });

    const project = "{\"projects\":" + JSON.stringify(results) + "}";

    const searchproject = JSON.parse(project);

    this.setState({
      stateselectedSearchClientData
        : searchproject
    });

  };

  handleVisibilityChange = (e) => {
    try {
      var isCookieust = false;
      if (document.visibilityState === 'visible') {
        console.log("visible");
        if (document.cookie && document.cookie !== '') {
          const cookies = document.cookie.split(';');
          if (!R.isNil(cookies) && cookies.length > 0) {
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.includes("ust")) {
                isCookieust = true;
                break;
              }
            }
          }
        }
        if (!isCookieust) {
          //this.setState({ isRedirectlogout: true }); 
          this.setState({
            showErrorModal: true,
            errorModalTitle: "Your session has expired. Please login again."
          });
        }
      }
    }
    catch (ee) {

    }
  };

  clickOutside = (e) => {
    try {
      if (!this.searchClientRef.current.contains(e.target)) {
        this.setState({ enableClientSearchDiv: false });
      }
      if (!this.searchClientProjectRef.current.contains(e.target)) {
        this.setState({ enableProjectSearchDiv: false });
      }
    }
    catch (ee) {

    }
  };

  redirectPageLogout() {
    if (hasLocalStorage) {
      window.localStorage.clear();
    }
    if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com') ) 
    {
      window.location.href = 'https://mapps.wptools.wintellplus.com/mappl/';
    }  
    else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) 
    {
      window.location.href = 'https://mapps.mstage.wintellplus.com/mappl/';
    } else {
      window.location.href = 'https://winstonretail.com';
    }
  }

  redirectPage()
  {
    if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com') ) 
      {
        window.location.href = 'https://mapps.wptools.wintellplus.com/mappm/home';
      }  
      else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) 
      {
        window.location.href = 'https://mapps.mstage.wintellplus.com/mappm/home';
      } else {
        window.location.href = 'https://winstonretail.com';
      }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.clickOutside);
  }

  componentDidMount() {
    document.addEventListener("click", this.clickOutside);
    const LocalstorageClient = window.localStorage.getItem('selectClient');
    const LocalstorageProject = window.localStorage.getItem('selectProject');
    const LocalstorageselectedClientdata = JSON.parse(window.localStorage.getItem('selectedClientdata'));
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    if (window.localStorage.getItem('selectClient') !== null) {
      this.setState({
        statecurrentClient: LocalstorageClient
      });
    }

    if (window.localStorage.getItem('selectProject') !== null) {
      this.setState({
        statecurrentProject: LocalstorageProject
      });
    }

    if (window.localStorage.getItem('selectedClientdata') !== null) {
      this.setState({
        stateselectedClientData: LocalstorageselectedClientdata
      });
    }
  }

  handleNewClickProject = clients => (e, focusedProjectname, focusedProject) => {
    e.stopPropagation();

    if (focusedProject) {
      this.props.setCurrentClient(R.find(R.eqProps('clientId', focusedProject))(clients));
      this.props.setCurrentProject(focusedProject);
    }

    if (focusedProjectname !== undefined && focusedProjectname !== null) {
      window.localStorage.setItem('selectProject', focusedProjectname);
      this.setState({
        statecurrentProject: focusedProjectname,
        enableProjectSearchDiv: false
      });
    }

    this.setState({
      stateselectedSearchResults: null
    });

  };

  handleClickProject = clients => (e, focusedProject) => {
    e.stopPropagation();

    if (focusedProject) {
      this.props.setCurrentClient(R.find(R.eqProps('clientId', focusedProject))(clients));
      this.props.setCurrentProject(focusedProject);
      this.handleClose(e);
    }
  };

  UNSAFE_componentWillMount() {
    if (!this.props.currentClient) {
      this.props.setCurrentClient(this.props.clients[0]);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.currentClient) {
      nextProps.setCurrentClient(nextProps.clients[0]);
    }
  }

  render() {
    const { anchorEl, focusedClientEl, focusedClient/*,isRedirectlogout*/ } = this.state;
    const { clients, currentClient, currentProject, location, isClientUser } = this.props;

    if (!currentClient) {
      return null;
    }

    // if (isRedirectlogout) {
    //   return <Redirect to={{ pathname: '/clogout' }} />;
    // }

    const hideClientDropdown = location && location.pathname && /(\/svrs\/)\w+/g.test(location.pathname);

    const hideClientDropdownOld = true;

    return (
      <Wrapper >
        {this.state.showErrorModal &&
          <Modal
            withoutCancel
            logoutText='Reload'
            onApprove={() => { this.setState({ showErrorModal: false, errorModalTitle: '' }); this.redirectPageLogout() }}>
            {this.state.errorModalTitle}

          </Modal>
        }
        <StyledBlock style={{ flex: 3 }}>
          <Logo />
          <StyledNavLink to="/dashboard" activeClassName="selected" margin="true">Dashboard</StyledNavLink>
          {!isClientUser && <StyledNavLink to="/manage/svrs" activeClassName="selected" margin="true">Manage</StyledNavLink>}
          <StyledNavLink to="/reporting/svrs" activeClassName="selected" margin="true">Reporting</StyledNavLink>
          {!isClientUser && <StyledNavLink to="/notifications/create" activeClassName="selected" margin="true">Notifications</StyledNavLink>}
        </StyledBlock>

        {/* Old Drop down #region  */}
        {hideClientDropdownOld ? null :
          <ClientProjectMenu
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <ClientName>{currentClient.clientName}</ClientName>
            <ProjectName>{currentProject.projectName}</ProjectName>
            <ArrowDropDown style={{ position: 'absolute', top: '13px', right: '5px' }} />
          </ClientProjectMenu>
        }
        <Menu
          id="client-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              opacity: focusedClient ? 0.9 : 1
            }
          }}
        >
          <TextField id="clientsearch" placeholder=' Client Search' onChange={(e) => this.HandleSearchClient(clients)(e)} type="search" fullWidth />
          {
            R.map(client => (
              <MenuItem key={client.clientId} onClick={e => this.handleClickClient(e, client)}>
                {client.clientName}
                <Menu
                  id={`project-menu-${client.clientId}`}
                  anchorEl={focusedClientEl}
                  open={!!focusedClientEl && !!focusedClient && focusedClient.clientId === client.clientId}
                  onClose={this.handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5
                    },
                  }}
                >
                  <TextField id="projectsearch" placeholder=' Project Search' type="search" fullWidth />
                  {
                    R.map(project => (
                      <MenuItem key={project.projectId} onClick={e => this.handleClickProject(clients)(e, project)}>
                        {project.projectName}
                      </MenuItem>
                    ), R.compose(
                      R.concat([{ projectId: null, clientId: client.clientId, projectName: 'ALL PROJECTS' }]),
                      R.sort(R.descend(R.prop('projectId'))),
                      R.reject(p => R.isNil(p.projectId)),
                      R.propOr([], 'projects')
                    )(client)
                    )
                  }
                </Menu>
              </MenuItem>
            ), clients)
          }
        </Menu>
        {/* #endregion */}

        &nbsp;&nbsp;
        {
          hideClientDropdown ? null :
            <div ref={this.searchClientRef} id="click-text" style={{ position: 'relative' }}>
              <ClientMenu
                aria-owns={anchorEl ? 'simple-menu' : null}
                aria-haspopup="true"
                onClick={this.handleClientSearchClick}
              >
                <ClientName>{isClientUser ? this.props.currentClient.clientName : this.state.statecurrentClient}</ClientName>
                <ArrowDropDown style={{ position: 'absolute', top: '1px', right: '5px' }} />
              </ClientMenu>
              {this.state.enableClientSearchDiv ?
                <div style={searchdiv.dropdownContent}>
                  <TextField placeholder="Search..." onChange={(e) => this.HandleSearchClient(clients)(e)} style={searchdiv.dropdown_Input}></TextField>
                  <ul className='clientmenu' style={{ maxHeight: '500px', overflowY: 'scroll', padding: '0px' }}>
                    {
                      R.map(client => (
                        <li key={client.clientId} onClick={e => this.handleNewClickClient(e, client.clientName, client)} style={searchdiv.contenta}> {client.clientName}</li>
                      ), (this.state.stateClients == null || this.state.stateClients.length === 0) ? clients : this.state.stateClients)
                    }
                  </ul>
                </div> : null
              }
            </div>
        }
        &nbsp;&nbsp;
        {hideClientDropdown ? null :
          <div ref={this.searchClientProjectRef} style={{ position: 'relative' }}>
            <ProjectMenu
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={this.handleProjectSearchClick} >
              <ProjectName>{this.state.statecurrentProject}</ProjectName>
              <ArrowDropDown style={{ position: 'absolute', top: '1px', right: '5px' }} />
            </ProjectMenu>
            {this.state.enableProjectSearchDiv ?
              <div style={searchdiv.dropdownContent}>
                <TextField placeholder="Search..." onChange={(e) => this.HandleSearchProject(isClientUser ? this.props.currentClient : this.state.stateselectedClientData)(e)} style={searchdiv.dropdown_Input}></TextField>
                <ul className='clientmenu' style={{ maxHeight: '500px', overflowY: 'scroll', padding: '0px' }}>
                  {
                    R.map(project => (
                      <li key={project.projectId} id={project.projectId} onClick={e => this.handleNewClickProject(clients)(e, project.projectName, project)} style={searchdiv.contenta} >
                        {project.projectName}
                      </li>
                    ), R.compose(
                      R.concat([{ projectId: null, clientId: (isClientUser ? this.props.currentClient.clientId : this.state.stateselectedClientData.clientId), projectName: 'ALL PROJECTS' }]),
                      R.sort(R.descend(R.prop('projectId'))),
                      R.reject(p => R.isNil(p.projectId)),
                      R.propOr([], 'projects')
                    )((this.state.stateselectedSearchResults === null || this.state.stateselectedSearchResults.length === 0) ? (isClientUser ? this.props.currentClient : this.state.stateselectedClientData) : this.state.stateselectedSearchClientData)
                    )
                  }
                </ul>
              </div> : null
            }
          </div>
        }

        <StyledBlock style={{ flex: 3, justifyContent: 'flex-end', paddingRight: '8px' }}>
          <StyledNavLink to="/account" style={{ display: 'none' }} activeClassName="selected">Account</StyledNavLink>
          <Home onClick={()=> this.redirectPage()} style={{cursor: 'pointer'}}></Home>&nbsp;<p onClick={()=> this.redirectPage()} 
                                           style={{paddingTop: '3px', cursor: 'pointer'}}>Home</p>
        </StyledBlock>
      </Wrapper>
    );
  }
}

export default Header;
