import * as R from 'ramda';

import {
  SET_CURRENT_CLIENT,
  SET_CURRENT_PROJECT,
  FETCH_ALL_SUCCESS,
  FETCH_ALL_ERROR,
  FETCH_AUTOCOMPLETE_SUCCESS,
  FETCH_AUTOCOMPLETE_ERROR,
  CLEAR_AUTOCOMPLETE
} from './ClientActions';

import { hasLocalStorage } from '../../utils/featureDetect';

export const STATE_KEY = 'client';

const CLIENT_ROLE_ID = R.path(['WINSTON', 'userRoles', 'CLIENT'])(window);

const getUserRoleId = () => {
  if (hasLocalStorage) {
    try {
      return JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).roleId;
    } catch (e) {
      return 0;
    }
  } else {
    return 0;
  }
};

export const isClientUser = () => getUserRoleId() === CLIENT_ROLE_ID;

const ALL_CLIENTS_OPTION = {
  projects   : [],
  clientId   : null,
  clientName : 'ALL CLIENTS'
};

const ALL_PROJECTS_OPTION = {
  projectId   : null,
  projectName : 'ALL PROJECTS'
};



const setInitialCurrentClient = () => {
  const failOption = isClientUser() ? null : ALL_CLIENTS_OPTION;
  if (hasLocalStorage) {
    try {
      return JSON.parse(window.localStorage.getItem('currentClient')) || failOption; 
    } catch (e) {
      return failOption;
    }
  } else {
    return failOption;
  }
};

const setInitialCurrentProject = () => {
  if (hasLocalStorage) {
    return JSON.parse(window.localStorage.getItem('currentProject')) || ALL_PROJECTS_OPTION;
  } else {
    return ALL_PROJECTS_OPTION;
  }
};

const setInitialClientProjectList = () => {
  const failOptions = isClientUser() ? [] : [ALL_CLIENTS_OPTION];
  if (hasLocalStorage) {
    return JSON.parse(window.localStorage.getItem('clientProjectList')) || failOptions;
  } else {
    return failOptions;
  }
};

const initialState = {
  currentClient  : setInitialCurrentClient(),
  currentProject : setInitialCurrentProject(),
  data           : setInitialClientProjectList(),
  autoComplete   : []
};

const ClientReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_CURRENT_CLIENT: {
      if (hasLocalStorage) {
        window.localStorage.setItem('currentClient', JSON.stringify(action.payload));
      }
      return R.assoc('currentClient', action.payload, state);
    }

    case SET_CURRENT_PROJECT: {
      if (hasLocalStorage) {
        window.localStorage.setItem('currentProject', JSON.stringify(action.payload));
      }
      return R.assoc('currentProject', action.payload, state);
    }

    case FETCH_ALL_ERROR : {
      // TODO ADD APPROPRIATE HANDLING
      return state;
    }

    case FETCH_ALL_SUCCESS : {
      const clientProjectList = R.pathOr([], ['payload', 'data', 'data'], action),
            menuOptions       = isClientUser() ? clientProjectList : R.concat([ALL_CLIENTS_OPTION], clientProjectList);

      if (hasLocalStorage) {
        window.localStorage.setItem('clientProjectList', JSON.stringify(menuOptions));
      }

      return R.assoc(
        'data',
        menuOptions,
        state
      );
    }

    case FETCH_AUTOCOMPLETE_ERROR : {
      return R.assoc(
        'autoComplete',
        [],
        state
      );
    }

    case FETCH_AUTOCOMPLETE_SUCCESS : {
      const autoComplete = R.pathOr([], ['payload', 'data', 'data'], action);

      return R.assoc(
        'autoComplete',
        autoComplete,
        state
      );
    }

    case CLEAR_AUTOCOMPLETE : {
      return R.assoc(
        'autoComplete',
        [],
        state
      );
    }

    default : {
      return state;
    }
  }
};

const prefilledFilters = (clients) => ({
  "Client Name": clients.filter(c => c.clientId).map(c => ({
    metaJson: { corporation: c.clientName }
  })),
  "Review State": [
    'Pending Submission', 'Pending Review', 'In Review', 'Approved', 'Rejected', 'Resubmitted'
  ].map((_, idx) => ({
      metaJson: { reviewState: idx }
  })),
  "Visit Duration Status": [
    'Unapproved', 'Approved'
  ].map((_, idx) => ({
      visitDurationStatus: idx
  })),
});

export const getClients        = R.compose(R.when(R.is(String), R.tryCatch(JSON.parse, R.always([]))), R.path([STATE_KEY, 'data']));
export const getCurrentClient  = R.path([STATE_KEY, 'currentClient']);
export const getCurrentProject = R.path([STATE_KEY, 'currentProject']);
export const getPrefilledFilters = (state) => prefilledFilters(R.path([STATE_KEY, 'data'], state));
export const getAutoComplete   = R.path([STATE_KEY, 'autoComplete']);

export default ClientReducer;
