import ensureNumericTokenUserId from '../../utils/ensureNumericTokenUserId';

export const FETCH_ALL              = 'SAVED_VIEWS/FETCH_ALL';
export const FETCH_ALL_ERROR        = 'SAVED_VIEWS/FETCH_ALL_ERROR';
export const FETCH_ALL_SUCCESS      = 'SAVED_VIEWS/FETCH_ALL_SUCCESS';
export const SET_CURRENT_SAVED_VIEW = 'SAVED_VIEWS/SET_CURRENT_SAVED_VIEW';
export const UPDATE                 = `SAVED_VIEWS/UPDATE`;

export const fetchSavedViews = useTenancy => dispatch => dispatch({
  types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
  payload : {
    request : {
      method : 'GET',
      url    : useTenancy ? '/api/v2/savedView' : '/api/v2/savedView/all'
    }
  }
});

export const setCurrentSavedView = payload => ({
  type : SET_CURRENT_SAVED_VIEW,
  payload
});

export const updateSavedView = (savedViewId, payload) => ({
  type    : UPDATE,
  payload : {
    request : {
      method : 'PUT',
      url    : `/api/v2/savedView/id/${savedViewId}`,
      data   : ensureNumericTokenUserId(payload)
    }
  }
});
