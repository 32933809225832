import * as R from 'ramda';

const namespace = 'MEAL_PERIOD';

export const FETCH_ALL              = `${namespace}/FETCH_ALL`;
export const FETCH_ALL_SUCCESS      = `${namespace}/FETCH_ALL_SUCCESS`;
export const FETCH_ALL_ERROR        = `${namespace}/FETCH_ALL_ERROR`;
export const FETCH                  = `${namespace}/FETCH`;
export const DELETE                 = `${namespace}/DELETE`;
export const UPDATE                 = `${namespace}/UPDATE`;
export const FILTER_BY_ACTION       = `${namespace}/FILTER_BY_ACTION`;
export const SHOW_SEARCH_FILTER     = `${namespace}/SHOW_SEARCH_FILTER`;
export const TRANSFORM_DATA         = `${namespace}/TRANSFORM_DATA`;
export const SELECT_NAVIGATE_FILTER = `${namespace}/SELECT_NAVIGATE_FILTER`;
export const CLEAR_CURRENT_DATA     = `${namespace}/CLEAR_CURRENT_DATA`;

export const fetchAllMealPeriod = (namespace='mealPeriod', filters={}) => dispatch => {
  if (R.isEmpty(filters)) {
    return dispatch({
      types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
      payload : {
        namespace,
        request : {
          method : 'GET',
          url    : '/api/v2/storeVisit/meal'
        }
      }
    });
  } else {
    return dispatch({
      types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
      payload : {
        namespace,
        request : {
          method : 'POST',
          url    : '/api/v2/storeVisit/meal/query',
          data   : { filters }
        }
      }
    });
  }
};

export const filterByAction = (namespace, filters) => ({
  type    : FILTER_BY_ACTION,
  payload : { namespace, filters }
});

export const transformData = (orderBy, order, sortType, filterList, data, namespace='mealPeriod') => ({
  type    : TRANSFORM_DATA,
  payload : { orderBy, order, sortType, filterList, data, namespace }
});

export const setShowSearchFilter = bool => ({
  type    : SHOW_SEARCH_FILTER,
  payload : bool
});

export const selectNavigateFilter = payload => ({
  type: SELECT_NAVIGATE_FILTER,
  payload
});

export const clearSvrResponses = (namespace='mealPeriod') => ({
  type    : CLEAR_CURRENT_DATA,
  payload : { namespace}
});
