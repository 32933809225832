import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import { Button }   from '@material-ui/core';
import StyledFooter from '../../../components/Modal/StyledFooter';

const ButtonWrapper = styled.div`
  display         : flex;
  height          : 100%;
  justify-content : space-between;
`;

const Footer = ({ onSave, onCancel, onDelete, canSave }) => (
  <StyledFooter>
    <ButtonWrapper>
      <Button
        color   = "secondary"
        style   = {{ padding : '0 15px' }}
        onClick = {onCancel}
      >
        CANCEL
      </Button>
      {typeof onDelete === 'function' ? (
        <Button
          color   = "secondary"
          onClick = {onDelete}
        >
          DELETE
        </Button>
      ) : null }
      {canSave() && (
        <Button
          color   = "secondary"
          onClick = {onSave}
        >
          CONTINUE
        </Button>
      )}

    </ButtonWrapper>
  </StyledFooter>
);

Footer.propTypes = {
  withoutCancel : PropTypes.bool,
  onSave        : PropTypes.func,
  onCancel      : PropTypes.func.isRequired
};

Footer.defaultProps = {
  withoutCancel : false,
  onSave        : () => {
  }
};

export default Footer;
