import * as R from 'ramda';

import recursivelyPluck from '../modules/svr/utils/recursivelyPluck';

const getUuids = R.compose(
  R.pluck('canonicalKey'),
  R.flatten,
  R.map(recursivelyPluck('content')),
  R.flatten,
  R.path(['content', 'structure'])
);

const selectAllQuestions = forms => R.chain(getUuids, forms);

const selectInternalQuestions = forms => {
  const getMainQuestions = R.propOr([], 'content');

  const getMainQuestionsUuids = R.compose(
    R.pluck('canonicalKey'),
    R.flatten,
    R.map(getMainQuestions),
    R.flatten,
    R.path(['content', 'structure'])
  );

  const notInternalQuestions = R.chain(getMainQuestionsUuids, forms);
  const allQuestions         = R.chain(getUuids, forms);

  return R.without(notInternalQuestions, allQuestions);
};

const selectCommonQuestions = forms => {
  const isCommonQuestion = R.has('isCommonQuestion');

  const getCommonQuestionsUuids = R.compose(
    R.pluck('canonicalKey'),
    R.filter(isCommonQuestion),
    R.flatten,
    R.map(recursivelyPluck('content')),
    R.flatten,
    R.path(['content', 'structure'])
  );

  return R.chain(getCommonQuestionsUuids, forms);
};


const selectnonphotoquestions = forms => {
  try {
    const getphotoQuestionsUuids = R.compose(
      R.pluck('canonicalKey'),
      R.filter(
        R.compose(
          R.contains('photo-input'),
          R.prop('type')
        )
      ),
      R.flatten,
      R.map(recursivelyPluck('content')),
      R.flatten,
      R.path(['content', 'structure'])
    );

    const photoQuestions = R.chain(getphotoQuestionsUuids, forms);
    const allQuestions = R.chain(getUuids, forms);
    return R.without(photoQuestions, allQuestions);
  }
  catch
  {
    const getphotoQuestionsUuids = R.compose(
      R.pluck('canonicalKey'),
      R.flatten,
      R.map(recursivelyPluck('content')),
      R.flatten,
      R.path(['content', 'structure'])
    );

    const photoQuestions = R.chain(getphotoQuestionsUuids, forms);
    const allQuestions = R.chain(getUuids, forms);
    return R.without(photoQuestions, allQuestions);
  }
};

const selectQuestions = (type, forms) => {
  const selectByType = R.cond([
    [R.equals('questions'),          () => selectAllQuestions(forms)],
    [R.equals('common_questions'),   () => selectCommonQuestions(forms)],
    [R.equals('internal_questions'), () => selectInternalQuestions(forms)],
    [R.equals('nonphoto'),           () => selectnonphotoquestions(forms)],
    [R.T,                            () => []]
  ]);

  return selectByType(type);
};

export default selectQuestions;
