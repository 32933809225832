import React      from 'react';
import { Helmet } from 'react-helmet';
import styled     from 'styled-components';

const Title = styled.h3`
  text-align: center;
`;

const NotFoundPage = () => (
  <div>
    <Helmet>
      <title>Page Not Found</title>
    </Helmet>
    <Title>Sorry, but we can not find any page by this address.</Title>
  </div>
);

export default NotFoundPage;
