import React       from 'react';
import { Helmet }  from 'react-helmet';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  setPropTypes,
}                  from 'recompose';

import Header from '../../components/Header';

import { getEmail } from '../auth/AuthReducer';
import { logout }   from '../auth/AuthActions';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser
} from '../client/ClientReducer';

import {
  setCurrentClient  as _setCurrentClient,
  setCurrentProject as _setCurrentProject
} from '../client/ClientActions';

import LogoutButton from './components/LogoutButton';
// import AccountCard  from './components/AccountCard';

const AccountPage = ({
    clients,
    currentClient,
    currentProject,
    setCurrentClient,
    setCurrentProject,
    // email,
    logout,
    isClientUser
  }) => (
  <div>
    <Helmet>
      <title>Account</title>
    </Helmet>
    <Header
      clients           = {clients}
      currentClient     = {currentClient}
      currentProject    = {currentProject}
      setCurrentClient  = {setCurrentClient}
      setCurrentProject = {setCurrentProject}
      isClientUser      = {isClientUser()}
    />
    <LogoutButton onLogout={logout} />
    {
      // <AccountCard email={email} />
    }
  </div>
);


const mapStateToProps = state => ({
  clients        : getClients(state),
  currentClient  : getCurrentClient(state),
  currentProject : getCurrentProject(state),
  email          : getEmail(state),
  isClientUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentClient  : payload => dispatch(_setCurrentClient(payload)),
  setCurrentProject : payload => dispatch(_setCurrentProject(payload)),
  logout            : () => dispatch(logout())
});

const withManage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    setCurrentClient  : () => {},
    setCurrentProject : () => {},
    currentClient     : {},
    currentProject    : {},
    clients           : [],
    email             : ''
  }),
  setPropTypes({
    currentClient     : PropTypes.object,
    currentProject    : PropTypes.object,
    clients           : PropTypes.array,
    email             : PropTypes.string,
    setCurrentClient  : PropTypes.func,
    setCurrentProject : PropTypes.func
  }),
);

export default withManage(AccountPage);
