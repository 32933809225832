import * as R         from 'ramda';
import React          from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import { history }    from '../../../store';
import { withRouter } from 'react-router';

import {
  compose,
  defaultProps,
  lifecycle,
  setPropTypes,
  withStateHandlers
} from 'recompose';

import styled from 'styled-components';

import selectListData  from '../../../utils/selectListData';
import selectQuestions from '../../../utils/selectQuestions';

import { getCurrentClient } from '../../client/ClientReducer';
import {
  createSavedWidget,
  editSavedWidget,
  fetchFormsByIds,
  fetchSavedWidget,
  resetForms
}                           from '../SavedWidgetsActions';
import { getForms }         from '../SavedWidgetsReducer';
import {
  updateSavedDashboard,
  fetchSavedDashboards
}                           from '../../dashboard/SavedDashboardsActions';

import { withStyles }                 from '@material-ui/core/styles';
import { MenuItem, Modal, TextField } from '@material-ui/core';

const HEIGHT = 30;

const styles = () => ({
  wrapper         : {
    maxWidth     : '92%',
    background   : '#fff',
    margin       : '5% auto 30px',
    borderRadius : '2px',
    boxShadow    :
      '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)'
  },
  selectContainer : {
    padding : '2rem'
  },
  select          : {
    marginTop    : '50px',
    marginBottom : '50px',
    display      : 'none'
  },
  label           : {
    fontWeight    : 'bold',
    textTransform : 'uppercase'
  }
});

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${HEIGHT}px;
  background: #f8f8f8;
  font-size: 15px;
  padding: 8px 25px;

  & > div {
    color: #cd222c;
    cursor: pointer;
  }
`;

const SavedWidgetPage = (
  {
    SearchParamsList,
    classes,
    handleQuickSelect,
    handleUpdate,
    isOpen,
    onClose,
    quickSelectedValue,
    selectedQuestions
  }) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={isOpen}
    onClose={onClose}
    style={{ overflow : 'scroll' }}>
    <div className={classes.wrapper}>
      {SearchParamsList}
      <div className={classes.selectContainer}>
        <TextField
          className={classes.select}
          fullWidth
          InputLabelProps={{
            shrink    : true,
            className : classes.label
          }}
          label="quick select"
          onChange={handleQuickSelect}
          select
          value={quickSelectedValue}>
          {selectListData.map(s => (
            <MenuItem key={s.value} value={s.value}>
              {s.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <Footer>
        {
          selectedQuestions.length ? (
            <div onClick={handleUpdate}>SAVE</div>
          ) : (
            <span>Please select one or more questions to roll up</span>
          )
        }
      </Footer>
    </div>
  </Modal>
);

const mapStateToProps    = (state, props) => ({
  currentClient : getCurrentClient(state),
  forms         : getForms(state, props.namespace)
});

const mapDispatchToProps = dispatch => ({
  createSavedWidget    : payload => dispatch(createSavedWidget(payload)),
  updateSavedDashboard : (id, data) => dispatch(updateSavedDashboard(id, data)),
  editSavedWidget      : (data, id) => dispatch(editSavedWidget(data, id)),
  fetchFormsByIds      : (namespace, payload) => payload.length && dispatch(fetchFormsByIds(namespace, payload)),
  fetchSavedDashboards : useTenancy => dispatch(fetchSavedDashboards(useTenancy)),
  fetchSavedWidget     : payload => dispatch(fetchSavedWidget(payload)),
  resetForms           : namespace => dispatch(resetForms(namespace))
});

const enhancedSavedWidgetPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
       id                 = null,
       quickSelectedValue = '',
       selectedForm       = '',
       selectedQuestions  = []
     }) => ({
      id,
      quickSelectedValue,
      selectedForm,
      selectedQuestions
    }),
    {
      handleUpdate         : (
        { selectedQuestions, id },
        {
          currentClient : { clientId },
          createSavedWidget,
          updateSavedDashboard,
          editSavedWidget,
          fetchSavedDashboards,
          filterList,
          metaJson,
          restrictTokenUserId,
          widgetDescription,
          widgetTitle
        }
      ) => () => {
        const uniqSelectedQuestions = R.uniq(selectedQuestions);
        if (R.isNil(id)) {
          const data = {
            formQuestionCanonicalKeys : R.join(',', uniqSelectedQuestions),
            filters                   : JSON.stringify(filterList),
            description               : widgetDescription,
            title                     : widgetTitle,
            metaJson                  : JSON.stringify(metaJson)
          };

          if (restrictTokenUserId) {
            data.restrictTokenUserId = parseInt(restrictTokenUserId, 10);
          }

          createSavedWidget(data).then(res => {
            const widgetId = R.pathOr(
              null,
              ['payload', 'data', 'data', 'id'],
              res
            );

            const useTenancy = Boolean(clientId);
            fetchSavedDashboards(useTenancy)
              .then(innerRes => {
                const firstDashboard = R.lensPath([
                  'payload',
                  'data',
                  'data',
                  [0]
                ]);

                const firstDashboardId = R.compose(
                  R.prop('id'),
                  R.view(firstDashboard)
                )(innerRes);

                const widgetIds = R.compose(
                  R.propOr([], 'widgetIds'),
                  R.view(firstDashboard)
                )(innerRes);

                const combinedWidgetIds = R.append(widgetId, widgetIds);

                updateSavedDashboard(
                  { widgetIds : combinedWidgetIds.toString() },
                  firstDashboardId
                ).then(() => {
                  history.push('/dashboard/dashboards');
                });
              })
              .catch(err => {
                throw new Error('SavedWidgetPage error:', err);
              });
          });
        } else {
          const data = {
            formQuestionCanonicalKeys : R.join(',', uniqSelectedQuestions),
            filters                   : JSON.stringify(filterList),
            description               : widgetDescription,
            title                     : widgetTitle,
            restrictTokenUserId       : restrictTokenUserId
              ? parseInt(restrictTokenUserId, 10)
              : null
          };

          editSavedWidget(data, id).then(res =>
            history.push(
              `/report/widgets/${R.path(
                ['payload', 'data', 'data', 'id'],
                res
              )}/edit`
            )
          );
        }
      },
      handleToggleForm     : ({ selectedForm }) => formId => ({
        selectedForm : R.equals(selectedForm, formId) ? '' : formId
      }),
      handleQuickSelect    : (__, { forms }) => ({ target }) => {
        const selectedQuestions = selectQuestions(target.value, forms);

        return {
          quickSelectedValue : target.value,
          selectedQuestions
        };
      },
      handleSelectQuestion : ({ selectedQuestions }) => (
        { target },
        canonicalKey
      ) => ({
        selectedQuestions : target.checked
          ? R.append(canonicalKey, selectedQuestions)
          : R.without([canonicalKey], selectedQuestions)
      }),
      setWidget            : () => widget => ({
        selectedQuestions : R.propOr([], 'formQuestionCanonicalKeys', widget),
        id                : R.propOr(null, 'id', widget)
      })
    }
  ),
  defaultProps({
    forms : []
  }),
  withStyles(styles),
  setPropTypes({
    classes : PropTypes.object.isRequired,
    forms   : PropTypes.array
  }),
  lifecycle({
    componentDidMount() {
      const {
              data,
              fetchFormsByIds,
              fetchSavedWidget,
              setWidget,
              widgetId,
              namespace
            } = this.props;

      const formIds = R.pluck('formId', data);

      if (!R.isEmpty(formIds)) {
        fetchFormsByIds(namespace, formIds);
      }

      if (widgetId) {
        fetchSavedWidget(widgetId).then(widgetData =>
          setWidget(R.pathOr({}, ['payload', 'data', 'data'], widgetData))
        );
      }
    },
    componentWillReceiveProps(nextProps) {
      const dataIsDifferent = !R.equals(
        R.pluck('id', nextProps.data),
        R.pluck('id', this.props.data)
      );

      if (dataIsDifferent) {
        const formIds = R.pluck('formId', nextProps.data);
        resetForms(nextProps.namespace);

        if (!R.isEmpty(formIds)) {
          fetchFormsByIds(nextProps.namespace, formIds);
        }
      }
    }
  }),
  withRouter
);

export default enhancedSavedWidgetPage(SavedWidgetPage);
