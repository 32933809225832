import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography                from '@material-ui/core/Typography';

import {
  ChevronLeft,
  ChevronRight,
  Edit,
  OpenWith
} from '@material-ui/icons';

import ImageSearch from '../../modules/svr-responses/components/review/icons/ImageSearch';

const styles = theme => ({
  widgetHeader: {
    display        : 'flex',
    justifyContent : 'space-between',
    position       : 'relative',
    padding        : theme.custom.spacing.paddingHeader,
    borderBottom   : `1px solid ${theme.custom.colors.grayAlt}`,
    minHeight      : '60px'
  },
  subheading: {
    minHeight : '20px',
    color     : theme.custom.colors.black
  },
  controls: {
    position : 'absolute',
    right    : theme.spacing.unit,
    bottom   : theme.spacing.unit,
  },
  nextPrevSlide: {
    paddingLeft  : theme.spacing.unit / 2,
    paddingRight : '7px',
    color        : theme.custom.colors.black,
    fontWeight   : '300',
    fontSize     : '22px',
    cursor       : 'pointer'
  },
  'nextPrevSlide + nextPrevSlide': {
    background: 'red',
  },
  iconContainer : {
    display: 'flex'
  },
  icon: {
    marginLeft : theme.spacing.unit / 2,
    cursor     : 'pointer',
  },
  iconImageSearch: {
    cursor: 'pointer',
  },
  title: {
    cursor: 'pointer'
  }
});

const WidgetHeader = ({
  classes,
  description,
  handleNextSlide,
  handlePreviousSlide,
  title,
  toggleDetailView,
  handleEditModal,
  onMoveClick,
  widgetContent,
  onTitleClick
}) => (
  <div className={classes.widgetHeader}>
    <div>
      <Typography className={classes.title} onClick={onTitleClick} variant="title">{title}</Typography>
      <Typography variant="subheading" className={classes.subheading}>
        {description}
      </Typography>
    </div>
    <div>
      <div className={classes.iconContainer}>
        <div className={classes.iconImageSearch} onClick={toggleDetailView}><ImageSearch /></div>
        <div className={classes.icon} onClick={handleEditModal}><Edit /></div>
        <div className={classes.icon} onClick={onMoveClick}><OpenWith /></div>
      </div>
      { widgetContent.length > 1 ? (
        <div className={classes.controls}>
          <ChevronLeft
            className={classes.nextPrevSlide}
            onClick={handlePreviousSlide}
          />
          <ChevronRight
            className={classes.nextPrevSlide}
            onClick={handleNextSlide}
          />
        </div>
      ) : null }
    </div>
  </div>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    classes             : PropTypes.object.isRequired,
    handleNextSlide     : PropTypes.func.isRequired,
    handlePreviousSlide : PropTypes.func.isRequired,
    theme               : PropTypes.object.isRequired,
    title               : PropTypes.string.isRequired,
    toggleDetailView    : PropTypes.func.isRequired,
    widgetContent       : PropTypes.array.isRequired
  })
)(WidgetHeader);
