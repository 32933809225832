import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import { Button, Tooltip }   from '@material-ui/core';
import StyledFooter from '../../../../../components/Modal/StyledFooter.js';

const ButtonWrapper = styled.div`
  display         : flex;
  height          : 100%;
  justify-content : space-between;
`;

const Footer = ({ onSend, onCancel, toolTip, onCancelText, onCancelNotification }) => (
  <StyledFooter>
    <ButtonWrapper>
      <Button
        color="secondary"
        style={{ padding: '0 15px' }}
        onClick={onCancel}>
          {onCancelText || 'CANCEL'}
      </Button>
      {onCancelNotification && (
        <Tooltip
          title      = {toolTip}
          placement  = "top-end"
          enterDelay = {300}
        >
          <div>
            <Button
                color="secondary"
                onClick={onCancelNotification}
                disabled={Boolean(toolTip)}
              >
                CANCEL
            </Button>
          </div>
        </Tooltip>
      )}
      <Tooltip
        title      = {toolTip}
        placement  = "top-end"
        enterDelay = {300}
      >
        <div>
          <Button
            color="secondary"
            onClick={onSend}
            disabled={Boolean(toolTip)}
          >
            SEND
          </Button>
        </div>
      </Tooltip>
    </ButtonWrapper>
  </StyledFooter>
);

Footer.propTypes = {
  withoutCancel : PropTypes.bool,
  onSend        : PropTypes.func.isRequired,
  onCancel      : PropTypes.func.isRequired,
  toolTip       : PropTypes.string
};

Footer.defaultProps = {
  withoutCancel: false,
};

export default Footer;
