import * as R    from 'ramda';
import * as RA   from 'ramda-adjunct';
import React     from 'react';
import PropTypes from 'prop-types';

import {
  compose,
  lifecycle,
  setPropTypes,
  withProps, withStateHandlers
} from 'recompose';

import { Typography } from '@material-ui/core';
import { history }    from '../../store';

import GeneralListWidgetContent from './GeneralListWidgetContent';
import WidgetContent            from './WidgetContent';
import WidgetHeader             from './WidgetHeader';

import { connect }              from 'react-redux';
import withUser                 from '../../modules/auth';
import withClient               from '../../modules/client';
import { fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';

const answersList = (answers=[]) => {
  return (
    <div style={{ fontSize: '0.8rem', marginBottom: '10px' }}>
      {
        R.sort(R.descend(R.prop('created')), answers).map(answer => (
          <li key={answer.id}>{answer.answerValue || '[Empty answer]'}</li>
        ))
      }
    </div>
  );
};

const QuestionResponseWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onEditClick,
    onMoveClick,
    onItemClick
  }) => (
  <div>
    <WidgetHeader
      title        = {title}
      description  = {description}
      onTitleClick = {onTitleClick}
      onEditClick  = {onEditClick}
      onMoveClick  = {onMoveClick}
    />
    <WidgetContent>
      {widgetContent.length ? (
        widgetContent.map(
          ({ id, checkInTime, answers, projectName, retailer, doorNumber, doorLocation }, i) => (
            <GeneralListWidgetContent
              key          = {i}
              blurb        = {answersList(answers)}
              date         = {checkInTime}
              projectName  = {projectName}
              retailer     = {retailer}
              doorNumber   = {doorNumber}
              doorLocation = {doorLocation}
              onClick      = {() => onItemClick(id)}
            />
          )
        )
      ) : (
        <Typography paragraph={true} variant="body2">
          No answers...
        </Typography>
      )}
    </WidgetContent>
  </div>
);

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload) => dispatch(fetchQuestionAnswers(namespace, payload)),
});

const mapStateToProps = () => ({});

const mapAnswersToQuestions = (answers={}) => storeVisit => {
  const matchingAnswers = answers[storeVisit.id] || [];
  return R.assoc('answers', matchingAnswers)(storeVisit);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withStateHandlers(null, {
    onAnswers : () => answers => (
      { answers : R.groupBy(R.prop('storeVisitId'))(answers)
    }),
    onItemClick : () => id => {
      history.push('/svr-response/' + id);
    }
  }),
  withProps(({ widgetData : { description, filteredWidgetData, title }, answers }) => ({
    description,
    title,
    widgetContent : R.compose(
      R.sort(R.descend(R.prop('checkInTime'))),
      R.filter(R.compose(R.length, R.prop('answers'))),
      R.map(
        R.compose(
          R.zipObj(['id', 'checkInTime', 'projectName', 'retailer', 'doorNumber', 'doorLocation', 'answers']),
          RA.paths([
            ['id'],
            ['expectedCheckinTime'],
            ['metaJson', 'projectName'],
            ['metaJson', 'retailer'],
            ['metaJson', 'doorNumber'],
            ['metaJson', 'doorLocation'],
            ['answers']
          ]),
          mapAnswersToQuestions(answers)
        )
      )
    )(filteredWidgetData)
  })),
  setPropTypes({
    description   : PropTypes.string,
    title         : PropTypes.string,
    widgetData    : PropTypes.object.isRequired,
    onTitleClick  : PropTypes.func,
    onEditClick   : PropTypes.func,
    onMoveClick   : PropTypes.func,
    widgetContent : PropTypes.arrayOf(
      PropTypes.shape({
        id           : PropTypes.number,
        checkInTime  : PropTypes.string,
        question     : PropTypes.string,
        projectName  : PropTypes.string,
        retailer     : PropTypes.string,
        doorLocation : PropTypes.string,
        doorNumber   : PropTypes.string
      })
    )
  }),
  lifecycle({
    componentDidMount() {
      const questions = this.props.widgetData.formQuestionCanonicalKeys || [];

      this.props.fetchQuestionAnswers(this.props.widgetData.metaJson.namespace, questions)
        .then(res => {
          const answers = R.filter(R.prop('answerValue'), R.pathOr([], ['payload', 'data', 'data'], res));
          this.props.onAnswers(answers);
        });
    }
  })
)(QuestionResponseWidgetContainer);
