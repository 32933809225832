import * as R from 'ramda';
import moment from 'moment';

import {
  getTableColumnsForPage,
  maybeDecorateColumn
} from '../../utils/tableColumns';

import { isClientUser } from '../client/ClientReducer';

import {
  makeDecoratedImageUrl,
  getRotation
} from '../../utils/image';

import {
  FETCH,
  FETCH_ALL,
  UPDATE,
  FILTER_BY_ACTION,
  TRANSFORM_DATA,
  SHOW_SEARCH_FILTER,
  SELECT_NAVIGATE_FILTER,
  CLEAR_CURRENT_DATA,

  CREATE_NOTIF
} from './NotificationsActions';

import {
  UPDATE_DOCUMENT
} from '../document/DocumentActions';

export const STATE_KEY = 'notifications';

const HIDDEN_FROM_CLIENT = R.path(['WINSTON', 'svrPrivacy', 'HIDDEN_FROM_CLIENT'])(window);

const initialState = {
  data            : {},
  transformedData : {},
  tableColumns    : [],
  formAnswers     : {},
  photos          : [],
  photoQuestions  : [],
  savedView       : {},
  navigateFilter  : null,
  filterList      : {}
};

const maybeAppendDecorators = R.map((filter={}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'notifications', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
});

const appendFilterType = R.map((filter={}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'notifications', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const determineTableColumnPage = () => {
  return R.ifElse(R.test(/sent/i), R.always('notifications'), R.always('svrResponses'))(window.location.pathname);
};

const ensureClientDoesNotSeeHiddenRecords = _isClientUser => n => {
  if (n.privacy === HIDDEN_FROM_CLIENT) {
    return !_isClientUser;
  } else {
    return true;
  }
};

const cacheImage = url => {
  if (!R.test(/(roll-ups)/gm, window.location.pathname)) {
    let small = document.createElement('img');
    let large = document.createElement('img');
    const src = document.getElementById('cached-images');

    small.src = makeDecoratedImageUrl(getRotation(url), 200)(url);
    large.src = makeDecoratedImageUrl(getRotation(url), 1280)(url);

    src.appendChild(small);
    src.appendChild(large);
  }
};

const prefilledFilters = () => ({
  "Type": [
    'Immediate', 'Scheduled'
  ].map((_, idx) => ({
      type: idx
  })),
  "Sent - Yes/No/Cancelled": [
    'No', 'Yes', 'Cancelled'
  ].map((_, idx) => ({
      sent: idx
  }))
});

const rejectAutomaticNotifications = R.reject(R.both(R.propSatisfies(R.test(/Visit (Approved|Rejected)/gm), 'title'), R.propEq('type', 0)));

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_SUCCESS`: {

      const tableColumns = getTableColumnsForPage('notifications', determineTableColumnPage());

      return R.compose(
        R.assocPath([R.path(['meta', 'previousAction', 'payload', 'namespace'])(action)], R.__, state),
        R.assoc('tableColumns', tableColumns),
        data => ({ data, transformedData : R.clone(data) }),
        R.indexBy(R.prop('id')),
        rejectAutomaticNotifications,
        R.pathOr([], ['payload', 'data', 'data']),
      )(action);
    }

    case `${FETCH}_SUCCESS`: {
      const data      = R.pathOr({}, ['payload', 'data', 'data'])(action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);
      const _isClientUser = isClientUser();

      if (ensureClientDoesNotSeeHiddenRecords(_isClientUser)(data)) {
        return R.compose(
          R.assocPath([namespace], R.__, state),
          R.assoc('transformedData', [R.clone(data)]),
          R.assocPath(['data', data.eventId + ''], data),
        )({});
      } else {
        return R.assocPath([namespace, 'data', data.eventId + ''], {}, state);
      }
    }

    case `${UPDATE_DOCUMENT}_SUCCESS`: {
      const doc       = R.pathOr({}, ['payload', 'data', 'data'], action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      cacheImage(doc.url);

      return R.assocPath([namespace, 'photos'], R.compose(
        R.append(doc),
        R.reject(R.propEq('id', doc.id)),
        R.pathOr([], [namespace, 'photos'])
      )(state), state);
    }

    case `${UPDATE}_SUCCESS`: {
      const modifyNotification = R.pathOr({}, ['payload', 'data', 'data'], action);
      const namespace         = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.assocPath([namespace, 'data', modifyNotification.eventId+''], modifyNotification, state);
    }

    case FILTER_BY_ACTION : {

      const filterList = R.compose(
        maybeAppendDecorators,
        appendFilterType
      )(action.payload.filters);

      const filterListNeedsUpdating = R.compose(
        R.not,
        R.equals(filterList),
        R.pathOr({}, [action.payload.namespace, 'filterList'])
      )(state);

      if (filterListNeedsUpdating) {

        const _state = R.assocPath([action.payload.namespace, 'filterList'], filterList, state);

        if (!R.isEmpty(filterList)) {
          try {
            window.localStorage.setItem(`persistedFilters:${action.payload.namespace}`, JSON.stringify(filterList));
          } catch (e) {
            return _state;
          }
        } else {
          try {
            window.localStorage.removeItem(`persistedFilters:${action.payload.namespace}`);
          } catch (e) {
            return _state;
          }
        }

        return _state;
      } else {
        return state;
      }

    }

    case TRANSFORM_DATA : {

      const emptyData = R.cond([
        [R.equals('string'), R.always('')],
        [R.equals('number'), R.always(0)],
        [R.equals('date'),   R.equals(new Date(-8640000000000000))],
        [R.T,                R.always('')]
      ])(action.payload.sortType);

      const _isClientUser = isClientUser();

      const compareValueIfFilterHasSelections = props => {
        const path        = props[0],
              data        = props[1],
              decorator   = props[2],
              type        = props[3],
              parsedData  = type === 'number' ? R.map(d => parseInt(d, 10))(data) : data;

        const filterableValue = v => maybeDecorateColumn(decorator)(R.path(path, v));

        const isWithinDateRange = R.anyPass(
          R.map(range => date => moment(date, 'MM/DD/YYYY hh:mm:A').isBetween(moment(range[0], 'YYYY-MM-DD'), moment(range[1], 'YYYY-MM-DD'), 'day', '[]'))(data)
        );

        const finder = type === 'date' ? isWithinDateRange : R.either(R.contains(R.__, parsedData), R.contains(R.__, data));
        
        return parsedData.length ? R.compose(finder, filterableValue) : R.T;
      };

      const maybeStringToNumber  = value => {
        if (action.payload.sortType === 'number' && R.is(String, value)) {
          return parseInt(value, 10);
        }

        return value;
      };
      const maybeLowerCaseString = R.when(R.is(String), R.toLower);
      const ensureEmptyTypeValue = R.unless(R.identity, R.always(emptyData));

      const allFilters = R.compose(
        R.map(compareValueIfFilterHasSelections),
        R.values,
        R.map(R.props(['path', 'data', 'decorator', 'type']))
      )(action.payload.filterList);

      const transformedData = R.compose(
        R.sort(
          R[`${action.payload.order}end`](
            R.compose(
              maybeStringToNumber,
              ensureEmptyTypeValue,
              maybeLowerCaseString,
              R.path(action.payload.orderBy.split('.'))
            )
          )
        ),
        R.filter(R.both(
          R.allPass(allFilters),
          ensureClientDoesNotSeeHiddenRecords(_isClientUser)
        ))
      )(action.payload.data);

      return R.assocPath([action.payload.namespace, 'transformedData'], transformedData)(state);
    }

    case SHOW_SEARCH_FILTER : {
      return { ...state, showSearchFilter : action.payload };
    }

    case SELECT_NAVIGATE_FILTER : {
      return { ...state, navigateFilter: action.payload };
    }

    case CLEAR_CURRENT_DATA : {
      return R.assocPath([action.payload.namespace, 'data' ], [], state);
    }

    case CREATE_NOTIF : {
      return state;
    }

    default: {
      return state;
    }
  }
};

export const getNotifications = (state, namespace=STATE_KEY) => R.values(
  R.pathOr([], [STATE_KEY, namespace, 'data'], state)
) || [];

export const getTransformedData = (state, namespace=STATE_KEY) => R.values(
  R.pathOr([], [STATE_KEY, namespace, 'transformedData'], state)
) || [];

export const getNotification      = (state, namespace, id) => {
  return R.pathOr({}, [STATE_KEY, namespace, 'data', id], state);
};

export const getTableColumns     = (type, namespace) => R.pathOr(getTableColumnsForPage(type, determineTableColumnPage()),    [STATE_KEY, namespace, 'tableColumns']);
export const getFilterList       = namespace => R.pathOr({},    [STATE_KEY, namespace, 'filterList']);
export const getShowSearchFilter = R.pathOr(false, [STATE_KEY, 'showSearchFilter']);
export const getNavigateFilter   = R.pathOr(null,  [STATE_KEY, 'navigateFilter']);
export const getPrefilledFilters = prefilledFilters;


export default NotificationsReducer;
