import * as R from 'ramda';

import {
  FETCH_ALL,
  CREATE,
  UPDATE,
  DELETE,
  ARCHIVE,
  ACTIVE,
  IS_EDITING
} from '../actions/CommonQuestionsActions';

export const ITEM_TYPES = {
  SECTION        : 'section',
  QUESTION_GROUP : 'question-group',
  RADIO_BUTTON   : 'radio-button-input',
  DROPDOWN       : 'dropdown-input',
  CHECKBOX       : 'checkbox-input',
  SINGLE_LINE    : 'single-line-input',
  MULTI_LINE     : 'multi-line-input',
  PHOTO          : 'photo-input',
  TOGGLE         : 'toggle-input',
  COMMON         : 'common-input',
  VIDEO          : 'video-input'
};

export const STATE_KEY = 'commonQuestions';

const initialState = {
  isEditing : false,
  data      : {}
};

const canonicalKeyAsProp = (a, b) => R.assoc(b.canonicalKey, b, a);

const CommonQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_SUCCESS`: {
      const parseCommonQuestion = item => ({
        canonicalKey : item.canonicalKey,
        id           : item.id,
        options      : item.options,
        tags         : item.tags,
        timestamp    : item.timestamp,
        title        : item.title,
        type         : item.type,
        documentIds  : R.when(R.is(String), JSON.parse)(item.documentIds[0]),
        meta         : R.prop('metaJson', item),
        status       : item.status
      });

      return R.compose(
        R.assoc('data', R.__, state),
        R.reduce(canonicalKeyAsProp, {}),
        R.map(parseCommonQuestion),
        R.pathOr([], ['payload', 'data', 'data']),
      )(action);
    }
    case `${CREATE}_SUCCESS`:
    case `${UPDATE}_SUCCESS`: {
      const commonQuestion = R.pathOr({}, ['payload', 'data', 'data'], action);
      const dataLens       = R.lensProp('data');

      const parseCommonQuestion = {
        ...commonQuestion,
        documentIds : R.when(R.is(String), R.split(','))(commonQuestion.documentIds),
        meta        : R.when(R.is(String), JSON.parse)(commonQuestion.metaJson),
        options     : R.when(R.is(String), JSON.parse)(commonQuestion.options),
        tags        : R.when(R.is(String), R.split(','))(commonQuestion.tags),
      };

      return R.set(
        dataLens,
        R.assoc(parseCommonQuestion.canonicalKey, parseCommonQuestion, R.view(dataLens, state)),
        state,
      );
    }

    case DELETE: {
      const questionKey = R.propOr('', 'payload', action);
      const lens        = R.lensPath(['data']);
      const questions   = R.view(lens, state);

      return R.set(
        lens,
        R.reject(R.propEq('id', questionKey), questions),
        state
      );
    }
    case ARCHIVE: {
      const questionKey   = R.propOr('', 'payload', action);
      const questionIndex = R.compose(
        R.findIndex(R.propEq('id', questionKey)),
        R.path(['data'])
      )(state);

      return R.assocPath(['data', questionIndex, 'state'], 'Archived', state);
    }
    case ACTIVE: {
      const questionKey   = R.propOr('', 'payload', action);
      const questionIndex = R.compose(
        R.findIndex(R.propEq('id', questionKey)),
        R.path(['data'])
      )(state);

      return R.assocPath(['data', questionIndex, 'state'], 'Active', state);
    }
    case IS_EDITING: {
      return  R.assoc('isEditing', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

export const getCommonQuestions = state => Object
  .keys(R.path([STATE_KEY, 'data'], state))
  .map(key => R.path([STATE_KEY, 'data', key], state));

export const getIsCommonQuestionLoaded = R.compose(R.length, getCommonQuestions);

export default CommonQuestionsReducer;
