import * as R from 'ramda';

import { getToken } from '../modules/auth/AuthReducer';
import { logout }   from '../modules/auth/AuthActions';

const axiosMiddlewareOptions = {
  interceptors : {
    request  : [
      {
        success : ({ getState }, request) => {
          const state = getState();
          const token = getToken(state);

          if (token) {
            request.headers.authorization = `Bearer ${token}`;
          }

          if (R.path(['client', 'currentClient', 'clientId'])(state)) {
            request.headers['x-tenancy-client-id'] = state.client.currentClient.clientId;
          }

          if (R.path(['clientProject', 'currentProject', 'projectId'])(state)) {
            request.headers['x-tenancy-project-id'] = state.clientProject.currentProject.projectId;
          }

          return request;
        }
      }
    ],
    response : [
      {
        error : ({ dispatch }, err) => {

          const statusCode = R.pathOr(500, ['response', 'data', 'statusCode'])(err);

          if (statusCode === 401 || statusCode === 403) {
            dispatch(logout());
          } else {
            return err;
          }

        }
      }
    ]
  }
};

export default axiosMiddlewareOptions;
