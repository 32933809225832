import React      from 'react';
import PropTypes  from 'prop-types';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import * as R     from 'ramda';

import {
compose,
setPropTypes,
withStateHandlers,
withHandlers,
lifecycle
} from 'recompose';

import FeedbackWidgetContainer         from './FeedbackWidgetContainer';
import PhotoWidgetContainer            from './PhotoWidgetContainer';
import VideoWidgetContainer            from './VideoWidgetContainer';
import QuestionResponseWidgetContainer from './QuestionResponseWidgetContainer';
import BarChartWidgetContainer         from './BarChartWidgetContainer';
import PieChartWidgetContainer         from './PieChartWidgetContainer';
import WidgetCreator                   from './WidgetCreator/WidgetCreator';
import WidgetContainer                 from './WidgetContainer';
import MoveWidgetDialog                from '../../modules/dashboard/components/MoveWidgetDialog';
import LineChartWidgetContainer        from './LineChartWidgetContainer';
import SumWidgetContainer              from './SumWidgetContainer';
import PercentageWidgetContainer       from './PercentageWidgetContainer';

const WIDGET_TYPES = {
  feedback         : FeedbackWidgetContainer,
  questionResponse : QuestionResponseWidgetContainer,
  photo            : PhotoWidgetContainer,
  video            : VideoWidgetContainer,
  sum              : SumWidgetContainer,
  barChart         : BarChartWidgetContainer,
  lineChart        : LineChartWidgetContainer,
  pieChart         : PieChartWidgetContainer,
  percentage       : PercentageWidgetContainer
};

const Widget = (
  {
    widgetData,
    onTitleClick,
    setEditingWidget,
    setMovingWidget,
    isEditing,
    isMoving,
    widgetIds,
    onMove,
    transformData,
    isDownloading,
    setDownloadWidget,
    setContainerSizes,
    isShowingIcons,
    setDownloadWidgetRef,
    setDownloadWidgetRefClone,
    containerHeight,
    containerWidth,
    exportGraph,
    onAnswers,
    answers,
    setFetchingData
  }) => {

  const RespectiveWidgetTypeComponent = WIDGET_TYPES[widgetData.metaJson.widgetType];

  return RespectiveWidgetTypeComponent ? (
      <WidgetContainer>
        <RespectiveWidgetTypeComponent
          widgetData        = {widgetData}
          onTitleClick      = {onTitleClick}
          transformData     = {transformData}
          onEditClick       = {() => setEditingWidget(true)}
          onMoveClick       = {() => setMovingWidget(true) }
          onDownloadClick   = {exportGraph}
          setDownloadWidget = {setDownloadWidget}
          setContainerSizes = {setContainerSizes}
          isShowingIcons    = {isShowingIcons}
          onAnswers         = {onAnswers}
          answers           = {answers}
          namespace         = {widgetData.metaJson.namespace}
          setFetchingData   = {setFetchingData}
        />
        {
          isEditing ? (
            <WidgetCreator
              isOpen
              toggleWidgetCreator = {() => setEditingWidget(false)}
              namespace           = {widgetData.metaJson.namespace}
              savedWidgetFormData = {widgetData}
              setFetchingData     = {setFetchingData}
            />
          ) : null
        }
        {
          isMoving ? (
            <MoveWidgetDialog
              open
              onClose   = {() => setMovingWidget(false)}
              onCancel  = {() => setMovingWidget(false)}
              onConfirm = {onMove}
              id        = {widgetData.id}
              widgetIds = {widgetIds}
            />
          ) : null
        }
        {
          isDownloading ? (
            <WidgetContainer
              containerWidth  = {containerWidth}
              containerHeight = {containerHeight}
            >
              <RespectiveWidgetTypeComponent
                widgetData           = {widgetData}
                onTitleClick         = {onTitleClick}
                transformData        = {transformData}
                onEditClick          = {() => setEditingWidget(true)}
                onMoveClick          = {() => setMovingWidget(true) }
                isShowingIcons       = {isShowingIcons}
                setDownloadWidgetRef = {setDownloadWidgetRef}
                setDownloadWidgetRefClone = {setDownloadWidgetRefClone}
                containerHeight      = {containerHeight}
                containerWidth       = {containerWidth}
                onAnswers            = {onAnswers}
                answers              = {answers}
                namespace            = {widgetData.metaJson.namespace}
                isDownloadContainer
                setFetchingData      = {setFetchingData}
              />
            </WidgetContainer>
          ) : null
        }
      </WidgetContainer>
  ) : null;
};

export default compose(
  setPropTypes({
    widgetData    : PropTypes.object.isRequired,
    widgetType    : PropTypes.string,
    widgetIds     : PropTypes.array,
    onMove        : PropTypes.func,
    onTitleClick  : PropTypes.func,
    isEditing     : PropTypes.bool,
    isMoving      : PropTypes.bool,
    isDownloading : PropTypes.bool,
  }),
  withStateHandlers(
    ({
       isShowingIcons  = true,
       isEditing       = false,
       isMoving        = false,
       isDownloading   = false,
       containerRef    = null,
       containerRefclone =  null,
       containerWidth  = 0,
       containerHeight = 0,
       downloadedFile  = false
     }) => ({
      isEditing,
      isMoving,
      isDownloading,
      containerRef,
      containerRefclone,
      isShowingIcons,
      containerWidth,
      containerHeight,
      downloadedFile
    }),
    {
      setEditingWidget    : () => bool => ({
        isEditing : bool
      }),
      setMovingWidget     : () => bool => ({
        isMoving : bool
      }),
      setDownloadWidget   : () => bool => ({
        isDownloading  : bool,
        downloadedFile : false
      }),
      setContainerSizes   : () => ref => ({
        containerHeight : ref.getBoundingClientRect().height * 1.2,
        containerWidth  : ref.getBoundingClientRect().width * 2.4,
      }),
      setDownloadWidgetRef : () => ref => ({
        containerRef: ref
      }),
      setDownloadWidgetRefClone : () => ref => ({
        containerRefclone: ref
      }),
      toggleIcons         : ({ isShowingIcons }) => () => ({ isShowingIcons: !isShowingIcons }),
      setDownloadedFile   : () => bool => ({ downloadedFile: bool }),
      onAnswers           : () => answers => ({ answers : R.groupBy(R.prop('storeVisitId'))(answers) }),
    }),
    withHandlers({
      exportGraph: ({ toggleIcons, setDownloadWidget }) => () => {
        setDownloadWidget(true);
        toggleIcons();
      }
    }),
    lifecycle({
      componentDidUpdate() {
        const {
          containerRef,
          containerRefclone,
          isDownloading,
          downloadedFile,
          setDownloadedFile,
          toggleIcons,
          setDownloadWidget,
          widgetData: {
            title
          }
        } = this.props;

        if (containerRef && isDownloading && !downloadedFile) {
          setTimeout(() => domtoimage.toBlob(R.isNil(containerRefclone) ? containerRef :  containerRefclone)
            .then(blob => {
              saveAs(blob, title + ".png");
              toggleIcons();
              setDownloadWidget(false);
            }), 5000);
          setDownloadedFile(true);
        }
      }
    })
)(Widget);
