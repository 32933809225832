import React     from 'react';
import styled    from 'styled-components';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

const Toolbar = styled.div`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 40px;
  padding         : 0 30px;
`;

const LogoutButton = ({
  onLogout,
}) => (
  <Toolbar>
      <Button
        variant="raised"
        onClick={onLogout}
        style={{
          border          : '2px solid #DE898C',
          color           : '#F3212E',
          backgroundColor : 'transparent',
          borderRadius    : '5px',
          padding         : '8px',
          minWidth        : '120px',
          letterSpacing   : '1px',
          boxShadow       : 'none'
        }}
      >
        LOGOUT
      </Button>
  </Toolbar>
);

LogoutButton.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default LogoutButton;
