import React       from 'react';
import PropTypes   from 'prop-types';
import styled      from 'styled-components';
import AddIcon     from '@material-ui/icons/AddCircleOutline';

import Uploader from '../../../upload/components/Uploader';

const Wrapper = styled(Uploader)`
  display         : flex;
  flex-direction  : row;
  justify-content : space-between;
  align-items     : center;
  cursor          : pointer;
`;

const DocumentUploader = ({ onUpload, handleFile, progressCB }) => (
  <Wrapper
    onUpload={onUpload}
    handleFile={handleFile}
    progressCB={progressCB}
  >
    <p>Add...</p>
    <AddIcon/>
  </Wrapper>
);

DocumentUploader.propTypes = {
  onUpload   : PropTypes.func.isRequired,
  progressCB : PropTypes.func
};

export default DocumentUploader;
