import React                 from 'react';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';

import { Checkbox, Menu } from '@material-ui/core';
import { FilterList }     from '@material-ui/icons';

const MENU_ITEMS = [
  { name: 'Active',   value: 'active'},
  { name: 'Archived', value: 'archived'},
  { name: 'Draft',    value: 'draft'},
  { name: 'All',      value: 'all'}
];

const STATUSES = {
  active   : 1,
  archived : 2,
  draft    : 3,
  all      : null,
};

const FilterButton = styled.div`
  cursor : pointer;
  float  : right;

  span {
    font-size   : 14px;
    font-weight : 500;
  }
`;

const SortValueContainer = styled.div`
  display : flex;
  width   : 200px;
  outline : none !important;
`;

const SortableTableByState = ({ anchorEl, open, close, onHandleSortChange, selectedSort }) => (
  <div>
    <FilterButton
      aria-owns={anchorEl ? 'sort-menu' : null}
      aria-haspopup="true"
      onClick={({ currentTarget }) => open(currentTarget)}
    >
      <FilterList />
    </FilterButton>

    <Menu
      id="sort-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={close}
    >
      {MENU_ITEMS.map(i => (
        <SortValueContainer key={i.value}>
          <Checkbox
            checked  = {selectedSort === i.value}
            onChange = {onHandleSortChange}
            value    = {i.value}
          />
          <p>{i.name}</p>
        </SortValueContainer>
      ))}
    </Menu>
  </div>
);

const selectSort = withStateHandlers(
  ({
    anchorEl        = null,
    selectedSort    = MENU_ITEMS[0].value,
  }) => ({ anchorEl, selectedSort }),
  {
    open               : () => currentTarget => ({ anchorEl: currentTarget }),
    close              : () => () => ({ anchorEl: null }),
    onHandleSortChange : (__, { onFilterByStatus }) => ({ target }) => {
      onFilterByStatus(STATUSES[target.value]);
      return { selectedSort: target.value, anchorEl: null };
    }
  },
);

export default selectSort(SortableTableByState);
