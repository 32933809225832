import * as R from 'ramda';

import {
  EXPORT_TO_FILE,
  EXPORT_TO_FILE_START,
  HIDE_FILE_EXPORT_MODAL
} from './FileExportActions';

export const STATE_KEY = 'fileExport';

const initialState = {};

const FileExportReducer = (state = initialState, action) => {

  switch (action.type) {

    case `${EXPORT_TO_FILE}_SUCCESS`: {
      const fileExportLink  = R.path(['payload', 'data', 'data', 'url'])(action),
            fileExportError = R.path(['payload', 'response', 'data', 'data', 'err', 'message'])(action)
              || R.path(['payload', 'message'])(action);

      if (fileExportError) {
        return R.compose(
          R.assoc('fileExportLink',  null),
          R.assoc('fileExportError', fileExportError),
        )(state);
      }

      if (fileExportLink) {
        return R.compose(
          R.assoc('fileExportLink',  fileExportLink),
          R.assoc('fileExportError', null),
        )(state);
      }

      return state;

    }

    case EXPORT_TO_FILE_START : {
      return R.compose(
        R.assoc('showFileExportModal', true),
        R.assoc('fileExportLink',      null),
        R.assoc('fileExportError',     null)
      )(state);
    }

    case `${EXPORT_TO_FILE}_ERROR` : {
      const fileExportError = R.path(['payload', 'data', 'err'])(action);

      if (fileExportError) {
        return R.compose(
          R.assoc('fileExportLink',  null),
          R.assoc('fileExportError', fileExportError),
        )(state);
      }

      return state;
    }

    case HIDE_FILE_EXPORT_MODAL : {
      return R.compose(
        R.assoc('showFileExportModal', false)
      )(state);
    }

    default : {
      return state;
    }
  }
};

export const getShowFileExportModal = R.path([STATE_KEY, 'showFileExportModal']);
export const getFileExportLink      = R.path([STATE_KEY, 'fileExportLink']);
export const getFileExportError     = R.path([STATE_KEY, 'fileExportError']);

export default FileExportReducer;
