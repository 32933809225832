import * as R from 'ramda';
import moment from 'moment';

import { isClientUser } from '../../client/ClientReducer';

import {
  getTableColumnsForPage,
  maybeDecorateColumn
} from '../../../utils/tableColumns';

import {
  CLEAR_CURRENT_DATA,
  CLEAR_ERROR,
  CREATE,
  FETCH,
  FETCH_ALL,
  FILTER_BY_ACTION,
  IS_EDITING,
  SHOW_SEARCH_FILTER,
  UPDATE,
  TRANSFORM_DATA
} from '../actions/AlertsActions';

export const STATE_KEY = 'alerts';

const initialState = {
  isEditing       : false,
  data            : {},
  transformedData : {},
  tableColumns    : [],
  error           : null,
  filterList      : {}
};

const maybeAppendDecorators = R.map((filter={}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'alerts', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
});

const appendFilterType = R.map((filter={}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'alerts', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const ensureClientDoesNotSeeHiddenRecords = _isClientUser => n => {
  if (n.privacy === HIDDEN_FROM_CLIENT) {
    return !_isClientUser;
  } else {
    return true;
  }
};

const HIDDEN_FROM_CLIENT = R.path(['WINSTON', 'svrPrivacy', 'HIDDEN_FROM_CLIENT'])(window);

const AlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_SUCCESS`: {
      const page = 'alerts';
      const tableColumns = getTableColumnsForPage('alerts', page);

      return R.compose(
        R.mergeDeepRight(state),
        R.assoc('error', null),
        R.assocPath(['tableColumns', page], tableColumns),
        data => ({ data, transformedData : R.clone(data) }),
        R.indexBy(R.prop('id')),
        R.when(R.has('err'), R.always([])),
        R.pathOr([], ['payload', 'data', 'data']),
      )(action);
    }

    case `${FETCH}_SUCCESS` :
    case `${CREATE}_SUCCESS`:
    case `${UPDATE}_SUCCESS`: {
      const data   = R.pathOr({}, ['payload', 'data', 'data'], action);
      if(R.has('err', data)) {
        return R.assoc('error', data.err, state);
      }

      const _isClientUser = isClientUser();
      if (ensureClientDoesNotSeeHiddenRecords(_isClientUser)(data)) {
        return R.compose(
          R.mergeDeepRight(state),
          R.assoc('transformedData', [R.clone(data)]),
          R.assocPath(['data', data.id+''], data),
        )({});
      } else {
        return R.assocPath(['data', data.id+''], {}, state);
      }
    }

    case FILTER_BY_ACTION : {

      const { payload : { filters, page = 'alerts' } } = action;
      
      const filterList = R.compose(
        maybeAppendDecorators,
        appendFilterType,
        R.defaultTo({})
      )(filters);

      const filterListNeedsUpdating = R.compose(
        R.not,
        R.both(R.complement(R.isEmpty), R.equals(filterList)),
        R.propOr({}, 'filterList')
      )(state);

      if (filterListNeedsUpdating) {

        const _state = R.assocPath(['filterList', page], filterList, state);

        if (!R.isEmpty(filterList)) {
          try {
            window.localStorage.setItem(`persistedFilters:${STATE_KEY}:${page}`, JSON.stringify(filterList));
          } catch (e) {
            return _state;
          }
        } else {
          try {
            window.localStorage.removeItem(`persistedFilters:${STATE_KEY}:${page}`);
          } catch (e) {
            return _state;
          }
        }

        return _state;
      } else {
        return state;
      }

    }

    case TRANSFORM_DATA : {

      const emptyData = R.cond([
        [R.equals('string'), R.always('')],
        [R.equals('number'), R.always(0)],
        [R.equals('date'),   R.equals(new Date(-8640000000000000))],
        [R.T,                R.always('')]
      ])(action.payload.sortType);

      const _isClientUser = isClientUser();

      const compareValueIfFilterHasSelections = props => {
        const path        = props[0],
              data        = props[1],
              decorator   = props[2],
              type        = props[3],
              parsedData  = type === 'number' ? R.map(d => parseInt(d, 10))(data) : data;

        const filterableValue = v => maybeDecorateColumn(decorator)(R.path(path, v));

        const isWithinDateRange = R.anyPass(
          R.map(range => date => moment(date, 'MM/DD/YYYY hh:mm:A').isBetween(moment(range[0], 'YYYY-MM-DD'), moment(range[1], 'YYYY-MM-DD'), 'day', '[]'))(data)
        );

        const finder = type === 'date' ? isWithinDateRange : R.either(R.contains(R.__, parsedData), R.contains(R.__, data));
        
        return parsedData.length ? R.compose(finder, filterableValue) : R.T;
      };

      const maybeStringToNumber  = value => {
        if (action.payload.sortType === 'number' && R.is(String, value)) {
          return parseInt(value, 10);
        }

        return value;
      };
      const maybeLowerCaseString = R.when(R.is(String), R.toLower);
      const ensureEmptyTypeValue = R.unless(R.identity, R.always(emptyData));

      const allFilters = R.compose(
        R.map(compareValueIfFilterHasSelections),
        R.values,
        R.map(R.props(['path', 'data', 'decorator', 'type']))
      )(action.payload.filterList);

      const transformedData = R.compose(
        R.sort(
          R[`${action.payload.order}end`](
            R.compose(
              maybeStringToNumber,
              ensureEmptyTypeValue,
              maybeLowerCaseString,
              R.path(action.payload.orderBy.split('.'))
            )
          )
        ),
        R.filter(R.both(
          R.allPass(allFilters),
          ensureClientDoesNotSeeHiddenRecords(_isClientUser)
        ))
      )(action.payload.data);
      
      return R.assoc('transformedData', transformedData)(state);
    }

    case SHOW_SEARCH_FILTER : {
      return { ...state, showSearchFilter : action.payload };
    }
    case IS_EDITING: {
      return  R.assoc('isEditing', action.payload, state);
    }
    case CLEAR_CURRENT_DATA : {
      return R.assoc('data', [], state);
    }
    case CLEAR_ERROR: {
      return  R.omit(['error'], state);
    }
    default: {
      return state;
    }
  }
};

const determineTableColumnPage = page => {
  return page;
};

export const getShowSearchFilter = R.pathOr(false, [STATE_KEY, 'showSearchFilter']);
export const getFilterList       = (state, page = 'alerts') => {
  return R.compose(
    alertsPage => R.pathOr({},    [STATE_KEY, 'filterList', alertsPage], state),
    determineTableColumnPage
  )(page);
};
export const getAlert            = id => R.pathOr(null, [STATE_KEY, 'data', id]);
export const getAlerts           = state => R.values(R.pathOr({}, [STATE_KEY, 'data'], state)) || [];
export const getTransformedData  = state => R.values(R.pathOr([], [STATE_KEY, 'transformedData'], state)) || [];
export const getError            = R.pathOr(null, [STATE_KEY, 'error']);
export const getTableColumns     = (state, page = 'alerts') => {
  return R.compose(
    alertsPage => R.pathOr(getTableColumnsForPage('alerts', alertsPage),    [STATE_KEY, alertsPage, 'tableColumns'], state),
    determineTableColumnPage
  )(page);
};

export default AlertsReducer;
