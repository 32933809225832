import * as R from 'ramda';

const LOCATION  = 'location',
      BRAND     = 'brand',
      ASSOCIATE = 'associate';

const filterByLocation = (data, svrResponse) => {
  const location = R.pathOr('', ['metaJson', 'projectEventLocation'], svrResponse);

  return data.filter(d => R.pathOr('', ['metaJson', 'projectEventLocation'], d) === location);
};

const filterByClientId = (data, svrResponse) => {
  const clientId = R.pathOr('', ['metaJson', 'clientId'], svrResponse);

  return data.filter(d => R.pathOr('', ['metaJson', 'clientId'], d) === clientId);
};

const filterByAssociateId = (data, svrResponse) => {
  const associateId = R.pathOr('', ['metaJson', 'MCID'], svrResponse);

  return data.filter(d => R.pathOr('', ['metaJson', 'MCID'], d) === associateId);
};

const filterData = (data, svrResponse, filter) => {
  const filterByType = R.cond([
    [R.equals(LOCATION),  () => filterByLocation(data, svrResponse)],
    [R.equals(BRAND),     () => filterByClientId(data, svrResponse)],
    [R.equals(ASSOCIATE), () => filterByAssociateId(data, svrResponse)]
  ]);

  const filteredData = filterByType(filter);

  return filteredData;
};

export default filterData;

