import * as R from 'ramda';

import recursivelyPluck from './recursivelyPluck';

const getQuestionsByAfterType = (type) => R.compose(
  R.filter(R.pathEq(['meta', `${type}Type`], `after-${type}`)),
  R.flatten,
  R.map(recursivelyPluck('content'))
);

const getPairedUuid = R.path(['meta', 'pairedQuestionUuid']);

const getPairedIds = (type) => R.compose(
  R.map(getPairedUuid),
  R.filter(R.pathSatisfies(R.is(String), ['meta', 'pairedQuestionUuid'])),
  R.flatten,
  R.map(getQuestionsByAfterType(type)),
  R.path(['builder', 'svrForm', 'content', 'structure'])
);

const rejectPaired = pairedIds => R.reject(q => pairedIds.some(p => p === q.uuid));

const getPhotoQuestionsByBeforeType = state => R.compose(
  rejectPaired(getPairedIds('photo')(state)),
  R.filter(R.pathEq(['meta', 'photoType'], 'before-photo')),
  R.flatten,
  R.map(recursivelyPluck('content'))
);

const getVideoQuestionsByBeforeType = state => R.compose(
  rejectPaired(getPairedIds('video')(state)),
  R.filter(R.pathEq(['meta', 'videoType'], 'before-video')),
  R.flatten,
  R.map(recursivelyPluck('content'))
);

export {
  getPhotoQuestionsByBeforeType,
  getVideoQuestionsByBeforeType
};
