import * as R             from 'ramda';
import React              from 'react';
import { Helmet }         from 'react-helmet';
import styled             from 'styled-components';
import { Redirect }       from 'react-router';
import { NavLink, Route } from 'react-router-dom';
import PropTypes          from 'prop-types';
import { connect }        from 'react-redux';

import {
  compose,
  defaultProps,
  lifecycle,
  setPropTypes, withStateHandlers, withHandlers
} from 'recompose';

import RollUpsListPage       from '../saved-rollups/pages/SavedRollupsListPage';
import DashboardPage         from '../dashboard/pages/DashboardPage';
import Header                from '../../components/Header';
import SvrResponsesPage      from '../svr-responses/pages/SvrResponsesPage';
import EditRollUpPage        from '../saved-rollups/pages/EditSavedRollupPage';
import SavedDashboardMenu    from './SavedDashboardMenu';
import UpsertDashboardDialog from '../dashboard/components/UpsertDashboardDialog';

import {
  getUserId
} from '../auth/AuthReducer';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser
} from '../client/ClientReducer';

import {
  setCurrentClient  as _setCurrentClient,
  setCurrentProject as _setCurrentProject
} from '../client/ClientActions';

import {
  getCurrentSavedDashboard,
  getSavedDashboards
} from '../dashboard/SavedDashboardsReducer';

import {
  fetchSavedDashboards     as _fetchSavedDashboards,
  setCurrentSavedDashboard as _setCurrentSavedDashboard,
  updateSavedDashboard     as _updateSavedDashboard
} from '../dashboard/SavedDashboardsActions';

import {
  fetchSvrs as _fetchSvrs
} from '../svr/SvrActions';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';

const StyledNavLink = styled(NavLink)`
  display         : flex;
  align-items     : center;
  height          : 32px;
  font-size       : 12px;
  margin-right    : 40px;
  font-weight     : lighter;
  letter-spacing  : 1px;
  color           : ${props => props.theme.main.black};
  border-bottom   : 2px solid transparent;
  border-top      : 2px solid transparent;
  text-decoration : none;
  cursor          : pointer;
  box-sizing      : border-box;
  transition      : .3s opacity;

  &:hover {
    opacity : 0.8;
  }

  &.selected {
    border-bottom : 4px solid #F3212E;
  }
`;

const NavBarWrapper = styled.div`
  display          : flex;
  flex-direction   : row;
  background-color : #fff;
  min-height       : 32px;
  padding-left     : 40px;
  box-shadow       :
    0px 0px 5px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
`;

const ReportPage = ({
  clients,
  currentClient,
  currentProject,
  setCurrentClient,
  setCurrentProject,
  savedDashboards,
  currentSavedDashboard,
  setCurrentSavedDashboard,
  isClientUser,
  editingDashboard,
  setEditingDashboard,
  handleUpdateDashboard,
  tokenUserId,
  history,
  isFetchingData
}) => (
  <div>
    <Helmet>
      <title>Report Page</title>
    </Helmet>
    <Header
      clients           = {clients}
      currentClient     = {currentClient}
      currentProject    = {currentProject}
      setCurrentClient  = {setCurrentClient}
      setCurrentProject = {setCurrentProject}
      isClientUser      = {isClientUser()}
    />
    <NavBarWrapper>
      <SavedDashboardMenu
        savedDashboards          = { savedDashboards }
        setCurrentSavedDashboard = { setCurrentSavedDashboard }
        StyledNavLink            = { StyledNavLink }
        currentSavedDashboard    = { currentSavedDashboard }
        onEdit                   = { setEditingDashboard }
        tokenUserId              = { tokenUserId }
        history                  = { history }
      />
      <StyledNavLink to="/dashboard/roll-ups"      activeClassName="selected" margin="true">Roll-ups</StyledNavLink>
      <StyledNavLink to="/dashboard/svr-responses" activeClassName="selected">View SVR Responses</StyledNavLink>
    </NavBarWrapper>
    <Route path="/dashboard/dashboards"         component={DashboardPage} />
    <Route exact path="/dashboard"              component={() => <Redirect to="/dashboard/dashboards" />} />
    <Route exact path="/dashboard/roll-ups"     component={RollUpsListPage} />
    <Route path="/dashboard/roll-ups/:id/edit"  component={EditRollUpPage} />
    <Route path="/dashboard/roll-ups/from-widget/:widgetNamespace" component={EditRollUpPage} />
    <Route path="/dashboard/svr-responses"      render={props => <SvrResponsesPage {...props} namespace="reporting" />} />
    <Route path="/dashboard/saved/:id"          component={DashboardPage} />

    { !R.isEmpty(editingDashboard) ? (
      <UpsertDashboardDialog
        open             = {true}
        onClose          = {() => setEditingDashboard({}) }
        onCancel         = {() => setEditingDashboard({}) }
        onConfirm        = {payload => handleUpdateDashboard(editingDashboard.id, payload)}
        onDelete         = {() => handleUpdateDashboard(editingDashboard.id, { status : 0 })}
        dialogTitle      = 'Edit Dashboard'
        tokenUserId      = {tokenUserId}
        editingDashboard = {editingDashboard}
      />
    ) : null }

    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
      <DialogTitle>Loading...</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>
  </div>
);

const mapStateToProps = state => ({
  clients               : getClients(state),
  currentClient         : getCurrentClient(state),
  currentProject        : getCurrentProject(state),
  savedDashboards       : getSavedDashboards(state),
  currentSavedDashboard : getCurrentSavedDashboard(state),
  tokenUserId           : getUserId(state),
  isClientUser
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  setCurrentSavedDashboard : payload => dispatch(_setCurrentSavedDashboard(payload))  
});

const getSavedDashboardId = R.compose(
  R.when(R.identity, R.partialRight(parseInt, [10])),
  R.view(R.lensIndex(1)),
  R.match(/\/dashboard\/saved\/([0-9]+)/)
);

const withManage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    clients               : [],
    savedDashboards       : [],
    currentSavedDashboard : {},
    editingDashboard      : {}
  }),
  setPropTypes({
    clients               : PropTypes.array,
    savedDashboards       : PropTypes.array,
    currentSavedDashboard : PropTypes.object,
    editingDashboard      : PropTypes.object
  }),
  withStateHandlers(
    ({
       editingDashboard = {},
       isFetchingData   = false
     }) => ({
      editingDashboard,
      isFetchingData
    }),
    {
      setEditingDashboard : () => item => ({
        editingDashboard : item
      }),

      toggleWidgetCreator  : () => bool => ({
        isWidgetCreatorOpen : bool
      }),
      setFetchingData       : () => fetching => ({
        isFetchingData : fetching
      })
    }
  ),
  withHandlers({
    setCurrentClient      : props => payload => {
      props.dispatch(_setCurrentClient(payload));
      Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSvrs()))
      .then(() => props.setFetchingData(false));
    },
    setCurrentProject     : props => payload => {
      props.dispatch(_setCurrentProject(payload));
      Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSvrs()))
      .then(() => props.setFetchingData(false));
    },
    fetchSavedDashboards  : props => payload => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSavedDashboards(payload)))
      .then(() => props.setFetchingData(false));
    },
    updateSavedDashboard  : props => (id, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_updateSavedDashboard(id, payload)))
      .then(data => {
        props.setFetchingData(false);
        return Promise.resolve(data);
      });
    },
    handleUpdateDashboard: props => (dashboardId, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_updateSavedDashboard(dashboardId, payload)))
      .then(res => {
        const updatedDashboard        = R.path(['payload', 'data', 'data'])(res),
              useTenancy              = Boolean(props.currentClient.clientId),
              didDelete               = R.propEq('status', 0, payload),
              affectsCurrentDashboard = dashboardId === props.currentSavedDashboard.id;

        if (didDelete) {
          if (affectsCurrentDashboard) {
            props.setCurrentSavedDashboard({});
            props.history.push(`/dashboard/dashboards`);
          } else {
            props.dispatch(_fetchSavedDashboards(useTenancy))
              .then(() => props.setCurrentSavedDashboard(props.currentSavedDashboard));
          }
        } else {
          if (!affectsCurrentDashboard) {
            props.history.push(`/dashboard/saved/${dashboardId}`);
          } else {
            props.setCurrentSavedDashboard(updatedDashboard);
          }
        }
      })
      .then(() => props.setEditingDashboard({}))
      .then(() => props.setFetchingData(false));
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.history.location.pathname === '/dashboard' && this.props.history.push('/dashboard/dashboards');

      const useTenancy = Boolean(this.props.currentClient.clientId);
      this.props.fetchSavedDashboards(useTenancy)
        .then(() => {
          const subPath          = this.props.history.location.pathname;
          const savedDashboardId = getSavedDashboardId(subPath);

          if (savedDashboardId) {
            const matchingSavedDashboard = R.find(R.propEq('id', savedDashboardId), this.props.savedDashboards);
            this.props.setCurrentSavedDashboard(matchingSavedDashboard);
          }
        });
    },
    componentWillReceiveProps(nextProps) {
      this.props.history.location.pathname === '/dashboard' && this.props.history.push('/dashboard/dashboards');

      if (!R.equals(nextProps.currentClient, this.props.currentClient)) {
        const useTenancy = Boolean(nextProps.currentClient.clientId);
        this.props.fetchSavedDashboards(useTenancy);
      }
    }
  })
);

export default withManage(ReportPage);
