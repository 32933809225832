import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, defaultProps, setDisplayName, setPropTypes } from 'recompose';

import { getUserId, getUserRole } from './AuthReducer';

const mapStateToProps = state => ({
  userId: getUserId(state),
  userRole: getUserRole(state),
});

const withUser = wrappedComponent =>
  compose(
    setDisplayName('withUser'),
    connect(mapStateToProps),
    defaultProps({
      userId: '',
    }),
    setPropTypes({
      userRole: PropTypes.number.isRequired,
    })
  )(wrappedComponent);

export default withUser;
