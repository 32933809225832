import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';
import * as R    from 'ramda';
import moment    from 'moment';

import Footer from './Footer';

const sortedData = data => data.sort((a, b) => new Date(b.actualCheckinTime) - new Date(a.actualCheckinTime));
const formatDate = date => !R.isNil(date) ? moment(date).format('MM/DD/YYYY - ') : '';

const Modal = ({ withoutCancel, children, onApprove, onCancel, list }) => (
  <Modal.Wrapper>
    <Modal.Container listLength={list.length}>
      <Modal.Package>
        <Modal.Title style={{ textAlign: 'center' }}>{children}</Modal.Title>
        <Modal.ListContainer>
          <Modal.List>
            {sortedData(list).map(i => (
              <li key={i.id}>
                {formatDate(i.actualCheckinTime)} {R.pathOr('', ['metaJson', 'projectName'], i)}
              </li>
            ))}
          </Modal.List>
        </Modal.ListContainer>
      </Modal.Package>
      <Footer
        withoutCancel={withoutCancel}
        onApprove={onApprove}
        onCancel={onCancel}
      />
    </Modal.Container>
  </Modal.Wrapper>
);

Modal.propTypes = {
  withoutCancel : PropTypes.bool,
  children      : PropTypes.node.isRequired,
  onApprove     : PropTypes.func.isRequired,
  onCancel      : PropTypes.func.isRequired,
};

Modal.defaultProps = {
  withoutCancel : false,
};

Modal.Wrapper = styled.div`
  top      : 0;
  bottom   : 0;
  width    : 100%;
  height   : 100%;
  position : fixed;
  z-index  : 9999;
`;

Modal.Container = styled.div`
  position      : relative;
  width         : 500px;
  height        : ${({ listLength }) => listLength > 5 ? '300px' : '170px'};
  background    : #fff;
  margin        : 5px auto;
  border-radius : 3px;
  box-shadow    : 0 2px 4px rgba(0, 0, 0, .2);
  top           : 40%;
`;

Modal.Package = styled.div`
  display         : flex;
  flex-direction  : column;
  height          : 80%;
  padding         : 0 40px;
  text-indent     : 5px;
  font-weight     : 300;
  font-size       : 16px;
`;

Modal.Title = styled.p`
  text-align: center;
`;

Modal.ListContainer = styled.div`
  overflow: scroll;
`;

Modal.List = styled.div`
  font-size: 13px;
`;

export default Modal;
