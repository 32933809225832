import React          from 'react';
import styled         from 'styled-components';
import { Paper }      from '@material-ui/core';

export const FileRowWrapper = styled(props => <Paper {...props} />)`
  display          : flex;
  justify-content  : space-between;
  align-items      : center;
  font-size        : .875rem;
  margin           : .5rem 0;
  margin-left      : .5rem;
  padding          : 0 .5rem;
  border-left      : .625rem solid ${props => props.noBorder ? 'white' : '#f69d50'};
  box-sizing       : border-box;
  background-color : 'inherit';
`;

export const FileRow = styled.div`
  display: flex;
  align-items: center;
  margin       : .25rem .5rem;
  align-self   : center;
  margin-right : auto;
  cursor       : default;
  width: 100%;
`;

export const FileLink = styled.a`
  color: inherit;
  display: inline-block;
  vertical-align: bottom;
`;

export const FileLinkText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  display: inline-block;
  vertical-align: middle;
  text-decoration: underline;
`;
export const FileLinkExtension = styled.span`
  display: inline-block;
  vertical-align: middle;
  text-decoration: underline;
`;
export const FileCol = styled.span`
  & + span {
    padding-left: 15px;
  }
  display: inline-block;
  vertical-align: middle;
  width: ${props => props.width ? props.width : 'auto'};
  ${props => props.last ? 'margin-left: auto;' : null};
  ${props => props.flex1 ? 'flex: 1;' : null};
`;

export const FileColTitle = styled.strong`
  display: block;
`;

export const FileQuestionRelated = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
  vertical-align: bottom;
`;

export  const ButtonDeleteDocument = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;
