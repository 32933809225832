import React, { Component } from 'react';
import styled               from 'styled-components';

import { Button, Tooltip, Hidden } from '@material-ui/core';

const Toolbar = styled.div`
  position   : relative;
  top        : 4rem;
  margin     : 0;
  text-align : right;
  padding    : 0 2rem;
`;

class NotificationsPageToolbar extends Component {

  render() {

    const { onSend, selectedData } = this.props;

    return (
      <Hidden only={['xs', 'sm']}>
        <Toolbar>
          <Tooltip
            title      = {!selectedData.length ? 'Please first select records to send the notification.' : ''}
            placement  = "top-end"
            enterDelay = {300}
          >
            <div>
              <Button
                onClick  = {onSend}
                disabled = {!selectedData.length}
                style    = {{
                  border          : '2px solid #DE898C',
                  color           : '#F3212E',
                  backgroundColor : 'transparent',
                  borderRadius    : '5px',
                  padding         : '0.875rem',
                  minWidth        : '120px',
                  letterSpacing   : '1px',
                }}
              >
                Create Notification
              </Button>
            </div>
          </Tooltip>
        </Toolbar>
      </Hidden>
    );
  }
}

export default NotificationsPageToolbar;
