import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as R from 'ramda';
import moment from 'moment';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';

import ChangesOnSvrModal from '../../modals/ChangesOnSvrModal';

import SortableTableByState from '../../../components/SortableTableByState';
import theme from '../../../styles/theme';

const HEIGHT = 30;
const STATUSES = ['Deleted', 'Active', 'Archived', 'Draft'];
const SVR_DELETE = 0,
  SVR_ACTIVE = 1,
  SVR_ARCHIVE = 2,
  SVR_DRAFT = 2;

const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-start;
  padding-left    : 30px;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 20px;

  & > div {
    margin-right : 20px;
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'}
    cursor       : pointer;
  }
`;

const ClientWarning = styled.div`
  opacity     : ${({ show }) => show ? 1 : 0}
  transition  : opacity 0.5s linear;
  display     : inline-block;
  margin-left : 20px;
  font-size   : 15px;
  color       : #CC0000;
  font-style  : italic;
`;

const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              style={{ height: `${HEIGHT}px` }}
            />
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'title' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'title'}
                direction={order}
                onClick={this.createSortHandler('title')}
              >
                Title
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'timestamp' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'timestamp'}
                direction={order}
                onClick={this.createSortHandler('timestamp')}
              >
                Last Modified
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric={false}
            padding="none"
            sortDirection={orderBy === 'canonicalKey' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'canonicalKey'}
                direction={order}
                onClick={this.createSortHandler('canonicalKey')}
              >
                SVR Form ID
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'authorEmail' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'authorEmail'}
                direction={order}
                onClick={this.createSortHandler('authorEmail')}
              >
                Author
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'editorEmail' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'editorEmail'}
                direction={order}
                onClick={this.createSortHandler('editorEmail')}
              >
                Last Editor
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'state' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'state'}
                direction={order}
                onClick={this.createSortHandler('state')}
              >
                State
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'state' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'state'}
                direction={order}
                onClick={this.createSortHandler('state')}
              >
                Client
              </TableSortLabel>
            </Tooltip>
          </TableCell>

        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    minHeight: '53px',
    padding: '0px 8px 25px 15px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: '1 1 100%'
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  typography: {
    fontWeight: 400,
    fontSize: '22px',
    letterSpacing: '1px'
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes, onFilterByStatus } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            variant="title"
            className={classes.typography}
          >
            Store Visit Reports
            <ClientWarning show={props.showClientWarning}>Please select a client above to edit a form.</ClientWarning>
          </Typography>
        )}
        <SortableTableByState onFilterByStatus={onFilterByStatus} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  showClientWarning: PropTypes.bool
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: '96%',
    marginTop: '12px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '80px'
  },

  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: [],
      data: R.sort(R.descend(R.prop('timestamp')), props.data),
      page: 0,
      rowsPerPage: props.rowsPerPage,
      showModal: false,
      clientList: props.clientList,
      rowsPerPageOptions: props.rowsPerPageOptions,
      showClientWarning: false,
      filterByStatus: 1,
      changeApprove: null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data = R.sort(
      R[`${order}end`](
        R.compose(
          R.toLower,
          R.propOr('', orderBy)
        )
      ), this.state.data);

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const filteredByStatusData = () => {
        if (this.state.filterByStatus) {
          return this.state.data.filter(d => d.status === this.state.filterByStatus);
        }

        return this.state.data;
      };

      this.setState({ selected: R.pluck('canonicalKey', filteredByStatusData()) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, canonicalKey) => {
    event.stopPropagation();

    const { selected } = this.state;
    const selectedIndex = selected.indexOf(canonicalKey);
    const firstItemIndex = 0;
    const lastItemIndex = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1), () => [].concat(selected, canonicalKey)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex), () => [].concat(selected.slice(0, -1))],
        [R.lt(0), () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = canonicalKey => R.contains(canonicalKey, this.state.selected);

  deleteSvrs = () => {
    this.updateSvr(SVR_DELETE);
  };

  activeSvrs = () => {
    this.updateSvr(SVR_ACTIVE);
  };

  archiveSvrs = () => {
    this.updateSvr(SVR_ARCHIVE);
  };

  draftSvrs = () => {
    this.updateSvr(SVR_DRAFT);
  };

  updateSvr = status => {
    const { onUpdate, data } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svr => {
          data.forEach(item => {
            if (R.equals(svr, item.canonicalKey)) {
              if (!R.equals(item.status, status)) {
                onUpdate(item.canonicalKey, { status: parseInt(status, 10) });
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected: [] });
      };

      this.setState({
        showModal: true,
        changeApprove: change,
      });
    }
  };

  toggleClientWarning = bool => {
    this.setState({ showClientWarning: bool });
  };

  onFilterByStatus = filterByStatus => {
    this.setState({ filterByStatus });
  };

  modalClose = () => {
    this.setState({
      showModal: false,
      changeApprove: null,
    });
  };

  render() {

    const { classes, goToEditPage, isEditable, data } = this.props;
    const { clientList, order, orderBy, selected, rowsPerPage, page, showModal, rowsPerPageOptions, filterByStatus, changeApprove } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const sortedData = data.sort((a, b) => b.id - a.id);

    const filteredByStatusData = () => {
      if (filterByStatus) {
        return sortedData.filter(d => d.status === filterByStatus).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }

      return sortedData.filter(d => d.status !== SVR_DELETE).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedIds={selected}
          onDelete={this.deleteSvrs}
          showClientWarning={this.state.showClientWarning}
          onFilterByStatus={this.onFilterByStatus}
        />
        <div className={classes.tableWrapper}>
          {showModal &&
            <ChangesOnSvrModal
              onApprove={changeApprove}
              onCancel={this.modalClose}
              items={selected.length}
            />
          }
          <Table className={classes.table}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={filteredByStatusData().length}
            />
            <TableBody>
              {filteredByStatusData().map(n => {
                const isSelected = this.isSelected(n.canonicalKey);
                const clientData = clientList.find(item => R.equals(item.clientId, R.pathOr(null, ['tenancyClientId'], n))) || {};

                return (
                  <TableRow
                    hover
                    onClick={() => { isEditable && goToEditPage(n.canonicalKey); }}
                    onMouseEnter={() => { !isEditable && this.toggleClientWarning(true); }}
                    onMouseLeave={() => { !isEditable && this.toggleClientWarning(false); }}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}
                    style={{
                      height: `${HEIGHT}px`,
                      cursor: isEditable ? 'pointer' : 'default',
                      opacity: isEditable ? 1 : 0.5,
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={event => this.handleClick(event, n.canonicalKey)}
                        style={{ height: `${HEIGHT}px` }}
                        checked={isSelected}
                      />
                    </TableCell>
                    <TableCell>{n.title}</TableCell>
                    <TableCell>{moment(n.timestamp).format('MM/DD/YYYY')}</TableCell>
                    <TableCell padding="none">{n.canonicalKey}</TableCell>
                    <TableCell>{n.authorEmail}</TableCell>
                    <TableCell>{n.editorEmail}</TableCell>
                    <TableCell>{STATUSES[n.status]}</TableCell>
                    <TableCell>{clientData.clientName || '\u2014'}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: HEIGHT * (emptyRows > 5 ? 5 : emptyRows) }}>
                  <TableCell colSpan={12} style={{ height: `${HEIGHT}px` }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={1}
                  count={filteredByStatusData().length}
                  style={{ height: `${HEIGHT}px` }}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        <Footer isSelected={selected.length > 0}>
          <div style={{ color: theme.palette.pblack.black }} onClick={this.archiveSvrs}>ARCHIVE</div>
          <div style={{ color: theme.palette.pblack.black }} onClick={this.activeSvrs}>ACTIVATE</div>
        </Footer>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    canonicalKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    client: PropTypes.string,
    status: PropTypes.number,
    timestamp: PropTypes.string
  })),
  rowsPerPage: PropTypes.number,
  classes: PropTypes.object.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  isEditable: PropTypes.bool
};

EnhancedTable.defaultProps = {
  data: [],
  rowsPerPage: 50,
  rowsPerPageOptions: [25, 50, 100, 250]
};

export default withStyles(styles)(EnhancedTable);
