import React  from 'react';
import styled from 'styled-components';
import logo   from '../../images/newlogowintell.png';

const StyledLogo = styled.img`
  padding      : .6rem 0;
  margin-right : 0.7rem;
  max-width    : 75px;
  width        : 100%;
  height       : auto;
`;

const Logo = ({ style }) => <StyledLogo src={logo} style={style} />;

export default Logo;
