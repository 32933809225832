import * as R             from 'ramda';
import React              from 'react';
import styled             from 'styled-components';
import { NavLink, Route } from 'react-router-dom';
import PropTypes          from 'prop-types';
import { connect }        from 'react-redux';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
} from 'recompose';

import Header from '../../components/Header';

import SvrListPage            from '../svr/pages/SvrListPage';
import CommonQuestionListPage from '../common-questions/pages/CommonQuestionListPage';
import SvrResponsesPage       from '../svr-responses/pages/SvrResponsesPage';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser
} from '../client/ClientReducer';

import {
  setCurrentClient  as _setCurrentClient,
  setCurrentProject as _setCurrentProject
} from '../client/ClientActions';

import {
  getSavedViews,
  getCurrentSavedView
} from '../saved-views/SavedViewsReducer';

import {
  setCurrentSavedView as _setCurrentSavedView,
  fetchSavedViews     as _fetchSavedViews
} from '../saved-views/SavedViewsActions';

import { fetchSvrs as _fetchSvrs } from '../svr/SvrActions';

import SavedViewMenu from './SavedViewMenu';

const StyledNavLink = styled(NavLink)`
  display         : flex;
  align-items     : center;
  height          : 32px;
  font-size       : 12px;
  margin-right    : 40px;
  font-weight     : lighter;
  letter-spacing  : 1px;
  color           : ${props => props.theme.main.black};
  border-bottom   : 2px solid transparent;
  border-top      : 2px solid transparent;
  text-decoration : none;
  cursor          : pointer;
  box-sizing      : border-box;
  transition      : .3s opacity;

  &:hover {
    opacity : 0.8;
  }

  &.selected {
    border-bottom : 4px solid #F3212E;
  }
`;

const NavBarWrapper = styled.div`
  display          : flex;
  flex-direction   : row;
  background-color : #fff;
  min-height       : 32px;
  padding-left     : 40px;
  box-shadow       :
    0px 0px 5px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
`;

const manageSvrResponsesFilterList = () => {
  return {
    'Review State' : {
      pathStr   : 'metaJson.reviewState',
      path      : ['metaJson', 'reviewState'],
      label     : 'Review State',
      decorator : v => window.WINSTON.statuses.reviewState[v || 0],
      data      : R.compose(
        values => R.filter(v => R.equals('Pending Review', v) || R.equals('Resubmitted', v), values),
        R.values
      )(window.WINSTON.statuses.reviewState)
    }
  };
};

const ManagePage = ({
  clients,
  currentClient,
  currentProject,
  setCurrentClient,
  setCurrentProject,
  savedViews,
  currentSavedView,
  setCurrentSavedView
}) => (
  <div>
    <Header
      clients           = {clients}
      currentClient     = {currentClient}
      currentProject    = {currentProject}
      setCurrentClient  = {setCurrentClient}
      setCurrentProject = {setCurrentProject}
      isClientUser      = {isClientUser()}
    />
    <NavBarWrapper>
      {
        !isClientUser() ? (
          <StyledNavLink to="/manage/svrs" activeClassName="selected" margin="true">SVRs</StyledNavLink>
        ) : null
      }
      {
        !isClientUser() ? (
          <StyledNavLink to="/manage/svr-responses" activeClassName="selected" margin="true">Review SVR
                                                                                             Responses</StyledNavLink>
        ) : null
      }
      {
        !isClientUser() ? (
          <StyledNavLink to="/manage/common-questions" activeClassName="selected" margin="true">Common Questions</StyledNavLink>
        ) : null
      }
      <SavedViewMenu
        savedViews          = { savedViews }
        setCurrentSavedView = { setCurrentSavedView }
        StyledNavLink       = { StyledNavLink }
      />
    </NavBarWrapper>
    {
      !isClientUser() ? (
        <div>
          <Route path="/manage/svrs"                      component={SvrListPage} namespace={`svrs`}/>
          <Route path="/manage/svr-responses"             render={props => <SvrResponsesPage {...props} namespace="svrResponses" initialFilterList={manageSvrResponsesFilterList()} />} />
          <Route path="/manage/common-questions"          component={CommonQuestionListPage} />
        </div>
      ) : null
    }
    <Route path="/manage/saved-views/:savedViewId"  render={props => <SvrResponsesPage {...props} namespace={`savedView${currentSavedView.id}`} savedView={currentSavedView} title={currentSavedView.title} description={currentSavedView.description} />} />
  </div>
);

const mapStateToProps = state => ({
  clients          : getClients(state),
  currentClient    : getCurrentClient(state),
  currentProject   : getCurrentProject(state),
  savedViews       : getSavedViews(state),
  currentSavedView : getCurrentSavedView(state)
});

const mapDispatchToProps = dispatch => ({
  setCurrentClient : payload => {
    dispatch(_setCurrentClient(payload));
    dispatch(_fetchSvrs());
  },
  setCurrentProject : payload => {
    dispatch(_setCurrentProject(payload));
    dispatch(_fetchSvrs());
  },
  setCurrentSavedView : payload => dispatch(_setCurrentSavedView(payload)),
  fetchSavedViews     : payload => dispatch(_fetchSavedViews(payload))
});

const getSavedViewId = R.compose(
  R.when(R.identity, R.partialRight(parseInt, [10])),
  R.view(R.lensIndex(1)),
  R.match(/\/manage\/saved-views\/([0-9]+)/)
);

const withManage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    clients          : [],
    savedViews       : [],
    currentSavedView : {}
  }),
  setPropTypes({
    clients          : PropTypes.array,
    savedViews       : PropTypes.array,
    currentSavedView : PropTypes.object
  }),
  lifecycle({
    componentDidMount() {
      this.props.history.location.pathname === '/manage' && this.props.history.push('/manage/svrs');

      const useTenancy = Boolean(this.props.currentClient.clientId);
      this.props.fetchSavedViews(useTenancy)
        .then(() => {
          const subPath     = this.props.history.location.pathname;
          const savedViewId = getSavedViewId(subPath);

          if (savedViewId) {
            const matchingSavedView = R.find(R.propEq('id', savedViewId), this.props.savedViews);
            this.props.setCurrentSavedView(matchingSavedView);
          }
        });

    },
    componentWillReceiveProps(nextProps) {
      this.props.history.location.pathname === '/manage' && this.props.history.push('/manage/svrs');

      if (!R.equals(nextProps.currentClient, this.props.currentClient)) {
        const useTenancy = Boolean(nextProps.currentClient.clientId);
        this.props.fetchSavedViews(useTenancy);
      }
    }
  })
);

export default withManage(ManagePage);
