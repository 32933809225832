import * as R      from 'ramda';
import { Helmet }  from 'react-helmet';
import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';
import uuidv4      from 'uuid/v4';
import {  createHash } from 'crypto';

import {
compose,
defaultProps,
lifecycle,
setPropTypes,
withStateHandlers,
withHandlers
} from 'recompose';

import { isClientUser } from '../../client/ClientReducer';

import {
  createSavedDashboard,
  updateSavedDashboard,
  setCurrentSavedDashboard,
  fetchSavedDashboards
} from '../SavedDashboardsActions';

import {
  getCurrentSavedDashboard
} from '../SavedDashboardsReducer';

import {
  fetchSvrResponses,
  filterByAction
} from '../../svr-responses/SvrResponsesActions';

import UpsertDashboardDialog from '../components/UpsertDashboardDialog';

import { transformSvrResponsesForWidget } from '../../../utils/widgetHelpers';
import { fetchSavedWidgetsByIds }         from '../../saved-widgets/SavedWidgetsActions';
import { getSavedWidgets }                from '../../saved-widgets/SavedWidgetsReducer';
import { getSvrResponses }                from '../../svr-responses/SvrResponsesReducer';
import { getUserId }                      from '../../auth/AuthReducer';
import withClient                         from '../../client/';

import InnerToolbar  from '../../../components/InnerToolbar';
import Widget        from '../../../components/widgets/Widget';
import WidgetCreator from '../../../components/widgets/WidgetCreator/WidgetCreator';

import { withStyles, withTheme } from '@material-ui/core/styles';

import { 
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Grid
} from '@material-ui/core';

const styles = theme => ({
  InnerToolbarButton : {
    marginLeft : theme.spacing.unit
  },
  widgetsContainer   : {
    display        : 'flex',
    alignItems     : 'flex-start',
    flexWrap       : 'wrap',
    height         : '100%',
    padding        : '10px 40px 0px 40px',
    justifyContent : 'space-between',
    minWidth       : '300px'
  }
});

const CLIENT_RESTRICTED_WIDGET_TYPES = [
  'feedback'
];

const isEligibleWidgetToDisplay = (currentClient={}) => widget => {
  if (!widget) {
    return false;
  }

  if (isClientUser()) {
    const isClientRestrictedWidgetType = R.contains(widget.metaJson.widgetType, CLIENT_RESTRICTED_WIDGET_TYPES),
          isTenancyClientWidget        = widget.tenancyClientId === currentClient.clientId;

    if (isClientRestrictedWidgetType || !isTenancyClientWidget) {
      return false;
    }
  }


  return true;
};

const DashboardPage = (
  {
    classes,
    currentClient,
    handleClickOpenDashboardCreation,
    handleCloseDashboardCreation,
    handleCreateDashboard,
    handleUpdateDashboard,
    isDashboardCreationOpen,
    isWidgetCreatorOpen,
    isFetchingData,
    svrResponses,
    toggleWidgetCreator,
    widgets,
    tokenUserId,
    newWidgetNamespace,
    viewUnderlyingWidgetData,
    dashboardId,
    currentSavedDashboard,
    setFetchingData
  }) => (
  <div>
    <Helmet>
      <title>Dashboard</title>
    </Helmet>
      <InnerToolbar
      buttonText    = "Add Widget"
      currentClient = {currentClient}
      handleOnClick = {() => toggleWidgetCreator}
    >
      <Button
        onClick = {handleClickOpenDashboardCreation}
        variant = "outlined"
      >
        Create Dashboard
      </Button>
      <Tooltip
        title      = {!dashboardId || dashboardId === 'undefined' ? 'First, create or load a dashboard' : ''}
        placement  = "bottom-end"
        enterDelay = {300}
      >
        <div>
          <Button
            className = {classes.InnerToolbarButton}
            onClick   = {toggleWidgetCreator}
            variant   = "outlined"
            disabled  = {!dashboardId || dashboardId === 'undefined'}
          >
            Add Widget
          </Button>
        </div>
      </Tooltip>
    </InnerToolbar>
    {
      dashboardId ? (
        <Grid container spacing={8} className={classes.widgetsContainer}>
          {R.map(R.compose(
            R.ifElse(
              isEligibleWidgetToDisplay(currentClient)
              ,
              _widget => {

              const widget = R.assocPath(
                ['filteredWidgetData'],
                transformSvrResponsesForWidget(_widget.filters, svrResponses(_widget.metaJson.namespace))
              )(_widget);

              return (
                <Grid item xs={12} sm={12} md={6} key={_widget.id}>
                  <Widget                  
                    widgetData      = {widget}
                    onTitleClick    = {() => viewUnderlyingWidgetData(widget)}
                    onMove          = {widgetIds => handleUpdateDashboard(dashboardId, widgetIds)}
                    widgetIds       = {currentSavedDashboard.widgetIds}
                    setFetchingData = {setFetchingData}
                  />
                </Grid>
              );

            }, R.always(null)),
            id => R.find(R.propEq('id', id), widgets || []) ,
          ))(currentSavedDashboard.widgetIds || [])}
        </Grid>
      ) : (
        <p>...redirecting to the first available dashboard.</p>
      )
    }

    <UpsertDashboardDialog
      open        = {isDashboardCreationOpen}
      onClose     = {handleCloseDashboardCreation}
      onCancel    = {handleCloseDashboardCreation}
      onConfirm   = {handleCreateDashboard}
      dialogTitle = "Create New Dashboard"
      tokenUserId = {tokenUserId}
    />

    <WidgetCreator
      isOpen              = {Boolean(isWidgetCreatorOpen)}
      toggleWidgetCreator = {toggleWidgetCreator}
      namespace           = {newWidgetNamespace}
      setFetchingData     = {setFetchingData}
    />

    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
      <DialogTitle>Loading...</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>
  </div>
);

const mapUniqueFiltersWithNamespaces = (wdata) => {
  const calculateHash = (x) => { return createHash('sha256').update(x).digest('hex') ;};
  const getStringify = (x) => JSON.stringify(x);
  const getHashValue = (x) =>  calculateHash( getStringify(x) );

  const appendHashValueProperty = (y) => R.assoc('HashValue', getHashValue(y) , y);
  const removeHashValueProperty = (y) => R.dissoc('HashValue', y);


  const setFiltersHashValue = x => R.map( (eachitem) => R.assoc('filters', appendHashValueProperty(R.prop('filters', eachitem)), eachitem)  , x);
  const removeFiltersHashValue = x => R.map( (eachitem) => R.assoc('filters', removeHashValueProperty(R.prop('filters', eachitem)), eachitem)  , x);


  const groupHashValuesByPath = R.groupBy((eachitem) => {return R.path(['filters', 'HashValue'], eachitem) ; });

  const sameFilterMultipleWidgets = x => R.map( (eachitem) => ( {
                                          namespace: R.compose(R.pluck('namespace'), R.pluck('metaJson'))(eachitem),
                                          filters: R.mergeAll( R.compose(R.pluck('filters'))(eachitem))
                                        }), x);

  const filtersWithWidgetsId = R.compose(removeFiltersHashValue, sameFilterMultipleWidgets ,groupHashValuesByPath, setFiltersHashValue)(wdata);
 
  const returnData = [];
  for (const property in filtersWithWidgetsId){
    returnData.push(R.clone(filtersWithWidgetsId[property]));
  }   

 return R.clone(returnData);

};

const maybeFetchSavedWidgetsByIds = (currentSavedDashboard, fetchSvrResponses, fetchSavedWidgetsByIds) => {
  if (R.compose(R.both(R.is(Array), R.length), R.prop('widgetIds'))(currentSavedDashboard)) {
    return fetchSavedWidgetsByIds(currentSavedDashboard.widgetIds)
      .then(res => {
        const widgets = R.pathOr([], ['payload', 'data', 'data'])(res);
        const widgetsFilters = mapUniqueFiltersWithNamespaces(widgets);
        R.map(({ filters,  namespace }) => { 
          return fetchSvrResponses(namespace, filters);
        }
        )(widgetsFilters);

        // R.map(({ filters, metaJson : { namespace }}) => {
        //   return fetchSvrResponses(namespace, filters);
        // })(widgets);
        
      });
  }
  return Promise.resolve();
};

const mapStateToProps    = (state, props) => ({
  currentSavedDashboard : getCurrentSavedDashboard(state),
  svrResponses          : namespace => getSvrResponses(state, namespace),
  widgets               : getSavedWidgets(state),
  tokenUserId           : getUserId(state),
  dashboardId           : parseInt(props.match.params.id, 10)
});
const mapDispatchToProps = dispatch => ({
  createSavedDashboard     : payload => dispatch(createSavedDashboard(payload)),
  updateSavedDashboard     : (id, payload) => dispatch(updateSavedDashboard(id, payload)),
  setCurrentSavedDashboard : payload => dispatch(setCurrentSavedDashboard(payload)),
  fetchSavedDashboards     : useTenancy => dispatch(fetchSavedDashboards(useTenancy)),
  fetchSvrResponses        : (namespace, filters) => dispatch(fetchSvrResponses(namespace, filters, [] , true)),
  fetchSavedWidgetsByIds   : ids => dispatch(fetchSavedWidgetsByIds(ids)),
  filterByAction           : (namespace, filters) => dispatch(filterByAction(namespace, filters))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    currentSavedDashboard : {},
    widgets               : [],
    newWidgetNamespace    : `widget-${uuidv4()}`
  }),
  withClient,
  withTheme(),
  withStyles(styles),
  setPropTypes({
    classes : PropTypes.object.isRequired,
    forms   : PropTypes.array,
    theme   : PropTypes.object
  }),
  withStateHandlers(
    ({
       isDashboardCreationOpen = false,
       isWidgetCreatorOpen     = false,
       isFetchingData          = true
     }) => ({
      isDashboardCreationOpen,
      isWidgetCreatorOpen,
      isFetchingData
    }),
    {
      handleClickOpenDashboardCreation : () => () => ({
        isDashboardCreationOpen : true
      }),
      handleCloseDashboardCreation     : () => () => ({
        isDashboardCreationOpen : false
      }),

      viewUnderlyingWidgetData : (state, props) => widget => {
        const rollupWidgets = ['questionResponse', 'barChart', 'lineChart', 'pieChart', 'photo', 'video', 'feedback', 'sum'];

        if (R.contains(widget.metaJson.widgetType, rollupWidgets)) {
          try {
            window.localStorage.setItem(widget.metaJson.namespace, widget.id.toString());
            props.history.push('/dashboard/roll-ups/from-widget/' + widget.metaJson.namespace);
          } catch (e) {
            props.filterByAction('reporting', widget.filters);
            props.history.push('/dashboard/svr-responses');
          }
        } else {
          props.filterByAction('reporting', widget.filters);
          props.history.push('/dashboard/svr-responses');
        }
      },
      toggleWidgetCreator      : () => bool => ({
        isWidgetCreatorOpen : bool
      }),
      setFetchingData : () => fetching => ({
        isFetchingData: fetching
      })
    }
  ),
  withHandlers({
    handleUpdateDashboard: props => (dashboardId, payload) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.updateSavedDashboard(dashboardId, payload))
      .then(res => {
          const updatedDashboard = R.path(['payload', 'data', 'data'])(res);
          props.setCurrentSavedDashboard(updatedDashboard);
      })
      .then(() => props.setFetchingData(false));
    },
    handleCreateDashboard    : props => payload => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => props.handleClickOpenDashboardCreation())
      .then(() => props.createSavedDashboard(payload))
      .then(res => {
          const newDashboardId = R.path(['payload', 'data', 'data', 'id'])(res);
          if (newDashboardId) {
            props.history.push(`/dashboard/saved/${newDashboardId}`);
          }
      })
      .then(() => props.setFetchingData(false));
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
              fetchSvrResponses,
              fetchSavedDashboards,
              fetchSavedWidgetsByIds,
              dashboardId,
              setCurrentSavedDashboard,
              currentClient,
              history,
              setFetchingData,
              tokenUserId
            } = this.props;

      fetchSavedDashboards(currentClient && !!currentClient.clientId)
        .then(res => {

          const dashboards     = R.pathOr([], ['payload', 'data', 'data'])(res);
          const firstDashboard = R.head(dashboards) || {};

          if (!dashboardId) {
            if (firstDashboard.id) {
              history.push('/dashboard/saved/' + firstDashboard.id);
            }
            setFetchingData(false);
          } else {
            const currentSavedDashboard = R.find(R.both(R.propEq('id', dashboardId), R.when(R.prop('restrictTokenUserId'), R.propEq('restrictTokenUserId', tokenUserId))), dashboards);
              setCurrentSavedDashboard(currentSavedDashboard);

              maybeFetchSavedWidgetsByIds(currentSavedDashboard, fetchSvrResponses, fetchSavedWidgetsByIds).then(() => {
                setFetchingData(false);
              });
          }
        });
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      const {
              currentSavedDashboard : { widgetIds : nextIds }
            } = nextProps;

      const {
              currentSavedDashboard : { widgetIds : currentIds },
              fetchSavedWidgetsByIds,
              setFetchingData
            } = this.props;

      if (R.difference(R.defaultTo([], nextIds), R.defaultTo([], currentIds)).length > 0) {
        if (R.is(Array, nextIds) && nextIds.length) {
          Promise.resolve(setFetchingData(true))
          .then(() => fetchSavedWidgetsByIds(nextIds))
          .then(() => setFetchingData(false))
          .catch(err => {
            throw new Error(err);
          });
        }
      }
    }
  })
)(DashboardPage);
