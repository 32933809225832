import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import { Paper }  from '@material-ui/core';
import EditIcon   from '@material-ui/icons/ModeEdit';
import CopyIcon   from '@material-ui/icons/ContentCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenWith   from '@material-ui/icons/OpenWith';

import AddSectionItem from './AddSectionItem';

const SectionOuterWrapper = styled.div`
  &:first-of-type {
    margin-top : 2rem;
  }
`;

const Wrapper = styled(Paper)`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  font-size       : .875rem;
  margin          : .5rem 0;
  margin-left     : .5rem;
  padding         : 0 .5rem;
  border-left     : .625rem solid #398559;
  box-sizing      : border-box;
  background-color: ${props => props.selected ? `rgba(243, 33, 46, .25) !important` : `inherit`};
`;

const Label = styled.p`
  margin      : 0;
  font-size   : .875rem;
  font-weight : 500;
  cursor      : default;
`;

const Title = styled.p`
  margin       : .25rem .5rem;
  align-self   : center;
  margin-right : auto;
  cursor       : default;
`;

const SectionActions = styled.div`
  display      : flex;
  align-items  : center;
  margin-right : .5rem;
  svg {
    margin-left : .25em;
    font-size   : 1.25rem;
    cursor      : pointer;
  }
`;

const SectionItem = ({ title, children, onCopy, onAdd, onEdit, onDelete, onSelect, isSelected }) => (
  <SectionOuterWrapper>
    <Wrapper selected={isSelected}>
      <Label>SECTION</Label>
      <Title>{title}</Title>
      <SectionActions>
        <CopyIcon
          onClick={onCopy}
        />
        <OpenWith
          onClick={onSelect}
        />
        <AddSectionItem
          onAdd={onAdd}
        />
        <EditIcon
          onClick={onEdit}
        />
        <DeleteIcon
          onClick={onDelete}
        />
      </SectionActions>
    </Wrapper>
    {children}
  </SectionOuterWrapper>
);

SectionItem.propTypes = {
  title    : PropTypes.string.isRequired,
  children : PropTypes.node,
  onCopy   : PropTypes.func.isRequired,
  onAdd    : PropTypes.func.isRequired,
  onEdit   : PropTypes.func.isRequired,
  onDelete : PropTypes.func.isRequired,
};

SectionItem.defaultProps = {
  children: null,
};

export default SectionItem;
