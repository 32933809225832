import * as R from 'ramda';

import recursivelyPluck from '../../../svr/utils/recursivelyPluck';

const maybeHideForClient = isClientUser => question => {
  return R.path(['meta', 'isHidden'])(question) && isClientUser;
};

const getFlattenedQuestions = (questions, forms, isClientUser, questionIdentifier='canonicalKey') => {
  const getQuestions = R.compose(
    R.reject(maybeHideForClient(isClientUser)),
    R.filter(q => R.contains(q[questionIdentifier], questions)),
    R.flatten,
    R.map(recursivelyPluck('content')),
    R.flatten,
    R.path(['content', 'structure'])
  );

  return (forms.length && questions.length) ? R.compose(
    R.uniqBy(R.prop('uuid')),
    R.chain(getQuestions)
  )(forms) : [];
};

export default getFlattenedQuestions;
