import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import * as R               from 'ramda';

import { history } from '../../store';
import { Wrapper } from './styledComponents';

import SaveAsModal      from './modals/SaveAs__Modal';
import SavedRollupPage  from '../../modules/saved-rollups/pages/SavedRollupPage';
import SavedWidgetPage  from '../../modules/saved-widgets/pages/SavedWidgetPage';
import SearchParamsList from './SearchParamsList';

class SearchTop extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      savedViewFormData     : { restrictTokenUserId : `${props.userId}` },
      savedRollupFormData   : { restrictTokenUserId : `${props.userId}` },
      savedWidgetFormData   : { restrictTokenUserId : `${props.userId}` },
      showSaveAsViewModal   : false,
      showSaveAsRollupModal : false,
      showSaveAsWidgetModal : false,
      showRollupDetailModal : false,
      showWidgetDetailModal : false,      
    };
  }

  handleSaveAsRollup = () => {
    this.toggleSaveAsRollupModal(true);
  };

  handleSaveAsView = () => {
    this.toggleSaveAsViewModal(true);
  };

  handleSaveAsWidget = () => {
    this.toggleSaveAsWidgetModal(true);
  };

  handleCloseSavedRollupPage = () => {
    this.setState({ showRollupDetailModal : false });
  };

  toggleSaveAsRollupModal = bool => {
    this.setState({
      showSaveAsRollupModal : bool
    });
  };

  toggleRollupDetailModal = bool => {
    this.setState({
      showRollupDetailModal : bool
    });
  };

  toggleSaveAsViewModal = bool => {
    this.setState({ showSaveAsViewModal : bool });
  };

  handleCloseSavedWidgetPage = () => {
    this.setState({ showWidgetDetailModal : false });
  };

  toggleSaveAsWidgetModal = bool => {
    this.setState({ showSaveAsWidgetModal : bool });
  };

  toggleWidgetDetailModal = bool => {
    this.setState({
      showWidgetDetailModal : bool
    });
  };

  onSaveAsView = formData => {
    this.toggleSaveAsViewModal(false);
    this.setState({ savedViewFormData : {} });
    this.props.onSaveAsView(
      R.assoc('filters', JSON.stringify(this.props.filterList), formData)
    ).then(res => {
      const id = R.path(['payload', 'data', 'data', 'id'])(res);
      if (id) {
        history.push('/manage/saved-views/' + id);
      }
    });
  };

  onSaveAsWidget = formData => {
    this.toggleSaveAsWidgetModal(false);
    this.setState({ savedWidgetFormData : {} });
    this.props.onSaveAsWidget(
      R.assoc('filters', JSON.stringify(this.props.filterList), formData)
    );
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showSaveAsRollupModal !== this.props.showSaveAsRollupModal) {
      this.setState({ showSaveAsRollupModal : nextProps.showSaveAsRollupModal });
    }
    if (nextProps.showSaveAsViewModal !== this.props.showSaveAsViewModal) {
      this.setState({ showSaveAsViewModal : nextProps.showSaveAsViewModal });
    }
    if (nextProps.showSaveAsWidgetModal !== this.props.showSaveAsWidgetModal) {
      this.setState({ showSaveAsWidgetModal : nextProps.showSaveAsWidgetModal });
    }
  }

  render() {
    const {
            props : {
              currentClient,
              currentProject,
              data,
              fetchAutocomplete,
              clearAutocomplete,
              autoComplete,
              prefilledFilters,
              filterByAction,
              filterList,
              syncFilters,
              namespace,
              searchFilterStyle,
              showWhen,
              style,
              tableColumns,
              transformedData,
              userId,
              userRole,
              allowSave,
              isClientUser,
              isRollup
            },
            state : {
              savedRollupFormData,
              savedViewFormData,
              savedWidgetFormData,
              showRollupDetailModal,
              showSaveAsRollupModal,
              showSaveAsViewModal,
              showSaveAsWidgetModal,
              showWidgetDetailModal
            }
          } = this;

    return (
      <Wrapper style={style} showWhen={showWhen}>
        <SearchParamsList
          data               = {data}
          fetchAutocomplete  = {fetchAutocomplete}
          clearAutocomplete  = {clearAutocomplete}
          autoComplete       = {autoComplete}
          prefilledFilters   = {prefilledFilters}
          tableColumns       = {tableColumns}
          filterByAction     = {filterByAction}
          filterList         = {filterList}
          syncFilters        = {syncFilters}
          namespace          = {namespace}
          style              = {searchFilterStyle}
          allowSave          = {allowSave}
          handleSaveAsView   = {this.handleSaveAsView}
          handleSaveAsWidget = {this.handleSaveAsWidget}
          handleSaveAsRollup = {this.handleSaveAsRollup}
          flexStart          = {namespace === 'attendance'}
          displayAsColumn    = {false}
          isAddorEditWidget  = {false}  
          isRollup           = {false}    
        />
        <SaveAsModal
          namespace           = "saved-view"
          onSave              = {() => this.onSaveAsView(savedViewFormData)}
          canSave             = {() => Boolean(savedViewFormData.title)}
          onCancel            = {() => this.toggleSaveAsViewModal(false)}
          restrictTokenUserId = {savedViewFormData.restrictTokenUserId}
          tokenUserId         = {userId}
          showWhen            = {showSaveAsViewModal}
          metaJson            = { savedViewFormData }
          isClientUser        = { isClientUser }
          onChange            =  {e =>
            this.setState(
              R.assocPath(
                ['savedViewFormData', e.target.name],
                e.target.value,
                this.state
              )
            )
          }
        />
        <SaveAsModal
          namespace = "saved-rollup"
          onSave    = {() => {
            this.toggleSaveAsRollupModal(false);
            this.toggleRollupDetailModal(true);
          }}
          canSave   = {() => Boolean(savedRollupFormData.title)}
          onCancel  = {() => this.toggleSaveAsRollupModal(false)}
          onChange  = {e =>
            this.setState(
              R.assocPath(
                ['savedRollupFormData', e.target.name],
                e.target.value,
                this.state
              )
            )
          }
          restrictTokenUserId = {savedRollupFormData.restrictTokenUserId}
          tokenUserId         = {userId}
          showWhen            = {showSaveAsRollupModal}
          metaJson            = { savedRollupFormData }
          isClientUser        = { isClientUser }
        />
        <SaveAsModal
          namespace="saved-widget"
          onSave={() => {
            this.toggleSaveAsWidgetModal(false);
            this.toggleWidgetDetailModal(true);
          }}
          canSave              = {() => Boolean(savedWidgetFormData.title)}
          onCancel             = {() => this.toggleSaveAsWidgetModal(false)}
          restrictTokenUserId  = {savedWidgetFormData.restrictTokenUserId}
          tokenUserId          = {userId}
          showWhen             = {showSaveAsWidgetModal}
          showWidgetTypeSelect = {true}
          metaJson             = {savedWidgetFormData.metaJson}
          isClientUser        = { isClientUser }
          onChange             = {e => {
            if (e.target.name === 'widgetType' || e.target.name === 'embedPhotosInExcelReport') {
              const metaJson = R.objOf(e.target.name, e.target.value);
              this.setState(
                R.assocPath(
                  ['savedWidgetFormData', 'metaJson'],
                  R.mergeDeepRight(
                    R.path(['savedWidgetFormData', 'metaJson'], this.state),
                    metaJson
                  ),
                  this.state
                )
              );
            } else {
              this.setState(
                R.assocPath(
                  ['savedWidgetFormData', e.target.name],
                  e.target.value,
                  this.state
                )
              );
            }
          }}
        />
        {showRollupDetailModal && (
          <SavedRollupPage
            filterByAction              = {filterByAction}
            prefilledFilters            = {prefilledFilters}
            filterList                  = {filterList}
            rollUpTitle                 = {savedRollupFormData.title}
            rollUpDescription           = {savedRollupFormData.description}
            isOpen                      = {showRollupDetailModal}
            onClose                     = {this.handleCloseSavedRollupPage}
            data                        = {transformedData}
            namespace                   = {namespace}
            restrictTokenUserId         = {savedRollupFormData.restrictTokenUserId}
            embedPhotos                 = {savedRollupFormData.embedPhotosInExcelReport}
            includeMotivesInExcelReport = {savedRollupFormData.includeMotivesInExcelReport}
            SearchParamsList            = {
              <SearchParamsList
                data               = {data}
                fetchAutocomplete  = {fetchAutocomplete}
                clearAutocomplete  = {clearAutocomplete}
                autoComplete       = {autoComplete}
                prefilledFilters   = {prefilledFilters}
                tableColumns       = {tableColumns}
                filterByAction     = {filterByAction}
                filterList         = {filterList}
                syncFilters        = {syncFilters}
                namespace          = {namespace}
                style              = {{ ...searchFilterStyle, display : 'block' }}
                userRole           = {userRole}
                userId             = {userId}
                currentClient      = {currentClient}
                currentProject     = {currentProject}
                allowSave          = {false}
                showWhen           = {true}
                displayAsColumn    = {true}
                flexStart          = {namespace === 'attendance'}
                handleSaveAsView   = {this.handleSaveAsView}
                handleSaveAsWidget = {this.handleSaveAsWidget}
                handleSaveAsRollup = {this.handleSaveAsRollup}
                isRollup           = {isRollup} 
              />
            }
            isClientUser        = {isClientUser}
          />
        )}
        {showWidgetDetailModal && (
          <SavedWidgetPage
            namespace           = {namespace}
            filterByAction      = {filterByAction}
            prefilledFilters    = {prefilledFilters}
            filterList          = {filterList}
            widgetTitle         = {savedWidgetFormData.title}
            widgetDescription   = {savedWidgetFormData.description}
            isOpen              = {showWidgetDetailModal}
            metaJson            = {savedWidgetFormData.metaJson}
            onClose             = {this.handleCloseSavedWidgetPage}
            data                = {transformedData}
            restrictTokenUserId = {savedWidgetFormData.restrictTokenUserId}
            SearchParamsList    = {
              <SearchParamsList
                data               = {data}
                fetchAutocomplete  = {fetchAutocomplete}
                clearAutocomplete  = {clearAutocomplete}
                autoComplete       = {autoComplete}
                prefilledFilters   = {prefilledFilters}
                tableColumns       = {tableColumns}
                filterByAction     = {filterByAction}
                filterList         = {filterList}
                syncFilters        = {syncFilters}
                namespace          = {namespace}
                style              = {{ ...searchFilterStyle, display : 'block' }}
                userRole           = {userRole}
                userId             = {userId}
                currentClient      = {currentClient}
                currentProject     = {currentProject}
                allowSave          = {false}
                showWhen           = {true}
                displayAsColumn    = {true}
                flexStart          = {namespace === 'attendance'}
                handleSaveAsView   = {this.handleSaveAsView}
                handleSaveAsWidget = {this.handleSaveAsWidget}
                isRollup           = {false}  
              />
            }
          />
        )}
      </Wrapper>
    );
  }
}

SearchTop.propTypes = {
  filterList           : PropTypes.object,
  flattenedStoreVisits : PropTypes.array,
  filterByAction       : PropTypes.func,
  isClientUser         : PropTypes.bool
};

SearchTop.defaultProps = {
  filterList           : {},
  flattenedStoreVisits : [],
  filterByAction       : () => {},
  isClientUser         : false
};

export default SearchTop;
