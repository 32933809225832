export const FETCH_ALL  = `ADMINISTRATION/FETCH_ALL`;
export const CREATE     = `ADMINISTRATION/CREATE`;
export const UPDATE     = `ADMINISTRATION/UPDATE`;
export const EDIT       = `ADMINISTRATION/EDIT`;
export const IS_EDITING = `ADMINISTRATION/IS_EDITING`;
export const DELETE     = `ADMINISTRATION/DELETE`;

export const fetchAdministrationSettings = () => ({
  type    : FETCH_ALL,
  payload : {
    request : {
      method : 'GET',
      url    : '/api/v2/administration/settings',
    }
  }
});

export const saveAdministrationSetting = setting => {
  return createAdministrationSetting({ ...setting });
};

export const createAdministrationSetting = data => ({
  type    : CREATE,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/administration/settings',
      data
    }
  }
});

export const saveEditedAdministrationSetting = (namespace, setting) => {
  return updateAdministrationSetting(namespace, setting);
};

export const updateAdministrationSetting = (namespace, setting) => dispatch => {
  dispatch({
    type    : UPDATE,
    payload : {
      namespace,
      request : {
        method : 'PUT',
        url    : `/api/v2/administration/settings/namespace/${namespace}/id/${setting.name}`,
        data   :  setting
      }
    }
  });
};

export const isEditingAction = ( bool, namespace, settingObject ) => {
  return {
    type : IS_EDITING,
    payload: {
      status              : bool,
      settingNamespace    : namespace,
      settingBeingEdited  : settingObject
    }
  };
};
