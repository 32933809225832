import * as R         from 'ramda';
import React          from 'react';
import { connect }    from 'react-redux';
import PropTypes      from 'prop-types';
import { Helmet }     from 'react-helmet';
import { withRouter } from 'react-router';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
  withStateHandlers,
  withHandlers,
  withProps
} from 'recompose';

import SavedRollupsTable       from '../components/SavedRollupsTable';
import SavedRollupsListToolbar from '../components/SavedRollupsListToolbar';
import SearchTop               from '../../../components/SearchFilter/SearchTop';

import { getCurrentClient, getCurrentProject, isClientUser, getPrefilledFilters, getAutoComplete } from '../../client/ClientReducer';
import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../client/ClientActions';

import {
  createSavedRollup as _createSavedRollup,
  fetchSvrResponses as _fetchSvrResponses,
  filterByAction    as _filterByAction,
  transformData     as _transformData,
  clearSvrResponses as _clearSvrResponses
} from '../../svr-responses/SvrResponsesActions';

import {
  getFilterList,
  getSvrResponses, getTableColumns, getTransformedData
} from '../../svr-responses/SvrResponsesReducer';

import {
  fetchSavedRollups  as _fetchSavedRollups,
  updateSavedRollUp  as _updateSavedRollUp
} from '../SavedRollupsActions';

import {
  getSavedRollups
} from '../SavedRollupsReducer';

import {
  getUserId,
  getUserRole
} from '../../auth/AuthReducer';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';

import { deriveTenancyFilters } from '../../../utils/deriveTenancyFilters';

import {
  maybeAppendDecorators
} from '../../../utils/widgetHelpers';

import moment    from 'moment';

const MODULE_NAMESPACE = 'savedRollup';

const DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER = 7;

const DEFAULT_FIELDS = ['id', 'form_id', 'timestamp', 'event_id', 'status', 'privacy'];
const mergeFieldsWithFilters = (columns, filters) => {
  const mergedFields = R.compose(
    R.uniq, 
    R.concat(DEFAULT_FIELDS), 
    R.values,
    R.map(R.compose(R.prop('column'), patStr => R.find(R.propEq('pathStr', patStr), columns), R.prop('pathStr')))
  )(filters);

  return mergedFields;
};

const SavedRollupsListPage = ({
  savedRollups,
  goToEditPage,
  isSaveAsSavedRollupModalOpen,
  toggleOpenSaveAsSavedRollupModal,
  svrResponses,
  transformedData,
  filterList,
  currentClient,
  currentProject,
  tableColumns,
  userRole,
  userId,
  createSavedRollup,
  namespace,
  maybeFilterByAction,
  updateSavedRollUp,
  isClientUser,
  isFetchingData,
  prefilledFilters,
  fetchAutocomplete,
  clearAutocomplete,
  autoComplete,
  syncFilters
}) => (
  <div>
    <Helmet>
      <title>Roll-up Reports</title>
    </Helmet>
    <SavedRollupsListToolbar onClick={toggleOpenSaveAsSavedRollupModal}/>
    <SavedRollupsTable
      data         = {savedRollups}
      goToEditPage = {goToEditPage}
      onUpdate     = {updateSavedRollUp}
      isClientUser = {isClientUser}
    />

    <SearchTop
      fetchAutocomplete     = { fetchAutocomplete }
      clearAutocomplete     = { clearAutocomplete }
      autoComplete          = { autoComplete }
      prefilledFilters      = { prefilledFilters }
      data                  = { svrResponses }
      transformedData       = { transformedData }
      tableColumns          = { !isClientUser ? tableColumns : R.reject(tc => tc.label === "Review State", tableColumns) }
      filterByAction        = { maybeFilterByAction }
      syncFilters           = { syncFilters }
      filterList            = { filterList }
      namespace             = { namespace }
      searchFilterStyle     = {{ display : 'none' }}
      style                 = {{}}
      userRole              = { userRole }
      userId                = { userId }
      currentClient         = { currentClient }
      currentProject        = { currentProject }
      allowSave             = { true }
      onSaveAsRollup        = { createSavedRollup }
      showWhen              = { true }
      showParamsWhen        = { true }
      showSaveAsRollupModal = { isSaveAsSavedRollupModalOpen }
      isClientUser          = {isClientUser}
      isRollup              = {true}   
    />

    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
      <DialogTitle>Loading...</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>
  </div>
);

const maybeDispatchFetchSvrResponses = (dispatch, namespace, filters, columns) => {
  const activeFilters = R.filter(R.compose(R.not, R.isEmpty, R.prop('data')))(filters);
  if(Object.keys(activeFilters).length) {
    return dispatch(_fetchSvrResponses(namespace, filters, mergeFieldsWithFilters(columns, filters), true));
  }
  dispatch(_clearSvrResponses(namespace));
  return Promise.resolve();
}; 

const appendFilterType = R.map((filter = {}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const manageSvrResponsesFilterList = () => {
 
  var todayDate = new Date().toISOString().slice(0, 10);
  todayDate = todayDate.concat(" 23:59:59");  

  const now = moment();
  const pastdate = moment(now).subtract(DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER, 'days').format("YYYY-MM-DD");
  
  var visitArray = [];
  var visitArrayClone = [];
  visitArray.push([pastdate, todayDate]);
  visitArrayClone.push(visitArray);

  return {
    'Visit Date': {
      pathStr: 'expectedCheckinTime',
      path: ['expectedCheckinTime'],
      label: 'Visit Date',
      //decorator: v => window.WINSTON.statuses.reviewState[v || 0],
      decorator: R.path(['WINSTON', 'tableColumns', 'storeVisit', "expectedCheckinTime", 'decorator'])(window),
      data: visitArrayClone
    },
    'Review State': {
      pathStr: 'metaJson.reviewState',
      path: ['metaJson', 'reviewState'],
      label: 'Review State',
      decorator: v => window.WINSTON.statuses.reviewState[v || 0],
      data: R.compose(
        values => R.filter(v => R.equals('Approved', v), values),
        R.values
      )(window.WINSTON.statuses.reviewState)
    }
  };
};


const maybeLoadInitialFilterListFromRouterProps = (state, props) => {
  //debugger;
  let storedFilters = {};
  try {
    storedFilters = R.compose(
      maybeAppendDecorators,
      appendFilterType,
      JSON.parse
    )(window.localStorage.getItem(`persistedFilters:${MODULE_NAMESPACE}`));
  } catch (e) {
    storedFilters = {};
  }

  //debugger;
  var objFilterSize = Object.keys(storedFilters).length;
  //console.log("storedFilters-Client Name", storedFilters.hasOwnProperty('Client Name'));

  if (objFilterSize === 0 && !isClientUser()) {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      manageSvrResponsesFilterList,
      R.mergeDeepLeft(storedFilters),
      getFilterList(MODULE_NAMESPACE)
    )(state);
  }
  else if (objFilterSize === 1 && storedFilters.hasOwnProperty('Client Name') && !isClientUser()) {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      manageSvrResponsesFilterList,
      R.mergeDeepLeft(storedFilters),
      getFilterList(MODULE_NAMESPACE)
    )(state);
  }
  else {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      R.mergeDeepLeft(storedFilters),
      getFilterList(MODULE_NAMESPACE)
    )(state);
  }
};


const mapStateToProps = (state, props) => ({
  svrResponses      : getSvrResponses(state, MODULE_NAMESPACE),
  transformedData   : getTransformedData(state, MODULE_NAMESPACE),
  tableColumns      : getTableColumns(MODULE_NAMESPACE)(state),
  userRole          : getUserRole(state),
  userId            : getUserId(state),
  currentClient     : getCurrentClient(state),
  currentProject    : getCurrentProject(state),
  savedRollups      : getSavedRollups(state),
  filterList        : maybeLoadInitialFilterListFromRouterProps(state, props),
  isClientUser      : isClientUser(),
  prefilledFilters  : getPrefilledFilters(state),
  autoComplete      : getAutoComplete(state)
});

// const mapStateToProps = (state, props) => ({
//   svrResponses      : getSvrResponses(state, MODULE_NAMESPACE),
//   transformedData   : getTransformedData(state, MODULE_NAMESPACE),
//   tableColumns      : getTableColumns(MODULE_NAMESPACE)(state),
//   userRole          : getUserRole(state),
//   userId            : getUserId(state),
//   currentClient     : getCurrentClient(state),
//   currentProject    : getCurrentProject(state),
//   savedRollups      : getSavedRollups(state),
//   filterList        : getFilterList(MODULE_NAMESPACE)(state),
//   isClientUser      : isClientUser(),
//   prefilledFilters  : getPrefilledFilters(state),
//   autoComplete      : getAutoComplete(state)
// });

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  fetchSavedRollups  : useTenancy => dispatch(_fetchSavedRollups(useTenancy)),
  goToEditPage       : id => props.history.push(`/dashboard/roll-ups/${id}/edit`),
  fetchSvrResponses  : (namespace, filters) => dispatch(_fetchSvrResponses(namespace, filters, mergeFieldsWithFilters(props.tableColumns, filters))),
  createSavedRollup  : data => dispatch(_createSavedRollup(data)),
  transformData      : (orderBy, order, sortType, filterList, data, namespace, tableColumns) => dispatch(_transformData(orderBy, order, sortType, filterList, data, namespace, tableColumns)),
  updateSavedRollUp  : (savedRollUpId, payload) => dispatch(_updateSavedRollUp(savedRollUpId, payload)),
  fetchAutocomplete  : (payload) => dispatch(_fetchAutocomplete(payload)),
  clearAutocomplete  : () => dispatch(_clearAutocomplete()),
});

const withSavedRollups = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
       savedRollup                  = {},
       savedRollupTitle             = '',
       savedRollupDescription       = '',
       isSavedRollupPage            = false,
       isSaveAsSavedRollupModalOpen = false,
       isFetchingData               = true
     }) => ({
      savedRollup,
      savedRollupTitle,
      savedRollupDescription,
      isSavedRollupPage,
      isSaveAsSavedRollupModalOpen,
      isFetchingData
    }),
    ({
      setSavedRollup                   : () => savedRollup => ({ savedRollup }),
      handleNextSavedRollupModal       : () => (savedRollupTitle, savedRollupDescription) => ({
        isSavedRollupPage            : true,
        isSaveAsSavedRollupModalOpen : false,
        savedRollupTitle,
        savedRollupDescription
      }),
      toggleOpenSaveAsSavedRollupModal : ({ isSaveAsSavedRollupModalOpen }) => () => ({
        isSaveAsSavedRollupModalOpen : !isSaveAsSavedRollupModalOpen,
        savedRollupTitle             : '',
        savedRollupDescription       : ''
      }),
      handleCloseSavedRollupPage       : () => () => ({
        isSavedRollupPage      : false,
        savedRollupTitle       : '',
        savedRollupDescription : ''
      }),
      setFetchingData : () => fetching => ({
        isFetchingData: fetching
      })
    })
  ),
  withHandlers({
    maybeFilterByAction: props => (namespace, filters) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => maybeDispatchFetchSvrResponses(props.dispatch, namespace, filters, props.tableColumns))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    filterByAction     : props => (namespace, filters) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => mergeFieldsWithFilters(props.tableColumns, filters))
      .then(mergedFields => props.dispatch(_fetchSvrResponses(namespace, filters, mergedFields)))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    syncFilters : props => (namespace, filters) => props.dispatch(_filterByAction(namespace, filters))
  }),
  withProps(({ currentClient, currentProject, filterList }) => ({
    filterList : R.mergeDeepRight(filterList, deriveTenancyFilters(currentClient, currentProject))
  })),
  defaultProps({
    savedRollups    : [],
    currentClient   : {},
    currentProject  : {},
    filterList      : {},
    tableColumns    : [],
    transformedData : [],
    namespace       : MODULE_NAMESPACE
  }),
  setPropTypes({
    savedRollups       : PropTypes.array,
    currentClient      : PropTypes.object,
    tableColumns       : PropTypes.array,
    transformedData    : PropTypes.array,
    createSavedRollup  : PropTypes.func.isRequired
  }),
  lifecycle({
    componentDidMount() {
      const useTenancy = Boolean(this.props.currentClient.clientId);
      this.props.fetchSavedRollups(useTenancy).then(() => this.props.setFetchingData(false));
    },
    componentDidUpdate(prevProps) {
      const dataIsDifferent     = !R.equals(R.pluck('timestamp', prevProps.transformedData), R.pluck('timestamp', this.props.transformedData));
      const filtersAreDifferent = !R.equals(prevProps.filterList, this.props.filterList);

      if (dataIsDifferent || filtersAreDifferent) {

        const order      = 'asc',
              orderBy    = 'id',
              sortType   = 'string',
              filterList = this.props.filterList || {};

        this.props.transformData(orderBy, order, sortType, filterList, this.props.svrResponses, this.props.namespace, this.props.tableColumns);
      }

    },
    componentWillReceiveProps(nextProps) {
      if (!R.equals(nextProps.currentClient, this.props.currentClient)) {
        const useTenancy = Boolean(nextProps.currentClient.clientId);
        this.props.setFetchingData(true);
        this.props.fetchSavedRollups(useTenancy).then(() => {
          this.props.setFetchingData(false);
        });
      }
    }
  }),
  withRouter
);

export default withSavedRollups(SavedRollupsListPage);
