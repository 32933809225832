import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as R from 'ramda';

const Wrapper = styled.div`
  padding-left  : ${props => props.level * 20}px;
  height        : 35px;
  border-bottom : 2px solid #EEEEEE;
  display       : flex;
  align-items   : center;
  color         : #909091;
  font-size     : 13px;
  font-weight   : bold;
`;

const AnswerTitle = styled.div`
  height        : 30px;
  padding-left  : ${props => props.level * 20}px;
  border-bottom : 2px solid #EEEEEE;
  font-style    : oblique;
  color         : #767676;
  font-weight   : 100;
  display       : flex;
  align-items   : center;
`;

const QuestionItem = ({
  answerTitle,
  level,
  options,
  title
}) => {
  const whenOptions = options.length > 0 ? `${title}   ( Options: ${options.map(item => ' ' + item.value)} )` : title;
  
  return (
    <div>
      {answerTitle && <AnswerTitle level={level}><span>{`If "${answerTitle}"`}</span></AnswerTitle>}
      <Wrapper level={level}>{whenOptions}</Wrapper>
      {R.flatten(options.map(option => option.conditionalFork.map((forkedQuestion, i) => (
        <QuestionItem
          level={R.inc(level)}
          key={forkedQuestion.uuid}         
          title={`${forkedQuestion.title}`}          
          conditionalItems={forkedQuestion.options}
          options={forkedQuestion.options}
          answerTitle={i === 0 && option.label}
        />

      ))))}
    </div>
  );
};

QuestionItem.propTypes = {
  answerTitle: PropTypes.string,
  level: PropTypes.number,
  options: PropTypes.array,
  title: PropTypes.string
};

QuestionItem.defaultProps = {
  answerTitle: '',
  level: 1,
  options: [],
  title: ''
};

export default QuestionItem;