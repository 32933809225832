import * as R from 'ramda';

import {
FETCH_FORMS,
FETCH_ALL_SUCCESS,
FETCH,
SET_QUESTIONS,
FETCH_QUESTION_ANSWERS,
RESET_FORMS,
UPDATE
} from './SavedRollupsActions';

import getFlattenedQuestions from './components/utils/getFlattenedQuestions';

import { isClientUser } from '../client/ClientReducer';

export const STATE_KEY = 'rollUps';

const initialState = {
  data                : {},
  forms               : {},
  questions           : [],
  questionAnswers     : [],
  questionAnswersDict : {},
  transformedDataDict : {},
  photosDict          : {}
};

const idKeyAsProp = (a, b) => R.assoc(b.id, b, a);

const SavedRollupsReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_ALL_SUCCESS       : {
      return R.compose(
        R.assoc('data', R.__, state),
        R.reduce(idKeyAsProp, {}),
        R.when(R.has('err'), R.always([])),
        R.pathOr([], ['payload', 'data', 'data'])
      )(action);
    }
    case `${FETCH}_SUCCESS`       : {
      const rollUp = R.pathOr({}, ['payload', 'data', 'data'], action);

      return R.assocPath(['data', rollUp.id], rollUp, state);
    }
    case `${SET_QUESTIONS}`       : {
      return R.assocPath([action.payload.namespace, 'questions'], action.payload.data, state);
    }
    case `${FETCH_FORMS}_SUCCESS` : {
      const forms     = R.pathOr([], ['payload', 'data', 'data'], action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);
      return R.assocPath([namespace, 'forms'], forms, state);
    }
    case `${FETCH_QUESTION_ANSWERS}_SUCCESS` : {
      const answers   = R.pathOr([], ['payload', 'data', 'data'], action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.compose(
        R.assocPath([namespace, 'questionAnswers'], R.__, state),
        R.groupBy(R.prop('storeVisitId')),
      )(answers);
    }
    case RESET_FORMS              : {
      const namespace = R.path(['payload', 'namespace'])(action);
      return R.assocPath([namespace, 'forms'], [], state);
    }
    case `${UPDATE}_SUCCESS`: {
      const modifyRollUp = R.pathOr({}, ['payload', 'data', 'data'], action);
      const contentLens  = R.lensProp('data');

      return R.set(
        contentLens,
        R.assoc(modifyRollUp.id, modifyRollUp, R.view(contentLens, state)),
        state
      );
    }
    default : {
      return state;
    }
  }
};

export const getSavedRollups = R.compose(R.values, R.path([STATE_KEY, 'data']));

export const getForms = (state, namespace='savedRollup') => R.pathOr([], [STATE_KEY, namespace, 'forms'], state);

export const getQuestions = (state, namespace='savedRollup') => {
  const questions = R.pathOr([], [STATE_KEY, namespace, 'questions'], state);
  const forms     = R.values(R.path([STATE_KEY, namespace, 'forms'], state));

  return getFlattenedQuestions(questions, forms, isClientUser());
};

export const getQuestionAnswers = (state, namespace='savedRollup') => R.pathOr({}, [STATE_KEY, namespace, 'questionAnswers'], state);

export default SavedRollupsReducer;
