import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = theme => ({
  styledFooter : {
    position     : 'absolute',
    width        : '100%',
    height       : '40px',
    background   : theme.custom.colors.grayLight,
    bottom       : '0px',
    borderRadius : '0 0 3px 3px'
  }
});

const StyledFooter = ({ className, children, classes }) => (
  <div className={`${className} ${classes.styledFooter}`}>{children}</div>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    className : PropTypes.string,
    children  : PropTypes.node,
    classes   : PropTypes.object.isRequired,
    theme     : PropTypes.object.isRequired
  })
)(StyledFooter);
