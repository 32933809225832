import styled   from 'styled-components';
import { Chip } from '@material-ui/core';

export const SearchContainer = styled.div`
  overflow : auto;
  height   : auto;
`;

export const StyledChip = styled(Chip)`
  &>span {
    max-width     : 100px;
    overflow      : hidden;
    text-overflow : ellipsis;
    display       : inline-block;
    color         : #777;
  }
`;

export const Top = styled.div`
  padding   : 10px 40px 10px 40px;
  display   : flex;
  font-size : 10px;
`;

export const Wrapper = styled.div`
  display : ${({ showWhen }) => showWhen ? 'block'  : 'none'};
`;

export const ChipContainer = styled.div`
  display   : flex;
  max-width : auto;
  padding   : 4px;
`;

export const Bottom = styled.div`
  display         : flex;
  justify-content : ${props => props.flexStart ? 'flex-start' : 'space-between'};
  color           : #c70000;
  font-size       : 12px;
  border-top      : ${props => props.displayAsColumn ? 'none' : '#ebebeb solid 1px'};
  position        : relative;
  bottom          : ${props => props.displayAsColumn ? '12px' : '5px'};
  width           : ${props => props.displayAsColumn ? '250px' : '100%'};
  margin-top      : 20px;

  .parameter-container {
    display         : flex;
    justify-content : 'flex-start';
  }
`;

export const Button = styled.div`
  min-height : 100%;
  padding    : 10px 20px 10px 20px;
  cursor     : pointer;
  &:hover {
    background : #f0f0f0;
    border     : #dbdbdb solid 1px;
  }
`;

export const AddParameterMenu = styled.div`
  visibility      : ${({ showWhen }) => showWhen ? 'visible' : 'hidden'};
  display         : flex;
  flex-direction  : column;
  justify-content : space-around;
  width           : auto;
  margin          : 5px;
  background      : white;
  box-shadow      : 2px 1px 7px 1px rgba(0,0,0,0.2);
  font-size       : 14px;
  font-weight     : 300;
  position        : absolute;
  left            : 0;
  z-index         : 1;
  overflow        : auto;
`;

export const AddParameterMenuItem = styled.div`
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-content   : center;
  height          : 100%;
  padding         : 12px;
  cursor          : pointer;
  &:hover {
    background : rgba(0,0,0,0.2);
  }
`;

export const TableFooterControls = styled.div`
  display         : flex;
  width           : 90vw;
  height          : 50px;
  color           :  ${({ svrIsSelected }) => svrIsSelected ? 'red' : 'grey'};
  background      : #ebebeb;
  justify-content : space-between;
  align-items     : center;
  box-sizing      : border-box;
  padding         : 20px;
  font-size       : 14px;
  cursor          : pointer;
`;

export const LeftControls = styled.div`
  display         : flex;
  width           : 200px;
  justify-content : space-between;
  align-items     : space-around;
`;

