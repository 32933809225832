import * as R from 'ramda';

import {
  DOWNLOAD_ZIP,
  DOWNLOAD_ZIP_START,
  HIDE_DOWNLOAD_ZIP_MODAL
} from './DocumentActions';

export const STATE_KEY = 'document';

const initialState = {};

const DocumentReducer = (state = initialState, action) => {
  switch (action.type) {

    case `${DOWNLOAD_ZIP}_SUCCESS`: {
      const zipLink  = R.path(['payload', 'data', 'data'])(action),
            zipError = R.path(['payload', 'response', 'data', 'data', 'err', 'message'])(action)
                       || R.path(['payload', 'message'])(action);

      if (zipError) {
        return R.compose(
          R.assoc('zipLink',  null),
          R.assoc('zipError', zipError),
        )(state);
      }

      if (zipLink) {
        return R.compose(
          R.assoc('zipLink',  zipLink),
          R.assoc('zipError', null),
        )(state);
      }

      return state;

    }

    case `${DOWNLOAD_ZIP}_ERROR` : {
      const zipError = R.path(['payload', 'data', 'err'])(action);

      if (zipError) {
        return R.compose(
          R.assoc('zipLink',  null),
          R.assoc('zipError', zipError),
        )(state);
      }

      return state;
    }

    case DOWNLOAD_ZIP_START : {
      return R.compose(
        R.assoc('showZipDownloadModal', true),
        R.assoc('zipLink',              null),
        R.assoc('zipError',             null)
      )(state);
    }

    case HIDE_DOWNLOAD_ZIP_MODAL : {
      return R.compose(
        R.assoc('showZipDownloadModal', false)
      )(state);
    }

    default : {
      return state;
    }
  }
};

export const getShowDownloadZipModal = R.path([STATE_KEY, 'showZipDownloadModal']);
export const getZipLink              = R.path([STATE_KEY, 'zipLink']);
export const getZipError             = R.path([STATE_KEY, 'zipError']);

export default DocumentReducer;
