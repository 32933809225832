import React, { Component } from 'react';
import styled               from 'styled-components';
import * as R               from 'ramda';

import {
  StarBorder,
  FileDownload,
  Flag,
  OpenInNew,
  // RotateRight,
  Block
} from '@material-ui/icons';

import Logo from '../../../../../components/Logo';

import { BigPlayButton, Player } from 'video-react';

const Container = styled.div`
  min-height : 50px;
  width      : 50%;
  position   : relative;
`;

const IconsWrapper = styled.div`
  display         : flex;
  margin          : 12px;
  padding         : 7px;
  width           : 180px;
  justify-content : space-between;
  background      : rgba(54, 54, 54, 0.74);
  border-radius   : 15px;
`;

const RejectedButton = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : center;
  cursor          : pointer;
  margin          : 12px;
  height          : 25px;
  min-width       : 100px;
  border-radius   : 50px;
  font-weight     : 600;
  font-size       : 12px;
  background      : ${props => props.isRejected ? 'red' : '#fff'};
  box-shadow      : 1px 1px 4px rgba(0, 0, 0, 0.2);
  color           : #000;
`;

const ButtonsContainer = styled.div`
  position        : absolute;
  top             : 25px;
  width           : 100%;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  box-sizing      : border-box;
`;

const styles = {
  icon : {
    color        : 'white',
    paddingRight : '.375rem',
    cursor       : 'pointer'
  },
  activeIcon : {
    color        : 'red',
    paddingRight : '.375rem',
    cursor       : 'pointer'
  },
};

const QuestionTitle = styled.p`
  text-align  : left;
  font-size   : .75rem;
  font-weight : 500;
  font-style  : oblique;
  margin-left : .5rem;
  line-height : 1.2;
  min-height  : 15px;
`;

const Video = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : center;
  
  .video-react-video, .video-react {
    background-color  : white;
  }
`;


class VideoContainer extends Component {
  state = {
    isRejected : R.has(this.props.video.canonicalKey, this.props.rejectedQuestions)
  };

  componentDidUpdate(prevProps) {
    if (!R.equals(prevProps.video, this.props.video)) {
      this.setState({
        isRejected: R.has(this.props.video.canonicalKey, this.props.rejectedQuestions)
      });
    }
  }

  toggleReject = questionUuid => {
    const isRejected = this.state.isRejected;
    this.setState(prevState => ({ isRejected : !prevState.isRejected }));

    this.props.toggleRejectVideoQuestion(!isRejected, questionUuid);
  };

  downloadVideo = video => {

    const url   = R.pathOr('', ['document', 'url'],   video),
          title = R.pathOr('', ['document', 'title'], video);

    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload       = function() {
      const urlCreator = window.URL || window.webkitURL;
      const videoUrl = urlCreator.createObjectURL(this.response);

      let a      = document.createElement('a');
      a.href     = videoUrl;
      a.download = title;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    xhr.send();
  };

  updateRotation = (rotation) => {
    if(!this.video) {
      return;
    }

    const thisVideo: HTMLVideoElement = R.path(['video', 'video'], this.video);
    if(!thisVideo) {
      return;
    }
    thisVideo.style.transform = `rotate(${rotation}deg)`;
  }  

  render () {
    const { isRejected } = this.state;
    const {
      video,
      onFlag,
      onBlock,
      onStar,
      //onRotate,
      isClientUser,
      blockVideos,
      reviewMode
    } = this.props;

    const { flag, block, star, rotate } = video;
    const { icon, activeIcon }  = styles;

    const videoUrl      = R.pathOr('', ['document', 'url'], video);
    const questionTitle = R.propOr('', 'title',             video);
    const questionUuid  = R.propOr('', 'uuid',              video);

    const isBlockedVideo = R.isEmpty(video) || (isClientUser && !R.isNil(R.find(R.eqProps('uuid', video.document))(blockVideos)));

    const rotation = R.defaultTo(0, rotate);
    
    return (
      <Container>
        <QuestionTitle>{questionTitle}</QuestionTitle>
        <div>
        {
          !isBlockedVideo ? (
            <Video>
              <Player 
                fluid = { false }
                src   = {`${videoUrl}`}
                crossOrigin={"Anonymous"}
                height = { 400 }
                ref={(video => {
                  this.video = video;
                  this.updateRotation(rotation);
                })}
              >
                <BigPlayButton position={"center"}/>
              </Player>
            </Video> 
          ) : (
            <Logo style={{ maxHeight : '400px' }}/>
          )
        }
        </div>
        {
          isClientUser ? (
            <ButtonsContainer>
              <IconsWrapper style={{ width : '70px' }}>
                <FileDownload
                  name    = "FileDownload"
                  style   = {styles.icon}
                  onClick = {() => this.downloadVideo(video)}
                />
                <OpenInNew
                  name    = "OpenInNew"
                  style   = {styles.icon}
                  onClick = {() => window.open(`${ videoUrl }`)}
                />
              </IconsWrapper>
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <IconsWrapper>
                <StarBorder
                  name    = "StarBorder"
                  style   = {star ? activeIcon : icon}
                  onClick = {() => onStar(star)}
                />
                <Block
                  name    = "Block"
                  style   = {block ? activeIcon : icon}
                  onClick = {() => onBlock(block)}
                />
                <Flag
                  name    = "Flag"
                  style   = {flag ? activeIcon : icon}
                  onClick = {() => onFlag(flag)}
                />
                <FileDownload
                  name    = "FileDownload"
                  style   = {styles.icon}
                  onClick = {() => this.downloadVideo(video)}
                />
                <OpenInNew
                  name    = "OpenInNew"
                  style   = {styles.icon}
                  onClick = {() => window.open(`${ videoUrl }`)}
                />
                {/* <RotateRight
                  name    = "RotatePhoto"
                  style   = {styles.icon}
                  onClick = {() => onRotate(rotation)}
                /> */}
              </IconsWrapper>
              {reviewMode && (
                <RejectedButton
                  isRejected = {isRejected}
                  onClick    = {() => this.toggleReject(questionUuid)}
                >
                  REJECT
                </RejectedButton>
              )}
            </ButtonsContainer>
          )
        }
      </Container>
    );
  }
}

export default VideoContainer;
