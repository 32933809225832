import * as R  from 'ramda';
import * as RA from 'ramda-adjunct';

import React     from 'react';
import PropTypes from 'prop-types';

import {
  compose,
  setPropTypes,
  withProps,
  withStateHandlers
} from 'recompose';

import GeneralListWidgetContent from './GeneralListWidgetContent';
import WidgetContent            from './WidgetContent';
import WidgetHeader             from './WidgetHeader';

import Typography  from '@material-ui/core/Typography';
import { history } from '../../store';

const FeedbackWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onEditClick,
    onMoveClick,
    onItemClick
  }) => (
  <div>
    <WidgetHeader
      title        = {title}
      description  = {description}
      onTitleClick = {onTitleClick}
      onEditClick  = {onEditClick}
      onMoveClick  = {onMoveClick}
    />
    <WidgetContent>
      {widgetContent ? (
        widgetContent.map(
          ({ id, checkInTime, feedback, projectName, retailer, doorNumber, doorLocation }, i) => (
            <GeneralListWidgetContent
              key          = {i}
              blurb        = {feedback}
              date         = {checkInTime}
              projectName  = {projectName}
              retailer     = {retailer}
              doorNumber   = {doorNumber}
              doorLocation = {doorLocation}
              onClick      = {() => onItemClick(id)}
            />
          )
        )
      ) : (
        <Typography paragraph={true} variant="body2">
          No feedback...
        </Typography>
      )}
    </WidgetContent>
  </div>
);

export default compose(
  withProps(({ widgetData : { description, filteredWidgetData, title } }) => ({
    description,
    title,
    widgetContent : R.compose(
      R.sort(R.descend(R.prop('checkInTime'))),
      R.filter(({ feedback }) => Boolean(feedback)),
      R.map(
        R.compose(
          R.zipObj(['id', 'checkInTime', 'projectName', 'retailer', 'doorNumber', 'doorLocation', 'feedback']),
          RA.paths([
            ['id'],
            ['expectedCheckinTime'],
            ['metaJson', 'projectName'],
            ['metaJson', 'retailer'],
            ['metaJson', 'doorNumber'],
            ['metaJson', 'doorLocation'],
            ['formState', 'feedback', 'message']
          ])
        )
      )
    )(filteredWidgetData)
  })),
  withStateHandlers(null, {
    onItemClick : () => id => {
      history.push('/svr-response/' + id);
    }
  }),
  setPropTypes({
    description            : PropTypes.string,
    title                  : PropTypes.string,
    widgetData             : PropTypes.object.isRequired,
    onTitleClick           : PropTypes.func,
    onEditClick            : PropTypes.func,
    onMoveClick            : PropTypes.func,
    widgetContent : PropTypes.arrayOf(
      PropTypes.shape({
        id           : PropTypes.number,
        checkInTime  : PropTypes.string,
        projectName  : PropTypes.string,
        retailer     : PropTypes.string,
        doorNumber   : PropTypes.string,
        doorLocation : PropTypes.string,
        feedback     : PropTypes.string,
      })
    )
  })
)(FeedbackWidgetContainer);
