import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import styled                    from 'styled-components';

const Container = styled.div`
  height: ${({ containerHeight }) => containerHeight ? containerHeight : '458'}px;
`;

const styles = theme => ({
  widgetContent : {
    position  : 'relative',
    overflowY : 'scroll',
    padding   : `18px 15px 20px ${theme.custom.spacing.md}`
  },
});

const WidgetContent = ({ children, classes, containerHeight }) => (
  <Container className={classes.widgetContent} containerHeight={containerHeight}>{children}</Container>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    children        : PropTypes.node,
    classes         : PropTypes.object.isRequired,
    theme           : PropTypes.object.isRequired,
    containerHeight : PropTypes.number
  })
)(WidgetContent);
