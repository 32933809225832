
import React                             from 'react';
import styled                            from 'styled-components';
import * as R                            from 'ramda';
import { List, ListItem }                from '@material-ui/core';
import { compose, withState }            from 'recompose';



const cannedResponseTypes = [
    {
        title: "Content",
        responses: [
            "I have edited spelling and grammar errors for you this time. Going forward, please proof-read before submitting",
            "Provide more detailed comments about conversations with management",
            "Provide more detailed comments about your accomplishments, successes and challenges"
        ]
    },
    {
        title: "Execution",
        responses: [
            "Negotiate with management for additional floor space",
            "Negotiate with management for more focal space",
            "Negotiate with management for mannequin presence",
            "Feature looks or newest delivery pieces on the mannequin(s)",
            "Change mannequins on future visits",
            "Fill in negative space on the wall/fixtures",
            "Refolded/detail folding stacks for a cleaner presentation",
            "Color block according to the direction provided",
            "Partner with management to acquire signage/branding",
            "Rotate product for a fresh presentation",
            "Size product",
            "Tuck tags"
        ]
    },
    {
        title: "Photos",
        responses: [
            "Overview photos should be taken in landscape orientation (horizontal)",
            "Overview photo should be taken from a wider angle; try taking the photo from a step stool or stepping back and holding the camera high and tilted down for a birds eye view",
            "Ensure photos do not cut off fixtures and/or branding/signage",
            "Include a photo of the damaged fixtures/mannequins/graphics",
            "Capture photos without people",
            "Capture competitor photos",
            "Mannequin photos required on future visits",
            "Before/After Photos need to be from the same vantage point",
            "Before/After Photos need to be taken in the same orientation (vertical/horizontal)"
        ]
    }
];

const ResponseTypesList = styled.div`
    font-size: 0.85rem;
    background-color: whitesmoke;
  `;

const ResponseType = styled(ListItem)`
    display: block!important;
    padding: 0!important;
  `;

const ResponseTypeTitle = styled.div`
    cursor: pointer;
    width: 100%;
    background-color: white;
    border: 1px solid whitesmoke;
    padding: 0.4rem!important;
    &:hover {
      background-color: whitesmoke;
    }
  `;

const ResponsesList = styled(List)`
    display: flex;
    flex-direction: column;
    gap: 0.4rem!important;
    padding: 0 0.4rem!important;
  `;

const StyledListItem = styled(ListItem)`
    cursor: pointer;
    &:hover {
      background-color: white;
    }
    text-indent: 0;
    padding: 0!important;
  `;

const CannedResponses = ({showCannedResponses, setShowCannedResponses, onClickCannedResponse}) => {
    return (
        <ResponseTypesList>
            {cannedResponseTypes.map((responseType, i) => {
                return (
                    <ResponseType
                        key={i}>
                        <ResponseTypeTitle
                            onClick={() => setShowCannedResponses(prevState => {
                                const newState = [...prevState];
                                newState[i] = !newState[i];
                                return newState;
                            })}
                        >
                            <strong>{responseType.title}</strong>
                        </ResponseTypeTitle>
                        <ResponsesList
                            style={!showCannedResponses[i] ? { display: "none" } : {}}
                        >
                            {responseType.responses.map((r, j) => {
                                return (
                                    <StyledListItem
                                        onClick={() => onClickCannedResponse && onClickCannedResponse(cannedResponseTypes[i].responses[j])}
                                        // onClick={() => setTextFieldValue(prevValue => `${prevValue} \n\n ${cannedResponseTypes[i].responses[j]}`)}
                                        key={j}>
                                        {r}
                                    </StyledListItem>
                                );
                            })}
                        </ResponsesList>
                    </ResponseType>
                );
            })}
        </ResponseTypesList>
    );
};

const withHandlers = compose(
    withState("showCannedResponses", "setShowCannedResponses", cannedResponseTypes.map(R.F))
);


export default withHandlers(CannedResponses);