import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logout as _logout } from '../AuthActions';

const LogoutPage = ({ logout, history }) => {
  logout();
  history.push('/');

  return null;
};

LogoutPage.propTypes = {
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(_logout()),
});

export default withRouter(connect(null, mapDispatchToProps)(LogoutPage));
