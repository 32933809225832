import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';
import * as R    from 'ramda';

import { connect } from 'react-redux';

import ArrowRightIcon from './icons/ArrowRightIcon';
import ArrowDownIcon  from './icons/ArrowDownIcon';

import SavedRollupForm from './SavedRollupForm';

import { isClientUser as _isClientUser } from '../../client/ClientReducer';
import { compose } from 'recompose';

const Wrapper = styled.div`
  height     : initial;
  min-height : 50vh;
  padding    : 1.5rem;
  flex-grow  : 1;
  min-width  : 50%;
  overflow   : hidden;

  svg {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-size   : .875rem;
  font-weight : 500;
`;

const FormTitleContainer = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;

  p {
    margin : 0px !important;
    cursor : pointer;
  }
`;

const IDSuper = styled.span`
  font-size   : 0.5em;
  font-weight : bold;
  position    : relative;
  top         : -0.5em;
`;

const SavedRollupsFormsList = ({
  forms,
  handleToggleForm,
  selectedForm,
  selectedQuestions,
  handleSelectQuestion,
  title,
  onlyOne,
  onlyPhotos,
  onlyVideos,
  onlyMultipleChoice,
  onlyNumeric,
  filteredQuestions,
  filterSelectedValue,
  isClientUser
}) => (
  <Wrapper>
    <Title>{title}</Title>
    {
     forms.length === 0 && selectedForm.length === 0 ? 
     <div> <p> Please select 2 or more parameters to load SVR forms.</p> </div>
    //  <div> <p> All SVR associated with your selection are archived. Please change your selection. </p> </div>
     // : forms.length === 0 && selectedForm.length === 0 && selectedQuestions.length === 0 ?
     // <div> <p></p> </div> 
     :    
    forms.map(svrForm => (
      <div key={svrForm.id}>
        <FormTitleContainer onClick={() => handleToggleForm(svrForm.canonicalKey)}>
          {selectedForm === svrForm.canonicalKey ? <ArrowDownIcon /> : <ArrowRightIcon />}
          <p>{svrForm.title} <IDSuper>#{svrForm.id}</IDSuper></p>
        </FormTitleContainer>
        {selectedForm === svrForm.canonicalKey && (
          <SavedRollupForm
            handleSelectQuestion = {handleSelectQuestion}
            selectedQuestions    = {selectedQuestions}
            preSections          = {R.pathOr([], ['content', 'structure', 0], svrForm)}
            duringSections       = {R.pathOr([], ['content', 'structure', 1], svrForm)}
            postSections         = {R.pathOr([], ['content', 'structure', 2], svrForm)}
            onlyOne              = {onlyOne}
            onlyPhotos           = {onlyPhotos}
            onlyVideos           = {onlyVideos}
            onlyNumeric          = {onlyNumeric}
            onlyMultipleChoice   = {onlyMultipleChoice}
            filterSelectedValue  = {filterSelectedValue}
            filteredQuestions    = {filteredQuestions}
            isClientUser         = {isClientUser()}
          />
        )}
      </div>
    ))}
  </Wrapper>
);

const mapStateToProps    = () => ({
  isClientUser: _isClientUser
});

SavedRollupsFormsList.propTypes = {
  handleSelectQuestion : PropTypes.func.isRequired,
  handleToggleForm     : PropTypes.func.isRequired,
  forms                : PropTypes.array,
  selectedForm         : PropTypes.string,
  selectedQuestions    : PropTypes.array,
  filteredQuestions    : PropTypes.array,
  title                : PropTypes.string,
  onlyOne              : PropTypes.bool,
  onlyPhotos           : PropTypes.bool,
  onlyVideos           : PropTypes.bool,
  onlyNumeric          : PropTypes.bool,
  onlyMultipleChoice   : PropTypes.bool,
  filterSelectedValue  : PropTypes.string,
  isClientUser         : PropTypes.bool
};

SavedRollupsFormsList.defaulProps = {
  forms               : [],
  selectedForm        : '',
  selectedQuestions   : [],
  title               : 'SELECT QUESTIONS TO VIEW IN TABLE',
  onlyOne             : false,
  onlyPhotos          : false,
  onlyVideos          : false,
  onlyNumeric         : false,
  onlyMultipleChoice  : false,
  filteredQuestions   : [],
  filterSelectedValue : 'all',
  isClientUser        : R.F
};

export default compose(
  connect(mapStateToProps)
)(SavedRollupsFormsList);
