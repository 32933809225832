import React       from 'react';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';
import { connect } from 'react-redux';
import * as R      from 'ramda';

import {
  compose,
  defaultProps,
  lifecycle,
  setPropTypes,
  withHandlers,
  withStateHandlers,
} from 'recompose';

import {
  fetchSvrs  as _fetchSvrs,
  updateSvrStatus as _updateSvrStatus
} from '../SvrActions';

import {
  getClientList,
  getSvrs,
  getClient
} from '../SvrReducer';

import {
  getCurrentClient
} from '../../client/ClientReducer';

import SvrsTable      from '../components/SvrsTable';
import SvrListToolbar from '../components/SvrListToolbar';
import { Button }     from '../../../components/SearchFilter/styledComponents';
import { Dialog, Typography, DialogContent, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const NAMESPACE = 'svrs';

const TopContainer = styled.div`
  display         : flex;
  justify-content : end;
  color           : #c70000;
  padding-top     : 20px;
  padding-right   : 20px;
  height          : 50px;
  
  .load-data-button {
    display         : flex;
    justify-content : center;
    align-items     : center;
    min-height      : 0;
  }
`;

const SvrListPage = ({
   svrs,
   currentClient,
   clientList,
   goToEditPage,
   updateSvrStatus,
   client,
   isFetchingData,
   fetchSvrs,
   namespace
 }) => (
  <div>
    <Helmet>
      <title>SVRs</title>
    </Helmet>
    {!R.isNil(R.path(['currentClient', 'clientId'], client)) && <SvrListToolbar/>}
    <TopContainer>
      <Button
          className = 'load-data-button'
          onClick   = {() => {
            if(isFetchingData) {
              return;
            }
            fetchSvrs(namespace);
          }}
          // style={{ color: isFetchingData &&  '#939598' }}
          style={{ color: isFetchingData && theme.palette.pblack.black }}
        >
          <div>LOAD RESULTS</div>
      </Button>
    </TopContainer>
    <SvrsTable
      data         = {svrs}
      onUpdate     = {updateSvrStatus}
      goToEditPage = {goToEditPage}
      clientList   = {clientList}
      isEditable   = { !!currentClient.clientId }
    />

    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
     <DialogTitle>Loading...</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>
  </div>
);

const mapStateToProps = (state, props) => ({
  svrs          : getSvrs(state, props.namespace || NAMESPACE),
  clientList    : getClientList(state),
  currentClient : getCurrentClient(state),
  client        : getClient(state)
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  goToEditPage    : canonicalKey => props.history.push(`/svrs/${canonicalKey}`)
});

const withSvrs = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
       isFetchingData = false
     }) => ({
      isFetchingData
    }),
    ({
      setFetchingData : () => fetching => ({
        isFetchingData: fetching
      })
    })
  ),
  withHandlers({
    fetchSvrs       : props => namespace => {
      Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_fetchSvrs(namespace)))
      .then(() => props.setFetchingData(false));
    },
    updateSvrStatus : props => (canonicalKey, data) => {
      Promise.resolve(props.setFetchingData(true))
      .then(() => props.dispatch(_updateSvrStatus(canonicalKey, data)))
      .then(() => props.setFetchingData(false));
    }
  }),
  defaultProps({
    svrs         : [],
    namespace    : NAMESPACE,
    goToEditPage : () => {},
  }),
  setPropTypes({
    svrs         : PropTypes.array,
    goToEditPage : PropTypes.func,
    fetchSvrs    : PropTypes.func.isRequired,
    client       : PropTypes.object
  }),
  lifecycle({
    componentDidMount() {
    },
  }),
);

export default withSvrs(SvrListPage);
