import React     from 'react';
import PropTypes from 'prop-types';
import Modal     from '../../components/Modal/Modal';

const QuickReviewQuestionsModal = ({ withoutCancel, onApprove }) => (
  <Modal
    onApprove={onApprove}
    withoutCancel={withoutCancel}
    onCancel={() => {}}
  >
    This SVR doesn&#39;t have any quick review questions.
  </Modal>
);

QuickReviewQuestionsModal.propTypes = {
  withoutCancel : PropTypes.bool,
  onApprove     : PropTypes.func.isRequired,
};

QuickReviewQuestionsModal.defaultProps = {
  withoutCancel: false,
};

export default QuickReviewQuestionsModal;
