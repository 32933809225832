import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  modalInner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '86%',
    height: '90%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.custom.shadows.blockShadow.regular,
    borderRadius: theme.custom.borderRadius,
    outline: 'none',
  },
});

const ModalInner = ({ children, classes }) => (
  <div tabIndex={-1} className={classes.modalInner}>
    {children}
  </div>
);

const propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
};
ModalInner.propTypes = propTypes;

export default withStyles(styles)(ModalInner);
