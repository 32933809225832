import * as R        from 'ramda';
import React         from 'react';
import PropTypes     from 'prop-types';
import { titleCase } from 'change-case';

import {
  compose,
  defaultProps,
  setPropTypes,
  withStateHandlers
} from 'recompose';

import { withStyles, withTheme }         from '@material-ui/core/styles';
import styled                            from 'styled-components';

import Modal from './Modal';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Checkbox,
  MenuItem,
  TextField
} from '@material-ui/core';

const styles = () => ({
  input : {
    marginTop : '50px'
  },
  label : {
    fontWeight    : 'bold',
    textTransform : 'uppercase'
  }
});

const CheckboxWrapper = styled.div`
  position: relative;
  margin: 20px 0;
`;

const CheckboxLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-top: 50px;
`;

const SaveAs__Modal = (
  {
    canSave,
    classes,
    description,
    inferDescription,
    metaJson,
    namespace,
    onCancel,
    onChange,
    onSave,
    onDelete,
    isDeleting,
    restrictTokenUserId,
    showWhen,
    widgetTypeSelect,
    setIsDeleting,
    title,
    tokenUserId,
    isClientUser
  }) => (
  <Modal
    onSave   = {onSave}
    onCancel = {onCancel}
    onDelete = { R.is(Function, onDelete) ? () => { setIsDeleting(true); } : null }
    showWhen = {showWhen}
    canSave  = {canSave}
  >
    <TextField
      autoFocus
      fullWidth
      id              = {`${namespace}-title`}
      label           = "name"
      margin          = "normal"
      name            = "title"
      onChange        = {onChange}
      value           = {title}
      InputLabelProps = {{
        shrink    : true,
        className : classes.label
      }}
    />

    <TextField
      fullWidth
      className       = {classes.input}
      id              = {`${namespace}-description`}
      label           = "description"
      margin          = "normal"
      name            = "description"
      onChange        = {onChange}
      value           = {inferDescription ? '' : description}
      disabled        = {inferDescription}
      placeholder     = {inferDescription ? 'Inferred after widget creation' : ''}
      InputLabelProps = {{
        shrink    : true,
        className : classes.label
      }}
    />

    {R.both(R.is(Array), R.length)(widgetTypeSelect) && (
      <TextField
        select
        className = {classes.input}
        label     = "widget type"
        margin    = "normal"
        name      = "widget-type"
        onChange  = {e => {
          if (!e.target.value) return;
          onChange({
            target : {
              name  : 'widgetType',
              value : e.target.value
            }
          });
        }}
        InputLabelProps = {{
          shrink    : true,
          className : classes.label
        }}
        value = {metaJson.widgetType || ''}>
        {
          R.map(widgetType => (
            <MenuItem key={widgetType} value={widgetType}>{titleCase(widgetType)}</MenuItem>
          ))(widgetTypeSelect)
        }
      </TextField>
    )}
    <CheckboxWrapper>
      <CheckboxLabel htmlFor={`${namespace}-restrict-token-user-id-checkbox`}>
        ONLY VISIBLE TO ME
      </CheckboxLabel>
      <Checkbox
        label    = "DESCRIPTION"
        value    = {`${tokenUserId}`}
        id       = {`${namespace}-restrict-token-user-id-checkbox`}
        name     = "restrictTokenUserId"
        checked  = {Boolean(restrictTokenUserId)}
        onChange = {() =>
          onChange({
            target : {
              name  : 'restrictTokenUserId',
              value : restrictTokenUserId ? null : `${tokenUserId}`
            }
          })
        }
      />
    </CheckboxWrapper>
    <CheckboxWrapper>
      <CheckboxLabel htmlFor={`${namespace}-embed-photos-checkbox`}>
        EMBED PHOTOS IN EXCEL EXPORT
      </CheckboxLabel>
      <Checkbox
        label    = "DESCRIPTION"
        value    = {`${metaJson.embedPhotosInExcelReport}`}
        id       = {`${namespace}-embed-photos-checkbox`}
        name     = "embedPhotosInExcelReport"
        checked  = {metaJson.embedPhotosInExcelReport}
        onChange = {(e) => 
          onChange({
            target : {
              name  : 'embedPhotosInExcelReport',
              value : e.target.checked
            }
          })
        }
      />
    </CheckboxWrapper>
    {
      (namespace === "saved-rollup" || namespace === "savedRollup") && !isClientUser
        ? <CheckboxWrapper>
            <CheckboxLabel htmlFor={`${namespace}-include-motives`}>
              INCLUDE STARRED PHOTOS & FLAGGED PHOTOS
            </CheckboxLabel>
            <Checkbox
              label    = "DESCRIPTION"
              value    = {`${metaJson.includeMotivesInExcelReport}`}
              id       = {`${namespace}-include-motives`}
              name     = "includeMotivesInExcelReport"
              checked  = {metaJson.includeMotivesInExcelReport}
              onChange = {(e) => 
                onChange({
                  target : {
                    name  : 'includeMotivesInExcelReport',
                    value : e.target.checked
                  }
                })
              }
            />
          </CheckboxWrapper>
          : null
    }
    {isDeleting ? (
      <Dialog
        open    = {true}
        onClose = {() => setIsDeleting(false)}
      >
        <DialogTitle id="widget-dialog-title">Are you sure?</DialogTitle>
        <DialogContent style={{ justifyContent : 'space-between' }}>
          <Button onClick={() => setIsDeleting(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={onDelete} color="primary">
            Yes
          </Button>
        </DialogContent>
      </Dialog>
    ) : null
    }
  </Modal>
);

export default compose(
  withTheme(),
  withStyles(styles),
  defaultProps({
    metaJson : {
      widgetType : '',
      embedPhotosInExcelReport: false,
      includeMotivesInExcelReport: false
    },
    inferDescription : false
  }),
  setPropTypes({
    classes          : PropTypes.object.isRequired,
    theme            : PropTypes.object.isRequired,
    inferDescription : PropTypes.bool
  }),
  withStateHandlers(
    ({
       isDeleting = false
     }) => ({
      isDeleting
    }),
    {
      setIsDeleting : () => bool => ({
        isDeleting : bool
      })
    }
  )
)(SaveAs__Modal);
