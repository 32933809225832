import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import * as R                from 'ramda';

import { Button, Chip, Grid, Modal, TextField }                    from '@material-ui/core';
import { Content, Footer, GridSection, ModalWrapper, TagsWrapper } from './styled-components/Modal';

import { QUESTION_TYPES_NAME }   from '../types';

class EditCommonQuestionModal extends Component {
  state = {
    title   : '',
    type    : null,
    tags    : [],
    options : [],
  };

  setTitle = (event) => {
    this.setState({title: event.target.value});
  };

  setCurrentTag = () => {

  };

  close = () =>  {
    this.props.isEditingAction(false);
  };

  save = () =>  {
    const editedQuestion = R.assoc('title', this.state.title, this.props.isEditing.questionBeingEdited);

    this.props.saveEditedCommonQuestion(editedQuestion);
    this.setState({
      title   : '',
      type    : null,
      tags    : [],
      options : [],
    });

    this.close();
  };

  renderModal = () => {
    return <Modal
              aria-labelledby="Create Question"
              aria-describedby="Create Question"
              open={this.props.isEditing.status}
            >
            <ModalWrapper onSubmit={this.props.save}>
              <Content>
                <Grid container>
                  <GridSection item xs={6}>
                    <TextField
                      fullWidth
                      id="title"
                      label={`${R.toUpper(R.propOr('', this.props.type, QUESTION_TYPES_NAME))} QUESTION`}
                      defaultValue={this.props.isEditing.questionBeingEdited.title}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      autoFocus
                      onChange={this.setTitle}
                    />
                  </GridSection>
                  <GridSection item xs={6}>
                    <TextField
                      fullWidth
                      id="tags"
                      label="TAGS"
                      value={this.props.currentTag}
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.props.setCurrentTag}
                      onKeyPress={e => R.equals('Enter', e.key) && !R.isEmpty(this.props.currentTag) && this.addTag()}
                    />
                    <TagsWrapper>
                      {R.is(Array, this.props.tags) && this.props.tags.map(tag => (
                        <Chip
                          key={`${tag.label}_${tag.id}`}
                          label={tag.label}
                          onDelete={() => this.deleteTag(tag)}
                        />
                      ))}
                    </TagsWrapper>
                  </GridSection>
                </Grid>
              </Content>
              <Footer>
              <Button onClick={this.close} color="secondary">CANCEL</Button>
              <Button onClick={this.save} color="secondary">SAVE COMMON QUESTION</Button>
              </Footer>
            </ModalWrapper>
            </Modal>;
  };

  render () {
    return (
      this.props.isEditing.questionBeingEdited ? this.renderModal() : null
    );
  }
}


EditCommonQuestionModal.propTypes = {
  open    : PropTypes.bool,
  title   : PropTypes.string,
  type    : PropTypes.string,
  tags    : PropTypes.array,
  options : PropTypes.array,
};

EditCommonQuestionModal.defaultProps = {
  open    : false,
  title   : '',
  type    : null,
  tags    : [],
  options : [],
};

export default EditCommonQuestionModal;
