import * as R from 'ramda';

import recursivelyPluck from './recursivelyPluck';

const getQuestions = section => R.compose(
  R.filter(R.pathEq(['meta', 'isQuickReview'], true)),
  R.flatten,
  R.map(recursivelyPluck('content'))
)(section);

export const getAllQuickReviewQuestions = form => R.compose(
  R.flatten,
  R.map(getQuestions),
  R.path(['content', 'structure']),
)(form);
