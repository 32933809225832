import * as R from 'ramda';
//import axios from 'axios';

// const WRS_APP_DOCS_HOST = process.env.WRS_APP_DOCS_HOST;
// const WRS_APP_SEC_DOCS_HOST = process.env.WRS_APP_SEC_DOCS_HOST;
// const WRS_APP_REDUCED_DOCS_HOST = process.env.WRS_APP_REDUCED_DOCS_HOST;
// const WRS_APP_SEC_REDUCED_DOCS_HOST = process.env.WRS_APP_SEC_REDUCED_DOCS_HOST;

const WRS_APP_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
const WRS_APP_SEC_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
const WRS_APP_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";
const WRS_APP_SEC_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";

// const WRS_APP_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
// const WRS_APP_SEC_DOCS_HOST = "https://wrsphs.mstage.wintellplus.com";
// const WRS_APP_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";
// const WRS_APP_SEC_REDUCED_DOCS_HOST = "https://wrsphs.mstage.wintellplus.com";

//const cloudFrontHost = process.env.REACT_APP_CLOUDFRONT_HOST;

//const maybeAddSizing = size => R.ifElse(R.equals('full'), R.always(''), R.always(`${size}x${size}/`))(size);

// const prepareImageTs200xUrl = url => {
//     const imagesourceBucketwithprefixUrl = "https://wrsprodbucket.s3.amazonaws.com/wrsprodbucket";
//     const imagesourceBucketwithprefixUrlts200x = "https://wrsprodresizedbucket.s3.amazonaws.com/wrsprodresizedimgs/ts200x";
    
//     if (url.includes(imagesourceBucketwithprefixUrl)) {
//       const tempts200url = R.replace(imagesourceBucketwithprefixUrl, imagesourceBucketwithprefixUrlts200x, url);
//       if (tempts200url.includes(".png")) {
//         const ts200url = R.replace(".png", "-ts200x.png", tempts200url);
//         return ts200url;
//       }
//       else if (tempts200url.includes(".jpg")) {
//         const ts200url = R.replace(".jpg", "-ts200x.jpg", tempts200url);
//         return ts200url;
//       }
//       else if (tempts200url.includes(".jpeg")) {
//         const ts200url = R.replace(".jpeg", "-ts200x.jpeg", tempts200url);
//         return ts200url;
//       }
//     }
// }

// const checkImageTs200xUrl = async (url) => {
//   const tempts200url = prepareImageTs200xUrl(url);
//   try{
//     const responsegetDataReg = await axios.head(tempts200url);

//     if ( !R.isNil(responsegetDataReg) && !R.isEmpty(responsegetDataReg)){

//       console.log("tempts200url:", responsegetDataReg);

//       return true;
//     }
//     else {
//       console.log("tempts200url not exits");
//       return false;
//     }
//   }
//   catch (err) {
//     //console.log("tempts200url ERROR:", err);
//     return false;
//   }
// }



export const makeDecoratedImageUrl = (rotate = 0, size = 120) => url =>  {
if (url.includes("cloudfront.net")) {

    var rotateurl270 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(270)";
    var rotateurl180 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(180)";
    var rotateurl90 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(90)";
    var rotateurl0 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(0)";

    var curl = "";
    if (url.includes(rotateurl270)) {
      curl = url.replace(rotateurl270, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
      
    }
    else if (url.includes(rotateurl180)) {
      curl = url.replace(rotateurl180, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    }
    else if (url.includes(rotateurl90)) {
      curl = url.replace(rotateurl90, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    }
    else if (url.includes(rotateurl0)) {
      curl = url.replace(rotateurl0, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    }
    const tempSecUrl = getSecUrl(url); 
    return tempSecUrl;
}
else {

  const temp_url_dateyear = url.substring((url.length - 45), ((url.length - 45) + 4));
  const url_dateyear = (R.isNil(temp_url_dateyear) || R.isEmpty(temp_url_dateyear) ) ? 0 : Number(temp_url_dateyear)

  if (url_dateyear > 0 && url_dateyear <= 2022)
  {
    const tempSecUrl = getSecUrl(url); 
    return tempSecUrl;
  }

 // const ts200xurlExists = await checkImageTs200xUrl(url);
  // if ( ts200xurlExists ) {
  //   console.log("ts200xurlExists:", ts200xurlExists);

  if (size === 200) {
    const tempSecUrl = getSecUrl(url); 
    return getTs200xurl(tempSecUrl);
  }
  else if (size === 400) {
    const tempSecUrl = getSecUrl(url); 
    return getTs400xurl(tempSecUrl);
  }
  else if(size === 800)
  {
    const tempSecUrl = getSecUrl(url); 
    return getTs800xurl(tempSecUrl);
  }
  else if(size === 1280)
  {
    const tempSecUrl = getSecUrl(url); 
    return getTs1280xurl(tempSecUrl);
  }
  else if(size === "full")
  {
    const tempSecUrl = getSecUrl(url);
    return tempSecUrl;
  }
  else {    
    const tempSecUrl = getSecUrl(url);
      return tempSecUrl;    
  }
}
};

// export const makeDecoratedImageUrl = (rotate=0, size=120) => url => {
//   if (cloudFrontHost) {
//     return R.compose(
//       R.join('/'),
//       R.prepend(`https://${cloudFrontHost}.cloudfront.net/fit-in/${maybeAddSizing(size)}filters:rotate(${rotate})`),
//       R.takeLast(2),
//       R.split('/'),
//       R.defaultTo('')
//     )(url);
//   } else {
//     return url;
//   }
// };

 const getSecUrl = (burl) =>
 {
  if (!R.isNil(burl) && burl.includes(WRS_APP_DOCS_HOST)) {
    const tempSecUrl = R.replace(WRS_APP_DOCS_HOST, WRS_APP_SEC_DOCS_HOST, burl);
    return tempSecUrl;
  }
  else if(!R.isNil(burl) && burl.includes(WRS_APP_REDUCED_DOCS_HOST))
  {
    const tempSecUrl = R.replace(WRS_APP_REDUCED_DOCS_HOST, WRS_APP_SEC_REDUCED_DOCS_HOST, burl);
    return tempSecUrl;
  }
  else
  {
    return burl;
  }  
 }

const getTs200xurl = (url, xsurl = "") => {
  if (url.includes('ts200x')) {
    return url;
  }
  else {
    // const imagesourceBucketwithprefixUrl = "https://wrsprodbucket.s3.amazonaws.com/wrsprodbucket";
    // const imagesourceBucketwithprefixUrlts200x = "https://wrsprodresizedbucket.s3.amazonaws.com/wrsprodresizedimgs/ts200x";

    const imagesourceBucketwithprefixUrl = WRS_APP_SEC_DOCS_HOST + "/wrsprodbucket";
    const imagesourceBucketwithprefixUrlts200x = WRS_APP_SEC_REDUCED_DOCS_HOST+ "/wrsprodresizedimgs/ts200x";

    if (R.isNil(xsurl) === false) {
      if (xsurl.includes(imagesourceBucketwithprefixUrlts200x)) {
        return xsurl;
      }
    }
    if (url.includes(imagesourceBucketwithprefixUrl)) {
      const tempts200url = R.replace(imagesourceBucketwithprefixUrl, imagesourceBucketwithprefixUrlts200x, url);
      if (tempts200url.includes(".png")) {
        const ts200url = R.replace(".png", "-ts200x.png", tempts200url);
        return ts200url;
      }
      else if (tempts200url.includes(".jpg")) {
        const ts200url = R.replace(".jpg", "-ts200x.jpg", tempts200url);
        return ts200url;
      }
      else if (tempts200url.includes(".jpeg")) {
        const ts200url = R.replace(".jpeg", "-ts200x.jpeg", tempts200url);
        return ts200url;
      }
    }
    return url;
  }
}

const getTs400xurl = (url) => {
  if (url.includes('ts400x')) {
    return url;
  }
  else {
    const imagesourceBucketwithprefixUrl = WRS_APP_SEC_DOCS_HOST + "/wrsprodbucket";
    const imagesourceBucketwithprefixUrlts400x = WRS_APP_SEC_REDUCED_DOCS_HOST+ "/wrsprodresizedimgs/ts400x";

    if (url.includes(imagesourceBucketwithprefixUrl)) {
      const tempts400url = R.replace(imagesourceBucketwithprefixUrl, imagesourceBucketwithprefixUrlts400x, url);
      if (tempts400url.includes(".png")) {
        const ts400url = R.replace(".png", "-ts400x.png", tempts400url);
        return ts400url;
      }
      else if (tempts400url.includes(".jpg")) {
        const ts400url = R.replace(".jpg", "-ts400x.jpg", tempts400url);
        return ts400url;
      }
      else if (tempts400url.includes(".jpeg")) {
        const ts400url = R.replace(".jpeg", "-ts400x.jpeg", tempts400url);
        return ts400url;
      }
    }
    return url;
  }

}

const getTs800xurl = (url) => {
  if (url.includes('ts800x')) {
    return url;
  }
  else {
    const imagesourceBucketwithprefixUrl = WRS_APP_SEC_DOCS_HOST + "/wrsprodbucket";
    const imagesourceBucketwithprefixUrlts800x = WRS_APP_SEC_REDUCED_DOCS_HOST+ "/wrsprodresizedimgs/ts800x";

    if (url.includes(imagesourceBucketwithprefixUrl)) {
      const tempts800url = R.replace(imagesourceBucketwithprefixUrl, imagesourceBucketwithprefixUrlts800x, url);
      if (tempts800url.includes(".png")) {
        const ts800url = R.replace(".png", "-ts800x.png", tempts800url);
        return ts800url;
      }
      else if (tempts800url.includes(".jpg")) {
        const ts800url = R.replace(".jpg", "-ts800x.jpg", tempts800url);
        return ts800url;
      }
      else if (tempts800url.includes(".jpeg")) {
        const ts800url = R.replace(".jpeg", "-ts800x.jpeg", tempts800url);
        return ts800url;
      }
    }
    return url;
  }

}

const getTs1280xurl = (url) => {
  if (url.includes('ts1280x')) {
    return url;
  }
  else {
    const imagesourceBucketwithprefixUrl = WRS_APP_SEC_DOCS_HOST + "/wrsprodbucket";
    const imagesourceBucketwithprefixUrlts1280x = WRS_APP_SEC_REDUCED_DOCS_HOST+ "/wrsprodresizedimgs/ts1280x";

    if (url.includes(imagesourceBucketwithprefixUrl)) {
      const tempts1280url = R.replace(imagesourceBucketwithprefixUrl, imagesourceBucketwithprefixUrlts1280x, url);
      if (tempts1280url.includes(".png")) {
        const ts1280url = R.replace(".png", "-ts1280x.png", tempts1280url);
        return ts1280url;
      }
      else if (tempts1280url.includes(".jpg")) {
        const ts1280url = R.replace(".jpg", "-ts1280x.jpg", tempts1280url);
        return ts1280url;
      }
      else if (tempts1280url.includes(".jpeg")) {
        const ts1280url = R.replace(".jpeg", "-ts1280x.jpeg", tempts1280url);
        return ts1280url;
      }
    }
    return url;
  }

}


export const makeDecoratedThumbnailImageUrl = (rotate = 0, size = 120) => (url, xsurl) => {
  if (url.includes("cloudfront.net")) {    
    var rotateurl270 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(270)";
    var rotateurl180 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(180)";
    var rotateurl90 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(90)";
    var rotateurl0 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(0)";

    var curl = "";
    if (url.includes(rotateurl270)) {
      curl = url.replace(rotateurl270, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    }
    else if (url.includes(rotateurl180)) {
      curl = url.replace(rotateurl180, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    }
    else if (url.includes(rotateurl90)) {
      curl = url.replace(rotateurl90, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    }
    else if (url.includes(rotateurl0)) {
      curl = url.replace(rotateurl0, "");
      return curl = WRS_APP_SEC_DOCS_HOST + curl;
    } 

    const tempSecUrl = getSecUrl(url);   

     return tempSecUrl;
  }
  if (R.isNil(xsurl) || R.isEmpty(xsurl) ) {
    
    const temp_url_dateyear = url.substring((url.length - 45), ((url.length - 45) + 4));
    const url_dateyear = (R.isNil(temp_url_dateyear) || R.isEmpty(temp_url_dateyear) ) ? 0 : Number(temp_url_dateyear)
  
    if (url_dateyear > 0 && url_dateyear <= 2022)
    {
      const tempSecUrl = getSecUrl(url); 
      return tempSecUrl;
    }

    const tempSecUrl = getSecUrl(url);
    const tempSecxsUrl = getSecUrl(xsurl);

    return getTs200xurl(tempSecUrl, tempSecxsUrl);

  } else if (R.length(xsurl) > 0 && xsurl.includes('ts200x')) {
    const tempSecUrl = getSecUrl(xsurl);
    return tempSecUrl;
  }
  else {
    const tempSecUrl = getSecUrl(url);
    return tempSecUrl;
  }
}

const rotationSteps = {
  '0': '270',
  '270': '180',
  '180': '90',
  '90': '0'
};

export const getRotation = R.compose(
  R.defaultTo(0),
  R.head,
  R.match(/\d+/),
  R.defaultTo(''),
  R.head,
  R.match(/rotate\(\d+\)/g)
);

export const getNextRotation = R.compose(
  R.propOr('90', R.__, rotationSteps),
  getRotation
);
