import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';

import AddIcon            from '@material-ui/icons/AddCircleOutline';
import { Menu, MenuItem } from '@material-ui/core';

import { ITEM_TYPES } from '../../BuilderReducer';

const ITEMS = [
  { type: ITEM_TYPES.RADIO_BUTTON           , name: 'Radio Button' },
  { type: ITEM_TYPES.DROPDOWN               , name: 'Dropdown' },
  { type: ITEM_TYPES.CHECKBOX               , name: 'Checkbox' },
  { type: ITEM_TYPES.SINGLE_LINE            , name: 'Single Line' },
  { type: ITEM_TYPES.MULTI_LINE             , name: 'Multi Line' },
  { type: ITEM_TYPES.PHOTO                  , name: 'Photo' },
  { type: ITEM_TYPES.TOGGLE                 , name: 'Yes/No' },
  { type: ITEM_TYPES.QUESTION_GROUP         , name: 'Question Group' },
  { type: ITEM_TYPES.COMMON                 , name: 'Common Question' },
  { type: ITEM_TYPES.NUMERIC_INT            , name: 'Numeric'},
  { type: ITEM_TYPES.VIDEO                  , name: 'Video' },
  { type: ITEM_TYPES.SIGNATURE              , name: 'Signature' },
  { type: ITEM_TYPES.NUMERIC_CONDITIONAL    , name: 'Numeric Conditional' },
];

const AddButton = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  width           : 100%;
  cursor          : pointer;

  span {
    font-size   : 14px;
    font-weight : 500;
  }
`;

const AddSectionItem = ({ items, anchorEl, open, close, select }) => (
  <div>
    <AddButton
      aria-owns={anchorEl ? 'add-menu' : null}
      aria-haspopup="true"
      onClick={({ currentTarget }) => open(currentTarget)}
    >
      <AddIcon />
    </AddButton>

    <Menu
      id="add-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={close}
    >
      {items.map(i => (
        <MenuItem
          key={i.type}
          onClick={select.bind(null, i.type)}
        >
          {i.name}
        </MenuItem>
      ))}
    </Menu>
  </div>
);

AddSectionItem.propTypes = {
  items    : PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  anchorEl : PropTypes.any,
  open     : PropTypes.func.isRequired,
  close    : PropTypes.func.isRequired,
  select   : PropTypes.func.isRequired,
};

AddSectionItem.defaultProps = {
  items: ITEMS,
};

const addSelection = withStateHandlers(
  ({
    anchorEl = null,
    onAdd    = null,
  }) => ({ anchorEl, onAdd }),
  {
    open   : () => currentTarget => ({ anchorEl: currentTarget }),
    close  : () => () => ({ anchorEl: null }),
    select : ({ onAdd }) => (type) => {
      onAdd({ type });
      return { anchorEl: null };
    },
  },
);

export default addSelection(AddSectionItem);
