import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';
import * as R                from 'ramda';
import Downshift             from 'downshift';

import { Button, Chip, Grid, MenuItem, Modal, Paper, Switch, TextField } from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircleOutline';

const FOOTER_HEIGHT = 40;

const ModalWrapper = styled.form`
  position         : absolute;
  width            : 70%;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  background-color : #fff;
  border-left      : 10px solid #45a2cb;
  border-radius    : 3px;
  outline          : none;
`;

const Content = styled.div`
  display     : flex;
  max-height  : 90vh;
  overflow    : auto;
  padding     : 20px;
  box-sizing  : border-box;

  label {
    font-weight : bold;
    color       : #000;
  }

  span {
    font-weight: bold;
    font-size: 13px;
  }
`;

const Footer = styled.div`
  width           : 100%;
  display         : flex;
  height          : ${FOOTER_HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-end;

  button {
    margin-right: 40px;
  }
`;

const TagsWrapper = styled.div`
  overflow       : hidden;
  & > div {
    margin-right : 10px;
    margin-top   : 10px;
  }
`;

const StyledAddIcon = styled(AddCircleIcon)`
  position : absolute;
  top      : 50px;
  cursor   : pointer;
`;


const renderInputTag = (currentTag, setCurrentTag, addTag, handleAddTag, tags, searchedTags) => {
  const titleMatches   = R.propEq('title', currentTag);
  const hasSomeTitle   = currentTag && !tags.concat(searchedTags).some(titleMatches);
  const addNewTag      = () => addTag(currentTag).then(res => handleAddTag(R.path(['payload', 'data', 'data'], res)));
  const handleKeyPress = e => R.equals('Enter', e.key) && !R.isEmpty(currentTag) && hasSomeTitle && addNewTag();

  return (
    <div>
      <TextField
        fullWidth
        id="tags"
        label="TAGS"
        helperText="Tags will be auto-applied to all questions within this section."
        margin="normal"
        value={currentTag}
        onKeyPress={handleKeyPress}
        onChange={setCurrentTag}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {hasSomeTitle && <StyledAddIcon />}
    </div>
  );
};

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  handleAddTag,
}) => {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      onClick={() => handleAddTag(suggestion)}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.title}
    </MenuItem>
  );
};
renderSuggestion.propTypes = {
  highlightedIndex     : PropTypes.number,
  index                : PropTypes.number,
  itemProps            : PropTypes.object,
  selectedItem         : PropTypes.string,
  suggestion           : PropTypes.shape({ title: PropTypes.string }).isRequired,
  handleSelectQuestion : PropTypes.func,
};

const getSuggestions = (inputValue, searchedTags, tags) => {
  const uniqTags      = R.difference(searchedTags, tags);
  const titleContains = s => tag => tag.title.toLowerCase().indexOf(s.toLowerCase()) > -1;

  return R.compose(
    R.take(5),
    R.filter(R.__, uniqTags),
    titleContains
  )(inputValue);
};

const SectionModal = ({
  open,
  title,
  tags,
  meta: { forceQuestionOrder },
  setTitle,
  toggleForceQuestionOrder,
  save,
  close,
  addTag,
  deleteTag,
  handleAddTag,
  searchedTags,
  currentTag,
  setCurrentTag,
}) => (
  <Modal
    aria-labelledby="Create Section"
    aria-describedby="Create Section"
    open={open}
  >
    <ModalWrapper onSubmit={save}>
      <Content>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="title"
              label="SECTION TITLE"
              value={title}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              onChange={setTitle}
            />
          </Grid>
          <Grid item xs={4}>
            <Downshift>
              {({ getItemProps, highlightedIndex }) => (
                <div>
                  {renderInputTag(
                    currentTag,
                    setCurrentTag,
                    addTag,
                    handleAddTag,
                    tags,
                    searchedTags,
                  )}
                  {R.length(searchedTags) ? (
                    <div style={{position: 'absolute', zIndex: '99999'}}>
                      <Paper square>
                        {getSuggestions(currentTag, searchedTags, tags).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.title }),
                            highlightedIndex,
                            handleAddTag
                          }),
                        )}
                      </Paper>
                    </div>
                  ) : null}
                </div>
              )}
            </Downshift>
            <TagsWrapper>
              {R.is(Array, tags) && tags.map(tag => (
                <Chip
                  key={`${tag.title}_${tag.uuid}`}
                  label={tag.title}
                  onDelete={() => deleteTag(tag)}
                />
              ))}
            </TagsWrapper>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            <span>FORCE QUESTION ORDER</span>
            <Switch
              checked={forceQuestionOrder}
              onChange={toggleForceQuestionOrder}
              value="forceQuestionOrder"
            />
          </Grid>
        </Grid>
      </Content>
      <Footer>
        <Button onClick={close} color="secondary">CANCEL</Button>
        <Button onClick={save} color="secondary">SAVE SECTION</Button>
      </Footer>
    </ModalWrapper>
  </Modal>
);

SectionModal.propTypes = {
  open                     : PropTypes.bool,
  title                    : PropTypes.string,
  tags                     : PropTypes.arrayOf(PropTypes.object),
  meta                     : PropTypes.shape({
    forceQuestionOrder: PropTypes.bool,
  }),
  searchedTags             : PropTypes.array,
  setTitle                 : PropTypes.func.isRequired,
  toggleForceQuestionOrder : PropTypes.func.isRequired,
  onSave                   : PropTypes.func.isRequired,
  save                     : PropTypes.func.isRequired,
  close                    : PropTypes.func.isRequired,
  addTag                   : PropTypes.func.isRequired,
  deleteTag                : PropTypes.func.isRequired,
  resetSearchedTags        : PropTypes.func.isRequired,
  fetchTagsByStr           : PropTypes.func.isRequired,
};

const componentInitialState = {
  title        : '',
  tags         : [],
  currentTag   : '',
  searchedTags : [],
  meta         : {
    forceQuestionOrder: false,
  },
};

SectionModal.defaultProps = {
  open: false,
  ...componentInitialState,
};

const addEditing = withStateHandlers(
  ({
    title      = '',
    tags       = [],
    currentTag = '',
    meta       = { forceQuestionOrder: false },
  }) => ({ title, tags, meta, currentTag }),
  {
    setTitle                 : () => ({ target }) => ({ title: target.value }),
    setCurrentTag            : (__, { resetSearchedTags, fetchTagsByStr }) => ({ target }) => {
      R.isEmpty(target.value) ? resetSearchedTags() : fetchTagsByStr(target.value);

      return { currentTag: target.value };
    },
    handleAddTag             : ({ tags }, { resetSearchedTags }) => tag => {
      resetSearchedTags();

      return R.compose(
        R.assoc('currentTag', ''),
        R.assoc(tag, {}),
        R.assoc('tags', R.__, {}),
        R.concat(tags),
      )([tag]);
    },
    deleteTag                : ({ tags }) => tag => ({ tags: R.reject(R.whereEq(tag), tags) }),
    toggleForceQuestionOrder : ({ meta }) => () => ({ meta: R.assoc('forceQuestionOrder', !meta.forceQuestionOrder, meta) }),
    save                     : ({ title, tags, meta }, { k, onSave, onClose }) => () => {
      onSave({ uuid: k, title, tags, meta });
      onClose();
      return componentInitialState;
    },
    close                    : (_, { onClose }) => () => {
      onClose();
      return componentInitialState;
    },
  },
);

export default addEditing(SectionModal);
