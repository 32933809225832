const html2canvas = require('html2canvas'),
      jsPDF = require('jspdf');

const getMaxTopInRect = (elements, y1, y2) => {
  let maxTop = 0;
  const filteredChildren = elements.filter(e => {
    const y = e.offsetTop;
    const h = e.clientHeight;

    if (y > y1 && (y + h) < y2) {
      if((y + h) > maxTop) {
        maxTop = (y + h);
      }
      return false;
    }
    return true;
  });
  return { maxTop, filteredChildren };
};

const writePdfPages = (doc, pdf, childElements, currentPage, initTop, pageHeight, pageWidth, pageWidthPts) => {
  return new Promise ((resolve) => {
      const { maxTop, filteredChildren } = getMaxTopInRect(childElements, initTop, initTop + pageHeight);
      childElements = filteredChildren;

      const newPageHeight = maxTop - initTop;
            if(newPageHeight < 0) {
        return resolve();
      }

      html2canvas(doc, {
        useCORS                : true,
        allowTaint             : false,
        foreignObjectRendering : false,
        imageTimeout           : 0,
        backgroundColor        : '#ffffff',
        scale: 1,
        logging: true,
        y: initTop,
        height: initTop + 1500
      }).then(canvas => {
        const srcImg  = canvas,
              sX      = 0,
              sY      = 0,
              sWidth  = pageWidth,
              sHeight = newPageHeight,
              dX      = 0,
              dY      = 0,
              dWidth  = pageWidth,
              dHeight = newPageHeight;

        let onePageCanvas = document.createElement('canvas');

        onePageCanvas.setAttribute('width',  pageWidth);
        onePageCanvas.setAttribute('height', newPageHeight);

        const context = onePageCanvas.getContext('2d');
        context.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);

        const canvasDataURL = onePageCanvas.toDataURL('image/jpg', 0.9);

        const width  = onePageCanvas.width;
        const height = onePageCanvas.height;

        if (currentPage > 0) {
          pdf.addPage(pageWidthPts, newPageHeight * 0.75); // 8.5" x 11" in pts (in * 72)
        }

        const nextPage = currentPage + 1;
        pdf.setPage(nextPage);
        pdf.rect(0, 0, width, height);
        pdf.addImage(canvasDataURL, 'JPG', 0, 0, (width * .72), (height * .71));

        return writePdfPages(doc, pdf, childElements, nextPage, maxTop, pageHeight, pageWidth, pageWidthPts)
        .then(resolve);
      });
  });
};

const generatePdf = (domId, title, callback=()=>{}) => {

  const doc = document.getElementById(domId);
  window.alert('Please wait while we adjust this page for PDF rendering. It will return to normal when the file is ready');

  const pageHeight      = 1120,
        pageWidth       = 840,
        pageWidthPts    = 595;

  window.document.body.style.width=`${pageWidth}px`;

  window.document.querySelectorAll('.no-print').forEach(el => {
    el.style.visibility = 'hidden';
  });

  const pdf = new jsPDF('p', 'pt', 'a4', ['px_scaling'], '', 'SLOW');
  pdf.setFillColor(255, 255, 255);

  return writePdfPages(doc, pdf, [...doc.getElementsByTagName('*')].filter(e => e.childElementCount > 1), 0, doc.offsetTop, pageHeight, pageWidth, pageWidthPts)
    .then(() => {
      pdf.save(`${title}.pdf`);

      window.document.body.style.width=`100%`;

      window.document.querySelectorAll('.no-print').forEach(el => {
        el.style.visibility = 'visible';
      });

      callback();
    });
};


export default generatePdf;
