import * as R      from 'ramda';
import React       from 'react';
import { connect } from 'react-redux';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
  withStateHandlers,
  withHandlers
} from 'recompose';

import NotificationsTable       from '../components/NotificationsTable';
import SearchFilter            from '../../../components/SearchFilter';

import NotificationModal from '../components/modals/NotificationModal';

import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../client/ClientActions';

import {
  fetchNotifications  as _fetchNotifications,
  updateNotification  as _updateNotification,
  deleteNotification  as _deleteNotification,
  filterByAction      as _filterByAction,
  transformData       as _transformData,
  setShowSearchFilter as _setShowSearchFilter,
  clearNotifications  as _clearNotifications,

  sendNotification as _sendNotification,
  cancelNotification as _cancelNotification
} from '../NotificationsActions';

import {
  getCurrentClient,
  getCurrentProject,
  isClientUser,
  getAutoComplete
} from '../../client/ClientReducer';

import {
  getNotifications,
  getTransformedData,
  getTableColumns,
  getFilterList,
  getShowSearchFilter,
  getPrefilledFilters
} from '../NotificationsReducer';

import {
  getSavedViews
} from '../../saved-views/SavedViewsReducer';

import {
  getUserRole,
  getUserId,
  getEmail
}                               from '../../auth/AuthReducer';
import { Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';

const NAMESPACE = 'notifications';

const searchFilterStyle = {
  minHeight       : '10rem',
  margin          : '2rem 2rem 1rem 2rem',
  position        : 'relative',
  backgroundColor : '#FFF',
  boxShadow       : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
};

const NotificationsSentPage = ({
  notifications,
  clearAutocomplete,
  autoComplete,
  transformedData,
  transformedDataDict,
  tableColumns,
  filterList,
  updateNotification,
  deleteNotification,
  maybeFilterByAction,
  showSearchFilter,
  setShowSearchFilter,
  transformData,
  userRole,
  userId,
  currentClient,
  currentProject,
  savedView,
  title,
  description,
  createSavedView,
  createSavedWidget,
  createSavedRollup,
  namespace,
  userEmail,
  deleteSavedView,
  isClientUser,
  handleSelectData,
  selectedData,
  isFetchingData,
  feedbackMessage,
  showNotificationModal,
  setShowNotificationModal,
  handleNotificationAnswerChanged,
  currentNotification,
  sendNotification,
  cancelNotification,
  goToDetailsPage,
  fetchAutocomplete,
  prefilledFilters,
  syncFilters
}) => (
  <div>
    <Helmet>
      <title>Sent Notifications</title>
    </Helmet>
    <SearchFilter
      data              = { notifications }
      fetchAutocomplete = {fetchAutocomplete}
      clearAutocomplete = {clearAutocomplete}
      autoComplete      = {autoComplete}
      transformedData   = { transformedData }
      tableColumns    = { R.filter(R.prop('searchable'), tableColumns) }
      filterByAction    = { maybeFilterByAction }
      filterList        = { filterList }
      syncFilters       = { syncFilters }
      prefilledFilters  = { prefilledFilters }
      namespace         = { namespace }
      style             = { searchFilterStyle }
      userRole          = { userRole }
      userId            = { userId }
      currentClient     = { currentClient }
      currentProject    = { currentProject }
      isClientUser      = { isClientUser() }
      allowSave         = { true }
      showWhen          = { showSearchFilter }
      onSaveAsView      = { createSavedView }
      onSaveAsWidget    = { createSavedWidget }
      onSaveAsRollup    = { createSavedRollup }
    />
    <NotificationsTable
      transformedData     = { transformedData }
      transformedDataDict = { transformedDataDict }
      data                = { notifications }
      title               = { title }
      description         = { description }
      tableColumns        = { tableColumns }
      savedView           = { savedView }
      deleteSavedView     = { deleteSavedView }
      onUpdate            = { updateNotification }
      onHardDelete        = { deleteNotification }
      transformData       = { transformData }
      userRole            = { userRole }
      filterList          = { filterList }
      goToReviewPage      = { goToDetailsPage }
      setShowSearchFilter = { setShowSearchFilter }
      showSearchFilter    = { showSearchFilter }
      userEmail           = { userEmail }
      isClientUser        = { isClientUser() }
      handleSelectData    = { handleSelectData }
      namespace           = { namespace }
    />

    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
      <DialogTitle>{feedbackMessage}</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>

    {
      showNotificationModal && (
        <NotificationModal
          onSend                            ={(id) => sendNotification(namespace, id)}
          onCancel                          ={() => setShowNotificationModal(false)}
          onCancelNotification              ={(id) => cancelNotification(namespace, id)}
          notification                      ={currentNotification}
          onHandleNotificationAnswerChanged ={handleNotificationAnswerChanged}
          selectedData                      = { selectedData }
        />
      )
    }
  </div>
);

const maybeLoadInitialFilterListFromRouterProps = (state, props) => {

  let storedFilters = {};
  try {
    storedFilters = R.compose(
      maybeAppendDecorators,
      appendFilterType,
      JSON.parse
    )(window.localStorage.getItem(`persistedFilters:${props.namespace}`));
  } catch (e) {
    storedFilters = {};
  }

  return R.compose(
    maybeAppendDecorators,
    appendFilterType,
    R.when(R.isEmpty, R.always(props.initialFilterList || {})),
    R.mergeDeepLeft(storedFilters),
    getFilterList(props.namespace)
  )(state);
};

const maybeDispatchFetchNotifications = (dispatch, namespace, filters) => {
  const activeFilters = R.filter(R.compose(R.not, R.isEmpty, R.prop('data')))(filters);
  if(Object.keys(activeFilters).length) {
    return dispatch(_fetchNotifications(namespace, filters));
  }
  dispatch(_clearNotifications(namespace));
  return Promise.resolve();
};

function _goToDetailsPage(props, id) {
  return (dispatch, getState) => {
    const state = getState();
    const ids = R.compose(R.map(d => { return { id: d.id }; } ), R.pathOr([], [NAMESPACE, props.namespace, 'transformedData']))(state);
    props.history.push(`/notification/id/${id}`, { ids });
  };
}

const mapStateToProps = (state, props) => ({
  notifications       : getNotifications(state, props.namespace || NAMESPACE),
  transformedData     : getTransformedData(state, props.namespace || NAMESPACE),
  transformedDataDict : R.indexBy(R.prop('id'),  getTransformedData(state, props.namespace || NAMESPACE) || []),
  tableColumns        : getTableColumns('notifications', props.namespace || NAMESPACE)(state),
  userRole            : getUserRole(state),
  userId              : getUserId(state),
  userEmail           : getEmail(state),
  currentClient       : getCurrentClient(state),
  currentProject      : getCurrentProject(state),
  showSearchFilter    : getShowSearchFilter(state),
  savedViews          : getSavedViews(state),
  filterList          : maybeLoadInitialFilterListFromRouterProps(state, props),
  autoComplete        : getAutoComplete(state),
  isClientUser,
  prefilledFilters    : getPrefilledFilters()
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  fetchNotifications  : (namespace, filters) => dispatch(_fetchNotifications(namespace, filters)),
  updateNotification  : (namespace, notificationId, payload) => dispatch(_updateNotification(namespace, notificationId, payload)),
  deleteNotification  : id => dispatch(_deleteNotification(id)),
  goToDetailsPage     : id => dispatch(_goToDetailsPage(props, id)),
  persistFilters      : (namespace, filters) => dispatch(_filterByAction(namespace, filters)),
  setShowSearchFilter : bool => dispatch(_setShowSearchFilter(bool)),
  transformData       : (orderBy, order, sortType, filterList, data, namespace) => dispatch(_transformData(orderBy, order, sortType, filterList, data, namespace)),
  fetchAutocomplete   : payload => dispatch(_fetchAutocomplete(payload, 'membership/notification')),
  clearAutocomplete   : () => dispatch(_clearAutocomplete())
});

const maybeAppendDecorators = R.map((filter={}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'notifications', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
});

const appendFilterType = R.map((filter={}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'notifications', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const withNotifications = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
      selectedData = [],
      isFetchingData = false,
      feedbackMessage = ""
    }) => ({
      selectedData,
      isFetchingData,
      feedbackMessage
    }),
    ({
      handleSelectData: (__, { transformedData }) => selectedData => {
        if (selectedData.length > 0) {
          return {
            selectedData: R.compose(
              R.filter(d => R.contains(d.id, selectedData))
            )(transformedData)
          };
        }

        return { selectedData };
      },
      setFetchingData : () => (fetching, message) => ({
        isFetchingData: fetching,
        feedbackMessage: R.defaultTo("", message)
      }),
      setShowNotificationModal: (__, { transformedData }) => (show, id) => ({
        showNotificationModal: show,
        currentNotification: show ? R.find(R.propEq('id', id), transformedData) : null
      }),
      handleNotificationAnswerChanged: () => e => ({
        notificationValue: e.target.value
      })
    }),
  ),
  withHandlers({
    filterByAction : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true, 'Getting notifications'))
      .then(() => props.dispatch(_fetchNotifications(namespace, filters)))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    maybeFilterByAction : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true, 'Getting notifications'))
      .then(() => maybeDispatchFetchNotifications(props.dispatch, namespace, filters))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    sendNotification  : props => (namespace, id) => {
      return Promise.resolve(props.setShowNotificationModal(false))
      .then(() => props.setFetchingData(true, 'Sending notification'))
      .then(() => props.dispatch(_sendNotification(namespace, id)))
      .then(() => props.setShowNotificationModal(false))
      .then(() => props.setFetchingData(false));
    },
    cancelNotification  : props => (namespace, id) => {
      return Promise.resolve(props.setShowNotificationModal(false))
      .then(() => props.setFetchingData(true, 'Cancelin notification'))
      .then(() => props.dispatch(_cancelNotification(namespace, id)))
      .then(() => props.setShowNotificationModal(false))
      .then(() => props.setFetchingData(false));
    },
    syncFilters : props => (namespace, filters) =>
      Promise.resolve()
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
  }),
  defaultProps({
    notifications             : [],
    savedView                 : {},
    savedViews                : [],
    transformedData           : [],
    tableColumns              : [],
    showSearchFilter          : false,
    initialFilterList         : {},
    namespace                 : NAMESPACE,
    isClientUser              : () => {},
    showNotificationModal     : false,
    notificationValue         : -1
  }),
  setPropTypes({
    notifications                 : PropTypes.array,
    savedViews                    : PropTypes.array,
    savedView                     : PropTypes.object,
    transformedData               : PropTypes.array,
    title                         : PropTypes.string,
    description                   : PropTypes.string,
    tableColumns                  : PropTypes.array,
    updateNotification            : PropTypes.func.isRequired,
    deleteNotification            : PropTypes.func.isRequired,
    createSavedView               : PropTypes.func,
    createSavedWidget             : PropTypes.func,
    createSavedRollup             : PropTypes.func,
    showSearchFiler               : PropTypes.bool,
    setShowSearchFilter           : PropTypes.func,
    filterList                    : PropTypes.object.isRequired,
    initialFilterList             : PropTypes.object,
    userRole                      : PropTypes.number.isRequired,
    userId                        : PropTypes.number.isRequired,
    isClientUser                  : PropTypes.func,
    showNotificationModal         : PropTypes.bool,
    notificationValue             : PropTypes.number
  }),
  lifecycle({
    componentDidMount() {
      this.props.setShowSearchFilter(true);
    },
    componentDidUpdate(prevProps) {
      const savedViewUpdated         = !R.equals(prevProps.savedView,         this.props.savedView),
            filterListUpdated        = !R.equals(prevProps.filterList,        this.props.filterList),
            initialFilterListUpdated = !R.equals(prevProps.initialFilterList, this.props.initialFilterList);

      const filters = this.props.filterList || {};

      if (savedViewUpdated || initialFilterListUpdated) {
        this.props.maybeFilterByAction(this.props.namespace, filters);
      }

      else if (filterListUpdated) {
        this.props.persistFilters(this.props.namespace, filters);
      }
    }
  })
);

export default withNotifications(NotificationsSentPage);
