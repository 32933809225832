import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import * as R               from 'ramda';

import { withStyles }       from '@material-ui/core/styles';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  TablePagination
} from '@material-ui/core';

import { Footer, styles }   from '../styled-components/QuestionTable';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead    from './EnhancedTableHead';

import ChangesOnSvrModal from '../../../modals/ChangesOnSvrModal';

import { ITEMS } from '../../types';
import theme from '../../../../styles/theme';

const HEIGHT   = 30;
const COMMON_QUESTION_DELETE  = 0,
      COMMON_QUESTION_ACTIVE  = 1,
      COMMON_QUESTION_ARCHIVE = 2;
const STATUSES = ['Deleted', 'Active', 'Archived'];

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order              : 'asc',
      orderBy            : 'title',
      selected           : [],
      page               : 0,
      rowsPerPage        : props.rowsPerPage,
      rowsPerPageOptions : props.rowsPerPageOptions,
      data               : R.sort(R.ascend(R.prop('title')), props.data),
      filterByStatus     : 1,
      showModal          : false,
      changeApprove      : null
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.data !== this.props.data) {
      this.setState({
        data : this.props.data
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order     = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data = R.sort(R[`${order}end`](R.prop(orderBy)), this.state.data);
    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const filteredByStatusData = () => {
        if (this.state.filterByStatus) {
          return this.props.data.filter(d => d.status === this.state.filterByStatus);
        }

        return this.props.data;
      };

      this.setState({ selected : R.pluck('id', filteredByStatusData()) });
      return;
    }
    this.setState({ selected : [] });
  };

  handleClick = (event, id) => {
    event.stopPropagation();

    const { selected }   = this.state;
    const selectedIndex  = selected.indexOf(id);
    const firstItemIndex = 0;
    const lastItemIndex  = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1),             () => [].concat(selected, id)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex),  () => [].concat(selected.slice(0, -1))],
        [R.lt(0),                  () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage : event.target.value });
  };

  isSelected = id => R.contains(id, this.state.selected);

  deleteQuestions = () => {
    this.updateCommonQuestion(COMMON_QUESTION_DELETE);
  };

  activeQuestions = () => {
    this.updateCommonQuestion(COMMON_QUESTION_ACTIVE);
  };

  archiveQuestions = () => {
    this.updateCommonQuestion(COMMON_QUESTION_ARCHIVE);
  };

  handleEditQuestion = event => {
   const identifier     = event.currentTarget.getAttribute('value');
   const editedQuestion = R.find(R.propEq('canonicalKey', identifier))(this.props.data);

   this.props.isEditingAction(true, editedQuestion);
  };

  updateCommonQuestion = status => {
    const { onUpdate, data } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(rollUp => {
          data.forEach(item => {
            if (R.equals(rollUp, item.id)) {
              if (!R.equals(item.status, status)) {
                onUpdate(item.id, { status: parseInt(status, 10) });
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected : [] });
      };

      this.setState({
        showModal     : true,
        changeApprove : change,
      });
    }
  };

  modalClose = () => {
    this.setState({
      showModal     : false,
      changeApprove : null,
    });
  };

  onFilterByStatus = filterByStatus => {
    this.setState({ filterByStatus, selected: [] });
  };

  render() {
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      rowsPerPageOptions,
      filterByStatus,
      showModal,
      changeApprove
    }                       = this.state;
    const { classes, data } = this.props;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const filteredByStatusData = () => {
      if (filterByStatus) {
        return data.filter(d => d.status === filterByStatus).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }

      return data.filter(d => d.status !== COMMON_QUESTION_DELETE).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedIds={selected}
          onFilterByStatus={this.onFilterByStatus}
        />
        <div className={classes.tableWrapper}>
          {showModal &&
            <ChangesOnSvrModal
              onApprove = {changeApprove}
              onCancel  = {this.modalClose}
              items     = {selected.length}
            />
          }
          <Table className={classes.table}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={filteredByStatusData().length}
            />
            <TableBody>
              {filteredByStatusData().map((n, i) => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={this.handleEditQuestion}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={i}
                    value={n.canonicalKey}
                    selected={isSelected}
                    style={{ height : `${HEIGHT}px`, whiteSpace: 'nowrap', cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        style={{ height : `${HEIGHT}px` }}
                        onClick = {event => this.handleClick(event, n.id)}
                        checked={isSelected}
                      />
                    </TableCell>
                    <TableCell>{n.title}</TableCell>
                    <TableCell>{ITEMS[n.type]}</TableCell>
                    <TableCell>{n.status ? STATUSES[n.status] : STATUSES[1]}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height : `${HEIGHT * (emptyRows > 5 ? 5 : emptyRows)}px` }}>
                  <TableCell colSpan={6} style={{ height : `${HEIGHT}px` }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan             = {1}
                  count               = {filteredByStatusData().length}
                  style               = {{ height : `${HEIGHT}px` }}
                  rowsPerPage         = {rowsPerPage}
                  rowsPerPageOptions  = {rowsPerPageOptions}
                  page                = {page}
                  onChangePage        = {this.handleChangePage}
                  onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                  backIconButtonProps = {{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps = {{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        <Footer isSelected={selected.length > 0}>
          <div style={{ color: theme.palette.pblack.black }} onClick={this.archiveQuestions}>ARCHIVE</div>
          <div style={{ color: theme.palette.pblack.black }} onClick={this.deleteQuestions}>DELETE</div>
          <div style={{ color: theme.palette.pblack.black }} onClick={this.activeQuestions}>ACTIVATE</div>
        </Footer>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data        : PropTypes.arrayOf(PropTypes.shape({
    id        : PropTypes.number,
    title     : PropTypes.string,
    client    : PropTypes.string,
    status    : PropTypes.number,
    timestamp : PropTypes.string
  })),
  rowsPerPage        : PropTypes.number,
  classes            : PropTypes.object.isRequired,
  rowsPerPageOptions : PropTypes.array.isRequired
};

EnhancedTable.defaultProps = {
  data               : [],
  rowsPerPage        : 50,
  rowsPerPageOptions : [25, 50, 100, 250]
};

export default withStyles(styles)(EnhancedTable);
