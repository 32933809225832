import React  from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display             : ${props => props.show ? 'flex' : 'none'};
  align-items         : center;
  position            : fixed;
  bottom              : 10px;
  right               : 40px;
  padding-left        : 20px;
  height              : 40px;
  width               : 230px;
  background          : ${props => props.hasError ? '#860000' : '#333'};
  opacity             : 1;
  color               : white;
  font-size           : 14px;
  border-radius       : 4px;
  font-weight         : 200;
  box-shadow          : 2px 2px 3px #888888;
  animation-name      : slideIn;
  animation-duration  : 2s;
  @keyframes slideIn {
    0% {
      bottom  : -50px;
      opacity : 1
    }

    30%{
      bottom : 10px;
    }

    70%{
      opacity : 1;
    }

    100% {
     opacity : .01;
    }
  }
`;

const SavedNotificationToast = ({ show, hasError, message }) => (
  <div>
    <Wrapper show={show} hasError={hasError}>
     {message}
    </Wrapper>
  </div>
);

export default SavedNotificationToast;
