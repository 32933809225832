import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Button,
  Tooltip,
  Hidden
} from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ExportColumns from '../../export-columns/components/ExportColumns';
import { additionalcolumns } from '../../export-columns/components/Additonalcolumnsdata';

const Toolbar = styled.div`
  display         : flex;
  justify-content : space-between;
  margin-top      : 30px;
  margin-bottom   : 10px;
  padding         : 0 30px;
`;

const BackButtonWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  cursor         : pointer;
`;

const BackButtonTitle = styled.p`
  margin-left : 15px;
  font-size   : 15px;
  font-weight : 500;
  color       : #CE343E;
`;

const INITIAL_STATE = {
  anchorEl: null,
  isExecelExportOpen: false
};

class EditSavedRollupPageToolbar extends Component {

  state = INITIAL_STATE;

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget, isExecelExportOpen: true });   
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState(INITIAL_STATE);
  };

  render() {

    const { isExecelExportOpen } = this.state;
    const { goBack, selectedData, tableColumns, questions, title, onExport, photos, embedPhotosInExcelReport } = this.props;
    const client = this.props.isClientUser; 

    const allQuestions = [];

    tableColumns.forEach(question => {
      allQuestions.push(question.label);
    });

    questions.forEach(question => {
      allQuestions.push(question.title);
    });

    additionalcolumns.forEach(item => {
      allQuestions.push(item);
    }
    );    

    return (
      <Toolbar>
        <BackButtonWrapper onClick={goBack}>
          <ArrowBackIcon />
          <BackButtonTitle>BACK TO ALL ROLL-UPS</BackButtonTitle>
        </BackButtonWrapper>
        <Hidden only={['xs', 'sm']}>
          <Tooltip
            title={!selectedData.length ? 'Please first select rows to export.' : ''}
            placement="top-end"
            enterDelay={300}
          >            
            <div style={isExecelExportOpen === false ? {display : 'block'} : {display : 'none'}} >
              <Button
                onClick={this.handleClick}                
                disabled={!selectedData.length}
                style={{
                  border: '2px solid #DE898C',
                  color: '#F3212E',
                  backgroundColor: 'transparent',
                  borderRadius: '5px',
                  padding: '8px',
                  minWidth: '120px',
                  letterSpacing: '1px',
                }}
              >
                Export
              </Button>
            </div>
          </Tooltip>
        </Hidden>
        {
          isExecelExportOpen ? (           
            <ExportColumns
              open={true}
              onClose={false}
              onCancel={this.handleClose}
              onConfirm={e => { onExport('excel'); this.handleClose(e); }}
              dialogTitle="Export Columns"
              isClientUser={client}
              tableColumns={tableColumns}
              questions={questions}
              title={title}
              allQuestions={allQuestions}
              photos={photos}
              embedPhotosInExcelReport={ embedPhotosInExcelReport }
            />           
          ) : null
        }

      </Toolbar>
    );
  }
}

export default EditSavedRollupPageToolbar;
