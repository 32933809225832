export const CLEAR_CURRENT_DATA = `ALERTS/CLEAR_CURRENT_DATA`;
export const CLEAR_ERROR        = `ALERTS/CLEAR_ERROR`;
export const CREATE             = `ALERTS/CREATE`;
export const DELETE             = `ALERTS/DELETE`;
export const EDIT               = `ALERTS/EDIT`;
export const FETCH              = `ALERTS/FETCH`;
export const FETCH_ALL          = `ALERTS/FETCH_ALL`;
export const FILTER_BY_ACTION   = `ALERTS/FILTER_BY_ACTION`;
export const IS_EDITING         = `ALERTS/IS_EDITING`;
export const SHOW_SEARCH_FILTER = `ALERTS/SHOW_SEARCH_FILTER`;
export const TRANSFORM_DATA     = `ALERTS/TRANSFORM_DATA`;
export const UPDATE             = `ALERTS/UPDATE`;

export const fetchAlerts = () => ({
  type    : FETCH_ALL,
  payload : {
    request : {
      method : 'GET',
      url    : '/api/v2/alerts',
    }
  }
});

export const fetchAlert = (id) => ({
  type    : FETCH,
  payload : {
    request : {
      method : 'GET',
      url    : `/api/v2/alerts/id/${id}`
    }
  }
});

export const saveAlert = setting => {
  return createAlert({ ...setting });
};

export const createAlert = data => ({
  type    : CREATE,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/alerts',
      data
    }
  }
});

export const saveEditedsaveAlert = (id, alert) => {
  return updateAlert(id, alert);
};

export const updateAlert = (id, alert) => ({
  type    : UPDATE,
  payload : {
    request : {
      method : 'PUT',
      url    : `/api/v2/alerts/id/${id}`,
      data   :  alert
    }
  }
});

export const deleteAlert = id => ({
  type    : DELETE,
  payload : {
    request : {
      method : 'DELETE',
      url    : `/api/v2/alerts/id/${id}`,
    }
  }
});

export const setShowSearchFilter = bool => ({
  type    : SHOW_SEARCH_FILTER,
  payload : bool
});

export const filterByAction = (filters, page) => ({
  type    : FILTER_BY_ACTION,
  payload : { filters, page }
});

export const transformData = (orderBy, order, sortType, filterList, data) => ({
  type    : TRANSFORM_DATA,
  payload : { orderBy, order, sortType, filterList, data }
});

export const clearError = () => {
  return {
    type : CLEAR_ERROR,
  };
};

export const isEditingAction = ( bool, namespace, settingObject ) => {
  return {
    type : IS_EDITING,
    payload: {
      status              : bool,
      settingNamespace    : namespace,
      settingBeingEdited  : settingObject
    }
  };
};

export const clearAlerts = () => ({
  type    : CLEAR_CURRENT_DATA,
  payload : {}
});
