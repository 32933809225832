import React       from 'react';
import PropTypes   from 'prop-types';
import styled      from 'styled-components';

const Wrapper = styled.div`
  border     : 2px solid lightgray;
  padding    : 8px 15px;
  min-height : 220px;
  margin     : 2rem 0 0 0;
`;

const Title = styled.p`
  margin-bottom: 5px;
`;

const SectionGroup = ({ title, children, innerRef }) => (
  <div ref={innerRef}>
    <Title>{title}</Title>
    <Wrapper>
      {children}
    </Wrapper>
  </div>
);

SectionGroup.propTypes = {
  title    : PropTypes.string,
  children : PropTypes.node.isRequired,
};

SectionGroup.defaultProps = {
  title: '',
};

export default SectionGroup;
