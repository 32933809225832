import React     from 'react';
import styled    from 'styled-components';

const Toolbar = styled.div`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 50px;
  margin-bottom   : 10px;
  padding         : 0 30px;
`;

const ButtonWrapper = styled.button`
  padding       : 10px 20px;
  background    : transparent;
  color         : #c2403d;
  border        : 2px solid #d58c8d;
  border-radius : 5px;
  font-size     : 13px;
  font-weight   : 500;
  cursor        : pointer;
  transition    : .3s all;
  margin-left   : 20px;
  
  &:hover,
  &:active {
    color        : #e4322e;
    border-color : #e86062;
  }
`;
const ExitButton = ({ onClick }) => <ButtonWrapper  onClick={onClick}>EXIT</ButtonWrapper>;

const SvrPreviewToolbar = ({ onExit }) => (
  <Toolbar>
    <ExitButton onClick={onExit} />
  </Toolbar>
);

export default SvrPreviewToolbar;
