import React, { Component } from 'react';
import NavigationPrompt     from 'react-router-navigation-prompt';
import { connect }          from 'react-redux';
import * as R               from 'ramda';
import moment               from 'moment';

import { isDraftAction } from '../BuilderActions';

import styled            from 'styled-components';
import { Button, Modal } from '@material-ui/core';

const ModalWrapper = styled.div`
  position         : absolute;
  width            : 600px;
  height           : 140px;
  margin           : 15px;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  background-color : #fff;
  border-radius    : 3px;
  outline          : none;
`;

const Footer = styled.div`
  display                    : flex;
  justify-content            : space-between;
  border-bottom-left-radius  : 3px;
  border-bottom-right-radius : 3px;
  width                      : 100%;
  background                 : #f8f8f8;
`;

const Content = styled.div`
  display         : flex;
  height          : 80%;
  justify-content : center;
  align-items     : center;
`;

const LastSavedWrapper = styled.div`
  position: relative;
`;

const LastSavedContainer = styled.div`
  position: absolute;
  right: 0px;

  p {
    font-style    : italic;
    margin-bottom : 0;
    color         : rgba(0, 0, 0, 0.54);
    font-size     : .75rem;
  }
`;

class LeavingPageModal extends Component {
  state = {
    svrFormOnMount    : null,
    lastSavedSvr      : null,
    couldNotLeavePage : false
  };

  componentDidUpdate(){

    if (this.state.svrFormOnMount === null){
      this.setState({svrFormOnMount: this.props.svrForm});
    }

    if(R.equals(this.props.svrForm, this.state.svrFormOnMount)){
      this.props.isDraftAction(false);
    }

    if(this.state.svrFormOnMount !== null && !R.equals(this.props.svrForm, this.state.svrFormOnMount)){
      const setItem = R.assoc('timestamp', moment())(this.props.svrForm);
      this.props.isDraftAction(true);

      localStorage.setItem(this.props.svrForm.id, JSON.stringify(setItem));

    }

    if(this.props.lastSavedSvr){
      const makeObj = R.compose(R.pick(['title', 'content']), R.defaultTo({}));
      const pluckedlastSaved = makeObj(this.props.lastSavedSvr);
      const pluckedSvrForm  = makeObj(this.props.svrForm);

      this.props.isDraftAction(false);

      if(R.equals(pluckedlastSaved, pluckedSvrForm)){
        this.props.isDraftAction(false);
      }
    }
  }

  componentWillUnmount(){
    this.props.isDraftAction(false);
  }

  render () {
    return (
      <div>
        <div style={this.props.isDraft ? {position: 'fixed', top: '140px', right: '40px'} : {display: 'none'}}>
          <h1>Draft</h1>
        </div>
        {!R.isNil(this.props.lastSavedSvr) && <LastSavedWrapper>
          <LastSavedContainer>
            <p>
              {`Draft Saved: ${moment(this.props.lastSavedSvr.timestamp).format("MM/DD/YYYY h:mma z")}`}
            </p>
          </LastSavedContainer>
        </LastSavedWrapper>}
        <NavigationPrompt when={this.props.isDraft}>
          {({onConfirm, onCancel}) => (
            <Modal open={true}>
                <ModalWrapper>
                  <Content>
                    <p>You have unsaved changes to your SVR. Are you sure you want to exit?</p>
                  </Content>
                  <Footer>
                    <Button onClick={onCancel} color="secondary">CANCEL</Button>
                    <Button color="secondary" onClick={onConfirm}>YES</Button>
                  </Footer>
                </ModalWrapper>
              </Modal>
          )}
        </NavigationPrompt>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    svrForm      : state.builder.svrForm,
    lastSavedSvr : state.builder.lastSavedSvr,
    isDraft      : state.builder.isDraft
  };
}

export default connect(mapStateToProps, {isDraftAction})(LeavingPageModal);
