import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import * as R from 'ramda';
import moment from 'moment';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { lighten }    from '@material-ui/core/styles/colorManipulator';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';

import ChangesOnSvrModal from '../../modals/ChangesOnSvrModal';

import SortableTableByState from '../../../components/SortableTableByState';
import theme from '../../../styles/theme';

const HEIGHT                 = 30;
const ROLL_UP_STATUSES       = [null, 'Active', 'Archived'];
const ROLL_UP_DELETED_STATUS = 0;

const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-start;
  padding-left    : 25px;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 7px;

  & > div {
    margin-right : 20px;
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'}
    cursor       : pointer;
  }
`;

const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox" width={130}>
            <Checkbox
              indeterminate = {numSelected > 0 && numSelected < rowCount}
              checked       = {numSelected === rowCount}
              onChange      = {onSelectAllClick}
              style         = {{ height: `${HEIGHT}px` }}
            />
          </TableCell>
          <TableCell
            numeric       = {false}
            padding       = "default"
            sortDirection = {orderBy === 'title' ? order : false}
            width         = {200}
          >
            <Tooltip
              title      = "Sort"
              placement  = "bottom-end"
              enterDelay ={300}
            >
              <TableSortLabel
                active    = {orderBy === 'title'}
                direction = {order}
                onClick   = {this.createSortHandler('title')}
              >
                Title
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric       = {false}
            padding       = "default"
            sortDirection = {orderBy === 'created' ? order : false}
            width         = {120}
          >
            <Tooltip
              title      = "Sort"
              placement  = "bottom-end"
              enterDelay ={300}
            >
              <TableSortLabel
                active    = {orderBy === 'created'}
                direction = {order}
                onClick   = {this.createSortHandler('created')}
              >
                Date Created
              </TableSortLabel>
            </Tooltip>
          </TableCell>

          <TableCell
            numeric       = {false}
            padding       = "default"
            sortDirection = {orderBy === 'state' ? order : false}
          >
            <Tooltip
              title      = "Sort"
              placement  = "bottom-end"
              enterDelay ={300}
            >
              <TableSortLabel
                active    = {orderBy === 'state'}
                direction = {order}
                onClick   = {this.createSortHandler('state')}
              >
                State
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected      : PropTypes.number.isRequired,
  onRequestSort    : PropTypes.func.isRequired,
  onSelectAllClick : PropTypes.func.isRequired,
  order            : PropTypes.string.isRequired,
  orderBy          : PropTypes.string.isRequired,
  rowCount         : PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight : theme.spacing.unit,
    minHeight    : '53px',
    padding      : '0px 8px 25px 15px'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color           : theme.palette.secondary.main,
          backgroundColor : lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color           : theme.palette.text.primary,
          backgroundColor : theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    width          : '100%',
    display        : 'flex',
    justifyContent : 'space-between'
  },
  typography: {
    fontWeight    : 400,
    fontSize      : '22px',
    letterSpacing : '1px',
  }
});

let EnhancedTableToolbar = props => {

  const { numSelected, classes, onFilterByStatus } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            variant="title"
            className={classes.typography}
          >
            Roll-up Reports
          </Typography>
        )}
          <SortableTableByState onFilterByStatus={onFilterByStatus} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes     : PropTypes.object.isRequired,
  numSelected : PropTypes.number.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root         : {
    width        : '96%',
    marginTop    : '12px',
    marginLeft   : 'auto',
    marginRight  : 'auto',
    marginBottom : '80px'
  },
  table        : {
    minWidth : 800
  },
  tableWrapper : {
    overflowX : 'auto'
  }
});

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order              : 'asc',
      orderBy            : '',
      selected           : [],
      page               : 0,
      rowsPerPage        : props.rowsPerPage,
      rowsPerPageOptions : props.rowsPerPageOptions,
      filterByStatus     : 1,
      showModal          : false,
      changeApprove      : null
    };
  }

  handleRequestSort = (event, pathStr, sortType) => {
    const orderBy = pathStr || '';

    let order = 'desc';

    if (this.state.orderBy === pathStr && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, sortType });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      const filteredByStatusData = () => {
        if (this.state.filterByStatus) {
          return this.props.data.filter(d => d.status === this.state.filterByStatus);
        }

        return this.props.data;
      };

      this.setState({ selected : R.pluck('id', filteredByStatusData()) });
      return;
    }
    this.setState({ selected : [] });
  };

  handleClick = (event, id) => {
    event.stopPropagation();

    const { selected }   = this.state;
    const selectedIndex  = selected.indexOf(id);
    const firstItemIndex = 0;
    const lastItemIndex  = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1),             () => [].concat(selected, id)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex),  () => [].concat(selected.slice(0, -1))],
        [R.lt(0),                  () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => R.contains(id, this.state.selected);

  getStatusCode = label => {
    return R.compose(
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.storeVisit);
  };

  deleteRollUps = () => {
    this.updateSavedRollUp(this.getStatusCode('Deleted'));
  };

  activeRollUps = () => {
    this.updateSavedRollUp(this.getStatusCode('Active'));
  };

  archiveRollUps = () => {
    this.updateSavedRollUp(this.getStatusCode('Archived'));
  };

  updateSavedRollUp = status => {
    const { onUpdate, data } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(rollUp => {
          data.forEach(item => {
            if (R.equals(rollUp, item.id)) {
              if (!R.equals(item.status, status)) {
                onUpdate(item.id, { status: parseInt(status, 10) });
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected : [] });
      };

      this.setState({
        showModal     : true,
        changeApprove : change,
      });
    }
  };
  onFilterByStatus = filterByStatus => {
    this.setState({ filterByStatus });
  };
  modalClose = () => {
    this.setState({
      showModal     : false,
      changeApprove : null,
    });
  };

  render() {
    const { classes, goToEditPage, isClientUser } = this.props;
    const data = R.sort(R.descend(R.prop('created')))(this.props.data);
    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      rowsPerPageOptions,
      filterByStatus,
      showModal,
      changeApprove
    } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    const filteredByStatusData = () => {
      if (filterByStatus) {
        return data.filter(d => d.status === filterByStatus).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }

      return data.filter(d => d.status !== ROLL_UP_DELETED_STATUS).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected      = {selected.length}
          selectedIds      = {selected}
          onDelete         = {this.deleteRollUps}
          onFilterByStatus = {this.onFilterByStatus}
        />
        <div className={classes.tableWrapper}>
          {showModal &&
            <ChangesOnSvrModal
              onApprove = {changeApprove}
              onCancel  = {this.modalClose}
              items     = {selected.length}
            />
          }
          <Table className={classes.table}>
            <EnhancedTableHead
              numSelected      = {selected.length}
              order            = {order}
              orderBy          = {orderBy}
              onSelectAllClick = {this.handleSelectAllClick}
              onRequestSort    = {this.handleRequestSort}
              rowCount         = {filteredByStatusData().length}
            />
            <TableBody>
              {filteredByStatusData().map(n => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick      = {() => goToEditPage(n.id)}
                    role         = "checkbox"
                    aria-checked = {isSelected}
                    tabIndex     = {-1}
                    key          = {n.id}
                    selected     = {isSelected}
                    style        = {{ height: `${HEIGHT}px`, cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick = {event => this.handleClick(event, n.id)}
                        style   = {{ height: `${HEIGHT}px` }}
                        checked = {isSelected}
                      />
                    </TableCell>
                    <TableCell>{n.title}</TableCell>
                    <TableCell>{moment(n.created).format('MM/DD/YYYY')}</TableCell>
                    <TableCell>{ROLL_UP_STATUSES[n.status]}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height : HEIGHT * (emptyRows > 5 ? 5 : emptyRows) }}>
                  <TableCell colSpan={12} style={{ height : `${HEIGHT}px` }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan             = {1}
                  count               = {data.length}
                  style               = {{ height : `${HEIGHT}px` }}
                  rowsPerPage         = {rowsPerPage}
                  rowsPerPageOptions  = {rowsPerPageOptions}
                  page                = {page}
                  onChangePage        = {this.handleChangePage}
                  onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                  backIconButtonProps = {{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps = {{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        {
          !isClientUser ? (
            <Footer isSelected={selected.length > 0}>
              <div style={{ color: theme.palette.pblack.black }} onClick={this.archiveRollUps}>ARCHIVE</div>
              <div style={{ color: theme.palette.pblack.black }} onClick={this.deleteRollUps}>DELETE</div>
              <div style={{ color: theme.palette.pblack.black }} onClick={this.activeRollUps}>ACTIVATE</div>
            </Footer>
          ) : (
            <Footer isSelected={selected.length > 0}>              
              <div style={{ color: theme.palette.pblack.black }} onClick={this.deleteRollUps}>DELETE</div>            
            </Footer>
          )
        }
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data        : PropTypes.array,
  rowsPerPage : PropTypes.number,
  classes     : PropTypes.object.isRequired,
};

EnhancedTable.defaultProps = {
  data               : [],
  rowsPerPage        : 50,
  rowsPerPageOptions : [25, 50, 100, 250]
};

export default withStyles(styles)(EnhancedTable);
