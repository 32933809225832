import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import * as R               from 'ramda';
import styled               from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { lighten }    from '@material-ui/core/styles/colorManipulator';
import {
  Checkbox,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import SortableTableByState from '../../../components/SortableTableByState';

import ChangesOnSvrModal       from './../../modals/ChangesOnSvrModal';
import ImpossibleEditSvrsModal from './../../modals/ImpossibleEditSvrsModal';
import { maybeDecorateColumn } from '../../../utils/tableColumns';
import Modal from '../../../components/Modal/Modal';

const HEIGHT                       = 25;
const SVR_RESPONSE_APPROVED_STATUS = 3;

const DeleteIcon = styled(Delete)`
  margin-right : 20px;
  cursor       : pointer;
`;

const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

const isSortedBy  = (k, orderBy) => orderBy === k;
const maybeSortBy = (k, orderBy, order) => isSortedBy(k, orderBy) ? order : false;

class EnhancedTableHead extends Component {
  createSortHandler = (pathStr, type) => event => {
    this.props.onRequestSort(event, pathStr, type);
  };

  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      tableColumns
    } = this.props;

    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate = {numSelected > 0 && numSelected < rowCount}
              checked       = {numSelected === rowCount}
              onChange      = {onSelectAllClick}
              style         = {{ height: `${HEIGHT}px` }}
            />
          </TableCell>
          {
            R.map(({ pathStr, label, type, hideColumn }) =>
              hideColumn 
                ? null
                : <TableCell
                    key           = {pathStr}
                    numeric       = {false}
                    padding       = "dense"
                    type          = "head"
                    style         = {{ whiteSpace : 'nowrap', flexDirection : 'row' }}
                    sortDirection = {maybeSortBy(pathStr, orderBy, order)}
                  >
                    <Tooltip
                      title      = "Sort"
                      placement  = "bottom-end"
                      enterDelay = {300}
                    >
                      <TableSortLabel
                        active    = {isSortedBy(pathStr, orderBy)}
                        direction = {order}
                        onClick   = {this.createSortHandler(pathStr, type)}
                      >
                        { label }
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
            )(tableColumns)
          }

        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected      : PropTypes.number.isRequired,
  onRequestSort    : PropTypes.func.isRequired,
  onSelectAllClick : PropTypes.func.isRequired,
  order            : PropTypes.string.isRequired,
  orderBy          : PropTypes.string.isRequired,
  rowCount         : PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root       : {
    paddingRight : theme.spacing.unit,
    minHeight    : '53px',
    padding      : '0px 8px 25px 15px'
  },
  highlight  :
    theme.palette.type === 'light'
      ? {
        color           : theme.palette.secondary.main,
        backgroundColor : lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color           : theme.palette.text.primary,
        backgroundColor : theme.palette.secondary.dark
      },
  spacer     : {
    flex : '1 1 100%'
  },
  actions    : {
    color : theme.palette.text.secondary
  },
  title      : {
    flex : '0 0 auto'
  },
  headerIcon : {
    textAlign : 'right',
    width     : '100%'
  }
});

let EnhancedTableToolbar = props => {
  const {
          numSelected,
          classes,
          title,
          description,
          savedView,
          deleteSavedView
        } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <div>
            <Typography variant="title">
              { title || 'SVR Responses' }
            </Typography>
            {
              description && (
                <Typography color="inherit" variant="subheading">
                  { description }
                </Typography>
              )
            }
          </div>
        )}
      </div>
      <div className={classes.headerIcon}>
        <SortableTableByState/>
        {
          !R.isEmpty(savedView) && (
            <Tooltip
              title      = "Delete this saved view"
              placement  = "left-start"
              enterDelay = {300}
            >
              <DeleteIcon onClick={() => {
                deleteSavedView(savedView);
              }}/>
            </Tooltip>
          )
        }
      </div>

    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes             : PropTypes.object.isRequired,
  numSelected         : PropTypes.number.isRequired,
  setShowSearchFilter : PropTypes.func,
  title               : PropTypes.string,
  description         : PropTypes.string,
  deleteSavedView     : PropTypes.func,
  savedView           : PropTypes.object
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width        : '96%',
    marginTop    : '80px',
    marginLeft   : 'auto',
    marginRight  : 'auto',
    marginBottom : '80px'
  },
  tableWrapper: {
    overflowX : 'auto',
  }
});

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order                     : 'desc',
      orderBy                   : 'id',
      sortType                  : 'string',
      selected                  : [],
      page                      : 0,
      rowsPerPage               : props.rowsPerPage,
      showModal                 : false,
      changeApprove             : null,
      rowsPerPageOptions        : props.rowsPerPageOptions,
      isImpossibleEditSvrsModal : false,
      currentUserSvrResponses   : [],
      showErrorModal            : false,
      errorModalTitle           : ''
    };
  }

  getStatusCode = label => {
    return R.compose(
      R.partialRight(parseInt, [10]),
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.storeVisit);
  };

  getReviewState = label => {
    return R.compose(
      R.partialRight(parseInt, [10]),
      R.head,
      R.find(R.compose(R.equals(label), R.last)),
      R.toPairs
    )(window.WINSTON.statuses.reviewState);
  };

  deleteSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Deleted'));
  };

  resetSvrResponses = () => {
    this.resetSvrResponses();
  };

  activeSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Active'));
  };

  archiveSvrResponses = () => {
    this.updateSvrResponses(this.getStatusCode('Archived'));
  };

  approveSvrResponses = () => {
    const { onUpdate, data, userEmail, namespace } = this.props;
    const { selected } = this.state;

    const svrResponsesFilter = s => R.contains(s.id, selected) && !R.equals(R.path(['metaJson', 'reviewState'], s), SVR_RESPONSE_APPROVED_STATUS);
    const updatedMetaJson    = svrResponse => R.assoc('reviewState', SVR_RESPONSE_APPROVED_STATUS, svrResponse.metaJson);
    const updateSvrResponse  = svrResponse => onUpdate(namespace, svrResponse.id, { metaJson: JSON.stringify(updatedMetaJson(svrResponse)) });

    const svrResponses            = R.filter(svrResponsesFilter, data);
    const currentUserSvrResponses = R.filter(s => R.equals(R.path(['metaJson', 'mcEmail'], s), userEmail), svrResponses);

    if (currentUserSvrResponses.length > 0) {
      this.setState({
        isImpossibleEditSvrsModal: true,
        currentUserSvrResponses
      });
    } else {
      R.forEach(updateSvrResponse, svrResponses);

      this.setState({
        selected: []
      });
    }
  };

  modalClose = () => {
    this.setState({
      showModal                 : false,
      changeApprove             : null,
      isImpossibleEditSvrsModal : false,
      currentUserSvrResponses   : []
    });
  };

  updateSvrResponses = status => {
    const { onUpdate, data, namespace } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svrResponse => {
          data.forEach(item => {
            if (R.equals(svrResponse, item.eventId)) {
              if (!R.equals(item.status, status)) {
                onUpdate(namespace, item.id, { eventId: item.eventId, status: parseInt(status, 10) });
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected : [] });
      };

      this.setState({
        showModal     : true,
        changeApprove : change
      });
    }
  };

  resetSvrResponses = () => {
    const { onReset, data, namespace } = this.props;

    if (this.state.selected.length > 0) {
      const change = () => {
        this.state.selected.forEach(svrResponse => {
          data.forEach(item => {
            if (R.equals(svrResponse, item.eventId)) {
              if(R.propEq('svrType', 1, item)){
                this.setState({
                  showErrorModal: true,
                  errorModalTitle: "MultiDay SVRs can’t be reset."
                });
              }
              else {
                onReset(namespace, item.id);
              }
            }
          });
        });
        this.modalClose();
        this.setState({ selected : [] });
      };

      this.setState({
        showModal     : true,
        changeApprove : change
      });
    }
  };

  componentDidUpdate(prevProps) {
    const transformedDataIsDifferent = !R.equals(R.pluck('timestamp', prevProps.transformedData), R.pluck('timestamp', this.props.transformedData));
    const filtersAreDifferent        = !R.equals(prevProps.filterList, this.props.filterList);
    const dataIsDifferent            = !R.equals(R.pluck('timestamp', prevProps.data), R.pluck('timestamp', this.props.data));

    if (transformedDataIsDifferent || filtersAreDifferent || dataIsDifferent) {

      const order      = this.state.order      || 'asc',
            orderBy    = this.state.orderBy    || 'id',
            sortType   = this.state.sortType   || 'string',
            filterList = this.props.filterList || {};

      this.props.transformData(orderBy, order, sortType, filterList, this.props.data, this.props.namespace, this.props.tableColumns);
    }

  }

  handleRequestSort = (event, pathStr, sortType) => {
    const orderBy = pathStr || '';

    let order = 'desc';

    if (this.state.orderBy === pathStr && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, sortType });

    this.props.transformData(orderBy, order, sortType, this.props.filterList, this.props.data, this.props.namespace, this.props.tableColumns);
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: R.pluck('eventId', this.props.transformedData) });
      this.props.handleSelectData(R.pluck('eventId', this.props.transformedData));
      return;
    }
    this.setState({ selected: [] });
    this.props.handleSelectData([]);
  };

  handleClick = (event, eventId) => {
    event.stopPropagation();

    const { selected }   = this.state;
    const selectedIndex  = selected.indexOf(eventId);
    const firstItemIndex = 0;
    const lastItemIndex  = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1),             () => [].concat(selected, eventId)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex),  () => [].concat(selected.slice(0, -1))],
        [R.lt(0),                  () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
    this.props.handleSelectData(newSelected);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage : event.target.value });
  };

  isSelected = id => R.contains(id, this.state.selected);

  maybeAddMailTo = (isClientUser, path, email, text) => R.contains("mcFullName", path) && email && !isClientUser && text
  ? <a href={`mailto:${email}`} onClick={event => event.stopPropagation()}>
      {text}
    </a>
  : text;

  render() {
    const {
            classes,
            goToReviewPage,
            tableColumns,
            transformedData,
            setShowSearchFilter,
            showSearchFilter,
            title,
            description,
            savedView,
            deleteSavedView,
            isClientUser
          } = this.props;

    const {
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
            showModal,
            changeApprove,
            rowsPerPageOptions,
            isImpossibleEditSvrsModal,
            currentUserSvrResponses,
            showErrorModal,
            errorModalTitle
          } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, transformedData.length - page * rowsPerPage);

    const sliceNotifications = () => {
      return transformedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const orderedTableColumns = R.compose(
      d => R.concat(R.without(d, tableColumns), d),
      R.filter(
        R.compose(
          R.contains('Date'), 
          R.prop('label')
        )
      )
    )(tableColumns);

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected         = {selected.length}
          selectedIds         = {selected}
          setShowSearchFilter = {setShowSearchFilter}
          showSearchFilter    = {showSearchFilter}
          title               = {title}
          description         = {description}
          savedView           = {savedView}
          deleteSavedView     = {deleteSavedView}
        />
        <div className={classes.tableWrapper}>
          {showModal &&
            <ChangesOnSvrModal
              onApprove = {changeApprove}
              onCancel  = {this.modalClose}
              items     = {selected.length}
            />
          }
          {showErrorModal &&
            <Modal
              withoutCancel
              onApprove={() => this.setState({ showErrorModal: false, errorModalTitle: '' })}>
              {errorModalTitle}
            </Modal>
          }
          {isImpossibleEditSvrsModal &&
            <ImpossibleEditSvrsModal
              onApprove={this.modalClose}
              currentUserSvrResponses={currentUserSvrResponses}
              withoutCancel
            />
          }
          <Table>
            <EnhancedTableHead
              tableColumns     = {orderedTableColumns}
              numSelected      = {selected.length}
              order            = {order}
              orderBy          = {orderBy}
              onSelectAllClick = {this.handleSelectAllClick}
              onRequestSort    = {this.handleRequestSort}
              rowCount         = {transformedData.length}
            />
            <TableBody>
            {sliceNotifications()
              .map(notification => {
                const isSelected          = this.isSelected(notification.eventId);
                const hasBeenSent         = notification.sent !== 0;
                const maybeDisabledStyle  = hasBeenSent ? { cursor : 'default', backgroundColor : '#fbfbfb'} : {};

                return (
                  <TableRow
                    hover
                    onClick      = {() => goToReviewPage(notification.id)}
                    role         = "checkbox"
                    aria-checked = {isSelected}
                    tabIndex     = {-1}
                    key          = {notification.eventId}
                    selected     = {isSelected}
                    style        = {{ height: `${HEIGHT}px`, cursor: 'pointer', whiteSpace: 'nowrap', ...maybeDisabledStyle }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        style   = {{ height: `${HEIGHT}px` }}
                        checked = {isSelected}
                        onClick = {event => this.handleClick(event, notification.eventId)}
                      />
                    </TableCell>
                    {
                      R.map(({ path, decorator, width, hideColumn }) => 
                        hideColumn
                          ? null
                          : <TableCell
                              key     = {path}
                              padding = "dense"
                              numeric = {false}
                              type    = "body"
                              style   = {{ minWidth: `${width}px`, maxWidth: `${width}px`, whiteSpace: "normal", wordWrap: 'break-word' }}
                            >{
                              this.maybeAddMailTo(
                                isClientUser, 
                                path, 
                                R.prop('metaMcEmail', notification), 
                                maybeDecorateColumn(decorator)(R.path(path, notification))
                              )
                            }</TableCell>
                      )(orderedTableColumns)
                    };
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height : `${HEIGHT * (emptyRows > 5 ? 5 : emptyRows)}px` }}>
                  <TableCell colSpan={tableColumns.length + 1} style={{ height : `${HEIGHT}px` }} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan             = {1}
                  count               = {transformedData.length}
                  style               = {{ height : `${HEIGHT}px` }}
                  rowsPerPage         = {rowsPerPage}
                  rowsPerPageOptions  = {rowsPerPageOptions}
                  page                = {page}
                  onChangePage        = {this.handleChangePage}
                  onChangeRowsPerPage = {this.handleChangeRowsPerPage}
                  backIconButtonProps = {{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps = {{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data                : PropTypes.array,
  transformData       : PropTypes.func.isRequired,
  transformedData     : PropTypes.array,
  transformedDataDict : PropTypes.object,
  tableColumns        : PropTypes.array,
  rowsPerPage         : PropTypes.number,
  classes             : PropTypes.object.isRequired,
  rowsPerPageOptions  : PropTypes.array.isRequired,
  setShowSearchFilter : PropTypes.func,
  deleteSavedView     : PropTypes.func,
  savedView           : PropTypes.object,
  isClientUser        : PropTypes.bool
};

EnhancedTable.defaultProps = {
  data                : [],
  transformedData     : [],
  transformedDataDict : {},
  tableColumns        : [],
  rowsPerPage         : 50,
  filterList          : {},
  showSearchFilter    : false,
  rowsPerPageOptions  : [25, 50, 100, 250]
};

export default withStyles(styles)(EnhancedTable);
