import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';
//import { Link }    from 'react-router-dom';

const StyledFooter = styled.div`
  justify-content : center;
  text-align      : center;
  margin          : 1rem;
`;

const Label = styled.span`
  padding   : .75rem;
  font-size : 1em;
`;

const Footer = ({ retailer, location, doorNumber, svrId }) => (
  <StyledFooter>
    <Label>
     <strong>Retailer:</strong> <span>{retailer}</span>
    </Label>
    <Label>
     <strong>Location:</strong> <span>{location}</span>
    </Label>
    <Label>
      <strong>Door:</strong> <span>{doorNumber}</span>
    </Label>
    {/* <Label>
    <strong>Report #</strong>  
    <Link target="_blank" to={`/svr-response/${svrId}`} >{svrId}</Link>
    </Label> */}
  </StyledFooter>
);

Footer.propTypes = {
  retailer   : PropTypes.string,
  location   : PropTypes.string,
  doorNumber : PropTypes.string,
};

Footer.defaultProps = {
  withoutCancel : false,
};

export default Footer;
