import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';

import AddIcon from '@material-ui/icons/AddCircleOutline';

import { ITEM_TYPES } from '../../BuilderReducer';

const Wrapper = styled.div`
  display        : flex;
  flex-direction : row;
  width          : 200px;
  padding        : 4px 10px;
  background     : #fff;
  border-radius  : 3px;
  box-shadow     : 0px 0px 5px 0px rgba(119, 119, 119, 0.5);
`;

const AddButton = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  width           : 100%;
  cursor          : pointer;

  span {
    font-size   : 14px;
    font-weight : 500;
  }
`;

const AddFormItem = ({ select }) => (
  <Wrapper>
    <AddButton
      aria-haspopup="true"
      onClick={() => select({ type: ITEM_TYPES.SECTION, name: 'Section' })}
    >
      <span>ADD SECTION</span>
      <AddIcon />
    </AddButton>
  </Wrapper>
);

AddFormItem.propTypes = {
  select   : PropTypes.func.isRequired,
};

const addSelection = withStateHandlers(
  ({
    onAdd    = null,
  }) => ({ onAdd }),
  {
    select : ({ onAdd }) => (type) => {
      onAdd(type);
    },
  },
);

export default addSelection(AddFormItem);
