import * as R              from 'ramda';
import { combineReducers } from 'redux';
import { routerReducer }   from 'react-router-redux';
import { toast }           from 'react-toastify';

import AuthReducer, {
  STATE_KEY as AUTH_STATE_KEY
} from './modules/auth/AuthReducer';

import BuilderReducer, {
  STATE_KEY as BUILDER_STATE_KEY
} from './modules/svr/BuilderReducer';

import ClientReducer, {
  STATE_KEY as CLIENT_STATE_KEY
} from './modules/client/ClientReducer';

import SavedViewsReducer, {
  STATE_KEY as SAVED_VIEWS_STATE_KEY
} from './modules/saved-views/SavedViewsReducer';

import SvrReducer, {
  STATE_KEY as SVR_STATE_KEY
} from './modules/svr/SvrReducer';

import CommonQuestionsReducer, {
  STATE_KEY as COMMON_QUESTIONS_STATE_KEY
} from './modules/common-questions/reducers/CommonQuestionsReducer';

import SvrResponsesReducer, {
  STATE_KEY as SVR_RESPONSES_STATE_KEY
} from './modules/svr-responses/SvrResponsesReducer';

import SavedRollupsReducer, {
  STATE_KEY as ROLL_UPS_STATE_KEY
} from './modules/saved-rollups/SavedRollupsReducer';

import SavedWidgetsReducer, {
  STATE_KEY as WIDGETS_STATE_KEY
} from './modules/saved-widgets/SavedWidgetsReducer';

import SavedDashboardsReducer, {
  STATE_KEY as DASHBOARDS_STATE_KEY
} from './modules/dashboard/SavedDashboardsReducer';

import DocumentReducer, {
  STATE_KEY as DOCUMENT_STATE_KEY
} from './modules/document/DocumentReducer';

import FileExportReducer, {
  STATE_KEY as FILE_EXPORT_STATE_KEY
} from './modules/file-export/FileExportReducer';

import MealPeriodReducer, {
  STATE_KEY as MEAL_PERIOD_STATE_KEY
} from './modules/report/MealPeriodReducer';

import NotificationsReducer, {
  STATE_KEY as NOTIFICATIONS_STATE_KEY
} from './modules/notifications/NotificationsReducer';

import AttendanceReducer, {
  STATE_KEY as ATTENDANCE_STATE_KEY
} from './modules/report/AttendanceReducer';

import AdministrationReducer, {
  STATE_KEY as ADMINISTRATION_STATE_KEY
} from './modules/administration/reducers/AdministrationReducer';
import AlertsReducer, {
  STATE_KEY as ALERTS_STATE_KEY
} from './modules/alerts/reducers/AlertsReducer';

const initialAppState = {
  dashboardViewport : { height : 0, width : 0 },
  focusedPage       : '',
  session           : { loggedIn : false },
  tenancy           : { tenant : { domain : 'www' }, tenantLocation : {} }
};

const loadReducer = R.curry((initialState, reducer, state, action) => {
  try {
    return R.invoker(2, action.type)(R.defaultTo(initialState, state), action, reducer);
  } catch (e) {
    return R.defaultTo(initialState, state);
  }
});

const __reduce = path => (state, action) => R.assocPath(path, action.data)(state);

const hashNotification = R.compose(
  R.join('.'),
  R.map(R.invoker(0, 'charCodeAt')),
  R.split('')
);

const globalNotificationReducer = cache => (state, action) => {
  if (action && action.data) {
    const { message, severity } = action.data;
    const hash = hashNotification(message);
    if (!cache[hash]) {
      toast(message, {
        onOpen  : () => cache[hash] = true,
        onClose : () => delete cache[hash],
        type    : severity
      });
    }
  }
  return state;
};

const appReducer = {
  'app.resizeDashboard' : __reduce(['dashboardViewport']),
  'app.setFocusedPage'  : __reduce(['focusedPage']),
  'app.session'         : __reduce(['session']),
  'app.notify'          : globalNotificationReducer({})
};

export default combineReducers({
  routing                      : routerReducer,
  App                          : loadReducer(initialAppState, appReducer),
  [AUTH_STATE_KEY]             : AuthReducer,
  [BUILDER_STATE_KEY]          : BuilderReducer,
  [CLIENT_STATE_KEY]           : ClientReducer,
  [SAVED_VIEWS_STATE_KEY]      : SavedViewsReducer,
  [SVR_STATE_KEY]              : SvrReducer,
  [COMMON_QUESTIONS_STATE_KEY] : CommonQuestionsReducer,
  [SVR_RESPONSES_STATE_KEY]    : SvrResponsesReducer,
  [ROLL_UPS_STATE_KEY]         : SavedRollupsReducer,
  [WIDGETS_STATE_KEY]          : SavedWidgetsReducer,
  [DASHBOARDS_STATE_KEY]       : SavedDashboardsReducer,
  [DOCUMENT_STATE_KEY]         : DocumentReducer,
  [FILE_EXPORT_STATE_KEY]      : FileExportReducer,
  [MEAL_PERIOD_STATE_KEY]      : MealPeriodReducer,
  [NOTIFICATIONS_STATE_KEY]    : NotificationsReducer,
  [ATTENDANCE_STATE_KEY]       : AttendanceReducer,
  [ADMINISTRATION_STATE_KEY]   : AdministrationReducer,
  [ALERTS_STATE_KEY]           : AlertsReducer,
});
