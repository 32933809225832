import * as R from 'ramda';

const namespace = 'NOTIFICATIONS';

export const FETCH_ALL              = `${namespace}/FETCH_ALL`;
export const FETCH_ALL_SUCCESS      = `${namespace}/FETCH_ALL_SUCCESS`;
export const FETCH_ALL_ERROR        = `${namespace}/FETCH_ALL_ERROR`;
export const FETCH                  = `${namespace}/FETCH`;
export const LOAD                   = `${namespace}/LOAD`;
export const CREATE_NOTIF           = `${namespace}/CREATE_NOTIF`;
export const CREATE_NOTIF_SUCCESS   = `${namespace}/CREATE_NOTIF_SUCCESS`;
export const CREATE_NOTIF_ERROR     = `${namespace}/CREATE_NOTIF_ERROR`;
export const DELETE                 = `${namespace}/DELETE`;
export const UPDATE                 = `${namespace}/UPDATE`;
export const FILTER_BY_ACTION       = `${namespace}/FILTER_BY_ACTION`;
export const SHOW_SEARCH_FILTER     = `${namespace}/SHOW_SEARCH_FILTER`;
export const TRANSFORM_DATA         = `${namespace}/TRANSFORM_DATA`;
export const SELECT_NAVIGATE_FILTER = `${namespace}/SELECT_NAVIGATE_FILTER`;
export const CLEAR_CURRENT_DATA     = `${namespace}/CLEAR_CURRENT_DATA`;

export const fetchNotifications = (namespace='notifications', filters={}) => dispatch => {
  if (R.isEmpty(filters)) {
    return dispatch({
      types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
      payload : {
        namespace,
        request : {
          method : 'GET',
          url    : '/api/v2/membership/notification'
        }
      }
    });
  } else {
    return dispatch({
      types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
      payload : {
        namespace,
        request : {
          method : 'POST',
          url    : '/api/v2/membership/notification/all',
          data   : { filters }
        }
      }
    });
  }
};

export const fetchNotification = (namespace, notificationId) => dispatch => dispatch({
  type    : FETCH,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/membership/notification/id/${notificationId}`
    }
  }
});

export const updateNotification = (namespace, notificationId, data) => ({
  type    : UPDATE,
  payload : {
    namespace,
    request : {
      method : 'PUT',
      url    : `/api/v2/membership/notification/id/${notificationId}`,
      data
    }
  }
});

export const deleteNotification = (namespace, notificationId) => ({
  type: UPDATE,
  payload : {
    namespace,
    request : {
      method : 'DELETE',
      url    : `/api/v2/membership/notification/id/${notificationId}`
    }
  }
});

export const filterByAction = (namespace, filters) => ({
  type    : FILTER_BY_ACTION,
  payload : { namespace, filters }
});

export const transformData = (orderBy, order, sortType, filterList, data, namespace='notifications') => ({
  type    : TRANSFORM_DATA,
  payload : { orderBy, order, sortType, filterList, data, namespace }
});

export const setShowSearchFilter = bool => ({
  type    : SHOW_SEARCH_FILTER,
  payload : bool
});

export const selectNavigateFilter = payload => ({
  type: SELECT_NAVIGATE_FILTER,
  payload
});

export const clearNotifications = (namespace='notifications') => ({
  type    : CLEAR_CURRENT_DATA,
  payload : { namespace}
});

export const createNotification = payload => ({
  type    : CREATE_NOTIF,
  payload: {
    request : {
      method : 'POST',
      url    : `/api/v2/membership/notification`,
      data   : payload
    }
  }
});

export const sendNotification = (namespace, notificationId) => ({
  type    : UPDATE,
  payload: {
    namespace,
    request : {
      method : 'POST',
      url    : `/api/v2/membership/notification/id/${notificationId}/send`
    }
  }
});

export const cancelNotification = (namespace, notificationId) => ({
  type    : UPDATE,
  payload: {
    namespace,
    request : {
      method : 'DELETE',
      url    : `/api/v2/membership/notification/id/${notificationId}`
    }
  }
});
