import { createMuiTheme } from '@material-ui/core/styles';
import variables from './variables';

const theme = createMuiTheme({
  palette: {
    common: {
      white: variables.colors.white,
    },
    primary: {
      main: variables.colors.red,
    },
    text: {
      primary: variables.colors.grayDark,
    },
    pblack: {
      black: variables.colors.grayDark
    }
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    button: {
      letterSpacing: variables.font.letterSpacing.extraExtended,
    },
    subheading: {
      fontSize: variables.font.size.h2,
      fontWeight: 300,
      letterSpacing: variables.font.letterSpacing.extended,
      lineHeight: variables.font.lineHeight.extraCompressed,
    },
    title: {
      fontSize: variables.font.size.h1,
      fontWeight: 400,
      letterSpacing: variables.font.letterSpacing.extraExtended,
      lineHeight: variables.font.lineHeight.extraCompressed,
    },
    body2: {
      fontSize: variables.font.size.body2,
      color: variables.colors.grayDark,
      fontWeight: 400,
      lineHeight: variables.font.lineHeight.extraCompressed,
    },
  },
  custom: variables,
  overrides: {
    MuiButton: {
      outlined: {
        border: `2px solid ${variables.colors.redLight}`,
        borderRadius: variables.borderRadiusLarge,
        color: variables.colors.red,
        '&$disabled': {
          border: `2px solid ${variables.colors.gray}`,
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        background: variables.colors.grayLight,
        justifyContent: 'space-between',
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        fontWeight: 500,
      },
    },
    MuiModal: {
      root: {
        overflow: 'initial',
      },
    },
  },
});

export default theme;
