import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';

import SplitIcon          from '@material-ui/icons/CallSplit';
import RightArrowIcon     from '@material-ui/icons/KeyboardArrowRight';
import { Menu, MenuItem } from '@material-ui/core';

import { ITEM_TYPES } from '../../BuilderReducer';

const ITEMS = [
  { type: ITEM_TYPES.RADIO_BUTTON, name: 'Radio Button' },
  { type: ITEM_TYPES.DROPDOWN    , name: 'Dropdown' },
  { type: ITEM_TYPES.CHECKBOX    , name: 'Checkbox' },
  { type: ITEM_TYPES.SINGLE_LINE , name: 'Single Line' },
  { type: ITEM_TYPES.MULTI_LINE  , name: 'Multi Line' },
  { type: ITEM_TYPES.PHOTO       , name: 'Photo' },
  { type: ITEM_TYPES.TOGGLE      , name: 'Yes/No' },
  { type: ITEM_TYPES.COMMON      , name: 'Common Question' },
  { type: ITEM_TYPES.NUMERIC_INT , name: 'Numeric'},
  { type: ITEM_TYPES.VIDEO       , name: 'Video' }
];

const AddButton = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  width           : 100%;
  cursor          : pointer;

  span {
    font-size   : 14px;
    font-weight : 500;
  }
`;

const AddConditionalItem = ({
  conditionItems,
  items,
  conditionAnchorEl,
  fieldAnchorEl,
  open,
  close,
  selectCondition,
  selectFieldType,
}) => (
  <div>
    <AddButton
      aria-owns={conditionAnchorEl ? 'condition-menu' : null}
      aria-haspopup="true"
      onClick={({ currentTarget }) => open(currentTarget)}
    >
      <SplitIcon />
    </AddButton>

    <Menu
      id="condition-menu"
      anchorEl={conditionAnchorEl}
      open={Boolean(conditionAnchorEl)}
      onClose={close}
    >
      {conditionItems.map(i => (
        <MenuItem
          key={i.value}
          onClick={selectCondition.bind(null, i.value, i.uuid)}
        >
          <span>{i.label}</span>
          <RightArrowIcon />
        </MenuItem>
      ))}
    </Menu>

    <Menu
      id="fields-menu"
      anchorEl={fieldAnchorEl}
      open={Boolean(fieldAnchorEl)}
      onClose={close}
    >
      {items.map(i => (
        <MenuItem
          key={i.type}
          onClick={selectFieldType.bind(null, i.type)}
        >
          {i.name}
        </MenuItem>
      ))}
    </Menu>
  </div>
);

AddConditionalItem.propTypes = {
  conditionItems    : PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  items             : PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  })),
  conditionAnchorEl : PropTypes.any,
  fieldAnchorEl     : PropTypes.any,
  open              : PropTypes.func.isRequired,
  close             : PropTypes.func.isRequired,
  selectCondition   : PropTypes.func.isRequired,
  selectFieldType   : PropTypes.func.isRequired,
};

AddConditionalItem.defaultProps = {
  conditionItems : [],
  items          : ITEMS,
};

const addSelection = withStateHandlers(
  ({
    conditionAnchorEl = null,
    fieldAnchorEl     = null,
    contextCondition  = null,
    onAdd             = null,
  }) => ({ conditionAnchorEl, fieldAnchorEl, contextCondition, onAdd }),
  {
    open            : () => currentTarget => ({ conditionAnchorEl: currentTarget }),
    close           : () => () => ({ conditionAnchorEl: null, fieldAnchorEl: null }),
    selectCondition : ({ conditionAnchorEl }) => (condition, uuid) => {
      return { conditionAnchorEl: null, fieldAnchorEl: conditionAnchorEl, contextCondition: condition, uuid };
    },
    selectFieldType : ({ contextCondition, onAdd, uuid }) => (type) => {
      onAdd({ contextCondition, type, uuid });
      return { conditionAnchorEl: null, fieldAnchorEl: null, contextCondition: null };
    },
  },
);

export default addSelection(AddConditionalItem);
