import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import Footer from './Footer';

const Modal = ({ children, onSend, onCancel, toolTip, onCancelText, onCancelNotification }) => (
  <Modal.Wrapper>
    <Modal.Container>
      <Modal.Package>
        {children}
      </Modal.Package>
      <Footer
        onSend={onSend}
        onCancel={onCancel}
        onCancelNotification={onCancelNotification}
        toolTip={toolTip}
        onCancelText={onCancelText}
      />
    </Modal.Container>
  </Modal.Wrapper>
);

Modal.propTypes = {
  withoutCancel : PropTypes.bool,
  children      : PropTypes.node.isRequired,
  onSend        : PropTypes.func.isRequired,
  onCancel      : PropTypes.func.isRequired,
  toolTip       : PropTypes.string
};

Modal.defaultProps = {
  withoutCancel : false,
};

Modal.Wrapper = styled.div`
  top      : 0;
  bottom   : 0;
  left     : 0;
  right    : 0;
  width    : 100%;
  height   : 100%;
  position : fixed;
`;

Modal.Container = styled.div`
  position       : relative;
  width          : 500px;
  min-height     : 220px;
  background     : #fff;
  margin         : 5px auto;
  border-radius  : 3px;
  box-shadow     : 0 2px 4px rgba(0, 0, 0, .2);
  top            : 15%;
  padding-bottom : 20px;
`;

Modal.Package = styled.div`
  display         : flex;
  height          : 80%;
  max-height      : 500px;
  padding         : 30px 40px;
  text-indent     : 5px;
  font-weight     : 300;
  font-size       : 16px;
  overflow        : scroll;
`;

export default Modal;
