import React            from 'react';
import PropTypes        from 'prop-types';
import styled           from 'styled-components';
import {
  compose,
  withStateHandlers,
  withHandlers
}                       from 'recompose';
import CircularProgress from '@material-ui/core/CircularProgress';

import Uploader         from '../../../upload/components/Uploader';

const Wrapper = styled(Uploader)`
  border       : 2px solid lightgray;
  padding      : 8px 15px;
  min-height   : 220px;
  margin       : 2rem 0 0 0;
  background   : ${props => props.dragging ? 'rgba(255, 255, 255, 0.5)' : 'none'};
  border-style : ${props => props.dragging ? 'dashed' : 'solid'};
`;

const Title = styled.p`
  margin-bottom: 5px;
`;

const SubTitle = styled.p`
  font-size: 0.9rem;
`;

const Progress = styled(CircularProgress)`
  margin-left: 20px;
  vertical-align: middle;
  color: #f69d50;
`;

const DroppableZone = ({
  title,
  subtitle,
  children,
  innerRef,
  createDocument,
  addDocument,
  onProgressUpdate,
  onDrag,
  beginUpload,
  dragging,
  uploading,
}) => (
  <div ref={innerRef}>
    <Title>
      {title}
      { uploading && <Progress size={15} /> }
    </Title>
    {subtitle 
      ? <SubTitle>
          {subtitle}
          { uploading && <Progress size={15} /> }
        </SubTitle>
      : null}
    <Wrapper
      beginUpload={beginUpload}
      onUpload={addDocument}
      onDrag={onDrag}
      handleFile={createDocument}
      progressCB={onProgressUpdate}
      dragging={dragging}
      showAddFileButton
      disableClick
    >
      {children}
    </Wrapper>
  </div>
);

DroppableZone.propTypes = {
  title      : PropTypes.string,
  children   : PropTypes.node.isRequired,
  createDocument : PropTypes.func.isRequired,
};

DroppableZone.defaultProps = {
  title: '',
};

const addEditing = compose(
  withStateHandlers(
    ({
      dragging = false,
      uploading = false
    }) => ({
      dragging,
      uploading
    }),
    {
      setDragging : () => (status) => ({
        dragging : status,
      }),
      setUploading : () => (status) => ({
        uploading : status,
      }),
      addDocument : () => () => ({
        uploading: false,
        dragging: false,
      })
    },
  ),
  withHandlers({
    onDrag : props => (status) => {
      props.setDragging(status);
    },
    beginUpload: props => () => {
      props.setUploading(true);
    },
    onProgressUpdate : (props) => (response) => {
      if (response.error) {
        props.setUploading(false);
        props.setDragging(false);
      }
    },
  })
);



export default addEditing(DroppableZone);
