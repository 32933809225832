import React, {Component} from 'react';
import PropTypes          from 'prop-types';

import { Checkbox, TableCell, TableHead, TableSortLabel, Tooltip } from '@material-ui/core';

import { StyledTableRow } from '../styled-components/QuestionTable';

const isSortedBy  = (k, orderBy) => orderBy === k;
const maybeSortBy = (k, orderBy, order) => isSortedBy(k, orderBy) ? order : false;

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox" width={30}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              style={{ height : '30px' }}
            />
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={maybeSortBy('question', orderBy, order)}
            width={200}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={isSortedBy('question', orderBy)}
                direction={order}
                onClick={this.createSortHandler('question')}
              >
                Question
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'type' ? order : false}
            width={100}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'type'}
                direction={order}
                onClick={this.createSortHandler('type')}
              >
                Type
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'status' ? order : false}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'status'}
                direction={order}
                onClick={this.createSortHandler('status')}
              >
                Status
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected      : PropTypes.number.isRequired,
  onRequestSort    : PropTypes.func.isRequired,
  onSelectAllClick : PropTypes.func.isRequired,
  order            : PropTypes.string.isRequired,
  orderBy          : PropTypes.string.isRequired,
  rowCount         : PropTypes.number.isRequired
};

export default EnhancedTableHead;
