import React          from 'react';
import { DragSource } from 'react-dnd';
import SmoothScroll   from '../../../../utils/SmoothScroll';

const smoothScroll = new SmoothScroll(); 

const cardSource = {
  canDrag(props) {
    return props.canDrag;
  },

  isDragging({answer}, monitor) {
    return monitor.getItem().id === answer;
  },

  beginDrag({photo, question, answer}) {
    smoothScroll.onDragStart();
    return { answer, photo, question };
  },
  endDrag: () => {
    smoothScroll.onDragEnd();
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const DragPhoto = ({canDrag, connectDragSource, children})  => {
    return connectDragSource(
      <div style={{width: "100%", display: 'flex', flexDirection: 'column', border: "2px solid green", cursor: canDrag ? 'grab' : 'auto'}}>
        {children}
      </div>
    );
  
};

export default DragSource('svrPhoto', cardSource, collect)(DragPhoto);
