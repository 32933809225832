import React     from 'react';
import PropTypes from 'prop-types';
import Modal     from '../../components/Modal/Modal';

const SVR_RESPONSES_STATUSES = {
  '3' : 'Approved',
  '4' : 'Rejected',
  '5' : 'Needs Revision'
};

const ConfirmSvrModal = ({ onApprove, onCancel, status }) => (
  <Modal
    onApprove={onApprove}
    onCancel={onCancel}
  >
    {"Are you sure you want to mark this SVR as "}{SVR_RESPONSES_STATUSES[status]}?
  </Modal>
);

ConfirmSvrModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
};

export default ConfirmSvrModal;
