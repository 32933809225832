import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import {
  compose,
  withStateHandlers,
  withHandlers
 }                           from 'recompose';
import * as R                from 'ramda';
import uuid                  from 'uuid/v4';

import { Button, Chip, Grid, MenuItem, Modal, Select, Switch, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import AddIcon   from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import { ITEM_TYPES, ITEM_TYPES_WITH_CONDITION, ITEM_TYPES_WITH_AUTO_CALLOUT, ITEM_TYPES_WITHOUT_CALLOUT }   from '../../BuilderReducer';
import DocumentUploader from './DocumentUploader';

const QUESTION_TYPES_NAME = {
  [ITEM_TYPES.RADIO_BUTTON]         : 'Radio Button',
  [ITEM_TYPES.DROPDOWN]             : 'Dropdown',
  [ITEM_TYPES.CHECKBOX]             : 'Checkbox',
  [ITEM_TYPES.SINGLE_LINE]          : 'Single Line',
  [ITEM_TYPES.MULTI_LINE]           : 'Multi Line',
  [ITEM_TYPES.PHOTO]                : 'Photo',
  [ITEM_TYPES.TOGGLE]               : 'Yes/No',
  [ITEM_TYPES.COMMON]               : 'Common',
  [ITEM_TYPES.NUMERIC_INT]          : 'Numeric',
  [ITEM_TYPES.VIDEO]                : 'Video',
  [ITEM_TYPES.SIGNATURE]            : 'Signature',
  [ITEM_TYPES.NUMERIC_CONDITIONAL]  : 'Numeric Conditional',
};

const DEFAULT_TOGGLE_ANSWERS = [
  {
    value           : 'Yes',
    label           : 'Yes',
    uuid            : uuid(),
    conditionalFork : [],
  },
  {
    value           : 'No',
    label           : 'No',
    uuid            : uuid(),
    conditionalFork : [],
  },
];

const DEFAULT_NUMERIC_CONDITIONAL_ANSWERS = [
  {
    value           : 'Question type of follow-up questions',
    label           : 'Question type of follow-up questions', // Enter the number of follow-up questions
    uuid            : uuid(),
    conditionalFork : [],
  }
];

const PHOTO_TYPES = [
  { name : 'Before',  value : 'before-photo' },
  { name : 'After',   value : 'after-photo' },
  { name : 'General', value : 'general-photo' }
];

const VIDEO_TYPES = [
  { name : 'Before',  value : 'before-video' },
  { name : 'After',   value : 'after-video' },
  { name : 'General', value : 'general-video' }
];

const FOOTER_HEIGHT = 40;

const ModalWrapper = styled.form`
  position         : absolute;
  max-height       : 100%;
  width            : 70%;
  height           : auto;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  background-color : #fff;
  border-left      : 10px solid #45a2cb;
  border-radius    : 3px;
  outline          : none;
`;

const Content = styled.div`
  display    : flex;
  height     : auto;
  max-height : 90vh;
  overflow-y : auto;
  padding    : 8px;
  box-sizing : border-box;
`;

const AnswerContainer = styled.div`
  max-height : 100%;
`;

const Footer = styled.div`
  display         : flex;
  height          : ${FOOTER_HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-end;
`;

const GridSection = styled(Grid)`
  padding: 10px 20px !important;

  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: 1px solid #d6d7d8;
  }

  &:nth-child(2),
  &:nth-child(4) {
    border-left: 1px solid #d6d7d8;
  }
`;

const TagsWrapper = styled.div`
  overflow: auto;

  & > div {
    margin-right : 10px;
    margin-top   : 10px;
  }
`;

const Document = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
`;

const AnswerWrapper = styled.div`
  display     : flex;
  align-items : center;

  & > svg:hover {
    cursor: pointer;
  }
`;

const hasCondition  = type => ITEM_TYPES_WITH_CONDITION.includes(type);
const isAutoCallOut = type => ITEM_TYPES_WITH_AUTO_CALLOUT.includes(type);
const hasCallOut    = type => !ITEM_TYPES_WITHOUT_CALLOUT.includes(type);

const isAfterPhoto = R.equals('after-photo');
const isAfterVideo = R.equals('after-video');

const QuestionModal = ({
  open,
  title,
  tags,
  type,
  meta : {
    isRequired,
    isHidden,
    isQuickReview,
    isConditional,
    isCallOut,
    callOutOptions,
    photoType,
    videoType,
    pairedQuestionUuid,
    maxQuestions
  },
  setTitle,
  setCurrentTag,
  addTag,
  currentTag,
  setAnswer,
  currentAnswer,
  documentIds,
  documents,
  options,
  addOption,
  deleteTag,
  addDocument,
  createDocument,
  _deleteDocument,
  toggleRequired,
  toggleHidden,
  toggleQuickReview,
  toggleConditional,
  toggleCallOut,
  toggleCallOutOption,
  updateMaxQuestionsInput,
  deleteAnswer,
  save,
  close,
  commonQuestion,
  isValidate,
  selectPhotoType,
  selectVideoType,
  beforeTypesPhotos,
  selectPairedPhoto,
  selectedBeforePhoto,
  beforeTypesVideos,
  selectPairedVideo,
  selectedBeforeVideo,
  isCreateCommonQuestion,
  onProgressUpdate,
  showProgressModal,
  uploadError,
  uploadText,
  closeProgressModal,
  forkFromNumericConditional
}) => {
return (
  <Modal
    aria-labelledby="Create Question"
    aria-describedby="Create Question"
    open={open}
  >
    <ModalWrapper onSubmit={save}>
      <Content>
        <Grid container>
          <GridSection item xs={6}>
            <TextField
              fullWidth
              id="title"
              label={forkFromNumericConditional ? 'Yes' : `${R.toUpper(R.propOr('', R.isEmpty(commonQuestion) ? type : commonQuestion.type, QUESTION_TYPES_NAME))} QUESTION`}
              value={R.isEmpty(commonQuestion) ? title : commonQuestion.title}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              onChange={setTitle}
            />

            {hasCondition(type) && (type !== 'toggle-input' && type !== 'numeric-conditional-input') && (
              <AnswerContainer>
                <p>ANSWER(S)</p>
                {options.map(a => (
                  <AnswerWrapper key={`answer_${a.uuid}`}>
                    <TextField
                      fullWidth
                      id="options"
                      value={a.value}
                      margin="normal"
                      onChange={value => setAnswer(value, a.uuid)}
                    />
                    <CloseIcon onClick={() => deleteAnswer(a)} />
                  </AnswerWrapper>
                ))}
                <AnswerWrapper>
                  <TextField
                    fullWidth
                    id="answer"
                    value={currentAnswer}
                    placeholder="Add..."
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(event) =>  event && !R.isEmpty(currentAnswer) && addOption()}
                    onChange={setAnswer}
                    onKeyPress={e => R.equals('Enter', e.key) && !R.isEmpty(currentAnswer) && addOption() }
                  />
                  <AddIcon style={{ color: 'green'}} onClick={() => !R.isEmpty(currentAnswer) && addOption()} />
                </AnswerWrapper>
              </AnswerContainer>
            )}

            {isCreateCommonQuestion &&
              <div>
                {isPhotoQuestion(type) ? (
                  <div style={{ marginTop: '25px' }}>
                    <p>TYPE</p>
                    <Select
                      fullWidth
                      disabled
                      value="general-photo"
                    >
                      <MenuItem value="general-photo">General</MenuItem>
                    </Select>
                  </div>
                ) : isVideoQuestion(type) && (
                  <div style={{ marginTop: '25px' }}>
                    <p>TYPE</p>
                    <Select
                        fullWidth
                      disabled
                      value="general-video"
                    >
                      <MenuItem value="general-video">General</MenuItem>
                    </Select>
                  </div>
                )}
              </div>
            }

            {!isCreateCommonQuestion && (checkPhotoType(commonQuestion, type) ? (
              <div style={{ marginTop: '25px' }}>
                <p>TYPE</p>
                <Select
                  fullWidth
                  value={R.defaultTo('before-photo', photoType)}
                  onChange={({ target }) => selectPhotoType(target.value)}
                >
                  {PHOTO_TYPES.map(type => <MenuItem value={type.value} key={`type-${type.name}`}>{type.name}</MenuItem>)}
                </Select>
              </div>
            ) : checkVideoType(commonQuestion, type) && (
              <div style={{ marginTop: '25px' }}>
                <p>TYPE</p>
                <Select
                  fullWidth
                  value={R.defaultTo('before-video', videoType)}
                  onChange={({ target }) => selectVideoType(target.value)}
                >
                  {VIDEO_TYPES.map(type => <MenuItem value={type.value} key={`type-${type.name}`}>{type.name}</MenuItem>)}
                </Select>
              </div>
            ))}

            {!isCreateCommonQuestion && (isAfterPhoto(photoType) ? (
              <div style={{ marginTop: '25px' }}>
                <p>BEFORE PHOTO</p>
                <Select
                  fullWidth
                  placeholder="test"
                  value={R.defaultTo(selectedBeforePhoto, pairedQuestionUuid)}
                  onChange={({ target }) => selectPairedPhoto(target.value)}
                >
                  {beforeTypesPhotos.map(q => <MenuItem value={q.uuid} key={`before-${q.title}`}>{q.title}</MenuItem>)}
                </Select>
              </div>
            ) : isAfterVideo(videoType) && (
              <div style={{ marginTop: '25px' }}>
                <p>BEFORE VIDEO</p>
                <Select
                  fullWidth
                  placeholder="test"
                  value={R.defaultTo(selectedBeforeVideo, pairedQuestionUuid)}
                  onChange={({ target }) => selectPairedVideo(target.value)}
                >
                  {beforeTypesVideos.map(q => <MenuItem value={q.uuid} key={`before-${q.title}`}>{q.title}</MenuItem>)}
                </Select>
              </div>
            ))}

            {(type === 'toggle-input') && (
              <div>
                <p>ANSWER(S)</p>
                {options.map(a => (
                  <AnswerWrapper key={`answer_${a.uuid}`}>
                    <TextField
                      fullWidth
                      id="options"
                      value={a.value}
                      margin="normal"
                      onChange={value => setAnswer(value, a.uuid)}
                    />
                  </AnswerWrapper>
                ))}
              </div>
            )}
            
            {type === ITEM_TYPES.NUMERIC_CONDITIONAL && (
              <div>
                <span>MAX RESPONSES</span>
                <TextField
                  type='numeric'
                  style={{ marginLeft: 10 }}
                  onChange={updateMaxQuestionsInput}
                  value={maxQuestions}
                />
              </div>
            )}

          </GridSection>
          <GridSection item xs={6}>
            <TextField
              fullWidth
              id="tags"
              label="TAGS"
              helperText="Tags will be auto-applied to all questions within this section."
              value={currentTag}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={setCurrentTag}
              onKeyPress={e => R.equals('Enter', e.key) && !R.isEmpty(currentTag) && addTag()}
            />
            <TagsWrapper>
              {R.is(Array, tags) && tags.map(tag => (
                <Chip
                  key={`${tag.label}_${tag.id}`}
                  label={tag.label}
                  onDelete={() => deleteTag(tag)}
                />
              ))}
            </TagsWrapper>
          </GridSection>
          <GridSection item xs={6}>
            <div>
              <p>QUESTION RELATED DOCUMENTS</p>
              {R.is(Array, documents) && documents.filter(d => documentIds.includes(d.id)).map(doc => (
                <Document key={doc.id}>
                  <p>{doc.title}</p>
                  <CloseIcon style={{ cursor: 'pointer' }} onClick={() => _deleteDocument(doc.id)}/>
                </Document>
              ))}
              <DocumentUploader onUpload={addDocument} handleFile={createDocument} progressCB={onProgressUpdate}/>
            </div>

          </GridSection>
          <GridSection item xs={6}>
            <div>
              <span>REQUIRED</span>
              <Switch
                checked={isRequired}
                onChange={toggleRequired}
                value="isRequired"
              />
            </div>
            <div>
              <span>HIDDEN</span>
              <Switch
                checked={isHidden}
                onChange={toggleHidden}
                value="isHidden"
              />
            </div>
            <div>
              <span>QUICK REVIEW</span>
              <Switch
                checked={isQuickReview}
                onChange={toggleQuickReview}
                value="isQuickReview"
              />
            </div>
            {hasCondition(type) && (
              <div>
                <span>CONDITIONAL</span>
                <Switch
                  checked={isConditional}
                  onChange={toggleConditional}
                  value="isConditional"
                />
              </div>
            )}

            {hasCallOut(type) && (
              <div>
                <span>CALL OUT</span>
                <Switch
                  checked={isCallOut}
                  onChange={toggleCallOut}
                  value="isCallOut"
                />
                {isCallOut && !isAutoCallOut(type) && (
                <div>
                  <p>ANSWER(S)</p>
                  {options.map(o => (
                    <FormControlLabel
                      key     = {o.uuid}
                      value   = {o.value}
                      label   = {o.label}
                      control = {
                        <Checkbox
                          value     = {o.value}
                          onChange  = {() => toggleCallOutOption(o.value)}
                          checked   = {R.contains(o.value, R.defaultTo([], callOutOptions))}
                        />
                      }
                    />
                  ))}
                  </div>
                )}
              </div>
            )}
          </GridSection>
        </Grid>
      </Content>

      <Modal
        open={showProgressModal}
        onBackdropClick={uploadError ? closeProgressModal: null}>
        <ModalWrapper style={{ width: '50%', maxWidth: 500 }}>
          <Content>
            <Grid container>
              <GridSection item>
                <div>
                  <p>UPLOADING FILE</p>
                  <p>{`${uploadText}`}</p>
                </div>
              </GridSection>
            </Grid>
          </Content>
        </ModalWrapper>
      </Modal>

      <Footer>
        <Button onClick={close} color="secondary">CANCEL</Button>
        <Button onMouseDown={() => {setTimeout(save, 0);}}  disabled={isValidate} color="secondary">SAVE QUESTION</Button>
      </Footer>
    </ModalWrapper>
  </Modal>
);
};

QuestionModal.propTypes = {
  open                   : PropTypes.bool,
  isEditCommonQuestion   : PropTypes.bool,
  isCreateCommonQuestion : PropTypes.bool,
  commonQuestion         : PropTypes.object,
  title                  : PropTypes.string,
  tags                   : PropTypes.array,
  options                : PropTypes.array,
  documentIds            : PropTypes.array,
  documents              : PropTypes.array,
  currentTag             : PropTypes.string,
  type                   : PropTypes.string,
  setTitle               : PropTypes.func.isRequired,
  setCurrentTag          : PropTypes.func.isRequired,
  addTag                 : PropTypes.func.isRequired,
  deleteTag              : PropTypes.func.isRequired,
  addDocument            : PropTypes.func.isRequired,
  createDocument         : PropTypes.func.isRequired,
  removeDocument         : PropTypes.func.isRequired,
  deleteDocument         : PropTypes.func.isRequired,
  _deleteDocument        : PropTypes.func.isRequired,
  toggleRequired         : PropTypes.func.isRequired,
  toggleHidden           : PropTypes.func.isRequired,
  toggleQuickReview      : PropTypes.func.isRequired,
  toggleConditional      : PropTypes.func.isRequired,
  toggleCallOut          : PropTypes.func.isRequired,
  updateMaxQuestionsInput : PropTypes.func.isRequired,
  onSave                 : PropTypes.func.isRequired,
  save                   : PropTypes.func.isRequired,
  close                  : PropTypes.func.isRequired,
  forkFromNumericConditional: PropTypes.bool,
  meta                   : PropTypes.shape({
    isRequired         : PropTypes.bool,
    isHidden           : PropTypes.bool,
    isQuickReview      : PropTypes.bool,
    isConditional      : PropTypes.bool,
    isCallOut          : PropTypes.bool,
    callOutOptions     : PropTypes.array,
    photoType          : PropTypes.string,
    pairedQuestionUuid : PropTypes.string,
    maxQuestions       : PropTypes.any,
  }).isRequired,
  onProgressUpdate     : PropTypes.func,
  showProgressModal    : PropTypes.bool,
  uploadError          : PropTypes.bool,
  uploadText           : PropTypes.string
};

QuestionModal.defaultProps = {
  open                   : false,
  isCreateCommonQuestion : false,
  title                  : '',
  type                   : null,
  currentAnswer          : '',
  commonQuestion         : {},
  documentIds            : [],
  documents              : [],
  tags                   : [],
  options                : [],
  meta                   : {
    isRequired         : true,
    isHidden           : false,
    isQuickReview      : false,
    isConditional      : false,
    isCallOut          : false,
    callOutOptions     : [],
    photoType          : '',
    pairedQuestionUuid : '',
    maxQuestions       : 0,
  },
  forkFromNumericConditional: false,
  showProgressModal    : false,
  uploadError          : false,
  uploadText           : 'Starting upload'
};

const toggleMeta = (field, state) => R.compose(
  R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
  R.over(R.lensPath(['meta', field]), R.not),
  R.pick(['meta']),
)(state);

const setMetaPhotoType = (type, state) => R.compose(
  R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
  R.set(R.lensPath(['meta', 'photoType']), type),
  R.pick(['meta']),
)(state);

const setMetaMaxQuestions = (e, type, state) => R.compose(
  R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
  R.set(R.lensPath(['meta', type]), e.target.value),
  R.pick(['meta']),
)(state);

const setPairedPhoto = (id, state) => R.compose(
  R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
  R.assocPath(['meta', 'pairedQuestionUuid'], id),
  R.assoc('selectedBeforePhoto', id),
  R.pick(['meta']),
)(state);

const setMetaVideoType = (type, state) => R.compose(
  R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
  R.set(R.lensPath(['meta', 'videoType']), type),
  R.pick(['meta']),
)(state);

const setPairedVideo = (id, state) => R.compose(
  R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
  R.assocPath(['meta', 'pairedQuestionUuid'], id),
  R.assoc('selectedBeforeVideo', id),
  R.pick(['meta']),
)(state);

const toggleMetaCalloutOption = (state) => (value) => {
  return R.compose(
    R.assoc('isValidate', validate(state.options.length, state.title, state.type)),
    R.assocPath(['meta', 'callOutOptions'], R.__, state),
    R.ifElse(R.contains(value), R.without([value]), R.append(value)),
    R.pathOr([], ['meta', 'callOutOptions'])
  )(state);
};


const isToggleQuestion = (type) => type === 'toggle-input' || type === 'numeric-conditional-input';
const isPhotoQuestion = R.equals('photo-input') || R.equals('signature-input');
const isVideoQuestion = R.equals('video-input');

const checkPhotoType = (commonQuestion, type) => {
  return R.isEmpty(commonQuestion) ? isPhotoQuestion(type) : isPhotoQuestion(R.propOr('', 'type', commonQuestion));
};
const checkVideoType = (commonQuestion, type) => {
  return R.isEmpty(commonQuestion) ? isVideoQuestion(type) : isVideoQuestion(R.propOr('', 'type', commonQuestion));
};

const validate = (answersCount, title, type) => {
  if (title) {
    if (hasCondition(type)) {
      return answersCount < 1;
    }
    return false;
  }

  return true;
};

const updateOptions = (options, answerId, value) => R.map((opt => opt.uuid === answerId ? { ...opt, label: value, value } : opt), options);

const editingOptions = (type) => {
  if (isToggleQuestion(type)) {
    return type === 'numeric-conditional-input' ? DEFAULT_NUMERIC_CONDITIONAL_ANSWERS : DEFAULT_TOGGLE_ANSWERS;
  } else {
    return [];
  }
};

const addEditing = compose(
  withStateHandlers(
  ({
    isValidate          = true,
    selectedBeforePhoto = '',
    selectedBeforeVideo = '',
    title               = '',
    currentTag          = '',
    currentAnswer       = '',
    documentIds         = [],
    tags                = [],
    type                = null,
    options             = editingOptions(type),
    meta                = {
      isRequired    : true,
      isHidden      : false,
      isQuickReview : false,
      isConditional : isToggleQuestion(type),
      isCallOut     : false,
      callOutOptions: [],
      photoType     : isPhotoQuestion(type) ? 'before-photo' : '',
      videoType     : isVideoQuestion(type) ? 'before-video' : '',
      maxQuestions: 0,
    }
  }) => ({ title, tags, type, meta, currentTag, currentAnswer, documentIds, options, isValidate, selectedBeforePhoto, selectedBeforeVideo }),
  {
    setTitle          : ({ options, type }) => ({ target }) => ({ isValidate: validate(options.length, target.value, type), title: target.value }),
    setCurrentTag     : ({ options, type }) => ({ target }) => ({ isValidate: validate(options.length, target.value, type), currentTag: target.value }),
    setAnswer         : ({ options, title, type }) => ({ target }, answerId) => {
      return answerId ?
        ({
          isValidate : validate(options.length, title, type),
          options    : updateOptions(options, answerId, target.value),
        }) : ({
            isValidate    : validate(options.length, title, type),
            currentAnswer : target.value
          }
        );
    },
    addTag            : ({ title, options, type, tags, currentTag }) => () => R.compose(
      R.assoc('isValidate', validate(options.length, title, type)),
      R.assoc('currentTag', ''),
      R.assoc('tags', R.__, {}),
      R.concat(tags)
    )([{ id: R.inc(tags.length), label: currentTag }]),
    addOption         : ({ title, options, currentAnswer, type }) => () => R.compose(
      R.assoc('isValidate', validate(options.length + 1, title, type)),
      R.assoc('currentAnswer', ''),
      R.assoc('options', R.__, {}),
      R.concat(options),
    )([{
      label           : currentAnswer,
      value           : currentAnswer,
      conditionalFork : [],
      uuid            : uuid()
    }]),
    deleteTag         : ({ tags }) => tag => ({ tags: R.reject(R.whereEq(tag), tags) }),
    deleteAnswer      : ({ options, type, title, meta }) => option => ({
      meta       : R.assoc('callOutOptions', R.compose(R.without([R.prop('value', option)]), R.propOr([], 'callOutOptions'))(meta), meta),
      options    : R.reject(R.whereEq(option), options),
      isValidate : validate(options.length - 1, title, type)
    }),
    addDocument       : ({ options, title, type, documentIds }) => docId => ({
      documentIds       : R.is(Array, documentIds) ? R.concat(documentIds, [docId]) : R.concat([], [docId]),
      isValidate        : validate(options.length, title, type),
      uploadText        : 'Starting upload',
      showProgressModal : false,
      uploadError       : false
    }),
    removeDocument    : ({ options, title, type, documentIds }) => id => ({
      documentIds       : R.reject(R.equals(id), documentIds),
      isValidate        : validate(options.length, title, type),
      uploadText        : 'Starting upload',
      showProgressModal : false,
    }),
    toggleRequired     : state => () => toggleMeta('isRequired', state),
    toggleHidden       : state => () => toggleMeta('isHidden', state),
    toggleQuickReview  : state => () => toggleMeta('isQuickReview', state),
    toggleConditional  : state => () => toggleMeta('isConditional', state),
    toggleCallOut      : state => () => R.assocPath(['meta', 'callOutOptions'], [], toggleMeta('isCallOut', state)),
    toggleCallOutOption: state => toggleMetaCalloutOption(state),
    updateMaxQuestionsInput : state => (e) => setMetaMaxQuestions(e, 'maxQuestions', state),
    selectPhotoType    : state => type => setMetaPhotoType(type, state),
    selectPairedPhoto  : state => id => setPairedPhoto(id, state),
    selectVideoType    : state => type => setMetaVideoType(type, state),
    selectPairedVideo  : state => id => setPairedVideo(id, state),
    save               : ({ title, tags, type, meta, options, documentIds }, { k, ck, onSave, onClose, commonQuestion = {}, isCommonQuestion, isEditCommonQuestion, canonicalKey, id }) => () => {
      if (isEditCommonQuestion) {
        onSave({
          uuid         : k,
          options      : JSON.stringify(options),
          documentIds  : documentIds.join(','),
          tags         : tags.join(','),
          metaJson     : JSON.stringify(meta),
          id,
          canonicalKey,
          type,
          title
        });
      } else {
        onSave({
          isCommonQuestion,
          uuid         : k,
          type         : commonQuestion.type         || type,
          tags         : commonQuestion.tags         || tags,
          options      : commonQuestion.options      || options,
          title        : commonQuestion.title        || title,
          canonicalKey : commonQuestion.canonicalKey || ck === undefined ? uuid() : ck,
          meta,
          documentIds
        });
      }
      onClose();
      return {
        title       : '',
        tags        : [],
        type        : null,
        options     : [],
        documentIds : [],
        meta        : {
          isRequired    : false,
          isHidden      : false,
          isQuickReview : false,
          isConditional : false,
          isCallOut     : false,
          callOutOptions: []
        }
      };
    },
    close             : (_, { onClose }) => () => {
      onClose();
      return {
        title       : '',
        tags        : [],
        type        : null,
        options     : [],
        documentIds : [],
        meta        : {
          isRequired    : false,
          isHidden      : false,
          isQuickReview : false,
          isConditional : false,
          isCallOut     : false,
          callOutOptions: []
        }
      };
    },
    closeProgressModal  : () => () => ({
      uploadText        : 'Starting upload',
      showProgressModal : false,
      uploadError       : false
    }),
    onProgressUpdate  : () => (evt) => {
      if(R.has('error', evt)) {
        const message = R.compose(
          R.defaultTo('An error has ocurred. Please try again'),
          R.when(R.both(R.identity, R.has('message')), R.prop('message')),
          R.prop('error')
        )(evt);
        return {
          uploadText        : `Error: ${message}`,
          showProgressModal : true,
          uploadError       : true
        };
      }
      const updatePerc = Math.round((evt.loaded * 100) / evt.total);
      return {
        uploadText        : updatePerc >= 100 ? `Moving file into file server. Please wait.` : `${updatePerc}%`,
        showProgressModal : true,
        uploadError       : false
      };
    },
    setProgressModal : () => ({ text, show, error }) => ({
      uploadText        : text,
      showProgressModal : show,
      uploadError       : error
    })
  },),
  withHandlers({
    _deleteDocument : props => id => {
      Promise.resolve(props.setProgressModal({
        text: 'Removing document',
        show: true,
      }))
      .then(() => props.deleteDocument(id))
      .then(() => props.removeDocument(id));
    }
  })
);


export default addEditing(QuestionModal);
