import * as R      from 'ramda';
import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';
// import tinycolor   from 'tinycolor2';

import {
  compose, defaultProps,
  lifecycle,
  setPropTypes,
  withHandlers,
  withProps,
  withStateHandlers
} from 'recompose';

import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import { Typography } from '@material-ui/core';

import WidgetContent from './WidgetContent';
import WidgetContentClone from './WidgetContentClone';
import WidgetHeader  from './WidgetHeader';

import { colors }               from '../../components/Theme';
import withUser                 from '../../modules/auth';
import withClient               from '../../modules/client';
import { fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';

const randomColor = () => '#' + Math.random().toString(16).slice(2, 8);
const yesNoLength = 2;

const withoutEmptyData = data =>  {
  const totalData = data.reduce((acc, cur) => {
    return acc += cur.value;
  }, 0);

  var dataWithNames = data.map(d => (
    {
      ...d,
      name: R.isEmpty(d.name) ? 'N/A' : d.name
    }
  ));

  if(dataWithNames.length === Number(yesNoLength))
  {  
    if(dataWithNames[0].name.includes("Yes") || dataWithNames[0].name.includes("No"))
    {
       dataWithNames = R.sort(R.descend(R.prop('name')), dataWithNames);
    }
  }

  return dataWithNames.filter(d => ((d.value / totalData) * 100) > 0);
};

// const RADIAN = Math.PI / 180;

// const renderCustomizedLabel = (
//   {
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     percent,
//     fill
//   }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 1.02;
//   const x      = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y      = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text
//       style            = {{ fontSize : '1.0em'}}
//       x                = {x}
//       y                = {y}
//       fill             = {tinycolor(fill).isLight() ? 'black' : 'black'}
//       textAnchor       = {x > cx ? 'start' : 'end'}
//       dominantBaseline = "central"
//     >
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };

const renderCustomizedLabelExtented = ({ 
  cx, cy, midAngle, innerRadius, outerRadius, percent, fill, index }) => {
 const RADIAN = Math.PI / 180;
 const sin = Math.sin(RADIAN * midAngle);
 const cos = Math.cos(RADIAN * midAngle );
 const startX = cx + (outerRadius) * cos;
 const startY = cy + (outerRadius) * -sin;
 const middleY = cy + (outerRadius + 10 * Math.abs(sin)) * -sin;
 let endX = startX + (cos >= 0 ? 1 : -1) * ( index % 3 === 1 ? 20 : (index % 2 === 1 ? 50 : 80));
 let textAnchor = cos >= 0 ? 'start' : 'end';
 const mirrorNeeded = midAngle > -270 && midAngle < -180 && percent < 0.09 && index % 2 === 1;
 if (mirrorNeeded) {
   endX = startX + outerRadius * -cos * 2 + 50;
   textAnchor = 'start';
 }

 return (
   <g>
     <path
       d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
       fill="none"
       stroke='#000'
       strokeWidth={1}
     />
     <text
       x={endX + (cos >= 0 || mirrorNeeded ? 1 : -1) * 2}
       y={middleY + 10 / 2}
       textAnchor={textAnchor}
       // fontSize={ '1.0em' }
       fill = 'black'
       fontSize = '1.1em'
     >{`${(percent * 100).toFixed(0)}%`}</text>
   </g>
 );
};

const PieChartWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onViewClick,
    onEditClick,
    onMoveClick,
    onRef,
    onRefclone,
    width,
    onDownloadClick,
    isShowingIcons,
    containerWidth,
    containerHeight,
    isDownloadContainer
  }) => (
  <div>
    
      <WidgetHeader
        title           = {title}
        description     = {description}
        onTitleClick    = {onTitleClick}
        onViewClick     = {onViewClick}
        onEditClick     = {onEditClick}
        onMoveClick     = {onMoveClick}
        onDownloadClick = {onDownloadClick}
        isShowingIcons  = {isShowingIcons}
        isDownload
      />
      <div ref={onRef} style={{ backgroundColor: 'white'}}>
      
      <WidgetContent containerHeight={containerHeight}>
        {widgetContent ? (
          <PieChart
          width  = {isDownloadContainer ? containerWidth : width}
          height = {isDownloadContainer ? containerHeight : 448}
          margin = {{ top : -5, right : 0, left : 0 }}
         >
          <Pie
            data              = {withoutEmptyData(widgetContent.chartData)}
            cx                = {isDownloadContainer ? containerWidth / 2 : width / 2}
            cy                = {isDownloadContainer ? 220 : 160}
            labelLine         = {false}
            label             = {renderCustomizedLabelExtented}
            indexLabelPlacement = {"outside"}
            outerRadius       = {isDownloadContainer ? 200 : 130}
            fill              = "#8884d8"
            dataKey           = "value"
            isAnimationActive = {false}
            legendType        = "square"
          >
            {withoutEmptyData(widgetContent.chartData).map((v, i) => {
              return (
                <Cell
                  key  = {v.name}
                  fill = {colors[i] || randomColor()}
                />
              );
            })}
          </Pie>
          {!isDownloadContainer && <Tooltip content={props => {
            const count = R.compose(R.sum, R.pluck('value'))(widgetContent.chartData);
            return (
              <div style={{ backgroundColor : '#FFF', border : '1px solid #CCC'}}>
                <ul style={{ listStyle : 'none', margin : 0, padding : 0 }}>
                  {
                    props.payload.map((v,i) => (
                      <li key={i} style={{ margin : '5px'}}>
                        <span style={{ color : colors[i] || randomColor()}}>{v.name || 'N/A'}</span>: {`${v.value} out of ${count}`}
                      </li>
                    ))
                  }
                </ul>
              </div>
            );}}/>
          }
          <Legend
            align="left"
            verticalAlign='bottom'           
          />
        </PieChart>
        ) : (
          <Typography paragraph={true} variant="body2">
            No answers...
          </Typography>
        )}
      </WidgetContent>
      <div>
      <div ref={onRefclone}  
       style={{height: 0}}
      >
      {/* <WidgetContentClone containerHeight={containerHeight}> */}
      <WidgetContentClone containerHeight={containerHeight}>
      <div style={{backgroundColor: 'white' , width: '100%'}}>
        {/* <div style={{ paddingTop: '20px', paddingLeft: '40px' }}>
          <text style={{ backgroundColor: 'white', fontSize: '25px', width: '100%' }}> {title} </text>
        </div> */}
         <div style={{ paddingTop: '30px', paddingLeft: '40px' }}>
           <text style={{ backgroundColor: 'white', fontSize: '22px', width: '100%' }}> {description} </text>
        </div>
        {widgetContent ? (
          <PieChart
          width  = {isDownloadContainer ? containerWidth : width}
          height = {isDownloadContainer ? 1000 : 410}
          margin = {{ top : 40, right : 0, left : -344 }}
         >
          <Pie
            data              = {withoutEmptyData(widgetContent.chartData)}
            cx                = {isDownloadContainer ? containerWidth / 2 : width / 2}
            cy                = {isDownloadContainer ? 500 : 160}
            labelLine         = {false}
            label             = {renderCustomizedLabelExtented}
            indexLabelPlacement = {"outside"}
            outerRadius       =  {310}//{isDownloadContainer ? 200 : 130}
            fill              = "#8884d8"
            dataKey           = "value"
            isAnimationActive = {false}
            legendType="square" paddingAngle={1} minAngle={1}            
          >
            {withoutEmptyData(widgetContent.chartData).map((v, i) => {
              return (
                <Cell
                  key  = {v.name}
                  fill = {colors[i] || randomColor()}
                />
              );
            })}
          </Pie>
          {!isDownloadContainer && <Tooltip content={props => {
            const count = R.compose(R.sum, R.pluck('value'))(widgetContent.chartData);
            return (
              <div style={{ backgroundColor : '#FFF', border : '1px solid #CCC'}}>
                <ul style={{ listStyle : 'none', margin : 0, padding : 0 }}>
                  {
                    props.payload.map((v,i) => (
                      <li key={i} style={{ margin : '5px'}}>
                        <span style={{ color : colors[i] || randomColor()}}>{v.name || 'N/A'}</span>: {`${v.value} out of ${count}`}
                      </li>
                    ))
                  }
                </ul>
              </div>
            );}}/>
          }
          <Legend
            layout="vertical" verticalAlign="top" align="right" 
             margin = {{ top : 0, right : 0, left : 0, bottom:400 }}
             wrapperStyle={{ width:660, whiteSpace:"break-spaces", fontSize: '1.20rem' }}            
          />
        </PieChart>
        ) : (
          <Typography paragraph={true} variant="body2">
            No answers...
          </Typography>
        )}
        </div>
      </WidgetContentClone>
      </div>
      </div>
    </div>
  </div>  
);

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload) => dispatch(fetchQuestionAnswers(namespace, payload)),
});

const mapStateToProps = () => ({});

const mapAnswersToQuestions = (answers={}) => storeVisit => {
  const matchingAnswers = answers[storeVisit.id] || [];
  return R.assoc('answers', matchingAnswers)(storeVisit);
};

const maybeParseJson = o => R.tryCatch(JSON.parse, R.always(o))(o);
const isNilOrEmpty = R.either(R.isNil, R.isEmpty);

const countAnswers = R.compose(
  R.countBy(R.identity),
  R.reject(isNilOrEmpty),
  R.flatten,
  R.map(maybeParseJson),
  R.chain(R.pluck('answerValue')),
  R.pluck('answers')
);

const objsOf = R.compose(
  R.mergeAll,
  R.over(R.lensIndex(0), R.objOf('name')),
  R.over(R.lensIndex(1), R.objOf('value'))
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withStateHandlers(null, {
    setWidth  : () => width => ({ width })
  }),
  withHandlers(() => {
    let chart = null;

    return {
      onRef        : ({ setContainerSizes, setDownloadWidgetRef }) => ref => {
        chart = ref;

        setContainerSizes && setContainerSizes(ref);
        setDownloadWidgetRef && setDownloadWidgetRef(ref);
      },
      onRefclone        : ({ setContainerSizes, setDownloadWidgetRefClone }) => ref => {
        chart = ref;

        setContainerSizes && setContainerSizes(ref);
        setDownloadWidgetRefClone && setDownloadWidgetRefClone(ref);
      },
      setDimensions : ({ setWidth }) => () => {
        if (chart && R.is(Function, chart.getBoundingClientRect)) {
          setWidth(chart.getBoundingClientRect().width * 0.9);
        }
      }
    };
  }),
  withProps(({ widgetData : { description, filteredWidgetData, title, metaJson }, answers }) => ({
    description,
    title,
    metaJson,
    widgetContent : R.compose(
      R.objOf('chartData'),
      R.map(objsOf),
      R.sort(R.descend(R.prop(1))),
      R.toPairs,
      countAnswers,
      R.filter(R.compose(R.length, R.prop('answers'))),
      R.map(mapAnswersToQuestions(answers))
    )(filteredWidgetData)
  })),
  defaultProps({
    width          : 350,
    isShowingIcons : true
  }),
  setPropTypes({
    description   : PropTypes.string,
    title         : PropTypes.string,
    widgetData    : PropTypes.object.isRequired,
    onTitleClick  : PropTypes.func,
    onEditClick   : PropTypes.func,
    onViewClick   : PropTypes.func,
    onMoveClick   : PropTypes.func,
    widgetContent : PropTypes.object
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.isDownloadContainer) {
        const questions = this.props.widgetData.formQuestionCanonicalKeys || [];

        this.props.fetchQuestionAnswers(this.props.widgetData.metaJson.namespace, questions)
          .then(res => {
            const answers = R.pathOr([], ['payload', 'data', 'data'], res);
            this.props.onAnswers(answers);

            window.addEventListener('resize', this.props.setDimensions);
            this.props.setDimensions();
          });
      }
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.setDimensions);
    }
  })
)(PieChartWidgetContainer);
