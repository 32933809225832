import * as R from 'ramda';
import React, { Component } from 'react';

import styled from 'styled-components';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox
} from '@material-ui/core';

const CheckboxWrapper = styled.div`
  position: relative;
  margin: 20px;
`;

const CheckboxLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.75rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-top: 50px;
`;

class UpsertDashboardDialog extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isDeleting             : false,
      savedDashboardFormData : {
        title               : R.pathOr('',   ['editingDashboard', 'title'])(props),
        metaJson            : R.pathOr('{}', ['editingDashboard', 'metaJson'])(props),
        widgetIds           : R.pathOr('',   ['editingDashboard', 'widgetIds'])(props),
        restrictTokenUserId : R.pathOr(null, ['editingDashboard', 'restrictTokenUserId'])(props)
      }
    };
  }

  confirmDelete = () => {
    this.setState({ isDeleting : true });
  };

  cancelDelete = () => {
    this.setState({ isDeleting : false });
  };

  render() {
    const {
      savedDashboardFormData : { restrictTokenUserId, title },
      isDeleting,
    } = this.state;

    const {
      open,
      onClose,
      onCancel,
      onConfirm,
      onDelete,
      dialogTitle,
      tokenUserId,
      editingDashboard
    } = this.props;

    return (
      <div>
        <Dialog
          open={isDeleting}
          onClose={this.cancelDelete}
        >
          <DialogTitle id="form-dialog-title">Are you sure?</DialogTitle>
          <DialogContent style={{ justifyContent : 'space-between' }}>
            <Button onClick={this.cancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={onDelete} color="primary">
              Yes
            </Button>
          </DialogContent>
        </Dialog>
        <Dialog
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => {
                this.setState(R.assocPath(
                  ['savedDashboardFormData', 'title'],
                  e.target.value,
                  this.state));
              }}
              label="dashboard name"
              value={title}
              margin="normal"
              name="dashboardTitle"
              id="dashboardTitle"
            />
          </DialogContent>
          <CheckboxWrapper>
            <CheckboxLabel htmlFor="dashboard-restrict-token-user-id-checkbox">
              ONLY VISIBLE TO ME
            </CheckboxLabel>
            <Checkbox
              label="DESCRIPTION"
              value={`${tokenUserId}`}
              id="dashboard-restrict-token-user-id-checkbox"
              name="restrictTokenUserId"
              onChange={() => {
                this.setState(R.assocPath(
                  ['savedDashboardFormData', 'restrictTokenUserId'],
                  restrictTokenUserId ? null : `${tokenUserId}`,
                  this.state));
              }}
              checked={Boolean(parseInt(restrictTokenUserId, 10))}
            />
          </CheckboxWrapper>
          <DialogActions style={{ justifyContent : 'space-between' }}>
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            {
              editingDashboard ? (
                <Button onClick={this.confirmDelete} color="secondary">
                  Delete
                </Button>
              ) : null
            }
            <Button onClick={() => onConfirm(this.state.savedDashboardFormData)} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UpsertDashboardDialog;
