// TODO: clean-up unused/provisional/legacy

const variables = {
  borderRadius: '2px',
  borderRadiusLarge: '5px',
  colors: {
    black: '#231f20',
    gray: '#b3b3b3',
    grayAlt: '#c9cacb',
    grayDark: '#343434',
    grayMedium: '#757575',
    grayLight: '#f1f2f2',
    red: '#cf1f25',
    redLight: '#da888c',
    white: '#f0f1eb',
    whiteAlt: '#fff',
    whiteAlt2: '#f8f8f8',
  },
  font: {
    family: {
      default: "'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif",
    },
    size: {
      h1: '1.38rem',
      h2: '1.09rem',
      h4: '0.8125rem',
      body2: '0.8rem',
    },
    letterSpacing: {
      extended: '0.01em',
      extraExtended: '0.03em',
      extraExtraExtended: '0.07em',
    },
    lineHeight: {
      compressed: 1.4,
      extraCompressed: 1.2,
      extended: 1.8,
      regular: 1.6,
    },
  },
  header: {
    background: 'rgb(243, 33, 46)',
    color: '#fff',
  },
  main: {
    red: 'rgb(243, 33, 46)',
    black: 'rgb(35, 31, 32)',
    white: 'rgb(240, 241, 235)',
  },
  shadows: {
    blockShadow: {
      regular:
        '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    },
  },
  spacing: {
    paddingHeader: '18px 15px 9px 20px',
    xs: '8px',
    sm: '10px',
    md: '18px',
    lg: '28px',
    xl: '40px',
    xxl: '68px',
    xxxl: '82px',
  },
};

export default variables;
