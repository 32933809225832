import moment from 'moment';

const calculateDuration = (actualCheckIn, actualCheckOut) => {
  if (actualCheckIn && actualCheckOut) {
    const checkOut  = moment(actualCheckOut);
    const checkIn   = moment(actualCheckIn);
    const duration  = moment.duration(checkOut.diff(checkIn));
    const diff      = duration.asMinutes().toString().split('.', 1).join() + ' minutes';

    return diff;
  }
};

export default calculateDuration;
