import * as R    from 'ramda';
import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import {
  compose,
  lifecycle,
  setPropTypes,
  withProps, withStateHandlers
} from 'recompose';

import WidgetContent            from './WidgetContent';
import WidgetHeader             from './WidgetHeader';

import { connect }              from 'react-redux';
import withUser                 from '../../modules/auth';
import withClient               from '../../modules/client';
import { fetchSvrResponses } from '../../modules/svr-responses/SvrResponsesActions';

const SVR_RESPONSES_STATUSES = {
  PENDING_SUBMISSION : 0,
  PENDING_REVIEW     : 1,
  IN_REVIEW          : 2,
  APPROVED           : 3,
  REJECTED           : 4,
  RESUBMITTED        : 5
};

const getFontSize = value => {
  if (value < 1000) {
    return '12rem';
  }
  if (value < 10000) {
    return '11rem';
  }
  if (value < 100000) {
    return '8rem';
  }
  if (value < 1000000) {
    return '5rem';
  }
  if (value < 10000000) {
    return '4rem';
  }

  return '3rem';
};

const formatNumber = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const SizedNumericValue = styled.div`
  font-size      : ${({ value }) => getFontSize(value) };
  line-height    : 260px;
  vertical-align : middle;
  margin         : 0 auto;
  padding        : 0;
  text-align     : center;
`;

const PercentageWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onEditClick,
    onMoveClick
  }) => {
    const perc = R.compose(
      Math.round,
      R.multiply(100),
      R.defaultTo(0),
      w => R.divide(R.prop('approved', w), R.prop('total', w))
    )(widgetContent);
    return (
      <div>
        <WidgetHeader
          title        = {title}
          description  = {description}
          onTitleClick = {onTitleClick}
          onEditClick  = {onEditClick}
          onMoveClick  = {onMoveClick}
        />
        <WidgetContent>
          <SizedNumericValue value={perc}>{`${formatNumber(perc)}%`}</SizedNumericValue>
          <h5 style={{verticalAlign: 'middle', margin: '0 auto', padding: 0, textAlign: 'center'}}>Approved</h5>
          {`${R.propOr(0, 'approved', widgetContent)} out of ${R.propOr(0, 'total', widgetContent)}`}
        </WidgetContent>
      </div>
    );
  };

const mapDispatchToProps = dispatch => ({
  fetchSvrResponses : (namespace, filters) => dispatch(fetchSvrResponses(namespace, filters)),
});

const mapStateToProps = () => ({});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withStateHandlers(null, {
    onAnswers : () => answers => (
      { answers : answers }
    )
  }),
  withProps(({ widgetData : { description, filteredWidgetData, title } }) => ({
    description,
    title,
    widgetContent : R.compose(
      R.assoc('total', R.length(filteredWidgetData)),
      R.assoc('approved', R.__, {}),
      R.length,
      R.filter(R.propEq('metaReviewState', SVR_RESPONSES_STATUSES.APPROVED)),
    )(filteredWidgetData)
  })),
  setPropTypes({
    description   : PropTypes.string,
    title         : PropTypes.string,
    widgetData    : PropTypes.object.isRequired,
    onTitleClick  : PropTypes.func,
    onEditClick   : PropTypes.func,
    onMoveClick   : PropTypes.func,
    widgetContent : PropTypes.object
  }),
  lifecycle({
    componentDidMount() {
      const filters = this.props.widgetData.filters || {};

      this.props.fetchSvrResponses(this.props.widgetData.metaJson.namespace, filters)
        .then(res => {
          const answers = R.pathOr([], ['payload', 'data', 'data'], res);
          this.props.onAnswers(answers);
        });
    }
  })
)(PercentageWidgetContainer);
