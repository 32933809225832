import * as R from 'ramda';

import recursivelyPluck         from '../svr/utils/recursivelyPluck';
import ensureNumericTokenUserId from '../../utils/ensureNumericTokenUserId';

const namespace = 'SVR_RESPONSES';

export const FETCH_ALL              = `${namespace}/FETCH_ALL`;
export const FETCH_ALL_SUCCESS      = `${namespace}/FETCH_ALL_SUCCESS`;
export const FETCH_ALL_ERROR        = `${namespace}/FETCH_ALL_ERROR`;
export const FETCH                  = `${namespace}/FETCH`;
export const FETCH_DOCUMENT         = `${namespace}/FETCH_DOCUMENT`;
export const FETCH_DOCUMENTS        = `${namespace}/FETCH_DOCUMENTS`;
export const FETCH_PHOTO_QUESTIONS  = `${namespace}/FETCH_PHOTO_QUESTIONS`;
export const RESET_PHOTOS_REDUCER   = `${namespace}/RESET_PHOTOS_REDUCER`;
export const FETCH_MEDIA_QUESTIONS  = `${namespace}/FETCH_MEDIA_QUESTIONS`;
export const RESET_MEDIA_REDUCER    = `${namespace}/RESET_MEDIA_REDUCER`;
export const FETCH_VIDEO_QUESTIONS  = `${namespace}/FETCH_VIDEO_QUESTIONS`;
export const RESET_VIDEO_REDUCER    = `${namespace}/RESET_VIDEO_REDUCER`;
export const LOAD                   = `${namespace}/LOAD`;
export const DELETE                 = `${namespace}/DELETE`;
export const UPDATE                 = `${namespace}/UPDATE`;
export const CREATE_FORM_ANSWER     = `${namespace}/CREATE_FORM_ANSWER`;
export const FETCH_FORM_ANSWERS     = `${namespace}/FETCH_FORM_ANSWERS`;
export const UPDATE_FORM_ANSWER     = `${namespace}/UPDATE_FORM_ANSWER`;
export const FILTER_BY_ACTION       = `${namespace}/FILTER_BY_ACTION`;
export const SHOW_SEARCH_FILTER     = `${namespace}/SHOW_SEARCH_FILTER`;
export const TRANSFORM_DATA         = `${namespace}/TRANSFORM_DATA`;
export const CREATE_SAVED_VIEW      = `${namespace}/CREATE_SAVED_VIEW`;
export const CREATE_SAVED_ROLLUP    = `${namespace}/CREATE_SAVED_ROLLUP`;
export const CREATE_SAVED_WIDGET    = `${namespace}/CREATE_SAVED_WIDGET`;
export const SELECT_NAVIGATE_FILTER = `${namespace}/SELECT_NAVIGATE_FILTER`;
export const SET_QUESTIONS          = `${namespace}/SET_QUESTIONS`;
export const CLEAR_CURRENT_DATA     = `${namespace}/CLEAR_CURRENT_DATA`;

export const fetchSvrResponses = (namespace='svrResponses', filters={}, fields = [], groupMultiDaySvrs=false, sortandcolors=false) => dispatch => {
 
  if (sortandcolors){
    window.localStorage.setItem("displaycolors", 'YES');
  }
  else{
    window.localStorage.setItem("displaycolors", 'NO');
  }
 
  if (R.isEmpty(filters)) {
    return dispatch({
      types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
      payload : {
        namespace,
        request : {
          method : 'GET',
          url    : '/api/v2/storeVisit'
        }
      }
    });
  } else {
    return dispatch({
      types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
      payload : {
        namespace,
        request : {
          method : 'POST',
          url    : '/api/v2/storeVisit/userStoreVisits',
          data   : { filters, fields, groupMultiDaySvrs }
        }
      }
    });
  }
};

export const fetchSvrResponse = (namespace, payload) => dispatch => dispatch({
  type    : FETCH,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/storeVisit/id/${payload}`
    }
  }
});

export const fetchPhotoQuestions = (namespace, formAnswers, _storeVisitIds, _formIds) => (dispatch, getState) => {

  const state           = getState();
  const storeVisitIds   = R.is(Array, _storeVisitIds) ? _storeVisitIds : [_storeVisitIds];

  const formIds         = _formIds || R.compose(
    R.uniq,
    R.filter(R.identity),
    R.map(id => {
      const svrs = R.values(R.pathOr(null, ['svrResponses', namespace, 'data'])(state));
      return R.propOr(null, 'formId', svrs.find(s => s.id === id));
    }),
  )(storeVisitIds);

  if (formIds.length) {
    return dispatch({
      type    : FETCH_PHOTO_QUESTIONS,
      payload : {
        storeVisitIds,
        formAnswers : R.compose(R.flatten, R.values)(formAnswers),
        namespace,
        request : {
          method : 'GET',
          url    : `/api/v2/form/ids/${formIds}`
        }
      }
    }).then(res => {

      const data = R.pathOr([], ['payload', 'data', 'data'])(res);

      const photoQuestions = R.compose(
        R.indexBy(R.prop('uuid')),
        R.filter(R.propEq('type', 'photo-input')),
        R.flatten,
        R.map(recursivelyPluck('content')),
        R.flatten,
        R.map(R.pathOr({}, ['content', 'structure']))
      )(data);

      return Promise.resolve(formAnswers)
        .then(R.filter(a => a.answerValue && photoQuestions[a.questionUuid]))
        .then(R.pluck('answerValue'))
        .then(uuids => {
          if (uuids.length) {
            return dispatch({
              type    : FETCH_DOCUMENTS,
              payload : {
                namespace,
                request : {
                  method : 'POST',
                  url    : `/api/v2/document/uuids`,
                  data   : { uuids }
                }
              }
            });
          } else {
            return undefined;
          }
        });
    });
  }
};

export const fetchVideoQuestions = (namespace, formAnswers, _storeVisitIds, _formIds) => (dispatch, getState) => {

  const state           = getState();
  const storeVisitIds   = R.is(Array, _storeVisitIds) ? _storeVisitIds : [_storeVisitIds];

  const formIds         = _formIds || R.compose(
    R.uniq,
    R.filter(R.identity),
    R.map(id => R.pathOr(null, ['svrResponses', namespace, 'data', id, 'formId'])(state))
  )(storeVisitIds);

  if (formIds.length) {
    return dispatch({
      type    : FETCH_VIDEO_QUESTIONS,
      payload : {
        storeVisitIds,
        formAnswers : R.compose(R.flatten, R.values)(formAnswers),
        namespace,
        request : {
          method : 'GET',
          url    : `/api/v2/form/ids/${formIds}`
        }
      }
    }).then(res => {

      const data = R.pathOr([], ['payload', 'data', 'data'])(res);

      const videoQuestions = R.compose(
        R.indexBy(R.prop('uuid')),
        R.filter(R.propEq('type', 'video-input')),
        R.flatten,
        R.map(recursivelyPluck('content')),
        R.flatten,
        R.map(R.pathOr({}, ['content', 'structure']))
      )(data);

      return Promise.resolve(formAnswers)
        .then(R.filter(a => a.answerValue && videoQuestions[a.questionUuid]))
        .then(R.pluck('answerValue'))
        .then(uuids => {
          if (uuids.length) {
            return dispatch({
              type    : FETCH_DOCUMENTS,
              payload : {
                namespace,
                request : {
                  method : 'POST',
                  url    : `/api/v2/document/uuids`,
                  data   : { uuids }
                }
              }
            });
          } else {
            return undefined;
          }
        });
    });
  }
};

export const fetchMediaQuestions = (namespace, formAnswers, _storeVisitIds, _formIds) => (dispatch, getState) => {
  const state           = getState();
  const storeVisitIds   = R.is(Array, _storeVisitIds) ? _storeVisitIds : [_storeVisitIds];

  const formIds         = _formIds || R.compose(
    R.uniq,
    R.filter(R.identity),
    R.map(id => R.pathOr(null, ['svrResponses', namespace, 'data', id, 'formId'])(state))
  )(storeVisitIds);

  if (formIds.length) {
    return dispatch({
      type    : FETCH_MEDIA_QUESTIONS,
      payload : {
        storeVisitIds,
        formAnswers : R.compose(R.flatten, R.values)(formAnswers),
        namespace,
        request : {
          method : 'GET',
          url    : `/api/v2/form/ids/${formIds}`
        }
      }
    }).then(res => {

      const data = R.pathOr([], ['payload', 'data', 'data'])(res);

      const mediaQuestions = R.compose(
        R.indexBy(R.prop('uuid')),
        R.filter(R.anyPass([R.propEq('type', 'signature-input'), R.propEq('type', 'photo-input'), R.propEq('type', 'video-input')])),
        R.flatten,
        R.map(recursivelyPluck('content')),
        R.flatten,
        R.map(R.pathOr({}, ['content', 'structure']))
      )(data);

      return Promise.resolve(formAnswers)
        .then(R.filter(a => a.answerValue && mediaQuestions[a.questionUuid]))
        .then(R.pluck('answerValue'))
        .then(uuids => {
          if (uuids.length) {
            return dispatch({
              type    : FETCH_DOCUMENTS,
              payload : {
                namespace,
                request : {
                  method : 'POST',
                  url    : `/api/v2/document/uuids`,
                  data   : { uuids }
                }
              }
            });
          } else {
            return undefined;
          }
        });
    });
  }

};

export const resetPhotosReducer = namespace => ({
  type    : RESET_PHOTOS_REDUCER,
  payload : { namespace}
});

export const updateSvrResponse = (namespace, svrResponseId, data) => ({
  type    : UPDATE,
  payload : {
    namespace,
    request : {
      method : 'PUT',
      url    : `/api/v2/storeVisit/storevisitid/${svrResponseId}`,
      data
    }
  }
});

export const resetSvrResponse = (namespace, svrResponseId) => ({
  type    : UPDATE,
  payload : {
    namespace,
    request : {
      method : 'POST',
      url    : `/api/v2/storeVisit/id/${svrResponseId}/reset`
    }
  }
});

export const deleteSvrResponse = storeVisitId => ({
  type: DELETE,
  payload : {
    storeVisitId,
    request : {
      method : 'DELETE',
      url    : `/api/v2/storeVisit/id/${storeVisitId}`
    }
  }
});

export const fetchFormAnswers = (namespace, payload) => ({
  type: FETCH_FORM_ANSWERS,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/storeVisitFormAnswer/formAnswerstoreVisitId/${payload}`
    }
  }
});

export const createFormAnswer = payload => ({
  type    : CREATE_FORM_ANSWER,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/storeVisitFormAnswer/answer',
      data   : payload
    }
  }
});

export const createSavedRollup = payload => ({
  type    : CREATE_SAVED_ROLLUP,
  payload : {
    request: {
      method : 'POST',
      url    : '/api/v2/savedRollup',
      data   : ensureNumericTokenUserId(payload)
    }
  }
});

export const createSavedView = payload => ({
  type    : CREATE_SAVED_VIEW,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/savedView',
      data   : ensureNumericTokenUserId(payload)
    }
  }
});

export const createSavedWidget = payload => ({
  type    : CREATE_SAVED_WIDGET,
  payload : {
    request: {
      method : 'POST',
      url    : '/api/v2/savedWidget',
      data   : ensureNumericTokenUserId(payload)
    }
  }
});


export const updateFormAnswer = (namespace, answerValue, answerId) => ({
  type    : UPDATE_FORM_ANSWER,
  payload : {
    namespace,
    request : {
      method : 'PUT',
      url    : `/api/v2/storeVisitFormAnswer/id/${answerId}`,
      data   : {
        answerValue
      }
    }
  }
});

export const filterByAction = (namespace, filters) => ({
  type    : FILTER_BY_ACTION,
  payload : { namespace, filters }
});

export const transformData = (orderBy, order, sortType, filterList, data, namespace='svrResponses', tableColumns = []) => ({
  type    : TRANSFORM_DATA,
  payload : { orderBy, order, sortType, filterList, data, namespace, tableColumns }
});

export const setShowSearchFilter = bool => ({
  type    : SHOW_SEARCH_FILTER,
  payload : bool
});

export const selectNavigateFilter = payload => ({
  type: SELECT_NAVIGATE_FILTER,
  payload
});

export const setQuestions = (namespace, data) => ({
  type: SET_QUESTIONS,
  payload : {
    namespace,
    data
  }
});

export const clearSvrResponses = (namespace='svrResponses') => ({
  type    : CLEAR_CURRENT_DATA,
  payload : { namespace}
});
