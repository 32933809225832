import * as R from 'ramda';
import React, { Component } from 'react';
import styled from 'styled-components';
import { ArrowDropDown } from '@material-ui/icons';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Checkbox
} from '@material-ui/core';
import { additionalcolumns } from './Additonalcolumnsdata';
import Modal from '../../../components/Modal/PModal';

const SectionRow = styled.div`
  margin           : 2rem 0 0 0;
  padding          : .2rem;
  background-color : #E4E4E5;
  border-bottom    : 2px solid #DCDCDC;
  display          : flex;
  align-items      : center;
  color            : #909091;
  font-size        : .9rem;
  font-weight      : bold;
  cursor           : pointer;
`;

const CheckboxWrapper = styled.div`
  position: relative; 
`;

const CheckboxLabel = styled.label`
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 0.85rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  margin-top: 15px;
`;

class ExportColumns extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isCommonheaders: false,
      isPhotoQuestion: false,
      isYesnoQuestion: false,
      isFeedbackQuestion: false,
      isOtherQuestion: false,
      isAdditionalexcelcolumn: false,
      commonheadersSearchdata: null,
      photoquestionsSearchdata: null,
      yesnoquestionSearchdata: null,
      feedbackquestionsSearchdata: null,
      otherquestionSearchdata: null,
      additionalexcelcolumnSearchdata: null,
      selectAll: true,
      arrayallquestions: props.allQuestions,
      arraySelectedquestions: props.allQuestions,
      arrayunSelectedquestions: [],
      selectAllcommon: true,
      selectAllphoto: true,
      selectAllyesno: true,
      selectAllfeedback: true,
      selectAllotherquestion: true,
      selectAlladditionalcolumn: true,
      showErrorModal: false,
      errorModalTitle: ''
    };
  }

  confirmcolumns = () => {
    if (this.props.isClientUser === "client") {
      if (additionalcolumns.length > 0) {
        additionalcolumns.forEach(item => {
          this.state.arrayunSelectedquestions.push(item);
        });
      }
    }

    this.setState({ arrayunSelectedquestions: this.state.arrayunSelectedquestions });
    window.localStorage.setItem('Excelconfirmcolumns', JSON.stringify(this.state.arrayunSelectedquestions));
  };

  commonheadersClick = () => {
    if (this.state.isCommonheaders)
      this.setState({ isCommonheaders: false, commonheadersSearchdata: "" });
    else
      this.setState({ isCommonheaders: true });
  };

  photoquestionsClick = () => {
    if (this.state.isPhotoQuestion)
      this.setState({ isPhotoQuestion: false, photoquestionsSearchdata: "" });
    else
      this.setState({ isPhotoQuestion: true });
  };

  yesnoquestionsClick = () => {
    if (this.state.isYesnoQuestion)
      this.setState({ isYesnoQuestion: false, yesnoquestionSearchdata: "" });
    else
      this.setState({ isYesnoQuestion: true });
  };

  feedbackQuestionClick = () => {
    if (this.state.isFeedbackQuestion)
      this.setState({ isFeedbackQuestion: false, feedbackquestionsSearchdata: "" });
    else
      this.setState({ isFeedbackQuestion: true });
  };

  otherQuestionClick = () => {
    if (this.state.isOtherQuestion)
      this.setState({ isOtherQuestion: false, otherquestionSearchdata: "" });
    else
      this.setState({ isOtherQuestion: true });
  }

  additionalColumnClick = () => {
    if (this.state.isAdditionalexcelcolumn)
      this.setState({ isAdditionalexcelcolumn: false, additionalexcelcolumnSearchdata: "" });
    else
      this.setState({ isAdditionalexcelcolumn: true });
  }

  HandleSearchCommonheaders = commonheaders => (event) => {
    var results = [];
    results = commonheaders.filter((x) =>
      x.label.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      commonheadersSearchdata: results
    });
  };

  HandleCheckheaders = (event, headers) => {
    if (event.target === null)
      return;
    if (event.target.checked) {
      if (this.state.arraySelectedquestions !== undefined) {
        const index = this.state.arraySelectedquestions.indexOf(headers);
        if (index === -1) {
          this.state.arraySelectedquestions.push(headers);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions
          });
        }
      }
      if (this.state.arrayunSelectedquestions !== undefined) {
        const indexs = this.state.arrayunSelectedquestions.indexOf(headers);
        if (indexs > -1) {
          this.state.arrayunSelectedquestions.splice(indexs, 1);
          this.setState(
            {
              arrayunSelectedquestions: this.state.arrayunSelectedquestions
            }
          )
        }
      }
    }
    else {
      if (this.state.arraySelectedquestions !== undefined) {
        const index = this.state.arraySelectedquestions.indexOf(headers);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions
          });
        }
      }
      if (this.state.arrayunSelectedquestions !== undefined) {
        const indexs = this.state.arrayunSelectedquestions.indexOf(headers);
        if (indexs === -1) {
          this.state.arrayunSelectedquestions.push(headers);
          this.setState(
            {
              arrayunSelectedquestions: this.state.arrayunSelectedquestions
            }
          )
        }
      }
    }
  }

  HandleSearchPhotoquestions = photoquestions => (event) => {
    var results = [];
    results = photoquestions.filter((x) =>
      x.title.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      photoquestionsSearchdata: results
    });
  };

  HandleSearchYesnoquestions = yesnoquestions => (event) => {
    var results = [];
    results = yesnoquestions.filter((x) =>
      x.title.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      yesnoquestionSearchdata: results
    });
  };

  HandleSearchFeedbackquestions = feedbackquestions => (event) => {
    var results = [];
    results = feedbackquestions.filter((x) =>
      x.title.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      feedbackquestionsSearchdata: results
    });
  };

  HandleSearchOtherquestions = otherquestions => (event) => {
    var results = [];
    results = otherquestions.filter((x) =>
      x.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      otherquestionSearchdata: results
    });
  };

  HandleSearchAdditionalexcelsearch = additionalcolumnsexcel => (event) => {
    var results = [];
    results = additionalcolumnsexcel.filter((x) =>
      x.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      additionalexcelcolumnSearchdata: results
    });
  };

  HandleSelectall = event => {
    if (event.target === null)
      return;

    if (event.target.checked) {
      this.setState({
        arrayunSelectedquestions: [],
        arraySelectedquestions: this.state.allQuestions,
        selectAll: true
      });
    }
    else {
      this.setState({
        arrayunSelectedquestions: this.state.allQuestions,
        arraySelectedquestions: [],
        selectAll: false
      });
    }
  };



  HandleSelectallCommon = commonheaders => (event) => {
    if (event.target === null)
      return;

    if (event.target.checked) {
      commonheaders.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.label);
        if (index === -1) {
          this.state.arraySelectedquestions.push(item.label);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllcommon: true
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.label);
          if (indexs > -1) {
            this.state.arrayunSelectedquestions.splice(indexs, 1);
          }
        }

      });
    }
    else {
      commonheaders.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.label);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllcommon: false
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.label);
          if (indexs === -1) {
            this.state.arrayunSelectedquestions.push(item.label);
          }
        }
      });
    }
  };

  HandleSelectallphoto = photoquestions => (event) => {
    if (event.target === null)
      return;

    if (event.target.checked) {
      photoquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.title);
        if (index === -1) {
          this.state.arraySelectedquestions.push(item.title);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllphoto: true
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.title);
          if (indexs > -1) {
            this.state.arrayunSelectedquestions.splice(indexs, 1);
          }
        }
      });
    }
    else {
      photoquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.title);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllphoto: false
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.title);
          if (indexs === -1) {
            this.state.arrayunSelectedquestions.push(item.title);
          }
        }

      });
    }
  };


  HandleSelectallyesno = Yesornoquestions => (event) => {

    if (event.target === null)
      return;

    if (event.target.checked) {
      Yesornoquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.title);
        if (index === -1) {
          this.state.arraySelectedquestions.push(item.title);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllyesno: true
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.title);
          if (indexs > -1) {
            this.state.arrayunSelectedquestions.splice(indexs, 1);
          }
        }

      });
    }
    else {
      Yesornoquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.title);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllyesno: false
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.title);
          if (indexs === -1) {
            this.state.arrayunSelectedquestions.push(item.title);
          }
        }
      });
    }

  };


  HandleSelectallFeedback = Feedbackquestions => (event) => {
    if (event.target === null)
      return;

    if (event.target.checked) {
      Feedbackquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.title);
        if (index === -1) {
          this.state.arraySelectedquestions.push(item.title);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllfeedback: true
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.title);
          if (indexs > -1) {
            this.state.arrayunSelectedquestions.splice(indexs, 1);
          }
        }
      });
    }
    else {
      Feedbackquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item.title);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllfeedback: false
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item.title);
          if (indexs === -1) {
            this.state.arrayunSelectedquestions.push(item.title);
          }
        }

      });
    }
  };

  HandleSelectallOtherquestions = otherquestions => (event) => {
    if (event.target === null)
      return;

    if (event.target.checked) {
      otherquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item);
        if (index === -1) {
          this.state.arraySelectedquestions.push(item);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllotherquestion: true
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item);
          if (indexs > -1) {
            this.state.arrayunSelectedquestions.splice(indexs, 1);
          }
        }
      });
    }
    else {
      otherquestions.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAllotherquestion: false
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item);
          if (indexs === -1) {
            this.state.arrayunSelectedquestions.push(item);
          }
        }
      });
    }
  };


  HandleSelectalladditionalcolumns = additionalcolumnsexcel => (event) => {
    if (event.target === null)
      return;

    if (event.target.checked) {
      additionalcolumnsexcel.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item);
        if (index === -1) {
          this.state.arraySelectedquestions.push(item);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAlladditionalcolumn: true
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item);
          if (indexs > -1) {
            this.state.arrayunSelectedquestions.splice(indexs, 1);
          }
        }
      });
    }
    else {
      additionalcolumnsexcel.forEach(item => {
        const index = this.state.arraySelectedquestions.indexOf(item);
        if (index > -1) {
          this.state.arraySelectedquestions.splice(index, 1);
          this.setState({
            arraySelectedquestions: this.state.arraySelectedquestions,
            selectAlladditionalcolumn: false
          });
        }

        if (this.state.arrayunSelectedquestions !== undefined) {
          const indexs = this.state.arrayunSelectedquestions.indexOf(item);
          if (indexs === -1) {
            this.state.arrayunSelectedquestions.push(item);
          }
        }
      });
    }
  };

  validateEmbedadData = () => {
    this.setState({
      showErrorModal: true,
      errorModalTitle: "This export has more than 4,000 photos. The photos will be replaced with links to them."
    });
  };

  render() {
    const {
      isCommonheaders,
      isPhotoQuestion,
      isYesnoQuestion,
      isFeedbackQuestion,
      isOtherQuestion,
      isAdditionalexcelcolumn
    } = this.state;

    const {
      open,
      onClose,
      onCancel,
      onConfirm,
      dialogTitle,
      tableColumns,
      questions,
      title
    } = this.props;

    const commonquestions = R.compose(
      R.filter(
        R.compose(
          R.not,
          R.propEq('label', 'Report Number')
        )
      ),
      R.filter(
        R.compose(
          R.not,
          R.propEq('label', 'Exclude Client Name')
        )
      ),
      R.filter(
        R.compose(
          R.not,
          R.propEq('label', 'Exclude Project Name')
        )
      )
    )(tableColumns);

    var photoquestions = [];
    var Yesornoquestions = [];
    var Feedbackquestions = [];

    try {
      photoquestions = R.compose(
        R.filter(
          R.compose(
            R.contains('photo-input'),
            R.prop('type')
          )
        )
      )(questions);

      Yesornoquestions = R.compose(
        R.filter(
          R.compose(
            R.contains('toggle-input'),
            R.prop('type')
          )
        )
      )(questions);


      Feedbackquestions = R.compose(
        R.filter(
          R.compose(
            R.contains('feedback'),
            R.prop('title')
          )
        ),
        R.filter(
          R.compose(
            R.contains('multi-line-input'),
            R.prop('type')
          )
        )
      )(questions);
    }
    catch
    {
      questions.forEach(item => {
        if (item.title !== undefined && item.title !== null && item.title.toLowerCase().includes("feedback")) {
          Feedbackquestions.push(item);
        }
      });
    }   

    const othertypequestions = [];
    const dynamicquestions = [];
    const additionalcolumnsexcel = [];

    photoquestions.forEach(item => {
      dynamicquestions.push(item.title)
    });

    Yesornoquestions.forEach(item => {
      dynamicquestions.push(item.title)
    });

    Feedbackquestions.forEach(item => {
      dynamicquestions.push(item.title)
    });

    questions.forEach(item => {
      const index = dynamicquestions.indexOf(item.title);
      if (index === -1) {
        othertypequestions.push(item.title);
      }
    });

    additionalcolumns.forEach(item => {
      additionalcolumnsexcel.push(item);
    }
    );

    return (
      <div>
         {this.state.showErrorModal &&
                <Modal
                  withoutCancel = {false}
                  logoutText = ''
                  onApprove={(e) => {this.setState({ showErrorModal: false, errorModalTitle: '' }); this.confirmcolumns(e); onConfirm(e)}}
                  onCancel={(e) => this.setState({ showErrorModal: false, errorModalTitle: '' }) }
                  >                    
                  {this.state.errorModalTitle}
                </Modal>
              }
        <Dialog
          maxWidth='md'
          fullWidth='true'
          open={open}
          onClose={onClose}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{dialogTitle} - {title}</DialogTitle>
          <DialogContent>
            <div style={{ display: 'none' }}>
              <Checkbox
                label={`lblSelectall`}
                value={`valSelectall`}
                id={`idSelectall`}
                name={`nameSelectall`}
                defaultChecked
                onChange={(e) => this.HandleSelectall(e)}
              />
              <CheckboxLabel hidden style={{ fontSize: '1.0rem' }} htmlFor={`id-Selectall`}>
                Select All
              </CheckboxLabel>
            </div>
            <SectionRow onClick={this.commonheadersClick}>Common Headers<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
            {
              (isCommonheaders && commonquestions.length > 0) ? (
                <div>
                  <div>
                    <Checkbox
                      label={`lblSelectallcommon`}
                      value={`valSelectallcommon`}
                      id={`idSelectallcommon`}
                      name={`nameSelectallcommon`}
                      checked={this.state.selectAllcommon}
                      onChange={(e) => this.HandleSelectallCommon(commonquestions)(e)}
                    />
                    <CheckboxLabel style={{ fontSize: '1.0rem' }} htmlFor={`id-Selectallcommon`}>
                      Select All
                    </CheckboxLabel>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CheckboxWrapper>
                        <div style={{ marginLeft: '20px' }}>
                          <TextField
                            style={{ minWidth: '500px' }}
                            type="search"
                            placeholder='Search'
                            margin="normal"
                            name="commonHeaders"
                            id="commonHeaders"
                            onChange={(e) => this.HandleSearchCommonheaders(commonquestions)(e)}
                          />
                        </div>
                        {
                          R.map((commonheaders) => (
                            <div >
                              <Checkbox key={commonheaders.label}
                                label={`lbl${commonheaders.label}`}
                                value={commonheaders.label}
                                id={`id${commonheaders.label}`}
                                name={`name${commonheaders.label}`}
                                checked={this.state.arraySelectedquestions.includes(commonheaders.label)}
                                onChange={(e) => this.HandleCheckheaders(e, commonheaders.label)}
                              />
                              <CheckboxLabel htmlFor={`id-${commonheaders.label}`}>
                                {commonheaders.label}
                              </CheckboxLabel>
                            </div>
                          ), (this.state.commonheadersSearchdata === null || this.state.commonheadersSearchdata.length === 0) ? commonquestions : this.state.commonheadersSearchdata)
                        }
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              ) : (isCommonheaders && commonquestions.length === 0 ? <div> <h5 style={{ opacity: "0.8" }} >No related questions found</h5> </div> : null)
            }
            <SectionRow onClick={this.photoquestionsClick}>Photo Questions<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
            {
              isPhotoQuestion && photoquestions.length > 0 ? (
                <div>
                  <div>
                    <Checkbox
                      label={`lblSelectallphoto`}
                      value={`valSelectallphoto`}
                      id={`idSelectallphoto`}
                      name={`nameSelectallphoto`}
                      checked={this.state.selectAllphoto}
                      onChange={(e) => this.HandleSelectallphoto(photoquestions)(e)}
                    />
                    <CheckboxLabel style={{ fontSize: '1.0rem' }} htmlFor={`id-Selectallphoto`}>
                      Select All
                    </CheckboxLabel>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CheckboxWrapper>
                        <div style={{ marginLeft: '20px' }}>
                          <TextField
                            style={{ minWidth: '500px' }}
                            type="search"
                            placeholder='Search'
                            margin="normal"
                            name="photoQuestions"
                            id="photoQuestions"
                            onChange={(e) => this.HandleSearchPhotoquestions(photoquestions)(e)}
                          />
                        </div>
                        {
                          R.map(photoheaders => (
                            <div>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  label={`lbl${photoheaders.title}`}
                                  value={`val${photoheaders.title}`}
                                  id={`id${photoheaders.title}`}
                                  name={`name${photoheaders.title}`}
                                  checked={this.state.arraySelectedquestions.includes(photoheaders.title)}
                                  onChange={(e) => this.HandleCheckheaders(e, photoheaders.title)}
                                />
                                <CheckboxLabel htmlFor={`id-${photoheaders.title}`}>
                                  {photoheaders.title}
                                </CheckboxLabel>
                              </div>
                            </div>
                          ), (this.state.photoquestionsSearchdata === null || this.state.photoquestionsSearchdata.length === 0) ? photoquestions : this.state.photoquestionsSearchdata)
                        }
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              ) : (isPhotoQuestion && photoquestions.length === 0 ? <div> <h5 style={{ opacity: "0.8" }} >No related questions found</h5> </div> : null)
            }

            <SectionRow onClick={this.yesnoquestionsClick} >Yes/No Questions<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
            {
              isYesnoQuestion && Yesornoquestions.length > 0 ? (
                <div>
                  <div>
                    <Checkbox
                      label={`lblSelectallyesno`}
                      value={`valSelectallyesno`}
                      id={`idSelectallyesno`}
                      name={`nameSelectallyesno`}
                      checked={this.state.selectAllyesno}
                      onChange={(e) => this.HandleSelectallyesno(Yesornoquestions)(e)}
                    />
                    <CheckboxLabel style={{ fontSize: '1.0rem' }} htmlFor={`id-Selectallyesno`}>
                      Select All
                    </CheckboxLabel>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CheckboxWrapper>
                        <div style={{ marginLeft: '20px' }}>
                          <TextField
                            style={{ minWidth: '500px' }}
                            type="search"
                            placeholder='Search'
                            margin="normal"
                            name="yesNo"
                            id="yesNo"
                            onChange={(e) => this.HandleSearchYesnoquestions(Yesornoquestions)(e)}
                          />
                        </div>
                        {
                          R.map(yesnoheaders => (
                            <div>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  label={`lbl${yesnoheaders.title}`}
                                  value={`val${yesnoheaders.title}`}
                                  id={`id${yesnoheaders.title}`}
                                  name={`name${yesnoheaders.title}`}
                                  checked={this.state.arraySelectedquestions.includes(yesnoheaders.title)}
                                  onChange={(e) => this.HandleCheckheaders(e, yesnoheaders.title)}
                                />
                                <CheckboxLabel htmlFor={`id-${yesnoheaders.title}`}>
                                  {yesnoheaders.title}
                                </CheckboxLabel>
                              </div>
                            </div>
                          ), (this.state.yesnoquestionSearchdata === null || this.state.yesnoquestionSearchdata.length === 0) ? Yesornoquestions : this.state.yesnoquestionSearchdata)
                        }
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              ) : (isYesnoQuestion && Yesornoquestions.length === 0 ? <div> <h5 style={{ opacity: "0.8" }} >No related questions found</h5> </div> : null)
            }
            <SectionRow onClick={this.feedbackQuestionClick} >Feedback Questions<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
            {
              isFeedbackQuestion && Feedbackquestions.length > 0 ? (
                <div>
                  <div>
                    <Checkbox
                      label={`lblSelectallfeedback`}
                      value={`valSelectallfeedback`}
                      id={`idSelectallfeedback`}
                      name={`nameSelectallfeedback`}
                      checked={this.state.selectAllfeedback}
                      onChange={(e) => this.HandleSelectallFeedback(Feedbackquestions)(e)}
                    />
                    <CheckboxLabel style={{ fontSize: '1.0rem' }} htmlFor={`id-Selectallfeedback`}>
                      Select All
                    </CheckboxLabel>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CheckboxWrapper>
                        <div style={{ marginLeft: '20px' }}>
                          <TextField
                            style={{ minWidth: '500px' }}
                            type="search"
                            placeholder='Search'
                            margin="normal"
                            name="feedBack"
                            id="feedBack"
                            onChange={(e) => this.HandleSearchFeedbackquestions(Feedbackquestions)(e)}
                          />
                        </div>
                        {
                          R.map(feedbackheaders => (
                            <div>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  label={`lbl${feedbackheaders.title}`}
                                  value={`val${feedbackheaders.title}`}
                                  id={`id${feedbackheaders.title}`}
                                  name={`name${feedbackheaders.title}`}
                                  checked={this.state.arraySelectedquestions.includes(feedbackheaders.title)}
                                  onChange={(e) => this.HandleCheckheaders(e, feedbackheaders.title)}
                                />
                                <CheckboxLabel htmlFor={`id-${feedbackheaders.title}`}>
                                  {feedbackheaders.title}
                                </CheckboxLabel>
                              </div>
                            </div>
                          ), (this.state.feedbackquestionsSearchdata === null || this.state.feedbackquestionsSearchdata.length === 0) ? Feedbackquestions : this.state.feedbackquestionsSearchdata)
                        }
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              ) : (isFeedbackQuestion && Feedbackquestions.length === 0 ? <div> <h5 style={{ opacity: "0.8" }} >No related questions found</h5> </div> : null)
            }
            <SectionRow onClick={this.otherQuestionClick} >Other Questions<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
            {
              isOtherQuestion && othertypequestions.length > 0 ? (
                <div>
                  <div>
                    <Checkbox
                      label={`lblSelectallother`}
                      value={`valSelectallother`}
                      id={`idSelectallother`}
                      name={`nameSelectallother`}
                      checked={this.state.selectAllotherquestion}
                      onChange={(e) => this.HandleSelectallOtherquestions(othertypequestions)(e)}
                    />
                    <CheckboxLabel style={{ fontSize: '1.0rem' }} htmlFor={`id-Selectallother`}>
                      Select All
                    </CheckboxLabel>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CheckboxWrapper>
                        <div style={{ marginLeft: '20px' }}>
                          <TextField
                            style={{ minWidth: '500px' }}
                            type="search"
                            placeholder='Search'
                            margin="normal"
                            name="othertype"
                            id="othertype"
                            onChange={(e) => this.HandleSearchOtherquestions(othertypequestions)(e)}
                          />
                        </div>
                        {
                          R.map(otherquestionheaders => (
                            <div>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  label={`lbl${otherquestionheaders}`}
                                  value={`val${otherquestionheaders}`}
                                  id={`id${otherquestionheaders}`}
                                  name={`name${otherquestionheaders}`}
                                  checked={this.state.arraySelectedquestions.includes(otherquestionheaders)}
                                  onChange={(e) => this.HandleCheckheaders(e, otherquestionheaders)}
                                />
                                <CheckboxLabel htmlFor={`id-${otherquestionheaders}`}>
                                  {otherquestionheaders}
                                </CheckboxLabel>
                              </div>
                            </div>
                          ), (this.state.otherquestionSearchdata === null || this.state.otherquestionSearchdata.length === 0) ? othertypequestions : this.state.otherquestionSearchdata)
                        }
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              ) : (isOtherQuestion && othertypequestions.length === 0 ? <div> <h5 style={{ opacity: "0.8" }} >No related questions found</h5> </div> : null)
            }
            {
              this.props.isClientUser === "client"
                ?
                <SectionRow style={{ display: 'none' }} onClick={this.additionalColumnClick} >Additional Excel Columns<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
                : <SectionRow onClick={this.additionalColumnClick} >Additional Excel Columns<ArrowDropDown style={{ marginLeft: 'auto' }} /></SectionRow>
            }
            {
              isAdditionalexcelcolumn && additionalcolumnsexcel.length > 0 ? (
                <div>
                  <div>
                    <Checkbox
                      label={`lblSelectallAdditionalcolumn`}
                      value={`valSelectallAdditionalcolumn`}
                      id={`idSelectallAdditionalcolumn`}
                      name={`nameSelectallAdditionalcolumn`}
                      checked={this.state.selectAlladditionalcolumn}
                      onChange={(e) => this.HandleSelectalladditionalcolumns(additionalcolumnsexcel)(e)}
                    />
                    <CheckboxLabel style={{ fontSize: '1.0rem' }} htmlFor={`id-SelectallAdditioncolumn`}>
                      Select All
                    </CheckboxLabel>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <CheckboxWrapper>
                        <div style={{ marginLeft: '20px' }}>
                          <TextField
                            style={{ minWidth: '500px' }}
                            type="search"
                            placeholder='Search'
                            margin="normal"
                            name="additionaltype"
                            id="additionaltype"
                            onChange={(e) => this.HandleSearchAdditionalexcelsearch(additionalcolumnsexcel)(e)}
                          />
                        </div>
                        {
                          R.map(additionalcolumnsheaders => (
                            <div>
                              <div style={{ display: 'flex' }}>
                                <Checkbox
                                  label={`lbl${additionalcolumnsheaders}`}
                                  value={`val${additionalcolumnsheaders}`}
                                  id={`id${additionalcolumnsheaders}`}
                                  name={`name${additionalcolumnsheaders}`}
                                  checked={this.state.arraySelectedquestions.includes(additionalcolumnsheaders)}
                                  onChange={(e) => this.HandleCheckheaders(e, additionalcolumnsheaders)}
                                />
                                <CheckboxLabel htmlFor={`id-${additionalcolumnsheaders}`}>
                                  {additionalcolumnsheaders}
                                </CheckboxLabel>
                              </div>
                            </div>
                          ), (this.state.additionalexcelcolumnSearchdata === null || this.state.additionalexcelcolumnSearchdata.length === 0) ? additionalcolumnsexcel : this.state.additionalexcelcolumnSearchdata)
                        }
                      </CheckboxWrapper>
                    </div>
                  </div>
                </div>
              ) : (isAdditionalexcelcolumn && additionalcolumnsexcel.length === 0 ? <div> <h5 style={{ opacity: "0.8" }} >No related questions found</h5> </div> : null)
            }
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            {
            this.props.photos.length > 4000 && this.props.embedPhotosInExcelReport ?
            <Button onClick={e =>   { this.validateEmbedadData(e); }} color="primary">
            Confirm
          </Button>
            :
            <Button onClick={e => { this.confirmcolumns(e); onConfirm(e) }} color="primary">
              Confirm
            </Button>
           }
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


export default ExportColumns;
