import React  from 'react';
import styled from 'styled-components';
import moment from 'moment';
import * as R from 'ramda';

import {
  compose,
  withStateHandlers
} from 'recompose';

import { maybeDecorateColumn } from '../../../../utils/tableColumns';
import { TableRow, TableHead, TableCell, Table, TableBody } from '@material-ui/core';

const Section = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  min-height      : 30px;
  padding         : 0 30px;
  border-bottom   : 2px solid #DCDCDC;
  cursor          : pointer;
`;

const InfoWrapper = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : start-end;
  padding         : 0 40px;
`;

const InfoListWrapper = styled.div`
  width : 100%;
`;

const FieldRow = styled.div`
  display       : flex;
  align-items   : center;
  border-bottom : 1px solid #DCDCDC;
  font-size     : 13px;
  min-height    : 30px;

  p {
    width : 45%;
  }
`;

const FieldName = styled.p`
  color: #868686;
`;

const IMMEDIATE_NOTIF = {
    value: 0,
    label: 'IMMEDIATE'
  };
const SCHEDULED_NOTIF = {
    value: 1,
    label: 'SCHEDULED'
};

const NOTIFICATION_TYPES = [
    IMMEDIATE_NOTIF,
    SCHEDULED_NOTIF
];

const NOTIF_SENT_STATUS = [{
    label: 'No',
    toolTip: ''
}, {
    label: 'Yes',
    toolTip: 'This notification has been sent already'
}, {
    label: 'Cancelled',
    toolTip: 'This notification has been canceled'
}];

const HEIGHT = 50;

const tableColumnsDefinition =  {
    mcid: {
      path:       ['mcid'],
      label:      'MCID'
    },
    name: {
      path:       ['name'],
      label:      'Name'
    },
    date_seen: {
      path:       ['date_seen'],
      label:       'Date Seen',
      decorator:  v => v ? moment(v.replace(/z/i, '')).format('M-D-YYYY h:mm A [ET]') : 'NA'
    }
};

const DATE = {
    scheduled:  {
        path:       ['config', 'scheduled', 'date'],
        label:       'Scheduled Date and Time'
    },
    sent:       {
        path:       ['sentTimestamp'],
        label:       'Sent Date'
    },
    created:    {
        path:       ['created'],
        label:       'Create Date'
    },
    decorator:  v => v ? moment(v.replace(/z/i, '')).format('M-D-YYYY h:mm A [ET]') : 'NA'
};

const NotificationDetailInfo = ({
  notification,
}) => {
    const notificationType = R.find(R.propEq('value', R.prop('type', notification)), NOTIFICATION_TYPES);
    const isScheduled = SCHEDULED_NOTIF.value === R.prop('value', notificationType);
    const sentStatus = R.compose(s => R.nth(s, NOTIF_SENT_STATUS),  R.prop('sent'))(notification);
    
    const tableColumns    = R.values(tableColumnsDefinition);
    const dateScheduled   = R.prop('scheduled', DATE);
    const dateSent        = R.prop('sent', DATE);
    const createDate      = R.prop('created', DATE);

    const dateDecorator   = R.prop('decorator', DATE);

    return (
        <div>
            <Section>
            <p>Notification Information</p>
            </Section>
            <InfoWrapper>
                <InfoListWrapper>
                    <FieldRow>
                        <FieldName>{R.prop('label', createDate)}</FieldName>
                        <p>{maybeDecorateColumn(dateDecorator)(R.path(R.prop('path', createDate), notification))}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>Create User</FieldName>
                        <p>{R.propOr('NA', 'createUserEmail', notification)}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>Id</FieldName>
                        <p>{`${R.propOr('NA', 'id', notification)}`}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>Title</FieldName>
                        <p>{`${R.propOr('NA', 'title', notification)}`}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>Message</FieldName>
                        <p>{`${R.propOr('NA', 'message', notification)}`}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>Notification Type</FieldName>
                        <p>{R.propOr('NA', 'label', notificationType)}</p>
                    </FieldRow>

                    {isScheduled && (
                    <FieldRow>
                        <FieldName>{R.prop('label', dateScheduled)}</FieldName>
                        <p>{maybeDecorateColumn(dateDecorator)(R.path(R.prop('path', dateScheduled), notification))}</p>
                    </FieldRow>)}
                
                    <FieldRow>
                        <FieldName>Sent</FieldName>
                        <p>{R.prop('label', sentStatus)}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>{R.prop('label', dateSent)}</FieldName>
                        <p>{maybeDecorateColumn(dateDecorator)(R.path(R.prop('path', dateSent), notification))}</p>
                    </FieldRow>

                    <FieldRow>
                        <FieldName>Sent User</FieldName>
                        <p>{R.propOr('NA', 'sentUserEmail', notification)}</p>
                    </FieldRow>

                </InfoListWrapper>
            </InfoWrapper>
            <Section>
            <p>Recipients</p>
            </Section>
            <InfoWrapper>
                <InfoListWrapper>
                    <FieldRow>
                        <Table>
                            <TableHead>
                                <TableRow>
                                {
                                    R.map(({ path, label }) => {
                                    return (
                                        <TableCell
                                        key     = {path}
                                        padding = "dense"
                                        numeric = {false}
                                        type    = "body"
                                        >{label}</TableCell>
                                    );})(tableColumns)
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {R.defaultTo([], R.prop('users', notification)).map(user => {

                                return (
                                <TableRow
                                    hover
                                    key          = {R.prop('mcid', user)}
                                    style        = {{ height: `${HEIGHT}px`, cursor: 'default', whiteSpace: 'nowrap' }}
                                >
                                    {
                                    R.map(({ path, decorator }) => {
                                        return (
                                        <TableCell
                                            key     = {path}
                                            padding = "dense"
                                            numeric = {false}
                                            type    = "body"
                                        >{maybeDecorateColumn(decorator)(R.path(path, user))}</TableCell>
                                    );})(tableColumns)
                                    }
                                </TableRow>
                                );
                            })}
                            </TableBody>
                        </Table>
                    </FieldRow>
                </InfoListWrapper>
            </InfoWrapper>
        </div>
    );
};

const enhancePage = compose(
  withStateHandlers(),
);

export default enhancePage(NotificationDetailInfo);
