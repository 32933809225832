import * as R      from 'ramda';
import React       from 'react';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';
import { connect } from 'react-redux';
import styled      from 'styled-components';
import uuid        from 'uuid/v4';

import {
compose,
lifecycle,
withStateHandlers,
withHandlers
} from 'recompose';

import SvrPreviewPage from './SvrPreviewPage';

import ChangeToAffectsModal      from '../../modals/ChangeToAffectsModal';
import DeleteQuestionsGroupModal from '../../modals/DeleteQuestionsGroupModal';
import DuplicateElementModal     from "../../modals/DuplicateElementModal";

import makeFormClone from '../helpers/makeFormClone';

import findFormItem from '../helpers/findFormItem';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser
} from '../../client/ClientReducer';

import {
  setCurrentClient  as _setCurrentClient,
  setCurrentProject as _setCurrentProject
} from '../../client/ClientActions';

import {
  fetchCommonQuestions as _fetchCommonQuestions
} from '../../common-questions/actions/CommonQuestionsActions';

import {
  getCommonQuestions
} from '../../common-questions/reducers/CommonQuestionsReducer';

import {
  updateTitle                     as _updateTitle,
  updateAutoApprove               as _updateAutoApprove,
  updateHideFromClient            as _updateHideFromClient,
  updateInvoicePlannedTime        as _updateInvoicePlannedTime,
  updateMaxAllowedTime            as _updateMaxAllowedTime,
  updateBypassGPS                 as _updateBypassGPS,
  updateBypassDuplicatePhotoCheck as _updateBypassDuplicatePhotoCheck,
  updateClient                    as _updateClient,
  updateCallout                   as _updateCallout,
  saveSection                     as _saveSection,
  copySection                     as _copySection,
  deleteSection                   as _deleteSection,
  saveQuestion                    as _saveQuestion,
  copyQuestion                    as _copyQuestion,
  deleteQuestion                  as _deleteQuestion,
  loadSvr                         as _loadSvr,
  saveSvr                         as _saveSvr,
  saveDraftSvr                    as _saveDraftSvr,
  resetSvrForm                    as _resetSvrForm,
  saveForkQuestion                as _saveForkQuestion,
  copyForkQuestion                as _copyForkQuestion,
  deleteForkQuestion              as _deleteForkQuestion,
  saveGroupQuestion               as _saveGroupQuestion,
  copyGroupQuestion               as _copyGroupQuestion,
  deleteGroupQuestion             as _deleteGroupQuestion,
  fetchTagsByStr                  as _fetchTagsByStr,
  addTag                          as _addTag,
  resetSearchedTags               as _resetSearchedTags,
  changeNodeSection               as _changeNodeSection,
  changeNodeQ                     as _changeNodeQuestion,
  saveSvrForm                     as _saveSvrForm
} from '../BuilderActions';

import {
  getSvrForm,
  getDocuments,
  getSearchedTags,
  getSvrSections,
  getBeforeTypePhotos,
  getBeforeTypeVideos,
  getSelectedNode,
  SECTIONS_GROUPS,
  ITEM_TYPES,
  getCallOutQuestions
} from '../BuilderReducer';

import {
  addDocument as _createDocument,
} from '../../upload/UploadActions';

import {
  removeDocument as _deleteDocument
} from '../../document/DocumentActions';

import { fetchSvr as _fetchSvr } from '../SvrActions';

import Header                 from '../../../components/Header';
import AddFormItem            from '../components/builder/AddFormItem';
import SvrHeader              from '../components/builder/SvrHeader';
import BuilderWrapper         from '../components/builder/BuilderWrapper';
import SectionGroup           from '../components/builder/SectionGroup';
import SectionItem            from '../components/builder/SectionItem';
import SectionModal           from '../components/builder/SectionModal';
import QuestionItem           from '../components/builder/QuestionItem';
import QuestionModal          from '../components/builder/QuestionModal';
import QuestionGroup          from '../components/builder/QuestionGroup';
import QuestionGroupModal     from '../components/builder/QuestionGroupModal';
import CommonQuestionModal    from '../components/builder/CommonQuestionModal';
import MovePlaceholder        from '../components/builder/MovePlaceholder';
import SavedNotificationToast from '../components/SavedNotificationToast';
import LeavingPageModal       from '../components/LeavingPageModal';
import DeleteSectionModal     from "../../modals/DeleteSectionModal";
import DocumentItem           from '../components/builder/DocumentItem';
import DroppableZone          from '../components/builder/DroppableZone';

import {
  FileRowWrapper,
  FileRow,
  FileCol,
  FileColTitle,
}                             from '../components/styled-components/DocumentItemRow';

import getPairedQuestion from '../helpers/getPairedQuestion';

const DocumentsSummary = styled.div`

`;

const DocumentsSummaryTitle = styled.strong`
  display: block;
  margin-bottom: 5px;
`;
const DocumentsSummaryText = styled.p`
  margin: 0;
  margin-left: 10px;
`;

const DocumentList = styled.div`
  margin-top: 10px;
`;

const NAMESPACE = 'svrs';

const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const getTotalSizeOfDocuments = (documents) => {
  let sum = 0;
  documents.forEach(d => sum += d.size);
  return bytesToSize(sum);
};


let questionDocumentIds = [];
const appendDocumentIds = (documentIds) => {
  const alreadyExists = R.find(R.propEq('id', documentIds.id), questionDocumentIds);
  if (alreadyExists === undefined) {
    questionDocumentIds = [...questionDocumentIds, documentIds];
  }
};

const SvrBuilderPage = ({
  svrForm,
  svrForm: {
    title,
    canonicalKey,
    autoApprovalHourTrigger,
    heroImageUrl,
    status,
    timestamp,
    content: {
      callOut,
      hideFromClient,
      invoicePlannedTime,
      maxAllowedTime,
      bypassGPSValidation,
      bypassDuplicatePhotoCheck
    }
  },
  // Client
  clients,
  setCurrentClient,
  setCurrentProject,
  currentClient,
  currentProject,
  currentSection,
  currentQuestion,
  currentForkQuestion,
  preSections,
  duringSections,
  postSections,
  // Handlers
  updateTitle,
  updateClient,
  updateCallOut,
  updateAutoApprove,
  updateHideFromClient,
  updateInvoicePlannedTime,
  updateMaxAllowedTime,
  updateBypassGPS,
  updateBypassDuplicatePhotoCheck,
  copyForkQuestion,
  // Save
  saveSvr,
  saveDraftSvr,
  isSaving,
  saveSvrForm,
  // Section Modal
  isSectionModalOpen,
  openSectionModal,
  closeSectionModal,
  updateSection,
  addTag,
  fetchTagsByStr,
  searchedTags,
  resetSearchedTags,
  // Question Modal
  createDocument,
  deleteDocument,
  isQuestionModalOpen,
  openQuestionModal,
  closeQuestionModal,
  updateQuestion,
  openForkQuestionModal,
  isForkQuestion,
  isNumericConditional,
  updateForkQuestion,
  documents,
  forkedAnswer,
  isDeleteQuestionModalOpen,
  isDeleteQuestionGroupModalOpen,
  closeDeleteQuestionGroupModal,
  openDeleteQuestionModal,
  openDeleteForkQuestionModal,
  openDeleteQuestionGroupModal,
  openDeleteGroupQuestionModal,
  closeDeleteQuestionModal,
  onDeleteQuestion,
  beforeTypesPhotos,
  beforeTypesVideos,
  // Question Group
  isQuestionGroupModalOpen,
  openQuestionGroupModal,
  // Common Question Modal
  isCommonQuestionModalOpen,
  commonQuestions,
  selectQuestion,
  commonQuestion,
  // Preview
  togglePreview,
  isPreview,
  togglePreviewuuid,
  isPreviewuuid,
  // Duplicate Modal
  isDuplicateElementModalOpen,
  openDuplicateSectionModal,
  closeDuplicateSectionModal,
  onDuplicateItem,
  openDuplicateQuestionModal,
  openDuplicateQuestionInnerGroupModal,
  location,
  openDeleteSectionModal,
  isSectionQuestionModalOpen,
  closeDeleteSectionModal,
  selectedNode,
  changeNodeSectionHandler,
  changeNodeSectionChildHandler,
  selectedSection,
  selectedFlattenItems,
  selectedSectionChild,
  isSelectedItem,
  setWrapperRef,
  isClientUser,
  callOutQuestions
}) => (
  <div>
    <Helmet>
      <title>Build SVR</title>
    </Helmet>
    <Header
      clients={clients}
      currentClient={currentClient}
      currentProject={currentProject}
      setCurrentClient={setCurrentClient}
      setCurrentProject={setCurrentProject}
      location={location}
      isClientUser={isClientUser()}
    />

    {isDeleteQuestionModalOpen &&
    <ChangeToAffectsModal
      onApprove={onDeleteQuestion}
      onCancel={closeDeleteQuestionModal}
      text={'Are you sure? This will delete the question from the SVR.'}
    />
    }

    {isDeleteQuestionGroupModalOpen &&
    <DeleteQuestionsGroupModal
      onApprove={onDeleteQuestion}
      onCancel={closeDeleteQuestionGroupModal}
    />
    }

    {isDuplicateElementModalOpen &&
    <DuplicateElementModal
      onApprove={onDuplicateItem}
      onCancel={closeDuplicateSectionModal}
    />
    }

    {isSectionQuestionModalOpen &&
    <DeleteSectionModal
      onApprove={onDeleteQuestion}
      onCancel={closeDeleteSectionModal}
    />
    }

    {isPreview ? (
      <SvrPreviewPage
        onExit={togglePreview}
        title={title}
        preSections={preSections}
        duringSections={duringSections}
        postSections={postSections}
        heroImageUrl={heroImageUrl}
        isUuid={false}
      />  
    ) :
    isPreviewuuid ? (
      <SvrPreviewPage
        onExit={togglePreviewuuid}
        title={title}
        preSections={preSections}
        duringSections={duringSections}
        postSections={postSections}
        heroImageUrl={heroImageUrl}
        isUuid={true}
      />  
    ) : (
      <BuilderWrapper>
        <SvrHeader
          title={title}
          canonicalKey={canonicalKey}
          formStatus={status}
          formTimestamp={timestamp}
          autoApprovalHourTrigger={autoApprovalHourTrigger}
          hideFromClient={hideFromClient}
          invoicePlannedTime={invoicePlannedTime}
          heroImageUrl={heroImageUrl}
          clientsList={clients}
          onTitleChange={updateTitle}
          onClientChange={updateClient}
          onAutoApproveChange={updateAutoApprove}
          onHideFromClientChange={updateHideFromClient}
          onInvoicePlannedTimeChange={updateInvoicePlannedTime}
          onMaxAllowedTimeChange={updateMaxAllowedTime}
          maxAllowedTime={maxAllowedTime}
          onBypassGPSValidationChange={updateBypassGPS}
          onBypassDuplicatePhotoCheckChange={updateBypassDuplicatePhotoCheck}
          bypassGPSValidation={bypassGPSValidation}
          bypassDuplicatePhotoCheck={bypassDuplicatePhotoCheck}
          onSave={() => saveSvr(NAMESPACE, svrForm)}
          onSaveDraft={() => saveDraftSvr(NAMESPACE, svrForm)}
          isSaving={isSaving}
          isPreview={preSections.length > 0 || duringSections.length > 0 || postSections.length > 0}
          isPreviewuuid={preSections.length > 0 || duringSections.length > 0 || postSections.length > 0}
          isEdit={R.has('id', svrForm)}
          onSaveNew={() => saveSvr(NAMESPACE, makeFormClone(svrForm))}
          onPreview={togglePreview}
          onPreviewuuid={togglePreviewuuid}
          currentClient={R.compose(R.defaultTo(currentClient), R.find(R.propEq('clientId', svrForm.tenancyClientId)))(clients)}
          hasCallOut={R.length(callOutQuestions) > 0}
          callOutMailing={R.propOr('', 'mailing', callOut)}
          onCallOutChange={updateCallOut}
          onSaveSvrForm={() => saveSvrForm(NAMESPACE, svrForm)}
        />

        <SectionGroup innerRef={setWrapperRef} title="Pre: this portion must be answered first, in section order">
          {
            R.all(R.equals(true),
              [
                !R.isEmpty(selectedNode),
                R.equals(selectedNode.type, 'section'),
                R.any(
                  R.equals(true),
                  [R.isEmpty(preSections), !R.contains(R.prop('uuid', R.head(preSections)), selectedFlattenItems)]
                )
              ]
            ) ?
              <MovePlaceholder type={'section'} onChange={() => changeNodeSectionHandler(SECTION_GROUP_NAME.pre, 0, selectedNode)}/> :
              null
          }
          {preSections.map((s, sectionKey, arr) => (
            <SectionItem
              key={s.uuid}
              title={s.title}
              onAdd={openQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid)}
              onCopy={openDuplicateSectionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid)}
              onEdit={openSectionModal.bind(null, SECTION_GROUP_NAME.pre, s)}
              onDelete={openDeleteSectionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid)}
              onSelect={() => selectedSection(SECTION_GROUP_NAME.pre, s)}
              isSelected={isSelectedItem(s.uuid)}
            >
              {
                R.all(R.equals(true),
                  [
                    !R.isEmpty(selectedNode),
                    !R.equals(selectedNode.type, 'section'),
                    R.any(
                      R.equals(true),
                      [
                        R.isEmpty(R.propOr([], 'content', s)),
                        !R.contains(R.prop('uuid', R.head(R.propOr([], 'content', s))), selectedFlattenItems)
                      ]
                    )
                  ]
                ) ?
                  <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.pre, s.uuid, '', selectedNode)} /> :
                  null
              }
              {s.content.map((q, qKey, qArr) => (
                q.type === ITEM_TYPES.QUESTION_GROUP ? (
                  <div key={q.uuid}>
                    <QuestionGroup
                      title={q.title}
                      tags={q.tags}
                      content={q.content}
                      onAdd={openQuestionGroupModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q.uuid)}
                      onCopy={openDuplicateQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q.uuid)}
                      onDelete={openDeleteQuestionGroupModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q.uuid)}
                      onEdit={openQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q)}
                      sectionId={s.uuid}
                      questionUuid={q.uuid}
                      copyQuestion={openDuplicateQuestionInnerGroupModal} // Check why is not calling to its own copy group question function
                      deleteQuestion={openDeleteGroupQuestionModal}
                      editQuestion={openQuestionGroupModal}
                      sectionGroup={SECTION_GROUP_NAME.pre}
                      onSelect={() => selectedSectionChild(SECTION_GROUP_NAME.pre, s.uuid, q, false, null, null, null)}
                      isSelected={isSelectedItem(q.uuid)}
                      selectedFlattenItems={selectedFlattenItems}
                    />
                    {
                      R.all(R.equals(false),
                        [
                          R.isEmpty(selectedNode),
                          R.equals(selectedNode.type, 'section'),
                          R.contains(R.propOr('', 'uuid', q), selectedFlattenItems),
                          R.contains(R.propOr('', 'uuid', qArr[qKey + 1]), selectedFlattenItems)
                        ]
                      ) ?
                        <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.pre, s.uuid, q.uuid, selectedNode)} /> :
                        null
                    }
                  </div>
                ) : (
                  <div key={q.uuid}>
                    <QuestionItem
                      title={q.title}
                      meta={q.meta}
                      conditionalItems={q.options}
                      documentIds={q.documentIds}
                      onEdit={openQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, {
                        ...q,
                        pairedQuestion: getPairedQuestion(q, R.flatten([preSections.map(sect => sect.content), duringSections.map(sect => sect.content), postSections.map(sect => sect.content)]))
                      })}
                      onFork={openForkQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q)}
                      onCopy={openDuplicateQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q.uuid)}
                      onDelete={openDeleteQuestionModal.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q.uuid)}
                      onMove={() => alert('move')}
                      options={q.options}
                      sectionGroup={SECTION_GROUP_NAME.pre}
                      sectionId={s.uuid}
                      questionUuid={q.uuid}
                      openForkQuestionModal={openForkQuestionModal}
                      openQuestionModal={openQuestionModal}
                      copyForkQuestion={copyForkQuestion}
                      deleteForkQuestion={openDeleteForkQuestionModal}
                      hasNesting={R.is(Array, q.options) && q.options.some(option => R.length(option.conditionalFork) > 0)}
                      commonId={q.commonId}
                      onSelect={selectedSectionChild.bind(null, SECTION_GROUP_NAME.pre, s.uuid, q, false, null, null, null)}
                      forkedOnSelect={selectedSectionChild}
                      selectedFlattenItems={selectedFlattenItems}
                      appendDocumentIds={appendDocumentIds}
                      question={q}
                    />
                    {
                      R.all(R.equals(false),
                        [
                          R.isEmpty(selectedNode),
                          R.equals(selectedNode.type, 'section'),
                          R.contains(R.propOr('', 'uuid', q), selectedFlattenItems),
                          R.contains(R.propOr('', 'uuid', qArr[qKey + 1]), selectedFlattenItems)
                        ]
                      ) ?
                        <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.pre, s.uuid, q.uuid, selectedNode)} /> :
                        null
                    }
                  </div>
                )
              ))}
              {
                R.all(R.equals(true),
                  [
                    !R.isEmpty(selectedNode),
                    R.equals(selectedNode.type, 'section'),
                    !R.contains(s.uuid, selectedFlattenItems),
                    !R.contains(R.prop('uuid', arr[sectionKey + 1]), selectedFlattenItems)
                  ]
                ) ?
                  <MovePlaceholder
                    type     = {'section'}
                    onChange = {() => changeNodeSectionHandler(SECTION_GROUP_NAME.pre, sectionKey + 1, selectedNode)}
                  /> :
                  null
              }
            </SectionItem>
          ))}
          <AddFormItem
            onAdd={openSectionModal.bind(null, SECTION_GROUP_NAME.pre)}
          />
        </SectionGroup>

        <SectionGroup innerRef={setWrapperRef} title="During: this portion's sections can be answered in any order">
          {
            R.all(R.equals(true),
              [
                !R.isEmpty(selectedNode),
                R.equals(selectedNode.type, 'section'),
                R.any(
                  R.equals(true),
                  [R.isEmpty(duringSections), !R.contains(R.prop('uuid', R.head(duringSections)), selectedFlattenItems)]
                )
              ]
            ) ?
              <MovePlaceholder type={'section'} onChange={() => changeNodeSectionHandler(SECTION_GROUP_NAME.during, 0, selectedNode)}/> :
              null
          }
          {duringSections.map((s, sectionKey, arr) => (
            <SectionItem
              key={s.uuid}
              title={s.title}
              onAdd={openQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid)}
              onCopy={openDuplicateSectionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid)}
              onEdit={openSectionModal.bind(null, SECTION_GROUP_NAME.during, s)}
              onDelete={openDeleteSectionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid)}
              onSelect={() => selectedSection(SECTION_GROUP_NAME.during, s)}
              isSelected={isSelectedItem(s.uuid)}
            >
              {
                R.all(R.equals(true),
                  [
                    !R.isEmpty(selectedNode),
                    !R.equals(selectedNode.type, 'section'),
                    R.any(
                      R.equals(true),
                      [
                        R.isEmpty(R.propOr([], 'content', s)),
                        !R.contains(R.prop('uuid', R.head(R.propOr([], 'content', s))), selectedFlattenItems)
                      ]
                    )
                  ]
                ) ?
                  <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.during, s.uuid, '', selectedNode)} /> :
                  null
              }
              {s.content.map((q, qKey, qArr) => (
                q.type === ITEM_TYPES.QUESTION_GROUP ? (
                  <div key={q.uuid}>
                    <QuestionGroup
                      title={q.title}
                      tags={q.tags}
                      content={q.content}
                      onAdd={openQuestionGroupModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q.uuid)}
                      onCopy={openDuplicateQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q.uuid)}
                      onDelete={openDeleteQuestionGroupModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q.uuid)}
                      onEdit={openQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q)}
                      sectionId={s.uuid}
                      questionUuid={q.uuid}
                      copyQuestion={openDuplicateQuestionInnerGroupModal}
                      deleteQuestion={openDeleteGroupQuestionModal}
                      editQuestion={openQuestionGroupModal}
                      sectionGroup={SECTION_GROUP_NAME.during}
                      onSelect={() => selectedSectionChild(SECTION_GROUP_NAME.during, s.uuid, q, false, null, null, null)}
                      isSelected={isSelectedItem(q.uuid)}
                      selectedFlattenItems={selectedFlattenItems}
                    />
                    {
                      R.all(R.equals(false),
                        [
                          R.isEmpty(selectedNode),
                          R.equals(selectedNode.type, 'section'),
                          R.contains(R.propOr('', 'uuid', q), selectedFlattenItems),
                          R.contains(R.propOr('', 'uuid', qArr[qKey + 1]), selectedFlattenItems)
                        ]
                      ) ?
                        <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.during, s.uuid, q.uuid, selectedNode)} /> :
                        null
                    }
                  </div>
                ) : (
                  <div key={q.uuid}>
                    <QuestionItem
                      title={q.title}
                      meta={q.meta}
                      conditionalItems={q.options}
                      documentIds={q.documentIds}
                      onEdit={openQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, {
                        ...q,
                        pairedQuestion: getPairedQuestion(q, R.flatten([preSections.map(sect => sect.content), duringSections.map(sect => sect.content), postSections.map(sect => sect.content)]))
                      })}
                      onFork={openForkQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q)}
                      onCopy={openDuplicateQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q.uuid)}
                      onDelete={openDeleteQuestionModal.bind(null, SECTION_GROUP_NAME.during, s.uuid, q.uuid)}
                      options={q.options}
                      sectionGroup={SECTION_GROUP_NAME.during}
                      sectionId={s.uuid}
                      questionUuid={q.uuid}
                      openForkQuestionModal={openForkQuestionModal}
                      openQuestionModal={openQuestionModal}
                      copyForkQuestion={copyForkQuestion}
                      deleteForkQuestion={openDeleteForkQuestionModal}
                      hasNesting={R.is(Array, q.options) && q.options.some(option => R.length(option.conditionalFork) > 0)}
                      commonId={q.commonId}
                      onSelect={selectedSectionChild.bind(null, SECTION_GROUP_NAME.during, s.uuid, q, false, null, null, null)}
                      forkedOnSelect={selectedSectionChild}
                      selectedFlattenItems={selectedFlattenItems}
                      appendDocumentIds={appendDocumentIds}
                      question={q}
                    />
                    {
                      R.all(R.equals(false),
                        [
                          R.isEmpty(selectedNode),
                          R.equals(selectedNode.type, 'section'),
                          R.contains(R.propOr('', 'uuid', q), selectedFlattenItems),
                          R.contains(R.propOr('', 'uuid', qArr[qKey + 1]), selectedFlattenItems)
                        ]
                      ) ?
                        <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.during, s.uuid, q.uuid, selectedNode)} /> :
                        null
                    }
                  </div>
                )
              ))}
              {
                R.all(R.equals(true),
                  [
                    !R.isEmpty(selectedNode),
                    R.equals(selectedNode.type, 'section'),
                    !R.contains(R.prop('uuid', s), selectedFlattenItems),
                    !R.contains(R.prop('uuid', arr[sectionKey + 1]), selectedFlattenItems)
                  ]
                ) ?
                  <MovePlaceholder
                    type     = {'section'}
                    onChange = {() => changeNodeSectionHandler(SECTION_GROUP_NAME.during, sectionKey + 1, selectedNode)}
                  /> :
                  null
              }
            </SectionItem>
          ))}
          <AddFormItem
            onAdd={openSectionModal.bind(null, SECTION_GROUP_NAME.during)}
          />
        </SectionGroup>

        <SectionGroup innerRef={setWrapperRef} title="Post: this portion must be answered last, in section order">
          {
            R.all(R.equals(true),
              [
                !R.isEmpty(selectedNode),
                R.equals(selectedNode.type, 'section'),
                R.any(
                  R.equals(true),
                  [R.isEmpty(postSections), !R.contains(R.prop('uuid', R.head(postSections)), selectedFlattenItems)]
                )
              ]
            ) ?
              <MovePlaceholder type={'section'} onChange={() => changeNodeSectionHandler(SECTION_GROUP_NAME.post, 0, selectedNode)}/> :
              null
          }
          {postSections.map((s, sectionKey, arr) => (
            <SectionItem
              key={s.uuid}
              title={s.title}
              onAdd={openQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid)}
              onCopy={openDuplicateSectionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid)}
              onEdit={openSectionModal.bind(null, SECTION_GROUP_NAME.post, s)}
              onDelete={openDeleteSectionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid)}
              onSelect={() => selectedSection(SECTION_GROUP_NAME.post, s)}
              isSelected={isSelectedItem(s.uuid)}
            >
              {
                R.all(R.equals(true),
                  [
                    !R.isEmpty(selectedNode),
                    !R.equals(selectedNode.type, 'section'),
                    R.any(
                      R.equals(true),
                      [
                        R.isEmpty(R.propOr([], 'content', s)),
                        !R.contains(R.prop('uuid', R.head(R.propOr([], 'content', s))), selectedFlattenItems)
                      ]
                    )
                  ]
                ) ?
                  <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.post, s.uuid, '', selectedNode)} /> :
                  null
              }
              {s.content.map((q, qKey, qArr) => (
                q.type === ITEM_TYPES.QUESTION_GROUP ? (
                  <div key={q.uuid}>
                    <QuestionGroup
                      title={q.title}
                      tags={q.tags}
                      content={q.content}
                      onAdd={openQuestionGroupModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q.uuid)}
                      onCopy={openDuplicateQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q.uuid)}
                      onDelete={openDeleteQuestionGroupModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q.uuid)}
                      onEdit={openQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q)}
                      sectionId={s.uuid}
                      questionUuid={q.uuid}
                      copyQuestion={openDuplicateQuestionInnerGroupModal}
                      deleteQuestion={openDeleteGroupQuestionModal}
                      editQuestion={openQuestionGroupModal}
                      sectionGroup={SECTION_GROUP_NAME.post}
                      onSelect={() => selectedSectionChild(SECTION_GROUP_NAME.post, s.uuid, q, false, null, null, null)}
                      isSelected={isSelectedItem(q.uuid)}
                      selectedFlattenItems={selectedFlattenItems}
                    />
                    {
                      R.all(R.equals(false),
                        [
                          R.isEmpty(selectedNode),
                          R.equals(selectedNode.type, 'section'),
                          R.contains(R.propOr('', 'uuid', q), selectedFlattenItems),
                          R.contains(R.propOr('', 'uuid', qArr[qKey + 1]), selectedFlattenItems)
                        ]
                      ) ?
                        <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.post, s.uuid, q.uuid, selectedNode)} /> :
                        null
                    }
                  </div>
                ) : (
                  <div key={q.uuid}>
                    <QuestionItem
                      title={q.title}
                      meta={q.meta}
                      conditionalItems={q.options}
                      documentIds={q.documentIds}
                      onEdit={openQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, {
                        ...q,
                        pairedQuestion: getPairedQuestion(q, R.flatten([preSections.map(sect => sect.content), duringSections.map(sect => sect.content), postSections.map(sect => sect.content)]))
                      })}
                      onFork={openForkQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q)}
                      onCopy={openDuplicateQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q.uuid)}
                      onDelete={openDeleteQuestionModal.bind(null, SECTION_GROUP_NAME.post, s.uuid, q.uuid)}
                      options={q.options}
                      sectionGroup={SECTION_GROUP_NAME.post}
                      sectionId={s.uuid}
                      questionUuid={q.uuid}
                      openForkQuestionModal={openForkQuestionModal}
                      openQuestionModal={openQuestionModal}
                      copyForkQuestion={copyForkQuestion}
                      deleteForkQuestion={openDeleteForkQuestionModal}
                      hasNesting={R.is(Array, q.options) && q.options.some(option => R.length(option.conditionalFork) > 0)}
                      commonId={q.commonId}
                      onSelect={selectedSectionChild.bind(null, SECTION_GROUP_NAME.post, s.uuid, q, false, null, null, null)}
                      forkedOnSelect={selectedSectionChild}
                      selectedFlattenItems={selectedFlattenItems}
                      appendDocumentIds={appendDocumentIds}
                      question={q}
                    />
                    {
                      R.all(R.equals(false),
                        [
                          R.isEmpty(selectedNode),
                          R.equals(selectedNode.type, 'section'),
                          R.contains(R.propOr('', 'uuid', q), selectedFlattenItems),
                          R.contains(R.propOr('', 'uuid', qArr[qKey + 1]), selectedFlattenItems)
                        ]
                      ) ?
                        <MovePlaceholder onChange={() => changeNodeSectionChildHandler(SECTION_GROUP_NAME.post, s.uuid, q.uuid, selectedNode)} /> :
                        null
                    }
                  </div>
                )
              ))}
              {
                R.all(R.equals(true),
                  [
                    !R.isEmpty(selectedNode),
                    R.equals(selectedNode.type, 'section'),
                    !R.contains(R.prop('uuid', s), selectedFlattenItems),
                    !R.contains(R.prop('uuid', arr[sectionKey + 1]), selectedFlattenItems)
                  ]
                ) ?
                  <MovePlaceholder
                    type     = {'section'}
                    onChange = {() => changeNodeSectionHandler(SECTION_GROUP_NAME.post, sectionKey + 1, selectedNode)}
                  /> :
                  null
              }
            </SectionItem>
          ))}
          <AddFormItem
            onAdd={openSectionModal.bind(null, SECTION_GROUP_NAME.post)}
          />
        </SectionGroup>

        <DroppableZone
          innerRef={setWrapperRef}
          title="Documents (drop your files here)"
          subtitle="Disclaimer: PDF, Word and Excel Workbook documents accepted."
          createDocument={createDocument}
        >
          {
            Object.keys(documents).length === 0 ? (
              <p>
                No documents yet.
              </p>
            ) : (
              <>
                <DocumentsSummary>
                  <DocumentsSummaryTitle>Summary:</DocumentsSummaryTitle>
                  <DocumentsSummaryText>
                    <small><strong>Quantity: </strong>{Object.keys(documents).length}</small>
                  </DocumentsSummaryText>
                  <DocumentsSummaryText>
                    <small><strong>Total size: </strong>{getTotalSizeOfDocuments(documents)}</small>
                  </DocumentsSummaryText>
                </DocumentsSummary>
                <FileRowWrapper noBorder>
                  <FileRow>
                    <FileCol width="380px">
                      <FileColTitle>File</FileColTitle>
                    </FileCol>
                    <FileCol width="110px">
                      <FileColTitle>Size</FileColTitle>
                    </FileCol>
                    <FileCol width="155px">
                      <FileColTitle>Date added</FileColTitle>
                    </FileCol>
                    <FileCol width="250px">
                      <FileColTitle>Question</FileColTitle>
                    </FileCol>
                    <FileCol last>
                    </FileCol>
                  </FileRow>
                </FileRowWrapper>
                <DocumentList>
                  {
                    documents.map(doc => (
                      <DocumentItem
                        key={doc.uuid}
                        id={doc.id}
                        documentUuid={doc.uuid}
                        title={doc.title}
                        size={doc.size}
                        url={doc.url}
                        created={doc.timestamp}
                        deleteDocument={deleteDocument}
                        questionDocumentIds={questionDocumentIds}
                        openQuestionModal={openQuestionModal}
                      />
                    ))
                  }
                </DocumentList>
              </>
            )
          }
        </DroppableZone>

        {currentSection && (
          <SectionModal
            k={currentSection.uuid}
            title={currentSection.title}
            tags={currentSection.tags}
            meta={currentSection.meta}
            open={isSectionModalOpen}
            onClose={closeSectionModal}
            onSave={updateSection}
            addTag={addTag}
            fetchTagsByStr={fetchTagsByStr}
            searchedTags={searchedTags}
            resetSearchedTags={resetSearchedTags}
          />
        )}

        {isCommonQuestionModalOpen && currentQuestion && (
          <CommonQuestionModal
            type={currentQuestion.type}
            open={isCommonQuestionModalOpen}
            onClose={closeQuestionModal}
            commonQuestions={commonQuestions}
            selectQuestion={selectQuestion}
          />
        )}

        {isQuestionGroupModalOpen && currentQuestion && (
          <QuestionGroupModal
            k={currentQuestion.uuid}
            ck={currentQuestion.canonicalKey}
            title={currentQuestion.title}
            content={currentQuestion.content}
            type={currentQuestion.type}
            meta={currentQuestion.meta}
            tags={currentQuestion.tags}
            open={isQuestionGroupModalOpen}
            onClose={closeQuestionModal}
            onSave={updateQuestion}
          />
        )}

        {!isForkQuestion && currentQuestion && (
          <QuestionModal
            commonQuestion={commonQuestion}
            createDocument={createDocument}
            deleteDocument={deleteDocument}
            isCommonQuestion={commonQuestion.isCommonQuestion}
            k={currentQuestion.uuid}
            ck={currentQuestion.canonicalKey}
            title={currentQuestion.title}
            tags={currentQuestion.tags}
            type={currentQuestion.type}
            meta={currentQuestion.meta}
            documentIds={currentQuestion.documentIds}
            documents={documents}
            options={currentQuestion.options}
            open={isQuestionModalOpen}
            onClose={closeQuestionModal}
            onSave={updateQuestion}
            beforeTypesPhotos={currentQuestion.pairedQuestion ? [currentQuestion.pairedQuestion, ...beforeTypesPhotos] : beforeTypesPhotos}
            beforeTypesVideos={beforeTypesVideos}
            isValidate={!R.equals(currentQuestion.type, 'common-input')}
          />
        )}

        {isForkQuestion && (
          <QuestionModal
            k={currentForkQuestion.uuid}
            ck={currentForkQuestion.canonicalKey}
            createDocument={createDocument}
            deleteDocument={deleteDocument}
            title={currentForkQuestion.title}
            tags={currentForkQuestion.tags}
            type={currentForkQuestion.type || forkedAnswer.type}
            meta={currentForkQuestion.meta}
            documentIds={currentForkQuestion.documentIds}
            options={currentForkQuestion.options}
            documents={documents}
            open={isQuestionModalOpen}
            onClose={closeQuestionModal}
            onSave={updateForkQuestion}
            beforeTypesPhotos={currentForkQuestion.pairedQuestion ? [currentForkQuestion.pairedQuestion, ...beforeTypesPhotos] : beforeTypesPhotos}
            beforeTypesVideos={beforeTypesVideos}
            isNumericConditional={isNumericConditional}
            forkFromNumericConditional={currentForkQuestion.type === ITEM_TYPES.NUMERIC_CONDITIONAL} // To be tested
          />
        )}

        <SavedNotificationToast show={isSaving.status} message={isSaving.message} hasError={isSaving.error}/>
        <LeavingPageModal/>

      </BuilderWrapper>
    )}
  </div>
);

const SECTION_GROUP_NAME = {
  pre    : 'pre',
  during : 'during',
  post   : 'post',
};

SvrBuilderPage.propTypes = {
  svrForm                 : PropTypes.shape({
    title  : PropTypes.string,
  }),
  clients                 : PropTypes.arrayOf(PropTypes.shape({
    clientId   : PropTypes.id,
    clientName : PropTypes.string,
    projects   : PropTypes.arrays
  })),
  preSections                     : PropTypes.array.isRequired,
  duringSections                  : PropTypes.array.isRequired,
  postSections                    : PropTypes.array.isRequired,
  updateTitle                     : PropTypes.func.isRequired,
  updateAutoApprove               : PropTypes.func.isRequired,
  updateHideFromClient            : PropTypes.func.isRequired,
  updateInvoicePlannedTime        : PropTypes.func.isRequired,
  updateMaxAllowedTime            : PropTypes.func.isRequired,
  updateBypassGPS                 : PropTypes.func.isRequired,
  updateBypassDuplicatePhotoCheck : PropTypes.func.isRequired,
  createDocument                  : PropTypes.func.isRequired,
  updateClient                    : PropTypes.func.isRequired,
  updateCallOut                   : PropTypes.func.isRequired,
  copySection                     : PropTypes.func.isRequired,
  deleteSection                   : PropTypes.func.isRequired,
  copyQuestion                    : PropTypes.func.isRequired,
  deleteQuestion                  : PropTypes.func.isRequired,
  saveSvr                         : PropTypes.func.isRequired,
  saveDraftSvr                    : PropTypes.func.isRequired,
  saveSvrForm                     : PropTypes.func.isRequired,
  /* Sections Modal */
  isSectionModalOpen              : PropTypes.bool.isRequired,
  openSectionModal                : PropTypes.func.isRequired,
  closeSectionModal               : PropTypes.func.isRequired,
  updateSection                   : PropTypes.func.isRequired,
  /* Question Modal */
  isQuestionModalOpen             : PropTypes.bool.isRequired,
  openQuestionModal               : PropTypes.func.isRequired,
  closeQuestionModal              : PropTypes.func.isRequired,
  updateQuestion                  : PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  svrForm               : getSvrForm(state),
  isSaving              : state.builder.isSaving,
  clients               : getClients(state),
  documents             : getDocuments(state),
  commonQuestions       : getCommonQuestions(state),
  currentClient         : getCurrentClient(state),
  currentProject        : getCurrentProject(state),
  preSections           : getSvrSections(state, 'pre'),
  duringSections        : getSvrSections(state, 'during'),
  postSections          : getSvrSections(state, 'post'),
  searchedTags          : getSearchedTags(state),
  beforeTypesPhotos     : getBeforeTypePhotos(state),
  beforeTypesVideos     : getBeforeTypeVideos(state),
  selectedNode          : getSelectedNode(state),
  isClientUser,
  callOutQuestions      : getCallOutQuestions(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSvr                        : (namespace, payload) => dispatch(_fetchSvr(namespace, payload)),
  createDocument                  : payload => dispatch(_createDocument(payload)),
  deleteDocument                  : payload => dispatch(_deleteDocument(payload)),
  setCurrentClient                : payload => dispatch(_setCurrentClient(payload)),
  setCurrentProject               : payload => dispatch(_setCurrentProject(payload)),
  updateTitle                     : payload => dispatch(_updateTitle(payload)),
  updateAutoApprove               : payload => dispatch(_updateAutoApprove(payload)),
  updateHideFromClient            : payload => dispatch(_updateHideFromClient(payload)),
  updateInvoicePlannedTime        : payload => dispatch(_updateInvoicePlannedTime(payload)),
  updateMaxAllowedTime            : payload => dispatch(_updateMaxAllowedTime(payload)),
  updateBypassGPS                 : payload => dispatch(_updateBypassGPS(payload)),
  updateBypassDuplicatePhotoCheck : payload => dispatch(_updateBypassDuplicatePhotoCheck(payload)),
  updateClient                    : payload => dispatch(_updateClient(payload)),
  updateCallOut                   : payload => dispatch(_updateCallout(payload)),
  saveSection                     : (sectionGroup, payload) => dispatch(_saveSection(SECTIONS_GROUPS[sectionGroup], payload)),
  copySection                     : (sectionGroup, payload) => dispatch(_copySection(SECTIONS_GROUPS[sectionGroup], payload)),
  deleteSection                   : (sectionGroup, payload) => dispatch(_deleteSection(SECTIONS_GROUPS[sectionGroup], payload)),
  //selectSection                 : (sectionGroup, payload) => dispatch(_selectSection(SECTIONS_GROUPS[sectionGroup], payload)),
  changeNodeSection               : (sectionGroup, sectionPosition, payload) => dispatch(_changeNodeSection(SECTIONS_GROUPS[sectionGroup], sectionPosition, payload)),
  saveQuestion                    : (sectionGroup, section, payload) => dispatch(_saveQuestion(SECTIONS_GROUPS[sectionGroup], section, payload)),
  copyQuestion                    : (sectionGroup, section, payload) => dispatch(_copyQuestion(SECTIONS_GROUPS[sectionGroup], section, payload)),
  changeNodeQuestion              : (sectionGroup, section, question, payload) => dispatch(_changeNodeQuestion(SECTIONS_GROUPS[sectionGroup], section, question, payload)),
  copyForkQuestion                : (sectionGroup, section, question, forkedAnswer, payload) => dispatch(_copyForkQuestion(SECTIONS_GROUPS[sectionGroup], section, question, forkedAnswer, payload)),
  deleteQuestion                  : (sectionGroup, section, payload) => dispatch(_deleteQuestion(SECTIONS_GROUPS[sectionGroup], section, payload)),
  deleteForkQuestion              : (sectionGroup, section, question, forkedAnswer, payload) => dispatch(_deleteForkQuestion(SECTIONS_GROUPS[sectionGroup], section, question, forkedAnswer, payload)),
  saveForkQuestion                : (sectionGroup, section, question, forkedAnswer, payload, isEditForkQuestion) => dispatch(_saveForkQuestion(SECTIONS_GROUPS[sectionGroup], section, question, forkedAnswer, payload, isEditForkQuestion)),
  saveGroupQuestion               : (sectionGroup, section, questionGroup, payload) => dispatch(_saveGroupQuestion(SECTIONS_GROUPS[sectionGroup], section, questionGroup, payload)),
  copyGroupQuestion               : (sectionGroup, section, questionGroup, payload) => dispatch(_copyGroupQuestion(SECTIONS_GROUPS[sectionGroup], section, questionGroup, payload)),
  deleteGroupQuestion             : (sectionGroup, section, questionGroup, payload) => dispatch(_deleteGroupQuestion(SECTIONS_GROUPS[sectionGroup], section, questionGroup, payload)),
  loadSvr                         : (namespace, payload) => dispatch(_loadSvr(namespace, payload)),
  resetSvrForm                    : () => dispatch(_resetSvrForm()),
  saveSvr                         : (namespace, payload) => dispatch(_saveSvr(namespace, payload)),
  saveDraftSvr                    : (namespace, payload) => dispatch(_saveDraftSvr(namespace, payload)),
  addTag                          : payload => dispatch(_addTag(payload)),
  fetchTagsByStr                  : payload => dispatch(_fetchTagsByStr(payload)),
  resetSearchedTags               : () => dispatch(_resetSearchedTags()),
  fetchCommonQuestions            : () => dispatch(_fetchCommonQuestions()),
  saveSvrForm                     : (namespace, payload) => dispatch(_saveSvrForm(namespace, payload))
});

const enhancePage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
      contextSectionGroup             = null,
      contextSection                  = null,
      contextQuestion                 = null,
      currentSection                  = null,
      currentQuestion                 = null,
      currentForkQuestion             = null,
      deleteQuestionType              = null,
      duplicateElementType            = null,
      isSectionModalOpen              = false,
      isQuestionModalOpen             = false,
      isQuestionGroupModalOpen        = false,
      isCommonQuestionModalOpen       = false,
      isDuplicateElementModalOpen     = false,
      isForkQuestion                  = false,
      isNumericConditional            = false,
      numericConditionalMaxQuestions  = 0,
      questionTitle                   = '',
      isEditForkQuestion              = false,
      isQuestionGroup                 = false,
      isPreview                       = false,
      isPreviewuuid                   = false,
      savedNotificationShow           = false,
      isDeleteQuestionModalOpen       = false,
      isDeleteQuestionsGroupModalOpen = false,
      forkedAnswer                    = {},
      commonQuestion                  = {},
      isSectionQuestionModalOpen      = false,
      sectionQuestionType             = null,
      clientName                      = null,
      clientList                      = null,
      selectedNode                    = {},
      selectedFlattenItems            = [],
      wrapperRef                      = [],
    }) => ({
      contextSectionGroup,
      contextSection,
      contextQuestion,
      currentSection,
      currentQuestion,
      duplicateElementType,
      isSectionModalOpen,
      isQuestionModalOpen,
      isForkQuestion,
      isNumericConditional,
      numericConditionalMaxQuestions,
      questionTitle,
      forkedAnswer,
      isEditForkQuestion,
      currentForkQuestion,
      isQuestionGroupModalOpen,
      deleteQuestionType,
      isQuestionGroup,
      isCommonQuestionModalOpen,
      isDuplicateElementModalOpen,
      commonQuestion,
      isPreview,
      isPreviewuuid,
      savedNotificationShow,
      isDeleteQuestionModalOpen,
      isDeleteQuestionsGroupModalOpen,
      isSectionQuestionModalOpen,
      sectionQuestionType,
      clientName,
      clientList,
      selectedNode,
      selectedFlattenItems,
      wrapperRef
    }),
    {
      onDeleteQuestion : ({deleteQuestionType, contextSectionGroup, contextSection, contextQuestionGroup, contextQuestion, currentQuestion, forkedAnswer, currentSection},
                         {deleteQuestion, deleteForkQuestion, deleteGroupQuestion, deleteSection, preSections, duringSections, postSections, deleteDocument}) => () => {

        const sections = [...preSections, ...postSections, ...duringSections];
        let uuid;

        if(deleteQuestionType === "section"){
          uuid = currentSection;
        } else {
          uuid = currentQuestion;
        }

        const item = findFormItem(uuid, sections);

        const deleteAllFormItemDocuments = (item) => {
          item.documentIds && item.documentIds.forEach(deleteDocument);
          item.content && item.content.length && item.content.forEach(deleteAllFormItemDocuments);
          item.options && item.options.length && item.options.forEach(deleteAllFormItemDocuments);
          item.conditionalFork && item.conditionalFork.length && item.conditionalFork.forEach(deleteAllFormItemDocuments);
        };

        deleteAllFormItemDocuments(item);

        const deleteByType = R.cond([
          [R.equals('forkQuestion'),  () => deleteForkQuestion(contextSectionGroup, contextSection, contextQuestion, forkedAnswer, currentQuestion)],
          [R.equals('groupQuestion'), () => deleteGroupQuestion(contextSectionGroup, contextSection, contextQuestionGroup, currentQuestion)],
          [R.equals('question'),      () => deleteQuestion(contextSectionGroup, contextSection, currentQuestion)],
          [R.equals('section'),       () => deleteSection(contextSectionGroup, currentSection)]
        ]);

        deleteByType(deleteQuestionType);

        return {
          isDeleteQuestionModalOpen      : false,
          isDeleteQuestionGroupModalOpen : false,
          contextSectionGroup            : null,
          contextSection                 : null,
          contextQuestion                : null,
          deleteQuestionType             : null,
          forkedAnswer                   : {},
          currentQuestion                : null,
          isSectionQuestionModalOpen     : false,
          sectionQuestionType            : null,
          currentSection                 : null
        };
      },
      openDeleteQuestionModal : () => (sectionGroup, section, currentQuestion) => ({
        isDeleteQuestionModalOpen : true,
        contextSectionGroup       : sectionGroup,
        contextSection            : section,
        deleteQuestionType        : 'question',
        currentQuestion
      }),
      openDeleteQuestionGroupModal : () => (sectionGroup, section, currentQuestion) => ({
        isDeleteQuestionGroupModalOpen : true,
        contextSectionGroup            : sectionGroup,
        contextSection                 : section,
        deleteQuestionType             : 'question',
        currentQuestion
      }),
      openDeleteForkQuestionModal : () => (sectionGroup, section, question, forkedAnswer, currentQuestion) => ({
        isDeleteQuestionModalOpen : true,
        contextSectionGroup       : sectionGroup,
        contextSection            : section,
        contextQuestion           : question,
        deleteQuestionType        : 'forkQuestion',
        forkedAnswer,
        currentQuestion
      }),
      openDeleteGroupQuestionModal : () => (sectionGroup, section, question, currentQuestion) => ({
        isDeleteQuestionModalOpen : true,
        contextSectionGroup       : sectionGroup,
        contextSection            : section,
        contextQuestion           : question,
        deleteQuestionType        : 'groupQuestion',
        contextQuestionGroup      : question,
        currentQuestion
      }),

      openDeleteSectionModal : () => (sectionGroup, currentSection) => ({
        isSectionQuestionModalOpen : true,
        contextSectionGroup        : sectionGroup,
        deleteQuestionType         : 'section',
        currentSection
      }),

      closeDeleteSectionModal : () => () => ({
        isSectionQuestionModalOpen : false,
        contextSectionGroup        : null,
        sectionQuestionType        : null,
        currentSection             : null
      }),

      closeDeleteQuestionModal : () => () => ({
        isDeleteQuestionModalOpen : false,
        contextSectionGroup       : null,
        contextSection            : null,
        contextQuestion           : null,
        deleteQuestionType        : null,
        forkedAnswer              : {},
        currentQuestion           : null
      }),
      closeDeleteQuestionGroupModal : () => () => ({
        isDeleteQuestionGroupModalOpen : false,
        contextSectionGroup            : null,
        contextSection                 : null,
        contextQuestion                : null,
        deleteQuestionType             : null,
        forkedAnswer                   : {},
        currentQuestion                : null
      }),
      toggleShowSavedNotification : ({savedNotificationShow}) => () => ({savedNotificationShow: !savedNotificationShow}),
      togglePreview               : ({isPreview}) => () => ({isPreview: !isPreview}),
      togglePreviewuuid           : ({isPreviewuuid}) => () => ({isPreviewuuid: !isPreviewuuid}),
      openSectionModal            : () => (sectionGroup, currentSection = {}) => ({
        contextSectionGroup : sectionGroup,
        isSectionModalOpen  : true,
        currentSection
      }),
      closeSectionModal : () => () => ({
        isSectionModalOpen : false,
        currentSection     : null,
        isForkQuestion     : false,
        forkedAnswer       : {},
        isEditForkQuestion : false,
        commonQuestion     : {}
      }),
      updateSection     : ({contextSectionGroup}, {saveSection}) => payload => saveSection(contextSectionGroup, payload),
      openQuestionModal : () => (sectionGroup, section, currentQuestion = {}) => {
        return {
          contextSectionGroup       : sectionGroup,
          contextSection            : section,
          isQuestionModalOpen       : !R.contains(currentQuestion.type, [ITEM_TYPES.COMMON, ITEM_TYPES.QUESTION_GROUP]),
          isQuestionGroupModalOpen  : R.equals(currentQuestion.type, ITEM_TYPES.QUESTION_GROUP),
          isCommonQuestionModalOpen : R.equals(currentQuestion.type, ITEM_TYPES.COMMON),
          currentQuestion
        };
      },
      closeQuestionModal : () => () => ({
        isQuestionGroupModalOpen : false,
        isQuestionGroup          : false,
        isQuestionModalOpen      : false,
        currentQuestion          : null,
        isForkQuestion           : false,
        isNumericConditional     : false,
        forkedAnswer             : {},
        isEditForkQuestion       : false,
        commonQuestion           : {}
      }),
      openForkQuestionModal : (_, { saveForkQuestion }) => (sectionGroup, section, question, forkedAnswer, currentForkQuestion = {}, isEditForkQuestion = false) => {
        const numericConditionalMaxQuestions = typeof question === 'string' ? 0 : question.meta.maxQuestions;
        const questionTitle = typeof question === 'string' ? '' : question.title;
        const questionUuid = typeof question === 'string' ? question : question.uuid;
        let numericConditionalQuestionsArray = [];

        for (let i = 1; i <= numericConditionalMaxQuestions; i++) {
          const newUuId = uuid();

          let title = questionTitle;
          if(R.propEq("type", "numeric-conditional-input", question)){
            title = ITEM_TYPES.SINGLE_LINE === forkedAnswer.type || ITEM_TYPES.MULTI_LINE  === forkedAnswer.type ? 'Response'
                  : ITEM_TYPES.PHOTO       === forkedAnswer.type ? 'Photo'
                  : '';
          }

          const newQuestionObj = {
            type: forkedAnswer.type,
            tags: [],
            options: [],
            title: title + ' - ' + i,
            canonicalKey: newUuId,
            meta: {
                isRequired: true,
                isHidden: false,
                isQuickReview: false,
                isConditional: false,
                isCallOut: false,
                callOutOptions: [],
                photoType: '',
                videoType: '',
                forkFromNumericConditional: true,
            },
            documentIds: [],
            uuid: newUuId,
          };

          numericConditionalQuestionsArray = [...numericConditionalQuestionsArray, newQuestionObj];
        }

        saveForkQuestion(sectionGroup, section, questionUuid, forkedAnswer, numericConditionalQuestionsArray, isEditForkQuestion);

        return ({
          contextSectionGroup             : sectionGroup,
          contextSection                  : section,
          contextQuestion                 : questionUuid, // question = uuid
          isNumericConditional            : question.type === ITEM_TYPES.NUMERIC_CONDITIONAL,
          isQuestionModalOpen             : true,
          isForkQuestion                  : !(question.type === ITEM_TYPES.NUMERIC_CONDITIONAL),
          currentForkQuestion,
          forkedAnswer,
          isEditForkQuestion
        });
      },
      updateQuestion : ({contextSectionGroup, contextSection, isQuestionGroup, contextQuestionGroup}, {saveQuestion, saveGroupQuestion}) => payload => {
        isQuestionGroup ? saveGroupQuestion(contextSectionGroup, contextSection, contextQuestionGroup, payload) : saveQuestion(contextSectionGroup, contextSection, payload);
      },
      openQuestionGroupModal : () => (sectionGroup, section, questionGroup, currentQuestion = {}) => ({
        contextSectionGroup  : sectionGroup,
        contextSection       : section,
        contextQuestionGroup : questionGroup,
        isQuestionModalOpen  : true,
        isQuestionGroup      : true,
        currentQuestion
      }),
      updateForkQuestion : ({contextSectionGroup, contextSection, contextQuestion, forkedAnswer, isEditForkQuestion}, {saveForkQuestion}) => payload => {
        saveForkQuestion(contextSectionGroup, contextSection, contextQuestion, forkedAnswer, payload, isEditForkQuestion);
      },
      selectQuestion : () => (commonQuestion = {}) => ({
        isCommonQuestionModalOpen : false,
        isQuestionModalOpen       : true,
        commonQuestion
      }),

      onDuplicateItem : ({contextSectionGroup, duplicateElementType, currentSection, currentQuestion, contextSection, contextQuestionGroup}, {copySection, copyQuestion, copyGroupQuestion}) => () => {
        const duplicateByType = R.cond([
          [R.equals('section'),       () => copySection(contextSectionGroup, currentSection)],
          [R.equals('question'),      () => copyQuestion(contextSectionGroup, contextSection, currentQuestion)],
          [R.equals('groupQuestion'), () => copyGroupQuestion(contextSectionGroup, contextSection, contextQuestionGroup, currentQuestion)],
        ]);

        duplicateByType(duplicateElementType);

        return {
          contextSectionGroup         : null,
          duplicateElementType        : null,
          isDuplicateElementModalOpen : false,
          currentSection              : null,
          contextSection              : null,
          currentQuestion             : null,
          contextQuestion             : null
        };
      },

      openDuplicateSectionModal: () => (sectionGroup, currentSection) => ({
        isDuplicateElementModalOpen : true,
        contextSectionGroup         : sectionGroup,
        duplicateElementType        : 'section',
        currentSection
      }),

      openDuplicateQuestionModal: () => (sectionGroup, section, currentQuestion) => ({
        isDuplicateElementModalOpen : true,
        contextSectionGroup         : sectionGroup,
        contextSection              : section,
        duplicateElementType        : 'question',
        currentQuestion
      }),

      openDuplicateQuestionInnerGroupModal: () => (sectionGroup, section, question, currentQuestion) => ({
        isDuplicateElementModalOpen : true,
        contextSectionGroup         : sectionGroup,
        contextSection              : section,
        contextQuestion             : question,
        duplicateElementType        : 'groupQuestion',
        contextQuestionGroup        : question,
        currentQuestion
      }),

      closeDuplicateSectionModal: () => () => ({
        contextSectionGroup         : null,
        duplicateElementType        : null,
        isDuplicateElementModalOpen : false,
        contextQuestionGroup        : null,
        currentSection              : null
      }),

      selectedSection: ({ selectedFlattenItems }) => (sectionGroup, section) => {
        const newSelectedNode = R.assoc('sectionGroup', SECTIONS_GROUPS[sectionGroup], section);

        if (R.contains(newSelectedNode.uuid, selectedFlattenItems)) {
          return {
            selectedNode         : {},
            selectedFlattenItems : []
          };
        }

        const flatNodes = node => {
          let newNode = [];
          const checkConditionalFork = R.filter(i => !R.isEmpty(i.conditionalFork));
          const checkNext = (prev, next) => (
            R.isEmpty(checkConditionalFork(next.options)) ?
              [...prev, next.uuid] :
              [...prev, flatNodes(next)]
          );
          const innerMap = R.compose(R.reduce(checkNext, []), R.prop('conditionalFork'));
          if (R.propOr(false, 'options', node)) {
            newNode = [
              node.uuid,
              ...R.map(innerMap, checkConditionalFork(node.options))
            ];
          } else {
            newNode = [node.uuid];
          }
          return R.flatten(newNode);
        };

        const newSelectedFlattenItems = R.flatten(
          R.concat(
            [R.prop('uuid', section)],
            section.content.map(flatNodes)
          )
        );

        return {
          selectedNode         : newSelectedNode,
          selectedFlattenItems : newSelectedFlattenItems
        };
      },

      changeNodeSectionHandler: (_, {changeNodeSection}) => (sectionGroup, sectionPosition, payload) => {
        changeNodeSection(sectionGroup, sectionPosition, payload);
        return {
          selectedNode: {},
          selectedFlattenItems: []
        };
      },

      selectedSectionChild: ({ selectedFlattenItems }) => (sectionGroup, sectionId, childNode, isForked, forkedQuestion, forkedAnswer, forkedPayload) => {
        const newSelectedNode = R.merge(
          {
            'sectionGroup': SECTIONS_GROUPS[sectionGroup],
            sectionId,
            isForked,
            forkedQuestion,
            forkedAnswer,
            forkedPayload
          },
          childNode);

        if (R.contains(newSelectedNode.uuid, selectedFlattenItems)) {
          return {
            selectedNode: {},
            selectedFlattenItems: []
          };
        }

        const flatNodes = node => {
          let newNode = [];
          const checkConditionalFork = R.filter(i => !R.isEmpty(i.conditionalFork));
          const checkNext = (prev, next) => (
            R.isEmpty(checkConditionalFork(next.options)) ?
              [...prev, next.uuid] :
              [...prev,  flatNodes(next)]
          );
          const innerMap = R.compose(R.reduce(checkNext, []), R.prop('conditionalFork'));

          if (R.propOr(false, 'options', node)) {
            newNode = [
              node.uuid,
              ...R.map(innerMap, checkConditionalFork(node.options))
            ];
          } else {
            newNode = [node.uuid];
          }
          return R.flatten(newNode);
        };

        const newSelectedFlattenItems = flatNodes(childNode);

        return {
          selectedNode         : newSelectedNode,
          selectedFlattenItems : newSelectedFlattenItems
        };
      },

      changeNodeSectionChildHandler: (_, { changeNodeQuestion }) => (sectionGroup, sectionId, childId, payload) => {
        changeNodeQuestion(sectionGroup, sectionId, childId, payload);
        return {
          selectedNode: {},
          selectedFlattenItems: []
        };
      },
      setWrapperRef: ({ wrapperRef }) => (node) => ({
        wrapperRef: [ ...wrapperRef, node]
      }),
      resetSelection: () => () => ({
        selectedNode: {},
        selectedFlattenItems: []
      })
    }
  ),
  withHandlers({
    isSelectedItem : ({ selectedFlattenItems }) => R.contains(R.__, selectedFlattenItems),
    handleClickOutside: ({ wrapperRef, resetSelection }) => evt => {
      if (R.isEmpty(wrapperRef.filter(i => i && i.contains(evt.target)))) {
        resetSelection();
      }
    }
  }),
  lifecycle({
    componentDidMount() {
      this.props.resetSvrForm();
      this.props.fetchCommonQuestions();
      const formCanonicalKey = R.path(['match', 'params', 'canonicalKey'], this.props);

      if (formCanonicalKey) {
        const svrPromise = this.props.fetchSvr(NAMESPACE, formCanonicalKey);
        svrPromise
          ? svrPromise.then(() => {
            this.props.loadSvr(NAMESPACE, formCanonicalKey);
          })
          : this.props.loadSvr(NAMESPACE, formCanonicalKey);
      }

      document.addEventListener('mousedown', this.props.handleClickOutside);
    },
    componentWillUnmount() {
      document.removeEventListener('mousedown', this.props.handleClickOutside);
    },
    componentWillReceiveProps(nextProps) {
      const callOutChanged = !R.equals(
          R.length(R.propOr([], ['callOutQuestions'], this.props)),
          R.length(R.propOr([], ['callOutQuestions'], nextProps)),
      );

      if (callOutChanged) {
        this.props.updateCallOut({
          active: R.length(R.propOr([], ['callOutQuestions'], nextProps)) > 0,
          mailing: R.pathOr('', ['svrForm', 'content', 'callOut', 'mailing'], nextProps)
        });
      }
    }
  }
));


export default enhancePage(SvrBuilderPage);
