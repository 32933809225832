import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import { Paper }  from '@material-ui/core';
import EditIcon   from '@material-ui/icons/ModeEdit';
import CopyIcon   from '@material-ui/icons/ContentCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenWith   from '@material-ui/icons/OpenWith';

import AddQuestionGroupItem from './AddQuestionGroupItem';
import QuestionItem         from './QuestionItem';

const Wrapper = styled(Paper)`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  font-size       : .875rem;
  margin          : .5rem 0;
  margin-left     : 1.5rem;
  padding         : 0 .5rem;
  border-left     : .625rem solid rgba(57, 133, 89, 0.7);
  box-sizing      : border-box;
  background-color: ${props => props.selected ? `rgba(243, 33, 46, .25) !important` : `inherit`};
`;

const Label = styled.p`
  margin       : 0;
  font-size    : .875rem;
  font-weight  : 500;
  cursor       : default;
`;

const Title = styled.p`
  margin       : .25rem .5rem;
  align-self   : center;
  margin-right : auto;
  cursor       : default;
`;

const SectionActions = styled.div`
  display      : flex;
  align-items  : center;
  margin-right : .5rem;
  svg {
    margin-left : .25em;
    font-size   : 1.25rem;
    cursor      : pointer;
  }
`;

const QuestionGroup = ({
  title,
  onCopy,
  onAdd,
  onEdit,
  onDelete,
  content,
  copyQuestion,
  deleteQuestion,
  editQuestion,
  sectionGroup,
  sectionId,
  questionUuid,
  onSelect,
  isSelected,
  selectedFlattenItems
}) => (
  <div>
    <Wrapper selected={isSelected}>
      <Label>QUESTION GROUP</Label>
      <Title>{title}</Title>
      <SectionActions>
        <CopyIcon
          onClick={onCopy}
        />
        <OpenWith
          onClick={onSelect}
        />
        <AddQuestionGroupItem
          onAdd={onAdd}
        />
        <EditIcon
          onClick={onEdit}
        />
        <DeleteIcon
          onClick={onDelete}
        />
      </SectionActions>
    </Wrapper>
    {content.map(q => (
      <QuestionItem
        key={q.uuid}
        title={q.title}
        meta={q.meta}
        level={2}
        onCopy={copyQuestion.bind(null, sectionGroup, sectionId, questionUuid, q.uuid)}
        onDelete={deleteQuestion.bind(null, sectionGroup, sectionId, questionUuid, q.uuid)}
        onEdit={editQuestion.bind(null, sectionGroup, sectionId, questionUuid, q)}
        questionUuid={q.uuid}
        selectedFlattenItems={selectedFlattenItems}
        question={q}
      />
    ))}
  </div>
);

QuestionGroup.propTypes = {
  title              : PropTypes.string.isRequired,
  onCopy             : PropTypes.func.isRequired,
  onFork             : PropTypes.func,
  onEdit             : PropTypes.func.isRequired,
  onDelete           : PropTypes.func.isRequired,
  onAdd              : PropTypes.func.isRequired,
  copyQuestion       : PropTypes.func.isRequired,
  deleteQuestion     : PropTypes.func.isRequired,
  editQuestion       : PropTypes.func.isRequired,
  sectionGroup       : PropTypes.string,
  sectionId          : PropTypes.string,
  questionUuid       : PropTypes.string,
  content            : PropTypes.array,
};

QuestionGroup.defaultProps = {
  sectionGroup : '',
  sectionId    : '',
  questionUuid : '',
  content      : [],
  onFork       : () => {},
};

export default QuestionGroup;
