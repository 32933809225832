import * as R from 'ramda';

const mergeFlagStarMotiveAnswers = (questionAnswers, transformedData, questions) => R.mapObjIndexed((questionAnswers, svrId) => {
    const svr = transformedData.find(svrData => parseInt(svrData.id, 10) === parseInt(svrId, 10));
    const starPhotos = R.pathOr([], ['metaJson', 'starPhotos'], svr);
    const flagPhotos = R.pathOr([], ['metaJson', 'flagPhotos'], svr);
    const newAnswers = [];
    let counter = questionAnswers.length;
    R.forEach(answer => {
      newAnswers.push(answer);
      const q = questions.find(q => q.type === "photo-input" && q.uuid === answer.questionUuid);
      if(q){
        const starPhoto = starPhotos.find(sp => sp.uuid === answer.answerValue);
        const flagPhoto = flagPhotos.find(fp => fp.uuid === answer.answerValue);
        counter++;
        const starAnswer = R.propOr(false, 'motive', starPhoto) ? `Yes: ${starPhoto.motive}` : 'Yes' ;
        newAnswers.push({
            id: counter,
            questionUuid: `${answer.questionUuid}-star-motive`,
            questionCanonicalKey: `${answer.questionCanonicalKey}-star-motive`,
            storeVisitId: answer.storeVisitId,
            answerValue: starPhoto ? starAnswer : '',
        });
        counter++;
        const flagAnswer = R.propOr(false, 'motive', flagPhoto) ? `Yes: ${flagPhoto.motive}` : 'Yes' ;
        newAnswers.push({
            id: counter,
            questionUuid: `${answer.questionUuid}-flag-motive`,
            questionCanonicalKey: `${answer.questionCanonicalKey}-flag-motive`,
            storeVisitId: answer.storeVisitId,
            answerValue: flagPhoto ? flagAnswer : '',
        });
      }
    } ,questionAnswers);
    return newAnswers;
  }, questionAnswers);

export default mergeFlagStarMotiveAnswers;
