import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';
import styled                    from 'styled-components';


const Container = styled.div`
  height: ${({ containerHeight }) => containerHeight ? 1250 : 1250}
`;

const styles = theme => ({
  WidgetContentClone : {
    position  : 'relative',
    overflow : 'hidden',
    //overflowY : 'scroll',
    //padding   : `18px 15px 20px ${theme.custom.spacing.md}`,
    margin    : '0 auto'
  },
});

const WidgetContentClone = ({ children, classes, containerHeight }) => (
  <Container className={classes.WidgetContentClone} containerHeight={containerHeight}>{children}</Container>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    children        : PropTypes.node,
    classes         : PropTypes.object.isRequired,
    theme           : PropTypes.object.isRequired,
    containerHeight : PropTypes.number
  })
)(WidgetContentClone);