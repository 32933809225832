import axios                                     from 'axios';
import createHistory                             from 'history/createBrowserHistory';
import { routerMiddleware }                      from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import axiosMiddleware                           from 'redux-axios-middleware';
import { persistStore, persistReducer }          from 'redux-persist';
import thunk                                     from 'redux-thunk';

import apiConfig                                 from './config/apiConfig';
import persistConfig                             from './config/persistConfig';
import axiosMiddlewareOptions                    from './middlewares/axiosMiddlewareOptions';
import rootReducer                               from './rootReducer';

const history = createHistory(
  { forceRefresh : true }
);

const axiosClient = axios.create(apiConfig);
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = compose(
  applyMiddleware(
    axiosMiddleware(axiosClient, axiosMiddlewareOptions),
    routerMiddleware(history),
    thunk
  ),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : a => a
)(createStore)(persistedReducer);

const persistor = persistStore(store);

export { store, persistor, history, axiosClient };
