import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import moment                    from 'moment';

import { withStyles } from '@material-ui/core/styles';

import { BigPlayButton, Player } from 'video-react';
import * as R from 'ramda';

import {
  ChevronLeft,
  ChevronRight,
} from '@material-ui/icons';

import styled from 'styled-components';

const styles = theme => ({
  flexContainer     : {
    display        : 'flex',
    position       : 'relative',
    flex           : 1,
    height         : '100%',
    flexDirection  : 'column',
    justifyContent : 'space-between'
  },
  top               : {
    padding         : `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    backgroundColor : theme.custom.colors.whiteAlt,
    zIndex          : 100
  },
  center            : {
    display         : 'flex',
    position        : 'absolute',
    flex            : 1,
    left            : 0,
    top             : 0,
    objectFit       : 'contain',
    width           : '100%',
    height          : '100%',
    alignItems      : 'center',
    justifyContent  : 'center'
  },
  imgContainer       : {
    display            : 'flex',
    position           : 'relative',
    flex               : 1,
    objectFit          : 'contain',
    cursor             : 'pointer',
    width              : '100%',
    height             : '100%',
    alignItems         : 'center',
    justifyContent     : 'center'
  },
  imgContainerLeft  : {
    position           : 'absolute',
    top                : '0',
    left               : '0',
    width              : '50%',
    height             : '100%',
    minHeight          : '100px',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    backgroundPosition : 'center'
  },
  imgContainerRight : {
    position           : 'absolute',
    top                : '0',
    left               : '50%',
    width              : '50%',
    height             : '100%',
    minHeight          : '100px',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    backgroundPosition : 'center'
  },
  img               : {
    display   : 'block',
    width     : '100%',
    height    : '100%',
    objectFit : 'contain'
  },
  bottom            : {
    justifyContent : 'center',
    textAlign      : 'center',
    margin         : '1rem',
    zIndex         : 100
  },
  controls          : {
    float  : 'right',
    right  : theme.spacing.unit,
    bottom : theme.spacing.unit
  },
  nextPrevSlide     : {
    paddingLeft : theme.spacing.unit / 2,
    color       : theme.custom.colors.black,
    fontWeight  : '300',
    fontSize    : '22px',
    cursor      : 'pointer'
  },
  imgContainerCenter    : {
    position           : 'absolute',
    width              : '100%',
    height             : '100%',
    minHeight          : '100px',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    backgroundPosition : 'center'
  },
  footerSpan        : {
    padding  : '.75rem',
    fontSize : '1em'
  },
  singleVideoTitle  : {
    textAlign : 'center'
  },
  afterVideoTitle   : {
    width     : '50%',
    textAlign : 'center'
  },
  beforeVideoTitle  : {
    width     : '50%',
    textAlign : 'center'
  },
  withVideoTitles   : {
    display : 'flex'
  }
});

const VideoContainer = styled.div`
  height: 100%;
  display            : flex;
  position           : relative;
  flex               : 1;
  objectFit          : contain;
  cursor             : pointer;
  width              : 100%;
  height             : 100%;
  alignItems         : center;
  justifyContent     : center;

  .video-react-video, .video-react {
    background-color  : white;
  }
`;

const updateRotation = (rotation) => (video) => {
  if(!video) {
    return;
  }

  const thisVideo: HTMLVideoElement = R.path(['video', 'video'], video);
  if(!thisVideo) {
    return;
  }
  thisVideo.style.transform = `rotate(${rotation}deg)`;
};

const VideoWidgetModalContent = ({
  classes,
  handleNextSlide,
  handlePreviousSlide,
  imgUrlAfter,
  imgUrlBefore,
  imgUrl,
  retailer,
  doorNumber,
  location,
  checkinDate,
  imgRotation,
  imgRotationAfter,
  imgRotationBefore
}) => (
  <div className={classes.flexContainer}>
    <div className={classes.top}>
      <div className={classes.controls}>
        <ChevronLeft  className={classes.nextPrevSlide} onClick={handlePreviousSlide} />
        <ChevronRight className={classes.nextPrevSlide} onClick={handleNextSlide}    />
      </div>
      <div className={classes.bottom}>
        <span className={classes.footerSpan}>
          <strong>Retailer:</strong> <span>{retailer}</span>
        </span>
        <span className={classes.footerSpan}>
         <strong>Location:</strong> <span>{location}</span>
        </span>
        <span className={classes.footerSpan}>
          <strong>Door:</strong> <span>{doorNumber}</span>
        </span>
      </div>
    </div>
    <VideoContainer>
      {!imgUrlAfter && !imgUrlBefore && (
        <div className={classes.center}>
          <div className={classes.imgContainerCenter}>
            <Player fluid={ false } src= { imgUrl } height={ "100%" } ref={ updateRotation(imgRotation) }><BigPlayButton position={"center"}/></Player>
          </div>
        </div>
      )}
      {(imgUrlAfter || imgUrlBefore) && (
        <div className={classes.center}>
          <div className={classes.imgContainerLeft}>
            <Player fluid = { false } src   = { imgUrlBefore || imgUrl } height = { "100%" } ref={ updateRotation(imgUrlBefore ? imgRotationBefore : imgRotation) }><BigPlayButton position={"center"}/></Player>
          </div>
          <div className={classes.imgContainerRight}>
            <Player fluid = { false } src   = { imgUrlAfter || imgUrl } height = { "100%" } ref={ updateRotation(imgUrlAfter ? imgRotationAfter : imgRotation) }><BigPlayButton position={"center"}/></Player>
          </div>
        </div>
      )}
    </VideoContainer>
    <div className={classes.bottom}>
      <div>{moment(checkinDate).format('L')}</div>
    </div>
  </div>
);

export default compose(
  withStyles(styles),
  setPropTypes({
    caption             : PropTypes.string,
    classes             : PropTypes.object.isRequired,
    handleNextSlide     : PropTypes.func.isRequired,
    handlePreviousSlide : PropTypes.func.isRequired,
    imgUrlAfter         : PropTypes.string,
    imgUrlBefore        : PropTypes.string,
  })
)(VideoWidgetModalContent);
