import React       from 'react';
import { NavLink, Route } from 'react-router-dom';
import styled             from 'styled-components';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';

import {
  compose,
  defaultProps,
  setPropTypes
} from 'recompose';

import Header           from '../../components/Header';
import SvrResponsesPage from '../svr-responses/pages/SvrResponsesPage';
// import AdministrationPage     from '../administration/pages/AdministrationPage';

import { deriveTenancyFilters } from '../../utils/deriveTenancyFilters';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser
} from '../client/ClientReducer';

import {
  setCurrentClient  as _setCurrentClient,
  setCurrentProject as _setCurrentProject
} from '../client/ClientActions';

import { fetchSvrs as _fetchSvrs } from '../svr/SvrActions';
import MealPeriodPage from '../report/pages/MealPeriodPage';
// import AttendancePage from '../report/pages/AttendancePage';

const StyledNavLink = styled(NavLink)`
  display         : flex;
  align-items     : center;
  height          : 32px;
  font-size       : 12px;
  margin-right    : 40px;
  font-weight     : lighter;
  letter-spacing  : 1px;
  color           : ${props => props.theme.main.black};
  border-bottom   : 2px solid transparent;
  border-top      : 2px solid transparent;
  text-decoration : none;
  cursor          : pointer;
  box-sizing      : border-box;
  transition      : .3s opacity;

  &:hover {
    opacity : 0.8;
  }

  &.selected {
    border-bottom : 4px solid #F3212E;
  }
`;

const NavBarWrapper = styled.div`
  display          : flex;
  flex-direction   : row;
  background-color : #fff;
  min-height       : 32px;
  padding-left     : 40px;
  box-shadow       :
    0px 0px 5px 0px rgba(0, 0, 0, 0.2),
    0px 1px 2px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px 0px rgba(0, 0, 0, 0.12);
`;

const SearchPage = ({
  clients,
  currentClient,
  currentProject,
  setCurrentClient,
  setCurrentProject,
  isClientUser
}) => (
  <div>
    <Header
      clients           = {clients}
      currentClient     = {currentClient}
      currentProject    = {currentProject}
      setCurrentClient  = {setCurrentClient}
      setCurrentProject = {setCurrentProject}
      isClientUser      = {isClientUser()}
    />
    <NavBarWrapper>
      <StyledNavLink to="/reporting/svrs" activeClassName="selected" margin="true">SVRs</StyledNavLink>
      {
        !isClientUser() ? (
          <StyledNavLink to="/reporting/meal-period" activeClassName="selected" margin="true">Meal Period</StyledNavLink>
        ) : null
      }
      <StyledNavLink to="/reporting/checkin" activeClassName="selected" margin="true">Check In</StyledNavLink>
      {/* {
        !isClientUser() ? (
          <StyledNavLink to="/reporting/attendance" activeClassName="selected" margin="true">Attendance</StyledNavLink>
        ) : null
      } */}
      {/* {
        !isClientUser() ? (
          <StyledNavLink to="/reporting/administration" activeClassName="selected" margin="true">Administration</StyledNavLink>
        ) : null
      } */}
    </NavBarWrapper>
    {
        <div>
          <Route path="/reporting/svrs"                             render={props => <SvrResponsesPage {...props} namespace="reporting" initialFilterList={{}} filterList={deriveTenancyFilters(currentClient, currentProject)} title="Reporting" />} />
          {!isClientUser() && <Route path="/reporting/meal-period"  render={props => <MealPeriodPage {...props} namespace="mealPeriod" initialFilterList={{}} filterList={{}} title="Meal Period" />} />}
          <Route path="/reporting/checkin" render={props => <SvrResponsesPage {...props} namespace="reporting" initialFilterList={{}} filterList={deriveTenancyFilters(currentClient, currentProject)} title="Check In" checkin={true} />} />
          {/* {!isClientUser() && <Route path="/reporting/attendance"   render={props => <AttendancePage {...props} namespace="attendance" initialFilterList={{}} filterList={{}} title="Attendance" />} />} */}
          {/* {!isClientUser() && <Route path="/reporting/administration"  component={AdministrationPage} />} */}
        </div>
    }
  </div>
);


const mapStateToProps = state => ({
  clients        : getClients(state),
  currentClient  : getCurrentClient(state),
  currentProject : getCurrentProject(state),
  isClientUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentClient : payload => {
    dispatch(_setCurrentClient(payload));
    dispatch(_fetchSvrs());
  },
  setCurrentProject : payload => {
    dispatch(_setCurrentProject(payload));
    dispatch(_fetchSvrs());
  }
});

const withManage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    clients : []
  }),
  setPropTypes({
    clients : PropTypes.array
  })
);

export default withManage(SearchPage);
