import * as R from 'ramda';

const namespace  = 'UPLOAD';

export const UPLOAD_FILE     = `${namespace}/UPLOAD_FILE`;
export const ADD_DOCUMENT    = `${namespace}/ADD_DOCUMENT`;
export const REMOVE_DOCUMENT = `${namespace}/REMOVE_DOCUMENT`;

export const uploadFile = (payload, onUploadProgress) => {
  const file = R.compose(R.defaultTo({}), R.head)(payload);

  if (!R.isEmpty(file)) {
    const formData = new FormData();
    formData.append('file', file);

    return {
      type    : UPLOAD_FILE,
      payload : {
        request: {
          method : 'POST',
          url    : '/api/v2/upload/docs',
          data   : formData,
          onUploadProgress
        },
      },
    };
  }
};

export const addDocument = payload => ({
  type: ADD_DOCUMENT,
  payload,
});

export const removeDocument = id => ({
  type: REMOVE_DOCUMENT,
  payload : {
    id,
    request : {
      method : 'DELETE',
      url    : `api/v2/upload/rmv`,
      data   : { id }
    }
  }
});
