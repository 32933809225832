import * as R      from 'ramda';
import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';

import {
  compose,
  defaultProps,
  lifecycle,
  setPropTypes,
  withHandlers,
  withStateHandlers
} from 'recompose';

import {
  fetchSvrResponses,
  filterByAction
} from '../../../modules/svr-responses/SvrResponsesActions';

import {
  getFilterList,
  getTableColumns,
  getSvrResponses
} from '../../../modules/svr-responses/SvrResponsesReducer';

import withUser from '../../../modules/auth';

import { getCurrentSavedDashboard } from '../../../modules/dashboard/SavedDashboardsReducer';
import { editSavedWidget }          from '../../../modules/saved-widgets/SavedWidgetsActions';

import SaveAsModal from '../../../components/SearchFilter/modals/SaveAs__Modal';
import { history } from '../../../store';

import FeedbackWidgetForm         from './FeedbackWidgetForm';
import QuestionResponseWidgetForm from './QuestionResponseWidgetForm';
import PhotoWidgetForm            from './PhotoWidgetForm';
import VideoWidgetForm            from './VideoWidgetForm';
import BarChartWidgetForm         from './BarChartWidgetForm';
import PieChartWidgetForm         from './PieChartWidgetForm';
import LineChartWidgetForm        from './LineChartWidgetForm';
import SumWidgetForm              from './SumWidgetForm';
import PercentageWidgetForm       from './PercentageWidgetForm';

const WIDGET_TYPES = {
  feedback         : {
    component        : FeedbackWidgetForm,
    inferDescription : false
  },
  questionResponse : {
    component        : QuestionResponseWidgetForm,
    inferDescription : true
  },
  barChart         : {
    component        : BarChartWidgetForm,
    inferDescription : true
  },
  lineChart        : {
    component        : LineChartWidgetForm,
    inferDescription : true
  },
  pieChart         : {
    component        : PieChartWidgetForm,
    inferDescription : true
  },
  sum              : {
    component        : SumWidgetForm,
    inferDescription : true
  },
  photo            : {
    component        : PhotoWidgetForm,
    inferDescription : false
  },
  video            : {
    component        : VideoWidgetForm,
    inferDescription : false
  },
  percentage       : {
    component        : PercentageWidgetForm,
    inferDescription : false
  },
};

const WidgetCreator = (
  {
    maybeFilterByAction,
    filterList,
    isOpen,
    savedWidgetFormData,
    deleteWidget,
    showWidgetCreationModal,
    toggleSaveAsWidgetModal,
    toggleWidgetCreationModal,
    updateSavedWidgetFormData,
    handleCloseWidgetCreationModal,
    svrResponses,
    userId,
    namespace,
    setFetchingData,
    syncFilters
  }) => {
    const widgetConfig                  = WIDGET_TYPES[savedWidgetFormData.metaJson.widgetType] || {},
          RespectiveWidgetTypeComponent = R.propOr(null, 'component')(widgetConfig);
    return (
      <div>
        <SaveAsModal
          namespace            = {namespace}
          onSave               = {() => { toggleWidgetCreationModal(true); }}
          title                = {savedWidgetFormData.title}
          description          = {savedWidgetFormData.description}
          inferDescription     = {widgetConfig.inferDescription}
          canSave              = {() => savedWidgetFormData.title && savedWidgetFormData.metaJson.widgetType}
          onCancel             = {() => toggleSaveAsWidgetModal(false)}
          onDelete             = {savedWidgetFormData.id ? () => deleteWidget(savedWidgetFormData.id) : null}
          restrictTokenUserId  = {savedWidgetFormData.restrictTokenUserId}
          tokenUserId          = {userId}
          showWhen             = {isOpen}
          widgetTypeSelect     = {R.keys(WIDGET_TYPES)}
          metaJson             = {savedWidgetFormData.metaJson}
          onChange             = {e => { updateSavedWidgetFormData(e.target.name, e.target.value); }}
        />

        {showWidgetCreationModal && (
          <RespectiveWidgetTypeComponent
            namespace           = {namespace}
            data                = {svrResponses}
            filterByAction      = {maybeFilterByAction}
            syncFilters         = {syncFilters}
            filterList          = {filterList}
            savedWidgetFormData = {savedWidgetFormData}
            isOpen              = {showWidgetCreationModal}
            onClose             = {handleCloseWidgetCreationModal}
            onSave              = {toggleSaveAsWidgetModal}
            setFetchingData     = {setFetchingData}
          />
        )}
      </div>
    );
  };

const maybeLoadInitialFilterListFromRouterProps = (state, props) => {
  return R.compose(
    R.mergeDeepLeft(props.filterList || {}),
    R.when(R.isEmpty, R.always(props.initialFilterList)),
    getFilterList(props.namespace)
  )(state);
};

const maybeDispatchFetchSvrResponses = (dispatch, namespace, filters) => {
  const activeFilters = R.filter(R.compose(R.not, R.isEmpty, R.prop('data')))(filters);
  if(Object.keys(activeFilters).length) {
    return dispatch(fetchSvrResponses(namespace, filters, [], true));
  }
  return Promise.resolve();
}; 

const mapStateToProps = (state, props) => ({
  currentSavedDashboard : getCurrentSavedDashboard(state),
  filterList            : maybeLoadInitialFilterListFromRouterProps(state, props),
  tableColumns          : getTableColumns(props.namespace)(state),
  svrResponses          : getSvrResponses(state, props.namespace)
});

const mapDispatchToProps = dispatch => ({
  fetchSvrResponses : (namespace, filters) => dispatch(fetchSvrResponses(namespace, filters)),
  editSavedWidget   : (data, id) => dispatch(editSavedWidget(data, id)),
  filterByAction      : (namespace, filters) => dispatch(fetchSvrResponses(namespace, filters)).then(() => dispatch(filterByAction(namespace, filters)))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    filterByAction    : () => {},
    filterList        : {},
    initialFilterList : {},
    isOpen            : false,
    setFetchingData   : () => {}
  }),
  setPropTypes({
    filterList        : PropTypes.object.isRequired,
    forms             : PropTypes.array,
    svrResponses      : PropTypes.array,
    initialFilterList : PropTypes.object,
    isOpen            : PropTypes.bool,
    namespace         : PropTypes.string.isRequired,
    setFetchingData   : PropTypes.func
  }),
  withUser,
  withStateHandlers(
    ({
       savedWidgetFormData = {
         title               : '',
         description         : '',
         metaJson            : {
           widgetType : '',
           namespace  : '',
           embedPhotosInExcelReport: false,
         },
         restrictTokenUserId : null
       },
       showSaveAsWidgetModal   = false,
       showWidgetCreationModal = false
     }) => ({
      savedWidgetFormData,
      showSaveAsWidgetModal,
      showWidgetCreationModal
    }),
    {
      deleteWidget                   : (_, { editSavedWidget, currentSavedDashboard }) => id => {
        editSavedWidget({ status : 0 }, id).then(() => {
          history.push(`/dashboard/saved/${currentSavedDashboard.id}`);
        });
      },
      toggleSaveAsWidgetModal        : (state, { toggleWidgetCreator }) => () => {
        toggleWidgetCreator();
      },
      toggleWidgetCreationModal      : () => bool => ({
        showWidgetCreationModal : bool
      }),
      handleCloseWidgetCreationModal : () => () => ({
        showWidgetCreationModal : false
      }),
      updateSavedWidgetFormData      : ({ savedWidgetFormData }) => (
        name,
        value
      ) => {
        const metaJsonProps = ['widgetType', 'namespace', 'embedPhotosInExcelReport'],
              path          = R.contains(name, metaJsonProps) ? ['metaJson', name] : [name];

        return {
          savedWidgetFormData : R.assocPath(path, value, savedWidgetFormData)
        };
      }
    }
  ),
  withHandlers({
    maybeFilterByAction: props => (namespace, filters) => {
      return Promise.resolve(props.setFetchingData(true))
      .then(() => maybeDispatchFetchSvrResponses(props.dispatch, namespace, filters))
      .then(() => props.dispatch(filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    syncFilters: props => (namespace, filters) => props.dispatch(filterByAction(namespace, filters))
  }),
  lifecycle({
    componentDidMount() {
      this.props.maybeFilterByAction(this.props.namespace, this.props.filters || {});
      this.props.updateSavedWidgetFormData('namespace', this.props.namespace);
    }
  })
)(WidgetCreator);
