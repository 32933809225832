import React, { Component } from 'react';
import styled               from 'styled-components';

import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core';

const Toolbar = styled.div`
  position   : relative;
  top        : 4rem;
  margin     : 0;
  text-align : right;
  padding    : 0 2rem;
`;

const INITIAL_STATE = {
  anchorEl : null
};

class SvrResponsesPageToolbar extends Component {

  state = INITIAL_STATE;

  handleClick = e => {
    this.setState({ anchorEl : e.currentTarget });
  };

  handleClose = e => {
    e.stopPropagation();
    this.setState(INITIAL_STATE);
  };

  render() {

    const { anchorEl } = this.state;
    const { onExport, selectedData } = this.props;

    return (
      <Toolbar>
        <Tooltip
          title      = {!selectedData.length ? 'Please first select rows to export.' : ''}
          placement  = "top-end"
          enterDelay = {300}
        >
          <div>
            <Button
              onClick  = {this.handleClick}
              disabled = {!selectedData.length}
              style    = {{
                border          : '2px solid #DE898C',
                color           : '#F3212E',
                backgroundColor : 'transparent',
                borderRadius    : '5px',
                padding         : '0.875rem',
                minWidth        : '120px',
                letterSpacing   : '1px',
              }}
            >
              Export
            </Button>
          </div>
        </Tooltip>
        <Menu
          id       = "export-menu"
          anchorEl = {anchorEl}
          open     = {Boolean(anchorEl)}
          onClose  = {this.handleClose}
        >
          <MenuItem onClick={e => { onExport('excel'); this.handleClose(e); }}>Save as Excel</MenuItem>
        </Menu>
      </Toolbar>
    );
  }
}

export default SvrResponsesPageToolbar;
