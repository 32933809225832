import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  titleWrapper : {
    padding   : 0,
    margin    : 0,
    listStyle : 'none'
  }
});

const Ul = ({ children, classes }) => (
  <ul className={classes.titleWrapper}>{children}</ul>
);

export default compose(
  withStyles(styles),
  setPropTypes({
    children : PropTypes.node,
    classes  : PropTypes.object.isRequired
  })
)(Ul);
