import moment from 'moment';

const checkDelay = (actualCheckIn, actualCheckOut, expectedDurationMinutes) => {

  if (actualCheckIn && actualCheckOut && expectedDurationMinutes) {

    const duration  = moment(actualCheckOut).diff(actualCheckIn, 'minute');

    if (duration > expectedDurationMinutes) {
      return `${duration - expectedDurationMinutes} minutes over`;
    }

    if (duration < expectedDurationMinutes) {
      return `${expectedDurationMinutes - duration} minutes short`;
    }

  }

  return '';
};

export default checkDelay;
