import * as R from 'ramda';

const mergeStarMotiveQuestion = (allowedMotiveQuestions = [], questions) => {
    const newQuestions = [];
    questions.forEach(q => {
        newQuestions.push(q);
        if(q.type === "photo-input" && !!R.find(R.equals(`${q.uuid}-star-motive`), allowedMotiveQuestions)){
          newQuestions.push({...q, type: "single-line-input", uuid: `${q.uuid}-star-motive`, canonicalKey: `${q.canonicalKey}-star-motive`, title: `Is "${q.title}" photo starred?`});
        }
    });
    return newQuestions;
};

export default R.curry(mergeStarMotiveQuestion);
