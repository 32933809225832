import * as R from 'ramda';

import ensureNumericTokenUserId from '../../utils/ensureNumericTokenUserId';

export const FETCH_ALL                   = 'SAVED_DASHBOARDS/FETCH_ALL';
export const FETCH_ALL_ERROR             = 'SAVED_DASHBOARDS/FETCH_ALL_ERROR';
export const FETCH_ALL_SUCCESS           = 'SAVED_DASHBOARDS/FETCH_ALL_SUCCESS';
export const SET_CURRENT_SAVED_DASHBOARD = 'SAVED_DASHBOARDS/SET_CURRENT_SAVED_DASHBOARD';
export const UPDATE                      = `SAVED_DASHBOARDS/UPDATE`;
export const CREATE                      = `SAVED_DASHBOARDS/CREATE`;

export const fetchSavedDashboards = useTenancy => dispatch => dispatch({
  types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
  payload : {
    request : {
      method : 'GET',
      url    : useTenancy ? '/api/v2/savedDashboard' : '/api/v2/savedDashboard/all'
    }
  }
});

export const setCurrentSavedDashboard = payload => ({
  type : SET_CURRENT_SAVED_DASHBOARD,
  payload
});

export const createSavedDashboard = payload => ({
  type    : CREATE,
  payload : {
    request : {
      method : 'POST',
      url    : `/api/v2/savedDashboard`,
      data   : ensureNumericTokenUserId(payload)
    }
  }
});

export const updateSavedDashboard = (savedDashboardId, payload) => ({
  type    : UPDATE,
  payload : {
    request : {
      method : 'PUT',
      url    : `/api/v2/savedDashboard/id/${savedDashboardId}`,
      data   : R.compose(
        ensureNumericTokenUserId,
        R.over(R.lensProp('widgetIds'), R.when(R.is(Array), R.join(','))),
        R.over(R.lensProp('metaJson'),  R.when(R.is(Object), JSON.stringify))
      )(payload)
    }
  }
});

