import uuid       from 'uuid/v4';
import * as R from 'ramda';

const COMMON_QUESTION_DELETED  = 0,
      COMMON_QUESTION_ACTIVE   = 1,
      COMMON_QUESTION_ARCHIVED = 2;

export const FETCH_ALL  = `COMMON_QUESTIONS/FETCH_ALL`;
export const CREATE     = `COMMON_QUESTIONS/CREATE`;
export const UPDATE     = `COMMON_QUESTIONS/UPDATE`;
export const EDIT       = `COMMON_QUESTIONS/EDIT`;
export const IS_EDITING = `COMMON_QUESTIONS/IS_EDITING`;
export const DELETE     = `COMMON_QUESTIONS/DELETE`;
export const ARCHIVE    = `COMMON_QUESTIONS/ARCHIVE`;
export const ACTIVE     = `COMMON_QUESTIONS/ACTIVE`;

export const fetchCommonQuestions = () => ({
  type    : FETCH_ALL,
  payload : {
    request : {
      method : 'GET',
      url    : '/api/v2/commonFormQuestion',
    }
  }
});

export const saveCommonQuestion = question => {
  return createCommonQuestion({ ...question });
};

export const createCommonQuestion = payload => ({
  type    : CREATE,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/commonFormQuestion',
      data   : {
        title        : payload.title,
        type         : payload.type,
        options      : JSON.stringify(payload.options),
        tags         : R.join(',', payload.tags),
        documentIds  : R.join(',', payload.documentIds),
        metaJson     : JSON.stringify(payload.meta),
        canonicalKey : uuid(),
        status       : 1
      }
    }
  }
});

export const saveEditedCommonQuestion = (question) => {
  return updateCommonQuestion(question.id, question);
};

export const updateCommonQuestion = (questionId, payload) => dispatch => {
  dispatch({
    type    : UPDATE,
    payload : {
      request : {
        method : 'PUT',
        url    : `/api/v2/commonFormQuestion/id/${questionId}`,
        data   :  R.omit(['id'], payload)
      }
    }
  });
};

export const isEditingAction = ( bool, questionObject ) => {
  return {
    type : IS_EDITING,
    payload: {
      status              : bool,
      questionBeingEdited : questionObject
    }
  };
};

const updateCommonQuestionStatus = (type, id) => status => dispatch => dispatch({
  type,
  payload : {
    request : {
      method       : 'PUT',
      url          : `/api/v2/commonFormQuestion/id/${id}`,
      responseType : 'json',
      data         : { status }
    }
  }
}).then(fetchCommonQuestions);

export const deleteCommonQuestion  = payload => updateCommonQuestionStatus(DELETE,  payload)(COMMON_QUESTION_DELETED);
export const archiveCommonQuestion = payload => updateCommonQuestionStatus(ARCHIVE, payload)(COMMON_QUESTION_ARCHIVED);
export const activeCommonQuestion  = payload => updateCommonQuestionStatus(ACTIVE,  payload)(COMMON_QUESTION_ACTIVE);
