import React          from 'react';
import PropTypes      from 'prop-types';

import { withStyles }          from '@material-ui/core/styles';
import { Toolbar, Typography } from '@material-ui/core';

import { toolbarStyles } from '../styled-components/QuestionTable';

import SortableTableByState from '../../../../components/SortableTableByState';


let EnhancedTableToolbar = props => {
  const { numSelected, classes, onFilterByStatus } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.typography}
            variant="title"
          >
            Common Questions
          </Typography>
        )}
      <SortableTableByState onFilterByStatus={onFilterByStatus} />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes     : PropTypes.object.isRequired,
  numSelected : PropTypes.number.isRequired
};

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
