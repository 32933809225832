import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes, withHandlers } from 'recompose';
import moment                    from 'moment';
import styled                    from 'styled-components';
import * as R                    from 'ramda';

import { withStyles } from '@material-ui/core/styles';

import { makeDecoratedImageUrl, getRotation } from '../../utils/image';

import {
  ChevronLeft,
  ChevronRight,

  StarBorder,
  FileDownload,
  Flag,
  OpenInNew,
  //RotateRight,
  Block
} from '@material-ui/icons';
import { Link }    from 'react-router-dom';

const styles = theme => ({
  flexContainer     : {
    height         : '100%',
    display        : 'flex',
    flexDirection  : 'column',
    justifyContent : 'space-between'
  },
  top               : {
    padding         : `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
    backgroundColor : theme.custom.colors.whiteAlt
  },
  center            : {
    display         : 'flex',
    position        : 'relative',
    backgroundColor : '#fff',
    flex            : 'auto'
  },
  imgContainer  : {
    height             : '100%',
    minHeight          : '100px',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    backgroundPosition : 'center'
  },
  img               : {
    display   : 'block',
    width     : '100%',
    height    : '100%',
    objectFit : 'contain'
  },
  bottom            : {
    justifyContent : 'center',
    textAlign      : 'center',
    margin         : '1rem'
  },
  controls          : {
    float  : 'right',
    right  : theme.spacing.unit,
    bottom : theme.spacing.unit
  },
  nextPrevSlide     : {
    paddingLeft : theme.spacing.unit / 2,
    color       : theme.custom.colors.black,
    fontWeight  : '300',
    fontSize    : '22px',
    cursor      : 'pointer'
  },
  footerSpan        : {
    padding  : '.75rem',
    fontSize : '1em'
  },
  singlePhotoTitle  : {
    textAlign : 'center'
  },
  afterPhotoTitle   : {
    width     : '50%',
    textAlign : 'center'
  },
  beforePhotoTitle  : {
    width     : '50%',
    textAlign : 'center'
  },
  withPhotoTitles   : {
    display : 'flex'
  },
  icon : {
    color        : 'white',
    paddingRight : '.375rem',
    cursor       : 'pointer'
  },
  activeIcon : {
    color        : 'red',
    paddingRight : '.375rem',
    cursor       : 'pointer'
  },
});

const ButtonsContainer = styled.div`
  position        : absolute;
  bottom          : 0;
  width           : 100%;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  box-sizing      : border-box;
`;

const IconsWrapper = styled.div`
  display         : flex;
  margin          : 12px;
  padding         : 7px;
  width           : 180px;
  justify-content : space-between;
  background      : rgba(54, 54, 54, 0.74);
  border-radius   : 15px;
`;

const CLOUDFLARE_IMAGE_WIDTH = 1280;

// const WRS_APP_DOCS_HOST = process.env.WRS_APP_DOCS_HOST;
// const WRS_APP_SEC_DOCS_HOST = process.env.WRS_APP_SEC_DOCS_HOST;
// const WRS_APP_REDUCED_DOCS_HOST = process.env.WRS_APP_REDUCED_DOCS_HOST;
// const WRS_APP_SEC_REDUCED_DOCS_HOST = process.env.WRS_APP_SEC_REDUCED_DOCS_HOST;

const WRS_APP_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
const WRS_APP_SEC_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
const WRS_APP_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";
const WRS_APP_SEC_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";

// const WRS_APP_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
// const WRS_APP_SEC_DOCS_HOST = "https://wrsphs.mstage.wintellplus.com";
// const WRS_APP_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";
// const WRS_APP_SEC_REDUCED_DOCS_HOST = "https://wrsphs.mstage.wintellplus.com";

const getSecUrl = (burl) =>
  {
   if (!R.isNil(burl) && burl.includes(WRS_APP_DOCS_HOST)) {
     const tempSecUrl = R.replace(WRS_APP_DOCS_HOST, WRS_APP_SEC_DOCS_HOST, burl);
     return tempSecUrl;
   }
   else if(!R.isNil(burl) && burl.includes(WRS_APP_REDUCED_DOCS_HOST))
   {
     const tempSecUrl = R.replace(WRS_APP_REDUCED_DOCS_HOST, WRS_APP_SEC_REDUCED_DOCS_HOST, burl);
     return tempSecUrl;
   }
   else
   {
     return burl;
   }  
  }

const downloadImage = ({ url, title }) => {

  var rotateurl270 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(270)";
  var rotateurl180 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(180)";
  var rotateurl90 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(90)";
  var rotateurl0 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(0)";

  var curl = "";
  if (url.includes(rotateurl270)) {
    curl = url.replace(rotateurl270, "");
    curl = WRS_APP_SEC_DOCS_HOST + curl;
  }
  else if (url.includes(rotateurl180)) {
    curl = url.replace(rotateurl180, "");
    curl = WRS_APP_SEC_DOCS_HOST + curl;
  }
  else if (url.includes(rotateurl90)) {
    curl = url.replace(rotateurl90, "");
    curl = WRS_APP_SEC_DOCS_HOST + curl;
  }
  else if (url.includes(rotateurl0)) {
    curl = url.replace(rotateurl0, "");
    curl = WRS_APP_SEC_DOCS_HOST + curl;
  }

  const tempSecUrl = getSecUrl(url); 

  let xhr = new XMLHttpRequest();
  xhr.open('GET', R.isEmpty(curl) ? tempSecUrl : curl , true);
  xhr.responseType = 'blob';
  xhr.onload       = function() {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);

    let a      = document.createElement('a');
    a.href     = imageUrl;
    a.download = title;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  xhr.send();
};

const buttonsActionContainer = ({
  classes,
  url,
  isClientUser,
  onStar,
  onBlock,
  onFlag,
  //onRotate,
  imgTitle,
  status
}) => {
  const { flag, block, star } = status;
  const { icon, activeIcon }  = classes;

  return (
    <div
      style={{flex: 1}}
    >
      <div className={classes.imgContainer} style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(url), CLOUDFLARE_IMAGE_WIDTH)(url)}')` }} />
      {
        isClientUser ? (
          <ButtonsContainer>
            <IconsWrapper style={{ width : '70px' }}>
              <FileDownload
                name      = "FileDownload"
                className = {icon}
                onClick   = {() => downloadImage({ url, title: imgTitle })}
              />
              <OpenInNew
                name      = "OpenInNew"
                className = {icon}
                onClick   = {() => window.open(`${ url }`)}
              />
            </IconsWrapper>
          </ButtonsContainer>
        ) : (
          <ButtonsContainer>
            <IconsWrapper>
              <StarBorder
                name      = "StarBorder"
                className = {star ? activeIcon : icon}
                onClick = {() => onStar(star)}
              />
              <Block
                name      = "Block"
                className = {block ? activeIcon : icon}
                onClick = {() => onBlock(block)}
              />
              <Flag
                name      = "Flag"
                className = {flag ? activeIcon : icon}
                onClick = {() => onFlag(flag)}
              />
              <FileDownload
                name      = "FileDownload"
                className = {icon}
                onClick   = {() => downloadImage({ url, title: imgTitle })}
              />
              <OpenInNew
                name      = "OpenInNew"
                className = {icon}
                onClick   = {() => window.open(`${ url }`)}
              />
              {/* <RotateRight
                name      = "RotatePhoto"
                className = {icon}
                onClick   = {() => onRotate(url)}
              /> */}
            </IconsWrapper>
          </ButtonsContainer>
        )
      }
    </div>
  );
};



const PhotoWidgetModalContent = ({
  classes,
  handleNextSlide,
  handlePreviousSlide,
  storeVisitId,
  imgUrlAfter,
  imgUuidAfter,
  imgUrlBefore,
  imgUuidBefore,
  imgUrl,
  imgUuid,
  imgTitle,
  imgTitleAfter,
  imgTitleBefore,
  retailer,
  doorNumber,
  location,
  checkinDate,
  isClientUser,
  status,
  statusAfter,
  statusBefore,
  actOn
}) => {
  const current = actOn(imgUuid, storeVisitId);
  const after = actOn(imgUuidAfter, storeVisitId);
  const before = actOn(imgUuidBefore, storeVisitId);

  const actionPayload = {
    isClientUser,
    url: imgUrl,
    classes,
    imgTitle,
    status,
    onStar: current('star'),
    onBlock: current('block'),
    onFlag: current('flag'),
    //onRotate: current('rotate')
  };
  return (
    <div className={classes.flexContainer}>
      <div className={classes.top}>
        <div className={classes.controls}>
          <ChevronLeft  className={classes.nextPrevSlide} onClick={handlePreviousSlide} />
          <ChevronRight className={classes.nextPrevSlide} onClick={handleNextSlide}    />
        </div>
        <div className={classes.bottom}>
          <span className={classes.footerSpan}>
            <strong>Retailer:</strong> <span>{retailer}</span>
          </span>
          <span className={classes.footerSpan}>
          <strong>Location:</strong> <span>{location}</span>
          </span>
          <span className={classes.footerSpan}>
            <strong>Door:</strong> <span>{doorNumber}</span>
          </span>
          <span className={classes.footerSpan}>
            <strong>Report # </strong>  
            <Link target="_blank" to={`/svr-response/${storeVisitId}`} >{storeVisitId}</Link>
          </span>
        </div>
      </div>
      {!imgUrlAfter && !imgUrlBefore && (
        <div className={classes.center}>
          {buttonsActionContainer(actionPayload)}
        </div>
      )}
      {imgUrlAfter && (
        <div className={classes.center}>
          {buttonsActionContainer(actionPayload)}
          {buttonsActionContainer(R.mergeDeepRight(actionPayload, {
            status: statusAfter,
            imgTitle: imgTitleAfter,
            url: imgUrlAfter,
            onStar: after('star'),
            onBlock: after('block'),
            onFlag: after('flag'),
            //onRotate: after('rotate')
          }))}
        </div>
      )}
      {imgUrlBefore && (
        <div className={classes.center}>
          {buttonsActionContainer(R.mergeDeepRight(actionPayload, {
            status: statusBefore,
            imgTitle: imgTitleBefore,
            url: imgUrlBefore,
            onStar: before('star'),
            onBlock: before('block'),
            onFlag: before('flag'),
            //onRotate: before('rotate')
          }))}
          {buttonsActionContainer(actionPayload)}
        </div>
      )}
      <div className={classes.bottom}>
        <div>{moment(checkinDate).format('L')}</div>
      </div>
    </div>
  );
};

export default compose(
  withStyles(styles),
  withHandlers({
    actOn: props => (uuid, storeVisitId) => type => alreadyApplied => {
      props.onAction(type, alreadyApplied, { uuid, storeVisitId });
    }
  }),
  setPropTypes({
    caption             : PropTypes.string,
    classes             : PropTypes.object.isRequired,
    handleNextSlide     : PropTypes.func.isRequired,
    handlePreviousSlide : PropTypes.func.isRequired,
    imgUrlAfter         : PropTypes.string,
    imgUuidAfter        : PropTypes.string,
    imgUrlBefore        : PropTypes.string,
    imgUuidBefore       : PropTypes.string,
    isClientUser        : PropTypes.bool,
    imgTitle            : PropTypes.string,
    imgTitleAfter       : PropTypes.string,
    imgTitleBefor       : PropTypes.string,
    status              : PropTypes.shape(),
    statusAfter         : PropTypes.shape(),
    statusBefore        : PropTypes.shape(),
    storeVisitId        : PropTypes.number,
    onAction            : PropTypes.func
  })
)(PhotoWidgetModalContent);
