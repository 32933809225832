import React     from 'react';
import styled    from 'styled-components';
import PropTypes from 'prop-types';

import QuestionItem  from './QuestionItem';
import QuestionGroup from './QuestionGroup';

import { ITEM_TYPES } from '../../svr/BuilderReducer';

const SectionRow = styled.div`
  margin           : 2rem 0 0 0;
  padding          : .5rem;
  background-color : #E4E4E5;
  border-bottom    : 2px solid #DCDCDC;
  display          : flex;
  align-items      : center;
  color            : #909091;
  font-size        : .875rem;
  font-weight      : bold;
`;

const SavedRollupForm = ({
  preSections,
  duringSections,
  postSections,
  handleSelectQuestion,
  selectedQuestions,
  onlyOne,
  onlyPhotos,
  onlyVideos,
  onlyNumeric,
  onlyMultipleChoice,
  filteredQuestions,
  filterSelectedValue,
  isClientUser
}) => (
  <div style={{ margin: '1rem 0 1rem 1rem' }}>
    {
      [
        preSections,
        duringSections,
        postSections
      ].map(sectionsChunk => sectionsChunk.map(s => (
        <div key={s.uuid}>
          <SectionRow>{`Section: ${s.title}`}</SectionRow>
          {s.content.map(q => (
            q.type === ITEM_TYPES.QUESTION_GROUP ? (
              <QuestionGroup
                key                  = {q.uuid}
                title                = {q.title}
                content              = {q.content}
                handleSelectQuestion = {handleSelectQuestion}
                selectedQuestions    = {selectedQuestions}
                onlyOne              = {onlyOne}
                onlyPhotos           = {onlyPhotos}
                onlyVideos           = {onlyVideos}
                onlyNumeric          = {onlyNumeric}
                onlyMultipleChoice   = {onlyMultipleChoice}
                filteredQuestions    = {filteredQuestions}
                filterSelectedValue  = {filterSelectedValue}
                isClientUser         = {isClientUser}
              />
            ) : (
              <QuestionItem
                key                  = {q.uuid}
                options              = {q.options}
                title                = {q.title}
                answerTitle          = {''}
                question             = {q}
                handleSelectQuestion = {handleSelectQuestion}
                selectedQuestions    = {selectedQuestions}
                onlyOne              = {onlyOne}
                onlyPhotos           = {onlyPhotos}
                onlyVideos           = {onlyVideos}
                onlyNumeric          = {onlyNumeric}
                onlyMultipleChoice   = {onlyMultipleChoice}
                filteredQuestions    = {filteredQuestions}
                filterSelectedValue  = {filterSelectedValue}
                isInternalQuestion   = {q.meta.isHidden}
                isConditional        = {q.meta.isConditional}
                isClientUser         = {isClientUser}
              />
            )
          ))}
        </div>
      )))
    }
  </div>
);

SavedRollupForm.propTypes = {
  handleSelectQuestion : PropTypes.func.isRequired,
  preSections          : PropTypes.array,
  duringSections       : PropTypes.array,
  postSections         : PropTypes.array,
  selectedQuestions    : PropTypes.array,
  filteredQuestions    : PropTypes.array,
  onlyOne              : PropTypes.bool,
  onlyPhotos           : PropTypes.bool,
  onlyVideos           : PropTypes.bool,
  onlyNumeric          : PropTypes.bool,
  onlyMultipleChoice   : PropTypes.bool,
  filterSelectedValue  : PropTypes.string,
  isClientUser         : PropTypes.bool
};

SavedRollupForm.defaultProps = {
  preSections         : [],
  duringSections      : [],
  postSections        : [],
  selectedQuestions   : [],
  onlyOne             : false,
  onlyPhotos          : false,
  onlyVideos          : false,
  onlyNumeric         : false,
  onlyMultipleChoice  : false,
  filterSelectedValue : 'all',
  filteredQuestions   : [],
  isClientUser        : false
};

export default SavedRollupForm;
