import React     from 'react';
import PropTypes from 'prop-types';
import Modal     from '../../components/Modal/Modal';

const NOTIFICATION_SENT_STATUSES = {
  '1' : 'send',
  '2' : 'cancel',
};

const ConfirmNotificationModal = ({ onApprove, onCancel, status }) => (
  <Modal
    onApprove={onApprove}
    onCancel={onCancel}
  >
    {`Are you sure you want to ${NOTIFICATION_SENT_STATUSES[status]} this Notification?`}
  </Modal>
);

ConfirmNotificationModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
};

export default ConfirmNotificationModal;
