import React, { Component }     from 'react';
import styled    from 'styled-components';
import * as R    from 'ramda';

import DateTimePicker from "react-datetime-picker";

import { MenuItem, Select, TextField } from '@material-ui/core';

import Modal from './components/Modal';

const Container = styled.div`
  width        : 100%;
  padding-left : 20px;

  p {
    font-size   : 13px;
    font-weight : bold;
    color       : #5E5D5F;
  }
`;

const SelectContainer = styled.div`
  margin : 1rem 2rem 2rem 2rem;

  p {
    font-size   : .875rem;
    font-weight : 500;
  }
`;

const IMMEDIATE_NOTIF = {
  value: 0,
  label: 'IMMEDIATE'
};
const SCHEDULED_NOTIF = {
  value: 1,
  label: 'SCHEDULED'
};

const NOTIFICATION_TYPES = [
  IMMEDIATE_NOTIF,
  SCHEDULED_NOTIF
];

class SendNotificationModal extends Component {
  state = {
    notificationValue: 0,
    dateValue: new Date(),
    titleValue: '',
    messageValue: '',
    actionValue: 0
  }

  changeNotificationType = e => {
    this.setState({
      notificationValue: e.target.value
    });
  }

  changeAction = e => {
    this.setState({
      actionValue: e.target.value
    });
  }

  changeNotificationDate = e => {
    this.setState({
      dateValue: e
    });
  }

  changeTitle = e => {
    this.setState({
      titleValue: e.target.value
    });
  }

  changeMessage = e => {
    this.setState({
      messageValue: e.target.value
    });
  }

  canCreateNotification = () => {
    const { notificationValue, titleValue, messageValue, actionValue } = this.state;
    const { selectedData } = this.props;

    return !R.isEmpty(titleValue) && !R.isEmpty(messageValue) && R.gte(notificationValue, 0) && R.gte(actionValue, 0) && R.gt(R.length(selectedData), 0);
  }


  render () {
    const { notificationValue, dateValue, titleValue, messageValue, actionValue } = this.state;
    const { onSend, onCancel } = this.props;

    const isScheduled = SCHEDULED_NOTIF.value === notificationValue;
    
    return (
      <Modal
        onSend    = {() => this.canCreateNotification() && onSend({
          title: titleValue,
          message: messageValue,
          data: {
            action: actionValue,
          },
          type: notificationValue,
          config: {
            scheduled: {
              date: isScheduled ? dateValue : null
            }
          }
        })}
        onCancel  = {onCancel}
        toolTip   = {!this.canCreateNotification() ? 'Please fill all the information.' : ''}
      >

        <Container>
          <h2>{"Send Notification"}</h2>

          <SelectContainer>
            <p>Title</p>
            <TextField
              fullWidth
              autoFocus
              id              = "title"
              value           = {titleValue}
              margin          = "none"
              onChange        = {this.changeTitle}
              multiline
              InputLabelProps = {{
                shrink : true
              }}
            />
          </SelectContainer>
          <SelectContainer>
            <p>Message</p>
            <TextField
              fullWidth
              id              = "message"
              value           = {messageValue}
              margin          = "none"
              onChange        = {this.changeMessage}
              multiline
              InputLabelProps = {{
                shrink : true
              }}
            />
          </SelectContainer>
          <SelectContainer>
            <p>Notification Type</p>
            <Select
                fullWidth
                value    = {notificationValue}
                onChange = {this.changeNotificationType}
              >
                {NOTIFICATION_TYPES.map(o => (<MenuItem key={o.value} value={o.value}>{o.label}</MenuItem>))}
            </Select>
          </SelectContainer>
          {
            isScheduled && (
              <SelectContainer>
                <p>Schedule Date and Time</p>
                <DateTimePicker
                  minDate={new Date()}
                  value={dateValue}
                  onChange={this.changeNotificationDate}
                />
              </SelectContainer>
            )
          }
        </Container>
      </Modal>
    );
  }
}

export default SendNotificationModal;
