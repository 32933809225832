import React     from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/Modal';

const ChangeToAffectsModal = ({ onApprove, onCancel, text }) => (
  <Modal
    onApprove={onApprove}
    onCancel={onCancel}
  >
    { text }
  </Modal>
);

ChangeToAffectsModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
  text      : PropTypes.string
};

export default ChangeToAffectsModal;
