import * as R from 'ramda';
import uuidv4 from 'uuid/v4';

const MATCH_UUID_PROP = /"uuid":"[\w-]+"/g,
      RUSTY_PROPS     = ['id', 'createdAt', 'updatedAt', 'canonicalKey'],
      newUuid         = () => `"uuid":"${uuidv4()}"`;

const makeFormClone = R.compose(
  R.omit(RUSTY_PROPS),
  JSON.parse,
  R.replace(MATCH_UUID_PROP, newUuid),
  JSON.stringify
);

export default makeFormClone;
