import React   from 'react';
import styled  from 'styled-components';
import * as R  from 'ramda';

import {
  defaultProps,
  compose,
  withStateHandlers
} from 'recompose';
import NotificationDetailInfo   from './NotificationDetailInfo';
import ConfirmNotificationModal from '../../../modals/ConfirmNotificationModal';
import { Tooltip } from '@material-ui/core';

const NOTIFICATION_SENT_STATUSES = {
  SEND  : 1,
  CANCEL: 2
};

const Wrapper = styled.div`
  background-color : #fff;
  margin-right     : auto;
  margin-left      : auto;
  margin-bottom    : 100px;
  margin-top       : 30px;
  width            : 96%;
  box-shadow       : 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const StyledHeading = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : space-between;
  height          : 65px;
  border-bottom   : 1px solid #D3D3D3;
  padding         : 10px 30px;
  color           : #414042;

  p {
    font-size: 22px;
  }
`;

const Footer = styled.div`
  display          : flex;
  align-items      : center;
  justify-content  : space-between;
  background-color : #F8F8F8;
  color            : ${({ isSelected }) => !isSelected ? '#F3212E' : '#939598'};
  position         : fixed;
  bottom           : 0;
  width            : 100%;
  padding          : 1rem 2rem;
  left             : 0;
  width            : 100vw;
  border-top       : solid 2px rgb(220, 220, 220);
  box-sizing       : border-box;
  z-index          : 2;
`;

const ButtonsContainer = styled.div`
  display : flex;
`;

const Button = styled.div`
  color  : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'};
  margin : 0 1rem;

  &:hover {
    cursor : ${({ isSelected }) => isSelected ? 'pointer' : 'default'};
  }
`;

const NotificationDetail = ({
  notification,
  isClientUser,
  namespace,
  isOpenConfirmModal,
  selectedStatus,
  onOpenModal,
  onCloseModal,
  onApproved
}) => {
  const toolTip       = R.compose(R.ifElse(R.gte(0), R.always(''), R.always("This notification can't be modified")), R.prop('sent'))(notification);
  const buttonEnabled = !toolTip;
  return (
    <div id="notificatoin-detail-wrapper">
      {notification && notification.id ? (
      <Wrapper>
        <StyledHeading>
          <p>{`${notification.id} | ${notification.title}`}</p>
        </StyledHeading>

        <NotificationDetailInfo
          notification         = {notification}
          isClientUser        = {isClientUser()}
          namespace           = {namespace}
        />
        {isOpenConfirmModal && (
          <ConfirmNotificationModal
            status    = {selectedStatus}
            onApprove = {onApproved}
            onCancel  = {onCloseModal}
          />
        )}
        {
          !isClientUser() ? (
            <Footer className='no-print'>
              <ButtonsContainer>
                <Tooltip
                  title      = {toolTip}
                  placement  = "top-end"
                  enterDelay = {300}
                >
                  <div>
                    <Button
                      disabled   = {buttonEnabled}
                      onClick    = {() => buttonEnabled && onOpenModal(NOTIFICATION_SENT_STATUSES.SEND)}
                      isSelected = {buttonEnabled}
                    >
                      SEND
                    </Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title      = {toolTip}
                  placement  = "top-end"
                  enterDelay = {300}
                >
                  <div>
                    <Button
                      disabled   = {buttonEnabled}
                      onClick    = {() => buttonEnabled && onOpenModal(NOTIFICATION_SENT_STATUSES.CANCEL)}
                      isSelected = {buttonEnabled}
                    >
                      CANCEL
                    </Button>
                  </div>
                </Tooltip>
              </ButtonsContainer>
            </Footer>
          ) : null
        }
      </Wrapper>
      ) : null}
    </div>
  );
};

const withHandlers = compose(
  withStateHandlers(
    ({
      feedbackMessage              = '',
      currentQuestion              = '',
      isAnswerRejected             = false,
      isOpenFeedbackModal          = false,
      isOpenConfirmModal           = false,
      isOpenAnswerFeedbackModal    = false,
      initialCarouselPhoto         = null,
      quickReviewMode              = false,
      selectedStatus               = null,
      isPhotoReviewModalOpen       = false,
      isOpenImpossibleEditSvrModal = false,
      answerValue                  = ''
    }) => ({
      feedbackMessage,
      isOpenFeedbackModal,
      isPhotoReviewModalOpen,
      selectedStatus,
      quickReviewMode,
      isOpenConfirmModal,
      isOpenAnswerFeedbackModal,
      initialCarouselPhoto,
      currentQuestion,
      answerValue,
      isAnswerRejected,
      isOpenImpossibleEditSvrModal
    }),
    ({
      onApproved  : ({selectedStatus}, {notification, onSend, onCancel}) => () => {
        if(selectedStatus === NOTIFICATION_SENT_STATUSES.SEND) {
          onSend(notification.id);
        } else if(selectedStatus === NOTIFICATION_SENT_STATUSES.CANCEL) {
          onCancel(notification.id);
        }
        return {
          isOpenConfirmModal : false,
          selectedStatus     : null
        };
      },
      onOpenModal : () => (status) => ({
        isOpenConfirmModal  : true,
        selectedStatus      : status
      }),
      onCloseModal : () => () => ({
        isOpenConfirmModal : false,
        selectedStatus     : null
      })
    })
  ),
  defaultProps({
    isClientUser : () => {}
  })
);

export default withHandlers(NotificationDetail);
