const SCROLL_OFFSET = 20;

function scrollDown() {
  const nextScrollTop = document.documentElement.scrollTop + SCROLL_OFFSET;
  window.scrollTo(0, nextScrollTop);
}

function scrollUp() {
  const nextScrollTop = document.documentElement.scrollTop - SCROLL_OFFSET;
  if (nextScrollTop >= 0) {
    window.scrollTo(0, nextScrollTop);
  }
}

export default class SmoothScroll {
  data = {
    isDragging: false
  };
  constructor() {
    this.addEventListeners();
  }

  onDragStart = () => {
    this.data = {
      isDragging: true,
      lastMouseClientY: undefined
    };
    this.smoothscroll();
  };

  onDragEnd = () => {
    this.data = {
      isDragging: false,
      lastMouseClientY: undefined
    };
  };

  addEventListeners = () => {
    window.addEventListener("dragover", (e) => {
      this.data.lastMouseClientY = e.clientY;
    });
  };

  smoothscroll = () => {
    const { isDragging, lastMouseClientY } = this.data;
    if (isDragging) {
      if (lastMouseClientY !== undefined) {
        if (lastMouseClientY > window.innerHeight - 100) {
          scrollDown();
        } else if (lastMouseClientY < 100) {
          scrollUp();
        }
      }
      window.requestAnimationFrame(this.smoothscroll);
    }
  };
}
