import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons/';

const StyledHeader = styled.div`
  display         : flex;
  justify-content : flex-end;
  background      : white;
  padding         : .5rem;
  box-sizing      : border-box;
`;

const ArrowWrapper = styled.div`
  display : flex;
  cursor  : pointer;
`;

const Header = props => (
  <StyledHeader>
    <ArrowWrapper>
      <KeyboardArrowLeft onClick={props.previousPhoto}/>
      <KeyboardArrowRight onClick={props.nextPhoto}/>
    </ArrowWrapper>
  </StyledHeader>

);

Header.propTypes = {
  Detail : PropTypes.string
};

export default Header;
