import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import Footer from './Footer';

const PModal = ({ withoutCancel, withoutApprove, children, onApprove, onCancel, style={} ,logoutText }) => (
  <PModal.Wrapper>
    <PModal.Container style={style}>
      <PModal.Package>
        {children}
      </PModal.Package>
      <Footer
        withoutCancel  = {withoutCancel}
        withoutApprove = {withoutApprove}
        onApprove      = {onApprove}
        onCancel       = {onCancel}
        logoutText     = {logoutText}
      />
    </PModal.Container>
  </PModal.Wrapper>
);

PModal.propTypes = {
  withoutCancel : PropTypes.bool,
  children      : PropTypes.node.isRequired,
  onApprove     : PropTypes.func.isRequired,
  onCancel      : PropTypes.func.isRequired,
};

PModal.defaultProps = {
  withoutCancel : false,
};

PModal.Wrapper = styled.div`
  top      : 0;
  left     : 0;
  bottom   : 0;
  width    : 100%;
  height   : 100%;
  position : fixed;
  z-index  : 9999;
`;

PModal.Container = styled.div`
  position      : relative;
  width         : 500px;
  height        : 170px;
  background    : #fff;
  margin        : 5px auto;
  border-radius : 3px;
  box-shadow    : 0 2px 4px rgba(0, 0, 0, .2);
  top           : 40%;
`;

PModal.Package = styled.div`
  display         : flex;
  align-items     : center;
  text-align      : center;
  justify-content : center;
  height          : 80%;
  padding         : 0 60px;
  text-indent     : 5px;
  font-weight     : 300;
  font-size       : 16px;
  color           : black;
`;

export default PModal;