import styled       from 'styled-components';
import { TableRow } from '@material-ui/core';
import { lighten }  from '@material-ui/core/styles/colorManipulator';

const HEIGHT   = 30;

export const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-start;
  padding-left    : 30px;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 7px;

  & > div {
    margin-right : 20px;
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'}
    cursor       : pointer;
  }
`;

export const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

export const toolbarStyles = theme => ({
  root       : {
    paddingRight : theme.spacing.unit,
    minHeight    : '53px',
    padding      : '0px 8px 25px 15px'
  },
  highlight  :
    theme.palette.type === 'light'
      ? {
        color           : theme.palette.secondary.main,
        backgroundColor : lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color           : theme.palette.text.primary,
        backgroundColor : theme.palette.secondary.dark
      },
  spacer     : {
    flex : '1 1 100%'
  },
  actions    : {
    color : theme.palette.text.secondary
  },
  title      : {
    width          : '100%',
    display        : 'flex',
    justifyContent : 'space-between'
  },
  typography : {
    fontWeight    : 400,
    fontSize      : '22px',
    letterSpacing : '1px'
  }
});

export const styles = () => ({
  root         : {
    width       : '96%',
    marginTop   : '12px',
    marginLeft  : 'auto',
    marginRight : 'auto'
  },
  table        : {
    minWidth : 800
  },
  tableWrapper : {
    overflowX : 'auto'
  }
});
