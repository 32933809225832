// TODO: add react-router-scroll
import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { Route, Router, Redirect, Switch } from 'react-router';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { store, persistor, history } from './store';
import Theme from './components/Theme';
import { MuiThemeProvider } from '@material-ui/core/styles';

import PrivateRoute from './modules/auth/components/PrivateRoute';

import NotFoundPage from './modules/app/pages/NotFoundPage';
//import LoginPage from './modules/auth/pages/LoginPage';
import CLoginPage from './modules/auth/pages/CLoginPage';
import LogoutPage from './modules/auth/pages/LogoutPage';
import ManagePage from './modules/manage';
import SearchPage from './modules/search';
import AccountPage from './modules/account';
import AdminPage from './modules/admin';
import ReportPage from './modules/report';
import SvrBuilderPage from './modules/svr/pages/SvrBuilderPage';
import SvrResponseReviewPage from './modules/svr-responses/pages/SvrResponseReviewPage';
import NotiifcationsPage from './modules/notifications';
import NotificationDetailPage from './modules/notifications/pages/NotificationDetailPage';

import theme from './styles/theme';

import './index.css';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const renderApp = () => {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router history={history}>
          <ThemeProvider theme={Theme}>
            <MuiThemeProvider theme={theme}>
              <DragDropContextProvider backend={HTML5Backend}>
                <div>
                  <Helmet
                    defaultTitle="Winston Retail"
                    titleTemplate="%s - Winston Retail"
                  />
                  <Switch>
                    {/* Dashboard - default page */}
                    <Route
                      exact
                      path="/"
                      component={() => <Redirect to="/dashboard/dashboards" />}
                    />

                    {/* Public pages */}                    
                    {/* <Route path="/login" component={LoginPage} />   */}
                    <Route path="/login" component={CLoginPage} /> 
                    <Route path="/ulg" component={CLoginPage} /> 
                    <Route path="/logout" component={LogoutPage} />
 
                    {/* Private pages */}
                    <PrivateRoute path="/dashboard" component={ReportPage} />
                    <PrivateRoute path="/manage" component={ManagePage} />
                    <PrivateRoute path="/reporting" component={SearchPage} />
                    <PrivateRoute path="/notifications" component={NotiifcationsPage} />
                    <PrivateRoute path="/account" component={AccountPage} />
                    <PrivateRoute path="/admin" component={AdminPage} />
                    <PrivateRoute
                      path="/svrs/create"
                      component={SvrBuilderPage}
                    />
                    <PrivateRoute
                      path="/svrs/:canonicalKey"
                      component={SvrBuilderPage}
                    />
                    <PrivateRoute
                      path="/svr-response/:id"
                      component={SvrResponseReviewPage}
                    />
                    <PrivateRoute
                      path="/notification/id/:id"
                      component={NotificationDetailPage}
                    />

                    {/* Catch all routes */}

                    <Route component={NotFoundPage} />
                  </Switch>

                  {/* Toasts */}
                  <ToastContainer />
                </div>
              </DragDropContextProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('root')
  );
};

renderApp();
