import * as R              from 'ramda';

const namespace = 'DOCUMENT';

export const FETCH_DOCUMENTS         = `${namespace}/FETCH_DOCUMENTS`;
export const UPDATE_DOCUMENT         = `${namespace}/UPDATE_DOCUMENT`;
export const REMOVE_DOCUMENT         = `${namespace}/REMOVE_DOCUMENT`;
export const DOWNLOAD_ZIP            = `${namespace}/DOWNLOAD_ZIP`;
export const DOWNLOAD_ZIP_START      = `${namespace}/DOWNLOAD_ZIP_START`;
export const HIDE_DOWNLOAD_ZIP_MODAL = `${namespace}/HIDE_DOWNLOAD_ZIP_MODAL`;

export const fetchDocuments = (namespace, uuids=[]) => ({
  type    : FETCH_DOCUMENTS,
  payload : {
    namespace,
    request : {
      method : 'POST',
      url    : `/api/v2/document/uuids`,
      data   : { uuids : R.compose(R.filter(R.identity), R.uniq)(uuids) }
    }
  }
});

export const downloadZip = (uuids=[]) => dispatch => {

  dispatch({
    type    : DOWNLOAD_ZIP_START,
    payload : {}
  });

  return dispatch({
    type    : DOWNLOAD_ZIP,
    payload : {
      request : {
        method  : 'POST',
        timeout : 300000,
        url     : `/api/v2/document/zip`,
        data    : { uuids : R.compose(R.filter(R.identity), R.uniq)(uuids) }
      }
    }
  });
};

export const hideDownloadZipModal = () => ({
  type    : HIDE_DOWNLOAD_ZIP_MODAL,
  payload : null
});

export const updateDocument = (documentUuid, data, namespace) => ({
  type    : UPDATE_DOCUMENT,
  payload : {
    namespace,
    request : {
      method : 'PUT',
      url    : `/api/v2/document/uuid/${documentUuid}`,
      data
    }
  }
});

export const removeDocument = id => ({
  type    : REMOVE_DOCUMENT,
  payload : {
    id,
    request : {
      method : 'DELETE',
      url    : `/api/v2/document/id/${id}`
    }
  }
});
