import * as R from 'ramda';

export const FETCH_ALL           = `CLIENT/FETCH_ALL`;
export const FETCH_ALL_SUCCESS   = `CLIENT/FETCH_ALL_SUCCESS`;
export const FETCH_ALL_ERROR     = `CLIENT/FETCH_ALL_ERROR`;
export const SET_CURRENT_CLIENT  = `CLIENT/SET_CURRENT_CLIENT`;
export const SET_CURRENT_PROJECT = `CLIENT/SET_CURRENT_PROJECT`;

export const FETCH_AUTOCOMPLETE           = `CLIENT/FETCH_AUTOCOMPLETE`;
export const FETCH_AUTOCOMPLETE_SUCCESS   = `CLIENT/FETCH_AUTOCOMPLETE_SUCCESS`;
export const FETCH_AUTOCOMPLETE_ERROR     = `CLIENT/FETCH_AUTOCOMPLETE_ERROR`;
export const CLEAR_AUTOCOMPLETE           = `CLIENT/CLEAR_AUTOCOMPLETE`;

export const setCurrentClient = payload => ({
  type: SET_CURRENT_CLIENT,
  payload,
});

export const setCurrentProject = payload => ({
  type: SET_CURRENT_PROJECT,
  payload,
});

export const fetchClientProjectList = () => dispatch => dispatch({
  types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
  payload : {
    request : {
      method : 'GET',
      url    : '/api/v2/clientProjectList'
    }
  }
});

export const fetchAutocomplete = (payload, model = 'storeVisit') => dispatch => dispatch({
  types   : [FETCH_AUTOCOMPLETE, FETCH_AUTOCOMPLETE_SUCCESS, FETCH_AUTOCOMPLETE_ERROR],
  payload : {
    request : {
      method : 'GET',
      url    : `/api/v2/${model}/autocomplete/${R.compose(R.join('/'), R.values)(payload)}`
    }
  }
});

export const clearAutocomplete = () => ({
  type   : CLEAR_AUTOCOMPLETE
});
