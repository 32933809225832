import React, {
  Component,
}                from 'react';
import PropTypes from 'prop-types';
import {
  connect,
}                from 'react-redux';
import DropZone  from 'react-dropzone';
import * as R    from 'ramda';
import AddIcon   from '@material-ui/icons/AddCircleOutline';
import styled    from 'styled-components';

import {
  uploadFile as _uploadFile
} from '../UploadActions';

const SUPPORTED_MIME_TYPES = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
  'video/mp4',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const AddButtonWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  width          : 200px;
  padding        : 4px 10px;
  background     : #fff;
  border-radius  : 3px;
  box-shadow     : 0px 0px 5px 0px rgba(119, 119, 119, 0.5);
  margin-left    : 8px;
`;


const AddButton = styled.div`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  width           : 100%;
  cursor          : pointer;

  span {
    font-size   : 14px;
    font-weight : 500;
  }
`;

class Uploader extends Component {
  upload = fileData => {
    const { uploadFile, onUpload, handleFile, progressCB, beginUpload } = this.props;
    beginUpload();
    uploadFile(fileData, progressCB).then(action => {
      if(!R.pathEq(['payload', 'status'], 200, action)) {
        return progressCB({
          error: R.compose(
            R.when(R.isNil, () => R.path(['payload', 'message'], action)),
            R.path(['payload', 'response', 'data', 'data', 'err'])
          )(action)
        });
      }

      const uploadData  = R.path(['payload', 'data', 'data'])(action);
      const file        = R.compose(
        R.merge(uploadData),
        R.pathOr({}, ['meta', 'previousAction', 'payload', 'file']),
      )(action);
      onUpload(file.id);
      return handleFile(file);
    }).catch(error =>  {
      progressCB({ error });
      throw error;
    });
  };

  render() {
    const { children, className, onDrag, disableClick, showAddFileButton } = this.props;
    let dropzoneRef;

    return (
      <DropZone
        className={className}
        onDrop={this.upload}
        onDragEnter={() => onDrag(true)}
        onDragLeave={() => onDrag(false)}
        disableClick={disableClick}
        accept={SUPPORTED_MIME_TYPES}
        ref={(node) => { dropzoneRef = node; }}
      >
        {children}
        {
          showAddFileButton &&
            <AddButtonWrapper>
              <AddButton
                aria-haspopup="true"
                onClick={(e) => {
                  e.preventDefault();
                  dropzoneRef.open();
                }}
              >
                <span>ADD FILE</span>
                <AddIcon />
              </AddButton>
            </AddButtonWrapper>
        }
      </DropZone>
    );
  }
}

Uploader.propTypes = {
  className         : PropTypes.string,
  children          : PropTypes.node.isRequired,
  uploadFile        : PropTypes.func.isRequired,
  onUpload          : PropTypes.func.isRequired,
  handleFile        : PropTypes.func.isRequired,
  progressCB        : PropTypes.func,
  beginUpload       : PropTypes.func,
  onDrag            : PropTypes.func,
  disableClick      : PropTypes.bool,
  showAddFileButton : PropTypes.bool,
};

Uploader.defaultProps = {
  className         : '',
  disableClick      : false,
  onDrag            : () => {},
  beginUpload       : () => {},
  showAddFileButton : false,
};

const mapDispatchToProps = dispatch => ({
  uploadFile : (payload, callback) => dispatch(_uploadFile(payload, callback))
});

export default connect(null, mapDispatchToProps)(Uploader);
