import React, { Component } from 'react';
import styled               from 'styled-components';
import * as R               from 'ramda';
import { connect }          from 'react-redux';

import Footer      from './modal-component/Footer';
import ModalHeader from './modal-component/Header';
import Photo       from './modal-component/PhotoContainer';

import {
  updateSvrResponse as _updateSvrResponse
} from '../../SvrResponsesActions';

class PhotoReviewModal extends Component {
  state = {
    count                : 0,
    imagery              : [],
    initialCarouselPhoto : this.props.initialCarouselPhoto || null,
  };

  incrementCounter = () => {
    const { count, imagery } = this.state;
    const morePhotosToShow   = imagery.length - 1 > count;

    if (morePhotosToShow) {
      this.setState({ count : count + 1 });
    } else {
      this.setState({ count : 0 });
      this.props.openPhotoReviewModal();
    }
  };

  decrementCounter = () => {
    const isNotTheBeginning = this.state.count > 0;

    if(isNotTheBeginning){
      this.setState({ count : this.state.count - 1 });
    }
  };

  onAction = (type, alreadyApplied, payload) => {
    const sync = alreadyApplied ?
      R.over(R.lensProp(`${type}Photos`), R.reject(R.propEq('uuid', payload.uuid))) :
      R.over(R.lensProp(`${type}Photos`), R.tryCatch(R.append(payload), R.always([payload])));
    this.props.updateSvrResponse(
      this.props.namespace,
      this.props.svrResponse.id,
      { metaJson : R.compose(
          JSON.stringify,
          sync,
          R.prop('metaJson')
        )(this.props.svrResponse)
      }
    ).then(() => this.props.fetchPhotoQuestions(this.props.namespace, R.values(this.props.formAnswers))(this.props.svrResponse.id));

  };

  syncImagery(photos) {
    const {
            photoQuestions,
            formAnswers,
            svrResponse,
            blockedPhotos
          } = this.props;

    const imagery = R.compose(
      R.filter(q => !R.contains(q.document.uuid, blockedPhotos)),
      R.map(q => R.compose(R.assoc('flag',  R.__, q), Boolean, R.find(R.eqProps('uuid', q.document)), R.pathOr([], ['metaJson', 'flagPhotos']))(svrResponse)),
      R.map(q => R.compose(R.assoc('star',  R.__, q), Boolean, R.find(R.eqProps('uuid', q.document)), R.pathOr([], ['metaJson', 'starPhotos']))(svrResponse)),
      R.map(q => R.compose(R.assoc('block', R.__, q), Boolean, R.find(R.eqProps('uuid', q.document)), R.pathOr([], ['metaJson', 'blockPhotos']))(svrResponse)),
      R.reject(q => R.isNil(q.document)),
      R.map(q => R.assoc('document', photos.find(d => d.uuid === q.answer.answerValue))(q)),
      R.reject(q => R.isNil(q.answer)),
      R.map(q => R.assoc('answer', formAnswers[q.uuid])(q))
    )(photoQuestions);


    if (this.state.initialCarouselPhoto) {
      const count = R.findIndex(R.pathEq(['document', 'uuid'], this.state.initialCarouselPhoto.uuid))(imagery);

      this.setState({
        initialCarouselPhoto : null,
        count,
        imagery,
      });
    } else {
      this.setState({ imagery });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.syncImagery(nextProps.photos);
  }

  componentDidMount() {
    this.syncImagery(this.props.photos);
  }

  render () {
    const {
      photos,
      photoQuestions,
      formAnswers,
      toggleRejectPhotoQuestion,
      rejectedQuestions,
      svrResponse,
      isClientUser,
      updateDocument,
      reviewMode,
      namespace
    } = this.props;

    const imagery          = this.state.imagery;
    const carouselPosition = this.state.count;
    const haveDataToRender = photos.length && R.keys(formAnswers).length && photoQuestions.length;
    const meta             = R.pathOr({}, ['props', 'svrResponse', 'metaJson'], this);
    const svrResponses     = R.pathOr({}, ['props', 'svrResponse'], this);
    const currentImage     = imagery[carouselPosition];
    const blockPhotos      = R.pathOr([], ['metaJson', 'blockPhotos'])(svrResponse);
    const isEmptyAnswer    = R.isEmpty(R.pathOr('', ['answer', 'answerValue'], currentImage));

    if (!haveDataToRender || !currentImage) {
      return null;
    }

    const isBeforePhoto = R.pathEq(['meta', 'photoType'], 'before-photo')(currentImage),
          isAfterPhoto  = R.pathEq(['meta', 'photoType'], 'after-photo')(currentImage);

    const getPairedAfter  = p => R.find(R.pathEq(['meta', 'pairedQuestionUuid'], p.uuid))(imagery),
          getPairedBefore = p => R.find(R.propEq('uuid', R.pathOr('', ['meta', 'pairedQuestionUuid'], p)))(imagery);

    const actOnCurrent = action => (alreadyApplied, motive) => this.onAction(action, alreadyApplied, { uuid : currentImage.document.uuid, storeVisitId : svrResponse.id, motive }),
          actOnBefore  = action => (alreadyApplied, motive) => this.onAction(action, alreadyApplied, { uuid : R.pathOr('', ['document', 'uuid'], getPairedBefore(currentImage)), storeVisitId : svrResponse.id, motive }),
          actOnAfter   = action => (alreadyApplied, motive) => this.onAction(action, alreadyApplied, { uuid : R.pathOr('', ['document', 'uuid'], getPairedAfter(currentImage)), storeVisitId : svrResponse.id, motive  });

    const showAppropriateModal = () => {
      if (isEmptyAnswer) {
        return null;
      }
      if (isBeforePhoto) {
        return (
          <PhotoReviewModal.Center>
            <Photo
              onFlag                    = { actOnCurrent('flag')  }
              onStar                    = { actOnCurrent('star')  }
              onBlock                   = { actOnCurrent('block') }
              img                       = { currentImage }
              toggleRejectPhotoQuestion = { toggleRejectPhotoQuestion}
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockPhotos               = { blockPhotos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
            <Photo
              onFlag                    = { actOnAfter('flag')  }
              onStar                    = { actOnAfter('star')  }
              onBlock                   = { actOnAfter('block') }
              img                       = { getPairedAfter(currentImage) || {} }
              toggleRejectPhotoQuestion = { toggleRejectPhotoQuestion }
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockPhotos               = { blockPhotos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
          </PhotoReviewModal.Center>
        );
      }
      if (isAfterPhoto) {
        return (
          <PhotoReviewModal.Center>
            <Photo
              onFlag                    = { actOnBefore('flag')  }
              onStar                    = { actOnBefore('star')  }
              onBlock                   = { actOnBefore('block') }
              img                       = { getPairedBefore(currentImage) || {} }
              toggleRejectPhotoQuestion = { toggleRejectPhotoQuestion }
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockPhotos               = { blockPhotos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
            <Photo
              onFlag                    = { actOnCurrent('flag')  }
              onStar                    = { actOnCurrent('star')  }
              onBlock                   = { actOnCurrent('block') }
              img                       = { currentImage }
              toggleRejectPhotoQuestion = { toggleRejectPhotoQuestion }
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockPhotos               = { blockPhotos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
          </PhotoReviewModal.Center>
        );
      }
      return (
        <PhotoReviewModal.Center>
          <Photo
            onFlag                    = { actOnCurrent('flag')  }
            onStar                    = { actOnCurrent('star') }
            onBlock                   = { actOnCurrent('block') }
            img                       = { currentImage }
            toggleRejectPhotoQuestion = { toggleRejectPhotoQuestion }
            rejectedQuestions         = { rejectedQuestions }
            isClientUser              = { isClientUser }
            blockPhotos               = { blockPhotos }
            updateDocument            = { updateDocument }
            reviewMode                = { reviewMode }
            namespace                 = { namespace }
          />
        </PhotoReviewModal.Center>
      );
    };

    return (
      <PhotoReviewModal.Outer showWhen={this.props.showWhen}>
        <PhotoReviewModal.Listener onClick={this.props.openPhotoReviewModal} />
        <PhotoReviewModal.Container>
          <ModalHeader nextPhoto={this.incrementCounter} previousPhoto={this.decrementCounter} />
           {
             !R.isEmpty(imagery) && showAppropriateModal()
           }
           <Footer
            retailer     = { meta.retailer }
            location     = {`${meta.address1}, ${meta.city}, ${meta.zip}`}
            doorNumber   = { `${meta.doorNumber}` }
            svrId        = { `${svrResponses.id}` }            
          />
        </PhotoReviewModal.Container>
      </PhotoReviewModal.Outer>
    );
  }
}

PhotoReviewModal.Outer = styled.div`
  visibility  : ${({ showWhen }) => showWhen ? 'visible' : 'hidden'};
  overflow    : auto;
  display     : flex;
  align-items : center;
  position    : fixed;
  top         : 0;
  left        : 0;
  height      : 100%;
  width       : 100%;
  z-index     : 1200;
`;

PhotoReviewModal.Listener = styled.div`
  top        : 0;
  bottom     : 0;
  right      : 0;
  left       : 0;
  width      : 100%;
  position   : absolute;
  background : rgba(112, 112, 112, 0.66);
`;

PhotoReviewModal.Container = styled.div`
  width         : 95%;
  background    : #fff;
  margin        : 0 auto;
  border-radius : 3px;
  overflow      : visible;
  z-index       : 1;
`;

PhotoReviewModal.Center = styled.div`
  display         : flex;
  align-items     : flex-start;
  justify-content : space-around;
`;


const mapDispatchToProps = dispatch => ({
  updateSvrResponse  : (namespace, svrResponseId, payload) =>  dispatch(_updateSvrResponse(namespace, svrResponseId, payload))
});

export default connect( null, mapDispatchToProps )(PhotoReviewModal);
