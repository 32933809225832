import * as R from 'ramda';

const filterBlockedPhotos = (svrResponse, formAnswers, isClientUser) => {
  const filterPhotos = R.compose(
    R.pluck('uuid'),
    R.pathOr([], ['metaJson', 'blockPhotos'])
  );
  return isClientUser ? filterPhotos(svrResponse) : [];
};

export default filterBlockedPhotos;
