import * as R from 'ramda';

import {
  FETCH_ALL,
  UPDATE,
  IS_EDITING
} from '../actions/AdministrationActions';

export const STATE_KEY = 'administration';

const initialState = {
  isEditing : false,
  data      : {}
};

const AdministrationSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_SUCCESS`: {
      return R.compose(
        R.assoc('data', R.__, state),
        R.pathOr([], ['payload', 'data', 'data']),
      )(action);
    }
    
    case `${UPDATE}_SUCCESS`: {
      const setting   = R.pathOr({}, ['payload', 'data', 'data'], action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      const namespaceIndex = R.findIndex(R.propEq('namespace', namespace), state.data);
      const settingIndex   = R.findIndex(R.propEq('name', setting.name), state.data[namespaceIndex].settings);

      return R.assocPath(
        ['data', namespaceIndex, 'settings', settingIndex],
        setting,
        state,
      );
    }

    case IS_EDITING: {
      return  R.assoc('isEditing', action.payload, state);
    }
    default: {
      return state;
    }
  }
};

export const getAdministrationSettings = state => Object
  .keys(R.path([STATE_KEY, 'data'], state))
  .map(key => R.path([STATE_KEY, 'data', key], state));

export const getIsgetAdministrationSettingLoaded = R.compose(R.length, getAdministrationSettings);

export default AdministrationSettingsReducer;
