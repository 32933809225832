import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';
import * as R    from 'ramda';

import { Checkbox, FormControlLabel, MenuItem, Radio, Select, Switch, TextField } from '@material-ui/core';

import Modal from './components/Modal';

import {
  makeDecoratedImageUrl,
  getRotation
} from '../../../../../utils/image';
import { BigPlayButton, ControlBar, VolumeMenuButton, Player } from 'video-react';

const Container = styled.div`
  width        : 100%;
  padding-left : 20px;

  p {
    font-size   : 13px;
    font-weight : bold;
    color       : #5E5D5F;
  }

  .video-react-video, .video-react {
    background-color  : white;
  }
`;

const SwitchWrapper  = styled.div`
  display         : flex;
  justify-content : space-between;
  width           : 100%;
`;

const Thumbnail = styled.div`
    height            : 15vh;
    min-height        : 100px;
    width             : 15vh;
    background-size   : contain;
    background-repeat : no-repeat;
    max-width         : 100%;
    cursor            : pointer;
`;

const renderThumbnail = (currentAnswer, photos) => {

  const currentPhoto = R.compose(
    R.defaultTo({ url : '' }),
    R.find(R.propEq('uuid', currentAnswer))
  )(photos);

  return <Thumbnail style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(currentPhoto.url), 200)(currentPhoto.url)}')` }}  />;
};

const updateRotation = (rotation) => (video) => {
  if(!video) {
    return;
  }

  const thisVideo: HTMLVideoElement = R.path(['video', 'video'], video);
  if(!thisVideo) {
    return;
  }
  thisVideo.style.transform = `rotate(${rotation}deg)`;
};

const renderVideo = (currentAnswer, videos, rotateVideos) => {

  const currentVideo = R.compose(
    R.defaultTo({ url : '' }),
    R.find(R.propEq('uuid', currentAnswer))
  )(videos);

  const rotation = R.compose(R.defaultTo(0), R.prop('rotate'), R.find(R.propEq('uuid', currentAnswer)))(rotateVideos);

  return (
    <Player
      fluid = { false }
      src = { currentVideo.url }
      width = { 300 }
      ref = { updateRotation(rotation) }
    >
      <BigPlayButton position={"center"}/>
      <ControlBar autoHide={false}>
        <VolumeMenuButton vertical />
      </ControlBar>
    </Player>
  );
};

const AnswerFeedbackModal = ({
  onSave,
  onCancel,
  onHandleAnswerChange,
  isAnswerRejected,
  question,
  answerValue,
  onHandleAnswerMultiChange,
  onToggle,
  photos,
  videos,
  rotateVideos
}) => (
  <Modal
    onSave   = {onSave}
    onCancel = {onCancel}
  >

    <Container>
      <p>{question.title}</p>

      {R.equals('photo-input', question.type) && renderThumbnail(answerValue, photos)}

      {R.equals('signature-input', question.type) && renderThumbnail(answerValue, photos)}

      {R.equals('video-input', question.type) && renderVideo(answerValue, videos, rotateVideos)}

      {R.equals('single-line-input', question.type) && (
        <TextField
          fullWidth
          autoFocus
          id              = "title"
          value           = {answerValue}
          margin          = "normal"
          onChange        = {onHandleAnswerChange}
          InputLabelProps = {{
            shrink : true
          }}
        />
      )}

      {R.equals('multi-line-input', question.type) && (
        <TextField
          fullWidth
          multiline
          autoFocus
          id              = "title"
          value           = {answerValue}
          margin          = "normal"
          onChange        = {onHandleAnswerChange}
          InputLabelProps = {{
            shrink : true
          }}
        />
      )}

      {R.equals('toggle-input', question.type) && (
        <Select
          fullWidth
          value    = {answerValue}
          onChange = {onHandleAnswerChange}
        >
          {question.options.map(o => (<MenuItem key={o.uuid} value={o.value}>{o.label}</MenuItem>))}
        </Select>
      )}

      {R.equals('dropdown-input', question.type) && (
        <Select
          fullWidth
          value    = {answerValue}
          onChange = {onHandleAnswerChange}
        >
          {question.options.map(o => (<MenuItem key={o.uuid} value={o.value}>{o.label}</MenuItem>))}
        </Select>
      )}

      {R.equals('radio-button-input', question.type) && (
        <div style={{ display : 'flex', flexDirection : 'column' }}>
          {question.options.map(o => (
            <FormControlLabel
              key     = {o.uuid}
              value   = {o.value}
              label   = {o.label}
              control = {
                <Radio
                  checked  = {answerValue === o.value}
                  onChange = {onHandleAnswerChange}
                  value    = {o.value}
                />
              }
            />
          ))}
        </div>
      )}

      {R.equals('checkbox-input', question.type) && (
        <div>
          {question.options.map(o => (
            <FormControlLabel
              key     = {o.uuid}
              value   = {o.value}
              label   = {o.label}
              control = {
                <Checkbox
                  checked  = {R.contains(o.value, answerValue)}
                  onChange = {onHandleAnswerMultiChange}
                  value    = {o.value}
                />
              }
            />
          ))}
        </div>
      )}

      {R.equals('numeric-int-input', question.type) && (
        <TextField
          fullWidth
          autoFocus
          id              = "title"
          type            = "number"
          value           = {answerValue}
          margin          = "normal"
          onChange        = {onHandleAnswerChange}
          InputLabelProps = {{
            shrink : true
          }}
        />
      )}

      {R.equals('numeric-conditional-input', question.type) && (
        <TextField
          fullWidth
          autoFocus
          id              = "title"
          type            = "number"
          value           = {answerValue}
          margin          = "normal"
          onChange        = {onHandleAnswerChange}
          InputLabelProps = {{
            shrink : true
          }}
        />
      )}


      <SwitchWrapper>
        <p>NEEDS REVISION</p>
        <Switch
          checked  = {isAnswerRejected}
          onChange = {onToggle}
        />
      </SwitchWrapper>
    </Container>
  </Modal>
);

AnswerFeedbackModal.propTypes = {
  onSave                    : PropTypes.func.isRequired,
  onCancel                  : PropTypes.func.isRequired,
  onHandleFeedbackChange    : PropTypes.func.isRequired,
  onToggle                  : PropTypes.func.isRequired,
  onHandleAnswerChange      : PropTypes.func.isRequired,
  onHandleAnswerMultiChange : PropTypes.func.isRequired,
  question                  : PropTypes.object,
  isAnswerRejected          : PropTypes.bool,
  feedbackMessage           : PropTypes.string,
  answerValue               : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])

};

AnswerFeedbackModal.defaultProps = {
  isAnswerRejected : false,
  feedbackMessage  : '',
  question         : {},
  answerValue      : ''
};

export default AnswerFeedbackModal;
