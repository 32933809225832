import * as R from 'ramda';

const filterBlockedVideos = (svrResponse, formAnswers, isClientUser) => {
  const filterVideos = R.compose(
    R.pluck('uuid'),
    R.pathOr([], ['metaJson', 'blockVideos'])
  );
  return isClientUser ? filterVideos(svrResponse) : [];
};

export default filterBlockedVideos;
