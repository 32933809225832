import * as R from 'ramda';

const { tableColumns, tableColumnAssignments } = window.WINSTON || { tableColumns : [], tableColumnAssignments : {} };

const UNKNOWN_PERMISSIONS_ROLE = 0;

const getTokenUserRole = () => {
  const storedAuth = window.localStorage.getItem('persist:root');
  return R.compose(
    R.pathOr(UNKNOWN_PERMISSIONS_ROLE, ['auth', 'roleId']),
    R.over(R.lensProp('auth'), JSON.parse),
    JSON.parse,
  )(storedAuth);
};

const maybeRenameColumnAssignments = pageName => columns => {
  return R.when(
    R.always(R.equals('alertsBuilder', pageName)),
    R.map(R.when(R.propEq('pathStr', 'svrId'), R.mergeDeepLeft({ path : ['id'], pathStr : 'id' }))),
    columns
  );
};

// const maybeRemoveColumns = pageName => columns => {
//   if (R.equals('reporting', pageName)){
//    return R.reject(R.propEq('column','meta_project_name'))(columns);
//   }
//   else
//   {
//    return columns;
//   }
// };

const changeMCNameLable = x => { if( x.label === "MC Name" ) 
                                    {
                                      x.label = "Talent Name";
                                    }
                                    return x; 
                                   };

const maybeRenameColumnMCToTalent = columns => {
    return R.map(changeMCNameLable, columns);
};

const maybeMergeExcludeParams = params => R.compose(
    R.concat(params),
    R.map(excludeParam => 
      R.compose(
        R.omit(['excludeConfig', 'width']),
        R.mergeDeepLeft({
          label: R.pathOr(`Exclude: ${excludeParam.label}`, ['excludeConfig', 'label'], excludeParam),
          exclude: true,
          hideColumn: true,
          permissionGroup: R.pathOr([], ['excludeConfig', 'permissionGroup'], excludeParam)
        })
      )(excludeParam)),
    R.filter(R.pathOr(false, ['excludeConfig', 'enabled']))
  )(params);

export const getTableColumnsForPage = (namespace, pageName) => {
  const columns           = R.propOr([], namespace, tableColumns),
        columnAssignments = R.propOr([], pageName, tableColumnAssignments),
        userRole          = getTokenUserRole(),
        hasPermission     = R.compose(R.contains(userRole), R.prop('permissionGroup'));

  return R.compose(
    maybeRenameColumnMCToTalent,
    //maybeRemoveColumns(pageName),
    maybeRenameColumnAssignments(pageName),
    R.filter(hasPermission),
    maybeMergeExcludeParams,
    R.map(c => R.compose(R.assoc('path', R.__, c), R.split('.'), R.propOr('', 'pathStr'))(c)),
    R.map(k => R.compose(R.merge(columns[k]), R.objOf('pathStr'))(k))
  )(columnAssignments);
};

export const maybeDecorateColumn = d => v => d ? d(v) : v;

