const namespace     = 'AUTH';
export const LOGIN  = `${namespace}/LOGIN`;
export const LOGOUT = `${namespace}/LOGOUT`;

export const login = payload => ({
  type    : LOGIN,
  payload : {
    request : {
      method : 'POST',
      url    : '/auth/login',
      data   : payload
    }
  }
});

export const logout = () => ({
  type : LOGOUT
});
