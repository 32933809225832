import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = theme => ({
  h4 : {
    display       : 'inline-block',
    margin        : 0,
    color         : theme.custom.colors.grayMedium,
    fontSize      : theme.custom.font.size.h4,
    fontWeight    : 500,
    letterSpacing : theme.custom.font.letterSpacing.extended
  }
});

const H4 = ({ children, classes, onClick }) => (
  <h4
    className = {classes.h4}
    onClick   = {onClick}
    style     = {{ cursor : typeof onClick === 'function' ? 'pointer' : 'default'}}
  >{children}</h4>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    children : PropTypes.node,
    classes  : PropTypes.object.isRequired,
    theme    : PropTypes.object.isRequired,
    onClick  : PropTypes.func
  })
)(H4);
