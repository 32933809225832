import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import { Button } from '@material-ui/core';
import StyledFooter from './StyledFooter';

const ButtonWrapper = styled.div`
  display         : flex;
  height          : 100%;
  justify-content : ${({ withoutCancel }) => withoutCancel ? 'flex-end' : 'space-between'};
`;

const Footer = ({ onApprove, onCancel, withoutCancel, withoutApprove, logoutText = '' }) => (
  <StyledFooter>
    <ButtonWrapper
      withoutCancel={withoutCancel}
      withoutApprove={withoutApprove}
    >
      {!withoutCancel && (<Button
        color="secondary"
        style={{ padding: '0 30px' }}
        onClick={onCancel}>
          CANCEL
      </Button>
      )}
      {!withoutApprove && (<Button
        color="secondary"
        onClick={onApprove}
      >
        {withoutCancel ? (logoutText === '' ? 'OK' : logoutText) : 'YES'}
      </Button>
      )}
    </ButtonWrapper>
  </StyledFooter>
);

Footer.propTypes = {
  withoutCancel : PropTypes.bool,
  onApprove     : PropTypes.func.isRequired,
  onCancel      : PropTypes.func.isRequired,
};

Footer.defaultProps = {
  withoutCancel: false,
};

export default Footer;
