import React  from 'react';
import styled from 'styled-components';

import { Paper } from '@material-ui/core';

const Wrapper = styled(Paper)`
  display         : flex;
  justify-content : space-between;
  align-items     : center;
  min-height      : 30px;
  margin          : 8px 0;
  margin-left     : ${ props => props.type === 'section' ? `0` : `10px`};
  padding         : 0;
  border-left     : 10px solid #398559;
  box-sizing      : border-box;
  background-color: transparent !important;
  box-shadow      : none !important;
  border-right-color    : #CACCCD !important;
  cursor          : pointer;
`;

const Line = styled.div`
  width         : 42%;
  margin        : 0;
  padding       : 0;
  opacity       : .7;
  height        : 2px;
  background    : #CACCCD !important;
`;

const Title = styled.p`
  margin       : auto;
  align-self   : center;
  cursor       : default;
  width        : auto;
  color        : #CF1F25;
  cursor       : pointer;
`;

const MovePlaceholder = ({onChange, type}) => (
  <div>
    <Wrapper
      onClick={onChange}
      type={type}
    >
      <Line></Line>
      <Title>MOVE HERE</Title>
      <Line></Line>
    </Wrapper>
  </div>
);

export default MovePlaceholder;
