import * as R                    from 'ramda';
import React                     from 'react';
import PropTypes                 from 'prop-types';
import {
  compose,
  setPropTypes,
  defaultProps
} from 'recompose';
import styled                    from 'styled-components';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Typography, Tooltip }   from '@material-ui/core';

import {
  Edit,
  OpenWith,
  GetApp
} from '@material-ui/icons';

import { device } from '../styled/mediaquery';

const styles = theme => ({
  widgetHeader : {
    padding      : theme.custom.spacing.paddingHeader,
    borderBottom : `1px solid ${theme.custom.colors.grayAlt}`
  },
  subheading   : {
    minHeight : '20px',
    color : theme.custom.colors.black
  },
  titleWrapper : {
    display        : 'flex',
    justifyContent : 'space-between'
  },
  icon         : {
    cursor     : 'pointer',
    marginLeft : '5px'
  },
  downloadIcon : {
    cursor   : 'pointer',
    fontSize : '27px'
  },
  truncate     : {
    whiteSpace   : 'nowrap',
    overflow     : 'hidden',
    textOverflow : 'ellipsis'
  },
  iconsWrapper : {
    display: 'flex'
  }
});

const IconVisibility = styled.div`
  display: flex;
  @media ${device.tablet} {
    display: none;
  };

  @media ${device.tabletM} and (orientation: landscape) {
    display: none;
  }
`;

const WidgetTitle = styled(Typography)`
  cursor  : ${({ onClick }) => R.is(Function, onClick) ? 'pointer' : 'default' };
  width   : calc(100% - 44px);
  display : inline-block;
`;

const WidgetHeader = (
  {
    classes,
    title,
    description,
    onTitleClick,
    onEditClick,
    onMoveClick,
    onDownloadClick,
    isShowingIcons,
    isDownload
  }
) => (
  <div className={classes.widgetHeader}>
    <div className={classes.titleWrapper}>
      <Tooltip
        title      = {title.length > 40 ? title : ''}
        enterDelay = {300}
        placement  = 'top-start'
      >
        <WidgetTitle
          variant   = "title"
          onClick   = {onTitleClick}
          className = {classes.truncate}
        >
          {title}
        </WidgetTitle>
      </Tooltip>
      {isShowingIcons &&  (
        <IconVisibility>
          { isDownload && (
            <GetApp className={classes.downloadIcon} onClick={onDownloadClick} />
          )}
          <Edit     className={classes.icon}         onClick={onEditClick} />
          <OpenWith className={classes.icon}         onClick={onMoveClick} />
        </IconVisibility>
      )}
    </div>
    <Tooltip
      title      = {description && description.length > 40 ? description : ''}
      enterDelay = {300}
      style      = { { maxWidth : '300px' }}
      placement  = 'top-start'
      >
      <Typography variant="subheading" className={`${classes.subheading} ${classes.truncate}`}>
        {description}
      </Typography>
    </Tooltip>
  </div>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    classes         : PropTypes.object.isRequired,
    theme           : PropTypes.object.isRequired,
    title           : PropTypes.string.isRequired,
    onTitleClick    : PropTypes.func,
    onEditClick     : PropTypes.func.isRequired,
    onDownloadClick : PropTypes.func,
    isShowingIcons  : PropTypes.bool,
    isDownload      : PropTypes.bool
  }),
  defaultProps({
    isShowingIcons : true,
    isDownload     : false
  })
)(WidgetHeader);
