import * as R from 'ramda';
import generatePdf from './utils/generatePdf';

import mergePhotoMotives from '../saved-rollups/components/utils/mergePhotoMotives';

const namespace = 'FILE_EXPORT';

export const EXPORT_TO_FILE = `${namespace}/EXPORT_TO_FILE`;
export const EXPORT_TO_FILE_START = `${namespace}/EXPORT_TO_FILE_START`;
export const HIDE_FILE_EXPORT_MODAL = `${namespace}/HIDE_FILE_EXPORT_MODAL`;

const _exportToFile = (type, title, questions, questionAnswers, transformedData, namespace, photos, embedPhotosInExcelReport) => {
  const unSelectedExportcolumns = JSON.parse(window.localStorage.getItem('Excelconfirmcolumns'));

  const commonUnselectheaders = [];

  if (unSelectedExportcolumns !== null && unSelectedExportcolumns.length > 0) {

    unSelectedExportcolumns.forEach(item => {
      const index = R.findIndex(R.propEq('title', item), questions);
      if (index > -1) {
        questions.splice(index, 1);
      }

      if (item === "Report Number") {
        commonUnselectheaders.push("id");
      }
      else if (item === "Client Name") {
        commonUnselectheaders.push("meta_corporation");
      }
      else if (item === "Project Name") {
        commonUnselectheaders.push("meta_project_name");
      }
      else if (item === "Retailer") {
        commonUnselectheaders.push("meta_retailer");
      }
      else if (item === "Door Number") {
        commonUnselectheaders.push("meta_door_number");
      }
      else if (item === "Door Location") {
        commonUnselectheaders.push("meta_door_location");
      }
      else if (item === "Visit Date") {
        commonUnselectheaders.push("expected_checkin_time");
      }
      else if (item === "Talent Name") {
        commonUnselectheaders.push("meta_mc_full_name");
      }
      else if (item === "Project Number") {
        commonUnselectheaders.push("meta_project_segment_id");
      }
      else if (item === "Review State") {
        commonUnselectheaders.push("meta_review_state");
      }
      else if (item === "MCID") {
        commonUnselectheaders.push("meta_mcid");
      }
      else if (item === "Form Canonical Key") {
        commonUnselectheaders.push("form_canonical_key");
      }
      else if (item === "Call Out") {
        commonUnselectheaders.push("meta_has_call_out");
      }
      else if (item === "Department Type") {
        commonUnselectheaders.push("meta_department_type");
      }
      else if (item === "Visit Length") {
        commonUnselectheaders.push("expected_duration_minutes");
      }
      else if (item === "Approved Date") {
        commonUnselectheaders.push("meta_approved_date");
      }
      else if (item === "Approved by") {
        commonUnselectheaders.push("meta_reviewed_by");
      }
      else if (item === "Work Classification") {
        commonUnselectheaders.push("meta_talent_type");
      }
      else if (item === "Project Segment Name") {
        commonUnselectheaders.push("meta_project_segment_name");
      }
      else if (item === "Location - City") {
        commonUnselectheaders.push("meta_city");
      }
      else if (item === "Location - State") {
        commonUnselectheaders.push("meta_state");
      }
      else if (item === "Location Zone") {
        commonUnselectheaders.push("meta_zone");
      }
      else if (item === "Location Territory") {
        commonUnselectheaders.push("meta_territory");
      }
      else if (item === "Event ID") {
        commonUnselectheaders.push("event_id");
      }
      else if (item === "Visibility") {
        commonUnselectheaders.push("status");
      }
      else if (item === "Hidden From Client") {
        commonUnselectheaders.push("privacy");
      }
      else if (item === "Authorized by") {
        commonUnselectheaders.push("approved_by");
      }
      else if (item === "Project #") {
        commonUnselectheaders.push("meta_project_number");
      }
      else if (item === "PD") {
        commonUnselectheaders.push("meta_project_division");
      }
      else if (item === "TD") {
        commonUnselectheaders.push("meta_talent_division");
      }
      else if (item === "Device OS") {
        commonUnselectheaders.push("device_os");
      }
      else if (item === "Device OS Version") {
        commonUnselectheaders.push("device_os_version");
      }
      else if (item === "Device Model") {
        commonUnselectheaders.push("device_model");
      }
      else if (item === "App Version") {
        commonUnselectheaders.push("device_app_version");
      }
    });
  }
  const jsonObj = JSON.stringify({
    title,
    data: {
      questions,
      questionAnswers,
      transformedData,
      namespace,
      photos,
      embedPhotosInExcelReport,
      commonUnselectheaders: commonUnselectheaders
    },

  });

  const blob = new Blob([jsonObj], { type: 'application/json' });
  const data = new FormData();
  data.append('report', blob);
  window.localStorage.removeItem('Excelconfirmcolumns')
  return {
    type: EXPORT_TO_FILE,
    payload: {
      request: {
        method: 'POST',
        timeout: 0,
        url: `/api/v2/storeVisit/export/${type}`,
        data: data
      }
    }
  };
};

const _exportToFilefilter = (type, title, questions, questionAnswers, transformedData, namespace, photos, embedPhotosInExcelReport) => {

  if(!R.isNil(photos) && photos.length > 4000  && embedPhotosInExcelReport)
    {
      embedPhotosInExcelReport =  false
    }

  const unSelectedExportcolumns = JSON.parse(window.localStorage.getItem('Excelconfirmcolumns'));

  const commonUnselectheaders = [];

  const duplicates = questions.reduce((acc, obj, index) => {
    const duplicates = questions.filter((item, i) =>
      i !== index && item.title === obj.title
    );

    if (duplicates.length > 0 && !acc.some(dup => dup.title === obj.title)) {
      return acc.concat([obj]);
    }
    return acc;
  }, []);

  if (unSelectedExportcolumns !== null && unSelectedExportcolumns.length > 0) {

    const newArray = questions.map((m) => [m.title, m]);
    const newMap = new Map(newArray);
    const iterator = newMap.values();
    const unique = [...iterator];
    questions = unique;

    if (duplicates.length > 0) {
      for (var i = 0; i < duplicates.length; i++) {
        let index = unSelectedExportcolumns.indexOf(duplicates[i].title);

        if (index === -1) {
          questions.push(duplicates[i]);
        }

      }
    }

    unSelectedExportcolumns.forEach(item => {
      const index = R.findIndex(R.propEq('title', item), questions);
      if (index > -1) {
        questions.splice(index, 1);
      }

      if (item === "Report Number") {
        commonUnselectheaders.push("id");
      }
      else if (item === "Client Name") {
        commonUnselectheaders.push("meta_corporation");
      }
      else if (item === "Project Name") {
        commonUnselectheaders.push("meta_project_name");
      }
      else if (item === "Retailer") {
        commonUnselectheaders.push("meta_retailer");
      }
      else if (item === "Door Number") {
        commonUnselectheaders.push("meta_door_number");
      }
      else if (item === "Door Location") {
        commonUnselectheaders.push("meta_door_location");
      }
      else if (item === "Visit Date") {
        commonUnselectheaders.push("expected_checkin_time");
      }
      else if (item === "Talent Name") {
        commonUnselectheaders.push("meta_mc_full_name");
      }
      else if (item === "Project Number") {
        commonUnselectheaders.push("meta_project_segment_id");
      }
      else if (item === "Review State") {
        commonUnselectheaders.push("meta_review_state");
      }
      else if (item === "MCID") {
        commonUnselectheaders.push("meta_mcid");
      }
      else if (item === "Form Canonical Key") {
        commonUnselectheaders.push("form_canonical_key");
      }
      else if (item === "Call Out") {
        commonUnselectheaders.push("meta_has_call_out");
      }
      else if (item === "Department Type") {
        commonUnselectheaders.push("meta_department_type");
      }
      else if (item === "Visit Length") {
        commonUnselectheaders.push("expected_duration_minutes");
      }
      else if (item === "Approved Date") {
        commonUnselectheaders.push("meta_approved_date");
      }
      else if (item === "Approved by") {
        commonUnselectheaders.push("meta_reviewed_by");
      }
      else if (item === "Work Classification") {
        commonUnselectheaders.push("meta_talent_type");
      }
      else if (item === "Project Segment Name") {
        commonUnselectheaders.push("meta_project_segment_name");
      }
      else if (item === "Location - City") {
        commonUnselectheaders.push("meta_city");
      }
      else if (item === "Location - State") {
        commonUnselectheaders.push("meta_state");
      }
      else if (item === "Location Zone") {
        commonUnselectheaders.push("meta_zone");
      }
      else if (item === "Location Territory") {
        commonUnselectheaders.push("meta_territory");
      }
      else if (item === "Event ID") {
        commonUnselectheaders.push("event_id");
      }
      else if (item === "Visibility") {
        commonUnselectheaders.push("status");
      }
      else if (item === "Hidden From Client") {
        commonUnselectheaders.push("privacy");
      }
      else if (item === "Authorized by") {
        commonUnselectheaders.push("approved_by");
      }
      else if (item === "Project #") {
        commonUnselectheaders.push("meta_project_number");
      }
      else if (item === "PD") {
        commonUnselectheaders.push("meta_project_division");
      }
      else if (item === "TD") {
        commonUnselectheaders.push("meta_talent_division");
      }
      else if (item === "Device OS") {
        commonUnselectheaders.push("device_os");
      }
      else if (item === "Device OS Version") {
        commonUnselectheaders.push("device_os_version");
      }
      else if (item === "Device Model") {
        commonUnselectheaders.push("device_model");
      }
      else if (item === "App Version") {
        commonUnselectheaders.push("device_app_version");
      }
    });
  }
  const jsonObj = JSON.stringify({
    title,
    data: {
      questions,
      questionAnswers,
      transformedData,
      namespace,
      photos,
      embedPhotosInExcelReport,
      commonUnselectheaders: commonUnselectheaders
    },

  });

  const blob = new Blob([jsonObj], { type: 'application/json' });
  const data = new FormData();
  data.append('report', blob);
  window.localStorage.removeItem('Excelconfirmcolumns')
  return {
    type: EXPORT_TO_FILE,
    payload: {
      request: {
        method: 'POST',
        timeout: 0,
        url: `/api/v2/storeVisit/export/${type}`,
        data: data
      }
    }
  };
};

export const exportToFile = (
  {
    type,
    domId,
    title,
    questions,
    questionAnswers,
    transformedData,
    namespace,
    photos,
    callback,
    embedPhotosInExcelReport,
    shouldIncludeMotives,
    filterColumns = false,
  }) => dispatch => {

    const cleanedTitle = title ? title.replace(/[[\]:\\/\\?\\*/]/gm, '_') : '';

    const { questions: questionsWithStarFlagMotives, answers: answersWithStarFlagMotives } = shouldIncludeMotives ? mergePhotoMotives(questions, questionAnswers, transformedData) : {};

    if (type === 'excel') {
      dispatch({
        type: EXPORT_TO_FILE_START,
        payload: {}
      });
    }

    if (type === 'pdf') {
      return generatePdf(domId, cleanedTitle, callback);
    }

    //console.log("test", filterColumns);

    if (filterColumns === undefined ||  filterColumns === null || filterColumns === false)
      return dispatch(_exportToFile(type, cleanedTitle, shouldIncludeMotives ? questionsWithStarFlagMotives : questions, shouldIncludeMotives ? answersWithStarFlagMotives : questionAnswers, transformedData, namespace, photos, embedPhotosInExcelReport));
    else
      return dispatch(_exportToFilefilter(type, cleanedTitle, shouldIncludeMotives ? questionsWithStarFlagMotives : questions, shouldIncludeMotives ? answersWithStarFlagMotives : questionAnswers, transformedData, namespace, photos, embedPhotosInExcelReport));
  };

export const hideFileExportModal = () => ({
  type: HIDE_FILE_EXPORT_MODAL,
  payload: null
});
