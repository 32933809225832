export const deriveTenancyFilters = (currentClient, currentProject) => {

  let filters = {};

  if (currentClient.clientId) {
    filters['Client Name'] = {
      pathStr : 'metaJson.corporation',
      path    : ['metaJson', 'corporation'],
      label   : 'Client Name',
      data    : [currentClient.clientName]
    };
  }

  if (currentProject.projectId) {
    filters['Project Name'] = {
      pathStr : 'metaJson.projectName',
      path    : ['metaJson', 'projectName'],
      label   : 'Project Name',
      data    : [currentProject.projectName]
    };
  }

  return filters;
};
