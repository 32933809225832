import * as R from 'ramda';

const parseAnswerTitle = (type, currentAnswer, options) => {
  if (R.equals(type, 'checkbox-input') && !R.isEmpty(currentAnswer)) {
    const answer = R.compose(
      R.unless(
        () => R.isNil(options), 
        R.filter(d => R.contains(d, R.map(r => r.value, options)))
      ),
      JSON.parse
    )(currentAnswer);
    return answer.join('; ');
  }

  return currentAnswer;
};

export default parseAnswerTitle;
