//Added new file
import * as R from 'ramda';
import React, { Component } from 'react';
import prr from 'prettycats';
import { axiosClient } from '../../../store';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { login as _login } from '../AuthActions';
import { fetchClientProjectList as _fetchClientProjectList } from '../../client/ClientActions';

class CLoginPage extends Component {
  state = {
    email: '',
    password: 'cuser',
    strategy: 'cuser',
    authstatus: ''
  };

  

  componentDidMount() {
    //debugger;
    //const tempbemail = btoa("");
    //const cookievalue = "utx=" + tempbemail + "; SameSite=lax; Secure=true; Path=/";
    //window.document.cookie = "utx=" + tempbemail + "; SameSite=lax; Path=/";
    
    if (document.URL !== undefined && document.URL !== null && document.URL !== "") {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')
        || document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')
        //|| document.URL.includes('http://mapps.mstage.wintellplus.test')
      ) {
        
        const name = 'ust';
        let uidValue = null;

        const qParameterName = 'uid';
          const urlDocument = new URL(document.URL)
          const urlParams = new URLSearchParams(urlDocument.ulg);
          const qUidValue = urlParams.has(qParameterName) ? urlParams.get(qParameterName) : null;

          if (!R.isNil(qUidValue) && !R.isEmpty(qUidValue)) {
            console.log("document.URL A: ", document.URL);
            uidValue = qUidValue; // decodeURIComponent(qUidValue);
          }        

         if (R.isNil(uidValue)) {
          console.log("document.URL B: ", document.URL);
          if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
              const cookie = cookies[i].trim();
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                uidValue = decodeURIComponent(cookie.substring(name.length + 1));
                //console.log("cookieValue: ", cookieValue);
                break;
              }
            }
          }
        }

        const email = R.isNil(uidValue) ? null: atob(uidValue);
        if (!R.isNil(email)) {
          this.props.login({
            email: email,
            password: this.state.password,
            strategy: this.state.strategy
          }).then(res => {
            if (!R.path(['payload', 'data', 'data', 'id'], res)) {
              this.setState({ errorMessage: 'Login failed. Please try again.' });
            } else {
             this.getuserdataregionals();
              this.props.fetchClientProjectList()
                .finally(() => this.setState({ redirectToReferrer: true }));
            }
          });
        }
        else {
          window.location.href = 'https://winstonretail.com/';
          
        }
      }
      else {
        window.location.href = 'https://winstonretail.com/';
        return;
      }
    }
    else {
      window.location.href = 'https://winstonretail.com/';
      return;
    }
  }

  getuserdataregionals = async () => {
    const responsegetDataReg = await axiosClient.get(
      `api/v2/regionals/getregionals`,
      {
        headers: {
          Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
        }
      }
    );
    window.localStorage.setItem('Regionalslist', JSON.stringify(responsegetDataReg));
  }


  getuserdataemail = async () => {
    const resposegetDatauseremail = await axiosClient.get(
      `api/v2/profileUseremail/getuserprofile/email/${this.state.email}`
    );

    if (!R.path(['data', 'data', 'id'], resposegetDatauseremail)) {
    } else {
      const Resultpassword = R.pathOr("", ['data', 'data', 'password'], resposegetDatauseremail);

      this.props.login({
        email: this.state.email,
        password: Resultpassword,
        strategy: 'bosminor'
      }).then(res => {

        this.props.fetchClientProjectList()
          .finally(() => this.setState({ redirectToReferrer: true }));
      });
    }
  }

  Getlogin = (e) => email => {
    this.getuserdataemail(email);
  }

  isValidForm() {
    return prr.isEmail(this.state.email) && prr.isStringOfLengthAtLeast(3)(this.state.password);
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={{ pathname: '/' }} />;
    }

    return (
      <div></div>
    );
  }
}

CLoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  fetchClientProjectList: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  login: payload => dispatch(_login(payload)),
  fetchClientProjectList: () => dispatch(_fetchClientProjectList())
});

export default connect(null, mapDispatchToProps)(CLoginPage);
