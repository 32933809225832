import React         from 'react';
import styled        from 'styled-components';
import {
  compose,
  withStateHandlers,
  withHandlers,
  lifecycle
}                    from 'recompose';
import * as R        from 'ramda';

import { MenuItem, Button, Menu } from '@material-ui/core';

import ArrowBackIcon    from '@material-ui/icons/ArrowBack';
import ChevronLeftIcon  from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import filterData from './utils/filterData';

const Toolbar = styled.div`
  display         : flex;
  justify-content : space-between;
  margin-top      : 30px;
  margin-bottom   : 10px;
  padding         : 0 30px;
`;

const BackButtonWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;
  cursor         : pointer;
`;

const BackButtonTitle = styled.p`
  margin-left : 15px;
  font-size   : 15px;
  font-weight : 500;
  color       : #CE343E;
`;

const ViewWrapper = styled.div`
  display        : flex;
  flex-direction : row;
  align-items    : center;

  & svg {
    cursor       : pointer;
    margin-right : 15px;
  }
`;

const ViewTitle = styled.p`
  color        : #8F9090;
  margin-right : 15px;
  font-size    : 13px;
`;

const BackButton = ({ onClick }) => (
  <BackButtonWrapper onClick={onClick}>
    <ArrowBackIcon />
    <BackButtonTitle>BACK TO SVR RESPONSES</BackButtonTitle>
  </BackButtonWrapper>
);

const SvrResponseReviewToolbar = ({
  history,
  handleNextArrowClick,
  handlePrevArrowClick,
  handleExportClick,
  handleExportMenuClose,
  hasPrev,
  hasNext,
  anchorEl,
  onExport,
  isReadyData,
  navIndex
}) => (
  <div style={{ overflow : 'hidden', paddingBottom : '2rem', marginBottom : '-0.875rem' }}>
    <Toolbar className='no-print'>
      <BackButton onClick={() => {
        if (
          document.referrer.indexOf(window.location.host) !== -1) {
          if (R.isNil(window.localStorage.getItem('dashboardId')) || R.isEmpty(window.localStorage.getItem('dashboardId'))) {
            history.go(navIndex);
          }
          else {
            history.push(`/dashboard/saved/${window.localStorage.getItem('dashboardId')}`);
            window.localStorage.removeItem('dashboardId')
          }
        } else {
          history.push(`/`);
        }
      }} />
      <ViewWrapper>
        <ChevronLeftIcon
          onClick={() => hasPrev ? handlePrevArrowClick() : {}}
          nativeColor={hasPrev ? '#000' : '#D3D3D3'}
        />
        <ChevronRightIcon
          onClick={() => hasNext ? handleNextArrowClick() : {}}
          nativeColor={hasNext ? '#000' : '#D3D3D3'}
        />
        <ViewTitle>View Previous / Next</ViewTitle>
      </ViewWrapper>
    </Toolbar>
    <div style={{ float : 'right', marginRight : '2rem', position : 'relative', top : '2rem' }}>
      {isReadyData && <Button
        onClick = {handleExportClick}
        style   = {{
          border          : '2px solid #DE898C',
          color           : '#F3212E',
          backgroundColor : 'transparent',
          borderRadius    : '5px',
          padding         : '0.875rem',
          minWidth        : '120px',
          letterSpacing   : '1px',
        }}
      >
        Export
      </Button>}
      <Menu
        id       = "export-menu"
        anchorEl = {anchorEl}
        open     = {Boolean(anchorEl)}
        onClose  = {() => handleExportMenuClose()}
      >
        <MenuItem onClick={e => { onExport('pdf'); handleExportMenuClose(e); }}>Save as PDF</MenuItem>
        <MenuItem onClick={e => { onExport('excel'); handleExportMenuClose(e); }}>Save as Excel</MenuItem>
      </Menu>
    </div>
  </div>
);

const withCompose = compose(
  withStateHandlers(
    ({
      filteredData = [],
      hasNext      = false,
      hasPrev      = false,
      anchorEl     = null
    }) => ({
      filteredData,
      hasNext,
      hasPrev,
      anchorEl
    }),
    {
      setAnchorEl : () => anchorEl => ({ anchorEl }),
      onHandleChange       : (__, { navigateFilter, onFilterChange }) => ({ target }) => {
        if (navigateFilter !== target.value) {
          onFilterChange(target.value);
        }
      },
      handleNextArrowClick : ({ filteredData, navIndex }, { history, id }) => () => {
        window.localStorage.setItem("displayUnansweredSections", 'NO');
        const currentIndex = R.findIndex(R.propEq('id', id))(filteredData);
        const nextId       = R.pathOr('', [currentIndex + 1, 'id'], filteredData);

        if (nextId) {
          history.push(`/svr-response/${nextId}`, { ids: filteredData, navIndex: --navIndex });
        }
      },
      handlePrevArrowClick : ({ filteredData, navIndex }, { history, id }) => () => {
        window.localStorage.setItem("displayUnansweredSections", 'NO');
        const currentIndex = R.findIndex(R.propEq('id', id))(filteredData);
        const prevId = R.pathOr('', [currentIndex - 1, 'id'], filteredData);

        if (prevId) {
          history.push(`/svr-response/${prevId}`, { ids: filteredData, navIndex: --navIndex });
        }
      },
      setFilteredData      : () => (filteredData, hasNext, hasPrev, navIndex) => ({
        filteredData,
        hasNext,
        hasPrev,
        navIndex
      })

    },
  ),
  withHandlers({
    handleExportClick     : ({ setAnchorEl }) => e => { setAnchorEl(e.currentTarget); },
    handleExportMenuClose : ({ setAnchorEl }) => () => { setAnchorEl(null); }
  }),
  lifecycle({
    componentDidMount() {
      const { navigateFilter, data, svrResponse, setFilteredData, id, currentIds, navIndex } = this.props;

      if (navigateFilter) {
        const filteredData = filterData(data, svrResponse, navigateFilter);
        const currentIndex = R.findIndex(R.propEq('id', id))(filteredData);
        const hasNext      = currentIndex !== filteredData.length - 1;
        const hasPrev      = currentIndex >= 1;

        setFilteredData(filteredData, hasNext, hasPrev, navIndex);
      } else {
        const currentIndex = R.findIndex(R.propEq('id', id))(currentIds);
        const hasNext      = currentIndex !== currentIds.length - 1;
        const hasPrev      = currentIndex >= 1;

        setFilteredData(currentIds, hasNext, hasPrev, navIndex);
      }
    },
    componentDidUpdate(prevProps) {
      const { navigateFilter, data, svrResponse, setFilteredData, id, currentIds, navIndex } = this.props;

      if (prevProps.navigateFilter !== navigateFilter || R.isNil(navigateFilter)) {
        if (navigateFilter) {
          const filteredData = filterData(data, svrResponse, navigateFilter);
          const currentIndex = R.findIndex(R.propEq('id', id))(filteredData);
          const hasNext      = currentIndex !== filteredData.length - 1;
          const hasPrev      = currentIndex >= 1;


          setFilteredData(filteredData, hasNext, hasPrev, navIndex);
        } else {
          const currentIndex = R.findIndex(R.propEq('id', id))(currentIds);
          const hasNext      = currentIndex !== currentIds.length - 1;
          const hasPrev      = currentIndex >= 1;

          setFilteredData(currentIds, hasNext, hasPrev, navIndex);
        }
      }
    }
  })
);

export default withCompose(SvrResponseReviewToolbar);
