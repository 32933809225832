import React, { Component } from 'react';

import SearchTop from './SearchTop';

class SearchComponent extends Component {
  render () {
    const {
      filterByAction,
      filterList,
      syncFilters,
      tableColumns,
      userRole,
      userId,
      currentClient,
      currentProject,
      isClientUser,
      style,
      data,
      prefilledFilters,
      fetchAutocomplete,
      clearAutocomplete,
      autoComplete,
      transformedData,
      showWhen,
      allowSave,
      onSaveAsView,
      onSaveAsWidget,
      onSaveAsRollup,
      namespace,
      displayAsColumn,
      searchFilterStyle
    } = this.props;

    return (
      <SearchTop
        filterByAction    = { filterByAction }
        filterList        = { filterList }
        syncFilters       = { syncFilters }
        namespace         = { namespace }
        data              = { data }
        prefilledFilters  = {prefilledFilters}
        fetchAutocomplete = {fetchAutocomplete}
        clearAutocomplete = {clearAutocomplete}
        autoComplete      = {autoComplete}
        transformedData   = { transformedData }
        tableColumns      = { tableColumns }
        userRole          = { userRole }
        userId            = { userId }
        currentClient     = { currentClient }
        currentProject    = { currentProject }
        isClientUser      = { isClientUser }
        style             = { style }
        searchFilterStyle = { searchFilterStyle }
        allowSave         = { allowSave }
        showWhen          = { showWhen }
        showParamsWhen    = { showWhen }
        displayAsColumn   = { displayAsColumn }
        onSaveAsView      = { allowSave ? onSaveAsView   : () => {}}
        onSaveAsWidget    = { allowSave ? onSaveAsWidget : () => {}}
        onSaveAsRollup    = { allowSave ? onSaveAsRollup : () => {}}
        isRollup          = {false}
      />
    );
  }
}

export default (SearchComponent);
