import React, { Component } from 'react';
import styled from 'styled-components';
import { Modal, TextField, Button } from '@material-ui/core';

class MotiveModal extends Component {
    state = {
        value: ''
    }
    render() {
        const { open, onClose, onSubmit, label } = this.props;
        return <Modal open={open} onClose={onClose} onSubmit={onSubmit}>
            <Box>
                <TextField onChange={e => this.setState({ value: e.target.value })} label={label} variant="standard" multiline rows={6} fullWidth />
                <div style={{ display: "flex", gap: "1rem" }}>
                    <Button variant="text" onClick={onClose}>CANCEL</Button>
                    <Button disabled={!this.state.value.length} color="primary" variant="contained" onClick={() => onSubmit(this.state.value)}>CONTINUE</Button>
                </div>
            </Box>
        </Modal>;
    }
}

export default MotiveModal;

const Box = styled.div`
  min-width: 350px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: white;
  border: 1px solid #000;
  padding: 2rem;
`;