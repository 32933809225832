import uuid from 'uuid/v4';

export const FETCH_ALL          = `SVR/FETCH_ALL`;
export const FETCH_ALL_SUCCESS  = `SVR/FETCH_ALL_SUCCESS`;
export const FETCH_ALL_ERROR    = `SVR/FETCH_ALL_ERROR`;
export const FETCH              = `SVR/FETCH`;
export const CREATE             = `SVR/CREATE`;
export const UPDATE             = `SVR/UPDATE`;
export const DELETE             = `SVR/DELETE`;
export const ARCHIVE            = `SVR/ARCHIVE`;
export const ACTIVE             = `SVR/ACTIVE`;
export const SUCCESS            = `SVR/SUCCESS`;
export const ERROR              = `SVR/ERROR`;

export const fetchSvrs = namespace => dispatch => dispatch({
  types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : '/api/v2/form'
    }
  }
});

export const fetchSvr = (namespace, data) => ({
  type    : FETCH,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/form/canonicalKey/${data}?includeNonActiveForms=true`
    }
  }
});

export const fetchSvrById = (namespace, data) => ({
  type    : FETCH,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/form/formid/${data}`
    }
  }
});

export const createSvrForm = (payload, { userId, userEmail }) => ({
  types   : [
    CREATE,
    SUCCESS,
    ERROR
  ],
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/form',
      data   : {
        title                   : payload.title,
        content                 : JSON.stringify(payload.content),
        canonicalKey            : uuid(),
        authorLegacyId          : userId,
        authorEmail             : userEmail,
        editorEmail             : userEmail,
        tenancyClientId         : payload.tenancyClientId,
        autoApprovalHourTrigger : payload.autoApprovalHourTrigger || 0,
        heroImageUrl            : payload.heroImageUrl,
        status                  : payload.status
      }
    }
  }
});

const postSvr = (isNew) => (payload, { userId, userEmail }) => ({
  types   : [
    isNew ? CREATE : UPDATE,
    SUCCESS,
    ERROR
  ],
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/form',
      data   : {
        title                   : payload.title,
        content                 : JSON.stringify(payload.content),
        canonicalKey            : isNew ? uuid() : payload.canonicalKey,
        authorLegacyId          : isNew ? userId : payload.authorLegacyId,
        authorEmail             : isNew ? userEmail : payload.authorEmail,
        editorEmail             : userEmail,
        tenancyClientId         : payload.tenancyClientId,
        autoApprovalHourTrigger : payload.autoApprovalHourTrigger || 0,
        heroImageUrl            : payload.heroImageUrl,
        status                  : isNew ? 1 : payload.status
      }
    }
  }
});

export const updateDraftSvr = (id, payload, userEmail) => ({
  types   : [
    UPDATE,
    SUCCESS,
    ERROR
  ],
  payload : {
    request : {
      method : 'POST',
      url    : `/api/v2/form/updateDraft/id/${id}`,
      data   : {
        title                   : payload.title,
        content                 : JSON.stringify(payload.content),
        canonicalKey            : payload.canonicalKey,
        authorLegacyId          : payload.authorLegacyId,
        authorEmail             : payload.authorEmail,
        editorEmail             : userEmail,
        tenancyClientId         : payload.tenancyClientId,
        autoApprovalHourTrigger : payload.autoApprovalHourTrigger || 0,
        heroImageUrl            : payload.heroImageUrl
      }
    }
  }
});

export const deleteSvr = id => ({
  type: DELETE,
  payload : {
    request : {
      method : 'DELETE',
      url    : `/api/v2/form/id/${id}`
    }
  }
});

export const updateSvrStatus = (canonicalKey, data) => ({
  type    : UPDATE,
  payload : {
    request : {
      method : 'PUT',
      url    : `/api/v2/form/canonical/${canonicalKey}`,
      data
    }
  }
});

export const createSvr      = postSvr(true);
export const updateSvr      = postSvr(false);
export const createDraftSvr = postSvr(false);
