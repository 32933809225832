import React       from 'react';
import { Helmet }  from 'react-helmet';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';
import {
  compose,
  defaultProps,
  setPropTypes,
}                  from 'recompose';

import Header  from '../../components/Header';

import UsersTable from './components/UsersTable';

import { getClients, getCurrentClient, isClientUser } from '../client/ClientReducer';
import { setCurrentClient as _setCurrentClient }      from '../client/ClientActions';

const AdminPage = ({
    clients,
    currentClient,
    setCurrentClient,
    users,
    isClientUser
  }) => (
  <div>
    <Helmet>
      <title>Admin</title>
    </Helmet>
    <Header
      clients={clients}
      currentClient={currentClient}
      setCurrentClient={setCurrentClient}
      isClientUser={isClientUser()}
    />
    <UsersTable data={users}/>
  </div>
);


const mapStateToProps = state => ({
  clients       : getClients(state),
  currentClient : getCurrentClient(state),
  isClientUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentClient: payload => dispatch(_setCurrentClient(payload)),
});

const withAdmin = compose(
  connect(mapStateToProps, mapDispatchToProps),
  defaultProps({
    setCurrentClient : () => {},
    currentClient    : {},
    clients          : [],
    // Mock data
    users            : [
      {
        id: 1,
        firstName: 'Test',
        lastName: 'User #1',
        company: 'Test Company #1',
        address: 'Test Address #1',
      },
      {
        id: 2,
        firstName: 'Test',
        lastName: 'User #2',
        company: 'Test Company #2',
        address: 'Test Address #2',
      },
      {
        id: 3,
        firstName: 'Test',
        lastName: 'User #3',
        company: 'Test Company #3',
        address: 'Test Address #3',
      },
      {
        id: 4,
        firstName: 'Test',
        lastName: 'User #4',
        company: 'Test Company #4',
        address: 'Test Address #4',
      },
    ],
  }),
  setPropTypes({
    clients       : PropTypes.array,
    currentClient : PropTypes.object,
    users         : PropTypes.array,
  }),
);

export default withAdmin(AdminPage);
