import React     from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/Modal';

const ChangesOnSvrModal = ({ onApprove, onCancel, items, title }) => {
  const modalTitle = title ? title : `Are you sure? This change will affect ${items} item(s)`;
  return (
    <Modal
      onApprove={onApprove}
      onCancel={onCancel}
    >
      {modalTitle}
    </Modal>
  );
};

ChangesOnSvrModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
  items     : PropTypes.number.isRequired,
  title     : PropTypes.string
};

export default ChangesOnSvrModal;
