import React, { Component } from 'react';
import styled from 'styled-components';


import QuestionItem from './QuestionItem';
import QuestionItemUuid from './QuestionItemUuid';
import QuestionGroup from './QuestionGroup';

import { ITEM_TYPES } from '../../BuilderReducer';

const Wrapper = styled.div`
  background-color : #fff;
  margin-right     : auto;
  margin-left      : auto;
  width            : 96%;
  margin-top       : 5px;
  box-shadow       : 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const StyledTitle = styled.div`
  height        : 65px;
  border-bottom : 1px solid #D3D3D3;
  padding-left  : 30px;
  padding-top   : 10px;
  font-size     : 22px;
  color         : #414042;
`;

const Container = styled.div`
  padding: 30px;
`;

const Footer = styled.div`
  height           : 40px;
  background-color : #F8F8F8;
`;

const SectionRow = styled.div`
  padding-left     : 20px;
  background-color : #E4E4E5;
  border-bottom    : 2px solid #DCDCDC;
  display          : flex;
  align-items      : center;
  height           : 30px;
  color            : #909091;
  font-size        : 13px;
  font-weight      : bold;
`;

const HeroImage = styled.div`
  width             : 250px;
  height            : 250px;
  margin            : 20px auto;
  background-image  : url(${({ src }) => src});
  background-size   : contain;
  background-repeat : no-repeat; 
`;

class SvrPreview extends Component {
  render() {
    const { preSections, duringSections, postSections, title, heroImageUrl, isUuid } = this.props;
    return (
      <Wrapper>
        <HeroImage src={heroImageUrl} />
        <StyledTitle>{title}</StyledTitle>
        <Container>
          {
            isUuid ?
              preSections.map(s => (
                <div key={s.uuid}>
                  <SectionRow>{<><span>{`Section: ${s.title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${s.uuid}`}</span> </>}</SectionRow>

                  {s.content.map(q => (
                    q.type === ITEM_TYPES.QUESTION_GROUP ? (
                      <QuestionGroup
                        key={q.uuid}
                        title={`${q.title}`}
                        content={q.content}
                        isUuid={isUuid}
                        iUuid={q.uuid}
                      />
                    ) : (
                      isUuid ?
                        <QuestionItemUuid
                          key={q.uuid}
                          options={q.options}
                          title={<><span>{`${q.title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${q.uuid}`}</span> </>}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                          titleuuid={q.uuid}
                        />
                        :
                        <QuestionItem
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}   ( ${q.uuid} )`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                        />
                    )
                  ))}
                </div>
              ))
              :
              preSections.map(s => (
                <div key={s.uuid}>
                  <SectionRow>{`Section: ${s.title}`}</SectionRow>
                  {s.content.map(q => (
                    q.type === ITEM_TYPES.QUESTION_GROUP ? (
                      <QuestionGroup
                        key={q.uuid}
                        title={`${q.title}`}
                        content={q.content}
                        isUuid={isUuid}
                      />
                    ) : (
                      isUuid ?
                        <QuestionItemUuid
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                          titleuuid={q.uuid}
                        />
                        :
                        <QuestionItem
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                        />
                    )
                  ))}
                </div>
              ))
          }
          {
            isUuid ?
              duringSections.map(s => (
                <div key={s.uuid}>
                  <SectionRow>{<><span>{`Section: ${s.title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${s.uuid}`}</span> </>}</SectionRow>

                  {s.content.map(q => (
                    q.type === ITEM_TYPES.QUESTION_GROUP ? (
                      <QuestionGroup
                        key={q.uuid}
                        title={`${q.title}`}
                        content={q.content}
                        isUuid={isUuid}
                        iUuid={q.uuid}
                      />
                    ) : (
                      isUuid ?
                        <QuestionItemUuid
                          key={q.uuid}
                          options={q.options}
                          title={<><span>{`${q.title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${q.uuid}`}</span> </>}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                          titleuuid={q.uuid}
                        />
                        :
                        <QuestionItem
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}   ( ${q.uuid} )`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                        />
                    )
                  ))}
                </div>
              ))
              :
              duringSections.map(s => (
                <div key={s.uuid}>
                  <SectionRow>{`Section: ${s.title}`}</SectionRow>
                  {s.content.map(q => (
                    q.type === ITEM_TYPES.QUESTION_GROUP ? (
                      <QuestionGroup
                        key={q.uuid}
                        title={`${q.title}`}
                        content={q.content}
                        isUuid={isUuid}
                      />
                    ) : (
                      isUuid ?
                        <QuestionItemUuid
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                          titleuuid={q.uuid}
                        />
                        :
                        <QuestionItem
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                        />
                    )
                  ))}
                </div>
              ))           
          }
          {
            isUuid ?
              postSections.map(s => (
                <div key={s.uuid}>
                  <SectionRow>{<><span>{`Section: ${s.title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${s.uuid}`}</span> </>}</SectionRow>

                  {s.content.map(q => (
                    q.type === ITEM_TYPES.QUESTION_GROUP ? (
                      <QuestionGroup
                        key={q.uuid}
                        title={`${q.title}`}
                        content={q.content}
                        isUuid={isUuid}
                        iUuid={q.uuid}
                      />
                    ) : (
                      isUuid ?
                        <QuestionItemUuid
                          key={q.uuid}
                          options={q.options}
                          title={<><span>{`${q.title}`}</span> <span style={{ color: 'black', marginLeft: 'auto' }}>{`_${q.uuid}`}</span> </>}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                          titleuuid={q.uuid}
                        />
                        :
                        <QuestionItem
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}   ( ${q.uuid} )`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                        />
                    )
                  ))}
                </div>
              ))
              :
              postSections.map(s => (
                <div key={s.uuid}>
                  <SectionRow>{`Section: ${s.title}`}</SectionRow>
                  {s.content.map(q => (
                    q.type === ITEM_TYPES.QUESTION_GROUP ? (
                      <QuestionGroup
                        key={q.uuid}
                        title={`${q.title}`}
                        content={q.content}
                        isUuid={isUuid}
                      />
                    ) : (
                      isUuid ?
                        <QuestionItemUuid
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                          titleuuid={q.uuid}
                        />
                        :
                        <QuestionItem
                          key={q.uuid}
                          options={q.options}
                          title={`${q.title}`}
                          isUuid={isUuid}
                          titletext={`${q.title}`}
                        />
                    )
                  ))}
                </div>
              ))           
           }
        </Container>    
        <Footer />
      </Wrapper>
    );
  }
}

export default SvrPreview;
