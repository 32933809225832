import * as R from 'ramda';

import {
  SET_CURRENT_SAVED_VIEW,
  FETCH_ALL_ERROR,
  FETCH_ALL_SUCCESS,
  UPDATE
} from './SavedViewsActions';

export const STATE_KEY = 'savedViews';

export const getSavedViews       = R.path([STATE_KEY, 'data']);
export const getCurrentSavedView = R.path([STATE_KEY, 'currentSavedView']);

const windowHasLocalStorage = R.compose(
  R.is(Function),
  R.pathOr(null, ['localStorage', 'setItem']),
  R.defaultTo({})
)(window);

const maybeAppendDecorators = R.over(R.lensProp('filters'), R.map((filter={}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
}));

const appendFilterType = R.over(R.lensProp('filters'), R.map((filter={}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
}));

const setInitialCurrentSavedView = () => {
  if (windowHasLocalStorage) {
    try {
      return maybeAppendDecorators(JSON.parse(window.localStorage.getItem('currentSavedView'))) || {};
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
};

const initialState = {
  currentSavedView : setInitialCurrentSavedView(),
  data             : []
};

const SavedViewsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_CURRENT_SAVED_VIEW: {
      if (action.payload) {
        if (windowHasLocalStorage) {
          try {
            window.localStorage.setItem('currentSavedView', JSON.stringify(action.payload));
          } catch (e) {
            return state;
          }
        }
        return R.assoc(
          'currentSavedView',
          R.compose(
            maybeAppendDecorators,
            appendFilterType
          )(action.payload),
          state
        );
      } else {
        return state;
      }

    }

    case FETCH_ALL_ERROR : {
      // TODO ADD APPROPRIATE HANDLING
      return state;
    }

    case FETCH_ALL_SUCCESS : {
      let savedViews = R.pathOr([], ['payload', 'data', 'data'], action);
      if (savedViews.err){
        savedViews = [];
      }
      return R.assoc('data', savedViews, state);
    }

    case `${UPDATE}_SUCCESS`: {
      const savedView = R.pathOr({}, ['payload', 'data', 'data'], action);

      return R.compose(
        R.objOf('data'),
        R.prepend(savedView),
        R.reject(R.propEq('id', savedView.id)),
        R.prop('data')
      )(state);
    }

    default : {
      return state;
    }
  }
};

export default SavedViewsReducer;
