
import * as R from 'ramda';

import mergeFlagStarMotiveAnswers   from './mergeFlagStarMotiveAnswers';
import mergeFlagMotiveQuestions     from './mergeFlagMotiveQuestions';
import mergeStarMotiveQuestions     from './mergeStarMotiveQuestion';

/**
 * Given the questions and answers, per each photo question, if it's necessary, adds a new question "Is <photo question> starred?" or
 * "Is <photo question> flagged?". 
 * This only happens if there is at least one answer photo starred/flagged
 * @param {*} questions 
 * @param {*} answers 
 * @param {*} transformedData 
 * @returns
 */
 const mergePhotoMotives = (questions, answers, transformedData) => {
    let answersWithMotives = mergeFlagStarMotiveAnswers(answers, transformedData, questions);
    const allowedMotiveQuestions = [];
  
    R.compose(
      R.forEach(answer => R.propOr(false, 'questionUuid', answer) &&
         R.endsWith('-motive', answer.questionUuid) &&
         R.propOr(false, 'answerValue', answer) &&
         allowedMotiveQuestions.push(answer.questionUuid)),
      R.flatten,
      R.values
    )(answersWithMotives);
  
    const questionsWithMotives = R.compose(
      qs => mergeStarMotiveQuestions(allowedMotiveQuestions, qs),
      qs => mergeFlagMotiveQuestions(allowedMotiveQuestions, qs)
    )(questions);
  
    return {questions: questionsWithMotives, answers: answersWithMotives};
  };

export default mergePhotoMotives; 
