import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import QuestionItem from './QuestionItem';

const Wrapper = styled.div`
  margin           : 0 0 0 .5rem;
  padding          : .5rem;
  border-bottom    : 2px solid #EEEEEE;
  display          : flex;
  background-color : #F4F4F4;
  align-items      : center;
  color            : #909091;
  font-size        : .875rem;
  font-weight      : bold;
`;

const QuestionGroup = ({
  title,
  content,
  handleSelectQuestion,
  selectedQuestions,
  onlyOne,
  onlyPhotos,
  onlyVideos,
  onlyNumeric,
  onlyMultipleChoice,
  filteredQuestions,
  filterSelectedValue,
  isClientUser
}) => (
  <div>
    <Wrapper>{`Question Group: ${title}`}</Wrapper>
    {content.map(q => (
      <QuestionItem
        key                  = {q.uuid}
        title                = {q.title}
        level                = {1}
        handleSelectQuestion = {handleSelectQuestion}
        selectedQuestions    = {selectedQuestions}
        question             = {q}
        onlyOne              = {onlyOne}
        onlyPhotos           = {onlyPhotos}
        onlyVideos           = {onlyVideos}
        onlyNumeric          = {onlyNumeric}
        onlyMultipleChoice   = {onlyMultipleChoice}
        filteredQuestions    = {filteredQuestions}
        filterSelectedValue  = {filterSelectedValue}
        isInternalQuestion   = {q.meta.isHidden}
        isConditional        = {q.meta.isConditional}
        isClientUser         = {isClientUser}
      />
    ))}
  </div>
);

QuestionGroup.propTypes = {
  handleSelectQuestion : PropTypes.func.isRequired,
  title                : PropTypes.string,
  content              : PropTypes.array,
  selectedQuestions    : PropTypes.array,
  filteredQuestions    : PropTypes.array,
  onlyOne              : PropTypes.bool,
  onlyPhotos           : PropTypes.bool,
  onlyVideos           : PropTypes.bool,
  onlyNumeric          : PropTypes.bool,
  onlyMultipleChoice   : PropTypes.bool,
  filterSelectedValue  : PropTypes.string,
  isClientUser         : PropTypes.bool
};

QuestionGroup.defaultProps = {
  title               : '',
  content             : [],
  selectedQuestions   : [],
  onlyOne             : false,
  onlyPhotos          : false,
  onlyVideos          : false,
  onlyNumeric         : false,
  onlyMultipleChoice  : false,
  filteredQuestions   : [],
  filterSelectedValue : 'all',
  isClientUser        : false
};

export default QuestionGroup;
