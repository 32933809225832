import React                 from 'react';
import PropTypes             from 'prop-types';
import { withStateHandlers } from 'recompose';
import * as R                from 'ramda';
import uuid                  from 'uuid/v4';

import { Button, Chip, Grid, Modal, TextField } from '@material-ui/core';

import { Content, Footer, GridSection, ModalWrapper, TagsWrapper } from './styled-components/Modal';

import { QUESTION_TYPES_NAME }   from '../types';

const CommonQuestionModal = ({
  open,
  title,
  tags,
  type,
  setTitle,
  setCurrentTag,
  addTag,
  currentTag,
  deleteTag,
  save,
  close
}) => (
  <Modal
    aria-labelledby="Create Question"
    aria-describedby="Create Question"
    open={open}
  >
    <ModalWrapper onSubmit={save}>
      <Content>
        <Grid container>
          <GridSection item xs={6}>
            <TextField
              fullWidth
              id="title"
              label={`${R.toUpper(R.propOr('', type, QUESTION_TYPES_NAME))} QUESTION`}
              value={title}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              onChange={setTitle}
            />
          </GridSection>
          <GridSection item xs={6}>
            <TextField
              fullWidth
              id="tags"
              label="TAGS"
              value={currentTag}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={setCurrentTag}
              onKeyPress={e => R.equals('Enter', e.key) && !R.isEmpty(currentTag) && addTag()}
            />
            <TagsWrapper>
              {R.is(Array, tags) && tags.map(tag => (
                <Chip
                  key={`${tag.label}_${tag.id}`}
                  label={tag.label}
                  onDelete={() => deleteTag(tag)}
                />
              ))}
            </TagsWrapper>
          </GridSection>
        </Grid>
      </Content>
      <Footer>
      <Button onClick={close} color="secondary">CANCEL</Button>
      <Button onClick={save} color="secondary">SAVE COMMON QUESTION</Button>
      </Footer>
    </ModalWrapper>
  </Modal>
);

CommonQuestionModal.propTypes = {
  open    : PropTypes.bool,
  title   : PropTypes.string,
  type    : PropTypes.string,
  tags    : PropTypes.array,
  options : PropTypes.array,
};

CommonQuestionModal.defaultProps = {
  open    : false,
  title   : '',
  type    : null,
  tags    : [],
  options : [],
};

const addEditing = withStateHandlers(
  ({
    title             = '',
    currentTag        = '',
    currentAnswer     = '',
    tags              = [],
    addCommonQuestion = null,
  }) => ({ title, tags, currentTag, currentAnswer, addCommonQuestion }),
  {
    setTitle          : () => ({ target }) => ({ title: target.value }),
    setCurrentTag     : () => ({ target }) => ({ currentTag: target.value }),
    setAnswer         : () => ({ target }) => ({ currentAnswer: target.value }),
    // TODO: enable tags update when API will be ready
    addTag            : ({ tags, currentTag }) => () => R.compose(
      R.assoc('currentTag', ''),
      R.assoc('tags', R.__, {}),
      R.concat(tags),
    )([{ id: R.inc(tags.length), label: currentTag }]),
    addOption         : ({ options, currentAnswer }) => () => R.compose(
      R.assoc('currentAnswer', ''),
      R.assoc('options', R.__, {}),
      R.concat(options),
    )([{ label: currentAnswer, value: currentAnswer, conditionalFork: [], uuid: uuid() }]),
    deleteTag         : ({ tags }) => tag => ({ tags: R.reject(R.whereEq(tag), tags) }),
    deleteAnswer      : ({ options }) => option => ({ options: R.reject(R.whereEq(option), options) }),
    save              : ({ title, tags, options }, { k, onSave, onClose, type }) => () => {
    onSave({ uuid: k, title, tags, type, options });
      onClose();
      return {
        title       : '',
        tags        : [],
        options     : [],
      };
    },
    close             : (_, { onClose }) => () => {
      onClose();
      return {
        title       : '',
        tags        : [],
        options     : [],
      };
    },
  },
);

export default addEditing(CommonQuestionModal);
