import * as R from 'ramda';

import {
  FETCH_ALL,
  FETCH,
  CREATE,
  UPDATE,
  DELETE,
} from './SvrActions';

import { getAllQuickReviewQuestions } from './utils/getAllQuickReviewQuestions';

export const STATE_KEY = 'svr';
export const CLIENT_KEY = 'client';

const initialState = {
  data: {},
};

const canonicalKeyAsProp = (a, b) => R.assoc(b.canonicalKey, b, a);

const SvrReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${FETCH_ALL}_SUCCESS`: {
      const data      = R.pathOr([], ['payload', 'data', 'data'])(action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.compose(
        R.assocPath([namespace, 'data'], R.__, state),
        R.reduce(canonicalKeyAsProp, {}),
      )(data);
    }
    case `${FETCH}_SUCCESS`: {
      const data      = R.pathOr({}, ['payload', 'data', 'data'])(action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return R.compose(
        R.assocPath([namespace, 'data'], R.__, state),
        R.reduce(canonicalKeyAsProp, {}),
      )([data]);
    }
    case `${CREATE}_SUCCESS`: {
      const svr         = R.pathOr({}, ['payload', 'data', 'data'], action);
      const contentLens = R.lensProp('content');
      const modifySvr   = R.assoc('content', svr);

      return R.set(
        contentLens,
        R.assoc(modifySvr.canonicalKey, modifySvr, R.view(contentLens, state)),
        state,
      );
    }
    case `${UPDATE}_SUCCESS`: {
      const modifySvr = R.pathOr({}, ['payload', 'data', 'data', 0], action);
      const contentLens  = R.lensProp('data');

      return R.set(
        contentLens,
        R.assoc(modifySvr.canonicalKey, modifySvr, R.view(contentLens, state)),
        state
      );
    }
    case `${DELETE}_SUCCESS`: {
      // TODO implement PUT { status : 0 } to all forms with canonicalKey
      const svrId    = R.path(['meta', 'previousAction', 'payload', 'id'], action);
      const dataLens = R.lensProp('data');
      const svrs     = R.view(dataLens, state);

      return R.set(
        dataLens,
        R.omit([svrId], svrs),
        state,
      );
    }
    default: {
      return state;
    }
  }
};

export const getSvrs = (state, namespace=STATE_KEY) => R.values(
  R.pathOr({}, [STATE_KEY, namespace, 'data'], state)
) || [];

export const getClientList = R.path([CLIENT_KEY, 'data']);

export const getSvr = (state, namespace, canonicalKey) => R.pathOr(null, [STATE_KEY, namespace, 'data', canonicalKey], state);

export const getQuickReviewForm = (state, namespace=STATE_KEY) => {
  const form = getSvrs(state, namespace)[0];

  return form && getAllQuickReviewQuestions(form);
};

export const getClient = R.compose(R.pick(['currentClient', 'currentProject']), R.prop('client'));

export default SvrReducer;
