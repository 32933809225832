import React      from 'react';
import { Link }   from 'react-router-dom';
import styled     from 'styled-components';
import { Button, Hidden } from '@material-ui/core';

const Toolbar = styled.div`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 10px;
  padding         : 0 30px;
`;

const PlainLink = styled(Link)`
  text-decoration: none;
`;

const SvrListToolbar = () => (
  <Hidden only={['xs', 'sm']}>
    <Toolbar>
      <PlainLink to="/svrs/create">
        <Button
          style={{
            border          : '2px solid #DE898C',
            color           : '#F3212E',
            backgroundColor : 'transparent',
            borderRadius    : '5px',
            padding         : '8px',
            minWidth        : '120px',
            letterSpacing   : '1px'
          }}
        >
          Create New
        </Button>
      </PlainLink>
    </Toolbar>
  </Hidden>
);

export default SvrListToolbar;
