import React     from 'react';
import styled    from 'styled-components';
import PropTypes from 'prop-types';
import * as R    from 'ramda';

import QuestionItem  from './QuestionItem';
import QuestionGroup from './QuestionGroup';

import { ITEM_TYPES } from '../../../svr/BuilderReducer';

const Container = styled.div`
  padding : 30px;
`;

const SectionRow = styled.div`
  padding-left     : 20px;
  background-color : #E4E4E5;
  border-bottom    : 2px solid #DCDCDC;
  display          : flex;
  align-items      : center;
  height           : 30px;
  color            : #909091;
  font-size        : 13px;
  font-weight      : bold;
`;


const SectionRowClone = styled.div`
  padding-left     : 20px;
  background-color : #e88c92;
  border-bottom    : 2px solid #DCDCDC;
  display          : flex;
  align-items      : center;
  height           : 30px;
  color            : #FFFFFF;
  font-size        : 13px;
  font-weight      : bold;
`;
  

const SvrResponseReviewFormAnswers = ({
  preSections,
  duringSections,
  postSections,
  formAnswers,
  photos,
  signatures,
  videos,
  reviewMode,
  quickReviewMode,
  onOpenAnswerFeedbackModal,
  rejectedQuestions,
  onThumbnailClick,
  onVideoThumbnailClick,
  isClientUser,
  svrResponse,
  isEditingPhotos,
  onSwitchPhotos,
  onOpenDeletePhotoModal
}) => {
  const blockPhotos = R.pathOr([], ['metaJson', 'blockPhotos'])(svrResponse);
  const blockVideos = R.pathOr([], ['metaJson', 'blockVideos'])(svrResponse);
  const rotateVideos = R.pathOr([], ['metaJson', 'rotateVideos'])(svrResponse);
  const filterHidden = q => !isClientUser || !R.pathEq(['meta', 'isHidden'], true, q);
  const getCurrentAnswer = question => R.pathOr('', [question.uuid, 'answerValue'], formAnswers);
  const sreview =  Number(svrResponse.metaReviewState) === 3 ? "Approved" : "No";

  const sectionsquestionslength = (secContent) => {
    var unansweredcount = 0;
    if (!R.isNil(secContent) && !R.isEmpty(secContent) && R.length(secContent) > 0) {
      for(var cnt = 0; cnt < secContent.length; cnt++) {
         var answerQuestion =   R.pathOr('', [secContent[cnt].uuid, 'answerValue'], formAnswers);

         if(R.isEmpty(answerQuestion))
         {
           unansweredcount = unansweredcount + 1;
         }
            
      }
    }
    return unansweredcount;
  }

  return (
      <Container>
      {preSections.map(s => (
        <div key={s.uuid}>
          {!quickReviewMode &&  ( s.content.length === sectionsquestionslength(s.content) && window.localStorage.getItem("displayUnansweredSections") === 'NO'
                                    ? <SectionRowClone>{`Section: ${s.title}`}</SectionRowClone> :
                                      <SectionRow>{`Section: ${s.title}`}</SectionRow> )}
          {s.content.filter(filterHidden).map(q => (
            q.type === ITEM_TYPES.QUESTION_GROUP ? (
              <QuestionGroup
                key                       = {q.uuid}
                title                     = {q.title}
                content                   = {q.content}
                formAnswers               = {formAnswers}
                reviewMode                = {reviewMode}
                quickReviewMode           = {quickReviewMode}
                onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
                rejectedQuestions         = {rejectedQuestions}
                photos                    = {photos}
                videos                    = {videos}
                isClientUser              = {isClientUser}
                svrResponse               = {svrResponse}
                isEditingPhotos           = {isEditingPhotos}
                onSwitchPhotos            = {onSwitchPhotos}
                onDeletePhotoClick        = {onOpenDeletePhotoModal}
                cReview                   = {sreview} 
                iBlockedPhoto             = {!R.isEmpty(getCurrentAnswer(q)) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
                                              , R.compose(
                                                R.defaultTo({ url: '', xsurl: '' }),
                                                R.find(R.propEq('uuid', getCurrentAnswer(q)))
                                              )(q.type === 'photo-input' ? photos : signatures)))(blockPhotos))} 
               iContentlength             = {s.content.length}
               unAnsweredQuestionlength   = {sectionsquestionslength(s.content)}             
              />
            ) : (
              <QuestionItem
                key                       = {q.uuid}
                options                   = {q.options}
                title                     = {q.title}
                answerTitle               = ''
                currentAnswer             = {getCurrentAnswer(q)}
                formAnswers               = {formAnswers}
                photos                    = {photos}
                signatures                = {signatures}
                videos                    = {videos}
                reviewMode                = {reviewMode}
                quickReviewMode           = {quickReviewMode}
                isQuickReview             = {q.meta.isQuickReview}
                isInternalQuestion        = {q.meta.isHidden}
                onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
                question                  = {q}
                isRejected                = {R.has(q.uuid, rejectedQuestions)}
                rejectedQuestions         = {rejectedQuestions}
                onThumbnailClick          = {onThumbnailClick}
                onVideoThumbnailClick     = {onVideoThumbnailClick}
                isConditional             = {q.meta.isConditional}
                isClientUser              = {isClientUser}
                blockPhotos               = {blockPhotos}
                blockVideos               = {blockVideos}
                rotateVideos              = {rotateVideos}
                isEditingPhotos           = {isEditingPhotos}
                onSwitchPhotos            = {onSwitchPhotos}
                svrResponse               = {svrResponse}
                onDeletePhotoClick        = {onOpenDeletePhotoModal}
                cReview                   = {sreview}
                iBlockedPhoto             = {!R.isEmpty(getCurrentAnswer(q)) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
                                            , R.compose(
                                              R.defaultTo({ url: '', xsurl: '' }),
                                              R.find(R.propEq('uuid', getCurrentAnswer(q)))
                                              )(q.type === 'photo-input' ? photos : signatures)))(blockPhotos))}
                iContentlength            = {s.content.length}
                unAnsweredQuestionlength  = {sectionsquestionslength(s.content)}                                     
              />
            )
          ))}
        </div>
      ))}

      {duringSections.map(s => (
        <div key={s.uuid}>
          {!quickReviewMode &&  ( s.content.length === sectionsquestionslength(s.content) && window.localStorage.getItem("displayUnansweredSections") === 'NO'
                                    ? <SectionRowClone>{`Section: ${s.title}`}</SectionRowClone> :
                                      <SectionRow>{`Section: ${s.title}`}</SectionRow> )}
          {s.content.filter(filterHidden).map(q => (
            q.type === ITEM_TYPES.QUESTION_GROUP ? (
              <QuestionGroup
                key                       = {q.uuid}
                title                     = {q.title}
                content                   = {q.content}
                formAnswers               = {formAnswers}
                reviewMode                = {reviewMode}
                quickReviewMode           = {quickReviewMode}
                onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
                rejectedQuestions         = {rejectedQuestions}
                photos                    = {photos}
                videos                    = {videos}
                isClientUser              = {isClientUser}
                svrResponse               = {svrResponse}
                isEditingPhotos           = {isEditingPhotos}
                onSwitchPhotos            = {onSwitchPhotos}
                onDeletePhotoClick        = {onOpenDeletePhotoModal}
                cReview                   = {sreview}
                iBlockedPhoto             = {!R.isEmpty(getCurrentAnswer(q)) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
                                              , R.compose(
                                                R.defaultTo({ url: '', xsurl: '' }),
                                                R.find(R.propEq('uuid', getCurrentAnswer(q)))
                                                )(q.type === 'photo-input' ? photos : signatures)))(blockPhotos))}
                iContentlength            = {s.content.length}
                unAnsweredQuestionlength  = {sectionsquestionslength(s.content)}  
              />
            ) : (
              <QuestionItem
                key                       = {q.uuid}
                options                   = {q.options}
                title                     = {q.title}
                answerTitle               = ''
                currentAnswer             = {R.pathOr('', [q.uuid, 'answerValue'], formAnswers)}
                formAnswers               = {formAnswers}
                photos                    = {photos}
                signatures                = {signatures}
                videos                    = {videos}
                reviewMode                = {reviewMode}
                quickReviewMode           = {quickReviewMode}
                isQuickReview             = {q.meta.isQuickReview}
                isInternalQuestion        = {q.meta.isHidden}
                onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
                question                  = {q}
                isRejected                = {R.has(q.uuid, rejectedQuestions)}
                rejectedQuestions         = {rejectedQuestions}
                onThumbnailClick          = {onThumbnailClick}
                onVideoThumbnailClick     = {onVideoThumbnailClick}
                isConditional             = {q.meta.isConditional}
                isClientUser              = {isClientUser}
                blockPhotos               = {blockPhotos}
                blockVideos               = {blockVideos}
                rotateVideos              = {rotateVideos}
                isEditingPhotos           = {isEditingPhotos}
                onSwitchPhotos            = {onSwitchPhotos}
                svrResponse               = {svrResponse}
                onDeletePhotoClick        = {onOpenDeletePhotoModal}
                cReview                   = {sreview}
                iBlockedPhoto             = {!R.isEmpty(getCurrentAnswer(q)) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
                                              , R.compose(
                                              R.defaultTo({ url: '', xsurl: '' }),
                                              R.find(R.propEq('uuid', getCurrentAnswer(q)))
                                              )(q.type === 'photo-input' ? photos : signatures)))(blockPhotos))}
                iContentlength            = {s.content.length}
                unAnsweredQuestionlength  = {sectionsquestionslength(s.content)}
              />
            )
          ))}
        </div>
      ))}

      {postSections.map(s => (
        <div key={s.uuid}>
          {!quickReviewMode && ( s.content.length === sectionsquestionslength(s.content) && window.localStorage.getItem("displayUnansweredSections") === 'NO'
                                    ? <SectionRowClone>{`Section: ${s.title}`}</SectionRowClone> :
                                      <SectionRow>{`Section: ${s.title}`}</SectionRow> )}
          {s.content.filter(filterHidden).map(q => (
            q.type === ITEM_TYPES.QUESTION_GROUP ? (
              <QuestionGroup
                key                       = {q.uuid}
                title                     = {q.title}
                content                   = {q.content}
                formAnswers               = {formAnswers}
                reviewMode                = {reviewMode}
                quickReviewMode           = {quickReviewMode}
                onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
                rejectedQuestions         = {rejectedQuestions}
                photos                    = {photos}
                videos                    = {videos}
                isClientUser              = {isClientUser}
                svrResponse               = {svrResponse}
                isEditingPhotos           = {isEditingPhotos}
                onSwitchPhotos            = {onSwitchPhotos}
                onDeletePhotoClick        = {onOpenDeletePhotoModal}
                cReview                   = {sreview}
                iBlockedPhoto             = {!R.isEmpty(getCurrentAnswer(q)) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
                                             , R.compose(
                                              R.defaultTo({ url: '', xsurl: '' }),
                                              R.find(R.propEq('uuid', getCurrentAnswer(q)))
                                               )(q.type === 'photo-input' ? photos : signatures)))(blockPhotos))}
                iContentlength            = {s.content.length}
                unAnsweredQuestionlength  = {sectionsquestionslength(s.content)}
              />
            ) : (
              <QuestionItem
                key                       = {q.uuid}
                options                   = {q.options}
                title                     = {q.title}
                answerTitle               = ''
                currentAnswer             = {R.pathOr('', [q.uuid, 'answerValue'], formAnswers)}
                formAnswers               = {formAnswers}
                photos                    = {photos}
                signatures                = {signatures}
                videos                    = {videos}
                reviewMode                = {reviewMode}
                quickReviewMode           = {quickReviewMode}
                isQuickReview             = {q.meta.isQuickReview}
                isInternalQuestion        = {q.meta.isHidden}
                onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
                question                  = {q}
                isRejected                = {R.has(q.uuid, rejectedQuestions)}
                rejectedQuestions         = {rejectedQuestions}
                onThumbnailClick          = {onThumbnailClick}
                onVideoThumbnailClick     = {onVideoThumbnailClick}
                isConditional             = {q.meta.isConditional}
                isClientUser              = {isClientUser}
                blockPhotos               = {blockPhotos}
                blockVideos               = {blockVideos}
                rotateVideos              = {rotateVideos}
                isEditingPhotos           = {isEditingPhotos}
                onSwitchPhotos            = {onSwitchPhotos}
                svrResponse               = {svrResponse}
                onDeletePhotoClick        = {onOpenDeletePhotoModal}
                cReview                   = {sreview}
                iBlockedPhoto             = {!R.isEmpty(getCurrentAnswer(q)) && isClientUser && !R.isNil(R.find(R.eqProps('uuid'
                                            , R.compose(
                                            R.defaultTo({ url: '', xsurl: '' }),
                                            R.find(R.propEq('uuid', getCurrentAnswer(q)))
                                            )(q.type === 'photo-input' ? photos : signatures)))(blockPhotos))}
                iContentlength            = {s.content.length}
                unAnsweredQuestionlength  = {sectionsquestionslength(s.content)}                           
              />
            )
          ))}
        </div>
      ))}
    </Container>
  );
};

SvrResponseReviewFormAnswers.propTypes = {
  preSections     : PropTypes.array,
  duringSections  : PropTypes.array,
  postSections    : PropTypes.array,
  photos          : PropTypes.array,
  videos          : PropTypes.array,
  formAnswers     : PropTypes.object,
  reviewMode      : PropTypes.bool,
  quickReviewMode : PropTypes.bool,
  isClientUser    : PropTypes.bool
};

SvrResponseReviewFormAnswers.defaultProps = {
  preSections     : [],
  duringSections  : [],
  postSections    : [],
  photos          : [],
  videos          : [],
  formAnswers     : {},
  reviewMode      : false,
  quickReviewMode : false,
  isClientUser    : false
};

export default SvrResponseReviewFormAnswers;
