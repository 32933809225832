import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';
import * as R                from 'ramda';
import uuid from 'uuid/v4';

import { Button, Chip, Grid, Modal, TextField } from '@material-ui/core';

const FOOTER_HEIGHT = 40;

const ModalWrapper = styled.form`
  position         : absolute;
  width            : 60%;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  background-color : #fff;
  border-left      : 10px solid #45a2cb;
  border-radius    : 3px;
  outline          : none;
`;

const Content = styled.div`
  display    : flex;
  max-height : 90vh;
  padding    : 20px;
  box-sizing : border-box;
  overflow   : auto;
`;

const Footer = styled.div`
  display         : flex;
  height          : ${FOOTER_HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-end;
`;

const TagsWrapper = styled.div`
  max-height : 100%;
  & > div {
    margin-right : 10px;
    margin-top   : 10px;
  }
`;

const QuestionGroupModal = ({
  open,
  title,
  currentTag,
  tags,
  setTitle,
  setCurrentTag,
  save,
  close,
  addTag,
  deleteTag,
}) => (
  <Modal
    aria-labelledby="Create Section"
    aria-describedby="Create Section"
    open={open}
  >
    <ModalWrapper onSubmit={save}>
      <Content>
        <Grid container>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="title"
              label="QUESTION GROUP TITLE"
              value={title}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              onChange={setTitle}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="tags"
              label="TAGS"
              helperText="Tags will be auto-applied to all questions within this question group."
              value={currentTag}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={setCurrentTag}
              onKeyPress={e => R.equals('Enter', e.key) && !R.isEmpty(currentTag) && addTag()}
            />
            <TagsWrapper>
              {R.is(Array, tags) && tags.map(tag => (
                <Chip
                  key={`${tag.label}_${tag.uuid}`}
                  label={tag.label}
                  onDelete={() => deleteTag(tag)}
                />
              ))}
            </TagsWrapper>
          </Grid>
        </Grid>
      </Content>
      <Footer>
        <Button onClick={close} color="secondary">CANCEL</Button>
        <Button onClick={save} color="secondary">SAVE QUESTION GROUP</Button>
      </Footer>
    </ModalWrapper>
  </Modal>
);

QuestionGroupModal.propTypes = {
  open                     : PropTypes.bool,
  title                    : PropTypes.string,
  content                  : PropTypes.array,
  meta                     : PropTypes.object,
  tags                     : PropTypes.arrayOf(PropTypes.object),
  type                     : PropTypes.string,
  setTitle                 : PropTypes.func.isRequired,
  setCurrentTag            : PropTypes.func.isRequired,
  onSave                   : PropTypes.func.isRequired,
  save                     : PropTypes.func.isRequired,
  close                    : PropTypes.func.isRequired,
  addTag                   : PropTypes.func.isRequired,
  deleteTag                : PropTypes.func.isRequired,
};

const componentInitialState = {
  title   : '',
  type    : '',
  tags    : [],
  content : [],
  meta    : {},
};

QuestionGroupModal.defaultProps = {
  open: false,
  ...componentInitialState,
};

const addEditing = withStateHandlers(
  ({ content = [], title = '', tags = [], meta = {} }) => ({ title, tags, meta, content }),
  {
    setTitle                 : () => ({ target }) => ({ title: target.value }),
    setCurrentTag            : () => ({ target }) => ({ currentTag: target.value }),
    addTag                   : ({ tags, currentTag }) => () => R.compose(
      R.assoc('currentTag', ''),
      R.assoc('tags', R.__, {}),
      R.concat(tags),
    )([{ uuid: R.inc(tags.length), label: currentTag }]),
    deleteTag                : ({ tags }) => tag => ({ tags: R.reject(R.whereEq(tag), tags) }),
    save                     : ({ title, tags, meta, content }, { k, ck, onSave, onClose, type }) => () => {
      onSave({
        uuid         : k,
        canonicalKey : ck === undefined ? uuid() : ck,
        title,
        tags,
        meta,
        content,
        type
      });
      onClose();
      return componentInitialState;
    },
    close                    : (_, { onClose }) => () => {
      onClose();
      return componentInitialState;
    },
  },
);

export default addEditing(QuestionGroupModal);
