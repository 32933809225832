import * as R               from 'ramda';
import React, { Component } from 'react';
import { Menu, MenuItem }   from '@material-ui/core';

const ITEM_HEIGHT = 48;

class SavedViewMenu extends Component {
  state = {
    anchorEl : null,
  };

  handleClick = event => {
    this.setState({ anchorEl : event.currentTarget });
  };

  handleClose = currentSavedView => () => {
    this.setState({ anchorEl : null });
    if (currentSavedView) {
      this.props.handleSelectSavedView(currentSavedView)();
    }
  };

  render() {

    const { anchorEl }                          = this.state;
    const { savedViews, userId, StyledNavLink } = this.props;

    if (R.isEmpty(savedViews)) {
      return null;
    }

    return (
      <div>
        <StyledNavLink
          aria-owns       = { anchorEl ? 'saved-views-menu' : null }
          aria-haspopup   = "true"
          activeClassName = "selected"
          to              = "/manage/saved-views"
          onClick         = { e => { e.preventDefault(); e.stopPropagation(); this.handleClick(e); } }
        >
          Saved Views
        </StyledNavLink>
        <Menu
          id       = "saved-views-menu"
          anchorEl = { anchorEl }
          open     = { Boolean(anchorEl) }
          onClose  = { this.handleClose() }
          PaperProps={{
            style : {
              maxHeight : ITEM_HEIGHT * 4.5
            }
          }}
        >
          {
            R.compose(
              R.map(item => (
                <MenuItem key={item.id} >
                  <StyledNavLink to={`/manage/saved-views/${item.id}`} activeClassName="selected">{ item.title }</StyledNavLink>
                </MenuItem>
              )),
              R.sort(R.ascend(R.prop('title'))),
              R.reject(R.propEq('restrictTokenUserId', userId))
            )(savedViews)

          }

        </Menu>
      </div>
    );
  }
}

export default SavedViewMenu;
