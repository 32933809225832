


import React         from 'react';
import PropTypes     from 'prop-types';
import styled        from 'styled-components';
import Modal         from './components/Modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RejectSvrAllModal = ({ onSave, onCancel,isproceed = true }) => (
  <Modal 
    onSave={ onSave }
    onCancel={ onCancel }
    containerStyles={{width: "500px"}}
    isproceed ={isproceed}
  >
    <Container>
      <h4>Reject All:</h4>
      <p>You are about to mark all questions for revision. This option should only be used in situations where the talent needs to revise the entire SVR. Otherwise, please use the Reject option.</p>
    </Container>
  </Modal>
);

RejectSvrAllModal.propTypes = {
  onSave          : PropTypes.func.isRequired,
  onCancel        : PropTypes.func.isRequired 
};

export default RejectSvrAllModal
