import React            from 'react';
import { DropTarget }   from 'react-dnd';

const DropPhoto = ({ isOver, canDrop, connectDropTarget, children }) => {
    const isOverBackgroundColor = isOver
        ? 'rgba(0,128,0,0.5)'
        : "rgba(0,128,0,0.1)";
    const backgroundColor = canDrop
        ? isOverBackgroundColor
        : '';
    return connectDropTarget(
        <div style={{ backgroundColor }}>
            {children}
        </div>
    );
};

const contract = {
    drop({answer, photo, question, onDrop }, monitor) {
        onDrop && onDrop({
            target: { answer, photo, question}, 
            source: monitor.getItem()
        });
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
        itemType: monitor.getItemType()
    };
}

export default DropTarget(
    'svrPhoto',
    contract,
    collect
)(DropPhoto);
