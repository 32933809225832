import React     from 'react';
import styled    from 'styled-components';
import PropTypes from 'prop-types';
import * as R    from 'ramda';

import QuestionItem  from './QuestionItem';
import QuestionGroup from './QuestionGroup';

import { ITEM_TYPES } from '../../../svr/BuilderReducer';

const Container = styled.div`
  padding: 30px;
`;

const SvrResponseQuickReviewFormAnswers = ({
  formAnswers,
  reviewMode,
  quickReviewMode,
  questions,
  onOpenAnswerFeedbackModal,
  rejectedQuestions,
  photos,
  videos,
  onThumbnailClick,
  isClientUser,
  blockPhotos,
  blockVideos,
  rotateVideos
}) => (
  <Container>
    {questions.filter(q => !isClientUser || !R.pathEq(['meta', 'isHidden'], true, q)).map(q => (
      q.type === ITEM_TYPES.QUESTION_GROUP ? (
        <QuestionGroup
          key                       = {q.uuid}
          title                     = {q.title}
          content                   = {q.content}
          formAnswers               = {formAnswers}
          reviewMode                = {reviewMode}
          quickReviewMode           = {quickReviewMode}
          onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
          rejectedQuestions         = {rejectedQuestions}
          photos                    = {photos}
          videos                    = {videos}
          onThumbnailClick          = {onThumbnailClick}
          blockPhotos               = {blockPhotos}
          blockVideos               = {blockVideos}
          rotateVideos              = {rotateVideos}
          isClientUser              = {isClientUser}
        />
      ) : (
        <QuestionItem
          key                       = {q.uuid}
          options                   = {q.options}
          title                     = {q.title}
          question                  = {q}
          currentAnswer             = {R.pathOr('', [q.uuid, 'answerValue'], formAnswers)}
          formAnswers               = {formAnswers}
          reviewMode                = {reviewMode}
          quickReviewMode           = {quickReviewMode}
          isQuickReview             = {q.meta.isQuickReview}
          isCommonQuestion          = {q.isCommonQuestion}
          answerTitle               = ''
          photos                    = {photos}
          videos                    = {videos}
          isInternalQuestion        = {q.meta.isHidden}
          onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
          isRejected                = {R.has(q.uuid, rejectedQuestions)}
          rejectedQuestions         = {rejectedQuestions}
          onThumbnailClick          = {onThumbnailClick}
          isConditional             = {q.meta.isConditional}
          isClientUser              = {isClientUser}
          blockPhotos               = {blockPhotos}
          blockVideos               = {blockVideos}
          rotateVideos              = {rotateVideos}
        />
      )
    ))}
  </Container>
);

SvrResponseQuickReviewFormAnswers.propTypes = {
  formAnswers     : PropTypes.object,
  reviewMode      : PropTypes.bool,
  quickReviewMode : PropTypes.bool,
  questions       : PropTypes.array,
};

SvrResponseQuickReviewFormAnswers.defaultProps = {
  formAnswers     : {},
  reviewMode      : false,
  quickReviewMode : false,
  questions       : [],
};

export default SvrResponseQuickReviewFormAnswers;
