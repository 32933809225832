import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';
import * as R    from 'ramda';

import QuestionItem from './QuestionItem';

const Wrapper = styled.div`
  display          : flex;
  align-items      : center;
  font-size        : .75rem;
  font-weight      : 500;
  color            : #909091;
  margin           : 0;
  padding          : .5rem 1rem;
  border-bottom    : 2px solid #EEEEEE;
  display          : flex;
  background-color : #F4F4F4;
  align-items      : center;
`;



const QuestionGroup = ({
  title,
  content,
  formAnswers,
  reviewMode,
  onOpenAnswerFeedbackModal,
  rejectedQuestions,
  photos,
  videos,
  onThumbnailClick,
  onVideoThumbnailClick,
  blockPhotos,
  blockVideos,
  rotateVideos,
  isClientUser,
  quickReviewMode,
  isEditingPhotos,
  onSwitchPhotos,
  svrResponse,
  onDeletePhotoClick,
  cReview,
  iBlockedPhoto,
  iContentlength,
  unAnsweredQuestionlength              
}) => {
  
  return (
    <div>
      <Wrapper>{`Question Group: ${title}`}</Wrapper>
      {content.filter(q => !isClientUser || !R.pathEq(['meta', 'isHidden'], true, q)).map(q => (
        <QuestionItem
          key                       = {q.uuid}
          title                     = {q.title}
          level                     = {1}
          formAnswers               = {formAnswers}
          reviewMode                = {reviewMode}
          isCommonQuestion          = {q.isCommonQuestion}
          question                  = {q}
          onOpenAnswerFeedbackModal = {onOpenAnswerFeedbackModal}
          isRejected                = {R.has(q.uuid, rejectedQuestions)}
          rejectedQuestions         = {rejectedQuestions}
          isInternalQuestion        = {q.meta.isHidden}
          isConditional             = {q.meta.isConditional}
          photos                    = {photos}
          videos                    = {videos}
          onThumbnailClick          = {onThumbnailClick}
          onVideoThumbnailClick     = {onVideoThumbnailClick}
          blockPhotos               = {blockPhotos}
          blockVideos               = {blockVideos}
          rotateVideos              = {rotateVideos}
          isClientUser              = {isClientUser}
          quickReviewMode           = {quickReviewMode}
          isQuickReview             = {q.meta.isQuickReview}
          currentAnswer             = {R.pathOr('', [q.uuid, 'answerValue'], formAnswers)}
          options                   = {q.options}
          answerTitle               = ''
          isEditingPhotos           = {isEditingPhotos}
          onSwitchPhotos            = {onSwitchPhotos}
          svrResponse               = {svrResponse}
          onDeletePhotoClick        = {onDeletePhotoClick}
          cReview                   = {cReview}
          iBlockedPhoto             = {iBlockedPhoto}
          iContentlength            = {iContentlength}
          unAnsweredQuestionlength  = {unAnsweredQuestionlength}  
        />
      ))}
    </div>
  );
};

QuestionGroup.propTypes = {
  title                     : PropTypes.string,
  content                   : PropTypes.array,
  formAnswers               : PropTypes.object,
  reviewMode                : PropTypes.bool,
  onOpenAnswerFeedbackModal : PropTypes.func,
  rejectedQuestions         : PropTypes.object,
  isClientUser              : PropTypes.bool.isRequired
};

QuestionGroup.defaultProps = {
  title                     : '',
  content                   : [],
  formAnswers               : {},
  reviewMode                : false,
  onOpenAnswerFeedbackModal : () => {},
  rejectedQuestions         : {},
  isClientUser              : false
};

export default QuestionGroup;
