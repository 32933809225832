import * as R    from 'ramda';
import * as RA   from 'ramda-adjunct';
import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import {
  compose,
  lifecycle,
  setPropTypes,
  withProps, withStateHandlers
} from 'recompose';

import { Typography } from '@material-ui/core';
import { history }    from '../../store';

import WidgetContent            from './WidgetContent';
import WidgetHeader             from './WidgetHeader';

import { connect }              from 'react-redux';
import withUser                 from '../../modules/auth';
import withClient               from '../../modules/client';
import { fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';

const getFontSize = value => {
  if (value < 1000) {
    return '12rem';
  }
  if (value < 10000) {
    return '11rem';
  }
  if (value < 100000) {
    return '8rem';
  }
  if (value < 1000000) {
    return '5rem';
  }
  if (value < 10000000) {
    return '4rem';
  }

  return '3rem';
};

const formatNumber = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const SizedNumericValue = styled.div`
  font-size      : ${({ value }) => getFontSize(value) };
  line-height    : 280px;
  vertical-align : middle;
  margin         : 0 auto;
  padding        : 0;
  text-align     : center;
`;

const SumWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onEditClick,
    onMoveClick
  }) => (
  <div>
    <WidgetHeader
      title        = {title}
      description  = {description}
      onTitleClick = {onTitleClick}
      onEditClick  = {onEditClick}
      onMoveClick  = {onMoveClick}
    />
    <WidgetContent>
      {widgetContent ? (
        R.compose(
          value => <SizedNumericValue value={value}>{formatNumber(value)}</SizedNumericValue>,
          R.when(isNaN, R.always(0)),
          Math.round,
          R.sum,
          R.reject(isNaN),
          R.map(R.partialRight(parseInt, [10])),
          R.filter(R.identity),
          R.pluck('answerValue'),
          R.flatten,
          R.pluck('answers')
        )(widgetContent)
      ) : (
        <Typography paragraph={true} variant="body2">
          No answers...
        </Typography>
      )}
    </WidgetContent>
  </div>
);

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload) => dispatch(fetchQuestionAnswers(namespace, payload)),
});

const mapStateToProps = () => ({});

const mapAnswersToQuestions = (answers={}) => storeVisit => {
  const matchingAnswers = answers[storeVisit.id] || [];
  return R.assoc('answers', matchingAnswers)(storeVisit);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withStateHandlers(null, {
    onAnswers : () => answers => (
      { answers : R.groupBy(R.prop('storeVisitId'))(answers)
    }),
    onItemClick : () => id => {
      history.push('/svr-response/' + id);
    }
  }),
  withProps(({ widgetData : { description, filteredWidgetData, title }, answers }) => ({
    description,
    title,
    widgetContent : R.compose(
      R.filter(R.compose(R.length, R.prop('answers'))),
      R.map(
        R.compose(
          R.zipObj(['id', 'checkInTime', 'projectName', 'retailer', 'doorNumber', 'doorLocation', 'answers']),
          RA.paths([
            ['id'],
            ['expectedCheckinTime'],
            ['metaJson', 'projectName'],
            ['metaJson', 'retailer'],
            ['metaJson', 'doorNumber'],
            ['metaJson', 'doorLocation'],
            ['answers']
          ]),
          mapAnswersToQuestions(answers)
        )
      )
    )(filteredWidgetData)
  })),
  setPropTypes({
    description   : PropTypes.string,
    title         : PropTypes.string,
    widgetData    : PropTypes.object.isRequired,
    onTitleClick  : PropTypes.func,
    onEditClick   : PropTypes.func,
    onMoveClick   : PropTypes.func,
    widgetContent : PropTypes.arrayOf(
      PropTypes.shape({
        id           : PropTypes.number,
        checkInTime  : PropTypes.string,
        question     : PropTypes.string,
        projectName  : PropTypes.string,
        retailer     : PropTypes.string,
        doorLocation : PropTypes.string,
        doorNumber   : PropTypes.string
      })
    )
  }),
  lifecycle({
    componentDidMount() {
      const questions = this.props.widgetData.formQuestionCanonicalKeys || [];

      this.props.fetchQuestionAnswers(this.props.widgetData.metaJson.namespace, questions)
        .then(res => {
          const answers = R.pathOr([], ['payload', 'data', 'data'], res);
          this.props.onAnswers(answers);
        });
    }
  })
)(SumWidgetContainer);
