import * as R      from 'ramda';
import React       from 'react';
import { connect } from 'react-redux';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';
import * as moment from 'moment';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
  withStateHandlers,
  withHandlers
} from 'recompose';

import NotificationDetail       from '../components/details/NotificationDetail';
import NotificationModal from '../components/modals/NotificationModal';

import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../client/ClientActions';

import {
  fetchNotifications  as _fetchNotifications,
  fetchNotification   as _fetchNotification,
  updateNotification  as _updateNotification,
  deleteNotification  as _deleteNotification,
  filterByAction      as _filterByAction,
  transformData       as _transformData,
  setShowSearchFilter as _setShowSearchFilter,
  clearNotifications  as _clearNotifications,

  sendNotification as _sendNotification,
  cancelNotification as _cancelNotification
} from '../NotificationsActions';

import {
  getClients,
  getCurrentClient,
  getCurrentProject,
  isClientUser,
  getAutoComplete
} from '../../client/ClientReducer';

import {
  getNotifications,
  getTransformedData
} from '../NotificationsReducer';

import {
  getUserRole,
  getUserId,
  getEmail
}                               from '../../auth/AuthReducer';
import { Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import Header from '../../../components/Header';
import NotificationDetailToolbar from '../components/details/NotificationDetailToolbar';

const NAMESPACE = 'notifications';

const NotificationDetailPage = ({
  notification,
  navIndex,
  transformedData,
  namespace,
  userEmail,
  isClientUser,
  selectedData,
  isFetchingData,
  feedbackMessage,
  showNotificationModal,
  setShowNotificationModal,
  handleNotificationAnswerChanged,
  currentNotification,
  sendNotification,
  cancelNotification,
  history,
  clients,
  currentClient,
  currentProject,
  setCurrentClient,
  setCurrentProject,
  currentIds
}) => (
  <div>
    <Helmet>
      <title>Notification Detail</title>
    </Helmet>
    <Header
      clients           = {clients}
      currentClient     = {currentClient}
      currentProject    = {currentProject}
      setCurrentClient  = {setCurrentClient}
      setCurrentProject = {setCurrentProject}
      isClientUser      = {isClientUser()}
    />
    <NotificationDetailToolbar
      data           = {transformedData}
      id             = {notification.id}
      history        = {history}
      currentIds     = {currentIds}
      navIndex       = {navIndex}
    />
    <NotificationDetail
      notification          = {notification}
      userEmail            = {userEmail}
      isClientUser         = {isClientUser}
      namespace            = {namespace}
      onSend               = {(id) => sendNotification(namespace, id)}
      onCancel             = {(id) => cancelNotification(namespace, id)}
    />
    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
      <DialogTitle>{feedbackMessage}</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>

    {
      showNotificationModal && (
        <NotificationModal
          onSend                            ={(id) => sendNotification(namespace, id)}
          onCancel                          ={() => setShowNotificationModal(false)}
          onCancelNotification              ={(id) => cancelNotification(namespace, id)}
          notification                      ={currentNotification}
          onHandleNotificationAnswerChanged ={handleNotificationAnswerChanged}
          selectedData                      = { selectedData }
        />
      )
    }
  </div>
);

const maybeDispatchFetchNotifications = (dispatch, namespace, filters) => {
  const activeFilters = R.filter(R.compose(R.not, R.isEmpty, R.prop('data')))(filters);
  if(Object.keys(activeFilters).length) {
    return dispatch(_fetchNotifications(namespace, filters));
  }
  dispatch(_clearNotifications(namespace));
  return Promise.resolve();
};

const tableColumnsDefinition =  {
  mcid: {
    path:       ['mcid'],
    label:      'MCID'
  },
  name: {
    path:       ['name'],
    label:      'Name'
  },
  date_seen: {
    path:       ['date_seen'],
    label:       'Date Seen',
    decorator:  v => v ? moment(v.replace(/z/i, '')).format('M-D-YYYY h:mm A') : 'NA'
  },
  date_scheduled: {
    path:       ['config', 'scheduled', 'date'],
    label:       'Scheduled Date and Time',
    decorator:  v => v ? moment(v.replace(/z/i, '')).format('M-D-YYYY h:mm A') : 'NA'
  }
};

const mapStateToProps = (state, props) => ({
  transformedData     : getTransformedData(state, NAMESPACE),
  transformedDataDict : R.indexBy(R.prop('id'),  getTransformedData(state, NAMESPACE) || []),
  tableColumns        : R.values(tableColumnsDefinition),
  notification        : getNotifications(state, NAMESPACE)[0],
  currentIds          : R.pathOr([], ['location', 'state', 'ids'], props),
  navIndex            : R.pathOr(-1, ['location', 'state', 'navIndex'], props),
  userRole            : getUserRole(state),
  userId              : getUserId(state),
  userEmail           : getEmail(state),
  clients             : getClients(state),
  currentClient       : getCurrentClient(state),
  currentProject      : getCurrentProject(state),
  autoComplete        : getAutoComplete(state),
  isClientUser
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  fetchNotifications  : (namespace, filters) => dispatch(_fetchNotifications(namespace, filters)),
  updateNotification  : (namespace, notificationId, payload) => dispatch(_updateNotification(namespace, notificationId, payload)),
  deleteNotification  : id => dispatch(_deleteNotification(id)),
  persistFilters      : (namespace, filters) => dispatch(_filterByAction(namespace, filters)),
  setShowSearchFilter : bool => dispatch(_setShowSearchFilter(bool)),
  transformData       : (orderBy, order, sortType, filterList, data, namespace) => dispatch(_transformData(orderBy, order, sortType, filterList, data, namespace)),
  fetchAutocomplete   : payload => dispatch(_fetchAutocomplete(payload)),
  clearAutocomplete   : () => dispatch(_clearAutocomplete())
});

const withNotifications = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
      selectedData = [],
      isFetchingData = false,
      feedbackMessage = ""
    }) => ({
      selectedData,
      isFetchingData,
      feedbackMessage
    }),
    ({
      handleSelectData: (__, { transformedData }) => selectedData => {
        if (selectedData.length > 0) {
          return {
            selectedData: R.compose(
              R.filter(d => R.contains(d.id, selectedData))
            )(transformedData)
          };
        }

        return { selectedData };
      },
      setFetchingData : () => (fetching, message) => ({
        isFetchingData: fetching,
        feedbackMessage: R.defaultTo("", message)
      }),
      setShowNotificationModal: (__, { transformedData }) => (show, id) => ({
        showNotificationModal: show,
        currentNotification: show ? R.find(R.propEq('id', id), transformedData) : null
      }),
      handleNotificationAnswerChanged: () => e => ({
        notificationValue: e.target.value
      })
    }),
  ),
  withHandlers({
    fetchNotification : props => (namespace, notificationId) => 
      Promise.resolve(props.setFetchingData(true, 'Getting notification data'))
      .then(() => props.dispatch(_fetchNotification(namespace, notificationId)))
      .then(() => props.setFetchingData(false))
    ,
    filterByAction : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true, 'Searching notifications'))
      .then(() => props.dispatch(_fetchNotifications(namespace, filters)))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    maybeFilterByAction : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true, 'Searching notifications'))
      .then(() => maybeDispatchFetchNotifications(props.dispatch, namespace, filters))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    sendNotification  : props => (namespace, id) => {
      return Promise.resolve(props.setFetchingData(true, 'Sending notification'))
      .then(() => props.dispatch(_sendNotification(namespace, id)))
      .then(() => props.setFetchingData(false));
    },
    cancelNotification  : props => (namespace, id) => {
      return Promise.resolve(props.setFetchingData(true, 'Canceling notificatin'))
      .then(() => props.dispatch(_cancelNotification(namespace, id)))
      .then(() => props.setFetchingData(false));
    }
  }),
  defaultProps({
    notification              : {},
    transformedData           : [],
    tableColumns              : [],
    filterList                : {},
    initialFilterList         : {},
    namespace                 : NAMESPACE,
    isClientUser              : () => {},
    showNotificationModal     : false,
    notificationValue         : -1
  }),
  setPropTypes({
    notification                  : PropTypes.object,
    transformedData               : PropTypes.array,
    title                         : PropTypes.string,
    description                   : PropTypes.string,
    tableColumns                  : PropTypes.array,
    updateNotification            : PropTypes.func.isRequired,
    deleteNotification            : PropTypes.func.isRequired,
    createSavedView               : PropTypes.func,
    createSavedWidget             : PropTypes.func,
    createSavedRollup             : PropTypes.func,
    showSearchFiler               : PropTypes.bool,
    setShowSearchFilter           : PropTypes.func,
    filterList                    : PropTypes.object.isRequired,
    initialFilterList             : PropTypes.object,
    userRole                      : PropTypes.number.isRequired,
    userId                        : PropTypes.number.isRequired,
    isClientUser                  : PropTypes.func,
    showNotificationModal         : PropTypes.bool,
    notificationValue             : PropTypes.number
  }),
  lifecycle({
    componentDidMount() {
      const id = R.path(['match', 'params', 'id'], this.props);
      if (id) {
        this.props.fetchNotification(NAMESPACE, id);
      }
    }
  })
);

export default withNotifications(NotificationDetailPage);
