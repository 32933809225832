import * as R from 'ramda';
import moment from 'moment';

import { maybeDecorateColumn } from './tableColumns';
import { isClientUser } from '../modules/client/ClientReducer';

import getFlattenedQuestions from '../modules/saved-rollups/components/utils/getFlattenedQuestions';

const HIDDEN_FROM_CLIENT = R.path([
  'WINSTON',
  'svrPrivacy',
  'HIDDEN_FROM_CLIENT',
])(window);

export const ensureClientDoesNotSeeHiddenRecords = _isClientUser => n => {
  if (n.privacy === HIDDEN_FROM_CLIENT) {
    return !_isClientUser;
  } else {
    return true;
  }
};

export const maybeAppendDecorators = R.compose(
  R.map((filter = {}) => {
    const decorator = R.path([
      'WINSTON',
      'tableColumns',
      'storeVisit',
      filter.pathStr,
      'decorator'
    ])(window);
    if (R.is(Function, decorator)) {
      return R.assoc('decorator', decorator, filter);
    } else {
      return filter;
    }
  }),
  R.defaultTo([])
);

const appendFilterType = R.map((filter = {}) => {
  const type = R.path([
    'WINSTON',
    'tableColumns',
    'storeVisit',
    filter.pathStr,
    'type',
  ])(window);
  return R.assoc('type', type, filter);
});

export const transformSvrResponsesForWidget = (widgetFilters, svrResponses) => {
  const filterList = R.compose(maybeAppendDecorators, appendFilterType)(
    widgetFilters
  );

  const _isClientUser = isClientUser();

  const compareValueIfFilterHasSelections = props => {
    const path        = props[0],
          data        = props[1],
          decorator   = props[2],
          type        = props[3],
          parsedData  = type === 'number' ? R.map(d => parseInt(d, 10))(data) : data;

    const filterableValue = v =>
      maybeDecorateColumn(decorator)(R.path(path, v));

    const isWithinDateRange = R.anyPass(
      R.map(range => date =>
        moment(date, 'MM/DD/YYYY hh:mm:A').isBetween(
          moment(range[0], 'YYYY-MM-DD'),
          moment(range[1], 'YYYY-MM-DD'),
          'day',
          '[]'
        )
      )(data)
    );

    const finder = type === 'date' ? isWithinDateRange : R.either(R.contains(R.__, parsedData), R.contains(R.__, data));

    return data.length ? R.compose(finder, filterableValue) : R.T;
  };

  const allFilters = R.compose(
    R.map(compareValueIfFilterHasSelections),
    R.values,
    R.map(R.props(['path', 'data', 'decorator', 'type']))
  )(filterList);

  const transformedData = R.compose(
    R.filter(ensureClientDoesNotSeeHiddenRecords(_isClientUser)),
    R.filter(R.allPass(allFilters))
  )(svrResponses);

  return transformedData;
};

const getMediaType = (answer, questions) => {
  if(R.path([answer.questionCanonicalKey, 'meta', 'photoType'], questions)) {
    return 'photo';
  } else if(R.path([answer.questionCanonicalKey, 'meta', 'videoType'], questions)) {
    return 'video';
  }
};

export const mapAnswersToQuestions = (answers, documents, storeVisit, fetchFormsByIds, questions, isClientUser, namespace) => {
  const storeVisitData = storeVisit.filter(s => R.contains(s.id, R.pluck('storeVisitId', answers)));
  const formIds        = R.pluck('formId', storeVisitData);

  if (formIds.length) {
    return fetchFormsByIds(namespace, formIds).then(res => {
      const forms          = R.pathOr([], ['payload', 'data', 'data'], res);
      const mediaQuestions = getFlattenedQuestions(questions, forms, isClientUser);

      const questionsByCanonicalKey = R.indexBy(R.prop('canonicalKey'), mediaQuestions),
            mediaByUuid            = R.indexBy(R.prop('uuid'), documents),
            svrResponsesById        = R.indexBy(R.prop('id'), storeVisitData),
            answersWithValue        = answers.filter(a => !R.isEmpty(a.answerValue));

      const docStatusFromAnswer = p => {
        if(!p) {
          return {};
        }
        const metaJson = R.pathOr({}, [p.storeVisitId, 'metaJson'], svrResponsesById);
        return R.ifElse(
          R.compose(R.anyPass([R.has('flagPhotos'), R.has('starPhotos'), R.has('blockPhotos')])),
          metaJson => R.compose(
            _doc => R.compose(R.assoc('flag', R.__, _doc), Boolean, R.find(R.propEq('uuid', p.answerValue)), R.propOr([], ['flagPhotos']))(metaJson),
            _doc => R.compose(R.assoc('star', R.__, _doc), Boolean, R.find(R.propEq('uuid', p.answerValue)), R.propOr([], ['starPhotos']))(metaJson),
            _doc => R.compose(R.assoc('block', R.__, _doc), Boolean, R.find(R.propEq('uuid', p.answerValue)), R.propOr([], ['blockPhotos']))(metaJson),
          )({}),
          R.always({})
        )(metaJson);
      };

      const widgetContent = answersWithValue.map(p => {
        const content = {
          imgTitle             : R.pathOr('', [p.answerValue, 'title'],                             mediaByUuid),
          imgUrl               : R.pathOr('', [p.answerValue, 'url'],                               mediaByUuid),
          imgUuid              : p.answerValue,
          storeVisitId         : p.storeVisitId,
          doorNumber           : R.pathOr('', [p.storeVisitId, 'metaJson', 'doorNumber'],           svrResponsesById),
          location             : R.pathOr('', [p.storeVisitId, 'metaJson', 'projectEventLocation'], svrResponsesById),
          retailer             : R.pathOr('', [p.storeVisitId, 'metaJson', 'retailer'],             svrResponsesById),
          checkinDate          : R.pathOr('', [p.storeVisitId, 'actualCheckinTime'],                svrResponsesById),
          status               : docStatusFromAnswer(p),
          doorLocation         : R.pathOr('', [p.storeVisitId, 'metaJson', 'doorLocation'],         svrResponsesById),
          expectedCheckinTime  : R.pathOr('', [p.storeVisitId, 'expectedCheckinTime'],              svrResponsesById)
        };

        const media = getMediaType(p, questionsByCanonicalKey);
        if(!media) {
          return content;
        }
        
        const rotation = R.pathOr([], [p.storeVisitId, 'metaJson', 'rotateVideos'], svrResponsesById);
        content.imgRotation = R.compose(
          R.prop('rotate'),
          uuid => R.find(R.propEq('uuid', uuid), rotation),
          R.pathOr('', [p, 'answerValue', 'uuid'])
        )(mediaByUuid);

        if (R.equals(R.pathOr('', [p.questionCanonicalKey, 'meta', `${media}Type`], questionsByCanonicalKey), `before-${media}`)) {
          const afterMedia = mediaQuestions.find(q => q.meta.pairedQuestionUuid === p.questionUuid);

          if (afterMedia) {
            const afterMediaAnswer = answersWithValue.find(a => a.questionCanonicalKey === afterMedia.canonicalKey && a.storeVisitId === p.storeVisitId);

            content.imgTitleAfter     = afterMediaAnswer ? R.pathOr('', [afterMediaAnswer.answerValue, 'title'], mediaByUuid) : '';
            content.imgUrlAfter       = afterMediaAnswer ? R.pathOr('', [afterMediaAnswer.answerValue, 'url'], mediaByUuid) : '';
            content.imgUuidAfter      = afterMediaAnswer ? afterMediaAnswer.answerValue : '';
            content.statusAfter       = docStatusFromAnswer(afterMediaAnswer);
            content.imgRotationAfter  = R.compose(
              R.prop('rotate'),
              uuid => R.find(R.propEq('uuid', uuid), rotation),
              R.unless(R.isNil, () => R.pathOr('', [afterMediaAnswer.answerValue, 'uuid'], mediaByUuid))
            )(afterMediaAnswer);
          }
        }

        if (R.equals(R.pathOr('', [p.questionCanonicalKey, 'meta', `${media}Type`], questionsByCanonicalKey), `after-${media}`)) {
          const pairedQuestionUuid = R.pathOr('', [p.questionCanonicalKey, 'meta', 'pairedQuestionUuid'], questionsByCanonicalKey),
                beforeMedia        = mediaQuestions.find(q => q.uuid === pairedQuestionUuid);

          if (beforeMedia) {
            const beforeMediaAnswer = answersWithValue.find(a => (a.questionCanonicalKey === beforeMedia.canonicalKey || a.questionCanonicalKey === beforeMedia.uuid) && a.storeVisitId === p.storeVisitId);

            content.imgTitleBefore    = beforeMediaAnswer ? R.pathOr('', [beforeMediaAnswer.answerValue, 'title'], mediaByUuid) : '';
            content.imgUrlBefore      = beforeMediaAnswer ? R.pathOr('', [beforeMediaAnswer.answerValue, 'url'], mediaByUuid) : '';
            content.imgUuidBefore     = beforeMediaAnswer ? beforeMediaAnswer.answerValue : '';
            content.statusBefore      = docStatusFromAnswer(beforeMediaAnswer);
            content.imgRotationBefore = R.compose(
              R.prop('rotate'),
              uuid => R.find(R.propEq('uuid', uuid), rotation),
              R.unless(R.isNil, () => R.pathOr('', [beforeMediaAnswer.answerValue, 'uuid'], mediaByUuid))
            )(beforeMediaAnswer);
          }
        }

        return content;
      });

      return widgetContent;
    });
  } else {
    return Promise.resolve([]);
  }

};

export const getFlaggedDocumentUuids = (media) => R.compose(
  R.uniq,
  R.filter(R.identity),
  R.pluck('uuid'),
  R.flatten,
  R.pluck(`flag${media}`),
  R.pluck('metaJson')
);

export const getStarredDocumentUuids = (media) => R.compose(
  R.uniq,
  R.filter(R.identity),
  R.pluck('uuid'),
  R.flatten,
  R.pluck(`star${media}`),
  R.pluck('metaJson')
);

export const getStoreVisitIdsContainingFlaggedDocumentUuids = (media) => R.compose(
  R.uniq,
  R.filter(R.identity),
  R.pluck('storeVisitId'),
  R.flatten,
  R.pluck(`flag${media}`),
  R.pluck('metaJson')
);

export const getStoreVisitIdsContainingStarredDocumentUuids = (media) => R.compose(
  R.uniq,
  R.filter(R.identity),
  R.pluck('storeVisitId'),
  R.flatten,
  R.pluck(`star${media}`),
  R.pluck('metaJson')
);

const WIDGET_WITHOUT_QUESTIONS = ['feedback'];
export const getWidgetTypeWithoutQuestions = rollUp => R.compose(
    t => R.compose(
        R.unless(R.equals(false), R.always(t)),
        R.contains(R.__, WIDGET_WITHOUT_QUESTIONS),
    )(t),
    R.pathOr('', ['metaJson', 'widgetType']),
    R.defaultTo({})
)(rollUp);

