import React     from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/Modal';

const DeleteQuestionsGroupModal = ({ onApprove, onCancel }) => (
  <Modal
    onApprove={onApprove}
    onCancel={onCancel}
  >
    Are you sure? This will delete the question group from the SVR.
  </Modal>
);

DeleteQuestionsGroupModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
};

export default DeleteQuestionsGroupModal;
