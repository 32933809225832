const findFormItem = (uuid, items) => {
    let formItem;
    items.some(item => {
        if(item.uuid === uuid) 
          formItem = item;
        else {
          if(item.content && item.content.length) 
            formItem = findFormItem(uuid, item.content);
          if(item.options && item.options.length)
            formItem = findFormItem(uuid, item.options);
          if(item.conditionalFork && item.conditionalFork.length)
            formItem = findFormItem(uuid, item.conditionalFork);
        }
        return formItem;
    });
    return formItem;
  };

export default findFormItem;
