import * as R    from 'ramda';
import React     from 'react';
import PropTypes from 'prop-types';
import moment    from 'moment';
import styled    from 'styled-components';

import Ul         from '../Ul';
import { H4 }     from '../text';
import Typography from '@material-ui/core/Typography';

const StyledText = styled.div`
  display        : inline-block;
  margin         : 0;
  color          : #b3b3b3;
  font-size      : 0.8125rem;
  letter-spacing : 0.01em;
  margin-right   : 4px;
`;

const StyledTitle = styled.div`
  width      : max-content;
  overflow-x : scroll;

  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 1rem;

  ul {
    list-style: none;
  }
`;

const GeneralListWidgetContent = (
  {
    blurb,
    date,
    projectName,
    retailer,
    doorNumber,
    doorLocation,
    onClick
  }) => (
  <Ul>
    <li onClick={onClick}>
      <StyledTitle>
        <StyledText>{`${moment(date).format('MM/DD/YYYY')} - `}</StyledText>
        <StyledText>{` ${projectName}`}</StyledText>
        <StyledText style={{ marginLeft: '4px' }}>{` ${retailer} | ${doorLocation}`}</StyledText>
        <H4>{`(${doorNumber})`}</H4>
      </StyledTitle>
        <StyledTypography paragraph={R.is(String, blurb)} variant="body2">
          {blurb}
        </StyledTypography>
    </li>
  </Ul>
);

const propTypes = {
  blurb       : PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  date        : PropTypes.string,
  projectName : PropTypes.string
};
GeneralListWidgetContent.propTypes = propTypes;

export default GeneralListWidgetContent;
