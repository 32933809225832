import styled   from 'styled-components';
import { Grid } from '@material-ui/core';

const FOOTER_HEIGHT = 40;

export const ModalWrapper = styled.form`
  position         : absolute;
  width            : 70%;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  background-color : #fff;
  border-radius    : 3px;
  outline          : none;
`;

export const Content = styled.div`
  display     : flex;
  max-height  : 90vh;
  box-sizing  : border-box;
`;

export const Footer = styled.div`
  display         : flex;
  height          : ${FOOTER_HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-end;
`;

export const GridSection = styled(Grid)`
  padding: 10px 20px !important;

  &:nth-child(1),
  &:nth-child(2) {
    border-bottom: 1px solid #d6d7d8;
  }

  &:nth-child(2),
  &:nth-child(4) {
    border-left: 1px solid #d6d7d8;
  }
`;

export const TagsWrapper = styled.div`
  overflow   : auto;
  max-height : 92%;

  & > div {
    margin-right : 10px;
    margin-top   : 10px;
  }
`;

