import React, { Component } from 'react';
import PropTypes from 'prop-types';
import prr from 'prettycats';
import * as R from 'ramda';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/ModeEdit';
import CopyIcon from '@material-ui/icons/ContentCopy';
import DownloadIcon from '@material-ui/icons/GetApp';
import Logo from '../../../components/Logo';

import { maybeDecorateColumn } from '../../../utils/tableColumns';
//import {  makeDecoratedImageUrl, getRotation } from '../../../utils/image';
import { makeDecoratedThumbnailImageUrl, getRotation } from '../../../utils/image';
import theme from '../../../styles/theme';

const HEIGHT = 30;

const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-start;
  padding-left    : 25px;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 7px;

  & > div {
    margin-right : 20px;
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'}
    cursor       : pointer;
  }
`;

const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

const Description = styled.p`
  color         : #B5B5B5;
  font-size     : 15px;
  margin-top    : 5px;
  margin-bottom : 0px;
`;

const Thumbnail = styled.div`
  height            : 15vh;
  min-height        : 100px;
  background-size   : contain;
  background-repeat : no-repeat;
  max-width         : 100%;
  cursor            : pointer;
`;

const shouldHidePhoto = (
  {
    value,
    isClientUser,
    blockPhotos
  }
) => {

  if (isClientUser) {
    return R.has(value, blockPhotos);
  } else {
    return false;
  }
};

const UUID_PATTERN = /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/;

const isProbablyPhoto = prr.isStringMatching(UUID_PATTERN);

const maybeDisplayPhotoThumbnail = (
  {
    isClientUser,
    value,
    photosDict,
    onThumbnailClick,
    blockPhotos
  }
) => {

  if (!isProbablyPhoto(value)) {
    return value;
  }

  const matchingPhoto = photosDict[value];

  if (matchingPhoto) {


    if (shouldHidePhoto({
      blockPhotos,
      isClientUser,
      value
    })) {
      return (
        <div style={{ backgroundColor: '#000', textAlign: 'center' }}>
          <Logo style={{ maxHeight: 'inherit', margin: 'auto', width: '90%' }} />
        </div>
      );
    } else {
      return <Thumbnail style={{ backgroundImage: `url('${makeDecoratedThumbnailImageUrl(getRotation(matchingPhoto.url), 200)(matchingPhoto.url, matchingPhoto.xsurl)}')` }}
        onClick={() => onThumbnailClick(matchingPhoto)} />;
    }

  } else {
    return value;
  }
};

const isSortedBy = (k, orderBy) => orderBy === k;
const maybeSortBy = (k, orderBy, order) => isSortedBy(k, orderBy) ? order : false;

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      onSelectAllClick,
      tableColumns,
      order,
      orderBy,
      numSelected,
      rowCount,
      questions
    } = this.props;
    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox" width={100}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              style={{ height: `${HEIGHT}px` }}
            />
          </TableCell>
          {
            R.map(({ pathStr, label, type, hideColumn }) =>
              hideColumn
                ? null
                : <TableCell
                  key={pathStr}
                  numeric={false}
                  padding="dense"
                  type="head"
                  style={{ whiteSpace: 'nowrap', flexDirection: 'row' }}
                  sortDirection={maybeSortBy(pathStr, orderBy, order)}
                >
                  <Tooltip
                    title="Sort"
                    placement="bottom-end"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={isSortedBy(pathStr, orderBy)}
                      direction={order}
                      onClick={this.createSortHandler(pathStr, type)}
                    >
                      {label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
            )(tableColumns)
          }
          {questions.map(({ title, uuid }) => (
            <TableCell
              key={uuid}
              numeric={false}
              padding="default"
              sortDirection={orderBy === title ? order : false}
            >
              <Tooltip
                title="Sort"
                placement="bottom-end"
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === title}
                  direction={order}
                  onClick={this.createSortHandler(title)}
                >
                  {title}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ))}
        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableColumns: PropTypes.array.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    minHeight: '53px',
    padding: '10px 8px 20px 15px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  typography: {
    fontWeight: 400,
    fontSize: '22px',
    letterSpacing: '1px'
  }
});

let EnhancedTableToolbar = props => {

  const {
    numSelected,
    classes,
    title,
    description,
    toggleRollUpPage,
    photos,
    questionAnswers,
    transformedData,
    blockedPhotos,
    downloadZip,
    isClientUser,
    data
  } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <div>
            <Typography
              variant="title"
              className={classes.typography}
            >
              {title}
            </Typography>
            <p>Total Row Count : { data.length }</p> 
            <Description>{description}</Description>
          </div>
        )}
        <div>
          <DownloadIcon
            style={{ fontSize: '2rem', margin: '0 .15rem', cursor: 'pointer' }}
            onClick={() => {

              const blockUuids = isClientUser ? blockedPhotos : [];
              const storeVisitIds = R.pluck('id', transformedData);

              const rollupPhotos = R.compose(
                R.map(a => R.find(p => p.uuid === a.answerValue)(photos)),
                R.filter(a => isProbablyPhoto(a.answerValue) && R.contains(a.storeVisitId, storeVisitIds)),
                R.flatten,
                R.values
              )(questionAnswers);

              return R.compose(
                R.when(R.length, downloadZip),
                R.without(blockUuids),
                R.pluck('uuid')
              )(rollupPhotos);
            }}
          />
          <CopyIcon style={{ fontSize: '2rem', margin: '0 .15rem', cursor: 'pointer' }} />
          <EditIcon
            style={{ fontSize: '2rem', margin: '0 .15rem', cursor: 'pointer' }}
            onClick={toggleRollUpPage}
          />
        </div>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  photos: PropTypes.array.isRequired,
  getSvrPhotos: PropTypes.func,
  blockedPhotos: PropTypes.array,
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  downloadZip: PropTypes.func.isRequired,
  isClientUser: PropTypes.bool.isRequired,
  data: PropTypes.array,
  transformedData: PropTypes.array
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: '96%',
    marginTop: '12px',
    marginBottom: '50px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  table: {
    minWidth: 800
  },
  tableWrapper: {
    overflowX: 'auto'
  }
});

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: '',
      selected: [],
      page: 0,
      rowsPerPage: props.rowsPerPage
    };
  }

  componentDidUpdate(prevProps) {
    const dataIsDifferent = !R.equals(R.pluck('timestamp', prevProps.transformedData), R.pluck('timestamp', this.props.transformedData));
    const filtersAreDifferent = !R.equals(prevProps.filterList, this.props.filterList);

    if (dataIsDifferent || filtersAreDifferent) {

      const order = this.state.order || 'asc',
        orderBy = this.state.orderBy || 'id',
        sortType = this.state.sortType || 'string',
        filterList = this.props.filterList || {};

        if(!R.isNil(this.props.data) && this.props.data.length > 500)
          {
            //debugger;
            const minData = this.dRange(0,499)
            this.props.transformData(orderBy, order, sortType, filterList, minData, this.props.namespace, this.props.tableColumns);
          }
          else
          {
             this.props.transformData(orderBy, order, sortType, filterList, this.props.data, this.props.namespace, this.props.tableColumns);
          }
    }
  }

  dRange(start, end) {
    //debugger;
    var arrData = [];
    for (var i = start; i <= end; i++) {
      arrData.push(this.props.data[i]);
    }
    return arrData;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dataIsDifferent = !R.equals(R.pluck('timestamp', nextProps.transformedData), R.pluck('timestamp', this.props.transformedData));
    const formsAreDifferent = !R.equals(nextProps.forms, this.props.forms);
    const questionAnswersAreDifferent = !R.equals(nextProps.questionAnswers, this.props.questionAnswers);

    if (dataIsDifferent) {
      const formIds = R.pluck('formId', nextProps.transformedData);
      if (!R.isEmpty(formIds)) {
        this.props.fetchForms(nextProps.namespace, formIds);
      }
    }

    if (formsAreDifferent || questionAnswersAreDifferent) {
      if (nextProps.forms.length && !R.isEmpty(this.props.questionAnswers)) {
        R.compose(
          this.props.fetchPhotoQuestions(nextProps.namespace, nextProps.questionAnswers),
          R.pluck('id')
        )(nextProps.transformedData);
      }
    }
  }
  handleRequestSort = (event, pathStr, sortType) => {
    const orderBy = pathStr || '';

    let order = 'desc';

    if (this.state.orderBy === pathStr && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy, sortType });

      if(!R.isNil(this.props.data) && this.props.data.length > 500)
      {
        //debugger;
        const minData = this.dRange(0,499)
        this.props.transformData(orderBy, order, sortType, this.props.filterList, minData, this.props.namespace);
      }
      else
      {
          this.props.transformData(orderBy, order, sortType, this.props.filterList, this.props.data, this.props.namespace);
      }
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: R.pluck('id', this.props.transformedData) });
      this.props.handleSelectData(R.pluck('id', this.props.transformedData));
      return;
    }
    this.setState({ selected: [] });
    this.props.handleSelectData([]);
  };

  handleClick = (event, id) => {
    event.stopPropagation();

    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    const firstItemIndex = 0;
    const lastItemIndex = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1), () => [].concat(selected, id)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex), () => [].concat(selected.slice(0, -1))],
        [R.lt(0), () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
    this.props.handleSelectData(newSelected);
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => R.contains(id, this.state.selected);

  deleteRollUps = () => {
    this.state.selected.forEach(rollUp => this.props.onDelete(rollUp));
    this.setState({ selected: [] });
  };

  archiveRollUps = () => {
    this.state.selected.forEach(rollUp => this.props.onArchive(rollUp));
    this.setState({ selected: [] });
  };

  activeRollUps = () => {
    this.state.selected.forEach(rollUp => this.props.onActive(rollUp));
    this.setState({ selected: [] });
  };

  render() {
    const {
      classes,
      title,
      description,
      questions,
      questionAnswers,
      data,
      transformedData,
      tableColumns,
      photos,
      getSvrPhotos,
      blockedPhotos,
      toggleRollUpPage,
      isClientUser,
      downloadZip,
      questionAnswersDict,
      photosDict,
      withoutQuestions,
      forms
    } = this.props;

    const {
      order,
      orderBy,
      selected,
      rowsPerPage,
      rowsPerPageOptions,
      page
    } = this.state;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, transformedData.length - page * rowsPerPage);

    const getBlockPhotos = R.compose(
      R.indexBy(R.prop('uuid')),
      R.pathOr([], ['metaJson', 'blockPhotos'])
    );

    const answer = (questionUuid, questionCanonicalKey, storeVisit, isClientUser, blockPhotos) => {

      const currentQuestion = R.path([storeVisit.id, questionCanonicalKey])(questionAnswersDict);

      if (currentQuestion) {
        return maybeDisplayPhotoThumbnail({
          storeVisit,
          questionUuid,
          photosDict,
          blockPhotos,
          isClientUser,
          value: currentQuestion.answerValue,
          onThumbnailClick: img => {
            window.open(img.url);
          }
        });
      } else {
        return '';
      }
    };

    const isLoadingQuestions = R.length(forms) === 0 ? false : !withoutQuestions && R.isEmpty(questionAnswers) && !R.isEmpty(transformedData);
    const isLoadingAnswers =  R.length(forms) === 0 ? false : !withoutQuestions && !isLoadingQuestions && R.isEmpty(questions);

    return (
      <Paper className={classes.root}>
        <Dialog
          onClose={() => { }}
          open={isLoadingQuestions}
        >
          <DialogTitle>Generating Rollup Report</DialogTitle>
          <div style={{ margin: '1rem' }}>Loading questions...</div>
          <div style={{ overflow: 'hidden' }}>
            <CircularProgress
              size={48}
              style={{ margin: '0 auto 2rem auto', display: 'block' }}
            />
          </div>
        </Dialog>
        <Dialog
          onClose={() => { }}
          open={isLoadingAnswers}
        >
          <DialogTitle>Generating Rollup Report</DialogTitle>
          <div style={{ margin: '1rem' }}>Mapping answers to questions and downloading photos...</div>
          <div style={{ overflow: 'hidden' }}>
            <CircularProgress
              size={48}
              style={{ margin: '0 auto 2rem auto', display: 'block' }}
            />
          </div>
        </Dialog>
        {
          ((!R.isEmpty(questionAnswers) && questions !== null && !questions.length) && R.length(forms) === 0) ? (
            <div style={{ width: '50%', padding: '20px', filter: isLoadingQuestions || isLoadingAnswers ? `blur(0.4rem)` : `` }}>
             Either the rollup is associated with archived SVRs or the rollup questions have no submitted answers,
              or you don&#039;t have permission to view them.              
            </div>
          ) : (
            R.length(forms)> 0 ?
            <>
            <div>
              <EnhancedTableToolbar
                numSelected={selected.length}
                selectedIds={selected}
                onDelete={this.deleteRollUps}
                title={title}
                description={description}
                toggleRollUpPage={toggleRollUpPage}
                photos={photos}
                getSvrPhotos={getSvrPhotos}
                blockedPhotos={blockedPhotos}
                downloadZip={downloadZip}
                isClientUser={isClientUser}
                data={data}
                transformedData={transformedData}
                questions={questions}
                questionAnswers={questionAnswers}
              />
              <div className={classes.tableWrapper} style={{ filter: isLoadingQuestions || isLoadingAnswers ? `blur(0.4rem)` : `` }}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    numSelected={selected.length}
                    tableColumns={tableColumns}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={transformedData.length}
                    questions={questions || []}
                    isClientUser={isClientUser}
                  />
                  <TableBody>
                    {transformedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(storeVisit => {
                      const isSelected = this.isSelected(storeVisit.id);
                      const isPendingSubmission = storeVisit.metaJson.reviewState === 0;
                      const maybeDisabledStyle = isPendingSubmission ? { cursor: 'default', backgroundColor: '#fbfbfb' } : {};
                      const blockPhotos = getBlockPhotos(storeVisit);
                      return (
                        <TableRow
                          hover
                          onClick={() => {
                            if (!isPendingSubmission) {
                              this.props.history.push(`/svr-response/${storeVisit.id}`);
                              if (!R.isNil(window.localStorage.getItem('dashboardId')) && !R.isEmpty(window.localStorage.getItem('dashboardId'))) {
                                window.localStorage.removeItem('dashboardId')
                              }
                            }
                          }}
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={storeVisit.id}
                          selected={isSelected}
                          disabled={true}
                          style={{ height: `${HEIGHT}px`, cursor: 'pointer', ...maybeDisabledStyle }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={event => this.handleClick(event, storeVisit.id)}
                              style={{ height: `${HEIGHT}px` }}
                              checked={isSelected}
                            />
                          </TableCell>
                          {
                            R.map(({ pathStr, path, decorator, width, hideColumn }) =>
                              hideColumn
                                ? null
                                : <TableCell
                                  key={pathStr}
                                  padding="dense"
                                  numeric={false}
                                  type="body"
                                  style={width && { minWidth: `${width}px`, maxWidth: `${width}px`, whiteSpace: "normal", wordWrap: 'break-word' }}
                                >{maybeDecorateColumn(decorator)(R.path(path, storeVisit))}</TableCell>
                            )(tableColumns)
                          }
                          {questions && questions.map(({ canonicalKey, uuid }) => {
                            return (
                              <TableCell
                                key={`question_${uuid}_${storeVisit.id}`}
                                style={{ minWidth: '150px', maxWidth: '300px', overflowX: 'scroll' }}
                              >
                                {answer(uuid, canonicalKey, storeVisit, isClientUser, blockPhotos)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 ? (
                      <TableRow style={{ height: HEIGHT * (emptyRows > 5 ? 5 : emptyRows) }}>
                        <TableCell colSpan={6} style={{ height: `${HEIGHT}px` }} />
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </div>
            </div>
            <div>
          <Table>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={1}
                  count={transformedData.length}
                  style={{ height: `${HEIGHT}px` }}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  page={page}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
        <Footer isSelected={selected.length > 0}>
          <div style={{ color: theme.palette.pblack.black }} onClick={() => { }}>OMIT FROM ROLL-UP</div>
        </Footer>
            </>
            : null
          )
        }
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data: PropTypes.array,
  transformedData: PropTypes.array,
  photos: PropTypes.array,
  getSvrPhotos: PropTypes.func,
  isClientUser: PropTypes.bool,
  tableColumns: PropTypes.array,
  rowsPerPage: PropTypes.number,
  filterList: PropTypes.object,
  rowsPerPageOptions: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  downloadZip: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired
};

EnhancedTable.defaultProps = {
  data: [],
  transformedData: [],
  tableColumns: [],
  rowsPerPage: 50,
  filterList: {},
  namespace: 'savedRollup',
  rowsPerPageOptions: [25, 50, 100, 250]
};

export default withStyles(styles)(EnhancedTable);
