import React, { Component }  from 'react';
import { Helmet }            from 'react-helmet';
import { connect }           from 'react-redux';
import * as R                from 'ramda';

import { CommonQuestionsTable, CommonQuestionListToolbar }  from '../components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography
} from '@material-ui/core';

import QuestionModal from '../../svr/components/builder/QuestionModal';

import * as actions           from '../actions/CommonQuestionsActions';
import { getCommonQuestions } from '../reducers/CommonQuestionsReducer';
class CommonQuestionListPage extends Component {
  state = {
    isQuestionModalOpen : false,
    type                : '',
    isFetchingData      : false
   };

  componentDidMount() {
    Promise.resolve(this.setState({isFetchingData: true}))
    .then(() => this.props.fetchCommonQuestions())
    .then(res => this.setState({commonQuestions: res.payload.data.data, isFetchingData: false}));
  }

  openQuestionModal = (type) => {
    this.setState({isQuestionModalOpen: true, type});
  };

  closeQuestionModal = () => {
    this.setState({isQuestionModalOpen: false});
  };


  render () {
    const { isQuestionModalOpen, type, isFetchingData } = this.state;
    const {
      commonQuestions,
      deleteCommonQuestion,
      archiveCommonQuestion,
      activeCommonQuestion,
      updateCommonQuestion,
      isEditingAction,
      saveEditedCommonQuestion,
      isEditing,
      saveCommonQuestion
    } = this.props;

     return (
      <div>
         <Helmet>
            <title>Common Questions</title>
          </Helmet>
          <CommonQuestionListToolbar
            openQuestionModal={this.openQuestionModal}
          />
          <CommonQuestionsTable
            data={commonQuestions}
            onDelete={deleteCommonQuestion}
            onArchive={archiveCommonQuestion}
            onActive={activeCommonQuestion}
            openQuestionModal={this.openQuestionModal}
            isEditingAction={isEditingAction}
            onUpdate={updateCommonQuestion}
          />

          {isQuestionModalOpen && <QuestionModal
            open={isQuestionModalOpen}
            onClose={this.closeQuestionModal}
            type={type}
            onSave={saveCommonQuestion}
            isCreateCommonQuestion
          />}

          {isEditing && isEditing.questionBeingEdited && <QuestionModal
            isCreateCommonQuestion
            k={isEditing.questionBeingEdited.uuid}
            title={isEditing.questionBeingEdited.title}
            type={isEditing.questionBeingEdited.type}
            canonicalKey={isEditing.questionBeingEdited.canonicalKey}
            id={isEditing.questionBeingEdited.id}
            meta={isEditing.questionBeingEdited.meta}
            options={isEditing.questionBeingEdited.options}
            documentIds={isEditing.questionBeingEdited.documentIds}
            open={Boolean(isEditing)}
            onClose={() => isEditingAction(false)}
            onSave={saveEditedCommonQuestion}
            isValidate={!R.equals(isEditing.questionBeingEdited.type, 'common-input')}
            isEditCommonQuestion={!R.isEmpty(isEditing.questionBeingEdited)}
          />}
          <Dialog
            open    = {isFetchingData}
            fullWidth = {true}
          >
          <DialogTitle>Loading...</DialogTitle>
            <DialogContent style={{ justifyContent : 'space-between' }}>
              <Typography>Please wait</Typography>
            </DialogContent>
          </Dialog>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  commonQuestions : getCommonQuestions(state).sort((a, b) => a.timestamp < b.timestamp),
  isEditing       : state.commonQuestions.isEditing,
});

export default connect(mapStateToProps, actions)(CommonQuestionListPage);
