import * as R         from 'ramda';
import styled         from 'styled-components';
import React          from 'react';
import PropTypes      from 'prop-types';
import { connect }    from 'react-redux';
import { history }    from '../../../store';
import { withRouter } from 'react-router';

import {
compose,
defaultProps,
lifecycle,
setPropTypes,
withProps,
withStateHandlers
} from 'recompose';

import { deriveTenancyFilters } from '../../../utils/deriveTenancyFilters';

import {
  createSavedWidget,
  editSavedWidget,
  fetchFormsByIds,
  resetForms
} from '../../../modules/saved-widgets/SavedWidgetsActions';

import { getForms } from '../../../modules/saved-widgets/SavedWidgetsReducer';
import { getPrefilledFilters, getAutoComplete, getCurrentClient,
  getCurrentProject,
  isClientUser } from '../../../modules/client/ClientReducer';
import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../../modules/client/ClientActions';

import {
  getFilterList,
  getTableColumns
} from '../../../modules/svr-responses/SvrResponsesReducer';

import {
  setCurrentSavedDashboard,
  updateSavedDashboard
} from '../../../modules/dashboard/SavedDashboardsActions';

import { getCurrentSavedDashboard } from '../../../modules/dashboard/SavedDashboardsReducer';
import SavedRollupsFormsList        from '../../../modules/saved-rollups/components/SavedRollupsFormsList';

import withClient from '../../../modules/client';
import withUser   from '../../../modules/auth';

import SearchParamsList from '../../SearchFilter/SearchParamsList';

import { withStyles } from '@material-ui/core/styles';
import { Modal }      from '@material-ui/core';

import {
  maybeAppendDecorators,
  transformSvrResponsesForWidget
} from '../../../utils/widgetHelpers';

import recursivelyPluck from '../../../modules/svr/utils/recursivelyPluck';
import moment    from 'moment';

const HEIGHT = 30;
const DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER = 7;

const styles = () => ({
  select : {
    marginTop    : '50px',
    marginBottom : '50px',
    display      : 'none'
  },
  label  : {
    fontWeight    : 'bold',
    textTransform : 'uppercase'
  },
  input : {
    flex   : 2,
    margin : '20px'
  }
});

const Wrapper = styled.div`
  background    : #fff;
  margin        : 40px auto;
  width         : 90vw;
  max-height    : 90vh;
  min-height    : initial;
  overflow-y    : auto;
  border-radius : 2px;
  box-shadow    : 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const Content = styled.div`
  display        : flex;
  flex-direction : row;
  padding        : 50px 100px;
`;

const OptionsContainer = styled.div`
  display        : flex;
  flex-direction : column;
  width          : 100%;
  min-height     : initial;
  border-right   : 2px solid #f2f2f2;
  flex-wrap      : wrap;
  flex-grow      : 1;
`;

const Footer = styled.div`
  display         : flex;
  justify-content : flex-end;
  align-items     : center;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  font-size       : 15px;
  padding         : 8px 25px;

  & > div {
    color  : #cd222c;
    cursor : pointer;
  }
`;

const SumWidgetForm = (
  {
    filterByAction,
    filterList,
    forms,
    handleSelectQuestion,
    handleToggleForm,
    handleUpdate,
    isOpen,
    onClose,
    selectedForm,
    selectedQuestions,
    tableColumns,
    data,
    namespace,
    prefilledFilters,
    fetchAutocomplete,
    clearAutocomplete,
    autoComplete,
    syncFilters
  }) => (
  <Modal
    aria-labelledby  = "create-question-response-widget"
    aria-describedby = "create-question-response-widget"
    open             = {isOpen}
    onClose          = {onClose}
    style            = {{
      overflow       : 'auto',
      justifyContent : 'center',
      alignItems     : 'center'
    }}>
    <Wrapper>
      <Content>
        <OptionsContainer>
          <SearchParamsList
            fetchAutocomplete= {fetchAutocomplete} 
            clearAutocomplete= {clearAutocomplete}
            prefilledFilters= {prefilledFilters}
            autoComplete    = {autoComplete}
            data            = {data}
            tableColumns    = {tableColumns}
            filterByAction  = {filterByAction}
            syncFilters     = {syncFilters}
            filterList      = {filterList}
            namespace       = {namespace}
            allowSave       = {false}
            displayAsColumn = {false}
            isAddorEditWidget = {true}
            isRollup          = {false}   
          />
        </OptionsContainer>
        <SavedRollupsFormsList
          forms                = {forms}
          handleToggleForm     = {handleToggleForm}
          selectedForm         = {selectedForm}
          selectedQuestions    = {selectedQuestions}
          handleSelectQuestion = {handleSelectQuestion}
          onlyOne              = {true}
          onlyNumeric          = {true}
        />
      </Content>
      <Footer style={{ display : 'flex', justifyContent : 'space-between'}}>
        <div onClick = {onClose}>CANCEL</div>
        {
          forms.length === 0 && selectedForm.length === 0
            ? null :
            selectedQuestions.length ? (
              <div onClick={handleUpdate}>NEXT</div>
            ) : (
              <span>Please select a numeric type question to roll up</span>
            )
        }
      </Footer>
    </Wrapper>
  </Modal>
);

const getFirstQuestionWithCanonicalKey = canonicalKey => R.compose(
  R.find(R.propEq('canonicalKey', canonicalKey)),
  R.flatten,
  R.map(recursivelyPluck('content'))
);

// const getNewestFilters = (state, props) => {
//   const stateFilters = getFilterList(props.namespace)(state);
//   const propsFilters = maybeAppendDecorators(props.savedWidgetFormData.filters);

//   if (R.isEmpty(stateFilters)) {
//     return propsFilters;
//   } else {
//     return stateFilters;
//   }
// };

const appendFilterType = R.map((filter = {}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const manageSvrResponsesFilterList = () => {
 
  var todayDate = new Date().toISOString().slice(0, 10);
  todayDate = todayDate.concat(" 23:59:59");  

  const now = moment();
  const pastdate = moment(now).subtract(DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER, 'days').format("YYYY-MM-DD");
  
  var visitArray = [];
  var visitArrayClone = [];
  visitArray.push([pastdate, todayDate]);
  visitArrayClone.push(visitArray);

  return {
    'Visit Date': {
      pathStr: 'expectedCheckinTime',
      path: ['expectedCheckinTime'],
      label: 'Visit Date',
      //decorator: v => window.WINSTON.statuses.reviewState[v || 0],
      decorator: R.path(['WINSTON', 'tableColumns', 'storeVisit', "expectedCheckinTime", 'decorator'])(window),
      data: visitArrayClone
    },
    'Review State': {
      pathStr: 'metaJson.reviewState',
      path: ['metaJson', 'reviewState'],
      label: 'Review State',
      decorator: v => window.WINSTON.statuses.reviewState[v || 0],
      data: R.compose(
        values => R.filter(v => R.equals('Approved', v), values),
        R.values
      )(window.WINSTON.statuses.reviewState)
    }
  };
};


const maybeLoadInitialFilterListFromRouterProps = (state, props) => {
  //debugger;
  if(!R.isNil(props.data) &&  props.data.length > 0)
  {
    const stateFilters = getFilterList(props.namespace)(state);
    const propsFilters = maybeAppendDecorators(props.savedWidgetFormData.filters);
  
    if (R.isEmpty(stateFilters)) {
      return propsFilters;
    } else {
      return stateFilters;
    }
  }
  else
  {
  let storedFilters = {};
  try {
    storedFilters = R.compose(
      maybeAppendDecorators,
      appendFilterType,
      JSON.parse
    )(window.localStorage.getItem(`persistedFilters:${props.namespace}`));
  } catch (e) {
    storedFilters = {};
  }

  //debugger;
  var objFilterSize = Object.keys(storedFilters).length;
  //console.log("storedFilters-Client Name", storedFilters.hasOwnProperty('Client Name'));

  if (objFilterSize === 0 && !isClientUser()) {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      manageSvrResponsesFilterList,
      R.mergeDeepLeft(storedFilters),
      getFilterList(props.namespace)
    )(state);
  }
  else if (objFilterSize === 1 && storedFilters.hasOwnProperty('Client Name') && !isClientUser()) {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      manageSvrResponsesFilterList,
      R.mergeDeepLeft(storedFilters),
      getFilterList(props.namespace)
    )(state);
  }
  else {
    return R.compose(
      maybeAppendDecorators,
      appendFilterType,
      R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
      R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
      R.when(R.isEmpty, R.always(props.initialFilterList || {})),
      R.mergeDeepLeft(storedFilters),
      getFilterList(props.namespace)
    )(state);
  }
}
};


const getNewestSelectedQuestions = (state, props) => {
  const stateQuestions = state.selectedQuestions || [];
  const propsQuestions = maybeAppendDecorators(props.savedWidgetFormData.formQuestionCanonicalKeys);

  if (R.isEmpty(stateQuestions)) {
    return propsQuestions;
  } else {
    return stateQuestions;
  }
};

const mapStateToProps    = (state, props) => ({
  currentSavedDashboard : getCurrentSavedDashboard(state),
  filterList            : maybeLoadInitialFilterListFromRouterProps(state, props),//getNewestFilters(state, props),
  selectedQuestions     : getNewestSelectedQuestions(state, props),
  forms                 : getForms(state, props.namespace),
  tableColumns          : getTableColumns(props.namespace)(state),
  prefilledFilters      : getPrefilledFilters(state),
  autoComplete          : getAutoComplete(state)
});

const mapDispatchToProps = dispatch => ({
  createSavedWidget        : payload => dispatch(createSavedWidget(payload)),
  editSavedWidget          : (data, id) => dispatch(editSavedWidget(data, id)),
  fetchFormsByIds          : (namespace, payload) => payload.length && dispatch(fetchFormsByIds(namespace, payload)),
  resetForms               : namespace => dispatch(resetForms(namespace)),
  setCurrentSavedDashboard : payload => dispatch(setCurrentSavedDashboard(payload)),
  updateSavedDashboard     : (id, data) => dispatch(updateSavedDashboard(id, data)),
  fetchAutocomplete        : payload => dispatch(_fetchAutocomplete(payload)),
  clearAutocomplete        : () => dispatch(_clearAutocomplete()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withProps(({ currentClient, currentProject, filterList, data }) => ({
    filterList      : R.mergeDeepRight(filterList, deriveTenancyFilters(currentClient, currentProject)),
    data : transformSvrResponsesForWidget(
      R.mergeDeepRight(filterList, deriveTenancyFilters(currentClient, currentProject)),
      data
    )
  })),
  withStateHandlers(
    ({
       id                 = null,
       quickSelectedValue = '',
       selectedForm       = '',
       selectedQuestions  = []
     }) => ({
      id,
      quickSelectedValue,
      selectedForm,
      selectedQuestions
    }),
    {
      handleUpdate         : (
        {
          selectedQuestions
        },
        {
          forms,
          createSavedWidget,
          currentSavedDashboard,
          editSavedWidget,
          filterList,
          savedWidgetFormData,
          setCurrentSavedDashboard,
          updateSavedDashboard
        }
      ) => () => {
        const { id, title, metaJson, restrictTokenUserId } = savedWidgetFormData;

        const uniqSelectedQuestions = R.uniq(selectedQuestions);

        const formsContent = R.compose(
          R.flatten,
          R.map(R.path(['content', 'structure']))
        )(forms);

        if (R.isNil(id)) {
          const data = {
            title,
            description               : getFirstQuestionWithCanonicalKey(uniqSelectedQuestions[0])(formsContent).title,
            formQuestionCanonicalKeys : R.join(',', uniqSelectedQuestions),
            filters                   : JSON.stringify(filterList),
            metaJson                  : JSON.stringify(metaJson),
          };

          if (restrictTokenUserId) {
            data.restrictTokenUserId = parseInt(restrictTokenUserId, 10);
          }

          createSavedWidget(data)
            .then(res => {
              updateSavedDashboard(currentSavedDashboard.id, {
                widgetIds: R.compose(
                  R.join(','),
                  R.append(R.__, currentSavedDashboard.widgetIds),
                  R.pathOr(null, ['payload', 'data', 'data', 'id'])
                )(res),
              }).then(_res => {
                setCurrentSavedDashboard(
                  R.pathOr(
                    currentSavedDashboard,
                    ['payload', 'data', 'data'],
                    _res
                  )
                );
                history.push(`/dashboard/saved/${currentSavedDashboard.id}`);
              });
            })
            .catch(err => {
              throw new Error('SumWidgetForm error:', err);
            });
        } else {

          const data = {
            title,
            description               : getFirstQuestionWithCanonicalKey(uniqSelectedQuestions[0])(formsContent).title,
            formQuestionCanonicalKeys : R.join(',', uniqSelectedQuestions),
            filters                   : JSON.stringify(filterList),
            metaJson                  : JSON.stringify(metaJson),
            restrictTokenUserId       : restrictTokenUserId
              ? parseInt(restrictTokenUserId, 10)
              : null
          };

          editSavedWidget(data, id).then(() => {
            history.push(`/dashboard/saved/${currentSavedDashboard.id}`);
          });
        }
      },
      handleToggleForm     : ({ selectedForm }) => formId => ({
        selectedForm : R.equals(selectedForm, formId) ? '' : formId
      }),
      handleSelectQuestion : () => (
        _,
        canonicalKey
      ) => ({
        selectedQuestions : [ canonicalKey ]
      }),
      setWidget            : () => widget => ({
        selectedQuestions : R.propOr([], 'formQuestionCanonicalKeys', widget),
        id                : R.propOr(null, 'id', widget)
      })
    }
  ),
  defaultProps({
    forms : [],
    data  : []
  }),
  withStyles(styles),
  setPropTypes({
    classes : PropTypes.object.isRequired,
    forms   : PropTypes.array
  }),
  lifecycle({
    componentDidMount() {
    },
    componentWillReceiveProps(nextProps) {
      const dataIsDifferent = !R.equals(
        R.pluck('id', nextProps.data),
        R.pluck('id', this.props.data)
      );

      if (dataIsDifferent) {
        const formIds = R.pluck('formId', nextProps.data);

        this.props.resetForms(nextProps.namespace);

        if (!R.isEmpty(formIds)) {
          this.props.fetchFormsByIds(nextProps.namespace, formIds);
        }
      }
    }
  }),
  withRouter
)(SumWidgetForm);
