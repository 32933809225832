import React, { Component } from 'react';
import * as R from 'ramda';
import '../../../../../index.css'
import { axiosClient } from '../../../../../store';
import imghistory from '../../../../../images/history.png';
import imgcomment from '../../../../../images/comment.png';
import imgfeedback from '../../../../../images/feedback.png';
import imgtick from '../../../../../images/Tick.png';
import moment from 'moment';

const INITIAL_STATE = {
    anchorEl: true,
    feedbackMessage: '',
    feedbackstatus: 1,
    feedbacktype: 'svr',
    docid: 0,
    isfeedback: false,
    ishistory: false,
    feedbackdata: [],
    historyicon: 'block',
    resolvetext: ''
};

class ClientFeedbackModal extends Component {
    state = INITIAL_STATE;

    getFeedbackData = async (id) => {

        const resposegetdatasvrFeedback = await axiosClient.get(
            `api/v2/svrfeedback/getfeedback/id/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
                }
            });

        if (resposegetdatasvrFeedback !== null && resposegetdatasvrFeedback !== undefined) {

            if (resposegetdatasvrFeedback.data !== null && resposegetdatasvrFeedback.data !== undefined) {
                if (resposegetdatasvrFeedback.data.data !== null && resposegetdatasvrFeedback.data.data !== undefined && resposegetdatasvrFeedback.data.data.length > 0) {

                    this.setState({
                        feedbackdata: resposegetdatasvrFeedback.data.data
                    });
                }
                else if (resposegetdatasvrFeedback.data.data !== null && resposegetdatasvrFeedback.data.data !== undefined && resposegetdatasvrFeedback.data.data.length === undefined) {
                    const objdata = resposegetdatasvrFeedback.data.data;
                    const datatwo = {
                        createdby: objdata.createdby,
                        createdbyemail: objdata.createdbyemail,
                        createddate: objdata.createddate,
                        feedbacktext: objdata.feedbacktext,
                        feedbackresolvedstatus: objdata.feedbackresolvedstatus
                    }

                    this.setState({
                        feedbackdata: []
                    });
                    this.state.feedbackdata.push(datatwo)
                    this.setState({
                        feedbackdata: this.state.feedbackdata
                    });
                }
                else {
                    this.setState({
                        feedbackdata: []
                    });
                }
            }
        }
    }

    getResolvecheck = async (id) => {
        //debugger;
        const resposegetdatasvrFeedback = await axiosClient.get(
            `api/v2/svrfeedback/getfeedback/id/${id}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
                }
            });

        if (resposegetdatasvrFeedback !== null && resposegetdatasvrFeedback !== undefined) {
            //debugger
            if (resposegetdatasvrFeedback.data !== null && resposegetdatasvrFeedback.data !== undefined) {
                if (resposegetdatasvrFeedback.data.data !== null && resposegetdatasvrFeedback.data.data !== undefined && resposegetdatasvrFeedback.data.data.length > 0) {

                    if (resposegetdatasvrFeedback.data.data["0"].feedbackresolvedstatus === "Y") {

                        this.setState({
                            ishistory: this.state.ishistory === true ? false : true,
                            isfeedback: false,
                            feedbackdata: resposegetdatasvrFeedback.data.data,
                            historyicon: 'none',
                            resolvetext: ' -  Resolved '
                        });

                    }
                    else {
                        if (this.state.isfeedback === false) {
                            this.setState({
                                isfeedback: true
                            });
                        }
                        else {
                            this.setState({
                                isfeedback: false
                            });
                        }

                        this.setState({
                            ishistory: false
                        });
                    }

                }
                else if (resposegetdatasvrFeedback.data.data !== null && resposegetdatasvrFeedback.data.data !== undefined && resposegetdatasvrFeedback.data.data.length === undefined) {
                    const objdata = resposegetdatasvrFeedback.data.data;
                    const datatwo = {
                        createdby: objdata.createdby,
                        createdbyemail: objdata.createdbyemail,
                        createddate: objdata.createddate,
                        feedbacktext: objdata.feedbacktext,
                        feedbackresolvedstatus: objdata.feedbackresolvedstatus
                    }

                    if (datatwo.feedbackresolvedstatus === "Y") {

                        this.setState({
                            ishistory: this.state.ishistory === true ? false : true,
                            isfeedback: false,
                            feedbackdata: resposegetdatasvrFeedback.data.data,
                            historyicon: 'none',
                            resolvetext: ' -  Resolved '
                        });

                    }
                    else {
                        if (this.state.isfeedback === false) {
                            this.setState({
                                isfeedback: true
                            });
                        }
                        else {
                            this.setState({
                                isfeedback: false
                            });
                        }

                        this.setState({
                            ishistory: false
                        });
                    }


                }
                else {
                    if (this.state.isfeedback === false) {
                        this.setState({
                            isfeedback: true
                        });
                    }
                    else {
                        this.setState({
                            isfeedback: false
                        });
                    }

                    this.setState({
                        ishistory: false
                    });
                }
            }
        }
    }



    handleCancelFeedback = (e) => {
        this.setState({
            feedbackMessage: "",
            isfeedback: false
        });

        document.getElementById("svrfeedbackTextarea").value = "";
    }

    showhideFeedback = (e) => id => {
        this.getResolvecheck(id);
    }

    showhideHistory = (e) => id => {
        this.setState({
            ishistory: true
        });

        this.setState({
            isfeedback: false
        });
        this.getFeedbackData(id);
    }

    hideHistory = (e) => {
        this.setState({
            ishistory: false
        });
        this.setState({
            isfeedback: true
        });
    }

    uuidv4 = () => {
        var id_str = [];
        var hxDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            id_str[i] = hxDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        id_str[14] = "4";

        id_str[19] = hxDigits.substr((id_str[19] & 0x3) | 0x8, 1);

        id_str[8] = id_str[13] = id_str[18] = id_str[23] = "-";

        var guid = id_str.join("");
        return guid;
    }

    handleSaveFeedback = async (e) => {

        if (this.state.feedbackMessage !== "" && this.state.feedbackMessage.trim() !== "") {
            document.getElementById("btnsavefeedback").disabled = true;

            const cuuid = this.uuidv4();

            const datasvrFeedback = {
                canonicalkey: cuuid,
                feedbackstatus: this.state.feedbackstatus,
                feedbacktype: this.state.feedbacktype,
                docid: this.state.docid,
                storevisitid: this.props.storeVisitId
            }

            await axiosClient.post(
                `api/v2/svrfeedback/savefeedback`,
                datasvrFeedback,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
                    }
                });

            const datasvrFeedbackinfo = {
                feedback_canonicalkey: cuuid,
                feedbacktext: this.state.feedbackMessage,
                createdby: JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).name === undefined
                    ? this.props.userEmail.substring(0, this.props.userEmail.lastIndexOf("@")) :
                    JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).name,
                createdbyemail: this.props.userEmail
            }

            await axiosClient.post(
                `api/v2/svrfeedbackinfo/savefeedbackinfo`,
                datasvrFeedbackinfo,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).token}`
                    }
                });

            this.setState({
                feedbackMessage: ""
            });

            document.getElementById("svrfeedbackTextarea").value = "";
            document.getElementById("btnsavefeedback").disabled = false;
        }
    };

    handleChangedataFeedback = e => {
        if (e == null || e.target == null)
            return;
        this.setState({
            feedbackMessage: e.target.value.trim()
        });
    };

    render() {
        const { isfeedback, ishistory, feedbackdata } = this.state;
        const { storeVisitId } = this.props;
        return (
            <div>
                {
                    ishistory ?
                        <div className="app-wrapper fix">
                            <div className="card-header">
                                <span>Client Feedback History{this.state.resolvetext}</span>
                                {
                                    this.state.resolvetext === '' ?
                                    <img src={imgfeedback} style={{ display: this.state.historyicon }} onClick={e => { this.hideHistory(e); }} className='img-responsive' title="Feedback" alt='Feedback'></img>
                                    :
                                    <img src={imgtick} className='img-responsive' title="Resolved" alt='Resolved'></img>
                                }
                            </div>
                            <div className="app-message-body">
                                {
                                    R.map(dataFeedback =>
                                        <div className="message-wrapper">
                                            <div className="message">
                                                <span>{dataFeedback.feedbacktext === undefined ? "No record found" : dataFeedback.feedbacktext}
                                                </span>
                                            </div>
                                            <div className="message-meta">
                                                <span className="timestamp"> {dataFeedback.feedbacktext === undefined ? "" : dataFeedback.createdby.concat(' - ').concat(moment(dataFeedback.createddate).format('MM/DD/YYYY'))}</span>
                                            </div>
                                        </div>
                                        , feedbackdata)
                                }
                            </div>
                        </div> : null}

                {isfeedback ?
                    <div className="card fixed">
                        <div className="card-header">
                            <span>Client Feedback</span>
                            <img alt='History' src={imghistory} onClick={e => { this.showhideHistory(e)(storeVisitId); }} className='img-responsive' title="History"></img>
                        </div>
                        <div className="form-floating">
                            <textarea className="form-control" placeholder="Leave a comment here" id="svrfeedbackTextarea" onChange={e => this.handleChangedataFeedback(e)} rows="5" required></textarea>
                        </div>
                        <div className="card-footer">
                            <div className="action">
                                <button id="btncancelfeedback" title="Cancel" type="button" onClick={e => { this.handleCancelFeedback(e); }} className="btn btn-cancel">Cancel</button>&nbsp;
                                <button id="btnsavefeedback" title="Save" type="button" onClick={e => { this.handleSaveFeedback(e); }} className="btn btn-save">Save</button>
                            </div>
                        </div>
                    </div>
                    : null
                }

                <div className="sticky-icon"> <p className="feedback">
                    <img src={imgcomment} onClick={e => { this.showhideFeedback(e)(storeVisitId); }} className='img-popup' alt='comment'></img>
                    <span onClick={e => { this.showhideFeedback(e)(storeVisitId); }} className="feedback-text">Send Feedback</span> </p>
                </div>
            </div>
        );
    }
}


export default ClientFeedbackModal;