import React, { Component } from 'react';
import DateRangePicker      from 'react-daterange-picker';

import 'react-daterange-picker/dist/css/react-calendar.css';

class SearchDatePicker extends Component {
  render () {
    return (
      <div style={this.props.style}>
        <DateRangePicker { ...this.props } singleDateRange={ true } />
      </div>
    );
  }
}

export default SearchDatePicker;
