import * as R from 'ramda';

import { hasLocalStorage } from '../../utils/featureDetect';

import { LOGIN, LOGOUT } from './AuthActions';

export const STATE_KEY = 'auth';

const initialState = {
  id              : null,
  email           : null,
  token           : null,
  refreshToken    : null,
  isAuthenticated : false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_SUCCESS` : {
      return {
        ...state,
        id              : R.path(['payload', 'data', 'data', 'id'],         action),
        email           : R.path(['payload', 'data', 'data', 'email'],      action),
        roleId          : R.path(['payload', 'data', 'data', 'roleId'],     action),
        roleName        : R.path(['payload', 'data', 'data', 'roleName'],   action),
        token           : R.path(['payload', 'headers', 'x-auth-token'],    action),
        refreshToken    : R.path(['payload', 'headers', 'x-refresh-token'], action),
        isAuthenticated : true,        
        name            : R.path(['payload', 'data', 'data', 'name'],   action)
      };
    }
    case LOGOUT : {
      if (hasLocalStorage) {
        window.localStorage.clear();
      }
      window.location.href = 'http://winstonretail.com/';
      break;
    }
    default : {
      return state;
    }
  }
};

export const getToken           = R.path([STATE_KEY, 'token']);
export const getEmail           = R.path([STATE_KEY, 'email']);
export const getIsAuthenticated = R.path([STATE_KEY, 'isAuthenticated']);
export const getUserId          = R.path([STATE_KEY, 'id']);
export const getUserRole        = R.path([STATE_KEY, 'roleId']);

export default AuthReducer;
