import React       from 'react';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';

import SvrPreviewToolbar from '../components/SvrPreviewToolbar';
import SvrPreview        from '../components/preview/SvrPreview';
import * as R from 'ramda';

const getSecDocHost = (parmUrl) => {
  if (document.URL !== undefined && document.URL !== null && document.URL !== "") {
    if (parmUrl.includes("wrsprodbucket.s3")) {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')) {
        const tempdocsechost = "wrsdts.wptools.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) {
        const tempdocsechost = "wrsdts.mstage.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes("http://mapps.mstage.wintellplus.test")) {
        const tempdocsechost = "wrsdts.mstage.wintellplus.com";
        return tempdocsechost;
      } else {
        return null;
      }
    } else if (parmUrl.includes("wrsprodsecbucket.s3")) {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')) {
        const tempdocsechost = "wrssfdc.wptools.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) {
        const tempdocsechost = "wrssfdc.mstage.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes("http://mapps.mstage.wintellplus.test")) {
        const tempdocsechost = "wrssfdc.mstage.wintellplus.com";
        return tempdocsechost;
      }
      else {
        return null;
      }
    }
  } else {
    return null;
  }
}


export const makeDecoratedImageUrl = url => {
  if (R.isNil(url) || R.isEmpty(url)) {
    return url;
  }
  const tempdocsechost = getSecDocHost(url);

  if (url.includes("wrsprodbucket.s3.amazonaws.com")) {
    const tempdocsecurls3 = url.replace("https://wrsprodbucket.s3.amazonaws.com", "");
    const tempdocsecurls3Clone = "https://" + tempdocsechost + tempdocsecurls3; 
    if (R.isNil(tempdocsecurls3Clone)) {
      return url;
    } else {
      return tempdocsecurls3Clone;
    }
  }
  else if (url.includes("wrsprodsecbucket.s3.amazonaws.com")) {
    const tempdocsecurls3 = url.replace("https://wrsprodsecbucket.s3.amazonaws.com", "");
    const tempdocsecurls3Clone = "https://" + tempdocsechost + tempdocsecurls3; 
    if (R.isNil(tempdocsecurls3Clone)) {
      return url;
    } else {
      return tempdocsecurls3Clone;
    }
  }
  return url; 
};

const SvrPreviewPage = ({
  onExit,
  title,
  heroImageUrl,
  preSections,
  duringSections,
  postSections,
  isUuid
}) => (
  <div>
    <Helmet>
      <title>Preview SVR</title>
    </Helmet>
    <SvrPreviewToolbar onExit={onExit} />
    <SvrPreview
      preSections={preSections}
      duringSections={duringSections}
      postSections={postSections}
      title={title}
      heroImageUrl={makeDecoratedImageUrl(heroImageUrl)}
      isUuid={isUuid}
    />
  </div>
);

SvrPreviewPage.propTypes = {
  onExit         : PropTypes.func.isRequired,
  title          : PropTypes.string,
  heroImageUrl   : PropTypes.string,
  preSections    : PropTypes.array,
  duringSections : PropTypes.array,
  postSections   : PropTypes.array,
};

SvrPreviewPage.defaultProps = {
  title          : '',
  preSections    : [],
  duringSections : [],
  postSections   : []
};

export default SvrPreviewPage;
