import React          from 'react';
import PropTypes      from 'prop-types';
import moment         from 'moment';
import DeleteIcon     from '@material-ui/icons/Delete';
import * as R         from 'ramda';
import {
  compose,
  withStateHandlers,
  withHandlers
} from 'recompose';

import {
  FileRowWrapper,
  FileRow,
  FileLink,
  FileLinkText,
  FileLinkExtension,
  FileCol,
  FileQuestionRelated,
  ButtonDeleteDocument
} from '../styled-components/DocumentItemRow';

const bytesToSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const renderQuestionRelatedColumn = (id, questionDocumentIds) => {
  const questionRelated = R.find(R.propEq('id', id), questionDocumentIds);
  let questionText = '';

  if (questionRelated) {
    questionText = `${questionRelated.sectionGroup.toUpperCase()}: ${questionRelated.question.title}`;
  }

  return (
    <FileCol width="250px" flex1>
      <FileQuestionRelated>{questionText}</FileQuestionRelated>
      {/* <button onClick={() => openQuestionModal.bind(null, questionRelated.sectionGroup, questionRelated.sectionId, questionRelated.question)}>{questionRelated.questionUuid}</button> */}
    </FileCol>
  );
};

const getSecDocHost = (parmUrl) => {
  if (document.URL !== undefined && document.URL !== null && document.URL !== "") {
    if ( parmUrl.includes("wrsprodbucket.s3") )
    {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com') ) 
        {
          const tempdocsechost =  "wrsdts.wptools.wintellplus.com";
          return tempdocsechost;
        } 
        else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) 
        {
          const tempdocsechost ="wrsdts.mstage.wintellplus.com";
          return tempdocsechost;
        } else {
          return null;
        }
    } else if (parmUrl.includes("wrsprodsecbucket.s3")) {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com') ) 
        {
          const tempdocsechost ="wrssfdc.wptools.wintellplus.com";
          return tempdocsechost;
        } 
        else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) 
        {
          const tempdocsechost ="wrssfdc.mstage.wintellplus.com";
          return tempdocsechost;
        } else {
          return null;
        }
    }
  } else 
  {
    return null;
  }
}

const getSecDocUrl = (parmUrl) => {
  if ( parmUrl ) {
    const tempdocsechost = getSecDocHost(parmUrl);
    //typeof parmUrl === "string"
    if ( parmUrl.includes("wrsprodbucket.s3.us-west-2.amazonaws.com") ) {
      const tempdocsecurl = R.replace("wrsprodbucket.s3.us-west-2.amazonaws.com", tempdocsechost, parmUrl);
      //console.log("tempdocsecurl:", tempdocsecurl);
      if (R.isNil(tempdocsecurl) )
      {
        return parmUrl;
      } else {
        return tempdocsecurl;
      }
    } else if ( parmUrl.includes("wrsprodbucket.s3.amazonaws.com") ) {
      const tempdocsecurls3 = R.replace("wrsprodbucket.s3.amazonaws.com", tempdocsechost, parmUrl);
      if (R.isNil(tempdocsecurls3) )
      {
        return parmUrl;
      } else {
        return tempdocsecurls3;
      }
    } else if ( parmUrl.includes("wrsprodsecbucket.s3.amazonaws.com") ) {
      const tempdocsecurls3sfdc = R.replace("wrsprodsecbucket.s3.amazonaws.com", tempdocsechost , parmUrl);
      if (R.isNil(tempdocsecurls3sfdc) )
      {
        return parmUrl;
      } else {
        return tempdocsecurls3sfdc;
      }
    }
    else {
      return parmUrl;
    }
  }
}


const DocumentItem = ({
  id,
  title,
  size,
  url,
  created,
  deleting,
  _deleteDocument,
  questionDocumentIds,
}) => (
  <div>
    <FileRowWrapper>
      <FileRow>
        <FileCol width="380px">
          <FileLink title={title} href={getSecDocUrl(url)} target='_blank'><FileLinkText>{title.split('.')[0]}</FileLinkText><FileLinkExtension>.{title.split('.').pop()}</FileLinkExtension></FileLink>
        </FileCol>
        <FileCol width="110px">
          <span>{bytesToSize(size)}</span>
        </FileCol>
        <FileCol width="155px">
          <span>{moment(created).format('MM/DD/YYYY')}</span>
        </FileCol>
        { renderQuestionRelatedColumn(id, questionDocumentIds) }

        <FileCol last>
          <ButtonDeleteDocument onClick={() => _deleteDocument(id)} title="Delete">
            {
              deleting ? (
                <span>Deleting...</span>
              ) : (
                <DeleteIcon />
              )
            }
          </ButtonDeleteDocument>
        </FileCol>
      </FileRow>
    </FileRowWrapper>
  </div>
);

DocumentItem.propTypes = {
  id                   : PropTypes.number,
  options              : PropTypes.array,
  title                : PropTypes.string.isRequired,
  size                 : PropTypes.number.isRequired,
  created              : PropTypes.string.isRequired,
  children             : PropTypes.node,
  documentUuid         : PropTypes.string,
  deleteDocument       : PropTypes.func.isRequired,
  questionDocumentIds  : PropTypes.array,
  openQuestionModal    : PropTypes.func,
};

DocumentItem.defaultProps = {
  options              : [],
  children             : null,
  documentUuid         : '',
  questionDocumentIds  : [],
  openQuestionModal    : () => {},
};

const addEditing = compose(
  withStateHandlers(
    ({
      deleting = false,
    }) => ({
      deleting
    }),
    {
      setDeleting : () => () => ({
        deleting : true,
      })
    }
  ),
  withHandlers({
    _deleteDocument : props => id => {
      if (!window.confirm('Are you sure?')) return false;
      Promise.resolve(props.setDeleting())
        .then(() => props.deleteDocument(id));
    }
  })
);



export default addEditing(DocumentItem);
