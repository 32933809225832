import * as R    from 'ramda';
import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import Footer from './Footer';

const Modal = ({ children, onSave, onCancel, onDelete, showWhen, canSave }) => (
  <Modal.Wrapper showWhen={showWhen}>
    <Modal.Container>
      <Modal.Package>
        {children}
      </Modal.Package>
      <Footer
        onSave   = {onSave}
        onCancel = {onCancel}
        onDelete = {onDelete}
        canSave  = {canSave}
      />
    </Modal.Container>
  </Modal.Wrapper>
);

Modal.propTypes = {
  withoutCancel : PropTypes.bool,
  children      : PropTypes.node.isRequired,
  onSave        : PropTypes.func,
  onDelete      : PropTypes.func,
  onCancel      : PropTypes.func.isRequired,
  showWhen      : PropTypes.bool.isRequired,
  canSave       : PropTypes.func.isRequired
};

Modal.defaultProps = {
  withoutCancel : false,
  canSave       : R.T,
  onSave        : () => {}
};

Modal.Wrapper = styled.div`
  visibility : ${({ showWhen }) => showWhen ? 'visible' : 'hidden'};
  top        : 0;
  bottom     : 0;
  left       : 0;
  width      : 100%;
  height     : 100%;
  position   : fixed;
  background : rgb(0, 0, 0, 0.3);
  z-index    : 999;
`;

Modal.Container = styled.div`
  position       : relative;
  width          : 500px;
  min-height     : 300px;
  background     : #fff;
  margin         : 5px auto;
  border-radius  : 3px;
  box-shadow     : 0 2px 4px rgba(0, 0, 0, .2);
  top            : 30%;
  padding-bottom : 20px;
`;

Modal.Package = styled.div`
  display        : flex;
  flex-direction : column;
  height         : 80%;
  padding        : 30px 40px;
  text-indent    : 5px;
  font-weight    : 300;
  font-size      : 16px;
`;

export default Modal;
