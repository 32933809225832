import * as R from 'ramda';

const recursivelyPluck = k => v => {
  if (v[k]) {
    return R.map(recursivelyPluck(k), v[k]);
  }
  if (v['options']) {
    return [v, R.map(recursivelyPluck('conditionalFork'), v['options'])];
  }
  return v;
};

export default recursivelyPluck;
