import * as R    from 'ramda';
import React     from 'react';
import PropTypes from 'prop-types';
import styled    from 'styled-components';

import ConditionalQuestionIcon from '@material-ui/icons/Copyright';
import InternalQuestionIcon from '@material-ui/icons/Info';
import { Checkbox, Radio }  from '@material-ui/core';

import { ITEM_TYPES, ITEM_TYPES_WITH_CONDITION } from '../../svr/BuilderReducer';

const Wrapper = styled.div`
  margin        : 0 0 0 .5rem;
  padding       : .5rem;
  display       : flex;
  align-items   : center;
  color         : #909091;
  font-size     : .875rem;
  font-weight   : 500;
  line-height   : 1.2em;
  width         : 100%;
`;

const AnswerTitle = styled.div`
  margin         : 0 0 0 ${props => props.level * 1 - .5}rem;
  padding        : .5rem 0;
  font-size      : .875rem;
  font-style     : oblique;
  font-weight    : 500;
  letter-spacing : 1px;
  color          : #767676;
  display        : flex;
  align-items    : center;
  border-bottom  : 2px solid #EEEEEE;
`;

const QuestionAnswerRow = styled.div`
  display       : flex;
  align-items   : center;
  border-bottom : 2px solid #EEEEEE;
  margin        : 0 0 0 ${props => props.level * 1 - .5}rem;

  p {
    width     : 45%;
    margin    : 0;
    font-size : .875rem;
  }
`;

const isPhotoQuestion = R.propEq('type', ITEM_TYPES.PHOTO);

const isVideoQuestion = R.propEq('type', ITEM_TYPES.VIDEO);

const isNumericQuestion = R.propEq('type', ITEM_TYPES.NUMERIC_INT);

const isMultipleChoiceQuestion = R.compose(
  R.flip(R.contains)(ITEM_TYPES_WITH_CONDITION),
  R.prop('type')
);

const QuestionItem = (
  {
    answerTitle,
    level,
    options,
    title,
    isInternalQuestion,
    handleSelectQuestion,
    selectedQuestions,
    question,
    onlyOne,
    onlyPhotos,
    onlyVideos,
    onlyNumeric,
    onlyMultipleChoice,
    filteredQuestions,
    filterSelectedValue,
    isConditional,
    isClientUser
  }) => {
    if(isClientUser && R.pathEq(['meta', 'isHidden'], true, question)) {
      return null;
    }
    const ControlComponent = onlyOne ? Radio : Checkbox;
    const Control = <ControlComponent
                      checked = {R.contains(question.canonicalKey, selectedQuestions)}
                      onClick = {event => handleSelectQuestion(event, question.canonicalKey)}
                    />;

    const maybeShowControl = () => {

      if (onlyPhotos && filterSelectedValue !== 'all') {
        return R.contains(question.canonicalKey, filteredQuestions) && isPhotoQuestion(question) ? Control : null;
      }

      if (onlyPhotos) {
        return isPhotoQuestion(question) ? Control : null;
      }

      if (onlyVideos && filterSelectedValue !== 'all') {
        return R.contains(question.canonicalKey, filteredQuestions) && isVideoQuestion(question) ? Control : null;
      }

      if (onlyVideos) {
        return isVideoQuestion(question) ? Control : null;
      }

      if (onlyNumeric) {
        return isNumericQuestion(question) ? Control : null;
      }

      if (onlyMultipleChoice) {
        return isMultipleChoiceQuestion(question) ? Control : null;
      }

      return Control;
    };

    return (
      <div>
        {answerTitle && <AnswerTitle level={level}>{`If "${answerTitle}"`}</AnswerTitle>}
        <QuestionAnswerRow level={level}>
          { maybeShowControl() }
          <Wrapper>
            {title}
            {isConditional      && <ConditionalQuestionIcon />}
            {isInternalQuestion && <InternalQuestionIcon />}
          </Wrapper>
        </QuestionAnswerRow>
        {R.flatten(options.map(option => option.conditionalFork.map((forkedQuestion, i) => (
          <QuestionItem
            level                = {R.inc(level)}
            key                  = {forkedQuestion.uuid}
            title                = {forkedQuestion.title}
            conditionalItems     = {forkedQuestion.options}
            options              = {forkedQuestion.options}
            answerTitle          = {i === 0 ? option.label : ''}
            handleSelectQuestion = {handleSelectQuestion}
            selectedQuestions    = {selectedQuestions}
            question             = {forkedQuestion}
            onlyOne              = {onlyOne}
            onlyPhotos           = {onlyPhotos}
            onlyVideos           = {onlyVideos}
            onlyNumeric          = {onlyNumeric}
            onlyMultipleChoice   = {onlyMultipleChoice}
            filteredQuestions    = {filteredQuestions}
            filterSelectedValue  = {filterSelectedValue}
            isInternalQuestion   = {forkedQuestion.meta.isHidden}
            isConditional        = {forkedQuestion.meta.isConditional}
            isClientUser         = {isClientUser}
          />
        ))))}
      </div>
    );
  };

QuestionItem.propTypes = {
  handleSelectQuestion : PropTypes.func.isRequired,
  selectedQuestions    : PropTypes.array,
  question             : PropTypes.object,
  answerTitle          : PropTypes.string,
  level                : PropTypes.number,
  options              : PropTypes.array,
  title                : PropTypes.string,
  isConditional        : PropTypes.bool,
  isInternalQuestion   : PropTypes.bool,
  onlyOne              : PropTypes.bool,
  onlyPhotos           : PropTypes.bool,
  onlyVideos           : PropTypes.bool,
  onlyNumeric          : PropTypes.bool,
  onlyMultipleChoice   : PropTypes.bool,
  isClientUser         : PropTypes.bool
};

QuestionItem.defaultProps = {
  answerTitle        : '',
  level              : 1,
  options            : [],
  title              : '',
  isConditional      : false,
  isInternalQuestion : false,
  selectedQuestions  : [],
  question           : {},
  onlyOne            : false,
  onlyPhotos         : false,
  onlyVideos         : false,
  onlyNumeric        : false,
  onlyMultipleChoice : false,
  isClientUser       : false
};

export default QuestionItem;
