import * as R      from 'ramda';
import React       from 'react';
import { connect } from 'react-redux';
import PropTypes   from 'prop-types';
import { Helmet }  from 'react-helmet';

import {
  compose,
  defaultProps,
  setPropTypes,
  lifecycle,
  withStateHandlers,
  withHandlers
} from 'recompose';

import NotificationsTable       from '../components/NotificationsTable';
import NotificationsPageToolbar from '../components/NotificationsPageToolbar';
import SearchFilter            from '../../../components/SearchFilter';

import SendNotificationModal from '../components/modals/SendNotificationModal';

import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../client/ClientActions';

import {
  createNotification  as _createNotification
} from '../NotificationsActions';

import {
  fetchSvrResponses   as _fetchSvrResponses,
  updateSvrResponse   as _updateSvrResponse,
  resetSvrResponse    as _resetSvrResponse,
  deleteSvrResponse   as _deleteSvrResponse,
  filterByAction      as _filterByAction,
  transformData       as _transformData,
  clearSvrResponses   as _clearSvrResponses,
  setShowSearchFilter as _setShowSearchFilter
} from '../../svr-responses/SvrResponsesActions';

import {
  getCurrentClient,
  getCurrentProject,
  isClientUser,
  getPrefilledFilters,
  getAutoComplete
} from '../../client/ClientReducer';

import {
  getSvrResponses,
  getTransformedData,
  getTableColumns,
  getFilterList,
  getShowSearchFilter
} from '../../svr-responses/SvrResponsesReducer';

import {
  getSavedViews
} from '../../saved-views/SavedViewsReducer';

import {
  getUserRole,
  getUserId,
  getEmail
}                               from '../../auth/AuthReducer';
import { deriveTenancyFilters } from '../../../utils/deriveTenancyFilters';
import { Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core';
import moment    from 'moment';

const NAMESPACE = 'svrResponses';
const DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER = 7;

const searchFilterStyle = {
  minHeight       : '10rem',
  margin          : '2rem 2rem 1rem 2rem',
  position        : 'relative',
  backgroundColor : '#FFF',
  boxShadow       : '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
};

const NotificationsPage = ({
  svrResponses,
  prefilledFilters,
  fetchAutocomplete,
  clearAutocomplete,
  autoComplete,
  transformedData,
  transformedDataDict,
  tableColumns,
  filterList,
  goToReviewPage,
  updateSvrResponse,
  resetSvrResponse,
  deleteSvrResponse,
  maybeFilterByAction,
  showSearchFilter,
  setShowSearchFilter,
  transformData,
  userRole,
  userId,
  currentClient,
  currentProject,
  savedView,
  title,
  description,
  createSavedView,
  createSavedWidget,
  createSavedRollup,
  namespace,
  userEmail,
  deleteSavedView,
  isClientUser,
  handleSelectData,
  selectedData,
  isFetchingData,
  feedbackMessage,
  showSendNotificationModal,
  setShowNotificationModal,
  handleNotificationAnswerChanged,
  notificationValue,
  createNotification,
  syncFilters
}) => (
  <div>
    <Helmet>
      <title>Notifications</title>
    </Helmet>
    <SearchFilter
      data            = { svrResponses }
      prefilledFilters= { prefilledFilters }
      fetchAutocomplete= {fetchAutocomplete}
      clearAutocomplete= {clearAutocomplete}
      autoComplete     = {autoComplete}
      transformedData = { transformedData }
      tableColumns    = { R.filter(R.prop('searchable'), tableColumns) }
      filterByAction  = { maybeFilterByAction }
      filterList      = { filterList }
      syncFilters     = { syncFilters }
      namespace       = { namespace }
      style           = { searchFilterStyle }
      userRole        = { userRole }
      userId          = { userId }
      currentClient   = { currentClient }
      currentProject  = { currentProject }
      isClientUser    = { isClientUser() }
      allowSave       = { true }
      showWhen        = { showSearchFilter }
      onSaveAsView    = { createSavedView }
      onSaveAsWidget  = { createSavedWidget }
      onSaveAsRollup  = { createSavedRollup }
    />
    <NotificationsPageToolbar
      selectedData = { selectedData }
      onSend     = { () => setShowNotificationModal(!showSendNotificationModal)}
    />
    <NotificationsTable
      transformedData     = { transformedData }
      transformedDataDict = { transformedDataDict }
      data                = { svrResponses }
      title               = { title }
      description         = { description }
      tableColumns        = { tableColumns }
      savedView           = { savedView }
      deleteSavedView     = { deleteSavedView }
      onUpdate            = { updateSvrResponse }
      onReset             = { resetSvrResponse}
      onHardDelete        = { deleteSvrResponse }
      transformData       = { transformData }
      userRole            = { userRole }
      filterList          = { filterList }
      goToReviewPage      = { goToReviewPage }
      setShowSearchFilter = { setShowSearchFilter }
      showSearchFilter    = { showSearchFilter }
      userEmail           = { userEmail }
      isClientUser        = { isClientUser() }
      handleSelectData    = { handleSelectData }
      namespace           = { namespace }
    />

    <Dialog
      open    = {isFetchingData}
      fullWidth = {true}
    >
      <DialogTitle>{feedbackMessage}</DialogTitle>
      <DialogContent style={{ justifyContent : 'space-between' }}>
        <Typography>Please wait</Typography>
      </DialogContent>
    </Dialog>

    {
      showSendNotificationModal && (
        <SendNotificationModal
          onSend  ={notifData => createNotification(notifData)}
          onCancel={() => setShowNotificationModal(false)}
          notificationValue={notificationValue}
          onHandleNotificationAnswerChanged={handleNotificationAnswerChanged}
          selectedData = { selectedData }
        />
      )
    }
  </div>
);

const manageSvrResponsesFilterList = () => {
  
  // return {
  //   'Review State': {
  //     pathStr: 'metaJson.reviewState',
  //     path: ['metaJson', 'reviewState'],
  //     label: 'Review State',
  //     decorator: v => window.WINSTON.statuses.reviewState[v || 0],
  //     data: R.compose(
  //       values => R.filter(v => R.equals('Pending Review', v) || R.equals('Resubmitted', v), values),
  //       R.values
  //     )(window.WINSTON.statuses.reviewState)
  //   }
  // };

  var todayDate = new Date().toISOString().slice(0, 10);
  todayDate = todayDate.concat(" 23:59:59");  

  const now = moment();
  const pastdate = moment(now).subtract(DEFAULT_NUMBER_OF_DAYS_REPORT_FILTER, 'days').format("YYYY-MM-DD");
  
  var visitArray = [];
  var visitArrayClone = [];
  visitArray.push([pastdate, todayDate]);
  visitArrayClone.push(visitArray);

  return {
    'Visit Date': {
      pathStr: 'expectedCheckinTime',
      path: ['expectedCheckinTime'],
      label: 'Visit Date',
      //decorator: v => window.WINSTON.statuses.reviewState[v || 0],
      decorator: R.path(['WINSTON', 'tableColumns', 'storeVisit', "expectedCheckinTime", 'decorator'])(window),
      data: visitArrayClone
    }
  };
};


const maybeLoadInitialFilterListFromRouterProps = (state, props) => {

  let storedFilters = {};
  try {
    storedFilters = R.compose(
      maybeAppendDecorators,
      appendFilterType,
      JSON.parse
    )(window.localStorage.getItem(`persistedFilters:${props.namespace}`));
  } catch (e) {
    storedFilters = {};
  }

  var objFilterSize = Object.keys(storedFilters).length;
  if (objFilterSize === 0 && !isClientUser()) {
      return R.compose(
        maybeAppendDecorators,
        appendFilterType,
        R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
        R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
        R.when(R.isEmpty, R.always(props.initialFilterList || {})),
        manageSvrResponsesFilterList,
        R.mergeDeepLeft(storedFilters),
        getFilterList(props.namespace)
      )(state);
    }
    else if(objFilterSize === 1 && storedFilters.hasOwnProperty('Client Name') && !isClientUser())
    {
      return R.compose(
        maybeAppendDecorators,
        appendFilterType,
        R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
        R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
        R.when(R.isEmpty, R.always(props.initialFilterList || {})),
        manageSvrResponsesFilterList,
        R.mergeDeepLeft(storedFilters),
        getFilterList(props.namespace)
      )(state);
    }
    else
    {
      return R.compose(
        maybeAppendDecorators,
        appendFilterType,
        R.mergeDeepLeft(deriveTenancyFilters(getCurrentClient(state), getCurrentProject(state))),
        R.mergeDeepRight(props.filterList || R.pathOr({}, ['savedView', 'filters'])(props)),
        R.when(R.isEmpty, R.always(props.initialFilterList || {})),
        R.mergeDeepLeft(storedFilters),
        getFilterList(props.namespace)
      )(state);
    }  
};

const maybeDispatchFetchSvrResponses = (dispatch, namespace, filters) => {
  const activeFilters = R.filter(R.compose(R.not, R.isEmpty, R.prop('data')))(filters);
  if(Object.keys(activeFilters).length) {
    return dispatch(_fetchSvrResponses(namespace, filters));
  }
  dispatch(_clearSvrResponses(namespace));
  return Promise.resolve();
};

function _goToReviewPage(props, id) {
  return (dispatch, getState) => {
    window.localStorage.setItem("displayUnansweredSections", 'NO');
    if (!R.isNil(window.localStorage.getItem('dashboardId')) && !R.isEmpty(window.localStorage.getItem('dashboardId'))) {
      window.localStorage.removeItem('dashboardId')
    }
    const state = getState();
    const currentIds = R.compose(R.map(d => { return { id: d.id }; } ), R.pathOr([], [NAMESPACE, props.namespace, 'transformedData']))(state);
    props.history.push(`/svr-response/${id}`, { ids: currentIds });
  };
}

const mapStateToProps = (state, props) => ({
  svrResponses        : getSvrResponses(state, props.namespace || NAMESPACE),
  transformedData     : getTransformedData(state, props.namespace || NAMESPACE),
  transformedDataDict : R.indexBy(R.prop('id'),  getTransformedData(state, props.namespace || NAMESPACE) || []),
  tableColumns        : getTableColumns(props.namespace || NAMESPACE)(state),
  userRole            : getUserRole(state),
  userId              : getUserId(state),
  userEmail           : getEmail(state),
  currentClient       : getCurrentClient(state),
  currentProject      : getCurrentProject(state),
  showSearchFilter    : getShowSearchFilter(state),
  savedViews          : getSavedViews(state),
  filterList          : maybeLoadInitialFilterListFromRouterProps(state, props),
  prefilledFilters    : getPrefilledFilters(state),
  autoComplete        : getAutoComplete(state),
  isClientUser
});

const mapDispatchToProps = (dispatch, props) => ({
  dispatch,
  fetchSvrResponses   : (namespace, filters) => dispatch(_fetchSvrResponses(namespace, filters)),
  updateSvrResponse   : (namespace, svrResponseId, payload) => dispatch(_updateSvrResponse(namespace, svrResponseId, payload)),
  resetSvrResponse    : (namespace, svrResponseId) => dispatch(_resetSvrResponse(namespace, svrResponseId)),
  deleteSvrResponse   : id => dispatch(_deleteSvrResponse(id)),
  goToReviewPage      : id => dispatch(_goToReviewPage(props, id)),
  persistFilters      : (namespace, filters) => dispatch(_filterByAction(namespace, filters)),
  setShowSearchFilter : bool => dispatch(_setShowSearchFilter(bool)),
  transformData       : (orderBy, order, sortType, filterList, data, namespace, tableColumns) => dispatch(_transformData(orderBy, order, sortType, filterList, data, namespace, tableColumns)),
  fetchAutocomplete   : payload => dispatch(_fetchAutocomplete(payload)),
  clearAutocomplete   : () => dispatch(_clearAutocomplete())
});

const maybeAppendDecorators = R.map((filter={}) => {
  const decorator = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'decorator'])(window);
  if (R.is(Function, decorator)) {
    return R.assoc('decorator', decorator, filter);
  } else {
    return filter;
  }
});

const appendFilterType = R.map((filter={}) => {
  const type = R.path(['WINSTON', 'tableColumns', 'storeVisit', filter.pathStr, 'type'])(window);
  return R.assoc('type', type, filter);
});

const withSvrResponses = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
      selectedData = [],
      isFetchingData = false,
      feedbackMessage = ""
    }) => ({
      selectedData,
      isFetchingData,
      feedbackMessage
    }),
    ({
      handleSelectData: (__, { transformedData }) => selectedData => {
        if (selectedData.length > 0) {
          return {
            selectedData: R.compose(
              R.filter(d => R.contains(d.eventId, selectedData))
            )(transformedData)
          };
        }

        return { selectedData };
      },
      setFetchingData : () => (fetching, message) => ({
        isFetchingData: fetching,
        feedbackMessage: R.defaultTo("", message)
      }),
      setShowNotificationModal: () => show => ({
        showSendNotificationModal: show
      }),
      handleNotificationAnswerChanged: () => e => ({
        notificationValue: e.target.value
      })
    }),
  ),
  withHandlers({
    filterByAction : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true, 'Getting visits'))
      .then(() => props.dispatch(_fetchSvrResponses(namespace, filters)))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    maybeFilterByAction : props => (namespace, filters) => {
      Promise.resolve(props.setFetchingData(true, 'Getting visits'))
      .then(() => maybeDispatchFetchSvrResponses(props.dispatch, namespace, filters))
      .then(() => props.dispatch(_filterByAction(namespace, filters)))
      .then(() => props.setFetchingData(false));
    },
    syncFilters : props => (namespace, filters) => props.dispatch(_filterByAction(namespace, filters))
    ,
    createNotification  : props => payload => {
      payload = R.compose(
        R.assoc('users', R.__, payload),
        R.uniq,
        R.pluck('metaMcid'),
        R.prop('selectedData'),
      )(props);

      Promise.resolve(props.setShowNotificationModal(false))
      .then(() => props.setFetchingData(true, 'Creating notification'))
      .then(() => props.dispatch(_createNotification(payload)))
      .then(() => props.setShowNotificationModal(false))
      .then(() => props.setFetchingData(false));
    }
  }),
  defaultProps({
    svrResponses              : [],
    savedView                 : {},
    savedViews                : [],
    transformedData           : [],
    tableColumns              : [],
    showSearchFilter          : false,
    initialFilterList         : {},
    namespace                 : NAMESPACE,
    isClientUser              : () => {},
    showSendNotificationModal : false,
    notificationValue         : -1
  }),
  setPropTypes({
    svrResponses                  : PropTypes.array,
    savedViews                    : PropTypes.array,
    savedView                     : PropTypes.object,
    transformedData               : PropTypes.array,
    title                         : PropTypes.string,
    description                   : PropTypes.string,
    tableColumns                  : PropTypes.array,
    updateSvrResponse             : PropTypes.func.isRequired,
    resetSvrResponse              : PropTypes.func.isRequired,
    deleteSvrResponse             : PropTypes.func.isRequired,
    createSavedView               : PropTypes.func,
    createSavedWidget             : PropTypes.func,
    createSavedRollup             : PropTypes.func,
    showSearchFiler               : PropTypes.bool,
    setShowSearchFilter           : PropTypes.func,
    filterList                    : PropTypes.object.isRequired,
    initialFilterList             : PropTypes.object,
    userRole                      : PropTypes.number.isRequired,
    userId                        : PropTypes.number.isRequired,
    isClientUser                  : PropTypes.func,
    showSendNotificationModal     : PropTypes.bool,
    notificationValue             : PropTypes.number
  }),
  lifecycle({
    componentDidMount() {
      this.props.setShowSearchFilter(true);
    },
    componentDidUpdate(prevProps) {
      const savedViewUpdated         = !R.equals(prevProps.savedView,         this.props.savedView),
            filterListUpdated        = !R.equals(prevProps.filterList,        this.props.filterList),
            initialFilterListUpdated = !R.equals(prevProps.initialFilterList, this.props.initialFilterList);

      const filters = this.props.filterList || {};

      if (savedViewUpdated || initialFilterListUpdated) {
        this.props.maybeFilterByAction(this.props.namespace, filters);
      }

      else if (filterListUpdated) {
        this.props.persistFilters(this.props.namespace, filters);
      }
    }
  })
);

export default withSvrResponses(NotificationsPage);
