import * as R      from 'ramda';
import { connect } from 'react-redux';
import React       from 'react';
import PropTypes   from 'prop-types';
import moment      from 'moment';

import {
  compose,
  lifecycle,
  setPropTypes,
  withProps,
  withStateHandlers,
  withHandlers
} from 'recompose';

import Modal from '@material-ui/core/Modal';

import PhotoWidgetContent      from './PhotoWidgetContent';
import PhotoWidgetModalContent from './PhotoWidgetModalContent';
import PhotoWidgetHeader       from './PhotoWidgetHeader';

import WidgetCreator from './WidgetCreator/WidgetCreator';

import ModalInner from '../ModalInner';

import withUser    from '../../modules/auth';
import withClient  from '../../modules/client';
import { history } from '../../store';

import { isClientUser } from '../../modules/client/ClientReducer';

import { fetchQuestionAnswers as _fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';
import {
  fetchDocuments as _fetchDocuments,
  updateDocument as _updateDocument
} from '../../modules/document/DocumentActions';
import {
  filterByAction    as _filterByAction,
  updateSvrResponse as _updateSvrResponse,
}             from '../../modules/svr-responses/SvrResponsesActions';
import { fetchFormsByIds as _fetchFormsByIds }           from '../../modules/saved-widgets/SavedWidgetsActions';

import {
  mapAnswersToQuestions,
  getFlaggedDocumentUuids,
  getStarredDocumentUuids,
  getStoreVisitIdsContainingFlaggedDocumentUuids,
  getStoreVisitIdsContainingStarredDocumentUuids
} from '../../utils/widgetHelpers';

import filterBlockedPhotos from '../../modules/svr-responses/components/review/utils/filterBlockedPhotos';

import { makeDecoratedImageUrl, getNextRotation } from '../../utils/image';

const maybeShowOnlyStarredOrFlaggedPhotos = ({ starred, flagged }, filteredWidgetData, allUuids) => {
  if (starred) {
    return R.always(getStarredDocumentUuids("Photos")(filteredWidgetData));
  }
  if (flagged) {
    return R.always(getFlaggedDocumentUuids("Photos")(filteredWidgetData));
  }
  return allUuids;
};

const maybeFilterStoreVisitsWithStarredOrFlaggedPhotos = ({ starred, flagged }, filteredWidgetData) => {
  if (starred) {
    const starredStoreVisitIds = getStoreVisitIdsContainingStarredDocumentUuids("Photos")(filteredWidgetData);
    return R.filter(R.compose(R.contains(R.__, starredStoreVisitIds), R.prop('id')))(filteredWidgetData);
  }
  if (flagged) {
    const flaggedStoreVisitIds = getStoreVisitIdsContainingFlaggedDocumentUuids("Photos")(filteredWidgetData);
    return R.filter(R.compose(R.contains(R.__, flaggedStoreVisitIds), R.prop('id')))(filteredWidgetData);
  }
  return filteredWidgetData;
};

const PhotoWidgetContainer = (
  {
    widgetData,
    currentSlide,
    description,
    handleNextSlide,
    handlePreviousSlide,
    currentModalSlide,
    isDetailView,
    widgetContent,
    title,
    toggleDetailView,
    handleNextModalSlide,
    handlePreviousModalSlide,
    handleEditModal,
    isEditFormView,
    onMoveClick,
    onTitleClick,
    isLoading,
    onAction
  }) => (
  <div>
    {isEditFormView && (
      <WidgetCreator
        isOpen              = {isEditFormView}
        toggleWidgetCreator = {handleEditModal}
        namespace           = {R.pathOr('', ['metaJson', 'namespace'], widgetData)}
        savedWidgetFormData = {widgetData}
      />
    )}
      <PhotoWidgetHeader
        title               = {title}
        description         = {description}
        handleNextSlide     = {handleNextSlide}
        handlePreviousSlide = {handlePreviousSlide}
        toggleDetailView    = {toggleDetailView}
        handleEditModal     = {handleEditModal}
        onMoveClick         = {onMoveClick}
        widgetContent       = {widgetContent}
        onTitleClick        = {onTitleClick}
      />
        <div>
          <PhotoWidgetContent
            imgUrl           = {R.pathOr('',   [[currentSlide], 'imgUrl'],       widgetContent)}
            imgUrlAfter      = {R.pathOr('',   [[currentSlide], 'imgUrlAfter'],  widgetContent)}
            imgUrlBefore     = {R.pathOr('',   [[currentSlide], 'imgUrlBefore'], widgetContent)}
            doorNumber       = {R.pathOr(null, [[currentSlide], 'doorNumber'],   widgetContent)}
            location         = {R.pathOr('',   [[currentSlide], 'location'],     widgetContent)}
            retailer         = {R.pathOr('',   [[currentSlide], 'retailer'],     widgetContent)}
            toggleDetailView = {toggleDetailView}
          />
          <div style={{ fontSize : '0.8em', textAlign : 'center', margin : '1.45em' }}>
            {
              widgetContent.length ? (
                <div>
                  <span style={{ fontWeight : 'bold' }}>{R.pathOr('', [[currentSlide], 'retailer'], widgetContent)}: </span>
                  <span>{R.pathOr('', [[currentSlide], 'doorLocation'], widgetContent)} </span>
                  <span>(#{R.pathOr(null, [[currentSlide], 'doorNumber'], widgetContent)}) </span>
                  <span>{R.compose(
                    R.when(
                      R.identity,
                      date => moment(date).format('MM/DD/YYYY')
                    ),
                    R.pathOr('', [[currentSlide], 'expectedCheckinTime'])
                  )(widgetContent)} </span>
                </div>
              ) : (
                <span>{isLoading ? 'Loading ...' : 'Empty Widget'}</span>
              )
            }
          </div>
        </div>
    <Modal
      aria-labelledby  = "photo-widget-modal"
      aria-describedby = "photo-widget-modal-detail"
      open             = {isDetailView}
      onClose          = {toggleDetailView}>
      <ModalInner>
        {
          widgetContent.length ? (
            <PhotoWidgetModalContent
              handlePreviousSlide = {handlePreviousModalSlide}
              handleNextSlide     = {handleNextModalSlide}
              onAction            = {onAction}
              storeVisitId        = {widgetContent[currentModalSlide].storeVisitId}
              imgUrlAfter         = {widgetContent[currentModalSlide].imgUrlAfter}
              imgTitleAfter       = {widgetContent[currentModalSlide].imgTitleAfter}
              imgUuidAfter        = {widgetContent[currentModalSlide].imgUuidAfter}
              statusAfter         = {widgetContent[currentModalSlide].statusAfter}
              imgUrlBefore        = {widgetContent[currentModalSlide].imgUrlBefore}
              imgTitleBefore      = {widgetContent[currentModalSlide].imgTitleBefore}
              imgUuidBefore       = {widgetContent[currentModalSlide].imgUuidBefore}
              statusBefore        = {widgetContent[currentModalSlide].statusBefore}
              imgUrl              = {widgetContent[currentModalSlide].imgUrl}
              imgTitle            = {widgetContent[currentModalSlide].imgTitle}
              imgUuid             = {widgetContent[currentModalSlide].imgUuid}
              doorNumber          = {widgetContent[currentModalSlide].doorNumber}
              location            = {widgetContent[currentModalSlide].doorLocation}
              retailer            = {widgetContent[currentModalSlide].retailer}
              checkinDate         = {widgetContent[currentModalSlide].checkinDate}
              status              = {widgetContent[currentModalSlide].status}
              isClientUser        = {isClientUser()}
            />
          ) : null
        }
      </ModalInner>
    </Modal>
  </div>
);

const mapStateToProps = () => ({
  isClientUser
});

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload, svrresponseids) => dispatch(_fetchQuestionAnswers(namespace, payload, svrresponseids)),
  fetchDocuments       : (namespace, payload) => dispatch(_fetchDocuments(namespace, payload)),
  fetchFormsByIds      : (namespace, payload) => dispatch(_fetchFormsByIds(namespace, payload)),
  filterByAction       : (namespace, filters) => dispatch(_filterByAction(namespace, filters)),
  updateSvrResponse    : (namespace, svrResponseId, payload) => dispatch(_updateSvrResponse(namespace, svrResponseId, payload)),
  updateDocument       : (uuid, payload, namespace) => dispatch(_updateDocument(uuid, payload, namespace))
});

const updatedContent = ({ widgetContent, doc }) => {
  if(!doc) {
    return;
  }
  let newContent = R.clone(widgetContent);
  let index = R.findIndex(R.propEq('imgUuid', doc.uuid), widgetContent);
  if(index > -1) {
    newContent[index].imgUrl = doc.url;
  }

  index = R.findIndex(R.propEq('imgUuidAfter', doc.uuid), widgetContent);
  if(index > -1) {
    newContent[index].imgUrlAfter = doc.url;
  }

  index = R.findIndex(R.propEq('imgUuidBefore', doc.uuid), widgetContent);
  if(index > -1) {
    newContent[index].imgUrlBefore = doc.url;
  }

  return newContent;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withProps(({ widgetData : { description, title, filteredWidgetData, formQuestionCanonicalKeys, filters }}) => ({
    description,
    title,
    filteredWidgetData,
    formQuestionCanonicalKeys,
    filters
  })),
  withStateHandlers(
    ({
      currentSlide      = 0,
      currentModalSlide = 0,
      isDetailView      = false,
      widgetContent     = [],
      isEditFormView    = false,
      isLoading         = false
    }, open = false) => ({
      currentSlide,
      isDetailView,
      open,
      widgetContent,
      currentModalSlide,
      isEditFormView,
      isLoading
    }),
    {
      setIsLoading        : () => loading => ({
        isLoading         : loading
      }),
      handlePreviousSlide : ({ currentSlide, widgetContent }) => () => ({
        currentSlide :
          currentSlide - 1 < 0
            ? widgetContent.length - 1
            : currentSlide - 1
      }),
      handleNextSlide     : ({ currentSlide, widgetContent }) => () => ({
        currentSlide :
          currentSlide + 1 > widgetContent.length - 1
            ? 0
            : currentSlide + 1
      }),
      handlePreviousModalSlide : ({ currentModalSlide, widgetContent }) => () => ({
        currentModalSlide :
          currentModalSlide - 1 < 0
            ? widgetContent.length - 1
            : currentModalSlide - 1
      }),
      handleNextModalSlide     : ({ currentModalSlide, widgetContent }) => () => ({
        currentModalSlide :
          currentModalSlide + 1 > widgetContent.length - 1
            ? 0
            : currentModalSlide + 1
      }),
      toggleDetailView    : ({ isDetailView, currentSlide }) => () => ({
        isDetailView      : !isDetailView,
        currentModalSlide : currentSlide
      }),
      onItemClick         : () => id => {
        history.push('/svr-response/' + id);
      },
      setWidgetContent    : () => widgetContent => ({ widgetContent, isLoading: false }),
      handleEditModal     : ({ isEditFormView }) => () => ({ isEditFormView: !isEditFormView })
    }
  ),
  withHandlers({
    onAction: props => (type, alreadyApplied, payload) => {
      const {
        widgetData: {
          filteredWidgetData,
          metaJson: {
            namespace
        } }
      } = props;

      props.setIsLoading(true);
      if(type === 'rotate') {
        const url = alreadyApplied;
        return props.updateDocument(payload.uuid, {
          url : makeDecoratedImageUrl(getNextRotation(url), 'full')(url)
        }, namespace).then((data) => {
          const doc = R.path(['payload', 'data', 'data'], data);
          props.setWidgetContent(updatedContent({ widgetContent: props.widgetContent, doc }));
          props.setIsLoading(false);
        });
      }

      const sync = alreadyApplied ?
        R.over(R.lensProp(`${type}Photos`), R.reject(R.propEq('uuid', payload.uuid))) :
        R.over(R.lensProp(`${type}Photos`), R.tryCatch(R.append(payload), R.always([payload])));

      const storeVisit = R.find(R.propEq('id', payload.storeVisitId), filteredWidgetData);
      props.updateSvrResponse(
        namespace,
        payload.storeVisitId,
        { metaJson : R.compose(
            JSON.stringify,
            sync,
            R.prop('metaJson')
          )(storeVisit)
        }
      ).then(() => {
        props.setIsLoading(false);
      });
    }
  }),
  setPropTypes({
    description  : PropTypes.string,
    title        : PropTypes.string,
    widgetData   : PropTypes.object.isRequired,
    onTitleClick : PropTypes.func,
    onEditClick  : PropTypes.func
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const {
        widgetData : {
          filteredWidgetData,
          formQuestionCanonicalKeys,
          metaJson
        },
        fetchFormsByIds,
        setWidgetContent,
        fetchQuestionAnswers,
        fetchDocuments,
        isClientUser,
        setIsLoading
      } = this.props;

      const {
        widgetData : {
          filteredWidgetData: nextFilteredWidgetData,
        }
      } = nextProps;

      if(R.equals(filteredWidgetData, nextFilteredWidgetData) || !R.is(Array, nextFilteredWidgetData) || !nextFilteredWidgetData.length) {
        return;
      }

      setIsLoading(true);
      const questions = formQuestionCanonicalKeys || [];
      const sids = R.pluck('id', nextFilteredWidgetData ) || [];

      fetchQuestionAnswers(metaJson.namespace, questions, sids)
        .then(res => {
          let answers       = R.pathOr([], ['payload', 'data', 'data'], res);
          const storeVisits = maybeFilterStoreVisitsWithStarredOrFlaggedPhotos(metaJson, nextFilteredWidgetData);

          answers = R.filter(R.compose(R.contains(R.__, R.pluck('id', storeVisits)), R.prop('storeVisitId')))(answers);
          
          const uuids = R.compose(
            R.without(
              R.compose(
                R.flatten,
                R.filter(R.length),
                R.map(v => filterBlockedPhotos(v, null, true))
              )(storeVisits)
            ),
            R.filter(R.identity),
            maybeShowOnlyStarredOrFlaggedPhotos(
              metaJson,
              nextFilteredWidgetData,
              R.pluck('answerValue')
            )
          )(answers);

          if (uuids.length) {
            fetchDocuments(metaJson.namespace, uuids)
              .then(res => {
                const photos = R.pathOr([], ['payload', 'data', 'data'], res);

                mapAnswersToQuestions(
                  answers,
                  photos,
                  storeVisits,
                  fetchFormsByIds,
                  formQuestionCanonicalKeys,
                  isClientUser(),
                  metaJson.namespace
                )
                  .then(R.filter(R.prop('imgUrl')))
                  .then(R.sort(R.descend(R.prop('checkinDate'))))
                  .then(setWidgetContent);
              });
          } else {
            setIsLoading(false);
          }
        });
    }
  })
)(PhotoWidgetContainer);
