import React from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import moment from 'moment';

import {
  defaultProps,
  compose,
  withStateHandlers,
  withState
} from 'recompose';

import QuickReviewIcon from '@material-ui/icons/Pageview';
import DownloadIcon from '@material-ui/icons/GetApp';

import QuickReviewQuestionsModal from '../../../modals/QuickReviewQuestionsModal';
import ConfirmSvrModal from '../../../modals/ConfirmSvrModal';
import DeletePhotoModal from '../../../modals/DeletePhotoModal';
import ImpossibleEditSvrsModal from '../../../modals/ImpossibleEditSvrsModal';

import AnswerFeedbackModal from './modals/AnswerFeedbackModal';
import SvrFeedbackModal from './modals/SvrFeedbackModal';

import RejectSvrAllModal from './modals/RejectSvrAllModal';
import NeedRevisionAllModal from './modals/NeedRevisionAllModal';
import theme from '../../../../styles/theme';

import PhotoReviewModal from '../photo-review/PhotoReviewModal';
import VideoReviewModal from '../video-review/VideoReviewModal';

import SvrResponseReviewInfo from './SvrResponseReviewInfo';
import SvrResponseReviewFormAnswers from './SvrResponseReviewFormAnswers';
import SvrResponseQuickReviewFormAnswers from './SvrResponseQuickReviewFormAnswers';

import ImageSearchIcon from './icons/ImageSearch';
import LibraryVideoIcon from './icons/LibraryVideo';

import formatData from './utils/formatData';
import filterBlockedPhotos from './utils/filterBlockedPhotos';
import filterBlockedVideos from './utils/filterBlockedVideos';

import getFlattenedQuestions from '../../../saved-rollups/components/utils/getFlattenedQuestions';
import ClientFeedbackModal from './modals/ClientFeedbackModal';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const SVR_RESPONSES_STATUSES = {
  PENDING_SUBMISSION: 0,
  PENDING_REVIEW: 1,
  IN_REVIEW: 2,
  APPROVED: 3,
  REJECTED: 4,
  RESUBMITTED: 5
};

const Wrapper = styled.div`
  background-color : #fff;
  margin-right     : auto;
  margin-left      : auto;
  margin-bottom    : 100px;
  margin-top       : 30px;
  width            : 96%;
  box-shadow       : 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const StyledHeading = styled.div`
  align-items     : center;
  display         : flex;
  justify-content : space-between;
  height          : 65px;
  border-bottom   : 1px solid #D3D3D3;
  padding         : 10px 30px;
  color           : #414042;

  p {
    font-size: 22px;
  }
`;

const Footer = styled.div`
  display          : flex;
  align-items      : center;
  justify-content  : space-between;
  background-color : #F8F8F8;
  color            : ${({ isSelected }) => !isSelected ? '#F3212E' : '#939598'};
  position         : fixed;
  bottom           : 0;
  width            : 100%;
  padding          : 1rem 2rem;
  left             : 0;
  width            : 100vw;
  border-top       : solid 2px rgb(220, 220, 220);
  box-sizing       : border-box;
  z-index          : 2;
`;

const ButtonsContainer = styled.div`
  display : flex;
`;

// const Button = styled.div`
//   color  : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598' };
//   margin : 0 1rem;

//   &:hover {
//     cursor : ${({ isSelected }) => isSelected ? 'pointer' : 'default'};
//   }
// `;

const Button = styled.div`
  color  : ${({ isSelected }) => isSelected ? '#F3212E' : theme.palette.pblack.black  };
  margin : 0 1rem;

  &:hover {
    cursor : ${({ isSelected }) => isSelected ? 'pointer' : 'default'};
  }
`;

const IconsWrapper = styled.div`
  display     : flex;
  align-items : center;

  svg {
    cursor : pointer;
  }
`;

const HeroImage = styled.img`
  width    : 250px;
  height   : auto;
  margin   : 20px auto;
  position : relative;
  top      : 20px;
`;

const getHeadingCopy = R.compose(
  R.join(' | Door #'),
  R.defaultTo([]),
  RA.paths([
    ['metaJson', 'doorLocation'],
    ['metaJson', 'doorNumber']
  ])
);

const hasVisibleVideos = R.curry((isClientUser, formAnswers, svrForm, videos) => {
  const questions = R.compose(
    R.pluck('questionCanonicalKey'),
    R.filter(
      a => R.contains(
        a.answerValue,
        R.compose(
          R.pluck('uuid'),
        )(videos)
      )
    ),
    R.values
  )(formAnswers);

  const flattened = getFlattenedQuestions(questions, [svrForm], isClientUser);
  return R.length(flattened) > 0;
});

const getSecDocHost = (parmUrl) => {
  //debugger;
  if (document.URL !== undefined && document.URL !== null && document.URL !== "") {
    if (parmUrl.includes("wrsprodbucket.s3")) {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')) {
        const tempdocsechost = "wrsdts.wptools.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) {
        const tempdocsechost = "wrsdts.mstage.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes("http://mapps.mstage.wintellplus.test")) {
        const tempdocsechost = "wrsdts.mstage.wintellplus.com";
        return tempdocsechost;
      } else {
        return null;
      }
    } else if (parmUrl.includes("wrsprodsecbucket.s3")) {
      if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')) {
        const tempdocsechost = "wrssfdc.wptools.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) {
        const tempdocsechost = "wrssfdc.mstage.wintellplus.com";
        return tempdocsechost;
      }
      else if (document.URL.includes("http://mapps.mstage.wintellplus.test")) {
        const tempdocsechost = "wrssfdc.mstage.wintellplus.com";
        return tempdocsechost;
      }
      else {
        return null;
      }
    }
  } else {
    return null;
  }
}

// const cloudFrontHost = process.env.REACT_APP_CLOUDFRONT_HOST;
// export const makeDecoratedImageUrl = url => {
//   if(R.isNil(url) || R.isEmpty(url))
//   {
//     return url;
//   }
  
//   if (cloudFrontHost && !( R.isNil(url) && R.isEmpty(url) ) && url.includes('cloudfront.net'))  {
//     return R.compose(
//       R.join('/'),
//       R.prepend(`https://${cloudFrontHost}.cloudfront.net`),
//       R.takeLast(2),
//       R.split('/'),
//       R.defaultTo('')
//     )(url);
//   } else {
//     return url;
//   }
// };

export const makeDecoratedImageUrl = url => {
  if (R.isNil(url) || R.isEmpty(url)) {
    return url;
  }
  const tempdocsechost = getSecDocHost(url);

  if (url.includes("wrsprodbucket.s3.amazonaws.com")) {
    const tempdocsecurls3 = url.replace("https://wrsprodbucket.s3.amazonaws.com", "");
    const tempdocsecurls3Clone = "https://" + tempdocsechost + tempdocsecurls3; 
    if (R.isNil(tempdocsecurls3Clone)) {
      return url;
    } else {
      return tempdocsecurls3Clone;
    }
  }
  else if (url.includes("wrsprodsecbucket.s3.amazonaws.com")) {
    const tempdocsecurls3 = url.replace("https://wrsprodsecbucket.s3.amazonaws.com", "");
    const tempdocsecurls3Clone = "https://" + tempdocsechost + tempdocsecurls3; 
    if (R.isNil(tempdocsecurls3Clone)) {
      return url;
    } else {
      return tempdocsecurls3Clone;
    }
  }
  return url; 
};


const SvrResponseReview = ({
  svrResponse,
  svrForm,
  photos,
  photoQuestions,
  videos,
  videoQuestions,
  signatures,
  updateSvrResponse,
  fetchPhotoQuestions,
  fetchVideoQuestions,
  feedbackMessage,
  onHandleFeedbackChange,
  isOpenFeedbackModal,
  openFeedbackModal,
  closeFeedbackModal,
  openPhotoReviewModal,
  openVideoReviewModal,
  formAnswers,
  onHandleSwitchReviewMode,
  reviewMode,
  onHandleSwitchQuickReviewMode,
  quickReviewMode,
  quickReviewQuestions,
  selectedStatus,
  closeConfirmModal,
  onSaveSvrStatus,
  saveStatusFeedbackModal,
  saveFeedbackModal,
  isOpenAnswerFeedbackModal,
  onOpenAnswerFeedbackModal,
  onCloseAnswerFeedbackModal,
  saveAnswer,
  isAnswerRejected,
  toggleReject,
  isPhotoReviewModalOpen,
  isVideoReviewModalOpen,
  currentQuestion,
  answerValue,
  onHandleAnswerChange,
  isOpenConfirmModal,
  onHandleAnswerMultiChange,
  initialCarouselPhoto,
  setInitialCarouselPhoto,
  initialCarouselVideo,
  setInitialCarouselVideo,
  onThumbnailClick,
  onVideoThumbnailClick,
  toggleRejectPhotoQuestion,
  toggleRejectVideoQuestion,
  isOpenImpossibleEditSvrModal,
  closeImpossibleEditModal,
  isClientUser,
  downloadZip,
  updateDocument,
  isMetaSectionOpen,
  namespace,
  isEditingPhotos,
  setIsEditingPhotos,
  onSwitchPhotos,
  setIsDeletePhotoModalOpen,
  isDeletePhotoModalOpen,
  onDeletePhoto,
  userEmail,
  storeVisitId,
  isOpenRejectallmodal,
  onOpenRejectAllModal,
  onCloseRejectAllModal,
  saveRejectAllModal,
  isOpenNeedrevisionall,
  onCloseNeedrevisionall,
  needrevsaveModal,
  isAnswerRevise,
  toggleRevise
}) => (
  <div id="svr-response-wrapper">
    {svrResponse ? (
      <Wrapper>
        {
          !R.isEmpty(svrForm.heroImageUrl) ? (
            <div style={{ textAlign: 'center' }}>
              <HeroImage src={makeDecoratedImageUrl(svrForm.heroImageUrl)} />
            </div>
          ) : null
        }
        <StyledHeading>
          <p>{getHeadingCopy(svrResponse)}</p>
          <IconsWrapper className='no-print'>
          {
              (R.isNil(window.localStorage.getItem("displayUnansweredSections"))
                || window.localStorage.getItem("displayUnansweredSections") === 'YES') ?
                <Visibility onClick={() => { window.localStorage.setItem("displayUnansweredSections", 'NO'); window.location.reload(); }}
                  style={{ fontSize: '2rem', margin: '0 .15rem' }} /> :
                <VisibilityOff onClick={() => { window.localStorage.setItem("displayUnansweredSections", 'YES'); window.location.reload(); }}
                  style={{ fontSize: '2rem', margin: '0 .15rem' }} />             
            }
            {!isClientUser() ? (
              <QuickReviewIcon
                style={{
                  fontSize: '2rem',
                  margin: '0 .15rem',
                  color: quickReviewMode ? '#C92837' : '#000'
                }}
                onClick={onHandleSwitchQuickReviewMode}
              />
            ) : null}
            <DownloadIcon
              style={{ fontSize: '2rem', margin: '0 .15rem' }}
              onClick={() => R.compose(
                R.when(R.length, downloadZip),
                R.without(filterBlockedPhotos(svrResponse, formAnswers, isClientUser())),
                R.pluck('uuid')
              )(photos)
              }
            />
            {
              !R.isEmpty(photos) &&
              <div onClick={() => {
                setInitialCarouselPhoto(null);
                openPhotoReviewModal({ isPhotoReviewModalOpen: false });
              }}>
                <ImageSearchIcon />
              </div>
            }
            {
              hasVisibleVideos(isClientUser(), formAnswers, svrForm, videos) &&
              <div onClick={() => {
                setInitialCarouselVideo(null);
                openVideoReviewModal({ isVideoReviewModalOpen: false });
              }}>
                <LibraryVideoIcon />
              </div>
            }
          </IconsWrapper>
        </StyledHeading>

        <div>
          {
            !R.isEmpty(photos) && isPhotoReviewModalOpen &&
            <PhotoReviewModal
              formAnswers={formAnswers}
              showWhen={isPhotoReviewModalOpen}
              openPhotoReviewModal={openPhotoReviewModal}
              svrForm={svrForm}
              initialCarouselPhoto={initialCarouselPhoto}
              isClientUser={isClientUser()}
              svrResponse={svrResponse}
              updateSvrResponse={updateSvrResponse}
              namespace={namespace}
              fetchPhotoQuestions={fetchPhotoQuestions}
              toggleRejectPhotoQuestion={toggleRejectPhotoQuestion}
              updateDocument={updateDocument}
              photos={photos}
              photoQuestions={photoQuestions}
              rejectedQuestions={R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)}
              reviewMode={reviewMode}
              blockedPhotos={filterBlockedPhotos(svrResponse, formAnswers, isClientUser())}
            />
          }
          {
            !R.isEmpty(videos) && isVideoReviewModalOpen &&
            <VideoReviewModal
              formAnswers={formAnswers}
              showWhen={isVideoReviewModalOpen}
              openVideoReviewModal={openVideoReviewModal}
              svrForm={svrForm}
              initialCarouselVideo={initialCarouselVideo}
              isClientUser={isClientUser()}
              svrResponse={svrResponse}
              updateSvrResponse={updateSvrResponse}
              namespace={namespace}
              fetchVideoQuestions={fetchVideoQuestions}
              toggleRejectVideoQuestion={toggleRejectVideoQuestion}
              updateDocument={updateDocument}
              videos={videos}
              videoQuestions={videoQuestions}
              rejectedQuestions={R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)}
              reviewMode={reviewMode}
              blockedVideos={filterBlockedVideos(svrResponse, formAnswers, isClientUser())}
            />
          }
          <SvrResponseReviewInfo
            isSectionForcedOpen={isMetaSectionOpen}
            svrResponse={svrResponse}
            updateSvrResponse={updateSvrResponse}
            isClientUser={isClientUser()}
            namespace={namespace}
          />
          {quickReviewMode ? (
            <SvrResponseQuickReviewFormAnswers
              questions={quickReviewQuestions}
              formAnswers={formAnswers}
              reviewMode={reviewMode}
              quickReviewMode={quickReviewMode}
              photos={photos}
              videos={videos}
              isClientUser={isClientUser()}
              svrResponse={svrResponse}
              onOpenAnswerFeedbackModal={onOpenAnswerFeedbackModal}
              rejectedQuestions={R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)}
              onThumbnailClick={onThumbnailClick}
              onVideoThumbnailClick={onVideoThumbnailClick}
            />
          ) : (
            <SvrResponseReviewFormAnswers
              preSections={R.pathOr([], ['content', 'structure', 0], svrForm)}
              duringSections={R.pathOr([], ['content', 'structure', 1], svrForm)}
              postSections={R.pathOr([], ['content', 'structure', 2], svrForm)}
              formAnswers={formAnswers}
              reviewMode={reviewMode}
              photos={photos}
              signatures={signatures}
              videos={videos}
              quickReviewMode={quickReviewMode}
              isClientUser={isClientUser()}
              svrResponse={svrResponse}
              onOpenAnswerFeedbackModal={onOpenAnswerFeedbackModal}
              rejectedQuestions={R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)}
              onThumbnailClick={onThumbnailClick}
              onVideoThumbnailClick={onVideoThumbnailClick}
              isEditingPhotos={isEditingPhotos}
              onSwitchPhotos={onSwitchPhotos}
              onOpenDeletePhotoModal={(data) => setIsDeletePhotoModalOpen({ open: true, data })}
            />
          )}
          {isOpenAnswerFeedbackModal && (
            <AnswerFeedbackModal
              feedbackMessage={feedbackMessage}
              onHandleFeedbackChange={onHandleFeedbackChange}
              onHandleAnswerChange={onHandleAnswerChange}
              onHandleAnswerMultiChange={onHandleAnswerMultiChange}
              onCancel={onCloseAnswerFeedbackModal}
              onSave={saveAnswer}
              isAnswerRejected={isAnswerRejected}
              onToggle={toggleReject}
              question={currentQuestion}
              formAnswers={formAnswers}
              answerValue={answerValue}
              photos={photos}
              videos={videos}
              rotateVideos={R.pathOr([], ['metaJson', 'rotateVideos'], svrResponse)}
            />
          )}
          {isOpenAnswerFeedbackModal && (
            <AnswerFeedbackModal
              feedbackMessage={feedbackMessage}
              onHandleFeedbackChange={onHandleFeedbackChange}
              onHandleAnswerChange={onHandleAnswerChange}
              onHandleAnswerMultiChange={onHandleAnswerMultiChange}
              onCancel={onCloseAnswerFeedbackModal}
              onSave={saveAnswer}
              isAnswerRejected={isAnswerRejected}
              onToggle={toggleReject}
              question={currentQuestion}
              formAnswers={formAnswers}
              answerValue={answerValue}
              photos={photos}
              videos={videos}
              rotateVideos={R.pathOr([], ['metaJson', 'rotateVideos'], svrResponse)}
            />
          )}
          {isOpenFeedbackModal && (
            <SvrFeedbackModal
              feedbackMessage={feedbackMessage}
              onChange={onHandleFeedbackChange}
              onCancel={closeFeedbackModal}
              onSave={R.is(Number, selectedStatus) ? saveStatusFeedbackModal : saveFeedbackModal}
            />
          )}
          {isOpenRejectallmodal && (
            <RejectSvrAllModal
              onCancel={onCloseRejectAllModal}
              onSave={saveRejectAllModal}
            />
          )}
           {isOpenNeedrevisionall && (
            <NeedRevisionAllModal
              onCancel={onCloseNeedrevisionall}
              onSave={needrevsaveModal}
              onToggleRevise={toggleRevise}
              isAnswerRevise={isAnswerRevise}
            />
          )}
          {isOpenConfirmModal && (
            <ConfirmSvrModal
              status={selectedStatus}
              onApprove={onSaveSvrStatus}
              onCancel={closeConfirmModal}
            />
          )}
          {isOpenImpossibleEditSvrModal && (
            <ImpossibleEditSvrsModal
              onApprove={closeImpossibleEditModal}
              withoutCancel
            />
          )}
          {quickReviewMode && quickReviewQuestions.length < 1 && (
            <QuickReviewQuestionsModal
              onApprove={onHandleSwitchQuickReviewMode}
              withoutCancel
            />
          )}
          {R.prop('open', isDeletePhotoModalOpen) && (
            <DeletePhotoModal
              data={isDeletePhotoModalOpen.data}
              onApprove={() => {
                onDeletePhoto && onDeletePhoto(isDeletePhotoModalOpen.data.question);
                setIsDeletePhotoModalOpen({ open: false, data: null });
              }}
              onCancel={() => setIsDeletePhotoModalOpen({ open: false, data: null })}
            />
          )}
        </div>
        {/* {
          isClientUser() ?
            <ClientFeedbackModal
              svrForm={svrForm}
              storeVisitId= {storeVisitId}
              userEmail = {userEmail}
            />
            : null
        } */}
        <ClientFeedbackModal
          svrForm={svrForm}
          storeVisitId={storeVisitId}
          userEmail={userEmail}
        />
        {
          !isClientUser() ? (
            <Footer className='no-print'>
              <ButtonsContainer>
                <Button
                  onClick={onHandleSwitchReviewMode}
                  isSelected={!reviewMode}
                >
                  EDIT RESPONSES
                </Button>
                <Button
                  onClick={() => setIsEditingPhotos(prevState => !prevState)}
                  isSelected>
                  {isEditingPhotos ? 'FINISH EDITING PHOTOS' : 'EDIT PHOTOS'}
                </Button>
                <Button onClick={openFeedbackModal} isSelected>SVR FEEDBACK</Button>
              </ButtonsContainer>
              <ButtonsContainer>
                <Button
                  onClick={() => reviewMode && openFeedbackModal(SVR_RESPONSES_STATUSES.APPROVED)}
                  isSelected={
                    reviewMode
                    && !R.equals(R.pathOr(null, ['metaJson', 'reviewState'], svrResponse), SVR_RESPONSES_STATUSES.APPROVED)
                    && R.isEmpty(R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse))
                  }
                >
                  APPROVE
                </Button>
                <Button
                  onClick={() => reviewMode && openFeedbackModal(SVR_RESPONSES_STATUSES.REJECTED)}
                  isSelected={
                    reviewMode &&
                    !R.equals(R.pathOr(null, ['metaJson', 'reviewState'], svrResponse), SVR_RESPONSES_STATUSES.REJECTED) &&
                    !R.equals(R.pathOr(null, ['metaJson', 'reviewState'], svrResponse), SVR_RESPONSES_STATUSES.APPROVED)
                  }
                >
                  REJECT
                </Button>
                <Button  
                  onClick={() => reviewMode && onOpenRejectAllModal(SVR_RESPONSES_STATUSES.REJECTED)}
                  isSelected={
                    reviewMode &&
                    !R.equals(R.pathOr(null, ['metaJson', 'reviewState'], svrResponse), SVR_RESPONSES_STATUSES.REJECTED) &&
                    !R.equals(R.pathOr(null, ['metaJson', 'reviewState'], svrResponse), SVR_RESPONSES_STATUSES.APPROVED)
                  }
                >
                  REJECT ALL
                </Button> 
              </ButtonsContainer>
            </Footer>
          ) : null
        }
      </Wrapper>
    ) : null}
  </div>
);

const withHandlers = compose(
  withStateHandlers(
    ({
      feedbackMessage = '',
      currentQuestion = '',
      isAnswerRejected = false,
      isOpenFeedbackModal = false,
      isOpenConfirmModal = false,
      isOpenAnswerFeedbackModal = false,
      initialCarouselPhoto = null,
      initialCarouselVideo = null,
      quickReviewMode = false,
      selectedStatus = null,
      isPhotoReviewModalOpen = false,
      isVideoReviewModalOpen = false,
      isOpenImpossibleEditSvrModal = false,
      answerValue = '',
      isOpenRejectallmodal = false,
      isOpenNeedrevisionall = false,
      isAnswerRevise = true
    }) => ({
      feedbackMessage,
      isOpenFeedbackModal,
      isPhotoReviewModalOpen,
      isVideoReviewModalOpen,
      selectedStatus,
      quickReviewMode,
      isOpenConfirmModal,
      isOpenAnswerFeedbackModal,
      initialCarouselPhoto,
      initialCarouselVideo,
      currentQuestion,
      answerValue,
      isAnswerRejected,
      isOpenImpossibleEditSvrModal,
      isOpenRejectallmodal,
      isOpenNeedrevisionall,
      isAnswerRevise  
    }),
    ({
      toggleReject: ({ isAnswerRejected }) => () => ({
        isAnswerRejected: !isAnswerRejected
      }),
      toggleRevise: ({ isAnswerRevise }) => () => ({
        isAnswerRevise: !isAnswerRevise
      }),
      onOpenAnswerFeedbackModal: (__, { svrResponse, formAnswers }) => currentQuestion => ({
        isOpenAnswerFeedbackModal: true,
        isAnswerRejected: R.has(currentQuestion.uuid, R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)),
        feedbackMessage: R.pathOr('', ['formState', 'feedback', 'questions', currentQuestion.uuid], svrResponse),
        answerValue: formatData(currentQuestion, formAnswers),
        currentQuestion
      }),
      onCloseAnswerFeedbackModal: () => () => ({
        isOpenAnswerFeedbackModal: false,
        currentQuestion: '',
        feedbackMessage: '',
        answerValue: ''
      }),
      toggleRejectPhotoQuestion: (__, { svrResponse, updateSvrResponse, namespace }) => (isAnswerRejected, questionUuid) => {
        const formStateData = {
          visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
          feedback: {
            message: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse),
            questions: isAnswerRejected ? {
              ...R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse),
              [questionUuid]: '',
            } : R.dissoc(questionUuid, R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)),
          }
        };

        updateSvrResponse(namespace, svrResponse.id, { formState: JSON.stringify(formStateData) });
      },
      toggleRejectVideoQuestion: (__, { svrResponse, updateSvrResponse, namespace }) => (isAnswerRejected, questionUuid) => {
        const formStateData = {
          visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
          feedback: {
            message: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse),
            questions: isAnswerRejected ? {
              ...R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse),
              [questionUuid]: '',
            } : R.dissoc(questionUuid, R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)),
          }
        };

        updateSvrResponse(namespace, svrResponse.id, { formState: JSON.stringify(formStateData) });
      },
      saveAnswer: (state, props) => () => {
        const { updateSvrResponse, svrResponse, updateFormAnswer, formAnswers, createFormAnswer, namespace } = props;
        const { answerValue, isAnswerRejected, feedbackMessage, currentQuestion } = state;

        if (R.has(currentQuestion.uuid, formAnswers)) {
          const formStateData = {
            visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
            feedback: {
              message: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse),
              questions: isAnswerRejected ? {
                ...R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse),
                [currentQuestion.uuid]: feedbackMessage,
              } : R.dissoc(currentQuestion.uuid, R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse)),
            },
          };

          updateSvrResponse(namespace, svrResponse.id, { formState: JSON.stringify(formStateData) }).then(() =>
            updateFormAnswer(namespace, R.when(R.is(Array), JSON.stringify)(answerValue), formAnswers[currentQuestion.uuid].id)
          );
        } else {
          const newAnswer = {
            questionUuid: currentQuestion.uuid,
            questionCanonicalKey: currentQuestion.canonicalKey,
            storeVisitId: svrResponse.id,
            answerValue: R.when(R.is(Array), JSON.stringify)(answerValue)
          };

          createFormAnswer(newAnswer);
        }

        return {
          isOpenAnswerFeedbackModal: false,
          isAnswerRejected: false,
          currentQuestion: '',
          feedbackMessage: ''
        };
      },
      onHandleSwitchQuickReviewMode: ({ quickReviewMode }) => () => ({ quickReviewMode: !quickReviewMode }),
      onHandleSwitchReviewMode: (__, { updateSvrResponse, svrResponse, userEmail, namespace }) => () => {
        const mcEmail = R.path(['metaJson', 'mcEmail'], svrResponse);

        if (R.equals(mcEmail, userEmail)) {
          return { isOpenImpossibleEditSvrModal: true };
        }

        if (!R.contains(svrResponse.metaJson.reviewState, [SVR_RESPONSES_STATUSES.APPROVED, SVR_RESPONSES_STATUSES.REJECTED])) {
          const updatedMetaJson = R.assoc('reviewState', SVR_RESPONSES_STATUSES.IN_REVIEW, svrResponse.metaJson);
          updateSvrResponse(namespace, svrResponse.id, { metaJson: JSON.stringify(updatedMetaJson) });
        }
        const propertyNames = Object.keys(R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse));

        var isNeedrevisionall = false;
        if (propertyNames.length > 1 && (svrResponse.metaJson.reviewState === 5 || svrResponse.metaJson.reviewState === 2)) {
          isNeedrevisionall = true;
        }

        return { reviewMode: true, isOpenNeedrevisionall: isNeedrevisionall };
      },
      onHandleFeedbackChange: () => ({ target }) => ({ feedbackMessage: target.value }),
      onHandleAnswerChange: () => ({ target }) => ({ answerValue: target.value }),
      onHandleAnswerMultiChange: ({ answerValue }) => ({ target }) => ({
        answerValue: target.checked ? R.append(target.value, answerValue) : R.without(target.value, answerValue)
      }),
      closeFeedbackModal: () => () => ({
        isOpenFeedbackModal: false,
        selectedStatus: null,
        feedbackMessage: ''
      }),
      setInitialCarouselPhoto: ({ initialCarouselPhoto }) => () => ({ initialCarouselPhoto }),
      openPhotoReviewModal: ({ isPhotoReviewModalOpen }) => () => ({ isPhotoReviewModalOpen: !isPhotoReviewModalOpen }),
      setInitialCarouselVideo: ({ initialCarouselVideo }) => () => ({ initialCarouselVideo }),
      openVideoReviewModal: ({ isVideoReviewModalOpen }) => () => ({ isVideoReviewModalOpen: !isVideoReviewModalOpen }),
      onThumbnailClick: () => initialCarouselPhoto => ({ initialCarouselPhoto, isPhotoReviewModalOpen: true }),
      onVideoThumbnailClick: () => initialCarouselVideo => ({ initialCarouselVideo, isVideoReviewModalOpen: true }),
      openFeedbackModal: (__, { svrResponse, userEmail }) => selectedStatus => {
        const mcEmail = R.path(['metaJson', 'mcEmail'], svrResponse);        
        if (R.equals(mcEmail, userEmail) && R.is(Number, selectedStatus)) {
          return { isOpenImpossibleEditSvrModal: true };
        } else {
          return {
            feedbackMessage: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse),
            isOpenFeedbackModal: true,
            selectedStatus
          };
        }
      },
      closeConfirmModal: () => () => ({
        isOpenConfirmModal: false,
        selectedStatus: null,
        feedbackMessage: ''
      }),
      onOpenRejectAllModal: () => ()=> ({
        isOpenRejectallmodal: true
      }),
      onCloseRejectAllModal: () => () => ({
        isOpenRejectallmodal: false
      }),
      onCloseNeedrevisionall: () => () => ({
        isOpenNeedrevisionall: false
      }),
      saveRejectAllModal: (__, { updateSvrResponse, svrResponse, namespace }) => (feedbackMessage) => {       
        feedbackMessage = "";
         const visquestions = R.pathOr([], ['formState', 'visitedQuestions'], svrResponse);

          var questions = "{";
          for (var i = 0; i < visquestions.length; i++) {
            if (i === visquestions.length - 1)
              questions += "\"" + visquestions[i] + "\":\"" + feedbackMessage + "\"";
            else
              questions += "\"" + visquestions[i] + "\":\"" + feedbackMessage + "\",";
          }
          questions += "}";

          questions = JSON.parse(questions);

          const formStateData = {
            visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
            feedback: {
              message: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse),
              questions
            },
          };        

        updateSvrResponse(namespace, svrResponse.id, { formState: JSON.stringify(formStateData) });

        return {
          feedbackMessage: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse),
          isOpenFeedbackModal: true,
          isOpenRejectallmodal: false,
          selectedStatus : SVR_RESPONSES_STATUSES.REJECTED
        };
      },
      saveStatusFeedbackModal: () => () => ({ isOpenConfirmModal: true, isOpenFeedbackModal: false }),
      saveFeedbackModal: (__, { updateSvrResponse, svrResponse, namespace }) => (feedbackMessage) => {
        const data = {
          visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
          feedback: {
            visitedQuestions: R.pathOr([], ['formState', 'feedback', 'visitedQuestions'], svrResponse),
            questions: R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse),
            message: feedbackMessage
          }
        };

        updateSvrResponse(namespace, svrResponse.id, { formState: JSON.stringify(data) });

        return { isOpenFeedbackModal: false };
      },
      needrevsaveModal: (state, props) => () => {
        const { updateSvrResponse, svrResponse, namespace } = props;
        const { isAnswerRevise } = state;
        if (!isAnswerRevise) {
          const data = {
            visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
            feedback: {
              visitedQuestions: R.pathOr([], ['formState', 'feedback', 'visitedQuestions'], svrResponse),
              questions: R.pathOr({}, ['formState', 'feedback', 'questionss'], svrResponse),
              message: R.pathOr('', ['formState', 'feedback', 'message'], svrResponse)
            }
          };
          updateSvrResponse(namespace, svrResponse.id, { formState: JSON.stringify(data) });
        }
        return { isOpenNeedrevisionall: false , isAnswerRevise : true };
      },
      onSaveSvrStatus: ({ feedbackMessage, selectedStatus }, { updateSvrResponse, svrResponse, namespace }) => () => {
        let updatedMetaJson = R.assoc('reviewState', selectedStatus, svrResponse.metaJson);

        const currentDatetime = moment().utc().format();
        if (selectedStatus === SVR_RESPONSES_STATUSES.REJECTED) {
          updatedMetaJson = R.assoc('lastRejectedDate', currentDatetime, updatedMetaJson);
        }

        const data = {
          visitedQuestions: R.pathOr([], ['formState', 'visitedQuestions'], svrResponse),
          feedback: {
            visitedQuestions: R.pathOr([], ['formState', 'feedback', 'visitedQuestions'], svrResponse),
            questions: R.pathOr({}, ['formState', 'feedback', 'questions'], svrResponse),
            message: feedbackMessage
          }
        };

        updateSvrResponse(namespace, svrResponse.id, { metaJson: JSON.stringify(updatedMetaJson), formState: JSON.stringify(data) });

        return {
          isOpenConfirmModal: false,
          selectedStatus: null,
          feedbackMessage: '',
          reviewMode: false
        };
      },
      closeImpossibleEditModal: ({ isOpenImpossibleEditSvrModal }) => () => ({ isOpenImpossibleEditSvrModal: !isOpenImpossibleEditSvrModal }),
      onSwitchPhotos: (_, { updateFormAnswer, formAnswers, namespace }) => ({ target, source }) => {
        const answers = R.values(formAnswers);
        const targetAnswer = R.find(fa => target.question.uuid === fa.questionUuid, answers);
        const sourceAnswer = R.find(fa => source.question.uuid === fa.questionUuid, answers);
        if (targetAnswer.id === sourceAnswer.id)
          return;
        updateFormAnswer(namespace, source.answer, targetAnswer.id).then(() => updateFormAnswer(namespace, target.answer, sourceAnswer.id));
      },
      onDeletePhoto: (data1, { namespace, formAnswers, updateFormAnswer }) => (question) => {
        const answers = R.values(formAnswers);
        const answer = R.find(a => question.uuid === a.questionUuid, answers);
        updateFormAnswer(namespace, "", answer.id);
      }
    })
  ),
  defaultProps({
    isClientUser: () => { }
  }),
  withState('isEditingPhotos', 'setIsEditingPhotos', false),
  withState('isDeletePhotoModalOpen', 'setIsDeletePhotoModalOpen', { open: false })
);

export default withHandlers(SvrResponseReview);
