import React                 from 'react';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';

import { Button, Menu, MenuItem, Hidden } from '@material-ui/core';

import { ITEM_TYPES } from '../types';

const Toolbar = styled.div`
  display         : flex;
  justify-content : flex-end;
  margin-top      : 10px;
  padding         : 0 30px;
`;

const ITEMS = [
  { type: ITEM_TYPES.RADIO_BUTTON, name: 'Radio' },
  { type: ITEM_TYPES.CHECKBOX    , name: 'Checkbox' },
  { type: ITEM_TYPES.DROPDOWN    , name: 'Dropdown' },
  { type: ITEM_TYPES.SINGLE_LINE , name: 'Single Line' },
  { type: ITEM_TYPES.MULTI_LINE  , name: 'Multi Line' },
  { type: ITEM_TYPES.TOGGLE      , name: 'Yes/No' },
  { type: ITEM_TYPES.PHOTO       , name: 'Photo' },
  { type: ITEM_TYPES.NUMERIC_INT , name: 'Numeric' },
  { type: ITEM_TYPES.VIDEO       , name: 'Video' }
];

const CommonQuestionListToolbar = ({
  fieldAnchorEl,
  open,
  close,
  selectFieldType,
}) => (
  <Hidden only={['xs', 'sm']}>
    <Toolbar>
        <Button
          variant="raised"
          onClick={({ currentTarget }) => open(currentTarget)}
          style={{
            border          : '2px solid #DE898C',
            color           : '#F3212E',
            backgroundColor : 'transparent',
            borderRadius    : '5px',
            padding         : '8px',
            minWidth        : '120px',
            letterSpacing   : '1px',
            boxShadow       : 'none',
          }}
        >
          CREATE NEW
        </Button>
        <Menu
          id="fields-menu"
          anchorEl={fieldAnchorEl}
          open={Boolean(fieldAnchorEl)}
          onClose={close}
          style={{ marginTop: '50px' }}
        >
        {ITEMS.map(i => (
          <MenuItem
            key={i.type}
            onClick={selectFieldType.bind(null, i.type)}
          >
            {i.name}
          </MenuItem>
        ))}
      </Menu>
    </Toolbar>
  </Hidden>
);

const addCommonQuestion = withStateHandlers(
  ({
    fieldAnchorEl     = null,
    onAdd             = null,
    openQuestionModal = null,
  }) => ({ fieldAnchorEl, onAdd, openQuestionModal }),
  {
    open            : () => currentTarget => ({ fieldAnchorEl: currentTarget }),
    close           : () => () => ({ fieldAnchorEl: null }),
    selectFieldType : ({ openQuestionModal }) => (type) => {
      openQuestionModal(type);
      return { fieldAnchorEl: null  };
    },
  },
);

export default addCommonQuestion(CommonQuestionListToolbar);
