import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Switch } from '@material-ui/core';
import Modal from './components/Modal';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NeedRevisionAllModal = ({ onSave, onCancel, isAnswerRevise, onToggleRevise, isproceed = true }) => (
    <Modal
        onSave={onSave}
        onCancel={onCancel}
        isproceed={isproceed}
    >
        <Container>
            <h4>Revisions on All</h4>
            <div style={{ textAlign: "left" }}>
                <span>Need revisions on All questions</span>
                <Switch
                    checked={isAnswerRevise}
                    onChange={onToggleRevise}
                    value="needrevisionsall"
                />
            </div>

        </Container>
    </Modal>
);

NeedRevisionAllModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isAnswerRevise: PropTypes.bool,
    onToggleRevise: PropTypes.func.isRequired
};

export default NeedRevisionAllModal;