import * as R from 'ramda';

const formatData = (currentQuestion, formAnswers) => {
  const answer = R.pathOr('', [currentQuestion.uuid, 'answerValue'], formAnswers);

  if (R.equals('checkbox-input', currentQuestion.type) && !R.isEmpty(answer)) {
    return R.when(R.is(String), JSON.parse)(answer);
  }

  return R.pathOr('', [currentQuestion.uuid, 'answerValue'], formAnswers);
};


export default formatData;
