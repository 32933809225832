import * as R from 'ramda';
import uuid from 'uuid/v4';

import {
  UPDATE_TITLE,
  UPDATE_AUTO_APPROVE,
  UPDATE_HIDE_FROM_CLIENT,
  UPDATE_INVOICE_PLANNED_TIME,
  UPDATE_MAX_ALLOWED_TIME,
  UPDATE_BYPASS_GPS,
  UPDATE_BYPASS_DUPLICATE_PHOTO_CHECK,
  UPDATE_HERO_IMAGE,
  UPDATE_CLIENT,
  UPDATE_CALLOUT,
  ADD_SECTION,
  EDIT_SECTION,
  COPY_SECTION,
  DELETE_SECTION,
  CHANGE_NODE_SECTION,
  ADD_QUESTION,
  EDIT_QUESTION,
  COPY_QUESTION,
  DELETE_QUESTION,
  CHANGE_NODE_QUESTION,
  ADD_FORK_QUESTION,
  COPY_FORK_QUESTION,
  DELETE_FORK_QUESTION,
  EDIT_FORK_QUESTION,
  ADD_GROUP_QUESTION,
  COPY_GROUP_QUESTION,
  DELETE_GROUP_QUESTION,
  CHANGE_NODE_GROUP_QUESTION,
  EDIT_GROUP_QUESTION,
  SVR_SAVED_NOTIFICATION,
  LOAD,
  RESET,
  FETCH_TAGS_BY_STRING,
  RESET_SEARCHED_TAGS,
  LAST_SAVED_SVR,
  IS_DRAFT
} from './BuilderActions';

import { ADD_DOCUMENT } from '../upload/UploadActions';
import { REMOVE_DOCUMENT } from '../document/DocumentActions';

import { getPhotoQuestionsByBeforeType, getVideoQuestionsByBeforeType } from './utils/getQuestionsByBeforeType';

import makeQuestionClone from './helpers/makeQuestionClone';

export const STATE_KEY = 'builder';

export const SECTIONS_GROUPS = {
  pre    : 0,
  during : 1,
  post   : 2
};

export const ITEM_TYPES = {
  SECTION               : 'section',
  QUESTION_GROUP        : 'question-group',
  RADIO_BUTTON          : 'radio-button-input',
  DROPDOWN              : 'dropdown-input',
  CHECKBOX              : 'checkbox-input',
  SINGLE_LINE           : 'single-line-input',
  MULTI_LINE            : 'multi-line-input',
  PHOTO                 : 'photo-input',
  TOGGLE                : 'toggle-input',
  COMMON                : 'common-input',
  NUMERIC_INT           : 'numeric-int-input',
  VIDEO                 : 'video-input',
  SIGNATURE             : 'signature-input',
  NUMERIC_CONDITIONAL   : 'numeric-conditional-input',
};

export const ITEM_TYPES_WITH_CONDITION = [
  ITEM_TYPES.CHECKBOX,
  ITEM_TYPES.DROPDOWN,
  ITEM_TYPES.RADIO_BUTTON,
  ITEM_TYPES.TOGGLE,
  ITEM_TYPES.NUMERIC_CONDITIONAL,
];

export const ITEM_TYPES_WITH_AUTO_CALLOUT = [
  ITEM_TYPES.SINGLE_LINE,
  ITEM_TYPES.MULTI_LINE,
  ITEM_TYPES.PHOTO,
  ITEM_TYPES.COMMON,
  ITEM_TYPES.NUMERIC_INT,
  ITEM_TYPES.VIDEO
];

export const ITEM_TYPES_WITHOUT_CALLOUT = [
  ITEM_TYPES.VIDEO
];

const FORM_STATUS_ACTIVE = 1;

const initialState = {
  isSaving      : false,
  lastSavedSvr  : null,
  isDraft       : false,
  searchedTags  : [],
  svrForm       : {
    title           : '',
    tenancyClientId : 0,
    status          : FORM_STATUS_ACTIVE,
    content         : {
      structure : [
        [],
        [],
        []
      ],
      documents : [],
      callOut   : {
        active  : false,
        mailing : ''
      }
    }
  }
};

const searchQuestion = (questionId, questions) => {
  for (let questionIdx = 0, questionNode; !R.isNil(questions[questionIdx]); questionIdx++) {
    questionNode = questions[questionIdx];
    if (questionNode.uuid === questionId) {
      return [questionIdx];
    }

    for (let optionIdx = 0, optionNode; !R.isNil(questionNode.options && questionNode.options[optionIdx]); optionIdx++) {
      optionNode = questionNode.options[optionIdx];
      const result = searchQuestion(questionId, optionNode.conditionalFork);
      if (result) {
        return [questionIdx, 'options', optionIdx, 'conditionalFork'].concat(result);
      }
    }
  }

  return null;
};

const searchForkQuestion = (questionId, answerId, data) => {
  const path = searchQuestion(questionId, data).concat('options');
  const answerIdx = R.compose(
    R.findIndex(R.propEq('uuid', answerId)), R.path(path)
  )(data);
  return path.concat(answerIdx);
};

const replaceUuidCanonicalKeyForkedOptions = data => {
  if (!R.has('options', data)) {
    return;
  }
  data.options.forEach(fqo => {
    const newUuIdForkOption = uuid();
    fqo.uuid = newUuIdForkOption;
    replaceUuidCanonicalKeyForkedQuestions(fqo);
  });
};

const replaceUuidCanonicalKeyForkedQuestions = data => {
  if (!R.has('conditionalFork', data)) {
    return;
  }
  data.conditionalFork.forEach(fq => {
    const newUuIdForkQuestion = uuid();
    fq.uuid = newUuIdForkQuestion;
    fq.canonicalKey = newUuIdForkQuestion;
    replaceUuidCanonicalKeyForkedOptions(fq);
  });

  return data;
};

const BuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TITLE: {
      return R.assocPath(['svrForm', 'title'], action.payload, state);
    }
    case UPDATE_AUTO_APPROVE: {
      return R.assocPath(['svrForm', 'autoApprovalHourTrigger'], action.payload, state);
    }
    case UPDATE_HIDE_FROM_CLIENT: {
      return R.assocPath(['svrForm', 'content', 'hideFromClient'], action.payload, state);
    }
    case UPDATE_INVOICE_PLANNED_TIME: {
      return R.assocPath(['svrForm', 'content', 'invoicePlannedTime'], action.payload, state);
    }
    case UPDATE_MAX_ALLOWED_TIME: {
      return R.assocPath(['svrForm', 'content', 'maxAllowedTime'], action.payload, state);
    }
    case UPDATE_BYPASS_GPS: {
      return R.assocPath(['svrForm', 'content', 'bypassGPSValidation'], action.payload, state);
    }
    case UPDATE_BYPASS_DUPLICATE_PHOTO_CHECK: {
      return R.assocPath(['svrForm', 'content', 'bypassDuplicatePhotoCheck'], action.payload, state);
    }
    case UPDATE_HERO_IMAGE: {
      return R.assocPath(['svrForm', 'heroImageUrl'], action.payload.url, state);
    }
    case UPDATE_CLIENT: {
      return R.assocPath(['svrForm', 'tenancyClientId'], action.payload, state);
    }
    case UPDATE_CALLOUT: {
      return R.assocPath(['svrForm', 'content', 'callOut'], action.payload, state);
    }
    case ADD_SECTION: {
      const sectionGroup = R.propOr(0, 'sectionGroup', action);
      const section      = R.merge({ content: [], type: 'section' }, R.propOr({}, 'payload', action));

      const sectionsLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup]);
      const sections     = R.view(sectionsLens, state);

      return R.set(
        sectionsLens,
        R.concat(sections, [section]),
        state,
      );
    }
    case EDIT_SECTION: {
      const sectionGroup = R.propOr(0, 'sectionGroup', action);
      const section      = R.propOr({}, 'payload', action);
      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', section.uuid)),
        R.path(['svrForm', 'content', 'structure', sectionGroup])
      )(state);

      const sectionLens  = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex]);
      const prevSection = R.view(sectionLens, state);

      return R.set(
        sectionLens,
        R.mergeDeepRight(prevSection, section),
        state,
      );
    }
    case COPY_SECTION: {
      const sectionGroup = R.propOr(0, 'sectionGroup', action);
      const sectionKey   = action.payload;

      const sectionsLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup]);
      const sections     = R.view(sectionsLens, state);

      const sectionIndex = R.findIndex(R.propEq('uuid', sectionKey), sections);
      const section      = sections[sectionIndex];
      const newSection   = R.assoc('uuid', uuid(), makeQuestionClone(section));

      // NEW: Create new uuid and canonical key for inner questions
      newSection.content.forEach(question => {
        const newUuId = uuid();
        question.uuid = newUuId;
        question.canonicalKey = newUuId;

        // If question has options == conditional question
        if (question.options) {
          // NEW: Create new uuid and canonical key for inner questions
          question.options.forEach(o => {
            const newUuIdOption = uuid();
            o.uuid = newUuIdOption;
            replaceUuidCanonicalKeyForkedQuestions(o);
          });
        }
      });

      const sectionData = R.append(newSection, sections);

      if (sectionData.length > 0) {
        for (var x = 0; x < sectionData.length; x++) {
          if (x === sectionData.length - 1) {
            if (sectionData[x].content !== undefined && sectionData[x].content !== null && sectionData[x].content.length > 0) {
              for (var y = 0; y < sectionData[x].content.length; y++) {
                if (sectionData[x].content[y].content !== undefined && sectionData[x].content[y].content !== null && sectionData[x].content[y].content.length > 0) {
                  for (var z = 0; z < sectionData[x].content[y].content.length; z++) {
                    const newUuId = uuid();
                    sectionData[x].content[y].content[z].uuid = newUuId;
                    sectionData[x].content[y].content[z].canonicalKey = newUuId;
                  }
                }
              }
            }
          }
        }
      }

      return R.set(
        sectionsLens,
        sectionData,
        state
      );
    }
    case DELETE_SECTION: {
      const sectionGroup = R.propOr(0, 'sectionGroup', action);
      const sectionKey   = action.payload;
      const sectionsLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup]);
      const sections     = R.view(sectionsLens, state);

      return R.set(
        sectionsLens,
        R.reject(R.propEq('uuid', sectionKey), sections),
        state,
      );
    }
    case CHANGE_NODE_SECTION: {
      const oldSectionGroup = R.path(['payload', 'sectionGroup'], action);
      const sectionKey      = R.pathOr('', ['payload', 'uuid'], action);
      const oldSectionsLens = R.lensPath(['svrForm', 'content', 'structure', oldSectionGroup]);
      const oldSections     = R.view(oldSectionsLens, state);

      const withOutSectionState = R.set(
        oldSectionsLens,
        R.reject(R.propEq('uuid', sectionKey), oldSections),
        state,
      );

      const newSectionGroup = R.prop('sectionGroup', action);
      const newSectionLens = R.lensPath(['svrForm', 'content', 'structure', newSectionGroup]);
      const newSections    = R.view(newSectionLens, withOutSectionState);
      const newSection = R.compose(
        R.dissoc('sectionGroup'),
        R.prop('payload')
      )(action);

      return R.set(
        newSectionLens,
        R.insert(R.prop('sectionPosition', action), newSection, newSections),
        withOutSectionState
      );
    }
    case ADD_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            question       = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const sectionQuestionsLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content']);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      return R.set(
        sectionQuestionsLens,
        R.concat(sectionQuestions, [question]),
        state,
      );
    }
    case EDIT_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            question       = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const questionIndex = R.compose(
        R.findIndex(R.propEq('uuid', question.uuid)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content']),
      )(state);

      const questionLens  = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionIndex]);
      const prevQuestion = R.view(questionLens, state);

      if (question.type === 'numeric-conditional-input') {
        //question.options[0].conditionalFork = [];
      }

      return R.set(
        questionLens,
        R.mergeDeepRight(prevQuestion, question),
        state,
      );
    }
    case COPY_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            questionKey    = _actionOr('', 'payload');

      const newUuId = uuid();

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const sectionQuestionsLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content']);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      const questionIndex = R.findIndex(R.propEq('uuid', questionKey), sectionQuestions);
      const question      = sectionQuestions[questionIndex];
      const newQuestion   = R.merge(makeQuestionClone(question),{ uuid: newUuId, canonicalKey: newUuId });

      // If question has content == group of questions
      if (newQuestion.content) {
        // NEW: Create new uuid and canonical key for inner questions
        newQuestion.content.forEach(q => {
          const newUuId = uuid();
          q.uuid = newUuId;
          q.canonicalKey = newUuId;
        });
      }

      // If question has options == conditional question
      if (newQuestion.options) {
        // NEW: Create new uuid and canonical key for inner questions
        newQuestion.options.forEach(o => {
          const newUuIdOption = uuid();
          o.uuid = newUuIdOption;
          replaceUuidCanonicalKeyForkedQuestions(o);
        });
      }

     return R.set(
        sectionQuestionsLens,
        R.append(
          newQuestion,
          sectionQuestions
        ),
        state,
      );
    }
    case DELETE_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            questionKey    = _actionOr('', 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const sectionQuestionsLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content']);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      return R.set(
        sectionQuestionsLens,
        R.reject(R.propEq('uuid', questionKey), sectionQuestions),
        state,
      );
    }
    case ADD_GROUP_QUESTION: {
      const _actionOr        = R.partialRight(R.propOr, [action]),
            sectionGroup     = _actionOr(0,  'sectionGroup'),
            sectionKey       = _actionOr('', 'section'),
            questionGroupKey = _actionOr('', 'questionGroup'),
            question         = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const questionGroupIndex = R.compose(
        R.findIndex(R.propEq('uuid', questionGroupKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content'])
      )(state);

      const questionGroupLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionGroupIndex, 'content']);
      const questionGroup     = R.view(questionGroupLens, state);

      return R.set(
        questionGroupLens,
        R.concat(questionGroup, [question]),
        state,
      );
    }
    case EDIT_GROUP_QUESTION: {
      const _actionOr        = R.partialRight(R.propOr, [action]),
            sectionGroup     = _actionOr(0,  'sectionGroup'),
            sectionKey       = _actionOr('', 'section'),
            questionGroupKey = _actionOr('', 'questionGroup'),
            question         = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const questionGroupIndex = R.compose(
        R.findIndex(R.propEq('uuid', questionGroupKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content'])
      )(state);

      const questionIndex = R.compose(
        R.findIndex(R.propEq('uuid', question.uuid)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionGroupIndex, 'content']),
      )(state);

      const questionLens  = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionGroupIndex, 'content', questionIndex]);
      const prevQuestion = R.view(questionLens, state);

      return R.set(
        questionLens,
        R.mergeDeepRight(prevQuestion, question),
        state,
      );
    }

    case COPY_GROUP_QUESTION : {
      const _actionOr        = R.partialRight(R.propOr, [action]),
            sectionGroup     = _actionOr(0,  'sectionGroup'),
            sectionKey       = _actionOr('', 'section'),
            questionGroupKey = _actionOr('', 'questionGroup'),
            questionKey      = _actionOr('', 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const questionGroupIndex = R.compose(
        R.findIndex(R.propEq('uuid', questionGroupKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content'])
      )(state);

      const questionIndex = R.compose(
        R.findIndex(R.propEq('uuid', questionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionGroupIndex, 'content']),
      )(state);

      const questionGroupLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionGroupIndex, 'content']);
      const questionGroup     = R.view(questionGroupLens, state);
      const question          = questionGroup[questionIndex];

      return R.set(
        questionGroupLens,
        R.append(R.assoc('uuid', uuid(), makeQuestionClone(question)), questionGroup),
        state,
      );
    }
    case DELETE_GROUP_QUESTION : {
      const _actionOr        = R.partialRight(R.propOr, [action]),
            sectionGroup     = _actionOr(0,  'sectionGroup'),
            sectionKey       = _actionOr('', 'section'),
            questionGroupKey = _actionOr('', 'questionGroup'),
            questionKey      = _actionOr('', 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const questionGroupIndex = R.compose(
        R.findIndex(R.propEq('uuid', questionGroupKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content'])
      )(state);

      const questionGroupLens = R.lensPath(['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content', questionGroupIndex, 'content']);
      const questionGroup     = R.view(questionGroupLens, state);

      return R.set(
        questionGroupLens,
        R.reject(R.propEq('uuid', questionKey), questionGroup),
        state,
      );
    }

    case CHANGE_NODE_GROUP_QUESTION:
    case CHANGE_NODE_QUESTION: {

      const oldSectionGroup   = R.path(['payload', 'sectionGroup'], action);
      const oldSectionKey     = R.path(['payload', 'sectionId'], action);
      const questionKey       = R.path(['payload', 'uuid'], action);

      const oldSectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', oldSectionKey)),
        R.path(['svrForm', 'content', 'structure', oldSectionGroup]),
      )(state);

      const sectionQuestionsLens = R.lensPath(['svrForm', 'content', 'structure', oldSectionGroup, oldSectionIndex, 'content']);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      const withOutSelectedItemState = R.set(
        sectionQuestionsLens,
        R.reject(R.propEq('uuid', questionKey), sectionQuestions),
        state,
      );

      const _actionOr      = R.partialRight(R.propOr, [action]),
        targetSectionGroup = _actionOr(0,  'sectionGroup'),
        targetSectionKey   = _actionOr('', 'section'),
        targetQuestionKey  = _actionOr('', 'question');

      const newQuestion = R.compose(
        R.omit(['sectionGroup', 'sectionId']),
        R.prop('payload')
      )(action);

      const newSectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', targetSectionKey)),
        R.path(['svrForm', 'content', 'structure', targetSectionGroup]),
      )(state);

      const newQuestionIndex = R.isEmpty(targetQuestionKey) ? 0 :
        R.compose(
          R.findIndex(R.propEq('uuid', targetQuestionKey)),
          R.path(['svrForm', 'content', 'structure', targetSectionGroup, newSectionIndex, 'content'])
        )(withOutSelectedItemState) + 1;

      const questionLens = R.lensPath(['svrForm', 'content', 'structure', targetSectionGroup, newSectionIndex, 'content']);
      const newQuestions = R.view(questionLens, withOutSelectedItemState);

      return R.set(
        questionLens,
        R.insert(newQuestionIndex, newQuestion, newQuestions),
        withOutSelectedItemState,
      );
    }

    case ADD_FORK_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            parentQuestion = _actionOr('', 'question'),
            forkedAnswer   = _actionOr({}, 'forkedAnswer'),
            question       = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const initialLens = ['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content' ];

      const sectionQuestionsLens = R.lensPath(initialLens);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      const searchedForkQuestion = searchForkQuestion(parentQuestion, forkedAnswer.uuid, sectionQuestions);
      const conditionalForkPath  = R.append('conditionalFork', searchedForkQuestion);

      const fullLens = R.concat(initialLens, conditionalForkPath);

      const forkQuestionLens = R.lensPath(fullLens);
      const forkQuestion     = R.view(forkQuestionLens, state);

      let flatQuestion = question;

      if (!Array.isArray(question)) {
        flatQuestion = [question];
      }

      return R.set(
        forkQuestionLens,
        R.concat(forkQuestion, flatQuestion),
        state,
      );
    }
    case EDIT_FORK_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            parentQuestion = _actionOr('', 'question'),
            forkedAnswer   = _actionOr({}, 'forkedAnswer'),
            question       = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const initialLens = ['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content' ];

      const sectionQuestionsLens = R.lensPath(initialLens);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      const searchedForkQuestion = searchForkQuestion(parentQuestion, forkedAnswer.uuid, sectionQuestions);
      const conditionalForkPath  = R.append('conditionalFork', searchedForkQuestion);

      const lensToConditionals = R.concat(initialLens, conditionalForkPath);

      const forkQuestionIndex = R.compose(
        R.findIndex(R.propEq('uuid', question.uuid)),
        R.path(lensToConditionals),
      )(state);

      const fullLens = R.concat(lensToConditionals, [forkQuestionIndex]);

      const forkQuestionLens = R.lensPath(fullLens);
      const forkQuestion     = R.view(forkQuestionLens, state);

      return R.set(
        forkQuestionLens,
        R.mergeDeepRight(forkQuestion, question),
        state,
      );
    }
    case DELETE_FORK_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            parentQuestion = _actionOr('', 'question'),
            forkedAnswer   = _actionOr({}, 'forkedAnswer'),
            questionKey    = _actionOr({}, 'payload');

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const initialLens = ['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content' ];

      const sectionQuestionsLens = R.lensPath(initialLens);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      const searchedForkQuestion = searchForkQuestion(parentQuestion, forkedAnswer, sectionQuestions);
      const conditionalForkPath    = R.append('conditionalFork', searchedForkQuestion);

      const fullLens = R.concat(initialLens, conditionalForkPath);

      const forkQuestionLens = R.lensPath(fullLens);
      const forkQuestion     = R.view(forkQuestionLens, state);

      return R.set(
        forkQuestionLens,
        R.reject(R.propEq('uuid', questionKey), forkQuestion),
        state,
      );
    }
    case COPY_FORK_QUESTION: {
      const _actionOr      = R.partialRight(R.propOr, [action]),
            sectionGroup   = _actionOr(0,  'sectionGroup'),
            sectionKey     = _actionOr('', 'section'),
            parentQuestion = _actionOr('', 'question'),
            forkedAnswer   = _actionOr({}, 'forkedAnswer'),
            questionKey    = _actionOr({}, 'payload');

      const newUuId = uuid();

      const sectionIndex = R.compose(
        R.findIndex(R.propEq('uuid', sectionKey)),
        R.path(['svrForm', 'content', 'structure', sectionGroup]),
      )(state);

      const initialLens = ['svrForm', 'content', 'structure', sectionGroup, sectionIndex, 'content' ];

      const sectionQuestionsLens = R.lensPath(initialLens);
      const sectionQuestions     = R.view(sectionQuestionsLens, state);

      const searchedForkQuestion = searchForkQuestion(parentQuestion, forkedAnswer, sectionQuestions);
      const conditionalForkPath    = R.append('conditionalFork', searchedForkQuestion);

      const fullLens = R.concat(initialLens, conditionalForkPath);

      const forkQuestionLens = R.lensPath(fullLens);
      const forkQuestion     = R.view(forkQuestionLens, state);

      const questionIndex = R.findIndex(R.propEq('uuid', questionKey), forkQuestion);
      const question      = forkQuestion[questionIndex];

      // If question has options
      if (question.options) {
        // NEW: Create new uuid and canonical key for inner questions
        question.options.forEach(o => {
          if (o.conditionalFork) {
            const newUuIdOption = uuid();
            o.uuid = newUuIdOption;
            o.canonicalKey = newUuIdOption;

            o.conditionalFork.forEach(q => {
              const newUuIdInnerQuestion = uuid();
              q.uuid = newUuIdInnerQuestion;
              q.canonicalKey = newUuIdInnerQuestion;

              if (q.options) {
                q.options.forEach(io => {
                  io.uuid = uuid();
                });
              }
            });
          } else {
            o.uuid = uuid();
          }
        });
      }

      const questionData = R.insert(
        R.inc(questionIndex),
        R.merge(
          makeQuestionClone(question),
          {
            uuid: newUuId,
            canonicalKey: newUuId
          }
        ),
        forkQuestion,
      )

      // if (questionData.length > 0) {
      //   for (var i = 0; i < questionData.length; i++) {
      //     if (i === questionData.length - 1) {
      //       if (questionData[i].options !== undefined && questionData[i].options !== null && questionData[i].options.length > 0) {
      //         for (var j = 0; j < questionData[i].options.length; j++) {
      //           const ouuid = uuid();
      //           questionData[i].options[j].uuid = ouuid;
      //           questionData[i].options[j].canonicalKey = ouuid;
      //           if (questionData[i].options[j].conditionalFork !== undefined && questionData[i].options[j].conditionalFork !== null
      //             && questionData[i].options[j].conditionalFork.length > 0) {
      //             for (var k = 0; k < questionData[i].options[j].conditionalFork.length; k++) {
      //               const cuuid = uuid();
      //               questionData[i].options[j].conditionalFork[k].uuid = cuuid;
      //               questionData[i].options[j].conditionalFork[k].canonicalKey = cuuid;
      //             }
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

      if (questionData.length > 0) {
        for (var i = 0; i < questionData.length; i++) {
          // if (i === questionData.length - 1) {
          //Level - 1
          if (questionData[i].options !== undefined && questionData[i].options !== null && questionData[i].options.length > 0) {
            for (var j = 0; j < questionData[i].options.length; j++) {
              const ouuid = uuid();
              questionData[i].options[j].uuid = ouuid;
              questionData[i].options[j].canonicalKey = ouuid;
              //Level - 2
              if (questionData[i].options[j].conditionalFork !== undefined && questionData[i].options[j].conditionalFork !== null
                && questionData[i].options[j].conditionalFork.length > 0) {

                for (var k = 0; k < questionData[i].options[j].conditionalFork.length; k++) {
                  const cuuid = uuid();
                  questionData[i].options[j].conditionalFork[k].uuid = cuuid;
                  questionData[i].options[j].conditionalFork[k].canonicalKey = cuuid;

                  if (questionData[i].options[j].conditionalFork[k].options !== undefined && questionData[i].options[j].conditionalFork[k].options !== null
                    && questionData[i].options[j].conditionalFork[k].options.length > 0) {

                    for (var l = 0; l < questionData[i].options[j].conditionalFork[k].options.length; l++) {
                      const couuid = uuid();
                      questionData[i].options[j].conditionalFork[k].options[l].uuid = couuid;
                      questionData[i].options[j].conditionalFork[k].options[l].canonicalKey = couuid;
                      //Level - 3
                      if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork !== undefined
                        && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork !== null
                        && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork.length > 0) {

                        for (var m = 0; m < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork.length; m++) {
                          const cocuuid = uuid();
                          questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].uuid = cocuuid;
                          questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].canonicalKey = cocuuid;

                          if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options !== undefined
                            && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options !== null
                            && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options.length > 0) {

                            for (var n = 0; n < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options.length; n++) {
                              const cocuuid = uuid();
                              questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].uuid = cocuuid;
                              questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].canonicalKey = cocuuid;

                              if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork !== undefined
                                && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork !== null
                                && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork.length > 0) {
                                //Level - 4
                                for (var o = 0; o < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork.length; o++) {
                                  const cocuuid = uuid();
                                  questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].uuid = cocuuid;
                                  questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].canonicalKey = cocuuid;

                                  if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options !== undefined
                                    && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options !== null
                                    && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options.length > 0) {

                                    for (var p = 0; p < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options.length; p++) {
                                      const cocuuid = uuid();
                                      questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].uuid = cocuuid;
                                      questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].canonicalKey = cocuuid;

                                      //Level - 5
                                      if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].conditionalFork !== undefined
                                        && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].conditionalFork !== null
                                        && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].conditionalFork.length > 0) {
                                        for (var q = 0; q < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].conditionalFork.length; q++) {
                                          const cocuuid = uuid();
                                          questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].conditionalFork[q].uuid = cocuuid;
                                          questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p].conditionalFork[q].canonicalKey = cocuuid;


                                          if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                            .conditionalFork[q].options !== undefined
                                            && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                              .conditionalFork[q].options !== null
                                            && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                              .conditionalFork[q].options.length > 0) {

                                            for (var r = 0; r < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                              .conditionalFork[q].options.length; r++) {
                                              const cocuuid = uuid();
                                              questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                .options[p].conditionalFork[q].options[r].uuid = cocuuid;
                                              questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                .options[p].conditionalFork[q].options[r].canonicalKey = cocuuid;

                                              //level - 6
                                              if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                .conditionalFork[q].options[r].conditionalFork !== undefined
                                                && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                  .conditionalFork[q].options[r].conditionalFork !== null
                                                && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                  .conditionalFork[q].options[r].conditionalFork.length > 0) {


                                                for (var s = 0; s < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                  .conditionalFork[q].options[r].conditionalFork.length; s++) {
                                                  const cocuuid = uuid();
                                                  questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                    .options[p].conditionalFork[q].options[r].conditionalFork[s].uuid = cocuuid;
                                                  questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                    .options[p].conditionalFork[q].options[r].conditionalFork[s].canonicalKey = cocuuid;


                                                  if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                    .conditionalFork[q].options[r].conditionalFork[s].options !== undefined
                                                    && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                      .conditionalFork[q].options[r].conditionalFork[s].options !== null
                                                    && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                      .conditionalFork[q].options[r].conditionalFork[s].options.length > 0) {

                                                    for (var t = 0; t < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                      .conditionalFork[q].options[r].conditionalFork[s].options.length; t++) {
                                                      const cocuuid = uuid();
                                                      questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                        .options[p].conditionalFork[q].options[r].conditionalFork[s].options[t].uuid = cocuuid;
                                                      questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                        .options[p].conditionalFork[q].options[r].conditionalFork[s].options[t].canonicalKey = cocuuid;

                                                      //Level -7

                                                      if (questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                        .conditionalFork[q].options[r].conditionalFork[s].options[t].conditionalFork !== undefined
                                                        && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                          .conditionalFork[q].options[r].conditionalFork[s].options[t].conditionalFork !== null
                                                        && questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                          .conditionalFork[q].options[r].conditionalFork[s].options[t].conditionalFork.length > 0) {

                                                        for (var u = 0; u < questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o].options[p]
                                                          .conditionalFork[q].options[r].conditionalFork[s].options[t].conditionalFork.length; u++) {
                                                          const cocuuid = uuid();
                                                          questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                            .options[p].conditionalFork[q].options[r].conditionalFork[s].options[t].conditionalFork[u].uuid = cocuuid;
                                                          questionData[i].options[j].conditionalFork[k].options[l].conditionalFork[m].options[n].conditionalFork[o]
                                                            .options[p].conditionalFork[q].options[r].conditionalFork[s].options[t].conditionalFork[u].canonicalKey = cocuuid;
                                                        }
                                                        
                                                      }
                                                    }
                                                  }
                                                }
                                              }

                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          // }
        }
      }
      
      return R.set(
        forkQuestionLens,
        questionData,
        state,
      );
    }
    case LOAD : {
      const svr = action.payload.content ? action.payload : R.assoc('content', action.payload.sections, action.payload);
      return R.assoc('svrForm', svr, state);
    }
    case RESET : {
      return initialState;
    }
    case SVR_SAVED_NOTIFICATION : {
      return R.assoc('isSaving', action.payload)(state);
    }
    case LAST_SAVED_SVR : {
      return R.assoc('lastSavedSvr', action.payload)(state);
    }
    case IS_DRAFT : {
      return R.assoc('isDraft', action.payload)(state);
    }
    case ADD_DOCUMENT : {
      const payload       = R.propOr({}, 'payload', action);
      const documentsLens = R.lensPath(['svrForm', 'content', 'documents']);
      const documents     = R.view(documentsLens, state);

      return R.set(
        documentsLens,
        R.concat(documents, [payload]),
        state,
      );
    }
    case `${REMOVE_DOCUMENT}_SUCCESS` : {
      const fileId = R.path(['meta', 'previousAction', 'payload', 'id'])(action);

      const documentsLens = R.lensPath(['svrForm', 'content', 'documents']);
      const documents     = R.view(documentsLens, state);

      return R.set(
        documentsLens,
        R.reject(R.propEq('id', fileId), documents),
        state,
      );
    }
    case `${FETCH_TAGS_BY_STRING}_SUCCESS` : {
      return R.assoc('searchedTags', R.pathOr([], ['payload', 'data', 'data'], action), state);
    }
    case RESET_SEARCHED_TAGS : {
      return R.assoc('searchedTags', [], state);
    }
    default: {
      return state;
    }
  }
};

export const getSelectedNode = state => R.pathOr({}, ['builder', 'selectedNode'], state);
export const getSvrForm      = state => R.path([STATE_KEY, 'svrForm'], state);
export const getSvrSections  = (state, sectionGroup) => R.pathOr(
  [],
  [STATE_KEY, 'svrForm', 'content', 'structure', SECTIONS_GROUPS[sectionGroup]],
  state,
);

export const getDocuments = state => R.pathOr(
  [],
  [STATE_KEY, 'svrForm', 'content', 'documents'],
  state,
);

export const getSearchedTags     = state => R.path([STATE_KEY, 'searchedTags'], state);
export const getBeforeTypePhotos = state => R.compose(
  R.flatten,
  R.map(getPhotoQuestionsByBeforeType(state)),
  R.path([STATE_KEY, 'svrForm', 'content', 'structure'])
)(state);
export const getBeforeTypeVideos = state => R.compose(
  R.flatten,
  R.map(getVideoQuestionsByBeforeType(state)),
  R.path([STATE_KEY, 'svrForm', 'content', 'structure'])
)(state);


const _getNested = R.curry((accum, q) => {
  accum = R.append(R.pick(["uuid", "canonicalKey", "type", "meta"], q), accum);
  if(!R.has('options', q) || !R.length(R.prop('options', q))) {
    return accum;
  }

  return R.compose(
    R.map(q => nestedQuestions(R.prop("conditionalFork", q), accum)),
    R.prop("options")
  )(q);
});
const nestedQuestions = R.curry((arr, accum = []) => R.ifElse(R.isEmpty, R.always(accum), R.map(_getNested(accum)))(arr));

export const getCallOutQuestions = state => R.compose(
    R.uniqBy(R.prop('uuid')),
    R.filter(R.pathEq(['meta', 'isCallOut'], true)),
    R.flatten,
    R.map(nestedQuestions),
    R.map(R.flatten),
    R.map(R.pluck('content')),
    R.path([STATE_KEY, 'svrForm', 'content', 'structure'])
  )(state);

export default BuilderReducer;
