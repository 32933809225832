import React     from 'react';
import PropTypes from 'prop-types';
import Modal     from '../../components/Modal/Modal';

const DeletePhotoModal = ({ onApprove, onCancel }) => (
  <Modal
    onApprove={onApprove}
    onCancel={onCancel}
  >
    Are you sure you want to delete this photo?
  </Modal>
);

DeletePhotoModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
};

export default DeletePhotoModal;
