import React     from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/Modal';

const DuplicateElementModal = ({ onApprove, onCancel }) => (
  <Modal
    onApprove={onApprove}
    onCancel={onCancel}
  >
    Are you sure?
  </Modal>
);

DuplicateElementModal.propTypes = {
  onApprove : PropTypes.func.isRequired,
  onCancel  : PropTypes.func.isRequired,
};

export default DuplicateElementModal;
