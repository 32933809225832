import * as R from 'ramda';

import {
  SET_CURRENT_SAVED_DASHBOARD,
  FETCH_ALL_ERROR,
  FETCH_ALL_SUCCESS,
  UPDATE
} from './SavedDashboardsActions';

export const STATE_KEY = 'savedDashboards';

export const getSavedDashboards       = R.path([STATE_KEY, 'data']);
export const getCurrentSavedDashboard = R.path([STATE_KEY, 'currentSavedDashboard']);

const windowHasLocalStorage = R.compose(
  R.is(Function),
  R.pathOr(null, ['localStorage', 'setItem']),
  R.defaultTo({})
)(window);

const setInitialCurrentSavedDashboard = () => {
  if (windowHasLocalStorage) {
    try {
      return JSON.parse(window.localStorage.getItem('currentSavedDashboard')) || {};
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
};

const initialState = {
  currentSavedDashboard : setInitialCurrentSavedDashboard(),
  data                  : []
};

const SavedDashboardsReducer = (state = initialState, action) => {
  switch (action.type) {

    case SET_CURRENT_SAVED_DASHBOARD: {
      if (action.payload) {
        if (windowHasLocalStorage) {
          try {
            window.localStorage.setItem('currentSavedDashboard', JSON.stringify(action.payload));
            const currentSavedDashboard =  JSON.parse(window.localStorage.getItem('currentSavedDashboard')) || {}
            window.localStorage.setItem('dashboardId', currentSavedDashboard.id);
          } catch (e) {
            return state;
          }
        }

        return R.assoc('currentSavedDashboard', action.payload, state);
      } else {
        return state;
      }

    }

    case FETCH_ALL_ERROR : {
      // TODO ADD APPROPRIATE HANDLING
      return state;
    }

    case FETCH_ALL_SUCCESS : {
      let savedDashboards = R.pathOr([], ['payload', 'data', 'data'], action);
      if (savedDashboards.err) {
        savedDashboards = [];
      }
      return R.assoc('data', savedDashboards, state);
    }

    case `${UPDATE}_SUCCESS`: {
      const savedDashboard = R.pathOr({}, ['payload', 'data', 'data'], action);

      return R.compose(
        R.objOf('data'),
        R.prepend(savedDashboard),
        R.reject(R.propEq('id', savedDashboard.id)),
        R.prop('data')
      )(state);
    }

    default : {
      return state;
    }
  }
};

export default SavedDashboardsReducer;
