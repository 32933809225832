import * as R      from 'ramda';
import moment      from 'moment';
import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';

import {
  compose, defaultProps,
  lifecycle,
  setPropTypes,
  withHandlers,
  withProps,
  withStateHandlers
} from 'recompose';

import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line
} from 'recharts';

import { Typography } from '@material-ui/core';

import WidgetContent from './WidgetContent';
import WidgetHeader  from './WidgetHeader';

import { colors }               from '../../components/Theme';
import withUser                 from '../../modules/auth';
import withClient               from '../../modules/client';
import { fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';

const randomColor = () => '#' + Math.random().toString(16).slice(2, 8);

const LineChartWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onEditClick,
    onMoveClick,
    onDownloadClick,
    onRef,
    width,
    isShowingIcons,
    isDownloadContainer,
    containerHeight,
    containerWidth
  }) => (
  <div ref={onRef}>
    <div style={{ backgroundColor: 'white' }}>
      <WidgetHeader
        title           = {title}
        description     = {description}
        onTitleClick    = {onTitleClick}
        onEditClick     = {onEditClick}
        onMoveClick     = {onMoveClick}
        onDownloadClick = {onDownloadClick}
        isShowingIcons  = {isShowingIcons}
        isDownload
      />
      <WidgetContent containerHeight={containerHeight}>
        {widgetContent ? (
          <LineChart
            width  = {isDownloadContainer ? containerWidth : width}
            height = {isDownloadContainer ? containerHeight : 270}
            data   = {widgetContent.chartData}
            margin = {{ top : 5, right : 0, left : -20, bottom : 5 }}
          >

            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {!isDownloadContainer && <Tooltip content={props => (
                <div style={{ backgroundColor : '#FFF', border : '1px solid #CCC'}}>
                  <ul style={{ listStyle : 'none', margin : 0, padding : 0 }}>
                    {
                      (props.payload || []).map((v,i) => (
                        <li key={i} style={{ margin : '5px'}}>
                          <span style={{ color : colors[i] || randomColor()}}>{v.name || 'N/A'}</span>: {v.value}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              )}/>
            }
            <Legend
              align="left"
              wrapperStyle={{
                paddingRight: isDownloadContainer ? '100px' : '0px'
              }}
            />
            {
              widgetContent.dataPoints.map((v,i) => (
                <Line
                  type    = "monotone"
                  key     = {v}
                  dataKey = {v}
                  stroke  = {colors[i] || randomColor()}
                />
              ))
            }
          </LineChart>
        ) : (
          <Typography paragraph={true} variant="body2">
            No answers...
          </Typography>
        )}
      </WidgetContent>
    </div>
  </div>
);

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload) => dispatch(fetchQuestionAnswers(namespace, payload)),
});

const mapStateToProps = () => ({});

const mapAnswersToQuestions = (answers={}) => storeVisit => {
  const matchingAnswers = answers[storeVisit.id] || [];
  return R.assoc('answers', matchingAnswers)(storeVisit);
};

const maybeParseJson = o => R.tryCatch(JSON.parse, R.always(o))(o);

const countAnswers = R.compose(
  R.countBy(R.identity),
  R.flatten,
  R.map(maybeParseJson),
  R.chain(R.pluck('answerValue')),
  R.pluck('answers')
);

const mergeName = R.compose(
  R.mergeAll,
  R.over(R.lensIndex(0), R.objOf('name'))
);

const getDataPoints = R.compose(
  R.objOf('dataPoints'),
  R.without('name'),
  R.uniq,
  R.pluck(0),
  R.sort(R.descend(R.prop(1))),
  R.chain(R.toPairs)
);

const groupBySortableDateTickString = R.tryCatch(
  R.groupBy(
    R.compose(
      t => moment(t).format('M-MMM'),
      R.prop('timestamp')
    )
  ),
  R.always({})
);

const decorateSortableDateTickString = R.map(
  R.over(R.lensProp('name'), R.compose(
    R.last,
    R.split('-')
  ))
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withStateHandlers(
  {},
  {
    setWidth    : () => width => ({ width }),
  }),
  withHandlers(() => {
    let chart = null;

    return {
      onRef         : ({ setContainerSizes, setDownloadWidgetRef }) => ref => {
        chart = ref;

        setContainerSizes && setContainerSizes(ref);
        setDownloadWidgetRef && setDownloadWidgetRef(ref);
      },
      setDimensions : ({ setWidth }) => () => {
        if (chart && R.is(Function, chart.getBoundingClientRect)) {
          setWidth(chart.getBoundingClientRect().width * 0.9);
        }
      },
    };
  }),
  withProps(({ widgetData : { description, filteredWidgetData, title, metaJson }, answers }) => ({
    description,
    title,
    metaJson,
    widgetContent : R.compose(
      o => ({ ...o, ...getDataPoints(o.chartData)}),
      R.objOf('chartData'),
      decorateSortableDateTickString,
      R.sortBy(R.prop('name')),
      R.map(mergeName),
      R.toPairs,
      R.map(countAnswers),
      groupBySortableDateTickString,
      R.filter(R.compose(R.length, R.prop('answers'))),
      R.map(mapAnswersToQuestions(answers))
    )(filteredWidgetData)
  })),
  defaultProps({
    width          : 250,
    isShowingIcons : true
  }),
  setPropTypes({
    description     : PropTypes.string,
    title           : PropTypes.string,
    widgetData      : PropTypes.object.isRequired,
    onTitleClick    : PropTypes.func,
    onEditClick     : PropTypes.func,
    onMoveClick     : PropTypes.func,
    onDownloadClick : PropTypes.func,
    widgetContent   : PropTypes.object
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.isDownloadContainer) {
        const questions = this.props.widgetData.formQuestionCanonicalKeys || [];

        this.props.fetchQuestionAnswers(this.props.widgetData.metaJson.namespace, questions)
          .then(res => {
            const answers = R.pathOr([], ['payload', 'data', 'data'], res);
            this.props.onAnswers(answers);

            window.addEventListener('resize', this.props.setDimensions);
            this.props.setDimensions();
          });
      }
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.setDimensions);
    }
  })
)(LineChartWidgetContainer);
