import * as R from 'ramda';

import {
  FETCH_FORMS,
  FETCH_ALL_SUCCESS,
  FETCH,
  FETCH_IDS_SUCCESS,
  SET_QUESTIONS,
  FETCH_QUESTION_ANSWERS,
  RESET_FORMS,
} from './SavedWidgetsActions';

import getQuestionsTitle from './components/utils/getQuestionsTitle';

import { getCurrentClient }    from '../client/ClientReducer';

export const STATE_KEY = 'widgets';

const initialState = {
  data            : {},
  forms           : {},
  questions       : [],
  questionAnswers : {}
};

const idKeyAsProp = (a, b) => R.assoc(b.id, b, a);

const SavedWidgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_SUCCESS: {
      return R.compose(
        R.assoc('data', R.__, state),
        R.reduce(idKeyAsProp, {}),
        R.when(R.has('err'), R.always([])),
        R.pathOr([], ['payload', 'data', 'data'])
      )(action);
    }
    case FETCH_IDS_SUCCESS: {
      return R.compose(
        R.assoc('data', R.__, state),
        R.indexBy(R.prop('id')),
        R.pathOr([], ['payload', 'data', 'data'])
      )(action);
    }
    case `${FETCH}_SUCCESS`: {
      const widget = R.pathOr({}, ['payload', 'data', 'data'], action);
      return R.assocPath(['data', widget.id], widget, state);
    }
    case `${SET_QUESTIONS}`       : {
      return R.assocPath([action.payload.namespace, 'questions'], action.payload.data, state);
    }
    case `${FETCH_FORMS}_SUCCESS` : {
      const data      = R.pathOr([], ['payload', 'data', 'data'])(action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);
      return R.assocPath([namespace, 'forms'], data, state);
    }

    case `${FETCH_QUESTION_ANSWERS}_SUCCESS`: {
      const answers   = R.pathOr([], ['payload', 'data', 'data'], action),
            namespace = R.path(['meta', 'previousAction', 'payload', 'namespace'])(action);

      return answers.length > 0
        ? R.assocPath(
            [namespace, 'questionAnswers', answers[0].questionCanonicalKey],
            answers,
            state
          )
        : state;
    }
    case RESET_FORMS: {
      const namespace = R.path(['payload', 'namespace'])(action);
      return R.assocPath([namespace, 'forms'], {}, state);
    }
    default: {
      return state;
    }
  }
};

export const getSavedWidgets = state => {
  const { clientId } = getCurrentClient(state);

  const maybeFilterByClient = arr => clientId ? R.filter(R.propEq('tenancyClientId', clientId))(arr) : arr;

  return maybeFilterByClient(
    Object.keys(R.path([STATE_KEY, 'data'], state)).map(key =>
      R.path([STATE_KEY, 'data', key], state)
    )
  );
};

export const getForms = (state, namespace=STATE_KEY) => R.values(
  R.pathOr([], [STATE_KEY, namespace, 'forms'], state)
) || [];


export const getQuestions = (state, namespace) => {
  const questions = R.pathOr([], [STATE_KEY, namespace, 'questions'], state);
  const forms     = getForms(state, namespace);

  return getQuestionsTitle(questions, forms);
};

export const getQuestionAnswers = (state, namespace) =>
  R.pathOr({}, [STATE_KEY, namespace, 'questionAnswers'], state);

export default SavedWidgetsReducer;
