import * as R from 'ramda';

const mergeFlagMotiveQuestions = (allowedMotiveQuestions = [], questions) => {
    const newQuestions = [];
    questions.forEach(q => {
        newQuestions.push(q);
        if(q.type === "photo-input" && !!R.find(R.equals(`${q.uuid}-flag-motive`), allowedMotiveQuestions)){
          newQuestions.push({...q, type: "single-line-input", uuid: `${q.uuid}-flag-motive`, canonicalKey: `${q.canonicalKey}-flag-motive`, title: `Is "${q.title}" photo flagged?`});
        }
    });
    return newQuestions;
};

export default R.curry(mergeFlagMotiveQuestions);
