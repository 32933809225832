import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';
import { withStyles, withTheme } from '@material-ui/core/styles';

import styled from 'styled-components';

const Container = styled.div`
  width: ${({ containerWidth }) => containerWidth ? `${containerWidth}px` : '100%'};
  ${({ containerHeight }) => containerHeight && `height: ${containerHeight}px`};
  ${({ containerHeight }) => containerHeight && `position: absolute !important`};
  ${({ containerHeight }) => containerHeight && `opacity: 0 !important`};
`;

const styles = theme => ({
  widgetContainer : {
    display       : 'flex',
    flexDirection : 'column',
    position      : 'relative',
    marginBottom  : theme.custom.spacing.xl,
    background    : theme.custom.colors.whiteAlt,
    boxShadow     : theme.custom.shadows.blockShadow.regular,
    borderRadius  : theme.custom.borderRadius,
    overflow      : 'hidden'
  }
});

const WidgetContainer = ({ children, classes, containerWidth, containerHeight }) => (
  <Container
    className       = {classes.widgetContainer}
    containerWidth  = {containerWidth}
    containerHeight = {containerHeight}
  >
    {children}
  </Container>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    children : PropTypes.node,
    classes  : PropTypes.object.isRequired,
    theme    : PropTypes.object.isRequired
  })
)(WidgetContainer);
