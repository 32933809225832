import React     from 'react';
import PropTypes from 'prop-types';

import Modal         from '../../components/Modal/Modal';
import ModalWithList from '../../components/Modal/ModalWithList';

const ImpossibleEditSvrsModal = ({ withoutCancel, onApprove, currentUserSvrResponses }) => currentUserSvrResponses ? (
  <ModalWithList
    onApprove={onApprove}
    withoutCancel={withoutCancel}
    onCancel={() => {}}
    list={currentUserSvrResponses}
  >
    You can not edit the status of an SVR you have submitted.
  </ModalWithList>
) : (
  <Modal
    onApprove={onApprove}
    withoutCancel={withoutCancel}
    onCancel={() => {}}
  >
    You can not edit the status of an SVR you have submitted.
  </Modal>
);

ImpossibleEditSvrsModal.propTypes = {
  withoutCancel : PropTypes.bool,
  onApprove     : PropTypes.func.isRequired,
};

ImpossibleEditSvrsModal.defaultProps = {
  withoutCancel: false,
};

export default ImpossibleEditSvrsModal;
