import React, { Component } from 'react';
import styled               from 'styled-components';
import * as R               from 'ramda';
import { connect }          from 'react-redux';

import Footer      from './modal-component/Footer';
import ModalHeader from './modal-component/Header';
import Video       from './modal-component/VideoContainer';

import {
  updateSvrResponse as _updateSvrResponse
} from '../../SvrResponsesActions';

class VideoReviewModal extends Component {
  state = {
    count                : 0,
    imagery              : [],
    initialCarouselVideo : this.props.initialCarouselVideo || null,
  };

  incrementCounter = () => {
    const { count, imagery } = this.state;
    const moreVideosToShow   = imagery.length - 1 > count;

    if (moreVideosToShow) {
      this.setState({ count : count + 1 });
    } else {
      this.setState({ count : 0 });
      this.props.openVideoReviewModal();
    }
  };

  decrementCounter = () => {
    const isNotTheBeginning = this.state.count > 0;

    if(isNotTheBeginning){
      this.setState({ count : this.state.count - 1 });
    }
  };

  onAction = (type, alreadyApplied, payload) => {
    let sync = null;
    if(R.equals(type, 'rotate')) {
      payload = R.assoc('rotate', (R.defaultTo(0, alreadyApplied) + 90) % 360, payload);
      sync = R.over(
        R.lensProp(`${type}Videos`), 
        R.ifElse(
          R.both(R.identity, R.find(R.propEq('uuid', payload.uuid))),
          R.map(R.when(R.propEq('uuid', payload.uuid), R.assoc('rotate', payload.rotate))),
          R.tryCatch(R.append(payload), R.always([payload]))
        )
      );
    } else {
      sync = alreadyApplied ?
        R.over(R.lensProp(`${type}Videos`), R.reject(R.propEq('uuid', payload.uuid))) :
        R.over(R.lensProp(`${type}Videos`), R.tryCatch(R.append(payload), R.always([payload])));
    }

    this.props.updateSvrResponse(
      this.props.namespace,
      this.props.svrResponse.id,
      { metaJson : R.compose(
          JSON.stringify,
          sync,
          R.prop('metaJson')
        )(this.props.svrResponse)
      }
    ).then(() => this.props.fetchVideoQuestions(this.props.namespace, R.values(this.props.formAnswers))(this.props.svrResponse.id));

  };

  syncImagery(videos) {
    const {
            videoQuestions,
            formAnswers,
            svrResponse,
            blockedVideos
          } = this.props;

    const imagery = R.compose(
      R.filter(q => !R.contains(q.document.uuid, blockedVideos)),
      R.map(q => R.compose(R.assoc('flag',  R.__, q), Boolean, R.find(R.eqProps('uuid', q.document)), R.pathOr([], ['metaJson', 'flagVideos']))(svrResponse)),
      R.map(q => R.compose(R.assoc('star',  R.__, q), Boolean, R.find(R.eqProps('uuid', q.document)), R.pathOr([], ['metaJson', 'starVideos']))(svrResponse)),
      R.map(q => R.compose(R.assoc('block', R.__, q), Boolean, R.find(R.eqProps('uuid', q.document)), R.pathOr([], ['metaJson', 'blockVideos']))(svrResponse)),
      R.map(q => R.compose(R.assoc('rotate', R.__, q), Number, R.compose(R.defaultTo(0), R.prop('rotate'), R.find(R.eqProps('uuid', q.document))), R.pathOr([], ['metaJson', 'rotateVideos']))(svrResponse)),
      R.reject(q => R.isNil(q.document)),
      R.map(q => R.assoc('document', videos.find(d => d.uuid === q.answer.answerValue))(q)),
      R.map(q => R.assoc('answer', formAnswers[q.uuid])(q))
    )(videoQuestions);


    if (this.state.initialCarouselVideo) {
      const count = R.findIndex(R.pathEq(['document', 'uuid'], this.state.initialCarouselVideo.uuid))(imagery);

      this.setState({
        initialCarouselVideo : null,
        count,
        imagery,
      });
    } else {
      this.setState({ imagery });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.syncImagery(nextProps.videos);
  }

  componentDidMount() {
    this.syncImagery(this.props.videos);
  }

  render () {
    const {
      videos,
      videoQuestions,
      formAnswers,
      toggleRejectVideoQuestion,
      rejectedQuestions,
      svrResponse,
      isClientUser,
      updateDocument,
      reviewMode,
      namespace
    } = this.props;

    const imagery          = this.state.imagery;
    const carouselPosition = this.state.count;
    const haveDataToRender = videos.length && R.keys(formAnswers).length && videoQuestions.length;
    const meta             = R.pathOr({}, ['props', 'svrResponse', 'metaJson'], this);
    const currentVideo     = imagery[carouselPosition];
    const blockVideos      = R.pathOr([], ['metaJson', 'blockVideos'])(svrResponse);
    const isEmptyAnswer    = R.isEmpty(R.pathOr('', ['answer', 'answerValue'], currentVideo));

    if (!haveDataToRender || !currentVideo) {
      return null;
    }

    const isBeforeVideo = R.pathEq(['meta', 'videoType'], 'before-video')(currentVideo),
          isAfterVideo  = R.pathEq(['meta', 'videoType'], 'after-video')(currentVideo);

    const getPairedAfter  = p => R.find(R.pathEq(['meta', 'pairedQuestionUuid'], p.uuid))(imagery),
          getPairedBefore = p => R.find(R.propEq('uuid', R.pathOr('', ['meta', 'pairedQuestionUuid'], p)))(imagery);

    const actOnCurrent = action => alreadyApplied => this.onAction(action, alreadyApplied, { uuid : currentVideo.document.uuid, storeVisitId : svrResponse.id }),
          actOnBefore  = action => alreadyApplied => this.onAction(action, alreadyApplied, { uuid : R.pathOr('', ['document', 'uuid'], getPairedBefore(currentVideo)), storeVisitId : svrResponse.id }),
          actOnAfter   = action => alreadyApplied => this.onAction(action, alreadyApplied, { uuid : R.pathOr('', ['document', 'uuid'], getPairedAfter(currentVideo)), storeVisitId : svrResponse.id  });

    const showAppropriateModal = () => {
      if (isEmptyAnswer) {
        return null;
      }
      if (isBeforeVideo) {
        return (
          <VideoReviewModal.Center>
            <Video
              onFlag                    = { actOnCurrent('flag')  }
              onStar                    = { actOnCurrent('star')  }
              onBlock                   = { actOnCurrent('block') }
              onRotate                  = { actOnCurrent('rotate') }
              video                     = { currentVideo }
              toggleRejectVideoQuestion = { toggleRejectVideoQuestion}
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockVideos               = { blockVideos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
            <Video
              onFlag                    = { actOnAfter('flag')  }
              onStar                    = { actOnAfter('star')  }
              onBlock                   = { actOnAfter('block') }
              onRotate                  = { actOnAfter('rotate') }
              video                     = { getPairedAfter(currentVideo) || {} }
              toggleRejectVideoQuestion = { toggleRejectVideoQuestion }
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockVideos               = { blockVideos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
          </VideoReviewModal.Center>
        );
      }
      if (isAfterVideo) {
        return (
          <VideoReviewModal.Center>
            <Video
              onFlag                    = { actOnBefore('flag')  }
              onStar                    = { actOnBefore('star')  }
              onBlock                   = { actOnBefore('block') }
              onRotate                  = { actOnBefore('rotate') }
              video                     = { getPairedBefore(currentVideo) || {} }
              toggleRejectVideoQuestion = { toggleRejectVideoQuestion }
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockVideos               = { blockVideos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
            <Video
              onFlag                    = { actOnCurrent('flag')  }
              onStar                    = { actOnCurrent('star')  }
              onBlock                   = { actOnCurrent('block') }
              onRotate                  = { actOnCurrent('rotate') }
              video                     = { currentVideo }
              toggleRejectVideoQuestion = { toggleRejectVideoQuestion }
              rejectedQuestions         = { rejectedQuestions }
              isClientUser              = { isClientUser }
              blockVideos               = { blockVideos }
              updateDocument            = { updateDocument }
              reviewMode                = { reviewMode }
              namespace                 = { namespace }
            />
          </VideoReviewModal.Center>
        );
      }
      return (
        <VideoReviewModal.Center>
          <Video
            onFlag                    = { actOnCurrent('flag')  }
            onStar                    = { actOnCurrent('star') }
            onBlock                   = { actOnCurrent('block') }
            onRotate                  = { actOnCurrent('rotate') }
            video                     = { currentVideo }
            toggleRejectVideoQuestion = { toggleRejectVideoQuestion }
            rejectedQuestions         = { rejectedQuestions }
            isClientUser              = { isClientUser }
            blockVideos               = { blockVideos }
            updateDocument            = { updateDocument }
            reviewMode                = { reviewMode }
            namespace                 = { namespace }
          />
        </VideoReviewModal.Center>
      );
    };

    return (
      <VideoReviewModal.Outer showWhen={this.props.showWhen}>
        <VideoReviewModal.Listener onClick={this.props.openVideoReviewModal} />
        <VideoReviewModal.Container>
          <ModalHeader nextVideo={this.incrementCounter} previousVideo={this.decrementCounter} />
           {
             !R.isEmpty(imagery) && showAppropriateModal()
           }
          <Footer
            retailer   = { meta.retailer }
            location   = {`${meta.address1}, ${meta.city}, ${meta.zip}`}
            doorNumber = { `${meta.doorNumber}` }
          />
        </VideoReviewModal.Container>
      </VideoReviewModal.Outer>
    );
  }
}

VideoReviewModal.Outer = styled.div`
  visibility  : ${({ showWhen }) => showWhen ? 'visible' : 'hidden'};
  overflow    : auto;
  display     : flex;
  align-items : center;
  position    : fixed;
  top         : 0;
  left        : 0;
  height      : 100%;
  width       : 100%;
  z-index     : 10000;
`;

VideoReviewModal.Listener = styled.div`
  top        : 0;
  bottom     : 0;
  right      : 0;
  left       : 0;
  width      : 100%;
  position   : absolute;
  background : rgba(112, 112, 112, 0.66);
`;

VideoReviewModal.Container = styled.div`
  width         : 95%;
  background    : #fff;
  margin        : 0 auto;
  border-radius : 3px;
  overflow      : visible;
  z-index       : 1;
`;

VideoReviewModal.Center = styled.div`
  display         : flex;
  align-items     : flex-start;
  justify-content : space-around;
`;


const mapDispatchToProps = dispatch => ({
  updateSvrResponse  : (namespace, svrResponseId, payload) => dispatch(_updateSvrResponse(namespace, svrResponseId, payload))
});

export default connect( null, mapDispatchToProps )(VideoReviewModal);
