import React from 'react';
import PropTypes from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles, withTheme } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';

const styles = theme => ({
  innerToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit
    }px`,
  },
});

const InnerToolbar = ({ children, classes }) => (
  <Hidden only={['xs', 'sm']}>
    <div className={classes.innerToolbar}>{children}</div>
  </Hidden>
);

export default compose(
  withTheme(),
  withStyles(styles),
  setPropTypes({
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
  })
)(InnerToolbar);
