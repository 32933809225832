import * as R from 'ramda';

const namespace = 'ROLL_UPS';

export const FETCH_ALL              = `${namespace}/FETCH_ALL`;
export const FETCH_ALL_ERROR        = `${namespace}/FETCH_ALL_ERROR`;
export const FETCH_ALL_SUCCESS      = `${namespace}/FETCH_ALL_SUCCESS`;
export const FETCH                  = `${namespace}/FETCH`;
export const CREATE                 = `${namespace}/CREATE`;
export const EDIT                   = `${namespace}/EDIT`;
export const UPDATE                 = `${namespace}/UPDATE`;
export const FETCH_FORMS            = `${namespace}/FETCH_FORMS`;
export const RESET_FORMS            = `${namespace}/RESET_FORMS`;
export const FETCH_QUESTION_ANSWERS = `${namespace}/FETCH_QUESTION_ANSWERS`;
export const SET_QUESTIONS          = `${namespace}/SET_QUESTIONS`;

export const fetchSavedRollups = useTenancy => dispatch => dispatch({
  types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
  payload : {
    request : {
      method : 'GET',
      url    : useTenancy ? '/api/v2/savedRollup' : '/api/v2/savedRollup/all'
    }
  }
});

export const fetchSavedRollup = payload => ({
  type    : FETCH,
  payload : {
    request : {
      method : 'GET',
      url    : `/api/v2/savedRollup/id/${payload}`
    }
  }
});

export const fetchQuestionAnswers = (namespace, questionCanonicalKeys, svrResponses) => ({
  type: FETCH_QUESTION_ANSWERS,
  payload: {
    namespace,
    request: {
      method : 'POST',
      timeout: 1800000,
      url    : `/api/v2/storeVisitFormAnswer/questionCanonicalKeys`,
      data   : { questionCanonicalKeys, svrResponses }
    }
  }
});

export const createSavedRollup = data => ({
  type: CREATE,
  payload: {
    request: {
      method : 'POST',
      url    : `/api/v2/savedRollup`,
      data
    }
  }
});

export const editSavedRollup = (data, id) => ({
  type: EDIT,
  payload: {
    request: {
      method : 'PUT',
      url    : `/api/v2/savedRollup/id/${id}`,
      data
    }
  }
});

export const fetchFormsByIds = (namespace, payload=[0], params={ status: [1] }) => ({
  type    : FETCH_FORMS,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/form/ids/${R.compose(R.join(','), R.filter(R.identity), R.uniq)(payload)}`,
      params
    }
  }
});

export const setQuestions = (namespace, data) => ({
  type    : SET_QUESTIONS,
  payload : { data, namespace }
});

export const resetForms = namespace => ({
  type: RESET_FORMS,
  payload : { namespace }
});

export const updateSavedRollUp = (savedRollUpId, data) => ({
  type    : UPDATE,
  payload : {
    request : {
      method : 'PUT',
      url    : `/api/v2/savedRollup/id/${savedRollUpId}`,
      data
    }
  }
});
