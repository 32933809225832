import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import { makeDecoratedImageUrl, getRotation } from '../../utils/image';

const styles = () => ({
  photoWidgetContent : {
    position  : 'relative',
    flex      : 'auto',
    // minHeight : '16.7em'
    minHeight : '430px;',
    maxHeight : '430px;'
  },
  imgContainer       : {
    display            : 'flex',
    position           : 'absolute',
    top                : '0.3em',
    left               : '0',
    width              : '100%',
    height             : '100%',
    objectFit          : 'contain',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    cursor             : 'pointer',
    backgroundPosition : 'center'
  },
  img                : {
    display   : 'block',
    width     : '100%',
    height    : '100%',
    objectFit : 'contain'
  },
  imagesContainer : {
    backgroundPosition : 'center',
    backgroundSize     : 'cover',
    cursor             : 'pointer',
    width              : '50%'
  },
  center            : {
    position        : 'relative',
    backgroundColor : '#fff',
    flex            : 'auto'
  },
  imgContainerLeft  : {
    position         : 'absolute',
    top              : '0',
    left             : '0',
    width            : '50%',
    height           : '100%',
    minHeight        : '100px',
    backgroundSize   : 'contain',
    backgroundRepeat : 'no-repeat',
    backgroundPosition : 'center'
  },
  imgContainerRight : {
    position         : 'absolute',
    top              : '0',
    left             : '50%',
    width            : '50%',
    height           : '100%',
    minHeight        : '100px',
    backgroundSize   : 'contain',
    backgroundRepeat : 'no-repeat',
    backgroundPosition : 'center'
  },
  imgContainerCenter : {
    position           : 'absolute',
    width              : '100%',
    height             : '100%',
    minHeight          : '100px',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    backgroundPosition : 'center'
  },
});

const CLOUDFLARE_IMAGE_WIDTH = 800;

const PhotoWidgetContent = ({ classes, imgUrl, toggleDetailView, imgUrlBefore, imgUrlAfter }) => (
  <div className={classes.photoWidgetContent}>
    <div className={classes.imgContainer} onClick={toggleDetailView}>
      {!imgUrlAfter && !imgUrlBefore && (
        <div className={classes.center}>
          <div className={classes.imgContainerCenter} style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(imgUrl), CLOUDFLARE_IMAGE_WIDTH)(imgUrl)}')` }} />
        </div>
      )}
      {imgUrlAfter && (
        <div className={classes.center}>
          <div className={classes.imgContainerLeft}  style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(imgUrl), CLOUDFLARE_IMAGE_WIDTH)(imgUrl)}')` }} />
          <div className={classes.imgContainerRight} style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(imgUrlAfter), CLOUDFLARE_IMAGE_WIDTH)(imgUrlAfter)}')` }} />
        </div>
      )}
      {imgUrlBefore && (
        <div className={classes.center}>
          <div className={classes.imgContainerLeft}  style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(imgUrlBefore), CLOUDFLARE_IMAGE_WIDTH)(imgUrlBefore)}')` }} />
          <div className={classes.imgContainerRight} style={{ backgroundImage : `url('${makeDecoratedImageUrl(getRotation(imgUrl), CLOUDFLARE_IMAGE_WIDTH)(imgUrl)}')` }} />
        </div>
      )}
    </div>
  </div>
);

export default compose(
  withStyles(styles),
  setPropTypes({
    classes          : PropTypes.object.isRequired,
    imgUrl           : PropTypes.string,
    toggleDetailView : PropTypes.func.isRequired
  })
)(PhotoWidgetContent);
