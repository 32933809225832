const theme = {
  header : {
    background : 'rgb(243, 33, 46)',
    color      : '#fff'
  },
  main   : {
    red   : 'rgb(243, 33, 46)',
    black : 'rgb(35, 31, 32)',
    white : 'rgb(240, 241, 235)'
  }
};

export const colors = [
  '#F3212E',
  '#b9b9ba',
  '#c40505',
  '#000000',
  //'#770101',
  '#E56399',
  //'#fc8395',
  //'#eac991',
  '#F26430',
  //'#ffcc33',
  '#6761A8',
  //'#afd3fe',
  '#009B72',
  '#000066',
  //'#e99040',
  '#009DDC',
  '#393d44',
  '#757784',
  '#996666',
  '#350008',
  '#fc8395'
];

export default theme;
