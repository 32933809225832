import React                 from 'react';
import PropTypes             from 'prop-types';
import styled                from 'styled-components';
import { withStateHandlers } from 'recompose';

import Downshift from 'downshift';

import { MenuItem, Modal, Paper, TextField } from '@material-ui/core';

const MODAL_HEIGHT = 130;

const ModalWrapper = styled.div`
  position         : absolute;
  width            : 50%;
  height           : ${MODAL_HEIGHT}px;
  top              : 50%;
  left             : 50%;
  transform        : translate(-50%, -50%);
  background-color : #fff;
  border-radius    : 3px;
  outline          : none;
`;

const Content = styled.div`
  display    : flex;
  padding    : 20px;
  box-sizing : border-box;
`;

const Title = styled.p`
  font-weight : bold;
  font-size   : 13px;
`;

const renderInput = inputProps => {
  const { InputProps, ref, ...other } = inputProps;

  return (
    <div>
      <Title>COMMON QUESTION</Title>
      <TextField
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...other}
      />
    </div>
  );
};

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  handleSelectQuestion,
}) => {
  const isHighlighted = highlightedIndex === index;

  return (
    <MenuItem
      {...itemProps}
      onClick={() => handleSelectQuestion(suggestion)}
      key={suggestion.canonicalKey}
      selected={isHighlighted}
      component="div"
    >
      {suggestion.title}
    </MenuItem>
  );
};
renderSuggestion.propTypes = {
  highlightedIndex     : PropTypes.number,
  index                : PropTypes.number,
  itemProps            : PropTypes.object,
  selectedItem         : PropTypes.string,
  suggestion           : PropTypes.shape({ title: PropTypes.string }).isRequired,
  handleSelectQuestion : PropTypes.func,
};

const getSuggestions = (commonQuestions, inputValue) => {
  let count = 0;

  return commonQuestions.filter(question => {
    const keep =
      (!inputValue || question.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) &&
      count < 5;

    if (keep) {
      count += 1;
    }

    return keep;
  });
};

const CommonQuestionModal = ({
  open,
  close,
  handleSelectQuestion,
  commonQuestions,
}) => (
  <Modal
    aria-labelledby="Create Common Question"
    aria-describedby="Create Common Question"
    open={open}
    onClose={close}
  >
    <ModalWrapper>
      <Content>
        <Downshift>
          {({ getInputProps, getItemProps, isOpen, inputValue, highlightedIndex }) => (
            <div style={{ width: '100%' }}>
              {renderInput({
                fullWidth  : true,
                InputProps : getInputProps({
                  placeholder : 'Search...',
                  id          : 'integration-downshift',
                }),
              })}
              {isOpen ? (
                <Paper square>
                  {getSuggestions(commonQuestions, inputValue).map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion.title }),
                      highlightedIndex,
                      handleSelectQuestion,
                    }),
                  )}
                </Paper>
              ) : null}
            </div>
          )}
        </Downshift>
      </Content>
    </ModalWrapper>
  </Modal>
);

CommonQuestionModal.propTypes = {
  onClose         : PropTypes.func.isRequired,
  selectQuestion  : PropTypes.func.isRequired,
  type            : PropTypes.string,
  open            : PropTypes.bool,
  commonQuestions : PropTypes.array,
};

CommonQuestionModal.defaultProps = {
  commonQuestions : [],
  type            : '',
  open            : false,
};

const addEditing = withStateHandlers(
  null,
  {
    handleSelectQuestion : (_, { selectQuestion }) => question => {
      selectQuestion({ ...question, isCommonQuestion: true });
      return { open: false };
    },
    close                : (_, { onClose }) => () => {
      onClose();
      return { open: false };
    },
  },
);

export default addEditing(CommonQuestionModal);
