import React     from 'react';
import PropTypes from 'prop-types';

import { Button, CircularProgress } from '@material-ui/core';

import Modal from '../../components/Modal/Modal';

const FileExportProgressModal = ({ fileExportLink, fileExportError, onApprove }) => (
  <Modal
    onCancel       = {onApprove}
    onApprove      = {onApprove}
    withoutApprove = {!fileExportError}
    style          = {{ margin : '0 200px', width : 'auto' }}
  >
    {
      fileExportError ? (
        <div>
          <p style={{ color : 'red' }}>Could not generate report file</p>
          <em>Errors have been logged on the server. Please notify support if this persists.</em>
        </div>
      ) : (
        <div style={{ flexDirection: 'row' }}>
          {
            !fileExportLink ? <p>Your report file is being generated, please wait.</p> : null
          }
          <div>
            { fileExportLink ? <p>Link: {fileExportLink}</p> : '' }
            <Button
              variant  = "flat"
              disabled = {!fileExportLink}
              style    = {{
                border          : '2px solid #DE898C',
                color           : '#F3212E',
                backgroundColor : 'transparent',
                borderRadius    : '5px',
                padding         : '8px',
                minWidth        : '120px',
                letterSpacing   : '1px',
                opacity         : fileExportLink ? '1' : '0.5'
              }}
              onClick  = {() => {
                window.open(fileExportLink);
                onApprove();
              }}
            >
              { fileExportLink ? 'Download File' : 'Creating report file...' }
            </Button>
            {!fileExportLink && (
              <CircularProgress
                size={24}
                style={{
                  position  : 'absolute',
                  left      : 'calc(50% - 1rem)',
                  marginTop : '.5rem'
                }}
              />
            )}
          </div>
        </div>
      )
    }

  </Modal>
);

FileExportProgressModal.propTypes = {
  onApprove       : PropTypes.func.isRequired,
  fileExportLink  : PropTypes.string,
  fileExportError : PropTypes.string
};

export default FileExportProgressModal;
