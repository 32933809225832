export const additionalcolumns = [
    'Department Type',
    'Visit Length',
    "Approved Date",
    'Approved by',
    'Work Classification',
    'Project Segment Name',
    'Location - City',
    'Location - State',
    'Location Zone',
    'Location Territory',
    'Event ID',
    'Visibility',
    'Hidden From Client',
    'Authorized by',
    'Project #',
    'PD',
    'TD',
    'Device OS',
    'Device OS Version',
    'Device Model',
    'App Version',
];