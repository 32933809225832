import * as R from 'ramda';

import { FETCH_DOCUMENTS } from '../svr-responses/SvrResponsesActions';

import recursivelyPluck from '../svr/utils/recursivelyPluck';

const namespace = 'WIDGETS';

export const FETCH_ALL              = `${namespace}/FETCH_ALL`;
export const FETCH_ALL_ERROR        = `${namespace}/FETCH_ALL_ERROR`;
export const FETCH_ALL_SUCCESS      = `${namespace}/FETCH_ALL_SUCCESS`;
export const FETCH                  = `${namespace}/FETCH`;
export const FETCH_IDS              = `${namespace}/FETCH_IDS`;
export const FETCH_IDS_SUCCESS      = `${namespace}/FETCH_IDS_SUCCESS`;
export const CREATE                 = `${namespace}/CREATE`;
export const EDIT                   = `${namespace}/EDIT`;
export const DELETE                 = `${namespace}/DELETE`;
export const ARCHIVE                = `${namespace}/ARCHIVE`;
export const ACTIVE                 = `${namespace}/ACTIVE`;
export const FETCH_FORMS            = `${namespace}/FETCH_FORMS`;
export const RESET_FORMS            = `${namespace}/RESET_FORMS`;
export const FETCH_QUESTION_ANSWERS = `${namespace}/FETCH_QUESTION_ANSWERS`;
export const SET_QUESTIONS          = `${namespace}/SET_QUESTIONS`;
export const FILTER_BY_ACTION       = `${namespace}/FILTER_BY_ACTION`;

export const fetchSavedWidgets = useTenancy => dispatch =>
  dispatch({
    types   : [FETCH_ALL, FETCH_ALL_SUCCESS, FETCH_ALL_ERROR],
    payload : {
      request : {
        method : 'GET',
        url    : useTenancy ? '/api/v2/savedWidget' : '/api/v2/savedWidget/all'
      }
    }
  });

export const fetchSavedWidget = payload => ({
  type    : FETCH,
  payload : {
    request : {
      method : 'GET',
      url    : `/api/v2/savedWidget/id/${payload}`
    }
  }
});

export const fetchSavedWidgetsByIds = ids => ({
  type    : FETCH_IDS,
  payload : {
    request : {
      method : 'POST',
      url    : '/api/v2/savedWidget/ids',
      data   : { ids }
    }
  }
});

export const fetchQuestionAnswers = (namespace, payload) => ({
  type    : FETCH_QUESTION_ANSWERS,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/storeVisitFormAnswer/questionCanonicalKey/${payload}`
    }
  }
});

export const createSavedWidget = data => ({
  type    : CREATE,
  payload : {
    request : {
      method : 'POST',
      url    : `/api/v2/savedWidget`,
      data
    }
  }
});

export const editSavedWidget = (data, id) => ({
  type    : EDIT,
  payload : {
    request : {
      method : 'PUT',
      url    : `/api/v2/savedWidget/id/${id}`,
      data
    }
  }
});

export const deleteSavedWidget = payload => ({
  type : DELETE,
  payload
});

export const archiveSavedWidget = payload => ({
  type : ARCHIVE,
  payload
});

export const activeSavedWidget = payload => ({
  type : ACTIVE,
  payload
});

export const fetchFormsByIds = (namespace, payload = [0], params = { status: [1] }) => ({
  type    : FETCH_FORMS,
  payload : {
    namespace,
    request : {
      method : 'GET',
      url    : `/api/v2/form/ids/${R.compose(
        R.join(','),
        R.filter(R.identity),
        R.uniq
      )(payload)}`,
      params
    }
  }
});

export const setQuestions = (namespace, data) => ({
  type: SET_QUESTIONS,
  payload : {
    namespace,
    ...data
  }
});

export const fetchPhotoQuestions = (namespace, forms, formAnswers) => dispatch => {
  const answers = R.compose(R.flatten, R.values)(formAnswers);

  let photoAnswers = [];

  R.map(form => {
    const data = R.pathOr({}, ['content', 'structure'])(form);

    const photoQuestions = R.compose(
      R.filter(R.propEq('type', 'photo-input')),
      R.flatten,
      R.map(recursivelyPluck('content')),
      R.flatten
    )(data);

    return R.map(({ uuid }) => {
      return R.compose(
        R.map(v => photoAnswers.push(v)),
        R.pluck('answerValue'),
        R.filter(R.propEq('questionUuid', uuid))
      )(answers);
    })(photoQuestions);
  })(forms);

  R.compose(
    uuids => {
      if (uuids.length) {
        dispatch({
          type    : FETCH_DOCUMENTS,
          payload : {
            namespace,
            request : {
              method : 'POST',
              url    : `/api/v2/document/uuids`,
              data   : { uuids }
            }
          }
        });
      }
    },
    R.filter(R.identity),
    R.uniq
  )(photoAnswers);
};

export const resetForms = namespace => ({
  type    : RESET_FORMS,
  payload : { namespace }
});
