import * as R from 'ramda';
import React, { Component } from 'react';
import { Paper, TextField, Button, Menu, MenuItem, Tooltip, Select, Checkbox, ListItemText } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Clear, Done, DateRange } from '@material-ui/icons';
import Downshift from 'downshift';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  isClientUser
} from '../../modules/client/ClientReducer';

import {
  Bottom,
  SearchContainer,
  StyledChip,
  ChipContainer
} from './styledComponents';

import ParameterMenu from './ParameterMenu';
import SearchDatePicker from './SearchDatePicker';

import { maybeDecorateColumn } from '../../utils/tableColumns';

import withUser from '../../modules/auth';
import withClient from '../../modules/client';

import { MINIMUM_FILTER_NUM_TO_LOAD } from '../../config/dataConfig';

import theme from '../../styles/theme';
import Modal from '../../components/Modal/PModal';
import moment from 'moment';

const getSortedFilterData = (item, inputValue, data) => () => R.unless(R.isEmpty, R.compose(
  R.sort((a, b) => R.ifElse(R.is(Number), () => a - b, () => a.localeCompare(b))(a)),
  R.reject(R.either(R.isNil, R.contains(R.__, item.data))),
  R.filter(i => !inputValue || R.test(new RegExp(`^${inputValue}`, 'gim'))(i)),
  R.uniq,
  R.map(v => maybeDecorateColumn(item.decorator)(R.path(item.path, v))),
))(data);

const getFilteredData = (item, inputValue, props) => R.compose(
  R.when(R.isEmpty, getSortedFilterData(item, inputValue, R.pathOr([], ['autoComplete'], props))),
  R.when(R.isEmpty, getSortedFilterData(item, inputValue, props.data)),
)(getSortedFilterData(item, inputValue, R.pathOr([], ['prefilledFilters', item.label], props))());

const styles = {
  menuPaper: {
    maxHeight: 400
  }
};

class SearchParamsList extends Component {

  state = {
    showSaveMenu: false,
    parameters: [],
    selectedFilterOption: this.props.filterList || {},
    filterSearchInputValue: '',
    saveMenuAnchorEl: null,
    savedViewFormData: {},
    savedRollupFormData: {},
    savedWidgetFormData: {},
    activeDatePickers: {},
    isReviewSvrResponsesPage: R.test(/svr-responses/i, window.location.pathname),
    isVisitDateInPage: true,
    showErrorModal: false,
    errorModalTitle: ''
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const dataIsDifferent = !R.equals(
      nextProps.filterList,
      this.props.filterList
    );
    if (dataIsDifferent) {
      this.setState({ selectedFilterOption: nextProps.filterList });
    }
  }

  handleShowSaveMenu = e => {
    this.setState({ saveMenuAnchorEl: e.currentTarget });
  };

  handleCloseSaveMenu = (then = () => { }) => {
    this.setState({ saveMenuAnchorEl: null }, then);
  };

  handleSelectParameter = tableColumn => () => {
    const { selectedFilterOption } = this.state;

    this.setState({
      selectedFilterOption: {
        ...selectedFilterOption,
        [tableColumn.label]: {
          pathStr: tableColumn.pathStr,
          path: tableColumn.path,
          label: tableColumn.label,
          decorator: tableColumn.decorator,
          data: [],
          exclude: tableColumn.exclude
        }
      }
    });
  };

  syncAndApplyFilters = () => {
    this.props.syncFilters(this.props.namespace, this.state.selectedFilterOption);
  };

  handleSelectOptionsValue = (label, asArray = false) => value => {
    const { selectedFilterOption } = this.state;

    const addFilterOptions = (arr = []) => v => R.compose(R.uniq, R.unless(() => asArray, R.append(R.__, arr)))(v);

    if (!value) {
      return;
    }

    this.setState(
      R.assocPath(
        ['selectedFilterOption', label, 'data'],
        addFilterOptions(selectedFilterOption[label].data)(value)
      )(this.state),
      this.syncAndApplyFilters
    );
  };

  valueFromType = type => value => {
    return type === 'number' ? Number(value) : value;
  }

  removeParameter = ({ label }) => () => {
    this.setState(
      R.over(
        R.lensProp('selectedFilterOption'), R.omit([label])
      )(this.state),
      this.syncAndApplyFilters
    );
  };

  removeFilter = ({ label }, value) => () => {
    const { selectedFilterOption } = this.state;

    this.setState(
      R.assocPath(
        ['selectedFilterOption', label, 'data'],
        R.reject(R.equals(value))(selectedFilterOption[label].data)
      )(this.state),
      this.syncAndApplyFilters
    );
  };

  downshiftStateReducer = (state, changes) => {
    switch (changes.type) {
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.blurInput:

        if (this.props.fetchAutocomplete) {
          this.props.clearAutocomplete();
          this.debounceTimer && clearTimeout(this.debounceTimer);
        }

        return {
          ...changes,
          inputValue: ''
        };
      default:
        return changes;
    }
  };

  itemIsClientName = item => {
    return (item.label === 'Client Name');
  };

  itemMatchesCurrentClient = item => {
    return (item.label === 'Client Name') && R.contains(this.props.currentClient.clientName, item.data);
  };

  itemMatchesCurrentProject = item => {
    return (item.label === 'Project Name') && R.contains(this.props.currentProject.projectName, item.data);
  };

  isReviewState = item => {
    return (item.label === 'Review State')
  };

  isReviewStateAndReviewPage = item => {
    return (item.label === 'Review State') && this.state.isReviewSvrResponsesPage;
  };

  isVisitDatePage = item => {
    return (item.label === "Visit Date") && this.state.isVisitDateInPage;
  };

  maybeShowParameterXButton = (item, style, onClick) => {

    if (this.props.isAddorEditWidget || this.props.isRollup) {
      if (this.isReviewState(item)) {
        return (
          <Tooltip id="review-state-param" title="Locked for review page">
            <Done style={style} />
          </Tooltip>
        );
      }

      if (this.isVisitDatePage(item)) {
        return (
          <Tooltip id="Visitdate-state-param" title="Locked for Vist date">
            <Done style={style} />
          </Tooltip>
        );
      }
    }
    else {
      if (this.isReviewStateAndReviewPage(item)) {
        return (
          <Tooltip id="review-state-param" title="Locked for review page">
            <Done style={style} />
          </Tooltip>
        );
      }

      if (this.isVisitDatePage(item)) {
        return (
          <Tooltip id="Visitdate-state-param" title="Locked for Vist date">
            <Done style={style} />
          </Tooltip>
        );
      }
    }
    if (this.itemMatchesCurrentClient(item)) {
      return (
        <Tooltip id="client-name-param" title="Injected from above">
          <Done style={style} />
        </Tooltip>
      );
    }

    if (this.itemMatchesCurrentProject(item)) {
      return (
        <Tooltip id="project-name-param" title="Injected from above">
          <Done style={style} />
        </Tooltip>
      );
    }

    return (
      <Clear style={{ ...style, cursor: 'pointer' }}
        onClick={onClick} />
    );
  };

  maybeShowAutoComplete = (item, component) => {

    if (this.itemMatchesCurrentClient(item)) {
      return null;
    }

    if (this.itemMatchesCurrentProject(item)) {
      return null;
    }

    return component;
  };

  maybeShowPermaChip = (item, permaChip, normalChip) => {

    if (this.itemMatchesCurrentClient(item)) {
      return permaChip;
    }

    if (this.itemMatchesCurrentProject(item)) {
      return permaChip;
    }

    return normalChip;
  };

  shouldSkipAutoComplete = (inputValue, item) => {
    if (!inputValue && this.props.clearAutocomplete) {
      this.props.clearAutocomplete();
    }
    return !this.props.fetchAutocomplete || !inputValue || this.itemIsClientName(item);
  }

  handleAutocompleteQuery = (inputValue, item) => {
    if (this.shouldSkipAutoComplete(inputValue, item)) {
      return;
    }
    this.debounceTimer && clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.props.fetchAutocomplete({
        path: item.pathStr,
        inputValue,
      });
    }, 200);
  }

  showAppropriateSelectorForItem = item => {
    const itemType = R.compose(
      R.prop('type'),
      R.find(R.eqProps('pathStr', item))
    )(this.props.tableColumns);

    switch (itemType) {

      case 'range': {
        const { classes } = this.props;
        const varianceOptions = R.pathOr([], ['prefilledFilters', item.label], this.props);

        return (
          <Select
            fullWidth
            multiple
            value={item.data}
            onChange={({ target: { value } }) => {
              this.handleSelectOptionsValue(item.label, true)(value);
            }}
            renderValue={R.always('')}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
          >
            {varianceOptions.map(({ label }) => (
              <MenuItem key={label} value={label}>
                <Checkbox checked={R.contains(label, item.data)} />
                <ListItemText primary={label} />
              </MenuItem>
            ))}
          </Select>
        );
      }

      case 'date':
        return this.state.activeDatePickers[item.pathStr] ? (
          <SearchDatePicker
            style={{
              position: 'absolute',
              zIndex: 9999,
              backgroundColor: '#FFF',
              boxShadow: '0px 1px 5px 0px  rgba(0, 0, 0, 0.2), 0px 2px 2px 0px  rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
              padding: '.5rem 0',
              borderRadius: '2px',
              display: this.state.activeDatePickers[item.pathStr] ? 'block' : 'none'
            }}
            onSelect={range => {
              const end = range.end.format('YYYY-MM-DD 23:59:59'),
                start = range.start.format('YYYY-MM-DD');

              this.setState(R.assocPath(['activeDatePickers', item.pathStr], false)(this.state));

              return this.handleSelectOptionsValue(item.label)([start, end]);
            }}
          />
        ) : (
          <div
            style={{
              cursor: 'pointer',
              margin: '1rem auto',
              backgroundColor: '#EEE',
              padding: '.5rem 1rem',
              borderRadius: '2rem',
              width: '100%'
            }}
            onClick={() => {
              this.setState(R.assocPath(['activeDatePickers', item.pathStr], true)(this.state));
            }}>
            <DateRange style={{ verticalAlign: 'text-bottom', marginRight: '.5rem' }} />
            Select
          </div>
        );

      default:
        return this.maybeShowAutoComplete(item, <Downshift
          onSelect={this.handleSelectOptionsValue(item.label)}
          itemToString={R.defaultTo('')}
          stateReducer={this.downshiftStateReducer}
          render={({
            getInputProps,
            getItemProps,
            getLabelProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            filteredData = getFilteredData(item, inputValue, this.props)
          }) => (
            <div>
              <label {...getLabelProps()} style={{ color: 'grey', fontSize: '.625rem' }}>Select Filter</label>
              <div style={{ position: 'relative' }}>
                { }
                <TextField
                  style={{ maxWidth: '100%' }}
                  {...getInputProps()}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      return this.handleSelectOptionsValue(item.label)(this.valueFromType(item.type)(event.target.value));
                    }
                    if (R.isEmpty(filteredData)) {
                      this.handleAutocompleteQuery(inputValue, item);
                    }
                  }}
                />
              </div>

              {isOpen ? (
                <div>
                  {
                    R.compose(
                      arr => (
                        <div style={{
                          zIndex: 10,
                          fontSize: '.875rem',
                          position: 'absolute',
                          maxHeight: '10rem',
                          padding: '5px',
                          overflowY: 'scroll'
                        }}>
                          {
                            arr.map((value, index) => (
                              value ?
                                <Paper square
                                  key={index}
                                  {...getItemProps({

                                    key: value,
                                    index,
                                    item: value,
                                    style: {
                                      padding: '.5rem',
                                      cursor: 'pointer',
                                      minWidth: '100px',
                                      maxHeight: '2rem',
                                      maxWidth: '50vw',
                                      overFlow: 'auto',
                                      backgroundColor: highlightedIndex === index
                                        ? 'lightgray'
                                        : 'white',
                                      fontWeight: selectedItem === value
                                        ? 'bold'
                                        : 'normal'
                                    }
                                  })}
                                >
                                  <div style={{ margin: '.375rem' }}>
                                    {value}
                                  </div>
                                </Paper> : null
                            ))
                          }
                        </div>
                      ),
                    )(filteredData)
                  }

                </div>
              ) : null}
            </div>
          )}
        />);
    }
  };

  decorateChipLabel = (item, value) => {
    if (Array.isArray(value[0])) {
      for (var cnt = 0; cnt < value[0].length; cnt++) {
        return item.type === 'date' ? `${value[0][0]} to ${value[0][1]}` : value[0];
      }
    }
    return item.type === 'date' ? `${value[0]} to ${value[1]}` : value;
  };

  hasMinimunFilters = () => {
    const { filterList } = this.props;
    const activeNum = R.compose(R.length, R.keys, R.filter(R.compose(R.not, R.isEmpty, R.prop('data'))))(filterList);
    return activeNum >= MINIMUM_FILTER_NUM_TO_LOAD;
  };

  hasNotificationSetupPageLoaded = () => {
    const herfURL = String(window.location.href);
    return (herfURL.includes('notifications/sent') || herfURL.includes('notifications/create') ? true : false);

  }

  hasReportingSetupPageLoaded = () => {
    const herfURL = String(window.location.href);
    const retValue = (((herfURL.includes('/reporting/svrs') && isClientUser() === false) || (herfURL.includes('/reporting/meal-period') && isClientUser() === false)) ? true : false);
    return retValue;
  }

  clearParameters = () => {
    const { selectedFilterOption } = this.state;
    var objFilterSize = Object.keys(selectedFilterOption).length;

    if (!R.isNil(selectedFilterOption) && objFilterSize > 0) {
      var removeParameterArray = [];
      if (!this.state.isReviewSvrResponsesPage && !this.props.isAddorEditWidget && !this.props.isRollup) {
        if (selectedFilterOption.hasOwnProperty('Review State')) {
          removeParameterArray.push("Review State");
        }
      }
      if (selectedFilterOption.hasOwnProperty('Report Number')) {
        removeParameterArray.push("Report Number");
      }
      if (selectedFilterOption.hasOwnProperty('Talent Name')) {
        removeParameterArray.push("Talent Name");
      }
      if (selectedFilterOption.hasOwnProperty('(Re)submitted Date')) {
        removeParameterArray.push("(Re)submitted Date");
      }
      if (selectedFilterOption.hasOwnProperty('Approved Date')) {
        removeParameterArray.push("Approved Date");
      }
      if (selectedFilterOption.hasOwnProperty('Approved by')) {
        removeParameterArray.push("Approved by");
      }
      if (selectedFilterOption.hasOwnProperty('Checkin Time')) {
        removeParameterArray.push("Checkin Time");
      }
      if (selectedFilterOption.hasOwnProperty('Checkin Distance mi')) {
        removeParameterArray.push("Checkin Distance mi");
      }
      if (selectedFilterOption.hasOwnProperty('Door Number')) {
        removeParameterArray.push("Door Number");
      }
      if (selectedFilterOption.hasOwnProperty('Door Location')) {
        removeParameterArray.push("Door Location");
      }
      if (selectedFilterOption.hasOwnProperty('Location Zone')) {
        removeParameterArray.push("Location Zone");
      }
      if (selectedFilterOption.hasOwnProperty('Location Territory')) {
        removeParameterArray.push("Location Territory");
      }
      if (selectedFilterOption.hasOwnProperty('Retailer')) {
        removeParameterArray.push("Retailer");
      }
      if (selectedFilterOption.hasOwnProperty('Department Type')) {
        removeParameterArray.push("Department Type");
      }
      if (selectedFilterOption.hasOwnProperty('Visit Length')) {
        removeParameterArray.push("Visit Length");
      }
      if (selectedFilterOption.hasOwnProperty('Work Classification')) {
        removeParameterArray.push("Work Classification");
      }
      if (selectedFilterOption.hasOwnProperty('TD')) {
        removeParameterArray.push("TD");
      }
      if (selectedFilterOption.hasOwnProperty('PD')) {
        removeParameterArray.push("PD");
      }
      if (selectedFilterOption.hasOwnProperty('Project #')) {
        removeParameterArray.push("Project #");
      }
      if (selectedFilterOption.hasOwnProperty('Project Name')) {
        removeParameterArray.push("Project Name");
      }
      if (selectedFilterOption.hasOwnProperty('Project Name')) {
        removeParameterArray.push("Project Name");
      }
      if (selectedFilterOption.hasOwnProperty('Project Segment Name')) {
        removeParameterArray.push("Project Segment Name");
      }
      if (selectedFilterOption.hasOwnProperty('Checkout Time')) {
        removeParameterArray.push("Checkout Time");
      }
      if (selectedFilterOption.hasOwnProperty('Actual Visit Length')) {
        removeParameterArray.push("Actual Visit Length");
      }
      if (selectedFilterOption.hasOwnProperty('Checkin Status')) {
        removeParameterArray.push("Checkin Status");
      }
      if (selectedFilterOption.hasOwnProperty('Checkout Status')) {
        removeParameterArray.push("Checkout Status");
      }
      if (selectedFilterOption.hasOwnProperty('Hidden From Client')) {
        removeParameterArray.push("Hidden From Client");
      }
      if (selectedFilterOption.hasOwnProperty('Authorized by')) {
        removeParameterArray.push("Authorized by");
      }
      if (selectedFilterOption.hasOwnProperty('Event ID')) {
        removeParameterArray.push("Event ID");
      }
      if (selectedFilterOption.hasOwnProperty('Form Canonical Key')) {
        removeParameterArray.push("Form Canonical Key");
      }
      if (selectedFilterOption.hasOwnProperty('Call Out')) {
        removeParameterArray.push("Call Out");
      }
      if (selectedFilterOption.hasOwnProperty('Exclude Client Name')) {
        removeParameterArray.push("Exclude Client Name");
      }
      if (selectedFilterOption.hasOwnProperty('Exclude Project Number')) {
        removeParameterArray.push("Exclude Project Number");
      }
      if (selectedFilterOption.hasOwnProperty('Exclude Project Name')) {
        removeParameterArray.push("Exclude Project Name");
      }
      if (selectedFilterOption.hasOwnProperty('Exclude Project Segment Name')) {
        removeParameterArray.push("Exclude Project Segment Name");
      }
      if (selectedFilterOption.hasOwnProperty('Project Number')) {
        removeParameterArray.push("Project Number");
      }
      if (selectedFilterOption.hasOwnProperty('MCID')) {
        removeParameterArray.push("MCID");
      }
      if (selectedFilterOption.hasOwnProperty('Zone')) {
        removeParameterArray.push("Zone");
      }
      if (selectedFilterOption.hasOwnProperty('State')) {
        removeParameterArray.push("State");
      }
      if (selectedFilterOption.hasOwnProperty('Waived')) {
        removeParameterArray.push("Waived");
      }
      if (selectedFilterOption.hasOwnProperty('Visibility')) {
        removeParameterArray.push("Visibility");
      }
      if (selectedFilterOption.hasOwnProperty('Location - City')) {
        removeParameterArray.push("Location - City");
      }
      if (selectedFilterOption.hasOwnProperty('Location - State')) {
        removeParameterArray.push("Location - State");
      }
      this.setState(
        R.over(
          R.lensProp('selectedFilterOption'), R.omit(removeParameterArray)
        )(this.state),
        this.syncAndApplyFilters
      );
    }
  }

  validateFilter = (nspace, sFilterOption, sbool) => {
    //debugger; 
    var isvalidated = false;
    var isvalidated45days = false;
    var isvalidatereview = false;
    var count45days = 0;

    //var objFilterSize = Object.keys(sFilterOption).length;

    if (!sFilterOption.hasOwnProperty('Visit Date') && sFilterOption.hasOwnProperty('Client Name')) {
      isvalidated = true;
    }
    else if ( !sFilterOption.hasOwnProperty('Review State') 
      && sFilterOption.hasOwnProperty('Client Name')
      && sFilterOption.hasOwnProperty('Visit Date')
      && this.props.isRollup) {
      if (isClientUser() === true)
      {
        isvalidatereview = false;
      } else {
        isvalidatereview = true;
      }
      
    }
    else {
      Object.entries(sFilterOption).forEach(function ([key, val], idx, arr) {
        // console.log("Key:", key);
        // console.log("Index:", idx);
        // console.log("Original Array:", arr);
        // console.log("Value:", val);
        // console.log("val.data:", val.data);
        //console.log("val.pathStr:", val.pathStr);
        //debugger;
        if (val.pathStr === "expectedCheckinTime" && val.data.length === 0) {
          isvalidated = true;
        }
        else if (val.pathStr === "expectedCheckinTime" && val.data.length > 0) {
          //debugger;
          for (var i = 0; i < val.data.length; i++) {
            //debugger;
            var todate = val.data[i][1];
            if (R.isNil(todate)) {
              var Dfilter = val.data[i][0];
              for (var j = 0; j < Dfilter.length - 1; j++) {
                var dtodate = Dfilter[1];
                var dfromdate = Dfilter[0];

                const ddaysdiff = Math.round(moment(dtodate).diff(dfromdate, 'days', true));
                count45days = count45days + ddaysdiff;
              }
            }
            else {
              var fromdate = val.data[i][0];
              const daysdiff = Math.round(moment(todate).diff(fromdate, 'days', true));
              count45days = count45days + daysdiff;
            }
          }

          if (count45days > 45) {
            isvalidated45days = true;
          }
        }
        else if (val.pathStr === "metaJson.reviewState" && val.data.length === 0) {
          if (isClientUser() === true)
            {
              isvalidatereview = false;
            } 
            else {
              isvalidatereview = true;
            }
        }
      });
    }
    if (isvalidated) {
      this.setState({
        showErrorModal: true,
        errorModalTitle: "Visit date range is required. Please select Visit Date Range"
      });
    }
    else if (isvalidated45days) {
      if (this.props.isAddorEditWidget) {
        var countMonths = count45days / 30;
        //debugger;
        if (countMonths > 12) {
          this.setState({
            showErrorModal: true,
            errorModalTitle: "You can only run this for maximum 12 months"
          });
        }
        else if (isvalidatereview) {
          this.setState({
            showErrorModal: true,
            errorModalTitle: "Review State is required. Please enter the Review State"
          });
        }
        else {
          this.props.filterByAction(nspace, sFilterOption, sbool);
        }
      }
      else if (this.props.isRollup) {
        var ccountMonths = count45days / 30;
        //debugger;
        if (ccountMonths > 6) {
          this.setState({
            showErrorModal: true,
            errorModalTitle: "You can only run this for maximum 6 months"
          });
        }
        else if (isvalidatereview) {
          this.setState({
            showErrorModal: true,
            errorModalTitle: "Review State is required. Please enter the Review State"
          });
        }
        else {
          this.props.filterByAction(nspace, sFilterOption, sbool);
        }
      }
      else {
        this.setState({
          showErrorModal: true,
          errorModalTitle: "You can only run this for maximum 45 days"
        });
      }
    }
    else if (isvalidatereview && (this.props.isAddorEditWidget || this.props.isRollup)) {
      this.setState({
        showErrorModal: true,
        errorModalTitle: "Review State is required. Please enter the Review State"
      });
    }
    else {
      this.props.filterByAction(nspace, sFilterOption, sbool);
    }
  }


  validateClientAlso = () => {
    this.setState({
      showErrorModal: true,
      errorModalTitle: "Please select the another one or more report parameter with client to Load Result"
    });
  }


  render() {
    const canSave = this.props.allowSave && !R.isEmpty(this.state.selectedFilterOption);
    const canShowSaveAsRollup = this.hasNotificationSetupPageLoaded();

    const canShowReportReview = this.hasReportingSetupPageLoaded();

    return (
      <div style={{ ...this.props.style }}>
        <SearchContainer>
          <div
            className='search-container-wrapper'
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '1rem',
              minHeight: '10rem',
              flexWrap: 'wrap'
            }}
          >

            {Object.keys(this.state.selectedFilterOption).map((label, i) => {
              const item = this.state.selectedFilterOption[label];

              return (
                <div key={i}>
                  <div style={{ margin: '1rem', width: '10rem' }}>
                    <div style={{ display: 'flex', flexWrap: 'no-wrap', maxWidth: '100%' }}>
                      <div style={{ flex: 4, display: 'inline-block', fontSize: '.875rem', textTransform: 'uppercase', fontWeight: 'bold' }}>
                        {item.label}
                      </div>
                      {
                        this.maybeShowParameterXButton(item, { fontSize: 14 }, this.removeParameter(item))
                      }
                    </div>
                    <div style={{ display: 'flex', maxWidth: '100%', boxSizing: 'border-box', marginBottom: '.5rem' }}>
                      {this.showAppropriateSelectorForItem(item)}
                    </div>
                    {
                      R.compose(
                        R.map(value =>
                          <ChipContainer key={value}>
                            {
                              this.maybeShowPermaChip(item,
                                <Tooltip id={`chip-${value}`} title={this.decorateChipLabel(item, value)}>
                                  <StyledChip
                                    value={value}
                                    label={this.decorateChipLabel(item, value)}
                                  />
                                </Tooltip>,
                                <Tooltip id={`chip-${value}`} title={this.decorateChipLabel(item, value)}>
                                  <StyledChip
                                    value={value}
                                    label={this.decorateChipLabel(item, value)}
                                    onDelete={this.removeFilter(item, value)}
                                    deleteIcon={<Clear />}
                                  />
                                </Tooltip>
                              )
                            }
                          </ChipContainer>
                        ),
                        R.prop('data'),
                        R.propOr({ data: [] }, item.label)
                      )(this.state.selectedFilterOption)
                    }
                  </div>

                </div>);
            })}
          </div>
          <Bottom displayAsColumn={this.props.displayAsColumn}>
            <div className="parameter-container">
              <ParameterMenu
                handleSelectParameter={this.handleSelectParameter}
                parameters={this.props.tableColumns}
                selectedFilterOption={this.state.selectedFilterOption}
              />
              <div>
                <Button
                  style={{ marginLeft: '1rem' }}
                  onClick={() => this.clearParameters() //this.props.syncFilters(this.props.namespace, [])
                  }
                >
                  <div style={{ color: 'red' }}>CLEAR PARAMETERS</div>
                </Button>
              </div>
              <div>
                <Button
                  style={{ marginLeft: '1rem' }}
                  onClick={
                    // Object.keys(this.state.selectedFilterOption).length === 0 ? () => this.validate()
                    // : Object.keys(this.state.selectedFilterOption).length === 1 && this.state.selectedFilterOption.hasOwnProperty('Client Name') ?
                    //   () => this.validateClientAlso()
                    //   : () => this.props.filterByAction(this.props.namespace, this.state.selectedFilterOption, false)
                    () => this.validateFilter(this.props.namespace, this.state.selectedFilterOption, false)
                  }
                  disabled={!this.hasMinimunFilters()}
                >
                  {/* <div style={{ color : this.hasMinimunFilters() ? 'red' : '#939598' }}>LOAD RESULTS</div> */}
                  <div style={{ color: this.hasMinimunFilters() ? 'red' : theme.palette.pblack.black }}>LOAD RESULTS</div>
                </Button>
              </div>
              {this.state.showErrorModal &&
                <Modal
                  withoutCancel
                  logoutText=''
                  onApprove={() => this.setState({ showErrorModal: false, errorModalTitle: '' })}>
                  {this.state.errorModalTitle}
                </Modal>
              }
              {canShowReportReview ? (
                <div>
                  <Button
                    style={{ marginLeft: '1rem' }}
                    onClick={
                      // Object.keys(this.state.selectedFilterOption).length === 0 ? () => this.validate()
                      // : Object.keys(this.state.selectedFilterOption).length === 1 && this.state.selectedFilterOption.hasOwnProperty('Client Name') ?
                      //   () => this.validateClientAlso()
                      //   : () => this.props.filterByAction(this.props.namespace, this.state.selectedFilterOption, true)
                      () => this.validateFilter(this.props.namespace, this.state.selectedFilterOption, true)
                    }
                    disabled={!this.hasMinimunFilters()}
                  >
                    {/* <div style={{ color : this.hasMinimunFilters() ? 'red' : '#939598' }}>REPORT REVIEW</div> */}
                    <div style={{ color: this.hasMinimunFilters() ? 'red' : theme.palette.pblack.black }}>REPORT REVIEW</div>
                  </Button>
                </div>
              ) : null
              }
            </div>

            {
              canSave ? (
                <Button style={{ marginRight: '2rem' }} onClick={this.handleShowSaveMenu}>SAVE...</Button>
              ) : null
            }
          </Bottom>
          {
            canSave ? (
              <Menu
                id="search-filters-save-menu"
                anchorEl={this.state.saveMenuAnchorEl}
                open={Boolean(this.state.saveMenuAnchorEl)}
                onClose={() => this.handleCloseSaveMenu()}
              >
                <MenuItem onClick={() => this.handleCloseSaveMenu(this.props.handleSaveAsView)}>Save As View</MenuItem>
                {canShowSaveAsRollup ? (
                  null
                ) : <MenuItem onClick={() => this.handleCloseSaveMenu(this.props.handleSaveAsRollup)}>Save As Roll-up</MenuItem>
                }
              </Menu>
            ) : null
          }
        </SearchContainer>
      </div>
    );
  }
}

const mapStateToProps = () => ({
  isClientUser: isClientUser()
});

export default compose(
  connect(mapStateToProps),
  withUser,
  withClient,
  withStyles(styles)
)(SearchParamsList);
