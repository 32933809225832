import * as R from 'ramda';
import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { isClientUser } from '../client/ClientReducer';
import { ListItemIcon } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

const maybeSupportTenantlessDashboards = !isClientUser() ? R.append(null) : R.identity;

const searchdiv = {
  dropdownContent: {
    position: 'absolute',
    backgroundColor: 'white',
    minWidth: '230px',
    overflow: 'auto',
    border: '1px solid #d1d1d1',
    zIndex: 1,
    top: '49px',
    left: '10px'
  },
  contenta: {
    color: '#343434',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'left',
    fontWeight: '300',
    fontSize: '1rem',
    cursor: 'pointer'
  },

  dropdown_Input: {
    padding: '15px 15px 6px 15px'
  }
};

class SavedDashboardMenu extends Component {
  state = {
    anchorEl: null,
    enableDashboardSearchDiv: false,
    stateSavedDashboard: null
  };

  searchDasboardRef = React.createRef();

  handleEdit = item => {
    this.setState({ anchorEl: null });
    this.props.onEdit(item);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };


  handleClickClone = () => {
    this.setState({ enableDashboardSearchDiv: true });
  };

  handleClose = currentSavedDashboard => () => {
    this.setState({ anchorEl: null });
    if (currentSavedDashboard) {
      this.props.handleSelectSavedDashboard(currentSavedDashboard)();
    }
  };

  componentDidMount() {
    const allowedClientIds = R.compose(
      maybeSupportTenantlessDashboards,
      R.filter(R.identity),
      R.pluck('clientId'),
      R.tryCatch(JSON.parse, R.always([]))
    )(window.localStorage.getItem('clientProjectList'));
    document.addEventListener("click", this.clickOutside);
    this.setState({ allowedClientIds });
  }

  HandleSearchDashboard = savedDashboard => (event) => {
    var results = [];
    results = savedDashboard.filter((x) =>
      x.title.toLowerCase().includes(event.target.value.toLowerCase()));
    this.setState({
      stateSavedDashboard: results
    });
  };

  handleNewClickDashboard = (e) => {
    this.setState({
      stateSavedDashboard: [],
      enableDashboardSearchDiv: false
    });
  }

  HandleSaveDashboardId = itemId => (event) => {
     window.localStorage.setItem('dashboardId', itemId);
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.clickOutside);
  }


  clickOutside = (e) => {
    try {
      if (!this.searchDasboardRef.current.contains(e.target)) {
        this.setState({ enableDashboardSearchDiv: false,  stateSavedDashboard: null }); 
      }
    }
    catch (ee) {
    }
  };


  render() {

    const {
      anchorEl,
      allowedClientIds
    } = this.state;

    const {
      savedDashboards,
      tokenUserId,
      StyledNavLink,
      currentSavedDashboard
    } = this.props;

    if (R.isEmpty(savedDashboards)) {
      return null;
    }

    const orderedSavedDashboards = R.sortBy(dashboard => R.toLower(dashboard.title), savedDashboards);
    const orderedSavedDashboardsTenancy = R.filter(R.compose(R.contains(R.__, allowedClientIds), R.prop('tenancyClientId'))
      , orderedSavedDashboards);
    const orderedSavedDashboardsRestrictedToken = R.filter(R.when(R.prop('restrictTokenUserId'), R.propEq('restrictTokenUserId', tokenUserId))
      , orderedSavedDashboardsTenancy);

    return (
      <div ref={this.searchDasboardRef}>
        <StyledNavLink
          aria-owns={anchorEl ? 'saved-dashboards-menu' : null}
          aria-haspopup="true"
          activeClassName="selected"
          to="/dashboard/saved"
          onClick={e => { e.preventDefault(); e.stopPropagation(); this.handleClick(e); }}
        >
          <p onClick={this.handleClickClone}> {`${R.propOr('Dashboards', 'title', currentSavedDashboard)} ▼`} </p>
        </StyledNavLink>

        {this.state.enableDashboardSearchDiv ?
          <div style={searchdiv.dropdownContent}>
            <TextField placeholder="Search..." onChange={(e) => this.HandleSearchDashboard(orderedSavedDashboardsRestrictedToken)(e)}
              style={searchdiv.dropdown_Input}></TextField>
            <ul className='clientmenu' style={{ maxHeight: '500px', overflowY: 'scroll', padding: '0px' }}>
              {
                R.map(item => (
                  <StyledNavLink to={`/dashboard/saved/${item.id}`} onClick={(e) => this.HandleSaveDashboardId(item.id)(e)}  activeClassName="selected">&nbsp;&nbsp; <p style={{width:'450px'}}>{item.title}</p>
                  <ListItemIcon  onClick={ e => { e.preventDefault(); e.stopPropagation(); this.handleEdit(item); } }>
                    <EditIcon />
                  </ListItemIcon>                   
                  </StyledNavLink>
                ),
                ( R.isNil(this.state.stateSavedDashboard)  //|| R.isEmpty(this.state.stateSavedDashboard)  || this.state.stateSavedDashboard.length === 0
                ) ?
                    orderedSavedDashboardsRestrictedToken : this.state.stateSavedDashboard)
              }
            </ul>
          </div> : null
        }
      </div>
    );
  }
}

export default SavedDashboardMenu;
