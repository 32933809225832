import React, { Component } from 'react';
import PropTypes            from 'prop-types';

import * as R from 'ramda';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import { lighten }    from '@material-ui/core/styles/colorManipulator';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography
} from '@material-ui/core';

const HEIGHT = 30;

const Footer = styled.div`
  display         : flex;
  height          : ${HEIGHT}px;
  background      : #f8f8f8;
  justify-content : flex-start;
  padding-left    : 30px;
  font-size       : 15px;
  align-items     : flex-end;
  padding-bottom  : 7px;

  & > div {
    margin-right : 20px;
    color        : ${({ isSelected }) => isSelected ? '#F3212E' : '#939598'}
    cursor       : pointer;
  }
`;

const StyledTableRow = styled(TableRow)`
  border-top : 1px solid #C9CACB;
  height     : 30px !important;
`;

const StyledTablePagination = styled(TablePagination)`
  position : absolute;
  float    : left;
`;

const isSortedBy = (k, orderBy) => orderBy === k;
const maybeSortBy = (k, orderBy, order) => isSortedBy(k, orderBy) ? order : false;

class EnhancedTableHead extends Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <StyledTableRow>
          <TableCell padding="checkbox" width={70}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              style={{ height: `${HEIGHT}px` }}
            />
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={maybeSortBy('firstName', orderBy, order)}
            width={100}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={isSortedBy('firstName', orderBy)}
                direction={order}
                onClick={this.createSortHandler('firstName')}
              >
                First Name
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'lastName' ? order : false}
            width={100}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'lastName'}
                direction={order}
                onClick={this.createSortHandler('lastName')}
              >
                Last Name
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'company' ? order : false}
            width={300}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'company'}
                direction={order}
                onClick={this.createSortHandler('company')}
              >
                Company Name
              </TableSortLabel>
            </Tooltip>
          </TableCell>
          <TableCell
            numeric={false}
            padding="default"
            sortDirection={orderBy === 'address' ? order : false}
            width={300}
          >
            <Tooltip
              title="Sort"
              placement="bottom-end"
              enterDelay={300}
            >
              <TableSortLabel
                active={orderBy === 'address'}
                direction={order}
                onClick={this.createSortHandler('address')}
              >
                Address
              </TableSortLabel>
            </Tooltip>
          </TableCell>
        </StyledTableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight : theme.spacing.unit,
    minHeight    : '53px',
    padding      : '0px 8px 25px 15px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  typography: {
    fontWeight    : 400,
    fontSize      : '22px',
    letterSpacing : '1px',
  }
});

let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subheading">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            className={classes.typography}
            variant="title"
          >
            Users
          </Typography>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = () => ({
  root: {
    width: '96%',
    marginTop: '80px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class EnhancedTable extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'title',
      selected: [],
      data: R.sort(R.ascend(R.prop('title')), props.data),
      page: 0,
      rowsPerPage: props.rowsPerPage,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data = R.sort(
      R[`${order}end`](
        R.compose(
          R.toLower,
          R.propOr('', orderBy)
        )
      ), this.state.data);

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: R.pluck('uuid', this.state.data) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    const firstItemIndex = 0;
    const lastItemIndex = selected.length - 1;

    const newSelected = R.defaultTo(
      [],
      R.cond([
        [R.equals(-1),             () => [].concat(selected, id)],
        [R.equals(firstItemIndex), () => [].concat(selected.slice(1))],
        [R.equals(lastItemIndex),  () => [].concat(selected.slice(0, -1))],
        [R.lt(0),                  () => [].concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))],
      ])(selectedIndex)
    );

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => R.contains(id, this.state.selected);

  render() {
    const { classes } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selectedIds={selected}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
            />
            <TableBody>
              {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                const isSelected = this.isSelected(n.uuid);
                return (
                  <TableRow
                    hover
                    onClick={event => this.handleClick(event, n.uuid)}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.uuid}
                    selected={isSelected}
                    style={{ height: `${HEIGHT}px` }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox style={{ height: `${HEIGHT}px` }} checked={isSelected} />
                    </TableCell>
                    <TableCell>{n.firstName}</TableCell>
                    <TableCell>{n.lastName}</TableCell>
                    <TableCell>{n.company}</TableCell>
                    <TableCell>{n.address}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: HEIGHT * emptyRows }}>
                  <TableCell colSpan={6} style={{ height: `${HEIGHT}px` }} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <StyledTablePagination
                  colSpan={6}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{
                    'aria-label': 'Previous Page',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Next Page',
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          <Footer />
        </div>
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id        : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName : PropTypes.string,
    lastName  : PropTypes.string,
    company   : PropTypes.string,
    address   : PropTypes.string,
  })),
  rowsPerPage : PropTypes.number,
  classes     : PropTypes.object.isRequired,
};

EnhancedTable.defaultProps = {
  data: [],
  rowsPerPage: 10,
};

export default withStyles(styles)(EnhancedTable);
