import * as R      from 'ramda';
import React       from 'react';
import PropTypes   from 'prop-types';
import { connect } from 'react-redux';
import styled      from 'styled-components';
import tinycolor   from 'tinycolor2';

import {
  compose, defaultProps,
  lifecycle,
  setPropTypes,
  withHandlers,
  withProps,
  withStateHandlers
} from 'recompose';

import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar
} from 'recharts';

import { Typography } from '@material-ui/core';

import WidgetContent from './WidgetContent';
import WidgetHeader  from './WidgetHeader';

import { colors }               from '../../components/Theme';
import withUser                 from '../../modules/auth';
import withClient               from '../../modules/client';
import { fetchQuestionAnswers } from '../../modules/saved-rollups/SavedRollupsActions';

const randomColor = () => '#' + Math.random().toString(16).slice(2, 8);

const Wrapper = styled.div`
  .recharts-legend-item {
    margin-right: 20px !important;
  }

  .recharts-default-legend {
    display: flex !important;
    justify-content: center;
  }
`;

class CustomLabel extends React.PureComponent {
  render() {
    const { width, x, y, fill, value, count } = this.props;
    const isCutOff = y <= 20;
    const dx = width * 0.5;
    const dy = isCutOff ? 14 : -2;
    const fillColor = (!isCutOff || tinycolor(fill).isLight()) ? 'black' : 'white';
    const percent = R.defaultTo(0, ((value / count) * 100)).toFixed(0);
    return isNaN(y) ? <text></text> : (
      <text 
        x         = {x} 
        y         = {y} 
        dx        = {dx}
        dy        = {dy} 
        style     = {{ fontSize : '0.9em'}}
        fontFamily= 'sans-serif'
        fill      = {fillColor}
        textAnchor= "middle">
        {`${percent}%`}
      </text>
    );
  }
}

const BarChartWidgetContainer = (
  {
    title,
    description,
    widgetContent,
    onTitleClick,
    onEditClick,
    onMoveClick,
    onDownloadClick,
    onRef,
    width,
    isShowingIcons,
    containerHeight,
    containerWidth,
    isDownloadContainer
  }) => (
  <Wrapper innerRef={onRef}>
    <div style={{ backgroundColor: 'white' }}>
      <WidgetHeader
        title           = {title}
        description     = {description}
        onTitleClick    = {onTitleClick}
        onEditClick     = {onEditClick}
        onMoveClick     = {onMoveClick}
        onDownloadClick = {onDownloadClick}
        isShowingIcons  = {isShowingIcons}
        isDownload
      />
      <WidgetContent containerHeight={containerHeight}>
        {widgetContent ? (
          <BarChart
            width  = {isDownloadContainer ? containerWidth : width}
            height = {isDownloadContainer ? containerHeight : 270}
            data   = {widgetContent.chartData}
            margin = {{ top : 5, right : 0, left : -20, bottom : 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            {!isDownloadContainer && <Tooltip content={props => {
              const count = R.compose(R.sum, R.flatten, R.map(R.pluck(1)), R.map(R.filter(R.prop(0))), R.map(R.toPairs), R.map(R.omit(['name'])))(widgetContent.chartData);
              const data = (props.payload || []);
              return R.length(data) > 1 && (
                <div style={{ backgroundColor : '#FFF', border : '1px solid #CCC'}}>
                  <h4>{R.pathOr('N/A', [0, 'payload', 'name'], data)}</h4>
                  <ul style={{ listStyle : 'none', margin : 0, padding : 0 }}>
                    {
                      data.map((v,i) => (
                        <li key={i} style={{ margin : '5px'}}>
                          <span style={{ color : colors[i] || randomColor()}}>{v.name || 'N/A'}</span>: {`${v.value} out of ${count}`}
                        </li>
                      ))
                    }
                  </ul>
                </div>
              );}}/>
            }
            <Legend
              align="left"
              wrapperStyle={{
                paddingRight: isDownloadContainer ? '100px' : '20px',
                paddingTop: '20px',
                paddingLeft: '20px',
                left: '0px',
                maxHeight: '1px'
              }}
            />
            {
              widgetContent.dataPoints.map((v,i) => {
                const count = R.compose(R.sum, R.flatten, R.map(R.pluck(1)), R.map(R.filter(R.prop(0))), R.map(R.toPairs), R.map(R.omit(['name'])))(widgetContent.chartData);
                return (
                <Bar
                  key               = {v}
                  dataKey           = {v}
                  fill              = {colors[i] || randomColor()}
                  label             = {<CustomLabel count={count} fill={colors[i] || randomColor()}/>}
                  isAnimationActive = {false}
                />
              );})
            }
          </BarChart>
        ) : (
          <Typography paragraph={true} variant="body2">
            No answers...
          </Typography>
        )}
      </WidgetContent>
    </div>
  </Wrapper>
);

const mapDispatchToProps = dispatch => ({
  fetchQuestionAnswers : (namespace, payload) => dispatch(fetchQuestionAnswers(namespace, payload)),
});

const mapStateToProps = () => ({});

const mapAnswersToQuestions = (answers={}) => storeVisit => {
  const matchingAnswers = answers[storeVisit.id] || [];
  return R.assoc('answers', matchingAnswers)(storeVisit);
};

const maybeParseJson = o => R.tryCatch(JSON.parse, R.always(o))(o);

const countAnswers = R.compose(
  R.countBy(R.identity),
  R.flatten,
  R.map(maybeParseJson),
  R.chain(R.pluck('answerValue')),
  R.pluck('answers')
);

const mergeName = R.compose(
  R.mergeAll,
  R.over(R.lensIndex(0), R.objOf('name'))
);

const getDataPoints = R.compose(
  R.objOf('dataPoints'),
  R.without('name'),
  R.uniq,
  R.pluck(0),
  R.sort(R.descend(R.prop(1))),
  R.chain(R.toPairs)
);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withClient,
  withUser,
  withStateHandlers({},
  {
    setWidth  : () => width => ({ width }),
  }),
  withHandlers(() => {
    let chart = null;

    return {
      onRef        : ({ setContainerSizes, setDownloadWidgetRef }) => ref => {
        chart = ref;

        setContainerSizes && setContainerSizes(ref);
        setDownloadWidgetRef && setDownloadWidgetRef(ref);
      },
      setDimensions : ({ setWidth }) => () => {
        if (chart && R.is(Function, chart.getBoundingClientRect)) {
          setWidth(chart.getBoundingClientRect().width * 0.9);
        }
      },
    };
  }),
  withProps(({ widgetData : { description, filteredWidgetData, title, metaJson }, answers }) => ({
    description,
    title,
    metaJson,
    widgetContent : R.compose(
      o => ({ ...o, ...getDataPoints(o.chartData)}),
      R.objOf('chartData'),
      R.map(mergeName),
      R.toPairs,
      R.map(countAnswers),
      R.tryCatch(
        R.groupBy(
          R.pathOr([], R.compose(
                         R.split('.'),
                         R.propOr('', 'dataGroupBy')
                       )(metaJson)
          )
        ),
        R.always({})
      ),
      R.filter(R.compose(R.length, R.prop('answers'))),
      R.map(mapAnswersToQuestions(answers))
    )(filteredWidgetData)
  })),
  defaultProps({
    width          : 250,
    isShowingIcons : true
  }),
  setPropTypes({
    description     : PropTypes.string,
    title           : PropTypes.string,
    widgetData      : PropTypes.object.isRequired,
    onTitleClick    : PropTypes.func,
    onEditClick     : PropTypes.func,
    onMoveClick     : PropTypes.func,
    onDownloadClick : PropTypes.func,
    widgetContent   : PropTypes.object
  }),
  lifecycle({
    componentDidMount() {
      if (!this.props.isDownloadContainer) {
        const questions = this.props.widgetData.formQuestionCanonicalKeys || [];

        this.props.fetchQuestionAnswers(this.props.widgetData.metaJson.namespace, questions)
          .then(res => {
            const answers = R.pathOr([], ['payload', 'data', 'data'], res);
            this.props.onAnswers(answers);

          window.addEventListener('resize', this.props.setDimensions);
          this.props.setDimensions();
        });
      }
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.setDimensions);
    }
  })
)(BarChartWidgetContainer);
