import * as R from 'ramda';

import React, { Component } from 'react';

import { Menu, Button, MenuItem } from '@material-ui/core';

const ITEM_HEIGHT = 48;

const mapIndexed = R.addIndex(R.map);

class ParameterMenu extends Component {
  state = {
    anchorEl: null,
    stparamters: this.props.parameters
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = selectedParameter => () => {
    this.setState({ anchorEl: null });
    if (selectedParameter) {
      this.props.handleSelectParameter(selectedParameter)();
    }
  };

  render() {
    const { anchorEl, stparamters } = this.state;
    //console.log("this.stparamters: ", stparamters);

    for (var cnt = 0; cnt < stparamters.length; cnt++) {
      if (stparamters[cnt].label === "Door Latitude"
        || stparamters[cnt].label === "Door Longitude"
        || stparamters[cnt].label === "Checkin Latitude"
        || stparamters[cnt].label === "Checkin Longitude"
        || stparamters[cnt].label === "Device OS"
        || stparamters[cnt].label === "Device OS Version"
        || stparamters[cnt].label === "Device Model"
        || stparamters[cnt].label === "App Version"
      ) {
        this.state.stparamters.splice(cnt, 1);

        this.setState({
          stparamters: this.state.stparamters
        });
      }
    }


    return (
      <div>
        <Button
          aria-owns={anchorEl ? 'search-filter-parameters' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          style={{ marginLeft: '1rem' }}
        >
          <div style={{ color: 'red' }}>ADD PARAMETER</div>
        </Button>
        <Menu
          id="search-filter-parameters"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose()}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5
            }
          }}
        >
          {
            R.compose(
              mapIndexed((item, i) => (
                <MenuItem key={i} onClick={this.handleClose(item)}>
                  {item.label}
                </MenuItem>
              )),
              R.reject(
                R.compose(
                  R.contains(R.__, R.keys(this.props.selectedFilterOption)),
                  R.prop('label')
                )
              )
            )(this.state.stparamters)

          }

        </Menu>
      </div>
    );
  }
}

export default ParameterMenu;
