export const ITEM_TYPES = {
  SECTION                   : 'section',
  QUESTION_GROUP            : 'question-group',
  RADIO_BUTTON              : 'radio-button-input',
  DROPDOWN                  : 'dropdown-input',
  CHECKBOX                  : 'checkbox-input',
  SINGLE_LINE               : 'single-line-input',
  MULTI_LINE                : 'multi-line-input',
  PHOTO                     : 'photo-input',
  TOGGLE                    : 'toggle-input',
  COMMON                    : 'common-input',
  NUMERIC_INT               : 'numeric-int-input',
  VIDEO                     : 'video-input',
  SIGNATURE                 : 'signature-input',
  NUMERIC_CONDITIONAL       : 'numeric-conditional-input',
};

export const ITEMS = {
  [ITEM_TYPES.RADIO_BUTTON]           : 'Radio Button',
  [ITEM_TYPES.DROPDOWN]               : 'Dropdown',
  [ITEM_TYPES.CHECKBOX]               : 'Checkbox',
  [ITEM_TYPES.SINGLE_LINE]            : 'Single Line',
  [ITEM_TYPES.MULTI_LINE]             : 'Multi Line',
  [ITEM_TYPES.PHOTO]                  : 'Photo',
  [ITEM_TYPES.TOGGLE]                 : 'Yes/No',
  [ITEM_TYPES.COMMON]                 : 'Common Question',
  [ITEM_TYPES.NUMERIC_INT]            : 'Numeric',
  [ITEM_TYPES.VIDEO]                  : 'Video',
  [ITEM_TYPES.SIGNATURE]              : 'Signature',
  [ITEM_TYPES.NUMERIC_CONDITIONAL]    : 'Numeric Conditional',
};


// export const ITEMS = [
//   { type: ITEM_TYPES.RADIO_BUTTON, name: 'Radio' },
//   { type: ITEM_TYPES.CHECKBOX    , name: 'Checkbox' },
//   { type: ITEM_TYPES.DROPDOWN    , name: 'Dropdown' },
//   { type: ITEM_TYPES.SINGLE_LINE , name: 'Single Line' },
//   { type: ITEM_TYPES.MULTI_LINE  , name: 'Multi Line' },
//   { type: ITEM_TYPES.TOGGLE      , name: 'Yes/No' },
//   { type: ITEM_TYPES.PHOTO       , name: 'Photo' },
// ];

export const QUESTION_TYPES_NAME = {
  [ITEM_TYPES.RADIO_BUTTON]           : 'Radio Button',
  [ITEM_TYPES.DROPDOWN]               : 'Dropdown',
  [ITEM_TYPES.CHECKBOX]               : 'Checkbox',
  [ITEM_TYPES.SINGLE_LINE]            : 'Single Line',
  [ITEM_TYPES.MULTI_LINE]             : 'Multi Line',
  [ITEM_TYPES.PHOTO]                  : 'Photo',
  [ITEM_TYPES.TOGGLE]                 : 'Yes/No',
  [ITEM_TYPES.COMMON]                 : 'Common',
  [ITEM_TYPES.NUMERIC_INT]            : 'Numeric',
  [ITEM_TYPES.VIDEO]                  : 'Video',
  [ITEM_TYPES.SIGNATURE]                : 'Signature',
  [ITEM_TYPES.NUMERIC_CONDITIONAL]    : 'Numeric Conditional',
};
