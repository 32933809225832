import { connect } from 'react-redux';
import { compose, defaultProps, setDisplayName } from 'recompose';

import { getCurrentClient, getCurrentProject } from './ClientReducer';

const mapStateToProps = state => ({
  currentClient: getCurrentClient(state),
  currentProject: getCurrentProject(state),
});

const withClient = wrappedComponent =>
  compose(
    setDisplayName('withClient'),
    connect(mapStateToProps),
    defaultProps({
      currentClient: {
        clientId: null,
        clientName: '',
      },
    })
  )(wrappedComponent);

export default withClient;
