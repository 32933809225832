import React                    from 'react';
import DropPhoto                from './DropPhoto';
import DraggablePhotoContainer  from './DragPhoto';
import { Button }               from '@material-ui/core';

const DragAndDropPhoto = ({ children, onSwitchPhotos, currentAnswer, question, photos, isEditingPhotos, onDeletePhotoClick }) => {

    return <DropPhoto
        onDrop      = {data => onSwitchPhotos && onSwitchPhotos(data)}
        answer      = {currentAnswer}
        question    = {question}
        photo       = {photos.find(ph => ph.uuid === currentAnswer)}>
        <DraggablePhotoContainer
            canDrag     = {isEditingPhotos}
            answer      = {currentAnswer}
            question    = {question}
            photo       = {photos.find(ph => ph.uuid === currentAnswer)}>
            {children}
            <div style={{ width: '100%' }}>
                <Button
                    color   = "secondary"
                    style   = {{ padding: '0 30px', width: 'fit-content', float: 'right' }}
                    onClick = {() => onDeletePhotoClick({ question, currentAnswer, photos })}>
                    Delete photo from SVR
                </Button>
            </div>
        </DraggablePhotoContainer>
    </DropPhoto>;
};


export default DragAndDropPhoto;
