import React                     from 'react';
import PropTypes                 from 'prop-types';
import { compose, setPropTypes } from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import { BigPlayButton, Player } from 'video-react';
import { Button } from '@material-ui/core';
import * as R from 'ramda';

import styled from 'styled-components';

const styles = () => ({
  videoWidgetContent : {
    display   : 'flex',
    position  : 'relative',
    flex      : 1,
    flexDirection: 'column',
    minHeight : '16.7em',
  },
  imgContainer       : {
    display            : 'flex',
    position           : 'relative',
    flex               : 1,
    objectFit          : 'contain',
    cursor             : 'pointer',
    width              : '100%',
    height             : '100%',
    alignItems         : 'center',
    justifyContent     : 'center'
  },
  center            : {
    display         : 'flex',
    position        : 'absolute',
    flex            : 1,
    left            : 0,
    top             : 0,
    objectFit       : 'contain',
    width           : '100%',
    height          : '100%',
    alignItems      : 'center',
    justifyContent  : 'center'
  },
  imgContainerLeft  : {
    position         : 'absolute',
    top              : '0',
    left             : '0',
    width            : '50%',
    height           : '100%',
    minHeight        : '100px',
    backgroundSize   : 'contain',
    backgroundRepeat : 'no-repeat',
    backgroundPosition : 'center'
  },
  imgContainerRight : {
    position         : 'absolute',
    top              : '0',
    left             : '50%',
    width            : '50%',
    height           : '100%',
    minHeight        : '100px',
    backgroundSize   : 'contain',
    backgroundRepeat : 'no-repeat',
    backgroundPosition : 'center'
  },
  imgContainerCenter : {
    position           : 'absolute',
    width              : '100%',
    height             : '100%',
    minHeight          : '100px',
    backgroundSize     : 'contain',
    backgroundRepeat   : 'no-repeat',
    backgroundPosition : 'center'
  },
});

const VideoContainer = styled.div`
  height: 100%;
  display            : flex;
  position           : relative;
  flex               : 1;
  objectFit          : contain;
  cursor             : pointer;
  width              : 100%;
  height             : 100%;
  alignItems         : center;
  justifyContent     : center;

  .video-react-video, .video-react {
    background-color  : white;
  }
`;

const updateRotation = (rotation) => (video) => {
  if(!video) {
    return;
  }

  const thisVideo: HTMLVideoElement = R.path(['video', 'video'], video);
  if(!thisVideo) {
    return;
  }
  thisVideo.style.transform = `rotate(${rotation}deg)`;
};

const VideoWidgetContent = ({ classes, imgUrl, toggleDetailView, imgUrlBefore, imgUrlAfter, imgRotation, imgRotationAfter, imgRotationBefore }) => (
  <div className={classes.videoWidgetContent}>
    <Button
      color="secondary"
      style={{ padding: '0 30px', width: '100%', zIndex: 100 }}
      onClick={toggleDetailView}
      className='no-print'>
        REVIEW VIDEOS
    </Button>
    <VideoContainer>
      {!imgUrlAfter && !imgUrlBefore && (
        <div className={classes.center}>
          <div className={classes.imgContainerCenter}>
            <Player fluid={ false } src= { imgUrl } height={ "100%" } ref={ updateRotation(imgRotation) }><BigPlayButton position={"center"}/></Player>
          </div>
        </div>
      )}
      {(imgUrlAfter || imgUrlBefore) && (
        <div className={classes.center}>
          <div className={classes.imgContainerLeft}>
            <Player fluid = { false } src   = { imgUrlBefore || imgUrl } height = { "100%" } ref={ updateRotation(imgUrlBefore ? imgRotationBefore : imgRotation) }><BigPlayButton position={"center"}/></Player>
          </div>
          <div className={classes.imgContainerRight}>
            <Player fluid = { false } src   = { imgUrlAfter || imgUrl } height = { "100%" } ref={ updateRotation(imgUrlAfter ? imgRotationAfter : imgRotation) }><BigPlayButton position={"center"}/></Player>
          </div>
        </div>
      )}
    </VideoContainer>
  </div>
);

export default compose(
  withStyles(styles),
  setPropTypes({
    classes          : PropTypes.object.isRequired,
    imgUrl           : PropTypes.string,
    toggleDetailView : PropTypes.func.isRequired
  })
)(VideoWidgetContent);
