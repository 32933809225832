import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import uuid from 'uuid/v4';

import {
  compose,
  defaultProps,
  lifecycle,
  withStateHandlers
} from 'recompose';

import SaveAsModal from '../../../components/SearchFilter/modals/SaveAs__Modal';
import SearchParamsList from '../../../components/SearchFilter/SearchParamsList';
import EditSavedRollupPageToolbar from '../components/EditSavedRollupPageToolbar';
import EditRollUpsTable from '../components/EditSavedRollupsTable';
import DownloadZipProgressModal from '../../modals/DownloadZipProgressModal';
import FileExportProgressModal from '../../modals/FileExportProgressModal';

import SavedRollupPage from './SavedRollupPage';
import { fetchAutocomplete as _fetchAutocomplete, clearAutocomplete as _clearAutocomplete } from '../../client/ClientActions';

import {
  isClientUser,
  getPrefilledFilters,
  getAutoComplete
} from '../../client/ClientReducer';

import {
  hideFileExportModal as _hideFileExportModal,
  exportToFile as _exportToFile
} from '../../file-export/FileExportActions';

import {
  getFileExportError,
  getFileExportLink,
  getShowFileExportModal
} from '../../file-export/FileExportReducer';

import {
  hideDownloadZipModal as _hideDownloadZipModal,
  downloadZip as _downloadZip
} from '../../document/DocumentActions';

import {
  fetchSavedWidget
} from '../../saved-widgets/SavedWidgetsActions';

import {
  getShowDownloadZipModal,
  getZipError,
  getZipLink
} from '../../document/DocumentReducer';

import {
  getUserId
} from '../../auth/AuthReducer';

import {
  fetchSavedRollup as _fetchRollUp,
  fetchQuestionAnswers as _fetchQuestionAnswers,
  fetchFormsByIds as _fetchForms,
  setQuestions as _setQuestions
} from '../SavedRollupsActions';

import {
  getQuestions,
  getQuestionAnswers,
  getForms
} from '../SavedRollupsReducer';

import {
  fetchSvrResponses as _fetchSvrResponses,
  filterByAction as _filterByAction,
  transformData as _transformData,
  fetchPhotoQuestions as _fetchPhotoQuestions
} from '../../svr-responses/SvrResponsesActions';

import {
  getSvrResponses,
  getTableColumns,
  getTransformedData,
  getFilterList,
  getSvrPhotos,
  getPhotos
} from '../../svr-responses/SvrResponsesReducer';

import {
  getFlaggedDocumentUuids,
  getStarredDocumentUuids,
  getStoreVisitIdsContainingFlaggedDocumentUuids,
  getStoreVisitIdsContainingStarredDocumentUuids,
  transformSvrResponsesForWidget,
  getWidgetTypeWithoutQuestions
} from '../../../utils/widgetHelpers';

import mergePhotoMotives from '../components/utils/mergePhotoMotives';

const maybeShowOnlyStarredOrFlaggedPhotos = ({ starred, flagged }, filteredWidgetData, allUuids) => {
  if (starred) {
    return R.always(getStarredDocumentUuids("Photos")(filteredWidgetData));
  }
  if (flagged) {
    return R.always(getFlaggedDocumentUuids("Photos")(filteredWidgetData));
  }
  return allUuids;
};

const maybeFilterStoreVisitsWithStarredOrFlaggedPhotos = ({ starred, flagged }, filteredWidgetData) => {
  if (starred) {
    const starredStoreVisitIds = getStoreVisitIdsContainingStarredDocumentUuids("Photos")(filteredWidgetData);
    return R.filter(R.compose(R.contains(R.__, starredStoreVisitIds), R.prop('id')))(filteredWidgetData);
  }
  if (flagged) {
    const flaggedStoreVisitIds = getStoreVisitIdsContainingFlaggedDocumentUuids("Photos")(filteredWidgetData);
    return R.filter(R.compose(R.contains(R.__, flaggedStoreVisitIds), R.prop('id')))(filteredWidgetData);
  }
  return filteredWidgetData;
};

const filteredTransformedData = (rollUp, data, answers) => {
  const metaJson = R.prop('metaJson', rollUp);
  const isFeedbackWidget = R.propEq('widgetType', 'feedback', R.defaultTo({}, metaJson));

  if (R.any(R.isNil)([metaJson, data]) || R.length(data) <= 0 || isFeedbackWidget) {
    return data;
  }

  data = maybeFilterStoreVisitsWithStarredOrFlaggedPhotos(metaJson, data);
  return R.compose(
    markedAnswers => R.filter(R.compose(R.contains(R.__, R.pluck('storeVisitId', markedAnswers)), R.prop('id')))(data),
    flatAnswers => R.compose(
      arr => R.filter(R.compose(R.contains(R.__, arr), R.prop('answerValue')))(flatAnswers),
      maybeShowOnlyStarredOrFlaggedPhotos(
        metaJson,
        data,
        R.pluck('answerValue')
      )
    )(flatAnswers),
    R.flatten,
    R.values
  )(answers);
};

const consolidateQuestionsForRollup = R.curry((_uuid, rollUp, questions) => {
  const type = getWidgetTypeWithoutQuestions(rollUp);
  if (!type) {
    return questions;
  }

  return [{
    canonicalKey: _uuid,
    title: "Feedback",
    uuid: _uuid
  }];
});

const consolidateQuestionAnswersForRollup = R.curry((_uuid, rollUp, questionAnswers, transformedData) => {
  const type = getWidgetTypeWithoutQuestions(rollUp);
  if (!type) {
    return questionAnswers;
  }

  return R.compose(
    R.groupBy(R.prop('storeVisitId')),
    R.map(v => ({
      answerValue: R.path(['formState', 'feedback', 'message'], v),
      questionCanonicalKey: _uuid,
      uuid: _uuid,
      storeVisitId: R.prop('id', v)
    }))
  )(transformedData);
});

const consolidateQuestionAnswersDictForRollup = R.curry((rollUp, questionAnswersDict, questionAnswers) => {
  const type = getWidgetTypeWithoutQuestions(rollUp);
  if (!type) {
    return questionAnswersDict;
  }
  return R.map(R.indexBy(R.prop('questionCanonicalKey')), questionAnswers || []);
});

const MODULE_NAMESPACE = 'savedRollup';

const EditSavedRollupPage = ({
  history,
  rollUp,
  svrResponses,
  transformData,
  transformedData,
  tableColumns,
  questions,
  questionAnswers,
  userId,
  isClientUser,
  fetchForms,
  fetchPhotoQuestions,
  filterList,
  forms,
  photos,
  blockedPhotos,
  getSvrPhotos,
  handleToggleSavedRollUpPage,
  showRollupDetailModal,
  filterByAction,
  showSaveAsRollupModal,
  toggleSaveAsRollupModal,
  title,
  description,
  toggleChangeSaveAsRollupModal,
  restrictTokenUserId,
  downloadZip,
  showDownloadZipModal,
  hideDownloadZipModal,
  zipLink,
  zipError,
  fileExportLink,
  fileExportError,
  showFileExportModal,
  hideFileExportModal,
  exportToFile,
  namespace,
  handleSelectData,
  selectedData,
  questionAnswersDict,
  photosDict,
  transformedDataDict,
  prefilledFilters,
  fetchAutocomplete,
  clearAutocomplete,
  autoComplete,
  _tempUuid,
  embedPhotosInExcelReport,
  includeMotivesInExcelReport
}) => {
  questions = consolidateQuestionsForRollup(_tempUuid, rollUp, questions);
  questionAnswers = consolidateQuestionAnswersForRollup(_tempUuid, rollUp, questionAnswers, transformedData);
  questionAnswersDict = consolidateQuestionAnswersDictForRollup(rollUp, questionAnswersDict, questionAnswers);
  const dataWithMotives = mergePhotoMotives(questions, questionAnswers, transformedData);
  const questionWithStarFlagMotives = dataWithMotives.questions;
  const answersWithStarFlagMotives = dataWithMotives.answers;
  const questionAnswersDictWithStarFlagMotives = consolidateQuestionAnswersDictForRollup(rollUp, R.map(R.indexBy(R.prop('questionCanonicalKey')), answersWithStarFlagMotives), answersWithStarFlagMotives);

  const clientuser = isClientUser();

  return (
    <div>
      <EditSavedRollupPageToolbar
        goBack={() => history.push('/dashboard/roll-ups')}
        selectedData={selectedData}
        questions={questions}
        tableColumns={tableColumns}
        title={title}
        namespace="svrResponses"
        photos={!isClientUser() ? photos : R.reject(R.propSatisfies(R.contains(R.__, blockedPhotos), 'uuid'))(photos)}
        embedPhotosInExcelReport={embedPhotosInExcelReport}
        shouldIncludeMotives={includeMotivesInExcelReport && !isClientUser()}
        isClientUser  = {clientuser ? "client" : "nonclient"}
        onExport={type => {
          exportToFile({
            title,
            type,
            questions,
            questionAnswers,
            transformedData: selectedData.length === 500 ? svrResponses : selectedData,
            namespace: 'svrResponses',
            photos: !isClientUser() ? photos : R.reject(R.propSatisfies(R.contains(R.__, blockedPhotos), 'uuid'))(photos),
            embedPhotosInExcelReport,
            shouldIncludeMotives: includeMotivesInExcelReport && !isClientUser(),
            filterColumns: true
          });
        }}
      />
      {
        showFileExportModal && (
          <FileExportProgressModal
            fileExportLink={fileExportLink}
            fileExportError={fileExportError}
            onApprove={hideFileExportModal}
          />
        )
      }
      {
        showDownloadZipModal && (
          <DownloadZipProgressModal
            zipLink={zipLink}
            zipError={zipError}
            onApprove={hideDownloadZipModal}
          />
        )
      }
      {showSaveAsRollupModal && <SaveAsModal
        namespace={namespace}
        onSave={() => { toggleSaveAsRollupModal(); handleToggleSavedRollUpPage(); }}
        canSave={() => Boolean(title)}
        onCancel={toggleSaveAsRollupModal}
        onChange={toggleChangeSaveAsRollupModal}
        restrictTokenUserId={restrictTokenUserId}
        tokenUserId={userId}
        showWhen={showSaveAsRollupModal}
        title={title}
        description={description}
        metaJson={{ embedPhotosInExcelReport, includeMotivesInExcelReport }}
        isClientUser={isClientUser()}
      />}
      {showRollupDetailModal && <SavedRollupPage
        rollUpId={rollUp.id}
        filterByAction={filterByAction}
        prefilledFilters={prefilledFilters}
        filterList={filterList}
        rollUpTitle={title}
        rollUpDescription={description}
        isOpen={showRollupDetailModal}
        onClose={handleToggleSavedRollUpPage}
        data={transformedData}
        restrictTokenUserId={restrictTokenUserId}
        embedPhotos={embedPhotosInExcelReport}
        includeMotivesInExcelReport={includeMotivesInExcelReport}
        SearchParamsList={(
          <SearchParamsList
            fetchAutocomplete={fetchAutocomplete}
            clearAutocomplete={clearAutocomplete}
            autoComplete={autoComplete}
            prefilledFilters={prefilledFilters}
            data={transformedData}
            tableColumns={!isClientUser() ? tableColumns : R.reject(tc => tc.label === "Review State", tableColumns)}
            filterByAction={filterByAction}
            syncFilters={filterByAction}
            filterList={filterList}
            namespace={namespace}
            style={{ display: 'block' }}
            allowSave={false}
            isAddorEditWidget = {false}
            isRollup= {true}   
          />
        )}
        isClientUser={isClientUser()}
      />}
      <EditRollUpsTable
        title={rollUp.title}
        description={rollUp.description}
        transformData={transformData}
        transformedData={filteredTransformedData(rollUp, transformedData, questionAnswers)}
        transformedDataDict={transformedDataDict}
        questionAnswersDict={isClientUser() ? questionAnswersDict : questionAnswersDictWithStarFlagMotives}
        photosDict={photosDict}
        tableColumns={tableColumns}
        data={svrResponses}
        prefilledFilters={prefilledFilters}
        filterList={filterList}
        isClientUser={isClientUser()}
        fetchForms={fetchForms}
        forms={forms}
        photos={photos}
        blockedPhotos={blockedPhotos}
        getSvrPhotos={getSvrPhotos}
        fetchPhotoQuestions={fetchPhotoQuestions}
        questions={isClientUser() ? questions : questionWithStarFlagMotives}
        questionAnswers={isClientUser() ? questionAnswers : answersWithStarFlagMotives}
        questionCanonicalKeys={R.propOr([], 'formQuestionCanonicalKeys', rollUp)}
        toggleRollUpPage={toggleSaveAsRollupModal}
        downloadZip={downloadZip}
        showDownloadZipModal={showDownloadZipModal}
        hideDownloadZipModal={hideDownloadZipModal}
        handleSelectData={handleSelectData}
        namespace={namespace}
        history={history}
        withoutQuestions={R.pathEq(['metaJson', 'widgetType'], 'feedback', rollUp)}
      />
    </div>
  );
};

const getAppropriateNamespace = R.pathOr(MODULE_NAMESPACE, ['match', 'params', 'widgetNamespace']);

const mapStateToProps = (state, props) => ({
  svrResponses: getSvrResponses(state, getAppropriateNamespace(props)),
  forms: getForms(state, getAppropriateNamespace(props)),
  photos: getPhotos(getAppropriateNamespace(props))(state),
  getSvrPhotos: storeVisitId => getSvrPhotos(storeVisitId)(state, getAppropriateNamespace(props)),
  transformedData: getTransformedData(state, getAppropriateNamespace(props)),
  tableColumns: getTableColumns(getAppropriateNamespace(props))(state),
  questions: getQuestions(state, getAppropriateNamespace(props)),
  questionAnswers: getQuestionAnswers(state, getAppropriateNamespace(props)),
  userId: getUserId(state),
  filterList: getFilterList(getAppropriateNamespace(props))(state),
  namespace: getAppropriateNamespace(props),
  showDownloadZipModal: getShowDownloadZipModal(state),
  zipLink: getZipLink(state),
  zipError: getZipError(state),
  showFileExportModal: getShowFileExportModal(state),
  fileExportLink: getFileExportLink(state),
  fileExportError: getFileExportError(state),
  questionAnswersDict: R.map(R.indexBy(R.prop('questionCanonicalKey')), getQuestionAnswers(state, getAppropriateNamespace(props)) || []),
  photosDict: R.indexBy(R.prop('uuid'), getPhotos(getAppropriateNamespace(props))(state) || []),
  transformedDataDict: R.indexBy(R.prop('id'), getTransformedData(state, getAppropriateNamespace(props)) || []),
  isClientUser,
  prefilledFilters: getPrefilledFilters(state),
  autoComplete: getAutoComplete(state)
});

const mapDispatchToProps = dispatch => ({
  fetchRollUp: payload => dispatch(_fetchRollUp(payload)),
  fetchSvrResponses: (namespace, filters = {}) => dispatch(_fetchSvrResponses(namespace, filters, [], true)),
  fetchQuestionAnswers: (namespace, payload, svrResponses) => dispatch(_fetchQuestionAnswers(namespace, payload, svrResponses)),
  fetchForms: (namespace, payload) => dispatch(_fetchForms(namespace, payload)),
  fetchPhotoQuestions: (namespace, questionAnswers) => storeVisitIds => dispatch(_fetchPhotoQuestions(namespace, questionAnswers, storeVisitIds)),
  setQuestions: (namespace, payload) => dispatch(_setQuestions(namespace, payload)),
  filterByAction: (namespace, filters) => dispatch(_filterByAction(namespace, filters)),
  transformData: (orderBy, order, sortType, filterList, data, namespace, tableColumns) => dispatch(_transformData(orderBy, order, sortType, filterList, data, namespace, tableColumns)),
  downloadZip: payload => dispatch(_downloadZip(payload)),
  hideDownloadZipModal: payload => dispatch(_hideDownloadZipModal(payload)),
  hideFileExportModal: payload => dispatch(_hideFileExportModal(payload)),
  exportToFile: payload => dispatch(_exportToFile(payload)),
  fetchAutocomplete: payload => dispatch(_fetchAutocomplete(payload)),
  clearAutocomplete: () => dispatch(_clearAutocomplete()),
  fetchSavedWidget: id => dispatch(fetchSavedWidget(id)),
});

const withRecompose = compose(
  defaultProps({
    svrResponses: [],
    transformedData: [],
    transformedDataDict: {},
    questionAnswers: {},
    questionAnswersDict: {},
    tableColumns: [],
    forms: [],
    photos: [],
    photosDict: {},
    filterList: {},
    namespace: MODULE_NAMESPACE
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    ({
      rollUp = {},
      showRollupDetailModal = false,
      showSaveAsRollupModal = false,
      restrictTokenUserId = false,
      selectedData = [],
      title = '',
      description = '',
      _tempUuid = uuid(),
      embedPhotosInExcelReport = false,
      includeMotivesInExcelReport = false
    }) => ({
      rollUp,
      showRollupDetailModal,
      showSaveAsRollupModal,
      title,
      description,
      restrictTokenUserId,
      selectedData,
      _tempUuid,
      embedPhotosInExcelReport,
      includeMotivesInExcelReport
    }),
    ({
      setRollUp: () => rollUp => ({
        rollUp,
        title: rollUp.title,
        description: rollUp.description,
        restrictTokenUserId: rollUp.restrictTokenUserId,
        embedPhotosInExcelReport: R.pathOr(false, ['metaJson', 'embedPhotosInExcelReport'], rollUp),
        includeMotivesInExcelReport: R.pathOr(false, ['metaJson', 'includeMotivesInExcelReport'], rollUp)
      }),
      setBlockedPhotos: () => R.compose(
        R.objOf('blockedPhotos'),
        R.pluck('uuid'),
        R.chain(R.pathOr([], ['metaJson', 'blockPhotos']))
      ),
      handleToggleSavedRollUpPage: ({ showRollupDetailModal }) => () => ({ showRollupDetailModal: !showRollupDetailModal }),
      toggleSaveAsRollupModal: ({ showSaveAsRollupModal }) => () => ({ showSaveAsRollupModal: !showSaveAsRollupModal }),
      toggleChangeSaveAsRollupModal: () => ({ target }) => ({ [target.name]: target.value }),
      handleSelectData: (__, { transformedData }) => selectedData => {
        if (selectedData.length > 0) {
          return {
            selectedData: R.compose(
              R.filter(d => R.contains(d.id, selectedData))
            )(transformedData)
          };
        }
        return { selectedData };
      }
    })
  ),
  lifecycle({
    componentDidMount() {
      const rollUpId = R.path(['params', 'id'], this.props.match),
        widgetNamespace = R.path(['params', 'widgetNamespace'], this.props.match);

      const buildRollup = R.curry((namespace, rollUp, svrResponses) => {
        const formQuestionCanonicalKeys = R.propOr([], 'formQuestionCanonicalKeys', rollUp);

        this.props.fetchQuestionAnswers(namespace, formQuestionCanonicalKeys, svrResponses)
          .then(res => {
            let formAnswers = R.pathOr([], ['payload', 'data', 'data'], res);
            const metaJson = R.compose(R.defaultTo({}), R.prop('metaJson'))(rollUp);
            const filteredWidgetData = R.compose(R.defaultTo([]), R.prop('filteredWidgetData'))(rollUp);
            if (!R.isNil(widgetNamespace)) {
              const storeVisits = maybeFilterStoreVisitsWithStarredOrFlaggedPhotos(metaJson, filteredWidgetData);
              formAnswers = R.compose(R.filter(R.compose(R.contains(R.__, R.pluck('id', storeVisits)), R.prop('storeVisitId'))))(formAnswers);
            }
            return Promise.resolve(
              R.compose(
                answers => this.props.fetchPhotoQuestions(namespace, answers)(R.compose(R.uniq, R.pluck('storeVisitId'))(answers)),
                arr => R.filter(R.compose(R.contains(R.__, arr), R.prop('answerValue')), formAnswers),
                maybeShowOnlyStarredOrFlaggedPhotos(
                  metaJson,
                  filteredWidgetData,
                  R.pluck('answerValue')
                ),
              )(formAnswers)
            ).then(() => {
              this.props.setQuestions(namespace, formQuestionCanonicalKeys);
              this.props.setRollUp(rollUp);
            });
          });
      });

      if (rollUpId) {
        this.props.fetchRollUp(rollUpId)
          .then(res => {
            const rollUp = R.pathOr([], ['payload', 'data', 'data'], res);

            this.props.fetchSvrResponses(this.props.namespace, rollUp.filters, this.props.tableColumns)
              .then(R.tap(() => this.props.filterByAction(this.props.namespace, rollUp.filters)))
              .then(res => {

                const svrResponses = R.pathOr([], ['payload', 'data', 'data'], res);
                this.props.setBlockedPhotos(svrResponses);

                return buildRollup(this.props.namespace)(rollUp)(R.pluck('id', svrResponses));

              });
          });
      }

      if (widgetNamespace) {
        const widgetId = JSON.parse(localStorage.getItem(widgetNamespace));
        return this.props.fetchSavedWidget(widgetId)
          .then(res => {
            const widget = R.pathOr([], ['payload', 'data', 'data'])(res);
            return this.props.fetchSvrResponses(widgetNamespace, widget.filters, this.props.tableColumns)
              .then(R.tap(() => this.props.filterByAction(widgetNamespace, widget.filters)))
              .then(res => {
                const svrResponses = R.pathOr([], ['payload', 'data', 'data'], res);
                this.props.setBlockedPhotos(svrResponses);

                return buildRollup(widgetNamespace)(R.assocPath(
                  ['filteredWidgetData'],
                  transformSvrResponsesForWidget(widget.filters, svrResponses)
                )(widget))(R.pluck('id', svrResponses));
              });
          });
      }
    }
  }),
  withRouter
);

export default withRecompose(EditSavedRollupPage);
